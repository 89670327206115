import React from 'react'
import AdminHeader from '../../PrivateComponents/AdminHeader'
import AdminSubCard from '../../PrivateComponents/AdminSubCard'
import DialogBox from '../../PrivateComponents/DialogBox'
import Avatar from '@mui/material/Avatar';
import PeopleIcon from '@mui/icons-material/People';
import { colorshades, http } from '../../Services/api.services';
import { BASE_URL } from '../../default.config';
import Skeleton from '@mui/material/Skeleton'
import moment from 'moment/moment';

const shades = colorshades('#F0AD00')

const Subscription = () => {
    console.log(shades)
    const [open, setOpen] = React.useState(false);
    const [color, setColor] = React.useState(null);
    const [totalSubscribers, setTotalSubscribers] = React.useState(null)
    const [totalSubscriptions, setTotalSubscriptions] = React.useState([])
    const [subscriptionDetail, setSubscriptionDetail] = React.useState(null)
    const [totalPlans, setTotalPlans] = React.useState(0)



    React.useEffect(() => {
        getTotalSubscribers();
        getAllSubscriptions();
    }, [])

    // GET THE SUBSCRIPTION DETAIL BY ID

    const getSubscriptionDetails = async (bg, id) => {
        try {
            const res = await http.get(`${BASE_URL}admin/subscription/${id}`)
            console.log(res);
            setSubscriptionDetail(res?.data[0])
            setOpen(true)
            setColor(bg)
        } catch (e) {
            console.log(e);
        }
    }

    // GET TOTAL SUBSCRIBERS COUNT 

    const getTotalSubscribers = async () => {
        try {
            const res = await http.get(`${BASE_URL}admin/total-subscriber`)
            setTotalSubscribers(res?.data?.total)
        } catch (e) {
            console.log(e);
        }
    }


    //    GET TOTAL AVAILABLE SUBSCRIPTIONS

    const getAllSubscriptions = async () => {
        try {
            const res = await http.get(`${BASE_URL}admin/subscriptions-list`)
            setTotalSubscriptions(res?.data)
            setTotalPlans(res?.data?.length)
        } catch (e) {
            console.log(e);
        }
    }



    return (
        <div className='w-full px-3'>
            <AdminHeader text='Subscription' totalPlans={totalPlans} />
            <div className='w-full flex items-center justify-start py-5'>
                <TotalSubscriberCard
                    total={totalSubscribers}
                />
            </div>
            <div className='flex flex-col w-full items-center justify-between gap-5 lg:flex-row'>
                {totalSubscriptions.map((subscription, index) => {
                    // { console.log(subscription) }
                    return (
                        subscription ? (
                            <Subscriptions
                                title={subscription?.name}
                                description={subscription?.description}
                                amount={subscription?.price}
                                validity={subscription?.trialperiod}
                                date={subscription?.createdat}
                                color={'#F0AD00'}
                                bg='rgba(240, 173, 0, 0.06)'
                                subscribers={subscription.subscriber}
                                key={index}
                            />
                            // <AdminSubCard
                            //     key={index}
                            //     text={subscription?.name}
                            //     description={subscription?.description}
                            //     price={subscription?.price}
                            //     trialperiod={subscription?.trialperiod}
                            //     createdat={subscription?.createdat}
                            //     sub={subscription?.subscriber}
                            //     bg='rgba(240, 173, 0, 0.04)'
                            //     color='[#F0AD00]'
                            // // onchange={() => getSubscriptionDetails('rgba(240, 173, 0, 0.04)', subscription?.id)}
                            // />
                        ) : (
                            <Skeleton sx={{ borderRadius: '5px' }} variant="rectangular" width={280} height={138} />
                        )
                    )
                })}
            </div>
            <DialogBox
                open={open}
                color={color}
                onClose={() => {
                    setOpen(false);
                    setSubscriptionDetail(null)
                }}
                subscriptionDetail={subscriptionDetail}
            />
        </div>
    )
}

export default Subscription


const TotalSubscriberCard = ({ total }) => (
    <div className='flex items-center min-h-[24] justify-between px-4  border border-[#ECEAEA] py-2 bg-[#FDFDFD] rounded shadow w-fit gap-6'>
        <Avatar>
            <PeopleIcon />
        </Avatar>
        <div className='w-full h-12'>
            {(total || total == 0) ?
                <h6 className='text-[#393939] text-[2vmax] lg:text-[1.2vmax] font-semibold'>{total}</h6>
                : <Skeleton variant='text' width={28} height={35} />
            }
            <p className='text-[#717171] text-[1.5vmax] lg:text-[.8vmax] font-normal'>Total subscribers</p>
        </div>
    </div>
)

const Subscriptions = ({ title, description, subscribers, date, validity, amount, color, bg }) => (
    <div style={{ background: bg }} className='flex flex-col px-4 py-2 rounded shadow'>
        <div className='flex justify-between items-center mb-4'>
            <h2 className='text-xl font-bold'>{title}</h2>
        </div>
        <p className='text-sm font-light text-[#262626] mb-2'>{description}</p>
        <p className='text-sm text-[#757575]'>Subscribers: <b>{subscribers}</b></p>
        <div className='flex justify-between items-center gap-4'>
            <p className='text-[#757575] text-xs font-semibold'>Created - <span>{moment(date)?.format('DD/MM/YY HH:mm A')}</span></p>
            <div className='flex items-center gap-6'>
                <p className='text-[#F0AD00] text-[14px] font-medium'>{validity} days</p>
                <p className='text-lg font-semibold text-[#262626]'>&#8377;&nbsp;{amount}</p>
            </div>
        </div>
    </div>
)