import React, { useRef, useState } from 'react'
import { useDimensions } from "../../Contexts/DimensionContext/DimensionsContext";
import AllTransections from '../../PrivateComponents/AllTransections';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

const HeadCell = ({ title }) => (
    <h6 className='text-[1.5vmax]  lg:text-[.8vmax] text-[#000000] font-semibold tracking-wider'>
        {title}
    </h6>
)

const columns = [

    {
        field: 'name',
        headerName: <HeadCell title="Name" />,
        minWidth: 150,
        flex: 1,
        // renderCell: (params) => {

        //     return <div className='flex gap-2  items-center' style={{ opacity: params?.row?.status ? 1 : 0.4 }}>

        //         <div>
        //             <p className='font-bold '>{params.row.name}</p>
        //         </div>
        //     </div>
        // }

    },
    {
        field: 'description',
        headerName: <HeadCell title="Description" />,
        minWidth: 250,
        flex: 1,

    },
    {
        field: 'entry',
        headerName: <HeadCell title="Entry con.." />,
        minWidth: 200,
        flex: 1,

    },
    {
        field: 'exit',
        headerName: <HeadCell title="Exit con.." />,
        minWidth: 200,
        flex: 1,

    },
    {
        field: 'tralling',
        headerName: <HeadCell title="Trailling con.." />,
        minWidth: 200,
        flex: 1,

    },
    {
        field: 'pl',
        headerName: <HeadCell title="P/L" />,
        minWidth: 200,
        flex: 1,

    },
    {
        field: 'trade-open',
        headerName: <HeadCell title="Trade open" />,
        minWidth: 200,
        flex: 1,

    },
    {
        field: 'trade-close',
        headerName: <HeadCell title="Trade Closed" />,
        minWidth: 200,
        flex: 1,

    },

    {
        field: 'createdAt',
        headerName: <HeadCell title="Created at" />,
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
            const date = params.row.createdAt;

            const date1 = date?.split('T')[0]

            return (
                <div style={{ opacity: params?.row?.status ? 1 : 0.4 }}>
                    <p>{date1}</p>
                    <DeleteOutlineOutlinedIcon />
                </div>
            )

        },

    },

];

const tableStyle = {
    maxWidth: 'calc(100% - 300px)'
}

const Actions = () => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState(0)
    const { xs, sm, md, lg, xl, xxl } = useDimensions()
    const divRef = useRef(null);
    const [currentWidth, setcurrentWidth] = React.useState(0)

    React.useEffect(() => {
        const updateDivWidth = () => {
            if (divRef.current) {
                const currentWidth = divRef.current.offsetWidth;
                setcurrentWidth(currentWidth)
            }
        };

        // Initial setup
        updateDivWidth();

        // Attach event listener for screen size changes
        window.addEventListener('resize', updateDivWidth);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateDivWidth);
        };
    }, []);

    return (
        <>
            <div className="w-full flex-1 flex gap-3 bg-white px-0  h-full">
                <div
                    ref={divRef}
                    // style={{ maxWidth: lg || xl || xxl ? 'calc(100% - 150px)' : '98vw' }}
                    className="flex-1 w-full  flex flex-col p-2 "
                >
                    <div className='flex-1 w-full'>
                        <AllTransections
                            currentWidth={currentWidth}
                            columns={columns}
                            rows={[]}
                        />
                    </div>
                    <div className='w-full flex pt-2 justify-end'>
                        <Fab
                            onClick={() => {
                                navigate('/admin/options/tools/actions/add-action')
                            }}
                            color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Actions
