import React from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            minWidth: 200,
        },
    },
};

const IhmHeader = () => {
    return (
        <>
            <div className='w-full flex items-center justify-between py-5 px-2'>
                <Tabs
                    sx={{
                        '& .css-kxhebr-MuiTabs-indicator': {
                            display: 'none'
                        },
                        '& .css-wy1qx7.Mui-selected': {
                            backgroundColor: '#1C1C1C',
                            color: '#FFFFFF',
                            '&:hover': {
                                backgroundColor: '#1C1C1C',
                            }
                        },
                        '& .css-wy1qx7': {
                            color: '#000',
                            minWidth: '80px',
                            minHeight: '35px',
                            padding: 1,
                            textTransform: 'capitalize',
                            borderRight: '1px solid #00000021',
                            '& .css-6w7hx6-MuiTabs-root': {
                                minHeight: '20px'
                            },
                            '&:hover': {
                                backgroundColor: '#eeeeee',
                            }
                        },
                        '& .css-p5x7jj': {
                            display: 'none'
                        },
                        background: '#FFFFFF',
                        border: '1px solid #00000021',
                        width: 'fit-content',
                        height: 'fit-content',
                        minHeight: '20px',
                        padding: 0,
                        borderRadius: 1,
                    }}
                    size='small'
                    // value={selectedIndex}
                    // onChange={handleChange}
                    variant="scrollable"
                    aria-label="basic tabs example"
                >
                    <Tab label={<div> <p className='text-xs'>22,217.95</p> <p className='text-xs text-gray-500'>NIFTY</p></div>} {...a11yProps(0)} />
                    <Tab label={<div> <p className='text-xs'>22,217.95</p> <p className='text-xs text-gray-500'>BANK N</p></div>} {...a11yProps(0)} />
                    <Tab label={<div> <p className='text-xs'>22,217.95</p> <p className='text-xs text-gray-500'>FIN N</p></div>} {...a11yProps(0)} />
                    <Tab label={<div> <p className='text-xs'>22,217.95</p> <p className='text-xs text-gray-500'>MIDCP N</p></div>} {...a11yProps(0)} />
                    <Tab label={<div> <p className='text-xs'>22,217.95</p> <p className='text-xs text-gray-500'>SENSEX</p></div>} {...a11yProps(0)} />
                    {/* {indexes?.map((_index, index) => (
                            <Tab key={index} onClick={() => updateCurrentIndex(_index.type)} label={<div> <p className='text-xs'>{_index.title}</p> <p className='text-xs text-gray-500'>{market[_index?.type]?.strikePrice?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p></div>} {...a11yProps(0)} />
                        ))} */}
                </Tabs>
                <div className='w-[200px]'>
                    <FormControl
                        size='small'
                        fullWidth>
                        <InputLabel id="demo-simple-select-label">Time</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={currentExpiry}
                            // size='small'
                            label="Expiry"
                            MenuProps={MenuProps}
                        // onChange={(e) => onExpiryChange(e?.target?.value)}
                        >
                            <MenuItem value={1}>1 Min</MenuItem>
                            <MenuItem value={1}>1 Min</MenuItem>
                            <MenuItem value={1}>1 Min</MenuItem>

                            {/* {expiry?.map((exp, idx) => (
                                <MenuItem key={idx} value={exp}>1 Min</MenuItem>
                            ))} */}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </>
    )
}

export default IhmHeader
