import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";

export default function ConfirmDeleteBasket({
  loading,
  open,
  onCloseBasket,
  onRemoveBasket,
  message
}) {
  return (
    <Dialog
      open={open}
      onClose={() => onCloseBasket()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete Basket ?</DialogTitle>
      <DialogContent
        sx={{
          maxWidth: 400,
        }}
      >
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseBasket}>Cancel</Button>
        <LoadingButton
          variant="contained"
          color="error"
          loading={loading}
          autoFocus
            onClick={() => onRemoveBasket()}  
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
