import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WolfLogo from '../assets/wolf-logo 1.png'
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { Link, useLocation } from 'react-router-dom';
import LoginPageImg from '../assets/Rectangle 447.png'
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { ApiService, http } from '../Services/api.services';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { emailRegex, mobileRegex, dobRegex, panRegex, cityRegex, passwordRegex, stateRegex, nameRegex, selectRegex, finCodeRegex } from '../ValidationRegex';
import { useDispatch } from 'react-redux';
import { asyngetStatus, showToast } from '../store/Actions/Action'
import LoadingButton from '@mui/lab/LoadingButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import CountrySwitch from '../PublicComponents/CountrySwitch';
import { BASE_URL, TERMS_AND_CONDITIONS } from '../default.config';
import DialogActions from '@mui/material/DialogActions';
import { IconButton } from '@mui/material';
import TnC from '../PublicComponents/TnC';



const tradingExperieces = [
    'Part Time Trader',
    'Full Time Trader',
    'Invest in Shares',
    'Invest in Mutual Funds'
];



const states = ["Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry"
]

const api = new ApiService()

export default function SignUp(props) {
    const dispatch = useDispatch();
    const path = useLocation();
    const pathnameSegments = path.pathname.split('/');
    const queryParam = path?.search?.split('&');


    //  GET THE VALUE FROM THE URL, UTM OR ETC 

    const getSignUpOptions = queryParam?.map((param) => {
        // Splitting the string using '=' and getting the second part (value)
        const [, value] = param.split('=');
        return value;
    });

    const [signUpOptions, setSignUpOptions] = React.useState({ ...getSignUpOptions })

    //  GET THE VALUE FROM THE URL, UTM OR ETC 

    const _form = {
        name: '',
        email: '',
        pan: '',
        dob: '',
        gender: '',
        city: '',
        state: '',
        income_group: '',
        trading_experience: [],
        isInterested: '',
        risk_appetite: '',
        password: '',
        country: 'India',
        fincode: '',
        utm_source: signUpOptions[0] || '',
        utm_medium: signUpOptions[1] || '',
        utm_campaign: signUpOptions[2] || '',
    }

    const [singupForm, setSignUpForm] = React.useState({ ..._form })
    const [personName, setPersonName] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false)
    const [tncAgreed, setTnCAgreed] = React.useState(false)
    const [openAgree, setOpenAgree] = React.useState(false)

    // STYLE TEXTFILED

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const inputStyle = {
        // color: 'rgba(55, 55, 55, 0.20)',
        fontSize: { lg: '.9vmax' },
        '& .MuiInputBase-root': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '2vmax', // Font size for large screens
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '.8vmax', // Font size for large screens
            },
            bgcolor: '#fff'
        },
    }

    // HANDLE PASSWORD VISIBILITY

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // HANDLE BUTTON VISIBILITY

    const isFormValid = () => {
        const isNameValid = nameRegex.test(singupForm.name)
        const isEmailValid = emailRegex.test(singupForm.email);
        const isPasswordValid = passwordRegex.test(singupForm.password);
        const isDobValid = dobRegex.test(singupForm.dob)
        const isCityvalid = cityRegex.test(singupForm.city)
        const isStatevalid = stateRegex.test(singupForm.state)
        const isPanValid = singupForm.pan ? panRegex.test(singupForm.pan) : true
        let isincome_groupValid = false;
        if (singupForm.income_group.length > 0) {
            isincome_groupValid = true;
        }
        let isGenderValid = false;
        if (singupForm.gender.length > 0) {
            isGenderValid = true;
        }
        let isTradingValid = false;
        if (singupForm.trading_experience.length > 0) {
            isTradingValid = true;
        }
        let isInterestedValid = false;
        if (singupForm.isInterested.length > 0) {
            isInterestedValid = true;
        }
        let isrisk_appetiteValid = false;
        if (singupForm.risk_appetite.length > 0) {
            isrisk_appetiteValid = true;
        }
        let iscountryvalid = false
        if (singupForm.country) {
            iscountryvalid = true;
        }

        return isEmailValid && isPasswordValid && isDobValid && isCityvalid && isPanValid && isStatevalid && isincome_groupValid && isTradingValid && isInterestedValid && isrisk_appetiteValid && isNameValid && isGenderValid && iscountryvalid;
    };

    // HANDLE FORM CHANGE

    const onFormChange = (name, value) => {
        try {
            if (name === 'dob') {
                // 31536000000 -- 18 years in ms
                if ((new Date().getTime() - new Date(value).getTime()) / 31536000000 < 18) {
                    const _snackbar = {
                        message: 'Minimum age is 18!',
                        open: true,
                        duration: 3000,
                        type: 'error'
                    }
                    dispatch(showToast(_snackbar))
                    return
                }
                // else {
                //     const _snackbar = {
                //         message: 'Age 18+',
                //         open: true,
                //         duration: 3000,
                //         type: 'success'
                //     }
                //     dispatch(showToast(_snackbar))
                // }
            }
            if (name == 'trading_experience') {
                const val = { ...singupForm }
                if (val[name]?.includes(value)) {
                    val[name].splice(val[name]?.indexOf(value), 1)
                }
                else val[name].push(value)
                setSignUpForm(val)
            }
            else {
                const form = { ...singupForm }
                form[name] = value
                form['mobile'] = props.mobile;
                setSignUpForm(form)
            }
        }
        catch (e) {
            console.log(`ERROR IN FORM CHANGE -- ${e}`)
        }
    }

    // HANDLE SIGNUP

    const onSignUp = async () => {
        try {
            // PUT SOME VALIDATIONS
            setIsLoading(true)
            // setSignUpForm(_form); //NOT REQUIRED
            const body = { ...singupForm }
            if (!body.pan) body.pan = null
            const response = await http.post(`${BASE_URL}user/register`, body)

            if (response?.status === 200) {
                if (response.data.auth) {
                    const tokenToSet = response.data.auth.token ? response.data.auth.token : '';
                    const userIdToSet = response.data.auth.user_id ? response.data.auth.user_id : '';
                    localStorage.setItem('token', tokenToSet)
                    localStorage.setItem('user_id', userIdToSet)
                }
                setIsLoading(false)
                const _snackbar = {
                    message: response?.data?.message,
                    open: true,
                    duration: 3000,
                    type: 'success'
                    //     type: response.status !== 'success' ? 'error' : 'success'
                };
                dispatch(showToast(_snackbar));
                props.onSignUp();

            } else {
                const _snackbar = {
                    message: response?.data?.message,
                    open: true,
                    duration: 3000,
                    type: 'error'
                };
                dispatch(showToast(_snackbar));
                setIsLoading(false)
            }

        }
        catch (e) {
            const response = e?.response;
            console.log(`ERROR WHILE SENDING OTP --- ${e}`)
            setIsLoading(false)
            const _snackbar = {
                message: response?.data?.message || 'Failed to register!',
                open: true,
                duration: 3000,
                type: 'error'
            };
            dispatch(showToast(_snackbar));
        }
    }


    return (

        <div>
            {!openAgree && (
                <div className='w-full !bg-rgba(248, 248, 248, 0.99)'>
                    <Dialog
                        fullScreen={fullScreen}
                        open={props.open}
                        onClose={() => props.onClose()}
                        aria-labelledby="responsive-dialog-title"
                        sx={{
                            '& .css-m9glnp-MuiPaper-root-MuiDialog-paper': {
                                backgroundColor: 'rgba(248, 248, 248, 0.99)'
                            }
                        }}
                    >
                        <div style={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }} className='w-full min-h-[50vh] !lg:min-h-[52vh] flex'>
                            <div className='hidden lg:flex w-full bg-[#000000]'>
                                {LoginPageImg ?
                                    <img className='w-full h-full object-contain' src={LoginPageImg} alt="LoginPageImg" />
                                    : <p>hello</p>
                                }
                            </div>
                            <div className='w-full '>
                                <DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }} className='shadow-sm'>
                                    <div className='flex flex-col items-center justify-center gap-3 py-6 lg:py-1 relative'>
                                        <img src={WolfLogo} alt="WolfLogo" />
                                        <h6 className='hidden lg:hidden text-[#373737] font-semibold tracking-wider text-[2vmax]'>Hello ! Welcome Back </h6>
                                        <h6 className='block lg:block text-[#373737] font-normal tracking-wider text-[1.5vmax]'>Join <span className='font-semibold'>Fastr</span></h6>
                                        <div className='absolute top-1 right-1'>
                                            <IconButton onClick={() => props.onClose()}>
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <p className='text-center text-sm text-[#373737]'>Ensure internet speed of minimum 40 mbps on a single device in a broadband connection</p>
                                </DialogTitle>
                                {/* <DialogContent sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', maxHeight:'10vh', maxHeight: { sm: '50vh' } , overflowY: 'hidden', maxHeight: { lg: '50vh' } , maxHeight: { md: '65vh' } }}> */}
                                <DialogContent sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', maxHeight: '70vh', overflowX: 'hidden' }}>
                                    <div className='input_area flex items-center justify-center w-full  lg:w-[35vw] lg:overflow-y-auto lg:px-4 pt-10vh'>
                                        <div className='w-full flex flex-col items-start justify-center gap-5'>
                                            <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Select Country  <span className='text-red-500'>*</span></h6>
                                                <Tooltip title="US Coming Soon">
                                                    <div>
                                                        <CountrySwitch />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Name  <span className='text-red-500'>*</span></h6>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    placeholder='Enter Your Name'
                                                    helperText={singupForm.name?.length > 0 && !nameRegex?.test(singupForm.name) ? "Please Enter Valid name" : ""}
                                                    error={singupForm.name?.length > 0 && !nameRegex?.test(singupForm.name)}
                                                    fullWidth
                                                    // variant="standard"
                                                    size='small'
                                                    type='text'
                                                    value={singupForm?.name}
                                                    onChange={(e) => onFormChange('name', e?.target?.value)}
                                                    className='custom-placeholder  border !border-[F4F4F4] !shadow-none'
                                                    sx={inputStyle}
                                                />
                                            </div>
                                            <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Email  <span className='text-red-500'>*</span></h6>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    placeholder='Enter Your Email'
                                                    helperText={singupForm.email?.length > 0 && !emailRegex?.test(singupForm.email) ? "Enter Valid Email" : ""}
                                                    error={singupForm.email?.length > 0 && !emailRegex?.test(singupForm.email)}
                                                    fullWidth
                                                    // variant="standard"
                                                    size='small'
                                                    type='email'
                                                    className='custom-placeholde  border !border-[F4F4F4] !shadow-none'
                                                    value={singupForm?.email}
                                                    onChange={(e) => onFormChange('email', e?.target?.value)}
                                                    sx={inputStyle}
                                                />
                                            </div>
                                            <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>PAN</h6>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    placeholder='Enter Your Pan Card Number'
                                                    helperText={singupForm.pan?.length > 0 && !panRegex?.test(singupForm.pan) ? "Enter Valid Pan Card Number" : ""}
                                                    error={singupForm.pan?.length > 0 && !panRegex?.test(singupForm.pan)}
                                                    fullWidth
                                                    // variant="standard"
                                                    size='small'
                                                    type='text'
                                                    className='custom-placeholde  border !border-[F4F4F4] !shadow-none'
                                                    value={singupForm?.pan}
                                                    inputProps={{
                                                        autoCapitalize: "true"
                                                    }}
                                                    onChange={(e) => onFormChange('pan', e?.target?.value ? e?.target?.value?.toUpperCase() : '')}
                                                    sx={inputStyle}
                                                    InputProps={{
                                                        autoComplete: 'none',
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Please enter valid pan card">
                                                                    <InfoIcon color="disabled" fontSize="small" />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <p className='text-xs text-slate-400'>Ensure correct PAN only, as per the guidelines</p>
                                            </div>
                                            <div className='w-full flex flex-col h-full items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>DOB <span className='text-red-500'>*</span></h6>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    placeholder='Enter Your DOB No.'
                                                    helperText={singupForm.dob?.length > 0 && !dobRegex?.test(singupForm.dob) ? "Enter Valid DOB." : ""}
                                                    error={singupForm.dob?.length > 0 && !dobRegex?.test(singupForm.dob)}
                                                    fullWidth
                                                    // variant="standard"
                                                    size='small'
                                                    type='date'
                                                    value={singupForm?.dob}
                                                    onChange={(e) => onFormChange('dob', e?.target?.value)}
                                                    className='custom-placeholder  border !border-[F4F4F4] !shadow-none'
                                                    sx={inputStyle}
                                                />
                                            </div>
                                            <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Gender  <span className='text-red-500'>*</span></h6>
                                                <FormControl className='!bg-white' sx={{ backgroundColor: 'white' }} fullWidth size='small' >
                                                    <Select
                                                        value={singupForm?.gender}
                                                        onChange={(e) => onFormChange('gender', e?.target?.value)}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        fullWidth
                                                        size='small'
                                                        sx={{
                                                            fontSize: { lg: '.9vmax' },
                                                        }}
                                                    >
                                                        <MenuItem disabled selected>
                                                            <em>Select Gender</em>
                                                        </MenuItem>
                                                        <MenuItem value={'Male'}>Male</MenuItem>
                                                        <MenuItem value={'Female'}>Female</MenuItem>
                                                        <MenuItem value={'Other'}>Other</MenuItem>
                                                    </Select>
                                                </FormControl>

                                            </div>
                                            <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>State  <span className='text-red-500'>*</span></h6>
                                                {/* <TextField
                                                id="input-with-icon-textfield"
                                                placeholder='Enter Your State'
                                                helperText={singupForm.state?.length > 0 && !stateRegex?.test(singupForm.state) ? "Enter Valid state" : ""}
                                                error={singupForm.state?.length > 0 && !stateRegex?.test(singupForm.state)}
                                                fullWidth
                                                // variant="standard"
                                                size='small'
                                                type='text'
                                                className='custom-placeholder  border !border-[F4F4F4] !shadow-none'
                                                value={singupForm?.state}
                                                onChange={(e) => onFormChange('state', e?.target?.value)}
                                                sx={inputStyle}
                                            /> */}

                                                <FormControl className='!bg-white' sx={{ backgroundColor: 'white' }} fullWidth size='small' >
                                                    <Select
                                                        value={singupForm?.state}
                                                        onChange={(e) => onFormChange('state', e?.target?.value)}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        fullWidth
                                                        size='small'
                                                        sx={{
                                                            fontSize: { lg: '.9vmax' },
                                                        }}
                                                    >
                                                        <MenuItem disabled selected>
                                                            <em>Select State</em>
                                                        </MenuItem>
                                                        {states?.map((_state, index) => (
                                                            <MenuItem key={index} value={_state}>{_state}</MenuItem>
                                                        ))}
                                                        {/* <MenuItem value={'Female'}>Female</MenuItem>
                                                    <MenuItem value={'Other'}>Other</MenuItem> */}
                                                    </Select>
                                                </FormControl>

                                            </div>
                                            <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>City  <span className='text-red-500'>*</span></h6>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    placeholder='Enter Your City'
                                                    helperText={singupForm.city?.length > 0 && !cityRegex?.test(singupForm.city) ? "Enter Valid city" : ""}
                                                    error={singupForm.city?.length > 0 && !cityRegex?.test(singupForm.city)}
                                                    fullWidth
                                                    // variant="standard"
                                                    size='small'
                                                    type='text'
                                                    className='custom-placeholder border !border-[F4F4F4] !shadow-none'
                                                    value={singupForm?.city}
                                                    onChange={(e) => onFormChange('city', e?.target?.value)}
                                                    sx={inputStyle}
                                                />

                                            </div>

                                            <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Income Group  <span className='text-red-500'>*</span></h6>

                                                <FormControl className='!bg-white' sx={{ backgroundColor: 'white' }} fullWidth size='small' >
                                                    <Select
                                                        value={singupForm?.income_group}
                                                        onChange={(e) => onFormChange('income_group', e?.target?.value)}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        fullWidth
                                                        size='small'
                                                        sx={{
                                                            fontSize: { lg: '.9vmax' },
                                                        }}
                                                    >
                                                        <MenuItem disabled selected>
                                                            <em>Select Income Group</em>
                                                        </MenuItem>
                                                        <MenuItem value={'Up to 4 lakhs'}>Up to 4 lakhs (₹)</MenuItem>
                                                        <MenuItem value={'Up to 4 - 10 lakhs'}>4 - 10 lakhs (₹)</MenuItem>
                                                        <MenuItem value={'Above to 10 lakhs'}>Above to 10 lakhs (₹)</MenuItem>
                                                    </Select>
                                                </FormControl>

                                            </div>
                                            <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Trading Experience  <span className='text-red-500'>*</span></h6>

                                                <FormControl className='!bg-white' sx={{ backgroundColor: 'white' }} fullWidth size='small' >
                                                    <div className='border border-gray-300 rounded-md'>
                                                        {tradingExperieces.map((exp) => (
                                                            <div onClick={() => onFormChange('trading_experience', exp)} className='hover:bg-slate-100 cursor-pointer flex gap-1 items-center mb-1' key={exp} value={exp}>
                                                                <Checkbox checked={singupForm?.trading_experience?.indexOf(exp) > -1} />
                                                                <p className='text-slate-700 text-sm'>{exp}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {/* <InputLabel id="demo-multiple-name-label">Select</InputLabel> */}
                                                    {/* <Select
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        value={singupForm?.trading_experience}
                                                        onChange={(e) => onFormChange('trading_experience', e?.target?.value)}
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => selected.join(', ')}
                                                        MenuProps={MenuProps}
                                                        fullWidth
                                                        variant='outlined'
                                                        size='small'
                                                        sx={{
                                                            fontSize: { lg: '.9vmax' },
                                                        }}
                                                    >
                                                        {tradingExperieces.map((exp) => (
                                                            <MenuItem key={exp} value={exp}>
                                                                <Checkbox checked={singupForm?.trading_experience?.indexOf(exp) > -1} />
                                                                <ListItemText primary={exp} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select> */}
                                                </FormControl>

                                            </div>
                                            <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] whitespace-nowrap font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Are you interested in Professional trading in options?  <span className='text-red-500'>*</span></h6>
                                                <FormControl>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="yes"
                                                        name="radio-buttons-group"
                                                        value={singupForm?.isInterested}
                                                        onChange={(e) => onFormChange('isInterested', e?.target?.value)}
                                                    >
                                                        <div className='flex items-center'>
                                                            <FormControlLabel value="yes" control={<Radio size='small' />} label="Yes" />
                                                            <FormControlLabel value="no" control={<Radio size='small' />} label="No" />
                                                        </div>
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Share Your Risk Appetite  <span className='text-red-500'>*</span></h6>

                                                <FormControl className='!bg-white' sx={{ backgroundColor: 'white' }} fullWidth size='small' >
                                                    <Select
                                                        value={singupForm?.risk_appetite}
                                                        onChange={(e) => onFormChange('risk_appetite', e?.target?.value)}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        fullWidth
                                                        size='small'
                                                        // sx={inputStyle}
                                                        sx={{
                                                            fontSize: { lg: '.9vmax' },
                                                        }}
                                                    >
                                                        <MenuItem disabled selected>
                                                            <em>Select </em>
                                                        </MenuItem>
                                                        <MenuItem value={'Low risk and limited gain'}>Low risk and limited gain</MenuItem>
                                                        <MenuItem value={'Limited risk and medium gain'}>Limited risk and medium gain</MenuItem>
                                                        <MenuItem value={'High risk and high gain'}>High risk and high gain</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>FINCODE </h6>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    placeholder='If you have FINCODE, enter here.'
                                                    fullWidth
                                                    autoComplete='off'
                                                    size='small'
                                                    inputProps={{
                                                        autoComplete: 'off'
                                                    }}
                                                    className='custom-placeholder  border !border-[F4F4F4] !shadow-none'
                                                    value={singupForm?.fincode}
                                                    helperText={singupForm.fincode?.length > 0 && !finCodeRegex?.test(singupForm.fincode) ? " Enter a Valid FINCODE" : ""}
                                                    error={singupForm.fincode?.length > 0 && !finCodeRegex?.test(singupForm.fincode)}
                                                    type={'text'}
                                                    onChange={(e) => onFormChange('fincode', e?.target?.value)}
                                                    sx={inputStyle}
                                                />

                                            </div>
                                            <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Create Your Password<span className='text-red-500'>*</span></h6>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    placeholder='Create Your Password'
                                                    helperText={singupForm.password?.length > 0 && !passwordRegex?.test(singupForm.password) ? " password should have atleast 5 character" : ""}
                                                    error={singupForm.password?.length > 0 && !passwordRegex?.test(singupForm.password)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {showPassword ? (
                                                                    <VisibilityIcon
                                                                        onClick={handleClickShowPassword}
                                                                        sx={{ cursor: 'pointer', color: 'rgba(55, 55, 55, 0.20)' }}
                                                                    />
                                                                ) : (
                                                                    <VisibilityOffIcon
                                                                        onClick={handleClickShowPassword}
                                                                        sx={{ cursor: 'pointer', color: 'rgba(55, 55, 55, 0.20)' }}
                                                                    />
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    // variant="standar
                                                    size='small'
                                                    className='custom-placeholder  border !border-[F4F4F4] !shadow-none'
                                                    value={singupForm?.password}
                                                    type={showPassword ? 'text' : 'password'}
                                                    onChange={(e) => onFormChange('password', e?.target?.value)}
                                                    sx={inputStyle}
                                                />
                                            </div>

                                            <div className='flex flex-col items-center justify-center w-full lg:flex-col-reverse lg:items-start lg:justify-start lg:gap-3 py-3'>
                                                <LoadingButton
                                                    loading={isLoading}
                                                    sx={{ padding: '.6vmax 0', boxShadow: 'none' }}
                                                    onClick={() => {
                                                        setOpenAgree(true);
                                                    }}
                                                    fullWidth
                                                    disabled={!isFormValid()}
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    <h6 className='text-[#FFFFFF] font-normal text-[2vmax] tracking-wider lg:text-[.8vmax]'>Next</h6>
                                                </LoadingButton>
                                            </div>
                                        </div>
                                    </div>
                                </DialogContent>
                            </div>
                        </div>

                    </Dialog>
                </div>
            )}

            <div className='w-full h-full !bg-rgba(248, 248, 248, 0.99)'>
                <Dialog
                    fullScreen={fullScreen}
                    open={openAgree}
                    onClose={() => setOpenAgree(false)}
                    aria-labelledby="responsive-dialog-title"
                >
                    <div style={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }} className='w-full h-full lg:h-auto flex lg:w-[40vw]'>
                        <div className='w-full flex flex-col'>
                            <DialogTitle textAlign={'center'} sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }} className='shadow-sm'>
                                <h6 className='text-[#373737] font-normal tracking-wider text-xl lg:text-2xl mb-4'>Terms & Conditions</h6>
                                <p className='text-center font-light text-sm text-[#373737]'>Welcome to go.Fastr.in!</p>
                            </DialogTitle>
                            <div className='absolute right-4 top-4'>
                                <IconButton onClick={() => setOpenAgree(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <DialogContent sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', px: 0, flex: 1, position: 'relative', display: 'flex', padding: 0.4 }}>
                                <iframe
                                    src={`${TERMS_AND_CONDITIONS}`}
                                    className='flex-1 lg:min-h-[50vh] lg:max-h-70vh h-full w-full flex justify-center'
                                />
                            </DialogContent>
                            <DialogActions sx={{ p: 2 }}>
                                <LoadingButton
                                    loading={isLoading}
                                    sx={{ padding: '.6vmax 0', boxShadow: 'none', width: 160 }}
                                    onClick={onSignUp}
                                    variant="contained"
                                    size="small"
                                >
                                    I AGREE
                                </LoadingButton>
                            </DialogActions>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}
