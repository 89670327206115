import React, { useState } from "react";
import { useThemeContext } from "../../Theme/ThemeContext";
import { H4, H5, H6, P } from "../../Theme/ThemeComponent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from '@mui/material/Button';
import moment from "moment";
import { Box, Divider } from "@mui/material";
import CompanyTable from "../../EquityComponents/CompanyTable";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

const MyAlgoReport = () => {
    const { theme } = useThemeContext();
    const navigate = useNavigate();
    const [totalRows, setTotalRows] = React.useState(100);
    const [loadingData, setLoadingData] = React.useState(true);
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [duration, setDuration] = React.useState('weekly');

    //  TABLE COLUMN 
    const columns = [
        {
            field: "ALgo Name", // Change field name here
            headerName: "ALgo Name",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: "type", // Change field name here
            headerName: "Type",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: "entry_d/t",
            headerName: "Entry Date/Time",
            minWidth: 180,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedDate = moment(params?.row?.entry_date).format('DD-MM-YYYY');
                const formattedTime = moment(params?.row?.entry_date).format('HH:mm:ss');
                return (
                    <div className="flex w-fit p-2 flex-col items-start justify-start gap-1">
                        <H6
                            title={formattedDate}
                            color={theme.palette.text.charcolGray}
                            font='normal'
                        />
                        <P
                            title={formattedTime}
                            color={theme.palette.text.silverGray}
                            font='bold'
                        />
                    </div>
                );
            },
        },
        {
            field: "exit_d/t",
            headerName: "Exit Date/Time",
            minWidth: 180,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedDate = moment(params?.row?.entry_date).format('DD-MM-YYYY');
                const formattedTime = moment(params?.row?.entry_date).format('HH:mm:ss');
                return (
                    <div className="flex w-fit p-2 flex-col items-start justify-start gap-1">
                        <H6
                            title={formattedDate}
                            color={theme.palette.text.charcolGray}
                            font='normal'
                        />
                        <P
                            title={formattedTime}
                            color={theme.palette.text.silverGray}
                            font='bold'
                        />
                    </div>
                );
            },
        },
        {
            field: "pnl", // Change field name here
            headerName: "P/L",
            renderHeader: () => (
                <Box textAlign="center">
                    <div className="flex w-fit items-center justify-center ">
                        <H6 title={'Position'} color={theme.palette.text.charcolGray} />
                        &nbsp;
                        <H6 title={'P'} color={theme.palette.text.green} />
                        <H6 title={'/'} />
                        <H6 title={'L'} color={theme.palette.text.red} />
                    </div>
                </Box>
            ),
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';
                const color = params?.value >= 0 ? 'green' : 'red';
                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },
        {
            field: "quantity", // Change field name here
            headerName: "Quantity",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: "roi", // Change field name here
            headerName: "ROI",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';
                const color = params?.value >= 0 ? 'green' : 'red';
                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },
        {
            field: "avg_beta", // Change field name here
            headerName: "Avg. Beta",
            type: "number",
            minWidth: 110,
            align: 'left',
            headerAlign: 'left',

        },
    ];

    // GO BACK TO THE PREVIOUS PAGE 
    const goBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div className="w-full flex-1 flex flex-col gap-4  p-4">
                <div style={{ background: theme.palette.background.white }} className="w-full flex-1 p-4 flex flex-col gap-6  border border-[#9E9E9E24]">
                    <div className="flex items-center gap-2 lg:gap-5">
                        <div className="flex items-center gap-3 lg:gap-4">
                            <ArrowBackIcon
                                sx={{ cursor: "pointer" }}
                                onClick={goBack}
                            />
                            <div className="flex items-center gap-1">
                                <H4
                                    title={'My Algo Report'}
                                    color={theme.palette.text.charcolGray}
                                    font="bold"
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-3 lg:gap-2">
                            <Button
                                variant={duration == 'daily' ? 'contained' : 'outlined'}
                                onClick={() => {
                                    setDuration('daily');
                                }}
                                size="small"
                            >
                                Daily
                            </Button>
                            <Button
                                variant={duration == 'weekly' ? 'contained' : 'outlined'}
                                onClick={() => {
                                    setDuration('weekly');
                                }}
                                size="small"
                            >
                                Weekly
                            </Button>
                            <Button
                                variant={duration == 'monthly' ? 'contained' : 'outlined'}
                                onClick={() => {
                                    setDuration('monthly');
                                }}
                                size="small"
                            >
                                Monthly
                            </Button>
                            <Button
                                variant={duration == 'quaterly' ? 'contained' : 'outlined'}
                                onClick={() => {
                                    setDuration('quaterly');
                                }}
                                size="small"
                            >

                                Quaterly
                            </Button>
                            <Button
                                variant={duration == 'yearly' ? 'contained' : 'outlined'}
                                onClick={() => {
                                    setDuration('yearly');
                                }}
                                size="small"
                            >
                                Yearly
                            </Button>
                        </div>
                        <FormControl size="small" sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel id="demo-multiple-checkbox-label">My Algo</InputLabel>
                            <Select
                                size="small"
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={[]}
                                // onChange={handleChange}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {names.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox />
                                        {/* <Checkbox checked={personName.indexOf(name) > -1} /> */}
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="w-full flex items-center justify-between p-4 lg:p-6 ">
                        <div className="flex flex-col items-center gap-2">
                            <H5
                                title={'ROI'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'80%'}
                                color={theme.palette.text.green}
                            />
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className="flex flex-col items-center gap-2">
                            <H5
                                title={'Closed Trades'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'04'}
                                color={theme.palette.text.charcolGray}
                            />
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className="flex flex-col items-center gap-2">
                            <H5
                                title={'Open Long Trades'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'05'}
                                color={theme.palette.text.charcolGray}
                            />
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className="flex flex-col items-center gap-2">
                            <H5
                                title={'Open Short Trades'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'06'}
                                color={theme.palette.text.charcolGray}
                            />
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className="flex flex-col items-center gap-2">
                            <H5
                                title={'Total Profit'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'10,000'}
                                color={theme.palette.text.green}
                            />
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className="flex flex-col items-center gap-2">
                            <H5
                                title={'Total Loss'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'10,000'}
                                color={theme.palette.text.red}
                            />
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className="flex flex-col items-center gap-2">
                            <H5
                                title={'Net Profit'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'10,000'}
                                color={theme.palette.text.green}
                            />
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className="flex flex-col items-center gap-2">
                            <H5
                                title={'Top Profit'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'10,000'}
                                color={theme.palette.text.green}
                            />
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className="flex flex-col items-center gap-2">
                            <H5
                                title={'Top Loss'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'10,000'}
                                color={theme.palette.text.red}
                            />
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className="flex flex-col items-center gap-2">
                            <H5
                                title={'Avg. Beta'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'0.93'}
                                color={theme.palette.text.charcolGray}
                            />
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className="flex flex-col items-center gap-2">
                            <H5
                                title={'Nifty Phase'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'Bearish'}
                                color={theme.palette.text.charcolGray}
                            />
                        </div>
                    </div>
                    <div className="w-full flex-1 flex-col flex">
                        <CompanyTable
                            columns={columns}
                            rows={[]}
                            isPagination={true}
                            setLimit={setLimit}
                            limit={limit}
                            skip={skip}
                            setSkip={setSkip}
                            totalRows={totalRows}
                            onRowClickFunction={() => { }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyAlgoReport;