import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemButton, } from "@mui/material"
import { useLocation, useNavigate } from 'react-router-dom';
import { useDimensions } from "../Contexts/DimensionContext/DimensionsContext";

const selectedConditionButton = {
    width: "100%",
    borderBottom: '3px solid  #1C1C1C',
    padding: '16px 24px'
}

const notSelectedButton = {
    width: '100%',
    borderBottom: '1px solid  #1C1C1C',
    padding: '16px 24px'
}

const childOptions = [
    { name: 'Entry conditions', route: 'entry-conditions' },
    { name: 'Exit conditions', route: 'exit-conditions' },
    { name: 'Trailing conditions', route: 'trailling-conditions' },
]

const rowText = 'text-[#373737] font-semibold text-md';


const ActionChildSelect = (props) => {
    const location = useLocation();
    const childRoute = location.pathname.split('/')[6];
    const navigate = useNavigate();
    const { xs, sm, md, lg, xl, xxl } = useDimensions()

    const navigationPath = '/admin/options/tools/actions';

    return (
        <>
            <div className=' w-full px-3 flex justify-end'>

                <List sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'
                }}
                >
                    <div className='border-l  border-[#0000003B]'>
                        {childOptions?.map((el, idx) => (
                            <div key={idx} className='flex items-center'>
                                <span className='w-4 mr-2 border-t border-[#0000003B]'></span>
                                <ListItem sx={{ overflow: 'hidden' }} disablePadding>
                                    <ListItemButton
                                        sx={childRoute === el?.route ? selectedConditionButton : notSelectedButton}
                                        onClick={() => {
                                            navigate(`${navigationPath}/${el?.route}`);
                                            if (xs || sm || md) {
                                                props?.onClose();
                                            }
                                        }}
                                    >
                                        <h6 className={rowText}>{el?.name}</h6>
                                    </ListItemButton>
                                </ListItem>
                            </div>
                        ))}
                    </div>
                </List >
            </div >
        </>
    )
}

export default ActionChildSelect
