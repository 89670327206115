import * as React from "react";
import AdminHeader from "../../PrivateComponents/AdminHeader";
import Achive from "../../assets/Achive.png";
import StaticsCard from "../../PrivateComponents/StaticsCard";
import { DashboardGroup, TotalInvestment, TotalROI, InfoIcon, TotalPL } from "../../PublicComponents/Icons";
import LineGraph from "../../PrivateComponents/LineGraph";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { http } from '../../Services/api.services'
import { BASE_URL } from "../../default.config";
import { Button, Skeleton } from "@mui/material";
import { useNavigate } from "react-router";
import FutureRelease from "../../PublicComponents/FutureRelease";

const AdminScreen = () => {
	const navigate = useNavigate()

	const [heapHeight, setHeapHeight] = React.useState(0);
	const [screenWidth, setScreenWidth] = React.useState(0);
	const [popularHeight, setPopularHeight] = React.useState(0);
	const [pAlgoHeight, setPAlgoHeight] = React.useState(0);
	const [totalSubscribers, setTotalSubscribers] = React.useState(0)
	const [totlFreeTrial, setTotlFreeTrial] = React.useState(0)
	const [totalRevenue, setTotalRevenue] = React.useState(0)
	const [popularSubscription, setPopularSubscription] = React.useState(null)
	const [lineGraph, setLineGraph] = React.useState({
		subs: [],
		free: [],
		revenue: []
	})

	const dash_cards = {
		subscription: React.useRef(null),
		trials: React.useRef(null),
		revenue: React.useRef(null)
	}

	// SELECT COMPONENT

	const [age, setAge] = React.useState('');

	const handleChange = (event) => {
		setAge(event.target.value);
	};

	// SELECT COMPONENT
	const heapParentdiv = React.useRef(null);
	const popularParentdiv = React.useRef(null);
	const pAlgo = React.useRef(null);

	const updateScreenWidth = () => {
		setScreenWidth(window.innerWidth);
	};

	React.useEffect(() => {
		setHeapHeight(heapParentdiv?.current?.offsetHeight);
		setPopularHeight(popularParentdiv?.current?.offsetHeight);
		setPAlgoHeight(pAlgo?.current?.offsetHeight);
	}, [heapParentdiv]);

	React.useEffect(() => {
		getDashboardDetails();
	}, [])



	const getDashboardDetails = async () => {
		try {
			const res = await http.get(`${BASE_URL}admin/dashboard/total`)
			setTotalSubscribers(res?.data?.subs)
			setTotalRevenue(res?.data?.revenue)
			setTotlFreeTrial(res?.data?.free)
			setPopularSubscription(res?.data?.popular_subscription)
			setLineGraph({
				free: res?.data?.freeStat,
				subs: res?.data?.subStat,
				revenue: res?.data?.revenueStat
			})
		} catch (e) {
			console.log(e);
		}
	}

	React.useEffect(() => {
		updateScreenWidth();
		window.addEventListener("resize", updateScreenWidth);
		return () => {
			window.removeEventListener("resize", updateScreenWidth);
		};
	}, []);



	return (
		<>
			<div className="w-full flex flex-col px-4">
				<AdminHeader text="Dashboard" />
				<div className="w-full">
					{/* STATICS AND POPULAR SUBSCRIPTION */}
					<div className="flex flex-col gap-4 lg:flex-row py-2">
						<div className="flex flex-2 lg:w-1/3">
							<div className="flex justify-between w-full p-4 bg-[#FBFBFB] rounded shadow border border-[#EAEAEA] relative">
								<div className="flex flex-col items-start gap-2">
									<h6 className="text-[#5C5C5C] text-sm lg:text-lg">
										Popular Subscription
									</h6>
									<div className="flex flex-col items-start gap-4">
										{popularSubscription ?
											<div>
												<h4 className="text-[#393939] font-semibold text-xs lg:text-sm">
													{popularSubscription?.name} Plan
												</h4>
												<p className="text-[#247CFF]  text-xs lg:text-sm">
													{popularSubscription?.price} ₹
												</p>
											</div> :
											<div className="flex flex-col">
												<Skeleton
													variant="text"
													height={20}
													width={100}
												/>
												<Skeleton
													variant="text"
													height={20}
													width={80}
												/>
											</div>
										}
										<Button
											size="small"
											variant="contained"
											color="primary"
											className="!capitalize"
											sx={{
												boxShadow: 'none',
												borderRadius: .3,
											}}
											onClick={() => navigate('/admin/subscriptions')}
										>
											View Plan
										</Button>
									</div>
								</div>
								<img className="h-16 absolute top-0 right-6" src={Achive} alt="Achive" />
							</div>
						</div>
						<div
							ref={popularParentdiv}
							className={`flex flex-1 flex-col gap-5 py-2 lg:bg-[#FBFBFB] rounded lg:shadow lg:border border-[#EAEAEA] relative`}
						>
							<FutureRelease fontSize={12} message={'Coming Soon...'} />
							<div className="px-3">
								<h6 className="text-[#5C5C5C]  font-semibold text-[2vmax] lg:text-[1vmax] uppercase tracking-wider">
									Statistics
								</h6>
							</div>
							<div className="flex w-fit  flex-wrap gap-3 lg:gap-10">
								<StaticsCard icon={<DashboardGroup />} heading='Total Users' count='--' />
								<StaticsCard icon={<TotalInvestment />} heading='Total Investment' count={'₹ 0' || '₹34,000'} />
								<StaticsCard icon={<TotalROI />} heading='Total ROI' count='0' />
								<StaticsCard icon={<TotalPL />} heading='Total P&L' count='0' />
							</div>
						</div>
					</div>
					<div className="flex flex-col gap-2 lg:gap-4 py-2 lg:flex-row lg:py-3">
						{/* TOTAL SUBSCRIPTION */}
						<div className="flex-2 lg:w-1/3 lg:flex flex-col">
							<div
								style={{
									height: screenWidth < 500 ? "fit-content" : pAlgoHeight / 2,
								}}
								className="flex  flex-col w-full items-start gap-2 lg:flex-row lg:gap-4 lg:pb-1"
							>
								<div ref={dash_cards.subscription} className="bg-[#FBFBFB] w-full h-full lg:w-2/4 px-3 py-2 rounded border border-[#EAEAEA]">
									<div className="flex w-full justify-between items-center gap-0 lg:gap-2">
										<h6 className="text-[#5C5C5C] uppercase text-xs">
											TOTAL subscriptions
										</h6>
										<InfoIcon />
									</div>
									<h6 className="text-[#393939] font-semibold text-sm lg:text-lg">
										{totalSubscribers}
									</h6>
									<div className="relative w-full">
										<LineGraph
											color='#07A704'
											width={dash_cards.subscription?.current?.offsetWidth - 30}
											data={lineGraph.subs}
										/>
									</div>
								</div>
								<div ref={dash_cards?.trials} className="bg-[#FBFBFB] w-full h-full lg:w-2/4 px-3 py-2 rounded border border-[#EAEAEA]">
									<div className="flex w-full justify-between items-center gap-0 lg:gap-2">
										<h6 className="text-[#5C5C5C] uppercase text-xs">
											TOTAL Free trials
										</h6>
										<InfoIcon />
									</div>
									<h6 className="text-[#393939] font-semibold text-sm lg:text-lg">
										{totlFreeTrial}
									</h6>
									<div className="relative w-full">
										<LineGraph
											color='#07A704'
											width={dash_cards.trials?.current?.offsetWidth - 30}
											data={lineGraph.free}
										/>
									</div>
								</div>
							</div>
							<div
								style={{
									height: screenWidth < 500 ? "fit-content" : pAlgoHeight / 2,
								}}
								className="pt-3 w-full"
							>
								<div ref={dash_cards.revenue} className="bg-[#FBFBFB] h-fit lg:h-full w-full lg:w-full px-3 py-2 rounded border border-[#EAEAEA]">
									<div className="flex w-full justify-between items-center gap-1 lg:gap-2">
										<h6 className="text-[#5C5C5C] uppercase text-xs">
											TOTAL REVENUE
										</h6>
										<InfoIcon />
									</div>
									<h6 className="text-[#393939] font-semibold text-sm lg:text-lg">
										{totalRevenue}
									</h6>
									<div className="relative w-full">
										<LineGraph
											color='#CE2A96'
											width={dash_cards.revenue?.current?.offsetWidth - 30}
											data={lineGraph.revenue}
										/>
									</div>
								</div>
							</div>
						</div>
						{/* POPULAR ALGO AND RECENT COMPLAINTS */}
						<div ref={pAlgo} className="flex flex-col gap-4 lg:flex-row flex-1 relative">
							<div className="bg-[#FBFBFB] w-full lg:w-1/2 flex flex-col items-center border border-[#EAEAEA] rounded shadow relative min-h-[320px]">
								<FutureRelease fontSize={14} message={'Coming Soon...'} />
								<div className="py-4 w-full px-2 lg:py-2">
									<h6 className="text-[#5C5C5C] whitespace-nowrap  font-semibold text-[1.5vmax] lg:text-[1vmax] uppercase tracking-wider">
										Popular ALGORITHM
									</h6>
								</div>
							</div>
							<div className="bg-[#FBFBFB] w-full lg:w-1/2 flex flex-col items-center border border-[#EAEAEA] rounded shadow relative min-h-[320px]">
								<FutureRelease fontSize={14} message={'Coming Soon...'} />
								<div className="py-4 px-2 w-full lg:py-2">
									<h6 className="text-[#5C5C5C] whitespace-nowrap  font-semibold text-[1.5vmax] lg:text-[1vmax] uppercase tracking-wider">
										RECENT COMPLAINTS
									</h6>
								</div>
								{/* <div className="max-w-[90vw] h-[45vh] lg:max-w-full overflow-hidden">
									<DashboardTable
										columns={complaintColumns}
										rows={complaintRows}
									/>
								</div> */}
							</div>
						</div>
					</div>
					<div className="flex  items-start flex-col gap-3 lg:gap-4 py-2 lg:flex-row lg:py-3 ">
						{/* top 10 watchlist */}
						<div
							ref={heapParentdiv}
							className="bg-[#FBFBFB] flex flex-col items-center border border-[#EAEAEA] rounded shadow w-full opacity-30 lg:w-3/5"
						>
							<div className="py-4 px-2 w-full lg:py-2">
								<h6 className="text-[#5C5C5C]  font-semibold text-[1.5vmax] lg:text-[1vmax] uppercase tracking-wider">
									Top 10 watchlist companies
								</h6>
							</div>
							<div className="max-w-[80vw] h-[30vh] lg:w-full overflow-hidden">
								{/* <DashboardTable columns={companyColumns} rows={companyRows} /> */}
							</div>
						</div>
						{/* HEATMAP */}
						<div
							style={{
								height: screenWidth < 500 ? "fit-content" : heapHeight,
							}}
							className="border border-[#EAEAEA] rounded shadow w-full lg:w-2/5  opacity-30"
						>
							<div className="flex flex-col-reverse items-end lg:flex-row lg:items-start justify-between gap-2 w-full px-3 py-3">
								<div className="flex w-full items-center justify-between gap-2">
									<div>
										<h6 className="text-[#8E8E8E] fotn-[Poppins] font-semibold text-[1.2vmax] whitespace-nowrap lg:text-[.7vmax]">
											Most traded hours
										</h6>
										<div className="w-12 h-4 bg-[#2608DC] rounded"></div>
									</div>
									<div>
										<h6 className="text-[#8E8E8E] fotn-[Poppins] font-semibold text-[1.2vmax] whitespace-nowrap lg:text-[.7vmax]">
											Less traded hours
										</h6>
										<div className="w-12 h-4 bg-[#2608DC66] rounded"></div>
									</div>
									<div>
										<h6 className="text-[#8E8E8E] fotn-[Poppins] font-semibold text-[1.2vmax] whitespace-nowrap lg:text-[.7vmax]">
											Very-Less traded hours
										</h6>
										<div className="w-12 h-4 bg-[#2608DC0F] rounded"></div>
									</div>
								</div>
								<div className="flex items-center gap-2">
									<div className="min-w-[25vw] hidden lg:min-w-[6vw]">
										<FormControl fullWidth size="small">
											<InputLabel sx={{ fontSize: '13px' }} id="demo-simple-select-label">Range</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={age}
												label="Age"
												onChange={handleChange}
												sx={{ fontSize: '10px' }}
											>
												<MenuItem value={10}>Ten</MenuItem>
												<MenuItem value={20}>Twenty</MenuItem>
												<MenuItem value={30}>Thirty</MenuItem>
											</Select>
										</FormControl>
									</div>
									<div>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="37"
											height="39"
											viewBox="0 0 37 39"
											fill="none"
										>
											<rect
												y="0.711426"
												width="37"
												height="38.2542"
												rx="6.93891"
												fill="#2A3B43"
											/>
											<path
												d="M26.7432 14.5918C26.7432 16.3132 25.3429 17.7136 23.6215 17.7136C21.9 17.7136 20.4997 16.3132 20.4997 14.5918C20.4997 12.8704 21.9 11.4701 23.6215 11.4701C25.3429 11.4701 26.7432 12.8704 26.7432 14.5918ZM24.9593 19.3101C24.5134 19.4261 24.0674 19.4974 23.6215 19.4974C22.3211 19.4951 21.0747 18.9775 20.1553 18.058C19.2358 17.1385 18.7182 15.8921 18.7158 14.5918C18.7158 13.2807 19.2332 12.0944 20.0537 11.2114C19.8919 11.013 19.6878 10.8532 19.4563 10.7436C19.2249 10.6341 18.9719 10.5775 18.7158 10.5781C17.7347 10.5781 16.932 11.3809 16.932 12.362V12.6206C14.283 13.4055 12.4723 15.8405 12.4723 18.6055V23.9571L10.6885 25.7409V26.6329H26.7432V25.7409L24.9593 23.9571V19.3101ZM18.7158 29.3087C19.7059 29.3087 20.4997 28.5148 20.4997 27.5248H16.932C16.932 27.9979 17.1199 28.4516 17.4545 28.7862C17.789 29.1207 18.2427 29.3087 18.7158 29.3087Z"
												fill="white"
											/>
										</svg>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminScreen;
