import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import FalureLogo from '../assets/failed.gif'

const Failure = () => {

    const navigate = useNavigate()
    const location = useLocation()

    React.useEffect(() => {
        if (location.hash) {
            setTimeout(() => {
                navigate('/')
            }, 5000);
        }
        else {
            setTimeout(() => {
                navigate('/index-options/strikes')
            }, 5000);
        }
    }, [location.hash])

    React.useEffect(() => {
    }, [])

    return (
        <div className="h-full w-full flex flex-col justify-center items-center bg-[#f6f6f6]">
            <img
                src={FalureLogo}
                height={200}
            />
            <div className="px-4 text-center">
                <h1 className="text-lg text-[#333]">We’re verifying your payment. Once done, our team will confirm your registration and contact you for the next steps</h1>
            </div>
        </div>
    )
}

export default Failure