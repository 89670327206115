import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';


const AllTransections = (props) => {
    const handleClick = props?.rowClick ? props?.rowClick : () => { };

    return (
        <Box sx={{ height: '100%', width: '100%' }} >
            <DataGrid
                rows={props.rows}
                columns={props.columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                sx={{
                    border: '1px solid #E0E0E0',
                    '& .css-yrdy0g-MuiDataGrid-columnHeaderRow': {
                        backgroundColor: 'transparent'
                    },
                    '& .css-1iyq7zh-MuiDataGrid-columnHeaders': {
                        backgroundColor: '#F6F6F6',
                    },
                    maxWidth: props?.currentWidth - 20
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
                onRowClick={(params) => handleClick(params)}
            />
        </Box>
    );
}


export default AllTransections