import React from "react";
import Menu from '@mui/material/Menu';
import { H5, H6, P } from "../Theme/ThemeComponent";
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import { Button, Divider, TextField } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { useThemeContext } from "../Theme/ThemeContext";
import DatePickerComponent from "./DatePickerComponent";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const _tradesValues = [
    { key: 'buy', label: 'Long' },
    { key: 'sell', label: 'Short' },
]

const CompanyFilter = ({ filterDate, setFilterDate, order_types, setOrder_types, onApplyFilter, isLoading, resetFilter }) => {
    const { theme } = useThemeContext();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);   
    };

    const handleClose = () => {
        setAnchorEl(null);
    };  

    const [tradeExpanded, setTradeExpanded] = React.useState(true);

    const summeryStyle = { padding: 0, minHeight: '0px' };
    const detailStyle = { padding: 1, marginTop: '-20px' }

    const AccordianHeading = ({ heading }) => (
        <P title={heading} font='bold' color={theme.palette.text.charcolGray} />
    );

    const AccordianCheckboxLabel = ({ label }) => (
        <P title={label} font='bold' color={theme.palette.text.charcolGray} />
    )

    return (
        <>
            <IconButton
                aria-label="Filter"
                size="small"
                onClick={handleClick}
            >
                <FilterAltIcon sx={{ color:theme.palette.icon.primary }} />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    maxWidth: 350
                }}
            >
                <div className="w-full  ">
                    <div className="w-full flex items-center justify-between py-2 px-2 lg:px-4">
                        <H6 title='Filter' color={theme.palette.text.charcolGray} />
                        <IconButton onClick={handleClose} aria-label="close" size="small">
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                    <Divider />
                    <div className="w-full py-4 max-h-[40vh] overflow-y-auto flex flex-col items-start gap-2 px-2 lg:px-4">
                        <div className="w-full">
                            <P title='Date' color={theme.palette.text.coolGray} />
                            <DatePickerComponent filterData={filterDate} setFilterData={setFilterDate} />
                        </div>
                        <div className="w-full">
                            <Accordion
                                expanded={tradeExpanded}
                                onChange={() => {
                                    setTradeExpanded((prevExpanded) => !prevExpanded);
                                }}
                                slots={{ transition: Fade }}
                                slotProps={{ transition: { timeout: 400 } }}
                                sx={{
                                    '& .MuiAccordion-region': { height: tradeExpanded ? 'auto' : 0 },
                                    '& .MuiAccordionDetails-root': { display: tradeExpanded ? 'block' : 'none' },
                                    boxShadow: 'none',
                                    backgroundColor: theme.palette.background.white,
                                    padding: 0,

                                }}
                                size='small'
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon fontSize="small" />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={summeryStyle}
                                >
                                    <AccordianHeading heading='Trade' />
                                </AccordionSummary>
                                <AccordionDetails sx={detailStyle}>
                                    <FormGroup size="small">
                                        {_tradesValues ? (
                                            _tradesValues.map((el, idx) => (
                                                <FormControlLabel
                                                    key={idx}
                                                    size="small"
                                                    control={
                                                        <Checkbox
                                                            onChange={() => {
                                                                setOrder_types((prevData) => {
                                                                    // Create a copy of the previous state to avoid direct mutation
                                                                    const updatedData = { ...prevData };
                                                                    // Toggle the value of order_types using bracket notation
                                                                    updatedData[el.key.toLowerCase()] = !updatedData[el.key.toLowerCase()];
                                                                    // Return the updated state object
                                                                    return updatedData;
                                                                });
                                                            }}
                                                            checked={order_types[el.key.toLowerCase()]} // Dynamically access the state
                                                            size="small"
                                                        />
                                                    }
                                                    label={<AccordianCheckboxLabel label={el.label} />}
                                                />
                                            ))
                                        ) : null}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                    <Divider />
                    <div className="w-full flex items-center justify-end gap-3 py-4 px-2 lg:px-4">
                        <Button
                            variant="outlined"
                            onClick={() => {
                                resetFilter();
                                handleClose();
                            }}
                            autoFocus
                            size="small"
                        >
                            Reset
                        </Button>
                        <LoadingButton
                            sx={{ boxShadow: "none" }}
                            // disabled={disable}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                onApplyFilter();
                            }}
                            loading={isLoading}
                        >
                            Apply
                        </LoadingButton>
                    </div>
                </div>
            </Menu>
        </>
    )
}

export default CompanyFilter;