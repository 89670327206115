import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allBaskets: null,
  allLegs: null,
  currentBasket: null,
  currentPosition: null,
  currentHistory: null,
  currentSelectedOrder: null,
  basketStatus: "notSelected",
  currentBasketFormData: {},
  currentBasketTab: 0,
  basketPositions: null,
  basketHistory: null,
  margins: null,
  modifyFormData: null,
};

const BasketReducer = createSlice({
  name: "basket",
  initialState,
  reducers: {
    updateAllBaskets: (state, action) => {
      state.allBaskets = action.payload;
    },
    updateAllBasketsLegs: (state, action) => {
      state.allLegs = action.payload;
    },
    updateCurrentBasket: (state, action) => {
      state.currentBasket = action.payload;
    },
    updateBasketStatus: (state, action) => {
      state.basketStatus = action.payload;
    },
    updateCurrentBasketFormData: (state, action) => {
      const { field, value } = action.payload;
      if (field.includes(".")) {
        const fields = field.split(".");
        state.currentBasketFormData[fields[0]][fields[1]] = value;
      } else {
        state.currentBasketFormData[field] = value;
      }
    },
    updateAllCurrentBasketFormFileds: (state, action) => {
      state.currentBasketFormData = action.payload;
    },
    updateCurrentBasketTab: (state, action) => {
      state.currentBasketTab = action.payload;
    },
    setBasketPositions: (state, action) => {
      state.basketPositions = action.payload;
    },
    updateCurrentPosition: (state, action) => {
      state.currentPosition = action.payload;
    },
    updateCurrentHistory: (state, action) => {
      state.currentHistory = action.payload;
    },
    updateCurrentSelectedOrder: (state, action) => {
      state.currentSelectedOrder = action.payload;
    },
    updateBaskethistory: (state, action) => {
      state.basketHistory = action.payload;
    },
    updateMargins: (state, action) => {
      state.margins = action?.payload;
    },
    updateModifyFormData: (state, action) => {
      state.modifyFormData = action?.payload;
    },
  },
});

export const {
  updateAllBaskets,
  updateAllBasketsLegs,
  updateCurrentBasket,
  updateBasketStatus,
  updateCurrentBasketFormData,
  updateAllCurrentBasketFormFileds,
  updateCurrentBasketTab, 
  setBasketPositions,
  updateCurrentPosition,
  updateBaskethistory,
  updateMargins,
  updateCurrentHistory,
  updateCurrentSelectedOrder,
  updateModifyFormData,
} = BasketReducer.actions;

export default BasketReducer.reducer;
