import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { http } from "../Services/api.services";
import { updatePrivateOrders } from "../store/Reducer/Reducer";
import LoadingButton from "@mui/lab/LoadingButton";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

const _txnType = ["Buy", "Sell"];
const _indexType = [
  "Nifty",
  "BankNifty",
  "FinNifty",
  "MidcpNifty",
  "Sensex",
  "Bankex",
];
const _orderType = ["Limit", "Market"];

export default function OrderFilterComponent({ open, onCloseFilter, setFCount, fCount, resetFilterFunction }) {
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state.admin);
  const user = useSelector((state) => state.user);
  const [selectedIndex, setSelectedIndex] = React.useState([]);
  const [selectedTxnType, setSelectedTxnType] = React.useState([]);
  const [selectedOrderType, setSelectedOrderType] = React.useState([]);
  const [quantity, setQuantity] = React.useState(null);
  const [price, setPrice] = React.useState(null);
  const [disable, setdisable] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({
    startDate: "",
    endDate: "",
  });

  const resetFilter = async () => {
    setPrice(null);
    setQuantity(null);
    setDateRange({
      startDate: "",
      endDate: "",
    });
    setSelectedIndex([]);
    setSelectedTxnType([]);
    setSelectedOrderType([]);
    onCloseFilter();
    resetFilterFunction();
  };

  const handleIndexChange = (event) => {
    // setSelectedIndex(event.target.value);
    const {
      target: { value },
    } = event;
    setSelectedIndex(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleTxnChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTxnType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleOrderChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOrderType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleDateChange = (e, _type) => {
    const { value } = e.target;
    setDateRange((prevState) => ({
      ...prevState,
      [_type]: value,
    }));
  };

  React.useEffect(() => {
    if (
      price ||
      quantity ||
      selectedTxnType?.length > 0 ||
      selectedOrderType?.length > 0 ||
      selectedIndex
    ) {
      setdisable(false);
    }
  }, [price, quantity, selectedOrderType, selectedTxnType, selectedIndex]);

  const FilterOrders = async (userId) => {
    setIsLoading(true); 
    try {
      let url;
      url = admin ? `/orders/private/${userId}` : `/orders/${userId}`;
      const params = new URLSearchParams();

      // Initialize filter count
      let filterCount = 0;

      if (quantity) {
        params.append("quantity", quantity);
        filterCount++;  // Increment filter count
      }
      if (price) {
        params.append("price", price);
        filterCount++;
      }
      if (selectedOrderType?.length > 0) {
        params.append("order_type", selectedOrderType);
        filterCount++;
      }
      if (selectedTxnType?.length > 0) {
        params.append("transaction_type", selectedTxnType);
        filterCount++;
      }
      if (selectedIndex?.length > 0) {
        params.append("index", selectedIndex);
        filterCount++;
      }

      // Check date range (startDate and endDate)
      if (dateRange?.startDate || dateRange?.endDate) {
        if (dateRange?.startDate) {
          params.append("from_date", dateRange?.startDate);
        }
        if (dateRange?.endDate) {
          params.append("to_date", dateRange?.endDate);
        }
        // Increment filter count only once for date range
        filterCount++;       
      }

      setFCount(filterCount); 

      // Append params to the URL
      url += `?${params.toString()}`;
      const response = await http.get(url);
      setIsLoading(false);

      if (response?.status == 200) {
        // Dispatch the response data and close the filter modal
        dispatch(updatePrivateOrders(response?.data));
        onCloseFilter();

        // Log or use the filter count (e.g., show in the UI)
        console.log(`Filters applied: ${filterCount}`);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(`ERROR WHILE FILTERING ORDERS ${e}`);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseFilter}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Filter"}</DialogTitle>
      <DialogContent sx={{ maxWidth: 500, width: 500, height: 450 }}>
        <div className="w-full flex flex-col items-start gap-6 ">
          <div className="w-full">
            <h6 className="text-[#666666] text-sm py-2 lg:text-base font-semibold">
              Date & Time
            </h6>
            <div className="flex w-full items-center gap-2">
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                // type="date"
                type="datetime-local"
                value={dateRange?.startDate}
                fullWidth
                onChange={(e) => handleDateChange(e, "startDate")}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              />
              <span>-</span>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                type="datetime-local"
                value={dateRange?.endDate}
                fullWidth
                onChange={(e) => handleDateChange(e, "endDate")}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              />
            </div>
          </div>
          <div className="w-full flex gap-4 items-center ">
            <div className="w-1/2">
              <h6 className="text-[#666666] text-sm py-2 lg:text-base font-semibold">
                Quantity
              </h6>
              <div>
                <TextField
                  placeholder="1"
                  size="small"
                  variant="outlined"
                  value={quantity}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000",
                    borderRadius: "5px",
                    "& .MuiInputBase-input": {
                      textAlign: "center",
                    },
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(value)) {
                      setQuantity(value);
                    }
                  }}
                  InputProps={{
                    style: { textAlign: "center" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => {
                            if (quantity > 0) {
                              setQuantity(quantity - 1);
                            }
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setQuantity(quantity + 1);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="w-1/2">
              <h6 className="text-[#666666] text-sm py-2 lg:text-base font-semibold">
                Price
              </h6>
              <div className="">
                <TextField
                  placeholder="1"
                  size="small"
                  variant="outlined"
                  sx={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000",
                    borderRadius: "5px",
                    "& .MuiInputBase-input": {
                      textAlign: "center",
                    },
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(value)) {
                      setPrice(e.target.value);
                    }
                  }}
                  value={price}
                  InputProps={{
                    style: { textAlign: "center" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => {
                            if (quantity > 0) {
                              setPrice(price - 1);
                            }
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setPrice(price + 1);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex gap-4 items-center ">
            <div className="w-1/2">
              <h6 className="text-[#666666] text-sm py-2 lg:text-base font-semibold">
                Txn Type
              </h6>
              <FormControl fullWidth>
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedTxnType}
                  onChange={handleTxnChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  size="small"
                  displayEmpty
                >
                  {_txnType.map((txn) => (
                    <MenuItem key={txn} value={txn}>
                      <Checkbox checked={selectedTxnType.indexOf(txn) > -1} />
                      <ListItemText primary={txn} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-1/2">
              <h6 className="text-[#666666] text-sm py-2 lg:text-base font-semibold">
                Order Type
              </h6>
              <FormControl fullWidth>
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedOrderType}
                  onChange={handleOrderChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  size="small"
                  placeholder="Order Type"
                >
                  {_orderType.map((order) => (
                    <MenuItem key={order} value={order}>
                      <Checkbox
                        checked={selectedOrderType.indexOf(order) > -1}
                      />
                      <ListItemText primary={order} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="w-full flex gap-4 items-center ">
            <div className="w-1/2">
              <h6 className="text-[#666666] text-sm py-2 lg:text-base font-semibold">
                Indexes
              </h6>
              <FormControl fullWidth>
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedIndex}
                  onChange={handleIndexChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  size="small"
                  displayEmpty
                >
                  {_indexType.map((index) => (
                    <MenuItem key={index} value={index}>
                      <Checkbox checked={selectedIndex.indexOf(index) > -1} />
                      <ListItemText primary={index} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          sx={{ boxShadow: "none" }}
          disabled={disable}
          variant="contained"
          onClick={() => {
            FilterOrders(admin?.id);
          }}
          loading={isLoading}
        >
          Apply
        </LoadingButton>
        <Button
          sx={{
            backgroundColor: "#F0F0F0",
            boxShadow: "none",
            color: "#373737",
            "&:hover": {
              backgroundColor: "#F0F0F0",
            },
          }}
          variant="contained"
          onClick={() => {
            resetFilter();
          }}
          autoFocus
        >
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  );
}
