import * as React from 'react'
import AdminHeader from '../../PrivateComponents/AdminHeader'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ComplaintCard from '../../PrivateComponents/ComplaintCard';
import FilterDrawer from '../../PrivateComponents/FilterDrawer';
import ComplainDrawer from '../../PrivateComponents/ComplaintDrawer'
import AdminTable from '../../PrivateComponents/AdminTable';
import { http } from '../../Services/api.services';
import { BASE_URL } from '../../default.config';



const HeadCell = ({ title }) => (
    <h6 className='text-[1.5vmax]  lg:text-[.8vmax] text-[#000000]  font-semibold tracking-wider'>
        {title}
    </h6>
)

const columns = [

    {
        id: 'name',
        label: <HeadCell title="Name" />,
        minWidth: 110, maxWidth: 200,
        align: 'left'
    },
    {
        id: 'email',
        label: <HeadCell title="Mobile" />,
        minWidth: 130,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'type',
        label: <HeadCell title="Type" />,
        minWidth: 130,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'message',
        label: <HeadCell title="Message" />,
        minWidth: 200,
        align: 'center',
        format: (value) => value.toFixed(2),
    },

    {
        id: 'createdat',
        label: <HeadCell title="Date" />,
        minWidth: 170,
        align: 'center',
        type: 'date',
        format: (value) => value.toFixed(2),
    },


];


function samePageLinkNavigation(event) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                // Routing libraries handle this, you can remove the onClick handle when using them.
                if (samePageLinkNavigation(event)) {
                    event.preventDefault();
                }
            }}
            {...props}
        />
    );
}

const Complaints = () => {
    const [value, setValue] = React.useState(0);
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const [feedbacks, setFeedbacks] = React.useState([])
    const [tableHeight, seTableHeight] = React.useState(null)
    const [totalSuggestions, setTotalSuggestions] = React.useState(0)
    const tc = React.useRef(null);

    const handleChange = (event, newValue) => {
        // event.type can be equal to focus with selectionFollowsFocus.
        if (
            event.type !== 'click' ||
            (event.type === 'click' && samePageLinkNavigation(event))
        ) {
            setValue(newValue);
        }
    };

    const [openDrawer, setOpenDrawer] = React.useState(false)

    const handleDrawerClick = () => {
        setOpenDrawer(true)
    }

    React.useEffect(() => {
        if (tc?.current) {
            seTableHeight(tc?.current?.offsetHeight)
        }
        getFeedbacks();
    }, []);

    const getFeedbacks = async () => {
        try {
            const url = `${BASE_URL}admin/feedback`
            const res = await http.get(url);
            setFeedbacks(res?.data)
            setTotalSuggestions(res?.data?.length)
        } catch (e) {
            console.log(e);
        }
    }



    return (
        <>
            <div className='w-full flex-1 flex flex-col  bg-[#fff] px-3'>
                <AdminHeader
                    text='Connects'
                    onFormChange={handleDrawerClick}
                    totalPlans={totalSuggestions}
                />
                {/* <div className='py-2'>
                    <Box size='small' sx={{ width: '100%' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="nav tabs example" size='small'>
                            <LinkTab sx={{ color: value === 0 ? '#1C1C1C' : '#AAAAAA' }} label={<h6 className='  text-[1.7vmax] lg:text-[.9vmax] capitalize'>Pending</h6>} href="/drafts" />
                            <LinkTab sx={{ color: value === 1 ? '#1C1C1C' : '#AAAAAA' }} label={<h6 className='  text-[1.7vmax] lg:text-[.9vmax] capitalize'>Resolved</h6>} href="/trash" />
                        </Tabs>
                    </Box>
                </div> */}
                <div ref={tc} className='flex  cursor-pointer flex-wrap flex-1 mb-2 max-w-[91vw] lg:max-w-full items-start justify-center gap-5  rounded'>
                    <AdminTable
                        height={tableHeight}
                        rows={feedbacks}
                        columns={columns}
                        // click={(id) => {
                        //     setuserId(id);
                        // }}
                        pagination={true}
                    // onUpdate={(id, active) => updateUser(id, active)}
                    />
                </div>
            </div>

            <FilterDrawer
                // filterObject={filterObject}
                // selectedRange={selectedRange}
                // setSelectedRange={setSelectedRange}
                openDrawer={openDrawer}
                data={[]}
            // onFilterChange={(value) => onFilterChange(value)}
            // onFormChange={() => setOpenDrawer(false)}
            // searchLocation={(value) => searchLocation(value)}
            // onResetData={() => {
            //     setFilterObject(filterSearchData);
            //     fetchUsers();
            //     setOpenDrawer(false)
            // }}
            />

            {/* <ComplainDrawer openDrawer={openDrawer} onFormChange={() => setOpenDrawer(false)} /> */}
        </>
    )
}

export default Complaints