import { createSlice } from "@reduxjs/toolkit";
const smoothAvailableFeatures = [
    'Advanced options Chain',
    'Data analytics',
    'Equilibrium Price',
    // 'OI ratio',
    'Buy Large quantity without any bucket order',
    'Volume analysis',
    'Stoploss using mutiple options',
    'Track Live Positions',
    'Track Your Performance'
]

const smoothNotAvailableFeatures = [
    'Auto Trailling Stoploss',
    'Speed buy & sell',
    'Advanced Stoploss setting',
    'Advanced target price setting ',
    'market sentimental indicator',
    'Short Covering indicator',
    'Profit booking indicator',
    'Long & Short buildup indicator',
    'Advance market data'
]
const plans = [
    {
        title: 'Smooth',
        tenure: [28, 12 * 28],
        cycle: [1, 12],
        amount: [2999, 2999 * 12],
        trial: 7,
        includes: smoothAvailableFeatures,
        excludes: smoothNotAvailableFeatures,
        features: [1, 2, 3, 4, 5, 6, 7, 8],
        disable: false,
        color: '#00B386',
        msg: 'For part-time traders with low capital',
        id: '1a010d2f-2470-47bf-a513-e26b28574a07'
    },
    {
        title: 'Speed',
        tenure: [28, 12 * 28],
        cycle: [1, 12],
        amount: [4999, 4999 * 12],
        trial: 7,
        includes: [...smoothAvailableFeatures, ...smoothNotAvailableFeatures],
        excludes: [],
        features: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        disable: true,
        msg: 'For full time traders with capital <b> ₹2.5L+</b>',
        color: '#F0AD00',
        id: '1a010d2f-2470-47bf-a513-e26b28574a07-2'
    }
]

const initialState = {
    plans
}

const PlanReducer = (state = initialState, action) => {
    return action.payload.payload
}

export const Reducer = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        updatePlans: PlanReducer
    },
})

export const { updatePlans } = Reducer.actions
export default Reducer.reducer  