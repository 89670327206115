import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation } from "react-router-dom";
import { Link, Outlet, useNavigate } from "react-router-dom";
import HeaderLogo from "../assets/logo 2 (2) 1.png";
import Tooltip from "@mui/material/Tooltip";
import {
	DakstopDashboard,
	Algorithms,
	Subscriptions,
	Users,
	Notifications,
	Feedback,
	Transection,
	Settings,
	Revenue,
} from "../PublicComponents/Icons";
import { Button } from "@mui/material";
import { ArticleOutlined, ExitToAppOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import ListItem from "@mui/material/ListItem";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from '@mui/material/useMediaQuery';

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 7px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DesktopDrawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

const options = [
	{
		label: "Dashboard",
		component: DakstopDashboard,
		route: "/admin/dashboard",
	},
	{
		label: "Algorithms",
		component: Algorithms,
		route: "/admin/algorithms",
	},
	{
		label: "Subscriptions",
		component: Subscriptions,
		route: "/admin/subscriptions",
	},
	{
		label: "Users",
		component: Users,
		route: "/admin/users",
	},
	{
		label: "Notifications",
		component: Notifications,
		route: "/admin/notifications",
	},
	{
		label: "Feedback & Complaints",
		component: Feedback,
		route: "/admin/feedback",
	},
	{
		label: "Orders",
		component: Transection,
		route: "/admin/orders",
	},
	{
		label: "Revenue",
		component: Revenue,
		route: "/admin/revenue",
	},
	{
		label: "News",
		component: ArticleOutlined,
		route: "/admin/news",
	},
	{
		label: "Settings",
		component: Settings,
		route: "/admin/settings",
	},
];


export default function AdminDekstopLayout(props) {
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	const navigate = useNavigate();
	const pathLocation = useLocation();
	const pathname = pathLocation.pathname;
	const routes = pathname.split("/");
	const route = routes[2];

	const theme = useTheme();
	const [open, setOpen] = React.useState(true);

	const { admin } = useSelector((state) => state.admin)

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};


	const [anchorEl, setAnchorEl] = React.useState(null);
	const openMenu = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};



	// Mobile Drawer 
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const MobileOptions = [
		{
			label: "Dashboard",
			component: (
				<DakstopDashboard color={route === "dashboard" ? "white" : "black"} />
			),
			route: "/admin/dashboard",
		},
		{
			label: "Algorithms",
			component: (
				<Algorithms color={route === "algorithms" ? "white" : "black"} />
			),
			route: "/admin/algorithms",
		},
		{
			label: "Subscriptions",
			component: (
				<Subscriptions color={route === "subscriptions" ? "white" : "black"} />
			),
			route: "/admin/subscriptions",
		},
		{
			label: "Users",
			component: <Users color={route === "users" ? "white" : "black"} />,
			route: "/admin/users",
		},
		{
			label: "Notifications",
			component: (
				<Notifications color={route === "notifications" ? "white" : "black"} />
			),
			route: "/admin/notifications",
		},
		{
			label: "Feedback & Complaints",
			// component: <Feedback color={route === "feedback" ? "white" : "black"} />,
			component: (
				<Feedback color='black' />
			),
			route: "/admin/feedback",
		},
		{
			label: "Orders",
			component: (
				<Transection color={route === "orders" ? "white" : "black"} />
			),
			route: "/admin/orders",
		},
		{
			label: "Revenue",
			component: (
				<Revenue color={route === "revenue" ? "white" : "black"} />
			),
			route: "/admin/revenue",
		},
		{
			label: "News",
			component: (
				<ArticleOutlined color={route === "news" ? "white" : "black"} />
			),
			route: "/admin/news",
		},
		{
			label: "Settings",
			component: <Settings color={route === "settings" ? "white" : "black"} />,
			route: "/admin/settings",
		},
	];

	const drawer = (
		<div className="flex flex-col h-full">
			<Toolbar />
			{/* <Divider /> */}
			<List>
				{MobileOptions?.map((el, idx) => {
					const splittedText = el.label.split(" & ")
					const text = splittedText[0]
					return (
						<Link
							to={el.route}
							key={idx}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<ListItem key={idx} disablePadding>
								<ListItemButton className="w-full flex items-center gap-4">
									<div
										style={{
											backgroundColor:
												route === `${text.toLocaleLowerCase()}`
													? "black"
													: "",
										}}
										className="flex items-center gap-3 w-full py-3 px-3 rounded"
									>
										{el.component}
										<h6
											style={{
												color:
													route === `${text.toLocaleLowerCase()}`
														? "#FFFFFF"
														: "#1C1C1C",
											}}
											className="font-medium  whitespace-nowrap text-[1.7vmax]"
										>
											{el.label}
										</h6>
									</div>
								</ListItemButton>
							</ListItem>
						</Link>
					)
				})}
			</List>
			<div className="flex-1"></div>
			<div className="py-4 px-4 w-full flex justify-center">
				<Button fullWidth className="flex gap-2" color="error" onClick={() => props?.logout()}>
					<ExitToAppOutlined className="rotate-180" color='error' />
					<span>LOGOUT</span>
				</Button>

			</div>
		</div>
	);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const container = window !== undefined ? () => window().document.body : undefined;




	return (
		<Box sx={{ display: 'flex', height: '100%' }}>
			{/* Dekstop Drawer  */}
			{isMobile ?
				<Box sx={{ display: 'flex', height: '100%' }}>
					{/* Mobile Drawer  */}
					<CssBaseline />
					<AppBar
						position="fixed"
						sx={{
							width: { sm: `calc(100% - ${drawerWidth}px)` },
							ml: { sm: `${drawerWidth}px` },
							boxShadow: "initial",
							border: "none",
							zIndex: '99'
						}}
					>
						<Toolbar
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								padding: "0 1vmax",
								border: 'none',

							}}
							className="!bg-white"
						>
							<h1 className="text-[#000000] font-semibold text-[2vmax] uppercase tracking-wider">
								{route}
							</h1>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="start"
								onClick={handleDrawerToggle}
								sx={{ display: { sm: "none" } }}
							>

								<MenuIcon sx={{ color: "#1C1C1C" }} />
							</IconButton>
						</Toolbar>
					</AppBar>
					<Box
						component="nav"
						sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
						aria-label="mailbox folders"
					>
						{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
						<Drawer
							container={container}
							variant="temporary"
							open={mobileOpen}
							onClose={handleDrawerToggle}
							anchor="right"
							ModalProps={{
								keepMounted: true, // Better open performance on mobile.
							}}
							BackdropProps={{ invisible: true }}
							sx={{
								display: { xs: "block", sm: "none" },
								"& .MuiDrawer-paper": {
									boxSizing: "border-box",
									width: drawerWidth,
								},
								zIndex: '999'
							}}
						>
							<div className="flex items-center justify-start w-full py-3 px-4">
								<IconButton onClick={handleDrawerToggle}>
									<svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 8 14" fill="none">
										<path fillRule="evenodd" clipRule="evenodd" d="M7.41926 8.02668C7.60673 7.83915 7.71204 7.58484 7.71204 7.31968C7.71204 7.05451 7.60673 6.80021 7.41926 6.61268L1.76226 0.955679C1.67001 0.860169 1.55967 0.783987 1.43766 0.731578C1.31566 0.679169 1.18444 0.651582 1.05166 0.650428C0.918879 0.649275 0.7872 0.674577 0.664303 0.724857C0.541407 0.775138 0.429754 0.849391 0.335862 0.943284C0.241969 1.03718 0.167716 1.14883 0.117435 1.27172C0.0671542 1.39462 0.0418527 1.5263 0.0430065 1.65908C0.0441603 1.79186 0.0717466 1.92308 0.124156 2.04508C0.176565 2.16709 0.252746 2.27743 0.348257 2.36968L5.29826 7.31968L0.348256 12.2697C0.166098 12.4583 0.0653037 12.7109 0.0675822 12.9731C0.0698606 13.2353 0.175029 13.4861 0.360437 13.6715C0.545846 13.8569 0.796658 13.9621 1.05886 13.9644C1.32105 13.9666 1.57365 13.8658 1.76226 13.6837L7.41926 8.02668Z" fill="#1C1C1C" fillOpacity="0.6" />
									</svg>
								</IconButton>
							</div>
							{drawer}
						</Drawer>
					</Box>
				</Box>
				:
				<Box sx={{ display: 'flex', height: '100%' }}>
					{/* Dekstop Drawer  */}

					<AppBar
						position="fixed"
						open={open}
						className="!bg-white "
						sx={{
							backgroundColor: 'white',
							boxShadow: "none",
							// borderBottom: '1px solid #D8D8D8',
							// padding: 0
						}}
					>
						<Toolbar>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={handleDrawerOpen}
								edge="start"
								sx={{
									marginRight: 5,
									...(open && { display: "none" }),
								}}
							>
								<ChevronRightIcon sx={{ color: "#1C1C1C" }} />
							</IconButton>
							<div
								onClick={handleClick}
								className="flex items-center justify-end w-full"
							>
								{/* <AppBarIcon /> */}
								{/* <Avatar alt="Remy Sharp" src="#" /> */}
							</div>
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={openMenu}
								onClose={handleClose}
								MenuListProps={{
									'aria-labelledby': 'basic-button',
								}}
								sx={{
									'& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
										right: '0',
										width: 'fit-content',
									}
								}}
							>
								<MenuItem onClick={handleClose}>{admin?.name}</MenuItem>
								<MenuItem onClick={handleClose}>{admin?.email}</MenuItem>
								<MenuItem
								//   sx={{
								// 	display:'flex',
								// 	alignItems:'center',
								// 	gap:'5px'
								//   }}
								>
								</MenuItem>
								<MenuItem
									onClick={
										() => {
											props?.logout();
											handleClose();
										}
									}
								>
									<LogoutIcon />

									<h6 className="mx-2">Logout</h6>
								</MenuItem>
								<MenuItem
									onClick={
										() => {
											handleClose();
											navigate('/admin/settings')
										}
									}
								>
									<SettingsIcon />
									<h6 className="mx-2">Settings</h6>
								</MenuItem>
							</Menu>
							<div>
								<Button
									id="basic-button"
									aria-controls={openMenu ? 'basic-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={openMenu ? 'true' : undefined}
									onClick={handleClick}
								>
									<Avatar alt="Remy Sharp" src="#" />
								</Button>


							</div>
						</Toolbar>

					</AppBar>
					<DesktopDrawer
						variant="permanent"
						open={open}
					>
						<DrawerHeader
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-evenly",
							}}
						>
							<img onClick={() => navigate('/admin/dashboard')} src={HeaderLogo} alt="HeaderLogo" />
							<IconButton onClick={handleDrawerClose}>
								{theme.direction === "rtl" ? (
									<ChevronRightIcon sx={{ color: "#1C1C1C" }} />
								) : (
									<ChevronLeftIcon sx={{ color: "#1C1C1C" }} />
								)}
							</IconButton>
						</DrawerHeader>
						<div className="pt-16 flex flex-col h-full">
							{options?.map((el, idx) => {
								const Component = options[idx].component;
								const truncatedText =
									el.label.length > 15
										? el.label.substring(0, 14) + "..."
										: el.label;
								const splittedText = truncatedText.split(" & ")
								const text = splittedText[0]
								return (
									<div className="w-full" key={idx}>
										{route === text.toLowerCase() ? (
											<Link to={el.route}>
												<div className="w-full px-3 py-3">
													<div
														style={open ? {} : { paddingRight: "2.1vmax" }}
														className="w-full bg-[#2A3B43] flex items-center px-3 rounded-md py-2"
													>
														{open ? (
															<ListItemIcon
																sx={{
																	minWidth: 0,
																	mr: open ? 2 : 0,
																	justifyContent: "center",
																}}
															>
																<Component color="white" />
															</ListItemIcon>
														) : (
															<Tooltip title={truncatedText}>
																<ListItemIcon
																	sx={{
																		minWidth: 0,
																		mr: open ? 2 : 2,
																		justifyContent: "center",
																	}}
																>
																	<Component color="white" />
																</ListItemIcon>
															</Tooltip>
														)}
														<ListItemText sx={{ opacity: open ? 1 : 0 }}>
															<h6 className="text-[#FFFFFF] font-medium  text-[.9vmax] sm:text-[1.4vmax] lg:text-[.8vmax]">
																{truncatedText}
															</h6>
														</ListItemText>
													</div>
												</div>
											</Link>
										) : (
											<Link to={el.route}>
												<div className="w-full px-3 py-3">
													<div className="w-full  flex items-center px-3 rounded-md">
														{open ? (
															<ListItemIcon
																sx={{
																	minWidth: 0,
																	mr: open ? 2 : "auto",
																	justifyContent: "center",
																}}
															>
																<Component color="black" />
															</ListItemIcon>
														) : (
															<Tooltip title={truncatedText}>
																<ListItemIcon
																	sx={{
																		minWidth: 0,
																		mr: open ? 2 : "auto",
																		justifyContent: "center",
																	}}
																>
																	<Component color="black" />
																</ListItemIcon>
															</Tooltip>
														)}
														<ListItemText sx={{ opacity: open ? 1 : 0 }}>
															<h6 className="text-[#1C1C1C] font-medium  text-[.9vmax] sm:text-[1.4vmax] lg:text-[.8vmax]">
																{truncatedText}
															</h6>
														</ListItemText>
													</div>
												</div>
											</Link>
										)}
									</div>
								);
							})}
							<div className="flex-1"></div>
							<div className="py-4 px-4 w-full flex justify-center">
								<Button fullWidth className="flex gap-2" color="error" onClick={() => props?.logout()}>
									<ExitToAppOutlined className="rotate-180" color='error' />
									{open ? <span>LOGOUT</span> : null}
								</Button>
							</div>
						</div>
					</DesktopDrawer>
				</Box>
			}

			<Box component="main" className="w-full max-w-full flex flex-col overflow-x-hidden">
				{/* <Toolbar />  */}
				<Outlet />
			</Box>
		</Box>
	);
}
