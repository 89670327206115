import { LearnIcon } from "./Icons";


const icons = [
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63" fill="none">
        <circle cx="31.5" cy="31.5" r="31.5" fill="#373737" fillOpacity="0.03" />
        <path d="M38.2 39.2H23.8C23.3228 39.1995 22.8652 39.0097 22.5277 38.6723C22.1903 38.3348 22.0005 37.8772 22 37.4V15.8C22.0005 15.3228 22.1903 14.8652 22.5277 14.5277C22.8652 14.1903 23.3228 14.0005 23.8 14H38.2C38.6772 14.0005 39.1348 14.1903 39.4723 14.5277C39.8097 14.8652 39.9995 15.3228 40 15.8V30.7562L35.5 28.5062L31 30.7562V15.8H23.8V37.4H38.2V33.8H40V37.4C39.9993 37.8772 39.8094 38.3346 39.472 38.672C39.1346 39.0094 38.6772 39.1993 38.2 39.2ZM35.5 26.4938L38.2 27.8438V15.8H32.8V27.8438L35.5 26.4938Z" fill="#373737" />
        <line x1="10" y1="46.5" x2="19.8" y2="46.5" stroke="#373737" strokeWidth="1.4" />
        <line x1="21.1992" y1="46.5" x2="51.9992" y2="46.5" stroke="#373737" strokeOpacity="0.28" strokeWidth="1.4" />
        <circle cx="17.6992" cy="46.5" r="3.185" fill="#373737" stroke="white" strokeWidth="0.63" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63" fill="none">
        <circle cx="31.5" cy="31.5" r="31.5" fill="#373737" fillOpacity="0.03" />
        <path d="M38.2 39.2H23.8C23.3228 39.1995 22.8652 39.0097 22.5277 38.6723C22.1903 38.3348 22.0005 37.8772 22 37.4V15.8C22.0005 15.3228 22.1903 14.8652 22.5277 14.5277C22.8652 14.1903 23.3228 14.0005 23.8 14H38.2C38.6772 14.0005 39.1348 14.1903 39.4723 14.5277C39.8097 14.8652 39.9995 15.3228 40 15.8V30.7562L35.5 28.5062L31 30.7562V15.8H23.8V37.4H38.2V33.8H40V37.4C39.9993 37.8772 39.8094 38.3346 39.472 38.672C39.1346 39.0094 38.6772 39.1993 38.2 39.2ZM35.5 26.4938L38.2 27.8438V15.8H32.8V27.8438L35.5 26.4938Z" fill="#373737" />
        <line x1="10" y1="46.3" x2="22" y2="46.3" stroke="#373737" strokeWidth="1.4" />
        <line x1="21.1992" y1="46.5" x2="51.9992" y2="46.5" stroke="#373737" strokeOpacity="0.28" strokeWidth="1.4" />
        <circle cx="24.5" cy="46.5" r="3.185" fill="#373737" stroke="white" strokeWidth="0.63" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63" fill="none">
        <circle cx="31.5" cy="31.5" r="31.5" fill="#373737" fillOpacity="0.03" />
        <path d="M38.2 39.2H23.8C23.3228 39.1995 22.8652 39.0097 22.5277 38.6723C22.1903 38.3348 22.0005 37.8772 22 37.4V15.8C22.0005 15.3228 22.1903 14.8652 22.5277 14.5277C22.8652 14.1903 23.3228 14.0005 23.8 14H38.2C38.6772 14.0005 39.1348 14.1903 39.4723 14.5277C39.8097 14.8652 39.9995 15.3228 40 15.8V30.7562L35.5 28.5062L31 30.7562V15.8H23.8V37.4H38.2V33.8H40V37.4C39.9993 37.8772 39.8094 38.3346 39.472 38.672C39.1346 39.0094 38.6772 39.1993 38.2 39.2ZM35.5 26.4938L38.2 27.8438V15.8H32.8V27.8438L35.5 26.4938Z" fill="#373737" />
        <line x1="10" y1="46.3" x2="30" y2="46.3" stroke="#373737" strokeWidth="1.4" />
        <line x1="21.1992" y1="46.5" x2="51.9992" y2="46.5" stroke="#373737" strokeOpacity="0.28" strokeWidth="1.4" />
        <circle cx="30.5" cy="46.5" r="3.185" fill="#373737" stroke="white" strokeWidth="0.63" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63" fill="none">
        <circle cx="31.5" cy="31.5" r="31.5" fill="#373737" fillOpacity="0.03" />
        <path d="M38.2 39.2H23.8C23.3228 39.1995 22.8652 39.0097 22.5277 38.6723C22.1903 38.3348 22.0005 37.8772 22 37.4V15.8C22.0005 15.3228 22.1903 14.8652 22.5277 14.5277C22.8652 14.1903 23.3228 14.0005 23.8 14H38.2C38.6772 14.0005 39.1348 14.1903 39.4723 14.5277C39.8097 14.8652 39.9995 15.3228 40 15.8V30.7562L35.5 28.5062L31 30.7562V15.8H23.8V37.4H38.2V33.8H40V37.4C39.9993 37.8772 39.8094 38.3346 39.472 38.672C39.1346 39.0094 38.6772 39.1993 38.2 39.2ZM35.5 26.4938L38.2 27.8438V15.8H32.8V27.8438L35.5 26.4938Z" fill="#373737" />
        <line x1="10" y1="46.3" x2="36" y2="46.3" stroke="#373737" strokeWidth="1.4" />
        <line x1="21.1992" y1="46.5" x2="51.9992" y2="46.5" stroke="#373737" strokeOpacity="0.28" strokeWidth="1.4" />
        <circle cx="36.5" cy="46.5" r="3.185" fill="#373737" stroke="white" strokeWidth="0.63" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63" fill="none">
        <circle cx="31.5" cy="31.5" r="31.5" fill="#373737" fillOpacity="0.03" />
        <path d="M38.2 39.2H23.8C23.3228 39.1995 22.8652 39.0097 22.5277 38.6723C22.1903 38.3348 22.0005 37.8772 22 37.4V15.8C22.0005 15.3228 22.1903 14.8652 22.5277 14.5277C22.8652 14.1903 23.3228 14.0005 23.8 14H38.2C38.6772 14.0005 39.1348 14.1903 39.4723 14.5277C39.8097 14.8652 39.9995 15.3228 40 15.8V30.7562L35.5 28.5062L31 30.7562V15.8H23.8V37.4H38.2V33.8H40V37.4C39.9993 37.8772 39.8094 38.3346 39.472 38.672C39.1346 39.0094 38.6772 39.1993 38.2 39.2ZM35.5 26.4938L38.2 27.8438V15.8H32.8V27.8438L35.5 26.4938Z" fill="#373737" />
        <line x1="10" y1="46.3" x2="40" y2="46.3" stroke="#373737" strokeWidth="1.4" />
        <line x1="21.1992" y1="46.5" x2="51.9992" y2="46.5" stroke="#373737" strokeOpacity="0.28" strokeWidth="1.4" />
        <circle cx="42.5" cy="46.5" r="3.185" fill="#373737" stroke="white" strokeWidth="0.63" />
    </svg>,
    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63" fill="none">
        <circle cx="31.5" cy="31.5" r="31.5" fill="#373737" fillOpacity="0.03" />
        <path d="M38.2 39.2H23.8C23.3228 39.1995 22.8652 39.0097 22.5277 38.6723C22.1903 38.3348 22.0005 37.8772 22 37.4V15.8C22.0005 15.3228 22.1903 14.8652 22.5277 14.5277C22.8652 14.1903 23.3228 14.0005 23.8 14H38.2C38.6772 14.0005 39.1348 14.1903 39.4723 14.5277C39.8097 14.8652 39.9995 15.3228 40 15.8V30.7562L35.5 28.5062L31 30.7562V15.8H23.8V37.4H38.2V33.8H40V37.4C39.9993 37.8772 39.8094 38.3346 39.472 38.672C39.1346 39.0094 38.6772 39.1993 38.2 39.2ZM35.5 26.4938L38.2 27.8438V15.8H32.8V27.8438L35.5 26.4938Z" fill="#373737" />
        <line x1="10" y1="46.3" x2="47" y2="46.3" stroke="#373737" strokeWidth="1.4" />
        <line x1="21.1992" y1="46.5" x2="51.9992" y2="46.5" stroke="#373737" strokeOpacity="0.28" strokeWidth="1.4" />
        <circle cx="48.5" cy="46.5" r="3.185" fill="#373737" stroke="white" strokeWidth="0.63" />
    </svg>
]

const LearnCard = ({ title, description, index, bg, textColor, amount, duration, onPress, original, support }) => (
    <div
        onClick={() => onPress()}
        className='hover:shadow-lg cursor-pointer flex w-[80%] lg:w-[30%] flex-col items-center justify-center gap-2  bg-[#FFFFFF] border border-[#E0E0E0] shrink-0 rounded shadow py-2 px-12 lg:px-20'
    >
        <div className='w-fit py-2'>
            {icons[index]}
        </div>
        <div className='w-fit flex flex-col py-2 items-center gap-2 justify-center'>
            {/* <div className="py-4">
            </div> */}
            <div className='w-fit flex flex-col items-center justify-center'>
                <h6 className='text-[#000000] font-semibold text-lg lg:text-xl whitespace-nowrap mb-1'>{title}</h6>
                <p className='text-[#656565] font-medium text-xs lg:text-sm whitespace-nowrap mb-3'>{description}</p>
                <div className='flex flex-col items-center justify-center py-2'>
                    <p className='text-[#757575] font-light text-xs lg:text-sm'>Duration</p>
                    <div className="flex gap-1 items-center">
                        <p className={`text-[${textColor}] font-semibold text-sm lg:text-xl`}>{duration} days</p>
                        <p className={`text-[#757575] text-xs font-medium whitespace-nowrap`}> + {support} Month support</p>
                    </div>
                </div>
                {/* <p className='text-[#00B386] text-xs lg:text-sm'>+{support} {support > 1 ? 'months' : 'month'} support</p> */}
            </div>
            <div className={`py-1 px-4 flex justify-center items-center gap-2 bg-[${bg}] border border-[${textColor}] rounded my-2 cursor-pointer hover:shadow transition`}>
                <h4 className='text-[₹9,999] opacity-50 line-through font-medium whitespace-nowrap text-xs md:text-sm lg:text-lg'>₹ {original?.toLocaleString()}</h4>
                <h4 className={`text-[${textColor}] whitespace-nowrap font-extrabold text-sm md:text-lg lg:text-xl`}>₹ {amount?.toLocaleString()}</h4>
            </div>
        </div>
    </div>  
)

export default LearnCard