import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useThemeContext } from '../Theme/ThemeContext';
import { H3, H4, H5, H6, P } from '../Theme/ThemeComponent';
import TextField from '@mui/material/TextField';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { InputAdornment, Tooltip } from '@mui/material';
import { BASE_URL, WEBSOCKET_API_URL, WEBSOCKET_URL } from '../default.config';
import { useDispatch, useSelector } from 'react-redux';
import { http } from '../Services/api.services';
import { showToast } from '../store/Actions/Action';
import { LoadingButton } from '@mui/lab';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import Badge from '@mui/material/Badge';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const _orderData = {
    qty: null,
    price: null,
}

const _sltData = {
    sl: null,
    target: null,
    slPer: null,
    targetPer: null
}

export default function OrderDrawer({ open, onClose }) {
    const { currentOrderType, currentSelectedOrder } = useSelector((state) => state.equity);
    const { theme } = useThemeContext();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user)
    const [currentTabValue, setCurrentTabValue] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [companyData, setCompanyData] = React.useState(null)
    const [currentExchange, setCurrentExchange] = React.useState('nse')
    const [currentBalance, setCurrentBalance] = React.useState(null)
    const [orderData, setOrderData] = React.useState({ ..._orderData })
    const [quantities, setQuantities] = React.useState({
        CNC: null,
        MIS: null
    })
    const [slDataError, setSlDataError] = React.useState({
        error: false,
        message: ''
    })
    const [targetDataError, setTargetDataError] = React.useState({
        error: false,
        message: ''
    })
    const [sltData, setSlData] = React.useState({
        ..._sltData
    })

    // UPDATE THE CURRET QYANTITY AND DATA ON MODIFY OR CANCEL ORDER 
    React.useEffect(() => {
        if (currentSelectedOrder && currentOrderType?.toLowerCase() == 'modify' || currentOrderType?.toLowerCase() == 'cancel') {
            setCurrentTabValue(currentSelectedOrder?.product_type == 'CNC' ? 0 : 1);
            const _oData = {
                qty: currentSelectedOrder?.quantity,
                price: currentSelectedOrder?.price
            }
            setOrderData((_oData));
        }
    }, [currentSelectedOrder, currentOrderType]);

    // HANDLE TABS CHANGE FUNCTION
    const handleTabChange = (event, newValue) => {
        setCurrentTabValue(newValue);
        setOrderData({ ..._orderData })
        setSlData({ ..._sltData })
    };

    // HANDLE INDEX CHANGE FUNCTION 
    const handleChangeIndex = (index) => {
        setCurrentTabValue(index);
    };

    // GET CURRENT COMPANY DATA BY SOCKET EVENT 
    React.useEffect(() => {
        try {
            if (currentSelectedOrder?.instrument_token) {
                // Create a WebSocket connection
                const socket = new WebSocket(`${WEBSOCKET_URL}/ws/instrument_token`);

                // Event listener for when the connection is opened
                socket.onopen = () => {
                    console.log('WebSocket connection opened for order drawer');
                    // Send a message to the server after the connection is open
                    socket.send(currentSelectedOrder?.instrument_token);
                };

                // Event listener for when a message is received from the server
                socket.onmessage = (event) => {
                    setCompanyData(JSON.parse(event.data))
                    // console.log(event?.data);

                };

                // Event listener for when the WebSocket connection is closed
                socket.onclose = (event) => {
                    console.log('WebSocket connection closed for order drawer:', event);
                };

                // Event listener for when there's an error with the WebSocket
                socket.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };

                // Cleanup function to close the WebSocket connection when the component unmounts
                return () => {
                    socket.close();
                };
            }
        } catch (e) {
            console.log(e);
        }
    }, [currentSelectedOrder]);

    // GET CURRENT USER AVAILABLE BALANCE BY API 
    React.useEffect(() => {
        if (user && open) {
            getUserAvailableBalance(user);
            getAvailableQuantity(currentSelectedOrder?.instrument_token);
        }
    }, [user, open]);

    const getUserAvailableBalance = async (user) => {
        try {
            const response = await http.get(`user-balance?user_id=${user?.id}`);
            if (response?.status == 200) {
                setCurrentBalance((prevData) => ({
                    ...prevData,
                    available: response?.data
                }))
            }
        } catch (e) {
            console.log(`ERRROR WHILE FETCHING USER BALANCE ${e}`);
        }
    }

    // GET REQUIRED ORDER MARGIN 
    React.useEffect(() => {
        if (open) {
            getOrdersMargin(orderData?.qty);
        }
    }, [companyData?.last_price, open])

    const getOrdersMargin = async (qty) => {

        try {
            // Ensure necessary parameters are provided
            if (!user?.id || !companyData?.exchange || !companyData?.tradingsymbol || !currentOrderType || !qty || currentTabValue == null) {
                console.error('Missing necessary parameters to fetch order margin.');
                return;
            }

            // Build query parameters object    
            const queryParams = {
                user_id: user.id,
                exchange: companyData.exchange,
                tradingsymbol: companyData.tradingsymbol,
                transaction_type: currentSelectedOrder?.transaction_type ? currentSelectedOrder?.transaction_type?.toUpperCase() : (currentOrderType || 'BUY').toUpperCase(),
                quantity: qty,
                product: currentTabValue === 0 ? 'CNC' : 'MIS',
                order_type: orderData.price ? 'LIMIT' : 'MARKET'
            };

            // Conditionally add the price parameter
            if (orderData.price) {
                queryParams.price = orderData.price;
            }

            // Convert the query parameters to URLSearchParams
            const params = new URLSearchParams(queryParams).toString();

            // Use axios to send the GET request with query parameters
            const response = await http.get(`order_margin?${params}`);
            if (response?.status == 200) {
                setCurrentBalance((prevData) => ({
                    ...prevData,
                    required: response?.data
                }))
            }
        } catch (error) {
            console.error(`ERROR WHILE FETCHING ORDERS MARGIN: ${error.message}`);
        }
    };

    // GET AVAILABLE order QUANTITY  
    const getAvailableQuantity = async (instrument_token) => {
        try {
            const response = await http.get(`${BASE_URL}get_quantities?user_id=${user?.id}&instrument_token=${instrument_token}`);
            if (response?.status == 200) {
                setQuantities(response?.data)
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING AVALIABLE QUANTITY: ${e}`);

        }
    }

    // HANDLE QTY AND PRICE CHANGE FUNCTION 
    const onHandleValueChange = (e, field) => {
        try {
            const value = e?.target?.value
            if (!isNaN(Number(value))) {
                setOrderData((prevData) => ({
                    ...prevData,
                    [field]: value,
                }));
            }
        } catch (e) {
            console.log(`ERROR WHILE UPDATING VALUE ${field}`);

        }
    }

    // HANDLE SL AND TARGET CHANGE FUNCTION 
    const onHandleSltChange = (e, field) => {
        try {
            const value = e?.target?.value;
            if (!isNaN(Number(value)) && Number(value) >= 0) {
                setSlData(prevData => ({
                    ...prevData,
                    [field]: value,
                }));
            } else {
                alert('Stop loss and target should be greater than 0');
            }
        } catch (e) {
            console.error(`Error while updating value ${field}: ${e.message}`);
        }
    }

    // CALCULATE PERCENTAGE SL AND TARGET FUNCTION 
    const calculatePercentageDifference = (current, value, field) => {
        if (value) {
            if (Number(value) < 0) {
                console.error(`Error: Negative value not allowed for ${field}`);
                setSlData((prevData) => ({ ...prevData, [field + 'Per']: field === 'sl' ? 0 : 0 }));
                return { percentageIncrease: 0, percentageDecrease: 0 };
            }
        }
        const highTarget = current + parseFloat(value);
        const lowTarget = current - parseFloat(value);

        // Check if current is zero to avoid division by zero error
        if (current === 0) {
            return { percentageIncrease: 0, percentageDecrease: 0 };
        }

        // Calculate percentage increase and decrease
        const percentageIncrease = ((highTarget - current) / current) * 100;
        const percentageDecrease = ((current - lowTarget) / current) * 100;

        // Update state with new values
        setSlData((prevData) => ({ ...prevData, [field + 'Per']: field === 'sl' ? percentageIncrease || 0 : percentageDecrease || 0 }));

        // Return the updated values
        return { percentageIncrease, percentageDecrease };
    }

    // VALIDATE SL & TARGET VALUES 
    const validateSltValues = (e, field) => {
        try {
            const value = e?.target?.value;
            if (currentOrderType == 'sell') {
                if (field == 'sl') {
                    if (value < companyData?.last_price) {
                        console.log('Stop loss should be greater than current ltp')
                        const _msg = {
                            error: true,
                            message: 'Stop loss should be greater than current ltp'
                        }
                        setSlDataError(_msg);
                        return false;
                    } else {
                        const _msg = {
                            error: false,
                            message: ''
                        }
                        setSlDataError(_msg);
                        return true;
                    }
                } else {
                    if (value > companyData?.last_price) {
                        console.log('Target should be less than current ltp')
                        const _msg = {
                            error: true,
                            message: 'Target should be less than current ltp'
                        }
                        setTargetDataError(_msg);
                        return false;
                    } else {
                        const _msg = {
                            error: false,
                            message: ''
                        }
                        setTargetDataError(_msg);
                        return true;
                    }
                }
            } else {
                if (field == 'sl') {
                    if (value > companyData?.last_price) {
                        console.log('Stop loss should be less than current price')
                        const _msg = {
                            error: true,
                            message: 'Stop loss should be less than current price'
                        }
                        setSlDataError(_msg);
                        return false;
                    } else {
                        const _msg = {
                            error: false,
                            message: ''
                        }
                        setSlDataError(_msg);
                    }
                } else {
                    if (value < companyData?.last_price) {
                        console.log('Target should be greager than current price')
                        const _msg = {
                            error: true,
                            message: 'Target should be greager than current price'
                        }
                        setTargetDataError(_msg);
                        return false;
                    } else {
                        const _msg = {
                            error: false,
                            message: ''
                        }
                        setTargetDataError(_msg);
                    }
                }
            }
        } catch (e) {
            return false;
        }
    }
    // PLACE ORDER FUNTION 
    const placeOrder = async () => {
        try {
            setIsLoading(true);
            const _body = {
                user_id: user?.id,
                instrument_token: companyData?.instrument_token,
                transaction_type: (currentOrderType || 'BUY').toUpperCase(),
                product_type: currentTabValue === 0 ? 'CNC' : 'MIS',
                order_type: orderData.price ? 'LIMIT' : 'MARKET',
                quantity: parseInt(orderData?.qty),
                price: orderData.price ? parseInt(orderData.price) : 0
            }
            if (currentTabValue == 1 && sltData?.sl) {
                _body.stopLoss = sltData?.sl
            }
            if (currentTabValue == 1 && sltData?.target) {
                _body.target = sltData?.target
            }
            const response = await http.post(`${BASE_URL}order`, _body)
            setIsLoading(false);
            if (response?.status == 200) {
                setOrderData({ ..._orderData });
                setSlData({ ..._sltData });
                setCurrentBalance(null);
                const _snack = {
                    message: response?.data?.status,
                    open: true,
                    type: 'success',
                    duration: 3000,
                }
                dispatch(showToast(_snack))
                onClose();
            } else {
                const _snack = {
                    message: response?.data?.message || 'Something went wrong',
                    open: true,
                    type: 'error',
                    duration: 3000,
                }
                dispatch(showToast(_snack))
            }
        } catch (e) {
            setIsLoading(false);
            const _snack = {
                message: e?.response?.data?.detail,
                open: true,
                type: 'error',
                duration: 3000,
            }
            dispatch(showToast(_snack))
            console.log(`ERROR WHILE PLACING ORDER: ${e.message}`);
        }
    }

    // CANCEL SELECTED ORDER 
    const cancelOrder = async (order) => {
        try {
            const response = await http.delete(`${BASE_URL}order/cancel/${order?.id}`);
            if (response?.status == 200) {
                setOrderData({ ..._orderData });
                setSlData({ ..._sltData });
                setCurrentBalance(null);
                const _snack = {
                    message: response?.data?.status,
                    open: true,
                    type: 'success',
                    duration: 3000,
                }
                dispatch(showToast(_snack))
                onClose();
            } else {
                const _snack = {
                    message: response?.data?.message || 'ERROR WHILE CANCELING ORDER',
                    open: true,
                    type: 'error',
                    duration: 3000,
                }
                dispatch(showToast(_snack))
            }
        } catch (e) {
            const _snack = {
                message: e?.response?.data?.detail,
                open: true,
                type: 'error',
                duration: 3000,
            }
            dispatch(showToast(_snack))
            console.log(`ERROR WHILE CANCEL ORDER: ${e}`);
        }
    }

    // MODIFY SELECTED ORDER 
    const onModifyOrder = async (order) => {
        try {
            const _payload = {
                order_id: order?.id,
                user_id: user?.id,
                quantity: parseInt(orderData?.qty),
                price: parseFloat(orderData?.price),
                order_type: orderData.price ? 'LIMIT' : 'MARKET',
            }
            const response = await http.put(`order/modify`, _payload);
            if (response?.status == 200) {
                setOrderData({ ..._orderData });
                setSlData({ ..._sltData });
                setCurrentBalance(null);
                const _snack = {
                    message: response?.data?.status,
                    open: true,
                    type: 'success',
                    duration: 3000,
                }
                dispatch(showToast(_snack))
                onClose();
            } else {
                const _snack = {
                    message: response?.data?.message || 'ERROR WHILE CANCELING ORDER',
                    open: true,
                    type: 'error',
                    duration: 3000,
                }
                dispatch(showToast(_snack))
            }
        } catch (e) {
            const _snack = {
                message: e?.response?.data?.detail,
                open: true,
                type: 'error',
                duration: 3000,
            }
            dispatch(showToast(_snack))
            console.log(`ERROR WHILE CANCEL ORDER: ${e}`);

        }

    }
    // RESET ALL VALUES ON ONCLOSE EVENT 
    const resetAllValues = async () => {
        try {
            setOrderData({ ..._orderData });
            setSlData({ ..._sltData });
            setCurrentBalance(null);
            setCurrentTabValue(0)
            const _msg = {
                error: false,
                message: ''
            }
            setSlDataError(_msg);
            setTargetDataError(_msg);
            onClose();
        } catch (e) {
            console.log(`ERROR WHILE PLACING ORDER: ${e.message}`);
        }
    }

    return (
        <Drawer
            sx={{ position: 'relative', width: 500, maxWidth: 500, overflow: 'hidden' }}
            anchor="right"
            open={open}
            onClose={() => {
                resetAllValues();
            }}
        >
            <div className='w-full '>
                <div className={`w-full flex items-start justify-between border-b py-6 px-2 lg:px-4 border-[${theme.palette.text.coolGray}]`}>
                    <div className='flex items-start gap-2'>
                        <ArrowBackIcon
                            onClick={() => {
                                resetAllValues();
                            }}
                            sx={{color:theme.palette.icon.primary, cursor: 'pointer' }}
                        />
                        <div>
                            <H5 title={currentSelectedOrder?.name ? currentSelectedOrder?.name : currentSelectedOrder?.industry} color={theme.palette.text.charcolGray} font="bold" />
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                size='small'
                                sx={{
                                    '& .MuiTypography-root': {
                                        fontSize: '0.80rem', // Adjusts label text size
                                    },
                                }}
                            >
                                <FormControlLabel size='small' value="nse" control={<Radio checked={currentExchange == 'nse'} size='small' />} label="NSE" />
                                <Tooltip title={'Coming soon'}>
                                    <FormControlLabel disabled size='small' value="bse" control={<Radio disabled checked={currentExchange == 'bse'} size='small' />} label="BSE" />
                                </Tooltip>
                            </RadioGroup>
                        </div>
                    </div>
                    <div className='flex items-center gap-1 '>
                        <H6 title={'LTP'} font="normal" />
                        <H6 title={companyData?.last_price?.toFixed(2) || '--'} font="bold" />
                    </div>
                </div>
                <AppBar sx={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: theme.palette.text.black,
                    boxShadow: 'none',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '0px 22px'
                }}
                    position="static"
                >
                    <Tabs
                        value={currentTabValue}
                        onChange={handleTabChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        // variant="fullWidth" 
                        aria-label="full width tabs example"
                        sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: theme.palette.text.black, // Set the indicator color to red
                            },
                        }}
                    >
                        <Tab label="Delivery" {...a11yProps(0)} />
                        <Tab label="Intraday" {...a11yProps(1)} />
                        {/* <Tab label="MTF" {...a11yProps(2)} /> */}
                    </Tabs>
                    <div className='flex'>
                        <Badge
                            badgeContent={currentTabValue == 0 ? quantities?.CNC : quantities?.MIS}
                            color="primary"
                            showZero
                        >
                            <WorkOutlineOutlinedIcon
                                sx={{
                                    color:theme.palette.icon.primary
                                }}
                            />
                        </Badge>
                    </div>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={currentTabValue}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={currentTabValue} index={0} dir={theme.direction}>
                        <div className='w-full flex flex-col lg:flex-row items-center gap-3 py-4'>
                            <div className='w-full lg:w-[49%] flex flex-col gap-2'>
                                <H6 color={theme.palette.text.charcolGray} title={'Qty'} />
                                <TextField
                                    size='small'
                                    id="outlined-basic"
                                    placeholder="Qty"
                                    variant="outlined"
                                    value={orderData?.qty}
                                    type='number'
                                    onChange={(e) => {
                                        onHandleValueChange(e, 'qty');
                                        getOrdersMargin(e?.target?.value);
                                    }}
                                />
                            </div>
                            <div className='w-full lg:w-[49%] flex flex-col gap-2'>
                                <H6 color={theme.palette.text.charcolGray} title={'Price'} />
                                <TextField
                                    size='small'
                                    id="outlined-basic"
                                    placeholder="Mkt"
                                    variant="outlined"
                                    value={orderData?.price}
                                    type='number'
                                    onChange={(e) => {
                                        onHandleValueChange(e, 'price');
                                        getOrdersMargin(e?.target?.value);
                                    }}
                                    InputProps={{
                                        startAdornment: <CurrencyRupeeIcon size="small" sx={{ color:theme.palette.icon.primary, fontSize: '18px !important' }} />,
                                    }}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={currentTabValue} index={1} dir={theme.direction}>
                        <div className='flex flex-col gap-2'>
                            <div className='w-full flex items-center gap-3 '>
                                <div className='w-[49%] flex flex-col gap-2'>
                                    <H6 color={theme.palette.text.charcolGray} title={'Qty'} />
                                    <TextField
                                        size='small'
                                        id="outlined-basic"
                                        placeholder="Qty"
                                        variant="outlined"
                                        type='number'
                                        value={orderData?.qty}
                                        onChange={(e) => {
                                            onHandleValueChange(e, 'qty');
                                            getOrdersMargin(e?.target?.value);
                                        }}
                                    />
                                </div>
                                <div className='w-[49%] flex flex-col gap-2'>
                                    <H6 color={theme.palette.text.charcolGray} title={'Price'} />
                                    <TextField
                                        size='small'
                                        id="outlined-basic"
                                        placeholder="Mkt"
                                        variant="outlined"
                                        value={orderData?.price}
                                        type='number'
                                        onChange={(e) => {
                                            onHandleValueChange(e, 'price');
                                            getOrdersMargin(e?.target?.value);
                                        }}
                                        InputProps={{
                                            startAdornment: <CurrencyRupeeIcon fontSize='12px' size="small" sx={{ color:theme.palette.icon.primary, fontSize: '18px !important' }} />,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='w-full py-2 flex flex-col gap-2'>
                                <H6 color={theme.palette.text.charcolGray} title={'Stop Loss '} />
                                <TextField
                                    size='small'
                                    id="outlined-basic"
                                    placeholder="Stop Loss "
                                    variant="outlined"
                                    value={sltData?.sl}
                                    type='number'
                                    error={slDataError?.error && sltData?.sl}
                                    helperText={sltData?.sl ? slDataError?.message : null}
                                    onChange={(e) => {
                                        onHandleSltChange(e, 'sl');
                                        validateSltValues(e, 'sl');
                                        calculatePercentageDifference(companyData?.last_price, e?.target?.value, 'sl')
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{sltData?.slPer?.toFixed(2)}%</InputAdornment>,
                                    }}
                                />
                            </div>
                            <div className='w-full py-2 flex flex-col gap-2'>
                                <H6 color={theme.palette.text.charcolGray} title={'Target '} />
                                <TextField
                                    size='small'
                                    id="outlined-basic"
                                    placeholder="Target "
                                    variant="outlined"
                                    value={sltData?.target}
                                    type='text'
                                    error={targetDataError?.error && sltData?.target}
                                    helperText={sltData?.target ? targetDataError?.message : null}
                                    onChange={(e) => {
                                        onHandleSltChange(e, 'target');
                                        validateSltValues(e, 'target');
                                        calculatePercentageDifference(companyData?.last_price, e?.target?.value, 'target')
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">{sltData?.targetPer?.toFixed(2)}%</InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    {/* <TabPanel value={value} index={2} dir={theme.direction}>
            Item Three
            </TabPanel>  */}
                </SwipeableViews>

                {/* {_type != 'intraday' ?
                <div className='flex items-center gap-2 px-4'>
                <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                <H6 title={'Notify me for algo signals'} color={theme.palette.text.blue} font="normal" />
                </div>
                : null} */}

                <div className='w-full absolute bottom-0'>
                    <div className={`w-full flex items-center justify-between p-2`}>
                        <H6 title={'Best Buy'} color={theme.palette.text.coolGray} />
                        <H6 title={'-- | --'} color={theme.palette.text.green} />
                    </div>
                    <div style={{ background: theme.palette.background.whisperGray }} className={`w-full flex items-center justify-between p-2`}>
                        <P title={`Available :  ${currentBalance?.available?.equity?.net?.toFixed(2) || '--'}`} color={currentBalance?.required?.order_margin[0]?.total > currentBalance?.available?.equity?.net ? theme.palette.text.red : theme.palette.text.coolGray} />
                        <P title={`Required :  ${currentBalance?.required?.order_margin[0]?.total?.toFixed(2) || '--'}`} color={theme.palette.text.coolGray} />
                    </div>
                    <div className='w-full p-4'>
                        {currentOrderType && currentOrderType == 'buy' ?
                            <LoadingButton
                                loading={isLoading}
                                onClick={() => placeOrder()}
                                disabled={
                                    (currentBalance?.required?.order_margin[0]?.total > currentBalance?.available?.equity?.net)
                                }
                                fullWidth color='buy' sx={{ color: theme.palette.text.white }} variant='contained' >
                                Buy
                            </LoadingButton>
                            :
                            <LoadingButton
                                fullWidth
                                color='sell'
                                loading={isLoading}
                                onClick={() => {
                                    if (currentOrderType == 'sell') {
                                        placeOrder();
                                    } else if (currentOrderType == 'cancel') {
                                        cancelOrder(currentSelectedOrder);
                                    } else if (currentOrderType == 'modify') {
                                        onModifyOrder(currentSelectedOrder);
                                    } else {
                                        placeOrder() //NEED TO CORRECT 
                                    }
                                }}
                                sx={{ color: theme.palette.text.white }}
                                variant='contained'
                            >
                                {currentOrderType}
                            </LoadingButton>
                        }
                    </div>
                </div>
            </div>
        </Drawer>
    );
}
