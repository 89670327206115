import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { useDimensions } from "../Contexts/DimensionContext/DimensionsContext";
// import http from 'path-to-your-http-module'; // Import your HTTP module
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SLChart = (props) => {
  const { sm, md, lg, xl, xxl } = useDimensions();
  const { slChartData, ihmChartData, companiesStockData } = useSelector(
    (state) => state.tools
  );
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState({
    high5: 0,
    low5: 0,
    highp: 0,
    lowp: 0,
    closep: 0,
    lowt: 0,
    hight: 0,
  });
  const [slcDataPoints, setSlcDataPoints] = React.useState([]);
  // console.log(props?.slcData);

  useEffect(() => {
    if (props.slcData) {
      // console.log(props.slcData);
      setSlcDataPoints(props.slcData);
    }
  }, [props?.slcData]);

  React.useEffect(() => {
    if (companiesStockData) {
      const currentIndexData = companiesStockData.find(
        (item) =>
          item?.index?.toLowerCase() === props?.currentIndex?.toLowerCase()
      );
      setData({
        high5: currentIndexData?.value?.high5,
        low5: currentIndexData?.value?.low5,
        highp: currentIndexData?.value?.highp,
        lowp: currentIndexData?.value?.lowp,
        closep: currentIndexData?.value?.closep,
        lowt: currentIndexData?.value?.lowt,
        hight: currentIndexData?.value?.hight,
      });
      if (currentIndexData) {
        setDataLoading(false);
      }
    }
  }, [slChartData?.data, props?.currentIndex, companiesStockData]);


  const [highestY, setHighestY] = useState(null);
  const [lowestY, setLowestY] = useState(null);

  useEffect(() => {
    if (props?.slcData && props?.slcData?.value?.length > 0) {
      const highestYValue = Math.max(...props?.slcData.value.map((item) => item.y));
      const lowestYValue = Math.min(...props?.slcData.value.map((item) => item.y));

      // console.log(highestValue, lowestValue);

      const highestYObject = props?.slcData.value.find(
        (item) => item.y === highestYValue
      );
      const lowestYObject = props?.slcData.value.find(
        (item) => item.y === lowestYValue
      );

      setHighestY(highestYObject);
      setLowestY(lowestYObject);
    }
  }, [props?.slcData]);

  //   FIND HIGHEST AND LOWEST YAXIS VALUE

  const [highestValue, setHighestValue] = React.useState(null);
  const [lowestValue, setLowestValue] = React.useState(null);

  React.useEffect(() => {
    // Update highest and lowest values whenever the data changes
    updateMinMaxValues();
  }, [data]);

  const updateMinMaxValues = () => {
    const values = Object.values(data);

    // Find the highest and lowest values using Math.max and Math.min
    const highest = Math.max(...values);
    const lowest = Math.min(...values);

    // Update state with the results
    setHighestValue(highest);
    setLowestValue(lowest);
  };

  const options = {
    height: props?.isFullScreen
      ? 500
      : sm
        ? 150
        : md
          ? 150
          : lg
            ? 150
            : xl
              ? 150
              : xxl
                ? 200
                : 250,
    title: {
      text: "",
    },
    backgroundColor: "transparent",
    axisX: {
      gridColor: "transparent",
      tickColor: "transparent",
      intervalType: "minute",
      interval: 0,
      valueFormatString: "HH:mm", // Display hours and minutes in the format you want
      labelFontSize: 10,
      labelFontColor: "#bdbdbd",
      lineColor: "#D2D2D2",
    },
    // axisX: {
    //   gridColor: "transparent",
    //   tickColor: "transparent",
    //   intervalType: "minute",
    //   interval: intervalValue,
    //   valueFormatString: "HH:mm", // Display hours and minutes in the format you want
    //   labelFontSize: 10,
    //   labelFontColor: "#bdbdbd",
    //   lineColor: "#D2D2D2",
    // },
    axisY: {
      stripLines: [
        {
          startValue: data?.high5,
          endValue: data?.high5 + 5,
          color: "#FB2600",
          thickness: 1,
          // label: 'Red Line'
        },
        {
          startValue: data?.low5,
          endValue: data?.low5 + 5,
          color: "#FB2600",
          thickness: 1,
          // label: 'Green Line'
        },
        {
          startValue: data?.highp,
          endValue: data?.highp + 5,
          color: "#5DA44B",
          thickness: 1,
          // label: 'Green Line'
        },
        {
          startValue: data?.lowp,
          endValue: data?.lowp + 5,
          color: "#5DA44B",
          thickness: 1,
          // label: 'Red Line'
        },
        {
          startValue: data?.closep,
          endValue: data?.closep + 5,
          color: "#2B65FA",
          thickness: 1,
          lineDashType: "dash", // Set the lineDashType to "dash" for a dashed line
        },
      ],
      tickColor: "transparent",
      gridColor: "transparent",
      interval: 100,
      // minimum: data?.lowt - 100,
      // maximum: data?.hight + 100,
      minimum: Math.min(lowestY?.y, data.low5, data.lowp) - 100,
      maximum: Math.max(highestY?.y, data.high5, data.highp) + 100,
      // minimum: lowestY?.y,
      // maximum: highestY?.y,
      lineColor: "#D2D2D2",
      labelFormatter: function (e) {
        return slChartData?.data && slChartData?.data.length > 0
          ? e.value?.toLocaleString()
          : "";
      },
      labelFontSize: 10,
      labelFontColor: "#bdbdbd",
    },
    data: [
      {
        type: "line",
        lineColor: "#000",
        markerSize: 6,
        lineThickness: 1,
        markerType: "circle",
        markerColor: "transparent",
        toolTipContent:
          "<span style='color: {color}; background-color: yellow;'></span> {y} ",
        dataPoints:
          slcDataPoints && slcDataPoints?.value?.length > 0
            ? slcDataPoints?.value?.map((dataPoint) => ({
              x: new Date(dataPoint.x),
              y: dataPoint.y,
              color: "transparent",
            }))
            : [],
      },
    ],
  };

  const chartStyle = {
    width: "100%",
  };

  // console.log(options,'options');
  // console.log(slcDataPoints?.value?.length,companiesStockData?.length,dataLoading);


  return (
    <div style={chartStyle}>
      {slcDataPoints?.value?.length > 0 &&
        companiesStockData?.length &&
        !dataLoading ? (
        <CanvasJSChart options={options} />
      ) : (
        <div className="w-full flex justify-center">
          <CircularProgress disableShrink />
        </div>
      )}
    </div>
  );
};

export default SLChart;
