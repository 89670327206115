import React from "react";
import GraphHeader from "./GraphHeader";
import SLChart from "./SLChart";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { updateSlcInterval } from "../store/Reducer/ToolsReducer";

const SLChartComponent = (props) => {
  const dispatch = useDispatch();
  // const {
  //   slChartData,
  //   ihmChartData,
  //   companiesStockData,
  //   currentToolsIndex,
  //   slcInterval,
  // } = useSelector((state) => state.tools);
  let _interval = props.slInterval
  let _currentIndex = props.currentIndex
  let _slceventName = `SLC${_currentIndex.toUpperCase()}minute${_interval}`
  let _emaEventName = `EMA${_currentIndex.toUpperCase()}minute${_interval}`


  // console.log(_slceventName,"ema",props?.slcData?.[_slceventName])
  // console.log(_emaEventName,"slc",props?.companiesData?.[_emaEventName])
  return (
    <>
      <div className="w-full h-full flex flex-col">
        <GraphHeader
          title="SL Chart"
          interval={_interval}
          setIntervalFunction={props?.setIntervalFunction}
        />
        <div className="w-full flex-1  flex flex-col items-center justify-center ">
          <SLChart
            slcData={props?.slcData?.[_slceventName]}
            setSlcData={props?.setSlcData}
            isFullScreen={props?.isFullScreen}
            companiesData={props?.companiesData?.[_emaEventName]}
            currentIndex={props?.currentIndex}
          />
          {!props?.isFullScreen ? (
            <div className="w-full  flex items-center justify-end pr-1 pb-1">
              <IconButton
                onClick={() => {
                  props?.setIsFullScreen(true);
                  props?.setGraphType("SL Chart");
                }}
                aria-label="delete"
              >
                <FullscreenIcon color="gray" className="cursor-pointer" />
              </IconButton>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SLChartComponent;
