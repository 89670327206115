import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Outlet, useLocation } from 'react-router-dom';
import Logo from "../assets/Group 829.png"
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Padding } from '@mui/icons-material';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Fab from '@mui/material/Fab';
import { Link } from 'react-router-dom';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import { useDispatch, useSelector } from 'react-redux'
import { asyngetCurrentIndex, asyngetJsonData } from '../store/Actions/Action';
import OptionHeader from './OptionHeader';
import OptionTable from './OptionTable'
import { http } from '../Services/api.services';
import { ENDPOINTS } from '../default.config';
import axios from 'axios';



const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Options = () => {

    const dispatch = useDispatch();
    const market = useSelector((state) => state.market);
    const user = useSelector((state) => state.user);
    const { currentIndex } = useSelector((state) => state.optionHome)
    const [open, setOpen] = React.useState(false);
    const [trnType, setTrnType] = React.useState('');
    const [ordType, setOrdType] = React.useState('');
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const path = useLocation();
    const pathnameSegments = path.pathname.split('/');
    const route = pathnameSegments[2];
    const [maxHeight, setmaxHeight] = React.useState(0)
    const [headerHeight, setheaderHeight] = React.useState(0)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const StyledFab = styled(Fab)({
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    });


    const onIndexChangeEvent = (value) => {
        try {
            dispatch(asyngetCurrentIndex(value))
        }
        catch (e) {
            console.log(`ERROR --- ${e}`)
        }
    }


    const onFormChange = (trn, ord) => {
        try {
            setOpen(!open)
            setTrnType(trn)
            setOrdType(ord)
        }
        catch (e) {
            console.log(`ERROR --- ${e}`)
        }
    }

    const getExpiry = async () => {
        try {
            // const response = await http.get(ENDPOINTS.EXPIRY)
            // console.log(response)
        } catch (e) {
            console.log(`error while fetching expiry :  ${e} `);
        }
    }

    const getOptionData = async () => {
        try {
            const response = await http.get(`${ENDPOINTS.OPTIONDATA}/${user?.id}`)
            console.log(response);
        } catch (e) {
            console.log(`error while fetching expiry :  ${e} `);
        }
    }

    React.useEffect(() => {
        //    getExpiry();
        //    getOptionData();
    }, [])



    return (
        <>

            <div className='w-full h-screen flex flex-col'>

                <AppBar sx={{}} position="static" className='!bg-white !shadow w-full'>
                    <Container sx={{ width: '100%' }} className='!px-1 !max-w-full py-0 lg:!px-4'>
                        <Toolbar disableGutters className='flex items-center justify-between'>
                            <div className='flex items-center gap-10'>
                                <div className='hidden lg:flex'>
                                    <img src={Logo} alt="" />
                                </div>
                                <div className='flex items-center gap-3 flex-wrap'>
                                    {route === undefined ? (
                                        <HomeIcon color='black' />
                                    ) : (
                                        <HomeIcon color='gray' />
                                    )}
                                    <HomeIcon color='gray' />
                                    <Tooltip title='Coming Soon'>
                                        <h6 className='text-[#A4A4A4] font-normal whitespace-nowrap m-0 text-[16px] lg:text-[14px]'>EQUITY</h6>
                                    </Tooltip>
                                    <h6 className='text-[#373737] font-semibold whitespace-nowrap  m-0 text-[16px] lg:text-[14px]'>INDEX OPTIONS</h6>

                                </div>
                            </div>
                            <div className='hidden items-center gap-10'>
                                <div className='flex items-center  gap-4'>
                                    <Link to="/home/exchanges">
                                        <h6 className={route === 'exchanges' ? 'text-[#373737] font-semibold text-[13px] uppercase m-0' : 'text-[#C0C0C0CA] font-normal uppercase text-[13px] m-0'}>EXCHANGES</h6>
                                    </Link>
                                    <Link to="/home/indexes">
                                        <h6 className={route === 'indexes' ? 'text-[#373737] font-semibold text-[13px] uppercase m-0' : 'text-[#C0C0C0CA] font-normal uppercase text-[13px] m-0'}>Indexes</h6>
                                    </Link>
                                    <Link to="/home/strikes">
                                        <h6 className={route === 'strikes' ? 'text-[#373737] font-semibold text-[13px] uppercase m-0' : 'text-[#C0C0C0CA] font-normal uppercase text-[13px] m-0'}>Strikes</h6>
                                    </Link>
                                </div>
                            </div>
                            <div className='flex items-center gap-2 lg:gap-5'>
                                <NotificationsIcon className='text-[#373737]' />
                                <Box sx={{ flexGrow: 0 }}>
                                    <Tooltip title="Open settings">
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {settings.map((setting, idx) => (
                                            <MenuItem key={idx} onClick={handleCloseUserMenu}>
                                                <Typography textAlign="center">{setting}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            </div>
                        </Toolbar>
                    </Container>
                </AppBar>
                <OptionHeader expiry="expiry" onIndexChange={onIndexChangeEvent} currentIndex={currentIndex} />
                <div className="flex-1 px-3 py-1 overflow-hidden shadow-sm">
                    <OptionTable data={market[currentIndex]} onFormChange={onFormChange} />
                </div>
            </div>
        </>
    )
}

export default Options