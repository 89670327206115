import React, { createContext, useContext, useEffect, useState } from "react";
import { WEBSOCKET_URL, WEBSOCKET_URL_TICKER } from "../../default.config";
import { useDispatch, useSelector } from "react-redux";
import { setPositions } from "../../store/Actions/Action";

// GET EVENT FOR POSISITIONS AND OPEN ORDERS

const WebSocketContext = createContext();

export const useWebSocket = () => {
	return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }) => {
	const [socket, setSocket] = useState(null);
	const [receivedMessages, setReceivedMessages] = useState({});
	const [updatePositions, setUpdatePositions] = useState("");
	const [updateBasketPositions, setUpdateBasketPositions] = useState("");
	const [updateOrders, setUpdateOrders] = useState("");

	const connectWebSocket = (userId) => {
		// CONNECT TO WEB SOCKET
		const ws = new WebSocket(`${WEBSOCKET_URL_TICKER}/position/${userId}`);
		ws.onopen = () => {
			// console.log('POSITIONS & OPEN ORDER WS CONNECTED!');
		};
		ws.onmessage = (event) => {
			console.log("RECEIVING POSITIONS TIME", new Date().getTime());
			let response = JSON.parse(event.data);
			console.log('WEBSOCKET EVENT RECEIVED -- ', response)
			if (response?.event == "positions") {
				if (Object.keys(response.data).length) {
					if (response.data.basket_id) {
						setUpdateBasketPositions(response?.data);
					} 
					else setUpdatePositions(response.data);
				}
			} else if (response?.event == "orders") {
				setUpdateOrders(response?.data);
			}
		};
		ws.onerror = (error, reason) => {
			console.log("ERROR IN SOCKET -- ", error, reason);
			ws.close();
		};
		ws.onclose = (e) => {
			console.log("WebSocket closed", e);
			setTimeout(() => {
				console.log("RECONNECTING POSTIONS WEBSOCKET");
				if (!ws || ws?.readyState == WebSocket.CLOSED) {
					connectWebSocket(userId);
				}
			}, 5000);
		};
		setSocket(ws);
	};

	const disconnectWebSocket = () => {
		if (socket) {
			socket.close();
			setSocket(null);
		}
	};

	const sendWebSocketMessage = (message) => {
		if (socket && socket.readyState === WebSocket.OPEN) {
			socket.send(message);
		}
	};

	useEffect(() => {
		// connectWebSocket()
		return () => {
			disconnectWebSocket(); // Disconnect WebSocket when the component unmounts
		};
	}, []);

	const contextValue = {
		connectWebSocket,
		disconnectWebSocket,
		sendWebSocketMessage,
		receivedMessages,
		updatePositions,
		updateBasketPositions,
		updateOrders,
	};

	return (
		<WebSocketContext.Provider value={contextValue}>
			{children}
		</WebSocketContext.Provider>
	);
};
