import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useThemeContext } from "../Theme/ThemeContext";
import { H5, H6, P } from "../Theme/ThemeComponent";
import { Chip, Divider, IconButton } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import moment from "moment";

const WatchlistCard = ({ watchListData, onEditWatchlist }) => {
  const location = useLocation();
  const { theme } = useThemeContext();
  const navigate = useNavigate();

  // console.log(watchListData);

  const pathname = location.pathname;

  // Split the pathname into segments
  const segments = pathname.split('/');

  // Check if there's an ID after the 'watchlist' segment
  const watchlistIndex = segments.indexOf('watchlist');
  const _id = segments[watchlistIndex + 1]; // The next segment after 'watchlist' should be the ID

  const watchlist = _id ? watchListData?.watchlist : watchListData
  const analytics = _id ? watchListData?.analytics : watchListData


  const WatchlistFields = ({ title, value }) => (
    <div className='flex flex-col items-center gap-1'>
      <P title={title} color={theme.palette.text.coolGray} />
      <H6 title={value} color={theme.palette.text.charcolGray} font='medium' />
    </div>
  )


  return (
    <>
      <div
        onClick={() => {
          if (!_id) {
            navigate(`/equity/watchlist/${watchlist?.id}`);
          }
        }}
        className="cursor-pointer shrink-0 flex flex-col border border-[#F2F2F2] overflow-hidden rounded-lg "
      >
        <div className={`w-full flex  items-center justify-between  p-3 `}>
          <div className="flex items-center gap-2">
            <H6
              title={watchlist?.title}
              color={theme.palette.text.charcolGray}
              font="medium"
            />
            {analytics?.stock_length != null || undefined ?
              <Chip size="small" label={analytics?.stock_length} />
              : null}
            {_id ?
              <EditOutlinedIcon
                // fontSize="20px"  
                fontSize='small'
                sx={{
                  color: theme.palette.icon.primary
                }}
                onClick={() => onEditWatchlist()}
              />
              : null}
          </div>
          <P title={moment(watchListData?.watchlist?.created_at).format('Do MMM YYYY')} color={theme.palette.text.silverGray} />
        </div>
        <div className='flex justify-between  flex-wrap gap-2 p-3  lg:gap-8'>
          <WatchlistFields title='Open Short Trades' value={analytics?.open_short_trade || '--'} />
          <Divider orientation="vertical" flexItem />
          <WatchlistFields title='Open Long Trades' value={analytics?.open_long_trade || '--'} />
          <Divider orientation="vertical" flexItem />
          <WatchlistFields title='Closed Trades' value={analytics?.closed_trade || '--'} />
        </div>
      </div>
    </>
  );
};

export default WatchlistCard;
