import React, { useCallback, useMemo, useState } from "react";
import {
	setAdminRedux,
	setOpenOrders,
	setPositions,
	showToast,
} from "../../store/Actions/Action";
import { useDispatch, useSelector } from "react-redux";
import { useDimensions } from "../../Contexts/DimensionContext/DimensionsContext";
import { ApiService, http } from "../../Services/api.services";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { expiryReducer } from "../../store/Reducer/ExpiryReducer";
import { ENDPOINTS, WEBSOCKET_URL_TICKER } from "../../default.config";
import HomeDrawer from "../../PublicComponents/HomeDrawer";
import BrokerForm from "../../PublicComponents/BrokerForm";
import {
	updateactivePath,
	updateActiveSL,
	updateIsEquity,
} from "../../store/Reducer/Reducer";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useWebSocket } from "../../Contexts/WebsocketContext/WebSocketContext";
import { useMarketContext } from "../../Contexts/MarketContext/MarketContext";
import { setBasketPositions, updateCurrentPosition } from "../../store/Reducer/BasketReducer";

const NetworkErrorImg = require("../../assets/NetworkErroImg.png");
const api = new ApiService();

// FIRST SCREEN WHERE POST LOGIN
// INITIALIZE ALL REDUX AND OTHER PROPERTIES TO BE USED IN FUTURE
let initialLoadExpiry = true;
let symbols = {};


// Debounce function
const debounce = (func, delay) => {
	let timeoutId;
	return (...args) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

const AdminAuth = () => {
	const {
		connectWebSocket,
		receivedMessages,
		updatePositions,
		updateBasketPositions,
		updateOrders,
	} = useWebSocket();

	const market = useMarketContext();

	// console.log('mymarket', market)
	//   const market = useMarketContext();
	// const market = useSelector((state) => state.market);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { xs, sm, md, lg, xl, xxl } = useDimensions();
	const home = useSelector((state) => state.optionHome);
	const { user } = useSelector((state) => state.user);
	const { ticks } = useSelector((state) => state.ticks);
	const { admin } = useSelector((state) => state.admin);
	const { positions, positionType } = useSelector((state) => state.orders);
	const { basketPositions, currentPosition } = useSelector((state) => state.Baskets);
	// console.log(currentPosition);

	// const orders = useSelector((state) => state.orders)
	let rankings = useSelector((state) => state.rankings);
	const allCurrentExpiry = useSelector((state) => state.allCurrentExpiry);

	const [anchorElUser, setAnchorElUser] = React.useState(null);
	const [login_url, setLogin_url] = React.useState("");
	const [openBroker, setOpenBroker] = React.useState(false);
	const [planRoute, setPlanRoute] = React.useState(false);
	const [brokers, setBrokers] = React.useState([]);
	const [isDrawerOpen, setDrawerOpen] = React.useState(false);
	const [currentIndex, setCurrentIndex] = React.useState(
		home?.currentIndex || "nifty"
	);
	const pathnameSegments = location.pathname.split("/");
	const route = pathnameSegments[pathnameSegments?.length - 1];
	const [isNetworkError, setIsNetworkError] = React.useState(false);

	const [positionState, setPositionState] = React.useState(positions);
	const [basketPositionState, setBasketPositionState] = React.useState(basketPositions);

	const [allSymbols, setAllSymbols] = useState({
		nifty: { ce: {}, pe: {} },
		banknifty: { ce: {}, pe: {} },
		finnifty: { ce: {}, pe: {} },
		midcpnifty: { ce: {}, pe: {} },
		sensex: { ce: {}, pe: {} },
	});

	var ticker;
	const _id = admin ? admin?.id : user?.id;
	const memoizedTicks = useMemo(() => ticks, [ticks]);

	React.useEffect(() => {
		// console.log(location.pathname)
		dispatch(updateactivePath(location.pathname.split("/")));
		if (location.pathname.includes("equity")) {
			dispatch(updateIsEquity([true, "equity"]));
		} else {
			dispatch(updateIsEquity([false, "options"]));
		}
	}, [location.pathname]);

	// USE EFFECT
	React.useEffect(() => {
		// CHECK FOR ADMIN LOGIN
		// market.setCurrentIndex("nifty");
		let _admin = localStorage.getItem("admin");
		_admin = _admin ? JSON.parse(_admin) : null;
		if (_admin) {
			fetchAdmin(_admin.user_id);
		} else navigate("/admin");
	}, []);

	React.useEffect(() => {
		// console.log(home.activePath)
		if (home.activePath?.includes("strikes")) {
			// FOR OPTION CHAIN SCREEN
			setCurrentIndex(home?.currentIndex);
			market.setCurrentIndex(home?.currentIndex);
			market.setCurrentExpiry(allCurrentExpiry[home?.currentIndex]);
		}
	}, [home?.currentIndex]);

	// IF RANKINGS ARE CHANGED - BELOW EVENT
	React.useEffect(() => {
		if (market[currentIndex]?.options && market[currentIndex]?.options?.CE) {
			updateRankings();
		}
	}, [rankings]);

	React.useEffect(() => {
		if (updatePositions) {
			if (admin) {
				updatePositionsInstruments(updatePositions);
			}
		}
	}, [updatePositions]);

	const updatePositionsInstruments = (position) => {
		try {
			dispatch(setPositions(positionAppend(position)));
			const positions = {
				...market.positions,
				...{ [parseInt(position.instrumenttoken)]: {} },
			};
			market.updatePositionsWS({
				updatePositions: true,
				positions,
			});
			market.updateIndex("positions", positions);
			// console.log(positions)
		}
		catch (e) {
			console.log(`ERROR WHILE UPDATING POSITIONS INSTRUMENTS TO CONTEXT ${e}`);
		}
	}

	const returnCurrentBasketPosition = () => {
		return currentPosition
	}

	const getAllPositions = async (id, _current) => {
		try {
			const response = await http.get(`orders/${id}/basket/position`);
			if (response?.status === 200) {
				dispatch(setBasketPositions(response?.data?.data));
				if (response?.data?.data?.length > 0) {
					// UPDATE CURRENT BASKET POSITION
					for (let index = 0; index < response.data.data.length; index++) {
						const basketPosition = response.data.data[index];
						// console.log(_current?.id, basketPosition.basket_id)
						if (_current) {
							if (_current?.id === basketPosition.basket_id) {
								dispatch(updateCurrentPosition(basketPosition));
							}
						}
					}
				}
			}
			else {
				dispatch(setBasketPositions([]));
			}
		} catch (e) {
			dispatch(setBasketPositions([]));
			console.log(`ERROR WHILE FETCHING POSITIONS ${e}`);
		}
	}

	const debouncedGetAllPositions = useCallback(
		debounce((id, _currentPosition) => {
			// console.timeEnd('START')
			getAllPositions(id, _currentPosition);
		}, 500),
		[]
	)

	// UPDATE BASKET BASKET POSITIONS
	React.useEffect(() => {
		// ADD DEBOUNCE 
		// console.log('appendBasketPositions')
		if (!market.basketPositions[parseInt(updateBasketPositions['instrumenttoken'])]) {
			market.appendBasketPositions(parseInt(updateBasketPositions['instrumenttoken']))
		}
		// console.time('START')
		if (admin) {
			debouncedGetAllPositions(admin?.id, currentPosition)
		}
	}, [updateBasketPositions]);

	React.useEffect(() => {
		// console.log(positions);
		setPositionState(positions);
	}, [positions]);

	React.useEffect(() => {
		if (admin) {
			if (updateOrders)
				fetchOpenOrders(admin?.id);
		}
	}, [updateOrders]);

	/*************************** */

	const positionAppend = (latestPosition) => {
		try {
			const storedPositions = positionState ? [...positionState] : [];
			// console.log(latestPosition, storedPositions);
			if (storedPositions && storedPositions?.length) {
				// FIND THE INDEX OF POSITIONS WITH SAME _id
				const _index = storedPositions.findIndex(
					(pos) => pos._id == latestPosition?._id
				);
				if (_index > -1) {
					storedPositions[_index] = latestPosition;
				} else storedPositions.unshift(latestPosition);
				return storedPositions;
			} else {
				storedPositions.push(latestPosition);
				return storedPositions;
			}
		} catch (e) { }
	};

	const basketPositionAppend = (latestPosition) => {
		try {
			const storedPositions = basketPositionState
				? [...basketPositionState]
				: [];
			// console.log(latestPosition, storedPositions);
			if (storedPositions && storedPositions?.length) {
				// FIND THE INDEX OF POSITIONS WITH SAME _id
				const _index = storedPositions.findIndex(
					(pos) => pos._id == latestPosition?._id
				);
				if (_index > -1) {
					storedPositions[_index] = latestPosition;
				} else storedPositions.unshift(latestPosition);
				return storedPositions;
			} else {
				storedPositions.push(latestPosition);
				return storedPositions;
			}
		} catch (e) { }
	};

	const wait = (duration) => {
		return new Promise((resolve, reject) => {
			try {
				setTimeout(() => {
					resolve(true);
				}, duration);
			} catch (e) {
				reject(e);
			}
		});
	};

	// FIRST THING FIRST -- FETCH ADMIN DETAILS
	const fetchAdmin = async (id) => {
		try {
			//   console.log(`FETCH PROCESS STARTED`);
			const url = `admin/fetch-admin/${id}`;
			const response = await http.get(url);
			// ACTIVE SL & T STRATEGY
			dispatch(updateActiveSL(response?.data?.active_sl));
			if (response.status == 200) {
				// market.setMarketOpen(response.data?.is_open)
				market.setMarketOpen(response.data?.is_open);
				market.sendMessageToWorker({ marketStatus: response.data?.is_open });
				// SET ADMIN REDUX
				dispatch(setAdminRedux(response.data));
				// NOW FETCH INDEXES - IN FETCH INDEXES GET THE OPTION CHAIN ONE BY ONE
				fetchIndexes(response?.data);
				// fetchPositions(id,'strike');
				fetchBasketPositions(id);
				setIsNetworkError(false);
				connectWebSocket(id);
				if (!response.data.active) {
					// console.log(response.data)
					const _snackbar = {
						message: "Broker Not Connected!",
						open: true,
						duration: 10000,
						type: "error",
					};
					dispatch(showToast(_snackbar));
				}
			} else {
				setIsNetworkError(true);
			}
		} catch (e) {
			console.log(e + " - ERROR WHILE FETCHING DETAILS!");
			if (e.message == "Network Error") {
				setIsNetworkError(true);
			}
		}
	};

	const fetchIndexes = async (admin) => {
		// LOG EXECUTION TIME
		const start = new Date().getTime();
		// console.log(`START TIME ${start} ms`);

		const xMarketIndexes = [
			"nifty",
			"banknifty",
			"finnifty",
			"midcpnifty",
			"sensex",
			"bankex",
		];
		const tickerIndex = { t1: 0, t2: 0, t3: 0 };
		const indexes = {};
		try {
			const response = await http.get("ds/indexes");
			if (response.data) {
				// SET MARKET DATA
				market.setCurrentIndex("nifty");
				market.setCurrentExpiry(
					response.data["nifty".toUpperCase()]?.["expiry"]?.[0]
				);
				await Promise.all(
					xMarketIndexes.map(async (key, i) => {
						indexes[key] = JSON.parse(
							JSON.stringify(response.data[key?.toUpperCase()])
						);
						const _m = await market.updateIndexPromise(key, indexes[key]);
						// console.log(`MARKET INDEXES UPDATED IN ${new Date().getTime() - start} ms`)
						indexes[key]?.expiry?.map((exp) => {
							indexes[key][exp] = {};
						});
						// console.log(`EXPIRY SET IN ${new Date().getTime() - start} ms`)
						return await setIndexOptionChain(
							indexes,
							indexes[key],
							key,
							admin,
							indexes[key]["tradingsymbol"],
							indexes[key]["strikeMargin"],
							tickerIndex
						);
					})
				);
				// SET OPTION CHAIN AND OTHER INDEX DATA
				console.log(`EXECUTION TIME -- ${new Date().getTime() - start}`);
				// CALL THIS BELOW FUNCTION ONLY AFTER THE WEBSOCKET IS SET IN STATE FOR ALL FIRST EXPIRY
				xFetchExpiryIndex(xMarketIndexes, admin.id, indexes, start);
			}
		} catch (e) {
			console.log(`ERROR WHILE FETCHING INDEXES ${e}`);
		}
	};

	const setIndexOptionChain = async (
		_indexes,
		_index,
		key,
		admin,
		ts,
		m,
		tickerIndex
	) => {
		// THIS FUNCTIONS FETHCES THE OPTION CHAIN AND SET MISC DATA TO INDEX
		return new Promise(async (resolve, reject) => {
			try {
				const option = await _fetchOptionChain(admin, _index, ts, m);
				// console.log('EXPIRY -- ', _index?.expiry)
				_indexes[key?.toLowerCase()][_index?.expiry[0]]["symbols"] = {
					CE: {},
					PE: {},
				};
				_indexes[key?.toLowerCase()][_index?.expiry[0]]["options"] = option;
				// console.log(symbols)
				symbols = { ...symbols };
				symbols[`'${_index?.instrument}'`] = {
					index: key?.toLowerCase(),
					type: "index",
				};
				symbols = { ...symbols };
				if (
					_indexes[key?.toLowerCase()][_index?.expiry[0]]["options"]["CE"] &&
					_indexes[key?.toLowerCase()][_index?.expiry[0]]["options"]["PE"]
				) {
					const _ce = Object.keys(
						_indexes[key?.toLowerCase()][_index?.expiry[0]]["options"]["CE"]
					);
					const _pe = Object.keys(
						_indexes[key?.toLowerCase()][_index?.expiry[0]]["options"]["PE"]
					);
					_ce.map((_c) => {
						_indexes[key?.toLowerCase()][_index?.expiry[0]]["symbols"]["CE"][
							_c
						] = true;
						symbols[_c] = {
							index: key?.toLowerCase(),
							type: "CE",
							expiry: _index?.expiry[0],
						};
					});
					_pe.map((_p) => {
						_indexes[key?.toLowerCase()][_index?.expiry[0]]["symbols"]["PE"][
							_p
						] = true;
						symbols[_p] = {
							index: key?.toLowerCase(),
							type: "PE",
							expiry: _index?.expiry[0],
						};
					});
				}
				// console.log('MARKET SNAPSHOT --- ', getSnapshot(market))
				// market.setMarketIndex(key?.toLowerCase(), _indexes[key?.toLowerCase()]);
				market.setExpiryIndex(
					key?.toLowerCase(),
					_index.expiry[0],
					_indexes[key?.toLowerCase()][_index.expiry[0]],
					symbols
				);
				dispatch(
					expiryReducer({
						type: key?.toLowerCase(),
						payload: _index?.expiry[0],
					})
				);
				dispatch(
					expiryReducer({
						type: "timeToExpire",
						key: key?.toLowerCase(),
						payload: moment(_index?.expiry[0]).valueOf() + 24 * 60 * 60 * 999,
					})
				);
				market.setInstruments(symbols);
				if (key?.toLowerCase() == "nifty") {
					tickerIndex.t1++;
					if (tickerIndex.t1 == 1) {
						delete tickerIndex.t1;
						const params = {
							url: `${WEBSOCKET_URL_TICKER}/ws`,
							market: _indexes,
							instruments: symbols,
							ticker: "T1",
							marketStatus: market.isMarketOpen,
						};
						// market.startWebSocket(params);
						// console.log('WSWORKER', params)
						market.startWebSockets(params);
					}
				} else if (
					key?.toLowerCase() == "banknifty" ||
					key?.toLowerCase() == "finnifty"
				) {
					tickerIndex.t2++;
					if (tickerIndex.t2 == 2) {
						delete tickerIndex.t2;
						const params = {
							url: `${WEBSOCKET_URL_TICKER}/ws`,
							instruments: symbols,
							market: _indexes,
							ticker: "T2",
							marketStatus: market.isMarketOpen,
						};
						// market.startWebSocket(params);
						// console.log('WSWORKER', params)
						market.startWebSockets(params);
					}
				} else {
					tickerIndex.t3++;
					if (tickerIndex.t3 == 3) {
						delete tickerIndex.t3;
						const params = {
							url: `${WEBSOCKET_URL_TICKER}/ws`,
							instruments: symbols,
							market: _indexes,
							ticker: "T3",
							marketStatus: market.isMarketOpen,
						};
						// market.startWebSocket(params);
						// console.log('WSWORKER', params)
						market.startWebSockets(params);
					}
				}
				resolve(symbols);
			} catch (e) {
				console.log(`ERROR WHILE SETTING VALUES TO INDEX ${key} -- `, e);
				reject(e);
			}
		});
	};

	const xFetchExpiryIndex = async (keys, adminId, indexes, startTime) => {
		try {
			for (let index = 0; index < keys.length; index++) {
				const key = keys[index];
				const oc = await Promise.all(
					indexes[key]["expiry"]?.map(async (exp, i) => {
						if (i)
							return {
								o: await setOtherExpiryOptionChain(
									key,
									exp,
									adminId,
									indexes[key]
								),
								e: exp,
							};
					})
				);
				// console.log(oc)
				oc.map((o) => {
					if (o) {
						// console.log(key, o.e, o.o)
						indexes[key][o.e] = o.o[o.e];
						market.setExpiryIndex(key, o.e, indexes[key][o.e], symbols);
					}
				});
				// console.log(indexes[key])
			}
			// market.setInstruments(symbols)
			market.updateMarketWs({
				updateTicker: true,
				instruments: symbols,
				market: indexes,
			});
			market.updateAllow(false);
			//   console.log(
			//     `ALL EXPIRY OF INDEXES -- EXECUTION TIME -- ${
			//       new Date().getTime() - startTime
			//     }`
			//   );
		} catch (e) {
			console.log(`ERROR IN xFetchExpiryIndex -- `, e);
		}
	};

	const returnMarketbyValue = (key) => {
		// console.log(market[key])
		return JSON.parse(JSON.stringify(market[key]));
	};

	const setOtherExpiryOptionChain = (key, exp, adminId, index) => {
		// const index = market[key]
		return new Promise(async (resolve, reject) => {
			try {
				// console.log(`${key} - ${exp} `, index)
				const options = await fetchOptionChainWithExpiry(
					adminId,
					key,
					exp,
					index["tradingsymbol"],
					index["strikeMargin"]
				);
				index[exp] = {};
				index[exp]["symbols"] = { CE: {}, PE: {} };
				symbols = { ...symbols };
				index[exp]["options"] = options;
				if (index[exp]["options"]["CE"] && index[exp]["options"]["PE"]) {
					const _ce = Object.keys(index[exp]["options"]["CE"]);
					const _pe = Object.keys(index[exp]["options"]["PE"]);
					_ce.map((_c) => {
						index[exp]["symbols"]["CE"][_c] = true;
						symbols[_c] = {
							index: key,
							type: "CE",
							expiry: exp,
						};
					});
					_pe.map((_p) => {
						index[exp]["symbols"]["PE"][_p] = true;
						symbols[_p] = {
							index: key,
							type: "PE",
							expiry: exp,
						};
					});
				}
				// console.log(symbols)
				// market.updateIndexPromise(key, index);
				market.setInstruments(symbols);
				resolve(index);
			} catch (e) {
				console.log(`ERROR WHILE FETCHING OTHER EXPIRY OPTION CHAIN - `, e);
				reject(e);
			}
		});
	};

	const lastdayTicks = () => {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await http.get("ds/last_tick");
				if (response.status == 200) resolve(response.data);
				else reject(null);
			} catch (e) {
				reject(e);
			}
		});
	};

	// NEW FUNCTION TO FETCH OPTION CHAIN - RETURNS PROMISE
	const _fetchOptionChain = async (usr, indexFuture, tradingsymbol, margin) => {
		return new Promise(async (resolve, reject) => {
			try {
				// console.log(indexFuture)
				const url = `${ENDPOINTS.OPTION_CHAIN}/${usr?.id}?index=${indexFuture.index}&expiry=${indexFuture.expiry[0]}&tradingsymbol=${tradingsymbol}&margin=${margin}`;
				const response = await http.get(url);
				if (response.status == 200) resolve(response.data.data);
				else resolve({});
			} catch (e) {
				console.log("NEW OPTION CHAIN FETCH ERR -- ", e);
				resolve({});
			}
		});
	};

	const fetchOptionChainWithExpiry = async (
		adminId,
		index,
		expiry,
		symbol,
		margin
	) => {
		return new Promise(async (resolve, reject) => {
			try {
				const OC_URL = `${ENDPOINTS.OPTION_CHAIN
					}/${adminId}?index=${index?.toUpperCase()}&expiry=${expiry}&tradingsymbol=${symbol}&margin=${margin}`;
				const response = await http.get(OC_URL);
				if (response.status == 200) resolve(response.data.data);
				else reject(new Error(400));
			} catch (e) {
				console.log(`ERROR WHILE FETCHING OPTION CHAIN WITH EXPIRIES -- ${e}`);
				reject(e);
			}
		});
	};

	// ON EXPIRY CHANGE
	const fetchOptionChainExpiry = async (currentIndex, expiry) => {
		const ticker = {
			nifty: "T1",
			banknifty: "T2",
			finnifty: "T2",
			midcpnifty: "T3",
			sesex: "T3",
			bankex: "T3",
		};
		try {
			const _market = {
				...market[currentIndex][allCurrentExpiry[currentIndex]],
			};
			_market["options"] = await _fetchOptionChain(
				admin,
				{ index: currentIndex?.toUpperCase(), expiry: [expiry] },
				market[currentIndex]?.tradingsymbol,
				market[currentIndex]?.strikeMargin
			);
			const symbols = { ...market.instruments };
			symbols[`'${_market?.instrument}'`] = {
				index: currentIndex,
				type: "index",
			};
			// console.log(symbols);
			if (_market?.options["CE"] && _market?.options["PE"]) {
				for (const sym in symbols) {
					if (
						symbols[sym]["index"] == currentIndex &&
						symbols[sym]["type"] != "index"
					) {
						delete symbols[sym];
					}
				}
				const _ce = Object.keys(_market["options"]["CE"]);
				const _pe = Object.keys(_market["options"]["PE"]);
				_ce.map((_c) => {
					symbols[_c] = {
						index: currentIndex?.toLowerCase(),
						type: "CE",
					};
				});
				_pe.map((_p) => {
					symbols[_p] = {
						index: currentIndex?.toLowerCase(),
						type: "PE",
					};
				});
			}
			market.setInstruments(symbols);
			// console.log('MARKET SNAPSHOT --- ', getSnapshot(market))
			market.setMarketIndex(currentIndex, {
				[allCurrentExpiry[currentIndex]]: _market,
			});
			const params = {
				url: `${WEBSOCKET_URL_TICKER}/ws`,
				instruments: symbols,
				market: JSON.parse(JSON.stringify(market)),
				ticker: ticker[currentIndex],
				update: true,
			};
			market.startWebSocket(params);
			const _snack = {
				message: "Expiry changed!",
				type: "success",
				duration: 3000,
				open: true,
			};
			dispatch(showToast(_snack));
		} catch (e) {
			console.log("ERROR WHILE FETCHING OPTION CHANIN FOR EXPIRY");
		}
	};

	const fetchBasketPositions = async (id) => {
		try {
			const response = await http.get(`orders/${id}/basket/position`);
			if (response?.status == 200) {
				dispatch(setBasketPositions(response?.data?.data));
				market.updateBasketPositionsWS({
					updateBasketPositions: true,
					basketPositions: market.setMarketBasketPositions(response.data.data),
				});
			} else {
				dispatch(setBasketPositions([]));
			}
		} catch (e) {
			dispatch(setBasketPositions([]));
			console.log(`ERROR WHILE FETCHING POSITIONS ${e}`);
		}
	};

	const fetchPositions = async (id, _type) => {
		try {
			// setRefresh(true)
			// const userId = localStorage.getItem('user_id');
			const apiUrl = `/orders/positions/${id}?r=a&type=${_type}`;
			const response = await http.get(apiUrl);
			if (response.status === 200) {
				dispatch(setPositions(response.data?.data));
				market.updatePositionsWS({
					updatePositions: true,
					positions: market.setMarketPositions(response.data.data),
				});
				fetchOpenOrders(id);
				// // console.log(response.data.data)
				// setPositions(response?.data?.data)
				// setRefresh(false)
				// if (response?.data?.data?.length)
				//     // setErrors({ message: 'Positions Updated!', open: true, type: 'success' })
				//     // DISABLED DUE TO SOCKET.IO REMOVAL
				//     if (ticks && response?.data?.data) {
				//         updatePositions(response?.data?.data)
				//     }
			}
		} catch (e) {
			// setRefresh(false)
			console.log("ERROR WHILE FETCH POSITIONS -- ", e);
		}
	};

	const fetchOpenOrders = async (id) => {
		try {
			if (id) {
				const apiUrl = `/orders/open/${id}?r=a`;
				const response = await http.get(apiUrl);
				if (response.status == 200) {
					// console.log(response.data)
					// setOpenOrders(response.data)
					dispatch(setOpenOrders(response.data));
					if (response.data?.length) {
						const _inst = {}
						for (let index = 0; index < response.data.length; index++) {
							const _order = response.data[index];
							_inst[_order?.instrument_token] = {}
							if (index == response.data?.length - 1) {
								market.appendPositionsWS({ updatePositions: true, positions: _inst })
							}
						}
					}
				}
				// else setOpenOrders([])
			}
		} catch (e) {
			setOpenOrders([]);
			console.log("error while orders ", e);
		}
	};

	// HANDLE LOGOUT
	const handleLogout = async () => {
		const token = localStorage.getItem("token");
		const response = await api.logout({ token });
		// console.log(response);
		localStorage.clear();
		window.location.reload();
	};

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	// CONTECT BROKER
	const connectBroker = () => {
		if (user?.broker?.length) window.location.href = login_url;
	};

	// UPDATE BROKER
	const updateBroker = () => {
		setOpenBroker(true);
		handleCloseUserMenu();
	};

	const updateRankings = async () => {
		try {
			const _market = JSON.parse(JSON.stringify(market));
			// console.log(_market)
			// const _rankings = JSON.parse(JSON.stringify(rankings.ranking))
			// for (let i = 0; i < ALL_RANKINGS.value.length; i++) {
			//     const _ranking = ALL_RANKINGS.value[i];
			//     // console.log(_rankings[currentIndex], _ranking)
			//     if (_rankings[currentIndex]?.includes(_ranking)) {
			//         const __market = xFindRankOC(_market, currentIndex, ALL_RANKINGS.keyInChain[i], ALL_RANKINGS.key[i], ALL_RANKINGS.htb[i])
			//         // dispatch(marketReducer({ type: currentIndex, payload: __market[currentIndex] }))
			//         market.setMarketIndex(currentIndex, __market)
			//     }
			// }
		} catch (e) {
			console.log("ERROR WHILE UPDATING RANKINGS --", e);
		}
	};

	React.useEffect(() => {
		if (admin || user) {
			const __id = admin ? admin?.id : user?.id;
			fetchPositions(__id, positionType);
		}
	}, [positionType, user, admin]);

	return (
		<div className="w-full h-full flex flex-col">
			{/* {console.log(market[currentIndex])} */}
			{admin ? (
				<div className="flex-1 flex flex-col overflow-hidden">
					<HomeDrawer
						isOpen={isDrawerOpen}
						currentRoute={location.pathname}
						onClose={() => setDrawerOpen(false)}
						openBroker={() => setOpenBroker(true)}
						onConnectBroker={() => connectBroker}
						earnwithus={() => navigate(`${planRoute}`)}
						learnwithus={() => navigate(`/home/learn-with-us`)}
						onNewsClick={() => navigate(`/home/news`)}
						onUpdateBroker={() => setOpenBroker(true)}
						onLogout={() => handleLogout()}
					/>
					{openBroker ? (
						<BrokerForm
							open={openBroker}
							userBroker={user?.broker?.length ? user?.broker[0] : null}
							onClose={() => setOpenBroker(false)}
							user={user}
							brokers={brokers}
							type={"update"}
							onSubmit={(response) => dispatch(showToast(response))}
							isFullScreen={xs || sm || md}
						/>
					) : null}
					<Outlet />
				</div>
			) : (
				<div className="w-full h-full flex justify-center items-center">
					{isNetworkError ? (
						<div className="w-full flex flex-col items-center justify-center gap-2">
							<img src={NetworkErrorImg} alt="NetworkErrorImg" />
							<h1 className="text-2xl uppercase font-bold text-[#f6908e]">
								Network Error
							</h1>
						</div>
					) : null}
				</div>
			)}
		</div>
	);
};

export default observer(AdminAuth);
