import * as React from 'react'
import { IOSSwitch } from './Switches'



const TenureSwitch = ({ planDuration, setPlanDuration, checked }) => (
    <>
        <div className='w-full flex justify-center items-center gap-4 mb-4 lg:mb-10 mt-5 lg:mt-10'>
            <div className='flex flex-1 flex-row justify-end items-center gap-2 lg:gap-4'>
                <p className='text-sm lg:text-lg font-bold'>1 C<span className='text-xs'>YCLE</span></p>
            </div>
            <IOSSwitch checked={checked} onChange={() => !planDuration ? setPlanDuration(1) : setPlanDuration(0)} />
            <div className='flex flex-row justify-start items-center gap-2 lg:gap-4 flex-1'>
                <p className='text-sm lg:text-lg font-bold'>12 C<span className='text-xs'>YCLES</span>&nbsp;<span className='text-xs text-[#00B386]'>+1 CYCLE FREE</span></p>
            </div>
        </div>
        <p className='text-sm lg:text-lg text-gray-600 font-medium text-center'>1 C<span className='text-xs lg:text-sm'>YCLE</span> = 28 D<span className='text-sm'>AYS</span></p>
    </>
)

export default TenureSwitch