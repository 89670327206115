import * as React from 'react'


const FutureRelease = ({ fontSize, message }) => {

    return (
        <div className='z-50 p-5 rouded absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center backdrop-blur'>
            <p style={{ fontSize }} className='tracking-widest text-[#3f3f3f] text-center'>{message}</p>
        </div>
    )
}

export default FutureRelease