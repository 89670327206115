import * as React from 'react';
import { createTheme } from '@mui/material/styles';

const material = createTheme({
  palette: {
    primary: {
      main: '#333',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: '#e53935',
    },
    white: {
      main: '#fff',
    },
    grey: {
      main: '#9e9e9e',
    },
    buy: {
      main: '#00A880C7',
    },
    sell: {
      main: '#EB5B3C',
    },
    gray: {
      main: '#A4A4A4',
    },
    blue: {
      main: '#006CE6',
    },
  },
});

export { material };
