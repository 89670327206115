import React, { useEffect, useState } from 'react'
import AdminHeader from '../../PrivateComponents/AdminHeader'
import AdminTable from '../../PrivateComponents/AdminTable'
import { RightIcon } from '../../PublicComponents/Icons';
import { v4 as uuidv4 } from 'uuid';
import AllTransections from '../../PrivateComponents/AllTransections';
import FilterDrawer from '../../PrivateComponents/FilterDrawer';
import { http } from '../../Services/api.services';
import { BASE_URL } from '../../default.config';
import { useNavigate } from 'react-router-dom';
import { FilterCheckboxes } from '../../PrivateComponents/FilterComponents';

const HeadCell = ({ title }) => (
    <h6 className='text-[1.5vmax]  lg:text-[.8vmax] text-[#000000] font-semibold tracking-wider'>
        {title}
    </h6>
)

const columns = [

    {
        id: 'name',
        label: <HeadCell title="Name" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'left'
    },
    {
        id: 'gender',
        label: <HeadCell title="Gender" />,
        minWidth: 130,
        maxWidth: 200,
        align: 'left'
    },
    {
        id: 'mobile',
        label: <HeadCell title="Mobile" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'email',
        label: <HeadCell title="Email" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'state',
        label: <HeadCell title="State" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'city',
        label: <HeadCell title="City" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'age',
        label: <HeadCell title="Age" />,
        minWidth: 130,
        maxWidth: 200,
        align: 'center'
    },
    {
        id: 'plan',
        label: <HeadCell title="Current Plan" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'center'
    },
    {
        id: 'income_group',
        label: <HeadCell title="Income Group" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'risk_appetite',
        label: <HeadCell title="Risk Appetite" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'trading_experience',
        label: <HeadCell title="Trading Experience" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'pan',
        label: <HeadCell title="Pan" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'center',
        format: (value) => value.toFixed(2),
    },

    {
        id: 'country',
        label: <HeadCell title="Country" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'fincode',
        label: <HeadCell title="Fin Code" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'utm_source',
        label: <HeadCell title="Utm_Source" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'joined_on',
        label: <HeadCell title="Joined On" />,
        minWidth: 170,
        align: 'center',
        type: 'date',
        format: (value) => value.toFixed(2),
    },
    // {
    //     id: 'plan',
    //     label: <HeadCell title="Subscription" />,
    //     minWidth: 170,
    //     align: 'center',
    //     format: (value) => value.toFixed(2),
    // },
    {
        id: 'active',
        label: <HeadCell title="Action" />,
        minWidth: 170,
        maxWidth: 200,
        align: 'center',
        format: (value) => value.toFixed(2),
        // <AntSwitch />
        action: true
    },
];

const filterSearchData = {
    age: [18, 50],
    gender: [],
    investment: [0, 20],
    broker: [],
    location: [],
    date: {
        from: '',
        to: ''
    }
}

const Users = () => {

    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const [users, setUsers] = useState(null)
    const [userId, setuserId] = useState(null)
    const [locations, setLocations] = useState([])
    const [selectedRange, setSelectedRange] = React.useState([20, 37])
    const tc = React.useRef(null)
    const [filterObject, setFilterObject] = React.useState({ ...filterSearchData })
    const [dates, setDates] = React.useState()
    const [totalUsers, setTotalUsers] = React.useState(0)


    // FILTER ROWS DATA 

    const filterRowsData = [
        {
            name: 'Age',
            type: 'age',
            component: 'slider',
            max: '100',
            data: [
                {
                    value: 0,
                    label: '0',
                },
                {
                    value: 13,
                    label: '13y',
                },
                {
                    value: 43,
                    label: '43y',
                },
                {
                    value: 73,
                    label: '73y',
                },
                {
                    value: 100,
                    label: '100y',
                },

            ]
        },
        {
            name: 'Gender',
            type: 'gender',
            component: 'FilterCheckboxes',
            data: [
                'male',
                'female',
                'other'
            ]
        },
        {
            name: 'Investment',
            type: 'investment',
            component: 'slider',
            max: '20',
            data: [
                {
                    value: 0,
                    label: '0',
                },
                {
                    value: 3,
                    label: '3L',
                },
                {
                    value: 6.5,
                    label: '6.5L',
                },
                {
                    value: 20,
                    label: '20L',
                },

            ]
        },
        {
            name: 'Brokers',
            type: 'brokers',
            component: 'FilterCheckboxes',
            data: [
                'Zerodha',
            ]
        },
        {
            name: 'Location',
            type: 'location',
            component: 'FilterCheckboxes',
            data: locations
        }
    ]


    // GET FILTER DATA 

    const filterData = async (data) => {
        try {
            const res = await http.post(`${BASE_URL}admin/users/filter`, data)
            if (res?.status === 200) {
                setUsers(res?.data)
            }
        } catch (e) {
            console.log(e);
        }
    }

    // OPEN FILTER DRAWER 

    const handleDrawerClick = () => {
        setOpenDrawer(true)
    }

    // ON FILTER CHANGE FUNCTION 

    const onFilterChange = (data) => {
        console.log(data);
        setFilterObject(data)
        filterData(data);
    }

    // FETCH ALL USERS 

    React.useEffect(() => {
        fetchUsers()
    }, [])

    // NAVIGATE TO USER DETAILS SCREEN 

    React.useEffect(() => {
        if (userId) {
            navigate(`/admin/users/${userId}`)
        }
    }, [userId])

    // UPDATE USER STATUS 

    const updateUser = async (id, active) => {
        try {
            const url = `${BASE_URL}admin/user/${id}/status`
            const res = await http.post(url, { status: active })
            if (res?.status === 200) {
                fetchUsers();
            }
        } catch (e) {
            console.log(e);
        }
    }

    // FATCH USERS FUNCTION 

    const fetchUsers = async () => {
        try {
            const url = `${BASE_URL}admin/users-list`
            const response = await http.get(url)
            if (response.status == 200) {
                setUsers(response?.data)
                setTotalUsers(response?.data?.length)
            }
            else setUsers([])
        }
        catch (e) {
            console.log(e)
            setUsers([])
        }
    }

    // SEARCH FILTER LOCATIONS 

    const searchLocation = async (value) => {
        try {
            const url = `${BASE_URL}locations?search=${value}`
            const res = await http.get(url)
            setLocations(res?.data?.locations)
        } catch (e) {
            console.log(e);
        }
    }


    // SEARCH USERS  

    const onUserSearch = async (value) => {
        try {
            const url = `${BASE_URL}admin/user?search=${value}`
            const res = await http.get(url)
            if (res?.status === 200) {
                setUsers(res?.data)
            }
        } catch (e) {
            console.log(e);
            fetchUsers();
        }
    }

    // GET THE SELECTED DATES FIR FILTER 

    const getDatas = async (data) => {
        if (data.startDate?.length === 0 && data.endDate?.length === 0) {
            setDates(data);
        }
        else if (data.startDate?.length > 0 && data.endDate?.length > 0) {
            setDates(data)
        }

    }

    // FILTER DATA BY DATES 

    const fetchData = async () => {
        if (dates) {
            setFilterObject((prevFilterObject) => ({
                ...prevFilterObject,
                date: {
                    from: dates?.startDate,
                    to: dates?.endDate,
                },
            }));

            try {
                const res = await http.post(`${BASE_URL}admin/users/filter`, filterObject);
                if (res?.status === 200) {
                    setUsers(res?.data)
                }
            } catch (e) {
                console.log(e);
                fetchUsers();
            }
        }
    };

    React.useEffect(() => {
        if (dates?.startDate && dates?.endDate) {
            console.log(dates);
            fetchData();
        }
    }, [dates])


    return (
        <>
            <FilterDrawer
                filterObject={filterObject}
                selectedRange={selectedRange}
                setSelectedRange={setSelectedRange}
                openDrawer={openDrawer}
                data={filterRowsData}
                onFilterChange={(value) => onFilterChange(value)}
                onFormChange={() => setOpenDrawer(false)}
                searchLocation={(value) => searchLocation(value)}
                onResetData={() => {
                    setFilterObject(filterSearchData);
                    fetchUsers();
                    setOpenDrawer(false)
                }}
            />

            <div className='w-full flex-1 flex flex-col  px-3'>
                <AdminHeader
                    onUserSearch={(value) => onUserSearch(value)}
                    text='users'
                    getDatas={getDatas}
                    onFormChange={handleDrawerClick}
                    totalPlans={totalUsers}
                />
                <div ref={tc} className='flex  flex-wrap flex-1 mb-2 max-w-[93vw] lg:max-w-full items-start justify-start gap-5 rounded'>
                    <AdminTable
                        height={tc?.current?.offsetHeight}
                        rows={users}
                        columns={columns}
                        click={(id) => {
                            setuserId(id);
                        }}
                        pagination={true}
                        onUpdate={(id, active) => updateUser(id, active)}
                    />
                </div>
            </div>
        </>
        // <div className='bg-red-100 flex-1 m-4'>
        //     <p>Hello World</p>
        // </div>
    )
}

export default Users