import React from "react";
import AdminLayout from "../PrivateComponents/AdminLayout";
import { Route, Routes, Outlet } from "react-router-dom";
import Dashboard from "../Private/Admin/Dashboard";
import Subscription from "../Private/Admin/Subscription";
import Users from "../Private/Admin/Users";
import Notification from "../Private/Admin/Notification";
import Complaints from "../Private/Admin/Complaints";
import Algorithm from "../Private/Admin/Algorithm";
import UserDetails from "../Private/Admin/UserDetails";
import RevenueScreen from "../Private/Admin/RevenueScreen";
import SettingScreen from "../Private/Admin/SettingScreen";
import AdminLogin from "../Private/Admin/AdminLogin";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar, Stack } from "@mui/material";
import { showToast } from "../store/Actions/Action";
import Orders from "../PublicComponents/Orders";
import News from "../Private/Admin/News";
import AddNews from "../Private/Admin/AddNews";
import AdminAuth from "../Private/Admin/AdminAuth";
import HomeLayout from "../PublicComponents/Homelayout";
import Strikes from "../PublicComponents/Strikes";
import Exchanges from "../Public/Exchanges";
import Indexes from "../Public/Indexes";
import Home from "../Public/Home";
import Settings from "../Private/Admin/Settings";
import Indicators from "../Private/Admin/Indicators";
import PrivateUsers from "../Private/Admin/PrivateUsers";
import Actions from "../Private/Admin/Actions";
import EntryConditions from "../Private/Admin/EntryConditions";
import AddEntryCondition from "../Private/Admin/AddEntryCondition";
import SettingSIdeNav from "../PublicComponents/SettingSIdeNav";
import ExitConditions from "../Private/Admin/ExitConditions";
import AddExitCondition from "../Private/Admin/AddExitCondition";
import TrailingCondition from "../Private/Admin/TrailingCondition";
import AddTraillingCondition from "../Private/Admin/AddTraillingCondition";
import AddAction from "../Private/Admin/AddAction";
import IhmGraph from "../PrivateComponents/IhmGraph";
import IhmScreen from "../Private/Market/IhmScreen";
import IndexIndicatorScreen from "../Private/Admin/IndexIndicatorScreen";
import VWAPScreen from "../Public/Options/VWAPScreen";
import AuthBroker from "../Public/Options/AuthBroker";
import { WebSocketProvider } from "../Contexts/WebsocketContext/WebSocketContext";
import { MarketContext, MarketStoreMobx } from "../store/OptionChain.Mobx";
import { observer } from "mobx-react-lite";
// import { MarketMSTProvider, MarketStoreModel, useMst } from "../store/MarketMST";
import { MarketStoreMstProvider } from "../store/mobx/MarketStoreMST";
import Algorithms from "../Public/Equity/Algorithms";
import AlgoDetails from "../Public/Equity/AlgoDetails";
import CompanyDetails from "../Public/Equity/CompanyDetails";
import Watchlists from "../Public/Equity/Watchlists";
import WatchlistDetails from "../Public/Equity/WatchlistDetails";
import EventSourceComponent from "../EventSourceComponent";
import { ToolsProvider } from "../Contexts/ToolsContext/ToolsContext";
import Portfolio from "../Public/Equity/Portfolio";
import BasketStrikes from "../Private/Market/BasketStrikes";
import { MarketStateProvider } from "../Contexts/MarketContext/MarketContext";

const Observer = observer(() => {
  return (
    <WebSocketProvider>
      <ToolsProvider>
        <Outlet />
      </ToolsProvider>
    </WebSocketProvider>
  );
});

// const MarketMobxComponents = () => {
// 	return (
// 		<MarketMSTProvider>
// 			<Observer/>
// 		</MarketMSTProvider>
// 		// <MarketContext.Provider value={new MarketStoreMobx()}>
// 		// </MarketContext.Provider>
// 	)
// }

const MarketMSTComponents = () => {
  return (
    <MarketStoreMstProvider>
      <MarketStateProvider>
        <Observer />
      </MarketStateProvider>
    </MarketStoreMstProvider>
  );
};

const PrivateRoutes = () => {
  const disptch = useDispatch();
  const { snackbar } = useSelector((state) => state.admin);

  const handleClose = (event, reason) => {
    disptch(
      showToast({
        open: false,
        message: snackbar.message,
        duration: 5000,
        type: snackbar.type,
      })
    );
  };

  return (
    <>
      <Routes>
        <Route path="/admin">
          <Route index element={<AdminLogin />} />
        </Route>
        <Route path="/admin/*" element={<AdminAuth />}>
          <Route path="/admin/*" element={<AdminLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="algorithms" element={<Algorithm />} />
            <Route path="subscriptions" element={<Subscription />} />
            <Route path="notifications" element={<Notification />} />
            <Route path="users" element={<Users />} />
            <Route path="users" element={<UserDetails />}>
              <Route path=":user" element={<UserDetails />} />
            </Route>
            <Route path="feedback" element={<Complaints />} />
            <Route path="orders" element={<Orders />} />
            <Route path="revenue" element={<RevenueScreen />} />
            <Route path="settings" element={<SettingScreen />} />
            <Route path="news" element={<News />} />
            <Route path="add-news" element={<AddNews />} />
            <Route path="update-news/:id" element={<AddNews />} />
          </Route>
        </Route>
        <Route path="/admin/auth-broker" element={<AuthBroker />} />
        <Route path="" element={<MarketMSTComponents />}>
          <Route path="/admin/options/*" element={<AdminAuth />}>
            {/* TRADING ROUTES */}
            <Route path="" element={<HomeLayout />}>
              <Route path="basket" element={<BasketStrikes />} />
              <Route path="ihm" element={<IhmScreen />} />
              <Route path="strikes" element={<Strikes />} />
              <Route path="orders" element={<Orders />} />
              <Route path="exchanges" element={<Exchanges />} />
              <Route path="indexes" element={<Indexes />} />
              <Route path="users" element={<PrivateUsers />} />
              <Route path="indicators" element={<Indicators />} />
              <Route path="tools">
                <Route path="index" element={<IndexIndicatorScreen />} />
                <Route path="vwap" element={<VWAPScreen />} />
                <Route path="esource" element={<EventSourceComponent />} />
                <Route path="actions" element={<SettingSIdeNav />}>
                  <Route index element={<Actions />} />
                  <Route path="add-action" element={<AddAction />} />
                  <Route path="entry-conditions">
                    <Route index element={<EntryConditions />} />
                    <Route path="add-entry-conditions">
                      <Route index element={<AddEntryCondition />} />
                      <Route path=":id" element={<AddEntryCondition />} />
                    </Route>
                  </Route>
                  <Route path="exit-conditions">
                    <Route index element={<ExitConditions />} />
                    <Route
                      path="add-exit-conditions"
                      element={<AddExitCondition />}
                    />
                  </Route>
                  <Route path="trailling-conditions">
                    <Route index element={<TrailingCondition />} />
                    <Route
                      path="add-trailling-conditions"
                      element={<AddTraillingCondition />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route path="settings" element={<SettingSIdeNav />}>
                <Route index element={<Settings />} />
                <Route path="sl-index" element={<Settings />} />
                <Route path="sl-ltp" element={<Settings />} />
                {/* <Route path="action"  >
										<Route index element={<Actions />} />
										<Route path="add-action" element={<AddAction />} />
										<Route path="entry-conditions"  >
											<Route index element={<EntryConditions />} />
											<Route path="add-entry-conditions" >
												<Route index element={<AddEntryCondition />} />
												<Route path=":id" element={<AddEntryCondition />} />
											</Route>
										</Route>	
										<Route path="exit-conditions"  >
											<Route index element={<ExitConditions />} />
											<Route path="add-exit-conditions" element={<AddExitCondition />} />	
										</Route>
										<Route path="trailling-conditions" >
											<Route index element={<TrailingCondition />} />
											<Route path="add-trailling-conditions" element={<AddTraillingCondition />} />
										</Route>
									</Route> */}
              </Route>
            </Route>
          </Route>
          {/* EQUITY ROUTES  */}

          <Route path="/admin" element={<AdminAuth />}>
            <Route path="equity" element={<HomeLayout />}>
              <Route path="algorithm">
                <Route index element={<Algorithms />} />
                <Route path=":id" element={<AlgoDetails />} />
                <Route path="company/:id" element={<CompanyDetails />} />
              </Route>
              <Route path="watchlist">
                <Route index element={<Watchlists />} />
                <Route path=":id" element={<WatchlistDetails />} />
              </Route>
              <Route path="portfolio">
                <Route index element={<Portfolio />} />
              </Route>
            </Route>
          </Route>
        </Route>
        {/* Define other routes outside of the admin section */}
      </Routes>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackbar.open}
          autoHideDuration={snackbar.duration}
          onClose={handleClose}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={snackbar.type}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default PrivateRoutes;
