import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppLogo from '../../assets/logo 2 (2) 1.png'
import Footer from '../../PublicComponents/Footer';
import { Link, Outlet, redirect, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import SignUpMobile from '../SignUpMobile';
import ConfirmOtop from './ConfirmOtp'
import SignUp from '../SignUp'
import Login from './Login';
import { PAYMENT_URL } from '../../default.config';
import { Avatar, Button, IconButton, ListItemIcon, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { showLogin, updateEarn, updateLearn } from '../../store/Reducer/EventReducer';
import { ApiService, http } from '../../Services/api.services';
import { setUser } from '../../store/Reducer/UserReducer';
import { Add, Home, Logout } from '@mui/icons-material';
import ForgotPass from './ForgotPass';
import EnterOtp from './EnterOtp';
import ResetPass from './ResetPass';
import Logo from "../../assets/fastr_logo.png";
import { updateSignUp } from '../../store/Reducer/Reducer';

const _form = {
    email: '',
    password: ''
}

const Root = () => {
    const dispatch = useDispatch()
    const events = useSelector((state) => state.events)
    const user = useSelector((state) => state.user)
    const navigate = useNavigate()
    const isSmallScreen = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed
    const drawerWidth = isSmallScreen ? 240 : 400; // Set the width based on the screen size
    const [openSignUp, setOpenSignUp] = React.useState(false);
    const [openLogin, setOpenLogin] = React.useState(false);
    const [openMobile, setOpenMobile] = React.useState(false);
    const [mobile, setmobile] = React.useState('');
    const [openConfirmOtp, setOpenConfirmOtp] = React.useState(false);
    const [openForgotPass, setOpenForgotPass] = React.useState(false);
    const [openEnterOtp, setOpenEnterOtp] = React.useState(false);
    const [openResetPassword, setOpenResetPassword] = React.useState(false);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [signinForm, setSigninForm] = React.useState({ ..._form })
    const [_token, set_token] = React.useState(null)
    const { signUp } = useSelector((state) => state.optionHome);
    const path = useLocation();
    const pathnameSegments = path.pathname.split('/');
    const route = pathnameSegments[2];
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [token, setToken] = React.useState(localStorage.getItem('token'))
    const [userId, setUserId] = React.useState(localStorage.getItem('user_id'))
    const [isLogged, setLogged] = React.useState(false)

    // CHECK SIGNUP TRUE || FALSE 
    React.useEffect(() => {
        if (signUp) {
            setOpenMobile(true);
            dispatch(updateSignUp(false))
        }
    }, [signUp])


    React.useEffect(() => {
        if (events.showlogin) {
            setOpenLogin(true)
        }
    }, [events.showlogin])

    React.useEffect(() => {
        if (token && userId) {
            setLogged(true)
            fetchUser(userId)
        }
    }, [])

    const fetchUser = async (userId) => {
        try {
            const response = await http.get(`user/${userId}`)
            if (response.status == 200) {
                dispatch(setUser(response.data))
            }
        }
        catch (e) {
            console.log('ERROR WHILE FETCHING USER --- ', e)
        }
    }

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleSignup = () => {
        setOpenMobile(true);
    };

    const handleEnterOtp = () => {
        setOpenEnterOtp(true);
    };

    const handleResetPasswod = () => {
        setOpenResetPassword(true);
    };

    const handleLogin = () => {
        const token = localStorage.getItem('token');
        if (token) {
            if (path?.hash?.includes('#start-free-trial')) {
                navigate('/index-options/strikes#start-free-trial')
            }
            else navigate('/index-options/strikes')
        } else {
            setOpenLogin(true)
        }
    }


    const focusToLearn = () => {
        // dispatch(updateLearn({type: 'events', payload: 1 }))
        navigate('/#learn-with-us')
    }

    const focusToEarn = () => {
        // dispatch(updateEarn({type: 'events', payload: 1 }))
        navigate('/#earn-with-us')
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }} className='px-4'>
                <img className='sm:h-12' src={AppLogo} alt="" />
            </Typography>

            <Divider />
            <div className='px-4 w-full py-6 flex flex-col items-start justify-start gap-1'>
                {isLogged ?
                    <div className='flex flex-col justify-center items-center w-full px-2 gap-4'>
                        <Avatar
                            title={user?.name}
                            sx={{
                                bgcolor: '#262626',
                                height: 64,
                                width: 64,
                                fontSize: 32
                            }}
                        >
                            {user?.name ? user?.name[0] : ''}
                        </Avatar>
                        <div>
                            <p className='text-slate-800'>{user?.name}</p>
                            <div className='flex gap-4'>
                                <IconButton color='success' onClick={() => navigate('/index-options/strikes')}>
                                    <Home />
                                </IconButton>
                                <IconButton color='error' onClick={() => handleLogout()}>
                                    <Logout />
                                </IconButton>
                            </div>
                        </div>
                    </div> : null

                }
                <button onClick={() => navigate('/news')}>
                    <h6 className='text-[#767676] font-[Open Sans] text-[2vmax] sm:text-[2vmax] my-2 font-normal tracking-wider'>News</h6>
                </button>
                <button>
                    <h6 className='text-[#767676] font-[Open Sans] text-[2vmax] sm:text-[2vmax] my-2 font-normal tracking-wider'>About</h6>
                </button>
                <button onClick={focusToLearn}>
                    <h6 className='text-[#767676] font-[Open Sans] text-[2vmax] sm:text-[2vmax] my-2   font-normal tracking-wider'>Learn with us</h6>
                </button>
                <button onClick={focusToEarn}>
                    <h6 className='text-[#767676] font-[Open Sans] text-[2vmax] my-2  sm:text-[2vmax]  font-normal tracking-wider'>Earn with us</h6>
                </button>
                {isLogged ?
                    null :
                    <div className='flex flex-col gap-3 w-full'>
                        <Button variant='outlined' onClick={handleLogin} type='button' className='py-2 px-8 rounded-sm'>
                            <h6 className='text-[#757575] font-[Open Sans] text-[1.6vmax] sm:text-[2vmax]  font-semibold tracking-wider'>Login</h6>
                        </Button>
                        <button onClick={handleSignup} type='button' className='bg-[#1C1C1C] py-2 px-8 rounded-sm'>
                            <h6 className='text-white font-[Open Sans] text-[1.6vmax] sm:text-[2vmax]  font-semibold tracking-wider'>Sign Up</h6>
                        </button>
                    </div>
                }
            </div>
        </Box >
    );

    const redirectToLearnWithUs = (id) => {
        // console.log(id)
        const url = `${PAYMENT_URL}/?course=${id}`
        // console.log(url)
        window?.open(url)
    }

    const onNews = () => {
        try {
            navigate('/news')
        }
        catch (e) {
            console.log(`ERROR WHILE NAVIGATING`)
        }
    }


    const onLoginClose = () => {
        setOpenLogin(false)
        dispatch(showLogin({ type: 'showlogin', payload: 0 }))
    }

    // LOGOUT FUNCTION 
    const handleLogout = async () => {
        const api = new ApiService()
        const token = localStorage.getItem('token');
        const response = await api.logout({ token })
        // console.log(response);
        localStorage.clear();
        window.location.reload();
    };


    return (
        <>
            <div className='w-full'>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar component="nav" sx={{ backgroundColor: '#FFFFFF', boxShadow: "none", color: "#111", padding: 0 }} >
                        {/* <Toolbar> */}
                        <div className='flex items-center justify-between w-full p-4 px-6 border-b' >
                            <div>
                                <img className='hover:cursor-pointer h-12' onClick={() => navigate('/')} src={Logo} alt="Logo" />
                            </div>
                            <div className='hidden lg:flex items-center justify-between gap-8 lg:gap-3'>
                                {/* <button onClick={() => onNews()}>
            <h6 className='font-[Open Sans] tracking-wider text-[#767676] text-[1vmax] font-semibold'>Today's Trend</h6>
            </button>
            <h6 className='font-[Open Sans] tracking-wider text-[#767676] text-[1vmax] font-semibold'>About</h6>
            <Link to={'/#learn-with-us'}>
            <h6 className='font-[Open Sans] tracking-wider text-[#767676] text-[1vmax] font-semibold'>Learn with us</h6>
            </Link>
            <button onClick={() => focusToEarn()}>
            <h6 className='font-[Open Sans] tracking-wider text-[#767676] text-[1vmax] font-semibold'>Earn with us</h6>
            </button> */}
                                {isLogged ?
                                    <>
                                        <button onClick={() => handleLogin()}>
                                            <h6 className='tracking-wider text-[#767676] text-[1vmax] font-semibold'>Go to Dashboard</h6>
                                        </button>
                                        <div className='flex gap-4'>
                                            <Avatar
                                                title={user?.name}
                                                sx={{
                                                    bgcolor: '#262626',
                                                    height: 36,
                                                    width: 36
                                                }}
                                                onClick={() => setAnchorElUser(true)}
                                            >
                                                {user?.name ? user?.name[0] : ''}
                                            </Avatar>
                                            <Menu
                                                sx={{ mt: '45px' }}
                                                id="menu-appbar"
                                                anchorEl={anchorElUser}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                open={Boolean(anchorElUser)}
                                                onClose={() => setAnchorElUser(null)}
                                            >
                                                <MenuItem sx={{ py: 1.6, minWidth: 240 }} divider onClick={() => navigate('/index-options/strikes')}>
                                                    <ListItemIcon>
                                                        <Home color='success' fontSize="small" />
                                                    </ListItemIcon>
                                                    <span className='text-slate-500'>Dashboard</span>
                                                </MenuItem>
                                                <MenuItem sx={{ py: 1.6, minWidth: 240 }} divider onClick={() => console.log('cliekc')}>
                                                    <ListItemIcon>
                                                        <Avatar
                                                            title={user?.name}
                                                            sx={{
                                                                bgcolor: '#262626',
                                                                height: 24,
                                                                width: 24
                                                            }}
                                                        >
                                                            <span className='text-sm'>{user?.name ? user?.name[0] : ''}</span>
                                                        </Avatar>
                                                    </ListItemIcon>
                                                    <span className='text-slate-500'>{user?.name}</span>
                                                </MenuItem>
                                                <MenuItem sx={{ py: 1.6, minWidth: 240 }} onClick={handleLogout}>
                                                    <ListItemIcon>
                                                        <Logout color='error' fontSize="small" />
                                                    </ListItemIcon>
                                                    <span className='text-red-400'>Logout</span>
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </> :
                                    <>
                                        {/* <button onClick={handleLogin} type='button'>
                    <h6 className='font-[Open Sans] tracking-wider text-[#767676] text-[1vmax] font-semibold'>Login</h6>
                    </button> */}
                                        <Button
                                            onClick={() => handleSignup()}
                                            variant='text'
                                            size="small"
                                            sx={{
                                                boxShadow: 'none',
                                            }}
                                        >
                                            Sign Up
                                        </Button>
                                        <Button
                                            onClick={handleLogin}
                                            variant='contained'
                                            color='blue'
                                            size="small"
                                            sx={{
                                                boxShadow: 'none',
                                                // borderRadius: 0,
                                                color: '#FFFFFF'
                                            }}
                                        >
                                            Login
                                        </Button>
                                    </>
                                }
                            </div>
                        </div>
                        {isLogged ?
                            <IconButton color='primary' className='lg:!hidden !block' onClick={() => navigate('/index-options/strikes')}>
                                <Home />
                            </IconButton> : null

                        }
                        <IconButton className='lg:!hidden !block' onClick={() => handleDrawerToggle()}>
                            <MenuIcon />
                        </IconButton>
                        {/* </Toolbar> */}
                    </AppBar>
                    <nav>
                        <Drawer
                            // container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true,
                            }}
                            sx={{
                                display: { xs: 'block', sm: 'block' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </nav>
                </Box>
                <div>
                    <Outlet />
                </div>
                <Footer
                    onEarnWithUs={() => focusToEarn()}
                    onLearnWithUs={() => focusToLearn()}
                />
            </div>
            {openConfirmOtp ?
                <ConfirmOtop
                    mobile={mobile}
                    open={openConfirmOtp}
                    onClose={() => setOpenConfirmOtp(false)}
                    onSignUp={() => { setOpenConfirmOtp(false); setOpenSignUp(true) }}
                />
                : null
            }

            {openMobile ?
                <SignUpMobile
                    open={openMobile}
                    onClose={() => setOpenMobile(false)}
                    onRequestOtp={(mobile) => {
                        setOpenConfirmOtp(true)
                        setOpenMobile(false)
                        setmobile(mobile);
                    }}
                    onSignIn={() => { setOpenMobile(false); handleLogin() }}
                />
                : null
            }

            {openLogin ?
                <Login
                    open={openLogin}
                    signinForm={signinForm}
                    setSigninForm={setSigninForm}
                    onClose={() => onLoginClose()}
                    onSignUp={() => { setOpenLogin(false); handleSignup() }}
                    onEnterOtp={() => { setOpenLogin(false); handleEnterOtp() }}
                /> : null
            }

            {openForgotPass ?
                <ForgotPass
                    open={openForgotPass}
                    onClose={() => setOpenForgotPass(false)}
                    onEnterOtp={() => { setOpenForgotPass(false); handleEnterOtp(); }}
                /> : null
            }

            {openEnterOtp ?
                <EnterOtp
                    open={openEnterOtp}
                    email={signinForm?.email}
                    onClose={() => {
                        setOpenForgotPass(false);
                        setOpenEnterOtp(false);
                    }}
                    _token={_token}
                    set_token={set_token}
                    onResetPass={() => { setOpenEnterOtp(false); handleResetPasswod(); }}
                /> : null
            }

            {openResetPassword ?
                <ResetPass
                    open={openResetPassword}
                    token={_token}
                    onClose={() => setOpenResetPassword(false)}
                    onLogin={() => { setOpenResetPassword(false); handleLogin() }}
                /> : null
            }

            {openSignUp ?
                <SignUp
                    open={openSignUp}
                    setOpen={setOpenSignUp}
                    mobile={mobile}
                    onClose={() => { setOpenSignUp(false); }}
                    onSignUp={() => { setOpenSignUp(false); handleLogin() }}
                /> : null
            }

        </>
    );
}

export default Root