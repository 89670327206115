import React from 'react'
import { Drawer, Tab, Tabs } from '@mui/material';
import Position from '../PublicComponents/Position';
import OpenOrders from './OpenOrders';

const MobilePositions = (props) => {
    return (
        <>
            <Drawer
                anchor="bottom"
                open={props.open}
                onClose={() => props.onClose()}
                width='100%'
                SlideProps={{
                    sx: {
                        background: 'transparent',
                        boxShadow: 'none',
                        // width: isLargeScreen ? '340px' : '100%',
                        width: '100%',
                        margin: 'auto',
                        borderRadius: '12px 12px 0px 0px'
                    },
                    bgColor: "white"
                }}
            >
                <div className='flex flex-col w-full bg-white min-h-[300px]'>
                    <Tabs value={props.currentTab} onChange={props.handleTabChange} aria-label="position-order-tabs">
                        <Tab label="Positions" {...a11yProps(0)} />
                        <Tab label="Open Orders" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={props.currentTab} index={0}>
                        <Position
                            data={props.positions}
                            onAdd={(pos) => props.onAdd(pos)}
                            onExit={(pos) => props.onExit(pos)}
                            onRefresh={() => props.onRefresh()}
                            refresh={props.refresh}
                            lossMsgClick={props?.lossMsgClick}
                        />
                    </TabPanel>
                    <TabPanel value={props.currentTab} index={1}>
                        <OpenOrders
                            data={props.openOrders}
                            onModify={(order) => props.onModify(order)}
                            onCancel={(order) => props.onCancel(order)}
                        />
                    </TabPanel>
                </div>
            </Drawer >
        </>
    )
}

export default MobilePositions

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tab-${index}`}
            aria-labelledby={`tab-${index}`}
            className='flex-1 overflow-auto'
            {...other}
        >
            {children}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}