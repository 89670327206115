import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import moment from "moment/moment";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { Skeleton, Tooltip } from "@mui/material";

const todayDate = moment().format("YYYY-MM-DD");
const showIndiaTime = (time) => {
  return new Date(time).getTime() + 19800000;
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 14,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#2A3B43",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    // backgroundColor:
    //     theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: "border-box",
  },
}));  


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const indexes = [
  { type: "nifty", title: "NIFTY" },
  { type: "banknifty", title: "BANK N" },
  { type: "finnifty", title: "FIN N" },
  { type: "midcpnifty", title: "MIDCP N" },
  { type: "sensex", title: "SENSEX" },
  { type: "bankex", title: "BANKEX" },
];

const BasketHeader = ({
  expiry,
  allCurrentExpiry,
  onIndexChange,
  onFormChange,
  currentIndex,
  onExpiryChange,
  market,
  isHideVolume,
  currentExpiry,
  upadteIsHideVolume,
}) => {
  const { admin } = useSelector((state) => state.admin);

  // FIND CURRENT SELECTED INDEX
  const findIndexByType = (type) => {
    return indexes.findIndex((item) => item.type === type);
  };

  const selectedIndex = findIndexByType(currentIndex);

  const updateCurrentIndex = (data, expData) => {
    onIndexChange(data);
  };

  // CHECKBOX STYLE
  const checkboxStyle = {
    "& .css-ahj2mt-MuiTypography-root": {
      fontSize: "12px",
    },
    "& .css-9l3uo3": {
      fontSize: "12px",
    },
  };

  // Create a ref to access the Switch component
  const switchRef = React.useRef();

  const handleDivClick = (e) => {
    // Check if the Switch component reference exists
    if (switchRef.current) {
      // Programmatically trigger a click on the Switch
      switchRef.current.click();
      upadteIsHideVolume(); 
    }
  };

  return (
    <>
      <div className="w-full flex items-start justify-center gap-5 lg:justify-between lg:items-end min-h-[64px] relative ">
        <div className=" ">
          <Tabs
            sx={{
              ".css-1yeb3ou-MuiButtonBase-root-MuiTab-root": {
                padding: "4px 0px",
                borderRight: "1px solid #00000021",
                "&:hover": {
                  backgroundColor: "#eeeeee",
                },
                minWidth: "80px",
              },
              "& .css-kxhebr-MuiTabs-indicator": {
                display: "none",
              },
              "& .Mui-selected": {
                backgroundColor: "#1C1C1C",
                color: "#FFFFFF !important",
                "&:hover": {
                  backgroundColor: "#1C1C1C !important",
                },
              },
              "& .css-wy1qx7": {
                padding: "4px 0px",
                minWidth: "80px",
                textTransform: "capitalize",
                borderRight: "1px solid #00000021",
                "& .css-6w7hx6-MuiTabs-root": {
                  padding: "4px 0px",
                },
                "&:hover": {
                  backgroundColor: "#eeeeee",
                },
              },
              "& .css-p5x7jj": {
                display: "none",
              },
              background: "#fff",
              border: "1px solid #00000021",
              borderBottom: "none",
              width: "fit-content",
              height: "fit-content",
              minHeight: "20px",
              // maxWidth: "100px",
              padding: 0,
              borderRadius: 0,
              padding: 0,
            }}
            size="small"
            value={selectedIndex}
            variant="scrollable"
            aria-label="basic tabs example"
          >
            {indexes?.map((_index, index) => (
              <Tab
                key={index}
                onClick={() => updateCurrentIndex(_index.type)}
                label={
                  <div>
                    <p
                      className={`text-xs ${
                        allCurrentExpiry[_index.type] === todayDate
                          ? "text-red-600"
                          : ""
                      }`}
                    >
                      {_index.title}
                    </p>
                    <p className="text-xs text-gray-400">
                      {market[_index?.type]?.strikePrice?.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                      )}
                    </p>
                    {market[_index?.type]?.change ? (
                      market[_index?.type]?.change ? (
                        market[_index?.type]?.change >
                        market[_index?.type]?.strikePrice ? (
                          <p className="text-[10px] text-red-600">
                            {(
                              market[_index?.type]?.change -
                              market[_index?.type]?.strikePrice
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                            &nbsp; (
                            {Math.abs(
                              market[_index?.type]?.changePercent
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 2,
                            })}
                            %)
                          </p>
                        ) : (
                          <p className="text-[10px] text-green-600">
                            {(
                              market[_index?.type]?.strikePrice -
                              market[_index?.type]?.change
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                            &nbsp; (
                            {Math.abs(
                              market[_index?.type]?.changePercent
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 2,
                            })}
                            %)
                          </p>
                        )
                      ) : (
                        <p className="text-[10px] text-green-600">
                          0.00 (0.00%)
                        </p>
                      )
                    ) : (
                      <p className="text-[10px] text-green-600">0.00 (0.00%)</p>
                    )}
                  </div>
                }
                {...a11yProps(0)}
              />
            ))}
          </Tabs>
        </div>
        {admin ? (
          <div className="flex gap-2 flex-col items-end justify-end  cursor-pointer">
            <Tooltip title="Default SL&T">
              <div
                onClick={handleDivClick}
                className="flex items-center gap-2 border border-[#DFDFDF] rounded p-2"
              >
                <p
                  style={{ color: isHideVolume ? "#373737" : "#3737376E" }}
                  className=" text-sm font-semibold "
                >
                  OI
                </p>
                <AntSwitch ref={switchRef} checked={!isHideVolume} />
                <p
                  style={{
                    color: !isHideVolume ? "#373737" : "#3737376E",
                  }}
                  className="text-sm font-semibold"
                >
                  Vol
                </p>
              </div>
            </Tooltip>
            {/* <FormGroup row>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="OI"
                checked={isHideVolume}
                sx={checkboxStyle}
                onChange={(e) => {
                  if (!isHideVolume) upadteIsHideVolume(e?.target?.checked);
                }}
              />
              <FormControlLabel
                // required
                control={<Checkbox />}
                className="text-xs"
                label="Vol"
                sx={checkboxStyle}
                checked={!isHideVolume}
                onChange={(e) => {
                  if (isHideVolume) upadteIsHideVolume(!e?.target?.checked);
                }}
              />
            </FormGroup> */}  
            {currentExpiry ? (
              <FormControl size="small">
                <InputLabel id="demo-simple-select-label">Expiry</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentExpiry}
                  // size='small'
                  label="Expiry"
                  // MenuProps={MenuProps}
                  onChange={(e) => onExpiryChange(e?.target?.value)}
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {expiry?.map((exp, idx) => (
                    <MenuItem key={idx} value={exp}>
                      {moment(showIndiaTime(exp)).format("DD-MM-YYYY")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default BasketHeader;
