import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { H5, H6 } from "../Theme/ThemeComponent";
import { useThemeContext } from "../Theme/ThemeContext";
import { Divider } from "@mui/material";

export default function ConfirmDeleteWatchlist({
    loading,
    open,
    onCloseWatchlist,
    onRemoveWatchlist,
    message
}) {
    const { theme } = useThemeContext();
    return (
        <Dialog
            open={open}
            onClose={() => onCloseWatchlist()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Remove Watchlist?</DialogTitle>
            <DialogContent
                sx={{
                    maxWidth: 400,
                }}
            >
                <DialogContentText id="alert-dialog-description">
                    <H6 title={'Are you sure you want to remove this Watchlist?'} color={theme.palette.text.coolGray} />
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    size="small"
                    variant="outlined" onClick={onCloseWatchlist}>Cancel</Button>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={loading}
                    autoFocus
                    size="small"
                    onClick={() => onRemoveWatchlist()}
                >
                    Remove
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
