import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { emailRegex, mobileRegex, nameRegex, passwordRegex } from '../ValidationRegex';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { InputAdornment } from '@mui/material';

export default function AddCondition(props) {

    const [age, setAge] = React.useState('');
    const [selectedCondition, setSelectedCondition] = React.useState(null)
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <>
            <Dialog
                // fullScreen={fullScreen}
                open={props?.open}
                onClose={() => props?.onclose()}
                aria-labelledby="responsive-dialog-title"
            // BackdropProps={{ invisible: true }}
            >
                <DialogTitle id="responsive-dialog-title">
                    <div className='w-full flex items-center justify-between'>
                        {"Add condition"}
                        <CloseOutlinedIcon
                            onClick={() => props?.onclose()}
                        />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className='w-full flex flex-col flex-1   h-full items-center justify-center py-8 min-w-[70vw] lg:min-w-[20vw] lg:items-start gap-5'>
                        <div className='w-full '>
                            <FormControl size='small' fullWidth>
                                <InputLabel id="demo-simple-select-label">Select condition</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedCondition}
                                    label="Select Condition"
                                    onChange={(e) => setSelectedCondition(e?.target?.value)}
                                >
                                    <MenuItem value='strikeLtp'>Strike LTP</MenuItem>
                                    <MenuItem value='indexPoints'>Index Points</MenuItem>
                                    <MenuItem value='rsiValue'>RSI Value</MenuItem>
                                    <MenuItem value='pcr/cpr'>PCR / CPR</MenuItem>
                                    <MenuItem value='equilibriumPrice'>Equilibrium Price</MenuItem>
                                    <MenuItem value='candleType'>Candle Type</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='w-full  bg-[#1C1C1C2E] h-[2px] my-2'></div>
                        {selectedCondition ?
                            <div className='w-full  flex flex-col border border-[#E5E5E5] p-4  lg:flex-row gap-3'>
                                {
                                    selectedCondition === 'strikeLtp' ? (
                                        < div className='w-full flex  items-center gap-[2px] justify-between'>
                                            <div className='w-[48%] '>
                                                <TextField
                                                    fullWidth
                                                    size='small'
                                                    id="outlined-basic"
                                                    label="SL"
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <CurrencyRupeeIcon fontSize='small' />
                                                            </InputAdornment>
                                                        ),

                                                    }}
                                                />
                                            </div>
                                            <div className='w-3 h-[2px] bg-[#37373769]'></div>
                                            <div className='w-[48%] '>
                                                <TextField
                                                    fullWidth
                                                    size='small'
                                                    id="outlined-basic"
                                                    label=""
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <CurrencyRupeeIcon fontSize='small' />
                                                            </InputAdornment>
                                                        ),

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) :
                                        selectedCondition === 'indexPoints' ? (
                                            <>
                                                <div className='w-full  '>
                                                    <FormControl size='small' fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Index Point</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={age}
                                                            label="Age"
                                                            onChange={handleChange}
                                                        >
                                                            <MenuItem value={10}>Ten</MenuItem>
                                                            <MenuItem value={20}>Twenty</MenuItem>
                                                            <MenuItem value={30}>Thirty</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className='w-full flex  items-center gap-[2px] justify-between'>
                                                    <div className='w-[48%] '>
                                                        <TextField
                                                            fullWidth
                                                            size='small'
                                                            id="outlined-basic"
                                                            label="Index Point"
                                                            variant="outlined"
                                                        // InputProps={{
                                                        //     startAdornment: (
                                                        //         <InputAdornment position="start">
                                                        //             <CurrencyRupeeIcon fontSize='small' />
                                                        //         </InputAdornment>
                                                        //     ),

                                                        // }}
                                                        />
                                                    </div>
                                                    <div className='w-3 h-[2px] bg-[#37373769]'></div>
                                                    <div className='w-[48%] '>
                                                        <TextField
                                                            fullWidth
                                                            size='small'
                                                            id="outlined-basic"
                                                            label=""
                                                            variant="outlined"
                                                        // InputProps={{
                                                        //     startAdornment: (
                                                        //         <InputAdornment position="start">
                                                        //             <CurrencyRupeeIcon fontSize='small' />
                                                        //         </InputAdornment>
                                                        //     ),

                                                        // }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) :
                                            selectedCondition === 'rsiValue' ? (
                                                <div className='flex  flex-col w-full gap-4 '>
                                                    <div className='w-full flex justify-between gap-4'>
                                                        <div className='w-full  '>
                                                            <FormControl size='small' fullWidth>
                                                                <InputLabel id="demo-simple-select-label">RSI</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={age}
                                                                    label="Age"
                                                                    onChange={handleChange}
                                                                >
                                                                    <MenuItem value={10}>Ten</MenuItem>
                                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className='w-full  '>
                                                            <FormControl size='small' fullWidth>
                                                                <InputLabel id="demo-simple-select-label">Candle</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={age}
                                                                    label="Age"
                                                                    onChange={handleChange}
                                                                >
                                                                    <MenuItem value={10}>Ten</MenuItem>
                                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className='w-full flex  items-center gap-[2px] justify-between'>
                                                        <div className='w-[48%] '>
                                                            <TextField
                                                                fullWidth
                                                                size='small'
                                                                id="outlined-basic"
                                                                label="Index Point"
                                                                variant="outlined"
                                                            // InputProps={{
                                                            //     startAdornment: (
                                                            //         <InputAdornment position="start">
                                                            //             <CurrencyRupeeIcon fontSize='small' />
                                                            //         </InputAdornment>
                                                            //     ),

                                                            // }}
                                                            />
                                                        </div>
                                                        <div className='w-3 h-[2px] bg-[#37373769]'></div>
                                                        <div className='w-[48%] '>
                                                            <TextField
                                                                fullWidth
                                                                size='small'
                                                                id="outlined-basic"
                                                                label=""
                                                                variant="outlined"
                                                            // InputProps={{
                                                            //     startAdornment: (
                                                            //         <InputAdornment position="start">
                                                            //             <CurrencyRupeeIcon fontSize='small' />
                                                            //         </InputAdornment>
                                                            //     ),

                                                            // }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) :
                                                selectedCondition === 'pcr/cpr' ? (
                                                    <div className='flex  flex-col w-full gap-4 '>
                                                        <div className='w-full flex flex-wrap justify-between gap-4'>
                                                            <div className='w-full lg:w-[48%]  '>
                                                                <FormControl size='small' fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">PCR/CPR</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={age}
                                                                        label="Age"
                                                                        onChange={handleChange}
                                                                    >
                                                                        <MenuItem value={10}>Ten</MenuItem>
                                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className='w-full lg:w-[48%]  '>
                                                                <FormControl size='small' fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">EXPIRY</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={age}
                                                                        label="Age"
                                                                        onChange={handleChange}
                                                                    >
                                                                        <MenuItem value={10}>Ten</MenuItem>
                                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className='w-full lg:w-[48%]  '>
                                                                <TextField
                                                                    fullWidth
                                                                    size='small'
                                                                    id="outlined-basic"
                                                                    label="Strike"
                                                                    variant="outlined"
                                                                // InputProps={{
                                                                //     startAdornment: (
                                                                //         <InputAdornment position="start">
                                                                //             <CurrencyRupeeIcon fontSize='small' />
                                                                //         </InputAdornment>
                                                                //     ),

                                                                // }}
                                                                />
                                                            </div>
                                                            <div className='w-full lg:w-[48%]  '>
                                                                <FormControl size='small' fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">PCR/CPR</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={age}
                                                                        label="Age"
                                                                        onChange={handleChange}
                                                                    >
                                                                        <MenuItem value={10}>Ten</MenuItem>
                                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className='w-full flex  items-center gap-[2px] justify-between'>
                                                            <div className='w-[48%] '>
                                                                <TextField
                                                                    fullWidth
                                                                    size='small'
                                                                    id="outlined-basic"
                                                                    label="PCR"
                                                                    variant="outlined"
                                                                // InputProps={{
                                                                //     startAdornment: (
                                                                //         <InputAdornment position="start">
                                                                //             <CurrencyRupeeIcon fontSize='small' />
                                                                //         </InputAdornment>
                                                                //     ),

                                                                // }}
                                                                />
                                                            </div>
                                                            <div className='w-3 h-[2px] bg-[#37373769]'></div>
                                                            <div className='w-[48%] '>
                                                                <TextField
                                                                    fullWidth
                                                                    size='small'
                                                                    id="outlined-basic"
                                                                    label=""
                                                                    variant="outlined"
                                                                // InputProps={{
                                                                //     startAdornment: (
                                                                //         <InputAdornment position="start">
                                                                //             <CurrencyRupeeIcon fontSize='small' />
                                                                //         </InputAdornment>
                                                                //     ),

                                                                // }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) :
                                                    selectedCondition === 'equilibriumPrice' ? (
                                                        <>
                                                            <div className='w-full  '>
                                                                <FormControl size='small' fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">Equilibrium Price</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={age}
                                                                        label="Age"
                                                                        onChange={handleChange}
                                                                    >
                                                                        <MenuItem value={10}>Ten</MenuItem>
                                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <div className='w-full flex  items-center gap-[2px] justify-between'>
                                                                <div className='w-[48%] '>
                                                                    <TextField
                                                                        fullWidth
                                                                        size='small'
                                                                        id="outlined-basic"
                                                                        label="PCR/CPR"
                                                                        variant="outlined"
                                                                    // InputProps={{
                                                                    //     startAdornment: (
                                                                    //         <InputAdornment position="start">
                                                                    //             <CurrencyRupeeIcon fontSize='small' />
                                                                    //         </InputAdornment>
                                                                    //     ),

                                                                    // }}
                                                                    />
                                                                </div>
                                                                <div className='w-3 h-[2px] bg-[#37373769]'></div>
                                                                <div className='w-[48%] '>
                                                                    <TextField
                                                                        fullWidth
                                                                        size='small'
                                                                        id="outlined-basic"
                                                                        label=""
                                                                        variant="outlined"
                                                                    // InputProps={{
                                                                    //     startAdornment: (
                                                                    //         <InputAdornment position="start">
                                                                    //             <CurrencyRupeeIcon fontSize='small' />
                                                                    //         </InputAdornment>
                                                                    //     ),

                                                                    // }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) :
                                                        selectedCondition === 'candleType' ? (
                                                            <div className='flex  flex-col w-full gap-4 '>
                                                                <div className='w-full flex flex-wrap justify-between gap-3'>
                                                                    <div className='w-full lg:w-[48%]  '>
                                                                        <FormControl size='small' fullWidth>
                                                                            <InputLabel id="demo-simple-select-label">Candle Type</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                value={age}
                                                                                label="Age"
                                                                                onChange={handleChange}
                                                                            >
                                                                                <MenuItem value={10}>Ten</MenuItem>
                                                                                <MenuItem value={20}>Twenty</MenuItem>
                                                                                <MenuItem value={30}>Thirty</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                    <div className='w-full lg:w-[48%]  '>
                                                                        <FormControl size='small' fullWidth>
                                                                            <InputLabel id="demo-simple-select-label">Candle</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                value={age}
                                                                                label="Age"
                                                                                onChange={handleChange}
                                                                            >
                                                                                <MenuItem value={10}>Ten</MenuItem>
                                                                                <MenuItem value={20}>Twenty</MenuItem>
                                                                                <MenuItem value={30}>Thirty</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                    <div className='w-full lg:w-[48%]  '>
                                                                        <TextField
                                                                            fullWidth
                                                                            size='small'
                                                                            id="outlined-basic"
                                                                            label="Type"
                                                                            variant="outlined"
                                                                        // InputProps={{
                                                                        //     startAdornment: (
                                                                        //         <InputAdornment position="start">
                                                                        //             <CurrencyRupeeIcon fontSize='small' />
                                                                        //         </InputAdornment>
                                                                        //     ),

                                                                        // }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ) : null

                                }
                            </div>
                            : null}
                    </div>
                </DialogContent>
                <DialogActions>
                    {props?.isEdit ?
                        <LoadingButton
                            loading={props?.isLoading}
                            onClick={() => props?.onUpdate()}
                            sx={{ width: 'fit-content' }}
                            fullWidth
                            variant="contained"
                            size="small"
                            disabled={
                                !(nameRegex.test(props?.addUserData?.name)
                                    && mobileRegex.test(props?.addUserData?.mobile)
                                    && emailRegex.test(props?.addUserData?.email)
                                    // && passwordRegex.test(props?.addUserData?.password)
                                    // && props?.addUserData?.password === props?.confirmPassword
                                )
                            }
                        >
                            Update
                        </LoadingButton>
                        :
                        <LoadingButton
                            loading={props?.isLoading}
                            onClick={() => props?.onAdd()}
                            sx={{ width: 'fit-content' }}
                            fullWidth
                            variant="contained"
                            size="small"
                            disabled={
                                !(nameRegex.test(props?.addUserData?.name)
                                    && mobileRegex.test(props?.addUserData?.mobile)
                                    && emailRegex.test(props?.addUserData?.email)
                                    && passwordRegex.test(props?.addUserData?.password)
                                    && props?.addUserData?.password === props?.confirmPassword
                                )
                            }
                        >
                            Done
                        </LoadingButton>
                    }
                </DialogActions>
            </Dialog >

        </>
    );
}