import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';


import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    FacebookShareCount,
    GabIcon,
    GabShareButton,
    HatenaIcon,
    HatenaShareButton,
    HatenaShareCount,
    InstapaperIcon,
    InstapaperShareButton,
    LineIcon,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    LivejournalIcon,
    LivejournalShareButton,
    MailruIcon,
    MailruShareButton,
    OKIcon,
    OKShareButton,
    OKShareCount,
    PinterestIcon,
    PinterestShareButton,
    PinterestShareCount,
    PocketIcon,
    PocketShareButton,
    RedditIcon,
    RedditShareButton,
    RedditShareCount,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TumblrShareCount,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    VKIcon,
    VKShareButton,
    VKShareCount,
    WeiboIcon,
    WeiboShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceIcon,
    WorkplaceShareButton,
    XIcon,
} from "react-share";
import { DialogContent, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { asyngetStatus, showToast } from '../store/Actions/Action';
import { useDispatch } from 'react-redux';




function ShareDialog({ show, link, onHide, title }) {

    const dispatch = useDispatch()

    const copyLink = () => {
        try {
            navigator?.clipboard.writeText(link)
            const _snackbar = {
                message: 'Copied to clipbnoard',
                open: true,
                duration: 3000,
                type: 'success'
            }
            dispatch(showToast(_snackbar))
        }
        catch (e) {
            console.log('ERRRO ---- ', e)

        }
    }

    return (
        <Dialog onClose={onHide} open={show} sx={{ width: 450, m: 'auto' }}>
            <DialogTitle sx={{ py: 1, px: 2 }}>Share</DialogTitle>
            <DialogContent sx={{ py: 1, px: 2 }}>
                <p className='text-sm text-gray-700 mb-4'>{title}</p>
                <div className='flex gap-4 flex-wrap w-full justify-center'>
                    <FacebookShareButton url={link}>
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <WhatsappShareButton url={link}>
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    <RedditShareButton url={link}>
                        <RedditIcon size={32} round />
                    </RedditShareButton>
                    <TelegramShareButton url={link}>
                        <TelegramIcon size={32} round />
                    </TelegramShareButton>
                    <TwitterShareButton url={link}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
                            <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                        </svg>
                    </TwitterShareButton>
                    <IconButton onClick={() => copyLink()}>
                        <ContentCopyIcon />
                    </IconButton>
                </div>
            </DialogContent>
        </Dialog>
    );
}


export default ShareDialog