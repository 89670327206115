import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { setNewWatchlist, setOpenAddStock, setSelectedCompany, setSelectedWatchlists } from "../store/Reducer/EquityReducer";
import { http } from "../Services/api.services";
import { BASE_URL } from "../default.config";
import { showToast } from "../store/Actions/Action";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AddInstrumentInWatchlist({
  getAllWatchlist,
}) {
  const dispatch = useDispatch();
  const { allWatchlists, openAddStock, selectedWatchlists, selectedCompany } = useSelector((state) => state.equity);
  const { user } = useSelector((state) => state.user);
  ; const [isLoading, setIsLoading] = React.useState(false)
  const handleClose = () => {
    dispatch(setOpenAddStock(false))
  };


  // HANLDLE WATCHLIST SELECTION 
  const handleToggle = (watchlist) => {
    const currentIndex = selectedWatchlists.indexOf(watchlist?.id);
    const newSelectedWatchlists = [...selectedWatchlists];

    if (currentIndex === -1) {
      newSelectedWatchlists.push(watchlist?.id);
    } else {
      newSelectedWatchlists.splice(currentIndex, 1);
    }

    dispatch(setSelectedWatchlists(newSelectedWatchlists));
  };

  // NEED TO FIX IN FREE TIME 
  React.useEffect(() => {
    getAllWatchlist();
  }, []);

  // ADD STOCKS IN WATCHLISTS 
  const addStocks = async () => {
    setIsLoading(true)
    try {
      const id = user?.id
      const res = await http.post(`${BASE_URL}watchlists/${id}/add_stock`, {
        instrument_token: selectedCompany?.instrument_token,
        watchlist_ids: selectedWatchlists
      })
      setIsLoading(false)
      if (res.status == 200) {
        dispatch(setNewWatchlist(res?.data));
        dispatch(setOpenAddStock(false));
        dispatch(setSelectedCompany(null));
        dispatch(setSelectedWatchlists([]));
        showSnackbarsSequentially(res?.data);
        //   for (let index = 0; index < res?.data?.length; index++) {
        //     const element = res?.data[index];
        //     const _snack = {
        //       message: element?.message,
        //       open: true,
        //       type: element?.status,
        //       duration: 3000,
        //     }
        //     dispatch(updateSnackbar(_snack))
        //   }
      }
    } catch (e) {
      console.log(`ERROR WHILE ADDING STOCK ${e}`);
      setIsLoading(false)
    }
  };

  const showSnackbarsSequentially = (data) => {
    let index = 0;

    const showNextSnackbar = () => {
      if (index < data.length) {
        const element = data[index];
        const _snack = {
          message: element?.message,
          open: true,
          type: element?.status,
          duration: 3000,
        };
        dispatch(showToast(_snack));

        // Close the snackbar after the duration
        setTimeout(() => {
          dispatch(showToast({ ..._snack, open: false }));
          index += 1;
          showNextSnackbar(); // Show the next snackbar after closing the current one
        }, _snack.duration);
      }
    };

    showNextSnackbar();
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openAddStock}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          SELECT WATCHLISTS
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ border: "none", minWidth: 300, minHeight: 400 }} dividers>
          <List>
            {allWatchlists ? allWatchlists.map((watchlist) => (
              <ListItem
                key={watchlist?.title}
                onClick={() => handleToggle(watchlist)}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedWatchlists.indexOf(watchlist?.id) !== -1}
                      onChange={() => handleToggle(watchlist)}
                    />
                  }
                  label={watchlist?.title}
                />
              </ListItem>
            )) : null}
          </List>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            size="medium"
            variant="contained"
            loading={isLoading}
            disabled={selectedWatchlists?.length == 0}
            onClick={() => {
              addStocks();
              //   handleClose();
            }}
          >
            Add
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
