import { Button } from "@mui/material";
import React from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import CableRoundedIcon from "@mui/icons-material/CableRounded";
import { useDimensions } from "../../Contexts/DimensionContext/DimensionsContext";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import AddUser from "../../PrivateComponents/AddUser";
import ConnectBroker from "../../PrivateComponents/ConnectBroker";
import { http } from "../../Services/api.services";
import { useDispatch, useSelector } from "react-redux";
import { asyngetStatus, showToast } from "../../store/Actions/Action";
import UserOrderTable from "../../PrivateComponents/UserOrderTable";
import Skeleton from "@mui/material/Skeleton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import RemoveIcon from "@mui/icons-material/Remove";
import { debounce } from "lodash";
import ConfirmRemoveUser from "../../PublicComponents/ConfirmRemoveUser";
import { updatePrivateUsers } from "../../store/Reducer/Reducer";

const _userData = {
  name: "",
  mobile: "",
  email: "",
  factor: "",
  password: "",
};

const _brokerData = {
  userId: "",
  brokerId: "",
  user_name: "",
  api_key: "",
  api_secret: "",
  name: "",
};

const PrivateUsers = (props) => {
  const dispatch = useDispatch();
  const { xs, sm, md, lg, xl, xxl } = useDimensions();
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [connectDialogOpen, setConnectDialogOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [addUserData, setAddUserData] = React.useState(_userData);
  const [addBrokerData, setAddBrokerData] = React.useState(_brokerData);
  const [userId, setUserId] = React.useState(localStorage.getItem("userId"));
  const { admin } = useSelector((state) => state.admin);
  const [confirmPassword, setConfirmPassword] = React.useState(null);
  const [allUsers, setallUsers] = React.useState([]);
  const [currentUser, setcurrentUser] = React.useState(null);
  const [userSelected, setUserSelected] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [brokerId, setbrokerId] = React.useState(null);
  const [brokerName, setBrokerName] = React.useState(null);
  const [userOrders, setUserOrders] = React.useState([]);
  const [removingUser, setRemovingUser] = React.useState(false);
  const [openRemoveUser, setOpenRemoveUser] = React.useState(false);
  const [currenRemovingUser, setCurrenRemovingUser] = React.useState("");
  const { privateUsers } = useSelector((state) => state.optionHome);
  const [tableHeight, setTableHeight] = React.useState(500);
  const parnetDiv = React.useRef(null);
  const childDiv = React.useRef(null);

  // CURRENT SELECTED USER ID
  const [user_Id, setuser_Id] = React.useState(null);

  const id = admin ? admin?.id : userId;
  const KITE_API_LOGIN = `https://kite.zerodha.com/connect/login?v=3&api_key=`;

  React.useEffect(() => {
    getUsers();
    getBroker();
  }, []);

  // ADD NEW USER
  const addUser = async () => {
    try {
      setIsLoading(true);
      const response = await http.post(`/admin/${id}/user`, addUserData);
      console.log(response);
      setIsLoading(false);
      if (response?.status == 200) {
        // RESET DATA
        setAddUserData(_userData);
        setConfirmPassword(null);

        // /OPEN SNACKBAR
        const _snackbar = {
          message: "User created successfully!",
          open: true,
          duration: 3000,
          type: "success",
        };
        dispatch(showToast(_snackbar));

        // UPDATE ADDBROKERDATA
        const updatedBrokerData = {
          ...addBrokerData,
          userId: response?.data[0]?.id,
        };
        setuser_Id(response?.data[0]?.id);
        setAddBrokerData(updatedBrokerData);

        // CLOSE ADD USER DIALOGUE
        setAddDialogOpen(false);
        // OPEN CONNECT BROKER DIALOGUE
        setConnectDialogOpen(true);
        getUsers();
      } else {
        const _snackbar = {
          message: "User already exist!",
          open: true,
          duration: 3000,
          type: "error",
        };
        dispatch(showToast(_snackbar));
      }
    } catch (e) {
      console.log(e?.response?.data?.message?.detail);
      setIsLoading(false);
      console.log("ERROR WHILE UPDATING BROKER ", e);
      const email_id = e?.response?.data?.message?.detail?.includes("email");
      const mobile = e?.response?.data?.message?.detail?.includes("mobile");
      let message_content;
      if (email_id) {
        message_content = "email already exist!";
      } else if (mobile) {
        message_content = "mobile number already exist!";
      } else {
        message_content = "user already exist!";
      }
      const _snackbar = {
        message: message_content,
        open: true,
        duration: 3000,
        type: "error",
      };
      dispatch(showToast(_snackbar));
    }
  };

  // GET ALL USER
  const getUsers = async () => {
    try {
      const response = await http.get(`/admin/${id}/users`);
      if (response?.status == 200) {
        setallUsers(response?.data);
        dispatch(updatePrivateUsers(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMargin = async (user) => {
    try {
      // FETCH MARGIN
      const response = await http.get(`/user/${user?.id}/margins`);
      if (response.status === 200) {
        const _users = [...allUsers];
        for (let index = 0; index < _users.length; index++) {
          if (_users[index].id === user.id) {
            // Create a new user object with the updated balance
            _users[index] = {
              ..._users[index],
              balance: response?.data?.equity?.net,
            };
            setcurrentUser(_users[index]);
          }
        }
        setallUsers(_users);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // GET BROKER
  const getBroker = async () => {
    try {
      const response = await http.get(`/brokers`);
      if (response?.status == 200) {
        const updatedBrokerData = {
          ...addBrokerData,
          brokerId: response?.data[0]?.id,
          name: response?.data[0]?.name,
        };
        setAddBrokerData(updatedBrokerData);
        setbrokerId(response?.data[0]?.id);
        setBrokerName(response?.data[0]?.name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ADD BROKER FUNCTION
  const addBroker = async () => {
    try {
      setIsLoading(true);
      const apiPayloadData = {
        ...addBrokerData,
        userId: user_Id,
        brokerId: brokerId,
        name: brokerName,
      };
      const response = await http.post("/user/add-broker", apiPayloadData);
      setIsLoading(false);
      if (response?.status == 200) {
        // RESET DATA
        setAddBrokerData(_brokerData);

        // /OPEN SNACKBAR
        const _snackbar = {
          message: "Broker added successfully!",
          open: true,
          duration: 3000,
          type: "success",
        };
        dispatch(showToast(_snackbar));

        // CLOSE CONNECT BROKER DIALOGUE
        setConnectDialogOpen(false);
        getUsers();
      } else {
        const _snackbar = {
          message: "api key already exist!",
          open: true,
          duration: 3000,
          type: "error",
        };
        dispatch(showToast(_snackbar));
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e?.response?.data?.message);
      const api_key = e?.response?.data?.message?.detail?.includes("api_key");
      const api_secret =
        e?.response?.data?.message?.detail?.includes("api_secret");
      let message_content;
      if (api_key) {
        message_content = "api_key already exist!";
      } else if (api_secret) {
        message_content = "api_secret already exist!";
      } else {
        message_content = "id already exist!";
      }
      const _snackbar = {
        message: message_content,
        open: true,
        duration: 3000,
        type: "error",
      };
      dispatch(showToast(_snackbar));
    }
  };

  // OPEN CONNECT BROKER DIALOG
  const connectBrokerDialogue = async (el) => {
    setIsEdit(false);
    setConnectDialogOpen(true);

    // Create the updatedBrokerData object
    const updatedBrokerData = {
      ...addBrokerData,
      userId: el?.id,
      user_name: el?.user_name,
      api_key: el?.api_key,
      api_secret: el?.api_secret,
      brokerId: brokerId,
      name: brokerName,
    };

    setuser_Id(el?.id);

    // Update the state with setAddBrokerData
    setAddBrokerData(updatedBrokerData);
  };

  // GET USER ORDERS
  const getUserOrders = async (user_id) => {
    try {
      setUserOrders(null);
      const response = await http.get(`/admin/user/${user_id}/orders`);
      if (response?.status == 200) {
        setUserOrders(response?.data);
      } else setUserOrders([]);
    } catch (e) {
      setUserOrders([]);
      console.log(e);
    }
  };

  // OPEN EDIT BROKER DIALOG
  const openUpdateBrokerDialog = async () => {
    setConnectDialogOpen(true);
    setAddDialogOpen(false);
  };

  // OPEN EDIT DIALOG
  const onEdit = (el) => {
    setAddUserData(el);
    setAddDialogOpen(true);
    setuser_Id(el?.id);
    setIsEdit(true);
    const updatedBrokerData = {
      ...addBrokerData,
      api_key: el?.api_key,
      api_secret: el?.api_secret,
      user_name: el?.user_name,
      userId: el?.id,
    };
    setAddBrokerData(updatedBrokerData);
  };

  // UPDATE FACTOR

  const xUpdateUserFactor = React.useCallback(
    debounce((el) => onUpdateFactor(el), 400),
    []
  );

  const onUpdateFactor = async (el) => {
    try {
      const _payload = {
        factor: el.factor,
      };
      const response = await http.patch(
        `/admin/${id}/user/${el?.id}`,
        _payload
      );
      if (response.status == 200) {
        const _snackbar = {
          message: "Factor updated successfully!",
          open: true,
          duration: 3000,
          type: "success",
        };
        dispatch(showToast(_snackbar));
      }
    } catch (e) {
      console.log(`ERROR WHILE UPDATING FACTOR ${e}`);
    }
  };

  const removeUser = async () => {
    setRemovingUser(true);
    try {
      const _payload = {
        delete: true,
      };
      const response = await http.patch(
        `/admin/${id}/user/${currenRemovingUser?.id}`,
        _payload
      );
      setRemovingUser(false);
      if (response.status == 200) {
        const _snackbar = {
          message: "User removed successfully!",
          open: true,
          duration: 3000,
          type: "success",
        };
        dispatch(showToast(_snackbar));
        getUsers();
        setCurrenRemovingUser("");
        setOpenRemoveUser(false);
      }
    } catch (e) {
      console.log(`ERROR WHILE REMOVING USER ${e}`);
      setRemovingUser(false);
    }
  };

  // UPDATE USER FUNCTION
  const updateUser = async () => {
    try {
      setIsLoading(true);
      const response = await http.patch(
        `/admin/${id}/user/${user_Id}`,
        addUserData
      );
      setIsLoading(false);
      if (response?.status == 200) {
        // RESET DATA
        setAddUserData(_userData);
        setConfirmPassword(null);
        // /OPEN SNACKBAR
        const _snackbar = {
          message: "User updated successfully!",
          open: true,
          duration: 3000,
          type: "success",
        };
        dispatch(showToast(_snackbar));

        // CLOSE ADD USER DIALOGUE
        setAddDialogOpen(false);
        // FETCH ALL USERS
        getUsers();
        setcurrentUser(response?.data);
      } else {
        setConfirmPassword(null);
        const _snackbar = {
          message: "Error while updating user!",
          open: true,
          duration: 3000,
          type: "error",
        };
        dispatch(showToast(_snackbar));
      }
    } catch (e) {
      console.log(e);
      const _snackbar = {
        message: "Error while updating user!",
        open: true,
        duration: 3000,
        type: "error",
      };
      dispatch(showToast(_snackbar));
      setIsLoading(false);
      setAddDialogOpen(false);
      setConfirmPassword(null);
    }
  };

  // UPDATE BROKER FUNCTION
  const updateBroker = async () => {
    try {
      setIsLoading(true);
      const apiPayloadData = { ...addBrokerData, userId: user_Id };
      const response = await http.patch(
        `/admin/${id}/user/${user_Id}`,
        apiPayloadData
      );
      setIsLoading(false);
      if (response?.status == 200) {
        // RESET DATA
        setAddBrokerData(_brokerData);

        // OPEN SNACKBAR
        const _snackbar = {
          message: "Broker updated successfully!",
          open: true,
          duration: 3000,
          type: "success",
        };
        dispatch(showToast(_snackbar));

        // CLOSE CONNECT BROKER DIALOGUE
        setConnectDialogOpen(false);
        // FETCH ALL USERS
        getUsers();
        setcurrentUser(response?.data);
      } else {
        const _snackbar = {
          message: "Error while updating broker!",
          open: true,
          duration: 3000,
          type: "error",
        };
        dispatch(showToast(_snackbar));
      }
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR WHILE UPDATING BROKER ", e);
      const api_key = e?.response?.data?.message?.detail?.includes("api_key");
      const api_secret =
        e?.response?.data?.message?.detail?.includes("api_secret");
      let message_content;
      if (api_key) {
        message_content = "api_key already exist!";
      } else if (api_secret) {
        message_content = "api_secret already exist!";
      } else {
        message_content = "id already exist!";
      }
      const _snackbar = {
        message: message_content,
        open: true,
        duration: 3000,
        type: "error",
      };
      dispatch(showToast(_snackbar));
    }
  };

  // SETTING TABLE HEIGHT   
  React.useEffect(() => {
    let parentHeight = parnetDiv?.current?.offsetHeight;
    let childHeight = childDiv?.current?.offsetHeight;
    setTableHeight(parentHeight - childHeight);
  }, [parnetDiv?.current, childDiv?.current]);

  return (
    <>
      {!userSelected ? (
        // USER LIST SCREEN
        <div className="h-screen w-full overflow-y-auto">
          {privateUsers?.map((el, idx) => {
            return (
              <div
                onClick={() => {
                  fetchMargin(el);
                  setcurrentUser(el);
                  getUserOrders(el?.id);
                  setUserSelected(true);
                }}
                key={idx}
                className="w-full  px-4 lg:px-8 py-4 lg:py-6 bg-white  flex justify-between items-center hover:bg-slate-50 cursor-pointer"
                style={{ borderBottom: "1px solid #eee" }}
              >
                <div className="flex items-center gap-2">
                  <p className="text-xl font-medium mb-1">{el?.name}</p>
                  {/* <p className="text-sm font-normal">({el?.factor}x)</p> */}
                </div>
                {lg || xl || xxl ? (
                  <div className="flex items-center gap-3">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="w-44"
                    >
                      <TextField
                        placeholder="1"
                        size="small"
                        variant="outlined"
                        sx={{
                          backgroundColor: "#FFFFFF",
                          border: "1px solid #000",
                          borderRadius: "5px",
                          // '& .css-12yjm75-MuiInputBase-input-MuiOutlinedInput-input':{
                          //   textAlign:'center'
                          // }
                          "& .MuiInputBase-input": {
                            textAlign: "center",
                          },
                        }}
                        onChange={(e) => {
                          e.stopPropagation();

                          // Get the input value
                          const inputValue = e.target.value;

                          // Check if the input value is a number
                          if (!isNaN(inputValue)) {
                            // Create a copy of the privateUsers array from the Redux store
                            const _allusers = [...privateUsers];

                            // Create a new user object with the updated factor value
                            const updatedUser = {
                              ..._allusers[idx],
                              factor: inputValue,
                            };

                            // Replace the old user object with the updated user object in the array
                            _allusers[idx] = updatedUser;

                            // Dispatch the updated array of users to the Redux store
                            dispatch(updatePrivateUsers(_allusers));

                            // Call the update user factor function
                            xUpdateUserFactor(updatedUser);
                          }
                        }}
                        value={el?.factor}
                        InputProps={{
                          // style: { textAlign: "center" },
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();

                                  // Create a copy of the privateUsers array
                                  const _allusers = [...privateUsers];

                                  if (_allusers[idx].factor > 0) {
                                    // Create a new user object with the updated factor value
                                    const updatedUser = {
                                      ..._allusers[idx],
                                      factor: _allusers[idx].factor - 1,
                                    };

                                    // Replace the old user object with the updated user object in the array
                                    _allusers[idx] = updatedUser;

                                    // Dispatch the updated array of users to the Redux store
                                    dispatch(updatePrivateUsers(_allusers));

                                    // Call the update user factor function
                                    xUpdateUserFactor(updatedUser);
                                  }
                                }}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();

                                  // Create a copy of the privateUsers array from the Redux store
                                  const _allusers = [...privateUsers];

                                  // Create a new user object with the updated factor value
                                  const updatedUser = {
                                    ..._allusers[idx],
                                    factor: _allusers[idx].factor + 1,
                                  };

                                  // Replace the old user object with the updated user object in the array
                                  _allusers[idx] = updatedUser;

                                  // Dispatch the updated array of users to the Redux store
                                  dispatch(updatePrivateUsers(_allusers));

                                  // Call the update user factor function
                                  xUpdateUserFactor(updatedUser);
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    {el?.active === true ? (
                      <Button
                        className="min-w-[150px]"
                        variant="outlined"
                        color="inherit"
                        startIcon={<CheckRoundedIcon />}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        CONNECTED
                      </Button>
                    ) : el?.active === false ? (
                      <Button
                        className="min-w-[150px]"
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshRoundedIcon color="white" />}
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(KITE_API_LOGIN + el?.api_key, "_self");
                          // localStorage.setItem
                          localStorage.setItem(
                            "before-connect",
                            JSON.stringify(el)
                          );
                        }}
                      >
                        RECONNECT
                      </Button>
                    ) : (
                      <Button
                        className="min-w-[150px]"
                        variant="contained"
                        color="primary"
                        startIcon={<CableRoundedIcon color="white" />}
                        onClick={(e) => {
                          e?.stopPropagation();
                          getBroker();
                          connectBrokerDialogue(el);
                        }}
                      >
                        CONNECT
                      </Button>
                    )}

                    <IconButton
                      onClick={(e) => {
                        e?.stopPropagation();
                        onEdit(el);
                      }}
                      aria-label=""
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenRemoveUser(true);
                        setCurrenRemovingUser(el);
                        // removeUser(el);
                      }}
                      aria-label="delete"
                      color="primary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ) : (
                  <div>
                    {el?.active === true ? (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        aria-label="CONNECTED"
                        color="primary"
                      >
                        <CheckRoundedIcon />
                      </IconButton>
                    ) : el?.active === false ? (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(KITE_API_LOGIN + el?.api_key, "_self");
                          // localStorage.setItem
                          localStorage.setItem(
                            "before-connect",
                            JSON.stringify(el)
                          );
                        }}
                        aria-label="RECONNECT"
                        color="primary"
                      >
                        <RefreshRoundedIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={(e) => {
                          e?.stopPropagation();
                          connectBrokerDialogue(el.id);
                        }}
                        aria-label="CONNECT"
                        color="primary"
                      >
                        <CableRoundedIcon fontSize="small" />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={(e) => {
                        e?.stopPropagation();
                        onEdit(el);
                      }}
                      aria-label=""
                      color="primary"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </div>
                )}
              </div>
            );
          })}
          {/* WHEN DATA IS NOT AVAILABLE  */}
          {privateUsers?.length === 0 ? (
            <div className="py-6">
              {[1, 2, 3, 4, 5, 6, 7].map((el) => (
                <div
                  key={el}
                  className="flex justify-between items-center px-4 mb-10"
                >
                  <div>
                    <Skeleton animation="pulse" width={240} height={40} />
                    <Skeleton animation="pulse" width={160} height={24} />
                  </div>
                  <div className="flex items-center gap-2">
                    <Skeleton animation="pulse" width={120} height={48} />
                    <Skeleton animation="pulse" width={20} height={48} />
                    <Skeleton animation="pulse" width={20} height={48} />
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {privateUsers?.length > 0 ? (
            <div className="fixed bottom-20 right-4 lg:bottom-8 lg:right-8">
              <Fab
                onClick={() => {
                  setAddDialogOpen(true);
                  setIsEdit(false);
                  setAddUserData(_userData);
                }}
                color="primary"
                aria-label="add"
              >
                <AddIcon />
              </Fab>
            </div>
          ) : (
            <div className="fixed bottom-20 right-4 lg:bottom-8 lg:right-8">
              <Skeleton
                animation="wave"
                variant="circular"
                width={60}
                height={60}
              />
            </div>
          )}
        </div>
      ) : (
        // DETAILS SCREEN
        <div ref={parnetDiv} className="w-full h-screen px-0 bg-white">
          <div
            ref={childDiv}
            onClick={() => {
              setcurrentUser(null);
              setUserSelected(false);
            }}
            className="w-full px-4 lg:px-8 py-4 lg:py-6   flex justify-between items-center hover:bg-slate-50 cursor-pointer"
            style={{ borderBottom: "1px solid #eee" }}
          >
            <div>
              <div className="flex items-center gap-2">
                <p className="text-xl font-medium mb-1">{currentUser?.name}</p>
                <p className="text-sm font-normal">({currentUser?.factor}x)</p>
              </div>
              <h6 className="text-lg font-bold">
                ₹ {currentUser?.balance ? currentUser?.balance?.toFixed(2) : "--"}  
              </h6>
            </div>
            {lg || xl || xxl ? (
              <div>
                {currentUser?.active === true ? (
                  <Button
                    className="min-w-[150px]"
                    variant="outlined"
                    color="inherit"
                    startIcon={<CheckRoundedIcon />}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    CONNECTED
                  </Button>
                ) : currentUser?.active === false ? (
                  <Button
                    className="min-w-[150px]"
                    variant="contained"
                    color="primary"
                    startIcon={<RefreshRoundedIcon color="whitesmoke" />}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        KITE_API_LOGIN + currentUser?.api_key,
                        "_self"
                      );
                    }}
                  >
                    RECONNECT
                  </Button>
                ) : (
                  <Button
                    className="min-w-[150px]"
                    variant="contained"
                    color="primary"
                    startIcon={<CableRoundedIcon color="white" />}
                    onClick={(e) => {
                      e?.stopPropagation();
                      connectBrokerDialogue(currentUser?.id);
                    }}
                  >
                    CONNECT
                  </Button>
                )}
                <IconButton
                  onClick={(e) => {
                    e?.stopPropagation();
                    onEdit(currentUser);
                  }}
                  aria-label=""
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              </div>
            ) : (
              <div>
                {currentUser?.active === true ? (
                  <IconButton aria-label="CONNECTED" color="primary">
                    <CheckRoundedIcon />
                  </IconButton>
                ) : currentUser?.active === false ? (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        KITE_API_LOGIN + currentUser?.api_key,
                        "_self"
                      );
                    }}
                    aria-label="RECONNECT"
                    color="primary"
                  >
                    <RefreshRoundedIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      connectBrokerDialogue(currentUser.id);
                    }}
                    aria-label="CONNECT"
                    color="primary"
                  >
                    <CableRoundedIcon fontSize="small" />
                  </IconButton>
                )}
                <IconButton
                  onClick={(e) => {
                    e?.stopPropagation();
                    onEdit(currentUser);
                  }}
                  aria-label=""
                  color="primary"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </div>
            )}
          </div>
          <div
            style={{ height: tableHeight }}
            className="w-full flex-1 overflow-auto"
          >
            <UserOrderTable data={userOrders} />
          </div>
        </div>
      )}

      {addDialogOpen ? (
        <AddUser
          open={addDialogOpen}
          isLoading={isLoading}
          onAdd={addUser}
          onUpdate={updateUser}
          isEdit={isEdit}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          addUserData={addUserData}
          setIsLoading={setIsLoading}
          setAddUserData={setAddUserData}
          onclose={() => {
            setAddDialogOpen(false);
          }}
          openUpdateBrokerDialog={() => openUpdateBrokerDialog()}
        />
      ) : null}

      {connectDialogOpen ? (
        <ConnectBroker
          isLoading={isLoading}
          addBroker={addBroker}
          isEdit={isEdit}
          open={connectDialogOpen}
          addBrokerData={addBrokerData}
          setAddBrokerData={setAddBrokerData}
          updateBroker={updateBroker}
          onclose={() => {
            setConnectDialogOpen(false);
            setAddBrokerData(_brokerData);
          }}
        />
      ) : null}
      <ConfirmRemoveUser
        loading={removingUser}
        open={openRemoveUser}
        onRemoveUser={() => removeUser()}
        currenRemovingUser={currenRemovingUser}
        onCloseUser={() => {
          setOpenRemoveUser(false);
          setCurrenRemovingUser("");
        }}
      />
    </>
  );
};

export default PrivateUsers;
