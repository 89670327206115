import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import ActionChildSelect from "../PrivateComponents/ActionChildSelect";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDimensions } from "../Contexts/DimensionContext/DimensionsContext";
import SettingDrawer from "../Private/Admin/SettingDrawer";
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const listItemStyle = {
    // margin: '5px 0',
    // padding: 0,
    padding: '0 10px',
    overflow: 'hidden',
    width: '100%'
}


const notSelectedButton = {
    width: '100%',
    borderBottom: '1px solid #0000003B',
    padding: '16px 24px'
}

const _menu = [
    { name: 'SL & Target (Index)', route: '' },
    { name: 'SL & Target (LTP)', route: 'sl-ltp' },
]

const _menu1 = [
    { name: 'Action', route: 'actions' },
]

const SettingSIdeNav = (props) => {
    const location = useLocation(); 
    const navigate = useNavigate();
    const parentRoute = location.pathname.split('/')[4] || '';
    const childRoute = location.pathname.split('/')[5] || null;
    const [menu, setMenu] = useState(location?.pathname?.includes('tools') ? _menu1 : _menu)
    const nestedChildRoute = location.pathname.split('/')[6] || null;
    const { xs, sm, md, lg, xl, xxl } = useDimensions()
    const [openDrawer, setopenDrawer] = useState(false)
    const [selected, setSelected] = useState(0)

    const selectedButton = {
        width: "100%",
        borderBottom: childRoute ? '1px solid #0000003B' : '4px solid #1C1C1C',
        backgroundColor: '#1C1C1C0A',
        padding: '16px 24px'
    }

    const onMenuChange = (value) => {   
        setSelected(value)
    }

    const _path = location?.pathname?.includes('tools') ? 'tools' : 'settings';
    const navigationPath = `/admin/options/${_path}`;


    return (    
        <>
            <div className="w-full flex h-full">
                {lg || xl || xxl ?
                    <div className="min-w-[300px] w-[300px] bg-[#F8F8F8] border-0 border-r border-solid border-gray-100">
                        <List>
                            {menu?.map((_m, index) => (
                                <ListItem
                                    key={index} // Add a unique key for each item in the array
                                    sx={listItemStyle}
                                    disablePadding
                                    onClick={() => onMenuChange(index)}

                                >
                                    <ListItemButton
                                        sx={parentRoute === _m?.route ? selectedButton : notSelectedButton}
                                        onClick={() => {
                                            navigate(`${navigationPath}/${_m?.route}`)
                                        }}  
                                    >
                                        {/* <ListItemIcon>
                        <TrendingUpIcon color="primary" />
                    </ListItemIcon> */}
                                        <h6 className='text-[#373737] font-semibold text-md'>{_m?.name}</h6>
                                    </ListItemButton>
                                </ListItem>
                            ))} 
                        </List>

                        {location?.pathname?.includes('tools') && <ActionChildSelect />}

                    </div>  
                    :
                    <>
                        <SettingDrawer
                            openDrawer={openDrawer}
                            selected={selected}
                            setSelected={setSelected}
                            onClose={() => setopenDrawer(false)}
                        />
                    </>
                }
                <div className="flex-1 h-full flex flex-col bg-white">
                    <div className="py-4 px-0 lg:px-4 uppercase cursor-pointer">
                        <div className="flex items-center gap-1" onClick={() => setopenDrawer(true)} >
                            {xs || sm || md ?
                                <ArrowForwardIosIcon fontSize="small" color="#3F3F3F" />
                                : null}
                            <h6 className="text-[#3F3F3F] text-lg font-bold">
                                {nestedChildRoute ?
                                    nestedChildRoute?.replace('-', ' ')
                                    : childRoute ?
                                        childRoute?.replace('-', ' ')
                                        : parentRoute ?
                                            parentRoute?.replace('-', ' ')
                                            : 'SL INDEX'
                                }
                            </h6>       
                        </div>  
                    </div>
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default SettingSIdeNav

