import * as React from 'react'
import AdminHeader from "../../PrivateComponents/AdminHeader"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Chip, IconButton, TextField } from '@mui/material';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import { useDispatch } from 'react-redux';
import { showToast } from '../../store/Actions/Action';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { http } from '../../Services/api.services';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import { IMAGE_URL } from '../../default.config';

const AddNews = () => {
    const [title, setTitle] = React.useState('');
    const [content, setContent] = React.useState('');
    const [keyword, setKeyword] = React.useState('');
    const [cover, setCover] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [categories, setCategories] = React.useState([])

    const coverRef = React.useRef()

    React.useEffect(() => {
        setNewsValue()
    }, [params?.id])

    const setNewsValue = async () => {
        try {
            const response = await http.get(`news/${params.id}`)
            console.log(response.data)
            if (response.status === 200) {
                setTitle(response.data.title)
                setContent(response.data.content)
                setCategories(response.data.category)
                setCover(response.data.cover)
            }
        }
        catch (e) {
            console.log('ERROR SETTING NEWS -- ', e)
        }
    }

    const addKeyWords = () => {
        const _cat = [...categories]
        if (_cat.includes(keyword?.trim())) {
            const _snack = {
                message: 'already added!',
                type: 'error',
                duration: 3000,
                open: true
            }
            dispatch(showToast(_snack))
        }
        if (_cat.length < 3) {
            _cat.push(keyword?.trim())
            setCategories(_cat)
            setKeyword('')
        }
        else {
            const _snack = {
                message: 'Max 3 Keywords allowed!',
                type: 'error',
                duration: 3000,
                open: true
            }
            dispatch(showToast(_snack))
        }
    }

    const removeCategory = (index) => {
        const _cat = [...categories]
        _cat.splice(index, 1)
        setCategories([..._cat])
    }

    const onFileChange = (e) => {
        const file = e?.target?.files[0] //SINGLE IMAGE
        if (file.type?.includes('image')) {
            setCover(file)
        }
        else {
            const _snack = {
                message: 'Only image format allowed',
                type: 'error',
                duration: 3000,
                open: true
            }
            dispatch(showToast(_snack))
        }
        // coverRef.current.value = null
    }


    const uploadCover = async (image) => {
        return new Promise(async (resolve, reject) => {
            try {
                const form = new FormData()
                form.append("file", image)
                const response = await http.post('admin/upload', form)
                if (response.status == 200) {
                    resolve(response.data)
                }
            }
            catch (e) {
                console.log('ERROR WHILE UPLOADING ', e)
                reject(e)
            }
        })
    }

    const addNews = async () => {
        try {
            // VALIDATION ADD KRNA HAI 
            setLoading(true)
            const _cover = await uploadCover(cover)
            const body = {
                title, content, category: categories, cover: _cover, author: 'APT'
            }
            const response = await http.post('news', body)
            if (response.status == 200) {
                const _snack = {
                    message: 'News added successfully!',
                    type: 'success',
                    duration: 3000,
                    open: true
                }
                dispatch(showToast(_snack))
                navigate(-1)
            }
            else {
                const _snack = {
                    message: 'Failed to add news!',
                    type: 'error',
                    duration: 3000,
                    open: true
                }
                dispatch(showToast(_snack))
            }
            setLoading(false)
        }
        catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    const updateNews = async () => {
        try {
            // VALIDATION ADD KRNA HAI 
            setLoading(true)
            let _cover
            if (typeof cover == 'object') {
                _cover = await uploadCover(cover)
            }
            else _cover = cover
            const body = {
                title, content, category: categories, cover: _cover, author: 'APT'
            }
            const response = await http.patch(`news/${params?.id}`, body)
            if (response.status == 200) {
                const _snack = {
                    message: 'News updated successfully!',
                    type: 'success',
                    duration: 3000,
                    open: true
                }
                dispatch(showToast(_snack))
                navigate(-1)
            }
            else {
                const _snack = {
                    message: 'Failed to update news!',
                    type: 'error',
                    duration: 3000,
                    open: true
                }
                dispatch(showToast(_snack))
            }
            setLoading(false)
        }
        catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    return (
        <div className="w-full flex-1 px-4 py-6">
            <AdminHeader back={true} text={params?.id ? 'Update News' : 'Add News'} />
            <div className="py-10 mx-4 lg:mx-24 flex flex-col gap-4">
                <TextField
                    fullWidth
                    id="title"
                    variant="outlined"
                    placeholder='Enter Title of the News'
                    inputProps={{ style: { fontSize: 32 } }}
                    value={title}
                    onChange={(e) => setTitle(e?.target?.value)}
                />
                <div>
                    <ReactQuill
                        style={{
                            height: 400
                        }}
                        theme="snow"
                        value={content}
                        onChange={setContent}
                    />
                    <div className='lg:h-12 h-20'></div>
                </div>
                <TextField
                    fullWidth
                    id="keywords"
                    variant="outlined"
                    placeholder='Add Keywords'
                    value={keyword}
                    onChange={(e) => setKeyword(e?.target?.value)}
                    onKeyDown={(e) => (e?.code == 'Enter' ? addKeyWords() : console.log(e))}
                    inputProps={{ style: { fontSize: 14 } }}
                />
                <div className='py-2 lg:min-h-10 flex gap-2 w-full overflow-auto'>
                    {categories?.length ? categories?.map((c, i) => (
                        <Chip size='small' label={c} onDelete={() => removeCategory(i)} />
                    )) : <p className='text-xs opacity-40'>Enter Keywords to add here</p>}
                </div>
                <div
                    onClick={() => coverRef?.current ? coverRef?.current.click() : null}
                    className='hover:bg-gray-100 cursor-pointer flex flex-col justify-center items-center h-96 w-full rounded-lg border-2 border-gray-300'
                >
                    {!cover ?
                        <>
                            <InsertPhotoOutlinedIcon
                                fontSize='large'
                                sx={{
                                    color: '#757575'
                                }}
                            />
                            <p className='text-xs text-gray-400 mt-2'>ADD COVER IMAGE</p>
                            <input
                                className='hidden'
                                ref={coverRef}
                                onChange={(e) => onFileChange(e)}
                                type='file'
                            />
                        </>
                        :
                        <div className='relative bg-white'>
                            <div className='absolute top-0 right-0'>
                                <IconButton color='primary' onClick={() => setCover(null)}>
                                    <HighlightOffRoundedIcon />
                                </IconButton>
                            </div>
                            <img className='h-96' src={(params.id && typeof cover == 'string') ? (IMAGE_URL + cover) : URL.createObjectURL(cover)} />
                        </div>
                    }
                </div>
                <div className='flex justify-end'>
                    {params?.id ?
                        <LoadingButton
                            loading={loading}
                            onClick={() => updateNews()}
                            sx={{ minWidth: 120 }}
                            variant='contained'
                        >
                            UPDATE
                        </LoadingButton>
                        : <LoadingButton
                            loading={loading}
                            onClick={() => addNews()}
                            sx={{ minWidth: 120 }}
                            variant='contained'
                        >
                            ADD
                        </LoadingButton>
                    }
                </div>
            </div>
        </div>
    )
}

export default AddNews