import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ticks: []
}

const TickerReducer = (state = initialState, action) => {
    // console.log(action)
    return action.payload.payload
}

export const Reducer = createSlice({
    name: 'ticker',
    initialState,
    reducers: {
        updateTicks: TickerReducer
    },
})

export const { updateTicks } = Reducer.actions
export default Reducer.reducer  