import React, { useEffect } from 'react'
import AdminHeader from '../../PrivateComponents/AdminHeader'
import AdminSubCard from '../../PrivateComponents/AdminSubCard'
import DialogBox from '../../PrivateComponents/DialogBox'
import Avatar from '@mui/material/Avatar';
import PeopleIcon from '@mui/icons-material/People';
import FilterDrawer from '../../PrivateComponents/FilterDrawer';
import AlgorithmDialog from '../../PrivateComponents/AlgorithmDialog';
import { Box } from '@mui/material';
import ComingSoon from '../../PrivateComponents/ComingSoon';
import FutureRelease from '../../PublicComponents/FutureRelease';



const Algorithm = () => {

    const [open, setOpen] = React.useState(false);
    // const [height, setheight] = React.useState(0)
    const heightDiv = React.useRef(null)

    React.useEffect(() => {
        //  console.log(heightDiv.current.offsetHeight); 
    }, [heightDiv])

    const handleClick = () => {
        setOpen(true)
    }

    return (
        <>
            <div className='bg-[#FFFFFF] px-3 lg:px-3 w-full  flex-1 flex flex-col'>
                <AdminHeader text='ALGORITHM (06)' />
                {/* NOT AVAILABLE ON THIS REALEASE  */}

                {/* <div className='w-full flex items-center justify-start py-5'>
                    <div className='flex items-center justify-between px-3  border border-[#ECEAEA] py-2 lg:py-3 lg:px-5 bg-[#FDFDFD] rounded shadow w-fit gap-6'>
                        <Avatar>
                            <PeopleIcon />
                        </Avatar>
                        <div>
                            <h6 className='text-[#393939] text-[2vmax] lg:text-[1.2vmax] font-semibold'>10,000</h6>
                            <p className='text-[#717171] text-[1.5vmax] lg:text-[.8vmax] font-normal'>Total subscribers</p>
                        </div>
                    </div>
                </div>
                {/* <div className=" flex-1 px-3 py-1  shadow-sm "> */}
                {/* <Box ref={heightDiv} className='flex flex-col  flex-1 w-full items-center lg:flex-wrap justify-between gap-5 lg:flex-row '>
                    <AdminSubCard sub='2056' text='ALGORITHM 01' per='96%' bg='#fff' color='[#F0AD00]' onchange={handleClick} />
                    <AdminSubCard sub='2056' text='ALGORITHM 01' per='96%' bg='#fff' color='[#00B386]' onchange={handleClick} />
                    <AdminSubCard sub='2056' text='ALGORITHM 01' per='96%' bg='#fff' color='[#3366FF]' onchange={handleClick} />
                    <AdminSubCard sub='2056' text='ALGORITHM 01' per='96%' bg='#fff' color='[#F0AD00]' onchange={handleClick} />
                    <AdminSubCard sub='2056' text='ALGORITHM 01' per='96%' bg='#fff' color='[#00B386]' onchange={handleClick} />
                    <AdminSubCard sub='2056' text='ALGORITHM 01' per='96%' bg='#fff' color='[#3366FF]' onchange={handleClick} />
                    <AdminSubCard sub='2056' text='ALGORITHM 01' per='96%' bg='#fff' color='[#F0AD00]' onchange={handleClick} />
                    <AdminSubCard sub='2056' text='ALGORITHM 01' per='96%' bg='#fff' color='[#00B386]' onchange={handleClick} />
                    <AdminSubCard sub='2056' text='ALGORITHM 01' per='96%' bg='#fff' color='[#3366FF]' onchange={handleClick} />
                    <AdminSubCard sub='2056' text='ALGORITHM 01' per='96%' bg='#fff' color='[#F0AD00]' onchange={handleClick} />
                    <AdminSubCard sub='2056' text='ALGORITHM 01' per='96%' bg='#fff' color='[#00B386]' onchange={handleClick} />
                    <AdminSubCard sub='2056' text='ALGORITHM 01' per='96%' bg='#fff' color='[#3366FF]' onchange={handleClick} />
                </Box> */}
                {/* </div> */}

                {/* <AlgorithmDialog open={open} onClose={() => setOpen(false)} />  */}
                {/* NOT AVAILABLE ON THIS REALEASE  */}
                <FutureRelease fontSize={24} message={'Algorithm will be available soon...'} />
            </div>
        </>
    )
}

export default Algorithm