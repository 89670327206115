import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FilterCheckboxes, FilterRange } from './FilterComponents';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const drawerWidth = 270;


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));


const FilterDrawer = (props) => {
    const [filter, setFilter] = React.useState(props.filterObject)


    const handleDrawerClose = () => {
        props.onFormChange();
        // props?.onResetData();
        setFilter(props?.filterObject)
    };

    const onChange = (type, value) => {
        if (value) {
            const _filter = { ...filter }
            _filter[type] = value
            setFilter(_filter)
            console.log(_filter);
            props.onFilterChange(_filter)
        }
    }

    return (
        <>
            <div>

                <Drawer
                    sx={{
                        // width: drawerWidth,
                        // flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            top: 'auto',
                            bottom: 0,
                            height: '93vh',
                            backgroundColor: '#FFFFFF',
                            borderTopLeftRadius: '10px',
                            boxShadow: "-3px 4px 9px 0px rgba(0, 0, 0, 0.16)"
                            // boxShadow
                        },
                        zIndex: '99999',
                        backgroundColor: 'white',
                        // width:'90%'
                    }}
                    variant="persistent "
                    anchor="right"
                    open={props.openDrawer}
                    onClose={handleDrawerClose}
                    // hideBackdrop='false'
                    BackdropProps={{ invisible: true }}
                >
                    <DrawerHeader>
                        <div className='w-full flex items-center justify-between'>
                            <h6 className='text-[#969090] font-semibold tracking-wider '>Filters</h6>
                            <CloseIcon onClick={handleDrawerClose} sx={{ color: '#969090' }} />
                        </div>
                        {/* <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton> */}
                    </DrawerHeader>
                    <Divider />
                    <List>
                        {props.data.map((text, index) => {
                            return (
                                <ListItem sx={{ margin: '5px 0', overflow: 'hidden' }} key={index} disablePadding>

                                    <Accordion sx={{ backgroundColor: "#FFFFFF", boxShadow: "initial", width: '100%' }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ color: '#1C1C1C', position: 'relative', fontSize: { xs: '2vmax', lg: '1vmax' } }} />
                                            }
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            {/* <Typography>Accordion 1</Typography> */}
                                            <h6 className='text-[#1C1C1C] font-semibold text-[1.7vmax] lg:text-[.8vmax]'>{text?.name}</h6>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {text?.name === 'Location' &&
                                                <TextField
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    onChange={(e) => props?.searchLocation(e?.target?.value)}
                                                    id="outlined-basic"
                                                    placeholder="search"
                                                    variant="outlined"
                                                    size='small'
                                                />
                                            }
                                            {text?.component && text?.component === 'slider' ? (
                                                <FilterRange
                                                    selectedRange={filter[text.type]}
                                                    setSelectedRange={(value) => onChange(text.type, value)}
                                                    data={text?.data}
                                                    max={text?.max}
                                                />
                                            ) : (
                                                <FilterCheckboxes
                                                    selectedRange={filter[text.type]}
                                                    setSelectedRange={(value) => onChange(text.type, value)}
                                                    data={text?.data}
                                                />
                                            )}

                                        </AccordionDetails>
                                    </Accordion>
                                </ListItem>
                            )
                        }
                        )}
                        <div className='w-full flex justify-center py-3'>
                            <Button
                                onClick={() => {
                                    props?.onResetData();
                                    setFilter(props?.filterObject)
                                    handleDrawerClose();
                                }}
                                variant="outlined"
                            >
                                <h6 className='text-[#373737] capitalize'>Reset</h6>
                            </Button>
                        </div>
                    </List>
                </Drawer>
            </div >
        </>
    )
}

export default FilterDrawer