import { Outlet } from 'react-router-dom'
// import AppLogo from '../assets/logo 2 (2) 1.png'

const Home = () => {

    return (
        <div className='flex-1 overflow-auto'>
            <Outlet />
        </div>
    )
}

export default Home