import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useThemeContext } from "../Theme/ThemeContext";

const PortfolioTable = ({
    columns,
    rows,
    totalRows,
}) => {
    // console.log(rows);

    const { theme } = useThemeContext();

    // Ensure rows is always an array
    const rowsWithId = Array.isArray(rows)
        ? rows.map((row, index) => ({ id: index, ...row }))
        : [];

    return (
        <>
            <DataGrid
                rows={rowsWithId}
                loading={!Array.isArray(rows)}
                rowCount={totalRows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                sx={{
                    backgroundColor: 'transparent !important',
                    "& .css-1iyq7zh-MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.whisperGray,
                        color: theme.palette.text.coolGray,
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none !important',
                    },
                    '& .css-lm0971-MuiDataGrid-root': {
                        borderColor: 'transparent !important',
                    },
                    overflowY: 'auto',
                    flex: 1,
                }}
                hideFooter={true}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
            />
        </>
    );
};

export default PortfolioTable;
