import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SendIcon from '@mui/icons-material/Send';
import useMediaQuery from '@mui/material/useMediaQuery'

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

const ComplainDrawer = (props) => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const drawerWidth = { width: isLargeScreen ? 500 : '100%' };


    const handleDrawerClose = () => {
        props.onFormChange();
    };


    return (
        <>
            <div className='relative'>
                <Drawer
                    sx={{
                        // width: drawerWidth,
                        // flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            top: 'auto',
                            bottom: 0,
                            height: '93vh',
                            backgroundColor: '#FFFFFF',
                            borderTopLeftRadius: '10px'
                            // boxShadow
                        },
                        position: 'relative',
                        zIndex: '99999',
                        backgroundColor: 'white',
                        // width:'90%'
                    }}
                    variant="persistent"
                    anchor="right"
                    open={props.openDrawer}
                    onClose={props?.onFormChange}
                >
                    <DrawerHeader sx={{ padding: '1vmax' }}>
                        <div className='w-full py-1'>
                            <div className='w-full flex items-center justify-between'>
                                <h6 className='text-[#000000] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>SANSKRATI MOOLCHANDANI</h6>
                                <CloseIcon onClick={handleDrawerClose} sx={{ color: '#969090' }} />
                            </div>
                            <div className='py-1'>
                                <h6 className='text-[#373737] font-semibold text-[1.7vmax] lg:text-[.8vmax]'><span className='text-[#A0A0A0] font-medium'>Category :</span> Others</h6>
                            </div>
                        </div>
                        {/* <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton> */}
                    </DrawerHeader>
                    <Divider />
                    <List sx={{ padding: '1vmax', }}>
                        {['Inbox'].map((text, index) => (
                            <ListItem sx={{ margin: '5px 0' }} key={text} disablePadding>
                                <div>
                                    <div className='flex items-center justify-end w-full py-2'>
                                        <div className='bg-[#1D1E1E08] rounded shadow px-2 py-2 w-3/5'>
                                            <div>
                                                <p className='text-[#615F5F]  font-medium text-[1.3vmax]  lg:text-[.8vmax]'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                                            </div>
                                            <div className='w-full flex items-center justify-end py-1'>
                                                <h6 className='text=[#37373796] text-[1vmax] lg:text-[.6vmax]'>12:30 PM</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex items-center justify-start w-full py-2'>
                                        <div className='bg-[#1D1E1E08] rounded shadow px-2 py-2 w-3/5'>
                                            <div>
                                                <p className='text-[#615F5F]  font-medium text-[1.3vmax]  lg:text-[.8vmax]'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                                            </div>
                                            <div className='w-full flex items-center justify-end py-1'>
                                                <h6 className='text=[#37373796] text-[1vmax] lg:text-[.6vmax]'>12:30 PM</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ListItem>
                        ))}
                    </List>
                    <div className='w-full px-2 py-2 absolute bottom-0 z-50 bg-white flex items-center justify-between'>
                        <div className='w-[72%]'>
                            <div className='w-full border bg-[#FBFBFB] rounded-full border-[#E8E8E8]  py-1 pl-3'>
                                <TextField
                                    fullWidth
                                    size='small'
                                    placeholder='Type here...'
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <div className='bg-[#1C1C1C] w-10 lg:w-8 h-10 lg:h-8 rounded-full flex items-center justify-center'>
                                                    <SendIcon sx={{ color: '#fff', fontSize: '1.1vmax' }} />
                                                </div>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        color: '#BCBCBC',
                                        '& .MuiInputBase-root': {
                                            [theme.breakpoints.down('sm')]: {
                                                fontSize: '1.5vmax', // Font size for large screens
                                            },
                                            [theme.breakpoints.up('lg')]: {
                                                fontSize: '.9vmax', // Font size for large screens
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <div className='w-[27%] h-fit border border-[#EB5B3C] rounded-full py-2 flex items-center justify-center'>
                            <h6 className='text-[#EB5B3C] text-[1.3vmax] lg:text-[.7vmax]'>Close complaint</h6>
                        </div>
                    </div>
                </Drawer>
            </div>
        </>
    )
}

export default ComplainDrawer