
// Email validation regex
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

// Password validation regex (at least 5 characters)
export const passwordRegex = /^.{5,}$/;

// Mobile phone number validation regex
export const mobileRegex = /^[0-9]{10}$/;

// Fin Code validation regex
export const finCodeRegex = /^[a-zA-Z]{3}[0-9]{3}$/;

//  OTP validation regex
export const otpRegex = /^\d{6}$/;

//  DOB validation regex
export const dobRegex = /^(?:19|20)\d\d-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01])$/;

// Name validation regex
export const nameRegex = /^[A-Za-z\s]+$/;

// City name validation regex
export const cityRegex = /^[A-Za-z\s]+$/;

// State name validation regex
export const stateRegex = /^[A-Za-z\s]+$/;

// State name validation regex
export const selectRegex = /^.+$/;

// Pan Number validation regex
export const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;


export const factorRegex = /^(100(\.0{1,2})?|\d{0,2}(\.\d{1,2})?)$/;

