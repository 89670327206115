import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useThemeContext } from "../Theme/ThemeContext";
import { Box } from '@mui/material';

const CompanyTable = ({
    rows,
    columns,
    isPagination,
    totalRows,
    skip,
    setSkip,
    setLimit,
    limit,
    onRowClickFunction
}) => {
    const { theme } = useThemeContext();
    const [hoveredRowId, setHoveredRowId] = useState(null);

    const handleRowHover = (id) => {
        console.log(id, 'hshshs');
        setHoveredRowId(id);
    };

    const handleRowLeave = () => {
        setHoveredRowId(null);
    };

    // Conditional pagination props
    const paginationProps = isPagination
        ? {
            paginationMode: "server",
            paginationModel: {
                pageSize: limit || 20,
                page: skip / (limit || 1) || 0, // prevent division by zero
            },
            onPaginationModelChange: (newPaginationModel) => {
                setSkip(newPaginationModel.page * newPaginationModel.pageSize);
                setLimit(newPaginationModel.pageSize);
            },
            pageSizeOptions: [10, 15, 20, 25, 30, 50, 100],
        }
        : {};

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <DataGrid
                rows={rows || []}
                loading={!rows}   // Show loading spinner if rows are null
                columns={columns}
                rowCount={totalRows}
                hideFooter={!isPagination} // Hide the footer if pagination is not enabled
                disableRowSelectionOnClick
                getRowClassName={(params) =>
                    `data-grid-row ${params.id === hoveredRowId ? 'hovered' : ''}`
                }
                onRowEnter={(params) => handleRowHover(params.instrument_token)}
                onRowLeave={handleRowLeave}
                onRowClick={(params) => onRowClickFunction(params)}
                getRowId={(row) => row.id ? row.id : row.instrument_token}
                sx={{
                    bgcolor: 'transparent !important',
                    "& .css-1iyq7zh-MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.whisperGray,
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none !important'
                    },
                    '& .css-lm0971-MuiDataGrid-root': {},
                    '& .data-grid-row:hover .MuiDataGrid-cell[data-field="hiddenColumn"]': {
                        display: 'flex'
                    },
                    '& .MuiDataGrid-cell[data-field="actions"]': {
                        display: 'flex'
                    },
                    overflowY: 'auto',
                    flex: 1
                }}
                {...(isPagination && paginationProps)} // Conditionally spread pagination props
            />
        </Box>
    );
};

export default CompanyTable;
