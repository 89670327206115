import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const PromptDialog = (props) => {


    return (
        <Dialog
            onClose={props.onClose}
            open={props.open}
        >
            <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#FFFFFF' }} id="customized-dialog-title">
                <h6 className='text-[#3E3E3E] text-[2vmax] font-semibold lg:text-[1.1vmax]'>{props.title}</h6>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon sx={{ color: "#3E3E3E" }} />
            </IconButton>
            <DialogContent sx={{ backgroundColor: "#FFFFFF" }} dividers>
                <p className='text-lg font-light text-slate-600 max-w-[250px]'>{props.message}</p>
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='error' onClick={() => props.onClose()}>
                    CLOSE
                </Button>
                <Button variant='contained' color='primary' onClick={() => props?.onSuccess()}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )

}


export default PromptDialog