import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import LockIcon from '@mui/icons-material/Lock';
import LoginPageImg from '../../assets/Rectangle 447.png'
import CloseIcon from '@mui/icons-material/Close';
import { ApiService, http } from '../../Services/api.services';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { emailRegex, passwordRegex } from '../../ValidationRegex'
import { asyngetStatus, showToast } from '../../store/Actions/Action'
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { Button, Skeleton } from '@mui/material';
const customPlaceholderStyle = {
    color: 'red', // Change the color to your desired color
    fontFamily: 'Arial, sans-serif', // Change the font family to your desired font
};


const _form = {
    password: '',
    confirmPassword: ''
}

const api = new ApiService()

export default function ResetPass(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    // handle Password Visiblity

    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowconfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    // handle Password Visiblity

    const [signinForm, setSigninForm] = React.useState({ ..._form })
    const [isLoading, setIsLoading] = React.useState(false)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [showImage, setShowImage] = React.useState(false)

    //  FORM CHANGE FUNCTION 
    const onFormChange = (name, value) => {
        try {
            const form = { ...signinForm }
            form[name] = value
            setSigninForm(form)
        }
        catch (e) {
            console.log(`ERROR WHILE FORM CHANGE --- ${e}`)
        }
    }

    // RESET PASSWORD FUNCTION 
    const resetPassword = async () => {
        try {
            setIsLoading(true)
            const _body = {
                password: signinForm?.password,
                token: props?.token
            }
            const response = await http.post("/user/password-reset", _body)
            setIsLoading(false)
            if (response.status == 200) {
                const _snackbar = {
                    message: 'password successfully changed',
                    open: true,
                    duration: 3000,
                    type: 'success'
                };
                dispatch(showToast(_snackbar));
                props?.onLogin();
            } else {
                setIsLoading(false);
            }
        }
        catch (e) {
            console.log(`ERROR WHILE LOGIN --- ${e}`)
            setIsLoading(false);
        }
    }

    const inputStyle = {
        // color: 'rgba(55, 55, 55, 0.20)',
        '& .MuiInputBase-root': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '2vmax', // Font size for large screens
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '.8vmax', // Font size for large screens
            },
            bgcolor: '#fff'
        },

    }

    const handleImageLoad = () => {
        setShowImage(true);
    };

    const handleImageError = () => {
        setShowImage(false);
    };


    // Create a new Image object to handle loading
    const image = new Image();
    image.src = LoginPageImg;
    image.onload = handleImageLoad;
    image.onerror = handleImageError;


    return (

        <div>
            <div className='w-full'>
                <Dialog
                    fullScreen={fullScreen}
                    open={props.open}
                    onClose={() => props.onClose()}
                    aria-labelledby="responsive-dialog-title"
                    sx={{
                        '& .css-m9glnp-MuiPaper-root-MuiDialog-paper': {
                            backgroundColor: 'rgba(248, 248, 248, 0.99)'
                        }
                    }}
                >
                    <div style={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }} className='w-full flex'>
                        {showImage ? (
                            <div className='hidden lg:flex w-full bg-[#000000]'>
                                <img
                                    className='w-full h-full object-contain'
                                    src={LoginPageImg}
                                    alt="LoginPageImg"
                                // onLoad={handleImageLoad}
                                // onError={handleImageError}
                                />
                            </div>
                        ) : (
                            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.11)' }} className='hidden lg:block'>
                                <Skeleton width={250} height={'100%'} />
                            </div>
                        )}
                        {/* <img className='w-full h-full object-contain' src={LoginPageImg} alt="LoginPageImg" /> */}
                        <div className='w-full'>
                            <DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }}>
                                <div className='flex flex-col items-center justify-center gap-3 py-6 lg:py-1'>
                                    <h6 className='text-[#373737] font-normal tracking-wider text-[1.5vmax]'>Forgot Password</h6>
                                    <CloseIcon onClick={() => props.onClose()} sx={{ position: "absolute", top: "4%", right: "1%", transform: 'translate(-50%,-50%)' }} />
                                </div>
                            </DialogTitle>
                            <DialogContent sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)' }} >
                                <div className='input_area flex items-center justify-center w-full h-[65vh] lg:h-[50vh] lg:w-[35vw] lg:overflow-y-auto lg:px-4'>
                                    <div className='w-full flex flex-col items-start justify-center gap-5 '>
                                        <div className='w-full  flex flex-col items-start gap-3 lg:gap-2'>

                                            <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Enter New Password</h6>
                                            <TextField
                                                id="input-with-icon-textfield"
                                                placeholder='Enter new Password '
                                                helperText={signinForm.password?.length > 0 && !passwordRegex?.test(signinForm.password) ? " password should have atleast 5 character" : ""}
                                                error={signinForm.password?.length > 0 && !passwordRegex?.test(signinForm.password)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LockIcon sx={{ color: 'rgba(55, 55, 55, 0.20)' }} />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {showPassword ? (
                                                                <VisibilityIcon
                                                                    onClick={handleClickShowPassword}
                                                                    sx={{ cursor: 'pointer', color: 'rgba(55, 55, 55, 0.20)' }}
                                                                />
                                                            ) : (
                                                                <VisibilityOffIcon
                                                                    onClick={handleClickShowPassword}
                                                                    sx={{ cursor: 'pointer', color: 'rgba(55, 55, 55, 0.20)' }}
                                                                />
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                onKeyUp={(event) => {
                                                    if (event.key === 'Enter') {
                                                        resetPassword()
                                                    }
                                                }}
                                                type={showPassword ? 'text' : 'password'}
                                                size="small"
                                                className='custom-placeholder  border !border-[F4F4F4] !shadow-none'
                                                sx={inputStyle}
                                                value={signinForm.password}
                                                onChange={(e) => onFormChange('password', e?.target?.value)}
                                            />
                                        </div>
                                        <div className='w-full  flex flex-col items-start gap-3 lg:gap-2'>

                                            <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Confirm Password</h6>
                                            <TextField
                                                id="input-with-icon-textfield"
                                                placeholder='Confirm Password '
                                                helperText={signinForm?.confirmPassword?.length > 0 && signinForm?.confirmPassword != signinForm?.password ? "password not matched" : ""}
                                                error={signinForm?.confirmPassword?.length > 0 && signinForm?.confirmPassword != signinForm?.password}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LockIcon sx={{ color: 'rgba(55, 55, 55, 0.20)' }} />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {showConfirmPassword ? (
                                                                <VisibilityIcon
                                                                    onClick={handleClickShowconfirmPassword}
                                                                    sx={{ cursor: 'pointer', color: 'rgba(55, 55, 55, 0.20)' }}
                                                                />
                                                            ) : (
                                                                <VisibilityOffIcon
                                                                    onClick={handleClickShowconfirmPassword}
                                                                    sx={{ cursor: 'pointer', color: 'rgba(55, 55, 55, 0.20)' }}
                                                                />
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                onKeyUp={(event) => {
                                                    if (event.key === 'Enter') {
                                                        resetPassword()
                                                    }
                                                }}
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                size="small"
                                                className='custom-placeholder  border !border-[F4F4F4] !shadow-none'
                                                sx={inputStyle}
                                                value={signinForm.confirmPassword}
                                                onChange={(e) => onFormChange('confirmPassword', e?.target?.value)}
                                            />
                                        </div>
                                        <LoadingButton
                                            loading={isLoading}
                                            sx={{ padding: '.6vmax 0', boxShadow: 'none' }}
                                            onClick={resetPassword}
                                            fullWidth
                                            disabled={
                                                !(passwordRegex.test(signinForm.password) && signinForm?.confirmPassword === signinForm?.password)
                                            }
                                            variant="contained"
                                            size="small"
                                        >
                                            <h6 className='text-[#FFFFFF] font-normal text-[2vmax] tracking-wider lg:text-[.8vmax]'>Confirm Password</h6>
                                        </LoadingButton>
                                    </div>
                                </div>
                            </DialogContent>
                        </div>
                    </div>

                </Dialog >
            </div >
        </div >
    );
}
