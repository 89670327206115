import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Avatar, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { Add, CurrencyRupee, EditOutlined, LightbulbRounded, LocalLibrary, Logout, Newspaper } from "@mui/icons-material";
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import SchoolIcon from '@mui/icons-material/School';


export default function HomeDrawer({ isOpen, onClose, currentRoute, openBroker, onConnectBroker, earnwithus, learnwithus, onNewsClick, onUpdateBroker, onLogout }) {

    const user = useSelector((state) => state.user)
    const home = useSelector((state) => state.optionHome)
    const { admin } = useSelector((state) => state.admin)

    const list = () => (
        <Box
            sx={{ width: 240, display: 'flex', flexDirection: 'column', height: '100%', paddingBottom: 4 }}
            role="presentation"
            onClick={() => onClose(false)}
            onKeyDown={() => onClose(false)}
        >
            <div className='flex flex-col justify-center items-center w-full px-2 py-6 gap-4'>
                <div className='relative'>
                    <div className={`w-3 h-3 rounded-full absolute bottom-[4px] right-[4px] z-50 ${home?.brokerConnected ? 'bg-green-600' : 'bg-red-600'}`}></div>
                    <Avatar 
                        title={user?.name}
                        sx={{
                            bgcolor: '#262626',
                            height: 64,
                            width: 64,
                            fontSize: 32
                        }}
                    >
                        {admin?.name ? admin?.name[0] :
                            user?.name ? user?.name[0] : ''
                        }
                    </Avatar>
                </div>
                <div>
                    <p className='text-slate-800'>{user?.name}</p>
                </div>
                {!user?.broker?.length ?
                    <Button variant='text' size='small' className='!bg-yellow-50 gap-2' onClick={openBroker}>
                        <Add color='success' fontSize="small" />
                        <span className='text-slate-500'>Add Broker</span>
                    </Button> : home.brokerConnected ?
                        <Button variant='text' size='small' className='!bg-green-50 gap-2'>
                            <DoneIcon color='success' fontSize="small" />
                            <span className='text-slate-500'>Broker Connected</span> 
                        </Button> :
                        <Button variant='text' size='small' onClick={onConnectBroker} className='!bg-red-50 gap-2'>
                            <ErrorIcon color='error' fontSize="small" />
                            <span className='text-slate-500'>Not Connected</span>
                        </Button>
                }
            </div>
            <Divider component={'div'} />
            <List>
                <ListItem disablePadding onClick={() => { onNewsClick(); onClose() }}>
                    <ListItemButton className={currentRoute?.includes('/home/news') ? '!bg-gray-200' : ''}>
                        <ListItemIcon>
                            <Newspaper color='primary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Today's Trend" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => { earnwithus(); onClose() }}>
                    <ListItemButton className={currentRoute?.includes('/home/plans/') ? '!bg-gray-200' : ''}>
                        <ListItemIcon>
                            <LightbulbRounded color='primary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary='Earn with us' />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => { learnwithus(); onClose() }}>
                    <ListItemButton className={currentRoute?.includes('/home/learn-with-us') ? '!bg-gray-200' : ''}>
                        <ListItemIcon>
                            <SchoolIcon color='primary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary='Learn with us' />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => { onUpdateBroker(); onClose() }}>
                    <ListItemButton>
                        <ListItemIcon>
                            <EditOutlined color='primary' fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary='Update Broker' />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <div className='flex-1'></div>
            <List>
                <ListItem color='error' disablePadding onClick={() => { onLogout(); onClose() }}>
                    <ListItemButton color='error'>
                        <ListItemIcon>
                            <Logout color='error' fontSize="small" />
                        </ListItemIcon>
                        <span className='text-red-600'>Logout</span>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <Drawer
            anchor={'right'}
            open={isOpen}
            onClose={() => onClose(false)}
        >
            {list()}
        </Drawer>
    );
}
