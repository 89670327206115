import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { FormControl, IconButton, MenuItem, Select, useTheme } from '@mui/material';
import { http } from '../Services/api.services';
import { Close } from '@mui/icons-material';

export default function BrokerForm({ open, userBroker, onClose, type, brokers, user, onSubmit, isFullScreen }) {
    const theme = useTheme();
    const sx = {
        // color: 'rgba(55, 55, 55, 0.20)',
        background: 'transparent',
        fontSize: { lg: '.9vmax' },
        '& .MuiInputBase-root': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '2vmax',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '.8vmax',
            },
        },
    }

    const [api_key, setApiKey] = React.useState(userBroker?.api_key || '');
    const [api_secret, setApiSecret] = React.useState(userBroker?.api_secret || '');
    const [user_id, setUserId] = React.useState(userBroker?.user_name || '');
    const [validations, setValidations] = React.useState({
        user_id: { blur: false },
        api_key: { blur: false },
        api_secret: { blur: false }
    })
    const [brokerStr, setBrokerStr] = React.useState('Zerodha');
    const [loading, setLoading] = React.useState('');

    const submit = async () => {
        try {
            setLoading(true)
            const body = {
                "brokerId": brokers[0]?.id,
                "name": brokers[0]?.name,
                "userId": user?.id,
                "user_name": user_id,
                "api_key": api_key,
                "api_secret": api_secret
            }
            if (type == 'add') {
                const response = await http.post(`/user/add-broker`, body)
                setLoading(false)
                if (response.status == 200) {
                    onClose()
                    onSubmit({ message: 'Broker added successfully!', open: true, type: 'success' })
                }
                else {
                    onSubmit({ type: response?.data?.status, message: response?.data?.message, open: true, duration: 5000 })
                }
            }
            else {
                delete body.userId
                delete body.brokerId
                delete body.name
                const response = await http.post(`/user/borker/${userBroker?.id}`, body)
                setLoading(false)
                if (response.status == 200) {
                    onSubmit({ message: 'Broker added successfully!', open: true, type: 'success', duration: 5000 })
                }
                else {
                    onSubmit({ type: response?.data?.status, message: response?.data?.message, open: true, duration: 5000 })
                }
            }
        }
        catch (e) {
            setLoading(false)
            console.log('add broker error', e)
            onSubmit({ type: 'error', message: e?.response?.data?.message || 'Something went wrong!', open: true, duration: 5000 })
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen={isFullScreen}>
            <div className='flex justify-between items-center pr-6'>
                <DialogTitle>{type?.toUpperCase()} BROKER</DialogTitle>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </div>
            <DialogContent>
                <FormControl className='!mb-4' sx={{ backgroundColor: 'white' }} fullWidth size='small' >
                    <h6 className='text-[#373737] font-semibold tracking-wider text-sm mb-1'>Select Broker<span className='text-red-600 text-xs'>*</span></h6>
                    <Select
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        fullWidth
                        value={brokerStr}
                        size='small'
                        sx={sx}
                    >
                        {brokers?.map((broker, index) => (
                            <MenuItem key={index} value={broker?.name} disabled selected>
                                <em>{broker?.name}</em>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className='w-full flex flex-col items-start gap-1 mb-2'>
                    <h6 className='text-[#373737] font-semibold tracking-wider text-sm'>User ID<span className='text-red-600 text-xs'>*</span></h6>
                    <TextField
                        id="input-with-icon-textfield"
                        placeholder='Enter User ID'
                        fullWidth
                        value={user_id}
                        onChange={(e) => setUserId(e?.target?.value)}
                        size='small'
                        type='text'
                        className='custom-placeholder bg-white  border !border-[F4F4F4] !shadow-none '
                        onBlur={() => setValidations((prevState) => ({ ...prevState, user_id: { blur: true } }))}
                        sx={sx}
                        error={validations.user_id.blur && !user_id}
                        helperText={validations.user_id.blur && !user_id ? 'User ID is required' : ' '}
                    />
                </div>
                <div className='w-full flex flex-col items-start gap-1 mb-2'>
                    <h6 className='text-[#373737] font-semibold tracking-wider text-sm'>API Key<span className='text-red-600 text-xs'>*</span></h6>
                    <TextField
                        id="input-with-icon-textfield"
                        placeholder='Enter API Key'
                        fullWidth
                        value={api_key}
                        onChange={(e) => setApiKey(e?.target?.value)}
                        size='small'
                        type='text'
                        className='custom-placeholder bg-white  border !border-[F4F4F4] !shadow-none '
                        onBlur={() => setValidations((prevState) => ({ ...prevState, api_key: { blur: true } }))}
                        sx={sx}
                        error={validations.api_key.blur && !api_key}
                        helperText={validations.api_key.blur && !api_key ? 'API Key is required' : ' '}
                    />
                </div>
                <div className='w-full flex flex-col items-start gap-1'>
                    <h6 className='text-[#373737] font-semibold tracking-wider text-sm'>API Secret<span className='text-red-600 text-xs'>*</span></h6>
                    <TextField
                        id="input-with-icon-textfield"
                        placeholder='Enter API Secret'
                        fullWidth
                        value={api_secret}
                        onChange={(e) => setApiSecret(e?.target?.value)}
                        size='small'
                        type='text'
                        className='custom-placeholder bg-white  border !border-[F4F4F4] !shadow-none '
                        sx={sx}
                        onBlur={() => setValidations((prevState) => ({ ...prevState, api_secret: { blur: true } }))}
                        error={validations.api_secret.blur && !api_secret}
                        helperText={validations.api_secret.blur && !api_secret ? 'API Secret is required' : ' '}
                    />
                </div>
            </DialogContent>
            <DialogActions sx={{ px: 3 }}>
                <Button onClick={onClose}>Cancel</Button>
                <LoadingButton
                    disabled={!api_key || !api_secret || !user_id || !brokerStr}
                    variant='contained'
                    color='primary'
                    loading={loading}
                    onClick={() => submit()}
                >
                    {type}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}