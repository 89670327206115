import * as React from 'react'
import { http } from '../Services/api.services'
import { BASE_URL } from '../default.config'
import HomepageHeaders from '../PublicComponents/HomepageHeaders'
import LearnCard from '../PublicComponents/LearnCard'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from '@mui/material'

const LearnHome = () => {

    const navigate = useNavigate()
    const [learnCourses, setLearnCourses] = React.useState(null)

    React.useEffect(() => {
        getCourses()
        document.title = 'Learn with us | Fastr'
        return () => {
            document.title = 'Fastr'
        }
    }, [])

    const getCourses = async () => {
        try {
            const url = `${BASE_URL}course`
            const res = await http.get(url)
            setLearnCourses(res?.data)
        } catch (e) {
            console.log(e);
            setLearnCourses([])
        }
    }

    const redirectToLearnWithUs = (course) => {
        navigate(`/home/learn-with-us/${course?.id}`, { state: { course: course } })
    }

    return (
        <>
            <div className='py-20 relative'>
                <HomepageHeaders heading='Learn with us!' desc='Learn all these courses with us' />
                <br></br>
                <br></br>
                <div className='w-full flex items-center justify-center'>
                    <div className='w-full lg:w-3/4 overflow-x-auto  flex flex-wrap items-start justify-center gap-6 py-4'>
                        {learnCourses ? learnCourses?.length ? learnCourses?.map((learn, index) => (
                            <LearnCard
                                key={index}
                                index={index}
                                amount={learn?.price}
                                title={learn?.title}
                                description={learn?.description}
                                duration={learn?.duration}
                                support={learn?.support}
                                original={learn?.strikethrough}
                                textColor={true ? '#00B386' : '#F0AD00'}
                                bg={true ? '#00B3863B' : "#F0AD0012"}
                                onPress={() => redirectToLearnWithUs(learn)}
                            />
                        )) : <p className='text-sm text-slate-600'>NO COURSES FOUND!</p> :
                            [1, 2, 3, 4, 5, 6].map((empty) => (
                                <div className='hover:shadow-lg cursor-pointer flex w-[80%] lg:w-[30%] flex-col items-center justify-center gap-2  bg-[#FFFFFF] border border-[#E0E0E0] shrink-0 rounded shadow py-2 px-20'>
                                    <Skeleton
                                        height={36}
                                        width={'70%'}
                                    />
                                    <Skeleton
                                        height={28}
                                        width={'90%'}
                                    />
                                    <Skeleton
                                        height={40}
                                        width={'100%'}
                                    />
                                    <p>&nbsp;</p>
                                    <Skeleton
                                        height={40}
                                        width={'60%'}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default LearnHome