import React, { useEffect } from "react";
import { types, effect, action } from "mst-effect";
import { BehaviorSubject, bufferTime, from, fromEvent, map, of, pipe } from "rxjs";
import { useSelector } from "react-redux";

let ALL_RANKINGS = {
    htb: [false, false, false, true, true, true, true, true, true, true, true, true],
    keyInChain: ['oiPCR', 'oiCPR', 'indexLTP', 'volume', 'volPCR', 'volCPR', 'inMinute', 'tbs', 'theta', 'smb', 'oi'],
    key: ['oiPCR', 'oiCPR', 'indexLTP', 'volume', 'volPCR', 'volCPR', 'inMinute', 'tbs', 'theta', 'smb', 'oi'],
    value: ['OI PCR', 'OI CPR', 'INDEX/LTP', 'VOLUME %', 'VOL PCR', 'VOL CPR', 'VOL 1/5 Min', 'TBO/TSO', 'Theta', 'BS-BB', 'OI']
}



/**
 * 1. CREATE CONTEXT FOR MARKET STORE 
 * 2. CONNECT TO WEB WORKER AND START RECEIVING DATA 
 * 3. USE rxjs TO GET onmessage CALLBACK EVENT FROM WORKER
 * 4. DEFINE MODEL FOR MARKET DATA WITH OPTION CHAIN, INDEX, CURRENT INDEX, EXPIRY, CURRENT EXPIRY, RANKS AND OTHERS
 * 5. DEFINE ACTIONS FOR MST STORE MODEL AND USE mst-effect `effect` TO AUTO RECEIVE EVENTS
 * 6. ALSO DEFINE METHODS IN ACTION TO UPDATE INDEX, CURRENT INDEX, EXPIRY, CURRENT EXPIRY, RANKS AND OTHERS 
 * 7. USE RXJS - bufferTime TO HOLD FOR BACKPRESSURE 
 */
export const MarketMstContext = React.createContext()

// WEBSOCKET WORKER -- CONNECTION CONFIGURATION
// const worker = new Worker('/websocket.worker.js')
// let observable$ = fromEvent(worker, 'message')

// PER TICK PROFIT LOSS
// const plWorker = new Worker('/pl.worker.js')
// let pl_observable$ = fromEvent(plWorker, 'message')

let observable$ = new BehaviorSubject([]);
let instruments$ = new BehaviorSubject({});


function updateObservable(newValue) {
    observable$.next(newValue);
}

let foreground = true
const updateForeground = (value) => {
    foreground = value
}

export const MarketStoreMstProvider = ({ children }) => {

    // useEffect(() => {
    //     function handleVisibilityChange() {
    //         if (document.hidden) {
    //             worker.postMessage({ status: 'pause' })
    //             updateForeground(false)
    //             // worker.terminate()
    //         } else {
    //             worker.postMessage({ status: 'resume' })
    //             updateForeground(true)
    //         }
    //     }
    //     document.addEventListener('visibilitychange', handleVisibilityChange);
    // }, [])

    // const rankings = useSelector(state => state.rankings)

    const MarketData = types
        .model({
            currentIndex: types.maybe(types.string),
            nifty: types.maybeNull(types.frozen()),
            banknifty: types.maybeNull(types.frozen()),
            finnifty: types.maybeNull(types.frozen()),
            midcpnifty: types.maybeNull(types.frozen()),
            sensex: types.maybeNull(types.frozen()),
            bankex: types.maybeNull(types.frozen()),
            instruments: types.maybeNull(types.frozen()),
            previousTicks: types.maybeNull(types.frozen()),
        })
        .actions(self => ({
            // updateIndex: effect(self, () => {
            //     // async function update(events) {
            //     //     console.log(events.data)
            //     //     const workerData = events.data;
            //     //     self.setMarketIndex(workerData.index, JSON.parse(workerData?.data));
            //     // }
            //     // return observable$?.pipe(
            //     //     map((newdata) => action(update, newdata))
            //     // );
                
            //     async function update(events) {
            //         // console.log(events.length)
            //         for (let index = 0; index < events.length; index++) {
            //             const workerData = events[index].data
            //             // console.log('RECEIVED AT STORE')
            //             // self.setMarketIndex(workerData.index, JSON.parse(workerData?.data))
            //             // self.setMarketIndex(workerData.index, workerData?.data)
            //         }
            //     }
            //     return observable$?.pipe(
            //         bufferTime(1),
            //         map((newdata) => action(update, newdata))
            //     )
            // }),
            updateIndex(index, data) {
                self[index] = data
            },
            updateIndexPromise(index, data) {
                return new Promise((resolve, reject) => {
                    try {
                        self[index] = { ...data }
                        // console.log('KEY -- MST -- ', self[index])
                        resolve(self[index])
                    }
                    catch (e) {
                        reject(e)
                    }
                })
            },
            updateExpiryMarket(key, exp, data) {
                const _temp = { ...self[key] }
                _temp[exp] = { ...data }
                self[key] = _temp
            },
            updateMarketIndex({ nifty, banknifty, finnifty, midcpnifty, sensex, bankex }) {
                self.nifty = nifty
                self.banknifty = banknifty
                self.finnifty = finnifty
                self.midcpnifty = midcpnifty
                self.sensex = sensex
                self.bankex = bankex
            },
            setCurrentIndex(index) {
                self['currentIndex'] = index
            },
            setMarketIndex(index, value) {
                self[index] = { ...self[index], ...value }
                // console.log(self[index])
            },
            setExpiryIndex(index, expiry, data) {
                self[index] = { ...self[index], [expiry]: data }
            },
            setCurrentExpiry(index, expiry) {
                self[index].currentExpiry = expiry
            },
            setExpiry(index, expiry) {
                self[index].expiry = expiry
            },
            setOptionChain(instrument, type, index, data) {
                let market = JSON.parse(JSON.stringify(self[index]))
                // console.log(self[index], instrument, type, index, data)
                market.options[type][`'${instrument}'`] = data
                self[index] = market
            },
            setOptions(options, index) {
                self[index].options = options
            },
            setData(newData) {
                self.data = newData;
            },
            setInstruments(inst) {
                self.instruments = { ...self.instruments, ...inst }
                instruments$.next(inst)
                // console.log(self.instruments)
            },
            setIndexData(index, value) {
                try {
                    if (self.data && self.data.length > index) {
                        if (value?.data) {
                            const __data = [...self.data]
                            // console.log(self.data[index], value.data)
                            __data[index] = value.data;
                            // console.log(__data)
                            state.setData(__data)
                        }
                        else {
                            // console.log(value)
                        }
                    }
                }
                catch (e) {
                    console.log(e)
                }
            },
            startWebSocket(params) {
                // START WEBSOCKET FOR THE RECEIVED INDEX
                // worker.postMessage({ ...params, ALL_RANKINGS })
                // connectWebSocket(url, instruments)
            },
            updateMarketWs(params) {
                if (params?.updateTicker) {
                    const _params = {
                        marketUpdate: true,
                        market: params?.market,
                        instruments: params.instruments
                    }
                    // worker.postMessage(_params)
                }
            },
            setMarket(market) {
                self = market
            },
            setLastDayTick(data) {
                self.previousTicks = data
            },
            startLastDayTicks(ticks, oc, details) {
                // plWorker.postMessage(JSON.stringify({ ticks: ticks, oc: oc, details: details }))
                // pl_observable$.subscribe(data => {
                //     // console.log('DATA FROM WEB WORKER -- ', data)
                //     self.setLastDayTick(data.data)
                //     plWorker.terminate()
                // })
            },
        }));
    const [state, setState] = React.useState(MarketData.create())
    return (
        <MarketMstContext.Provider value={state}>
            {children}
        </MarketMstContext.Provider>
    );
}

export const useMarketMst = () => {
    return React.useContext(MarketMstContext);
};
