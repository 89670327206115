import React from 'react'
import AdminHeader from '../../PrivateComponents/AdminHeader'
import FutureRelease from '../../PublicComponents/FutureRelease';

const Notification = () => {

    const [open, setOpen] = React.useState(false);
    const [dateOpen, setDateOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true)
    }

    return (
        <>
            <div className='w-full flex-1 flex flex-col bg-[#FFFFFF] px-3 relative'>
                <AdminHeader text='NOTIFICATIONS' onchange={handleClick} ondateChange={() => setDateOpen(true)} />

                <div className='flex flex-1 flex-wrap items-start justify-start gap-5'>
                    {/* <AdminTable rows={rows} columns={columns} /> */}
                    {/* <NotificationCard text='Subscribe the best algorithm of our platform..' />
                    <NotificationCard text='Subscribe the best algorithm of our platform..' />
                    <NotificationCard text='Subscribe the best algorithm of our platform..' />
                    <NotificationCard text='Subscribe the best algorithm of our platform..' />
                    <NotificationCard text='Subscribe the best algorithm of our platform..' />
                    <NotificationCard text='Subscribe the best algorithm of our platform..' />
                    <NotificationCard text='Subscribe the best algorithm of our platform..' />
                    <NotificationCard text='Subscribe the best algorithm of our platform..' /> */}
                </div>
                <FutureRelease message={'Notifications Coming Soon...'} fontSize={24} />
            </div>
            {/* <CreateNotificationDialog open={open} onClose={() => setOpen(false)} />
            <DatePicker  dateOpen={dateOpen} onClose={() => setDateOpen(false)} />  
            <NotificationDialog /> */}
        </>
    )
}

export default Notification