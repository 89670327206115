import * as React from "react";
import AdminHeader from "../../PrivateComponents/AdminHeader";
import Avatar from "@mui/material/Avatar";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import Switch from "@mui/material/Switch";
import { RightIcon } from "../../PublicComponents/Icons";
import { styled } from "@mui/material/styles";
import zerodha from "../../assets/zerodha.png";
import AddIcon from "@mui/icons-material/Add";
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddBrokerDialog from "../../PrivateComponents/AddBrokerDialog";
import AddDocumentDialog from "../../PrivateComponents/AddDocumentDialog";
import { http } from "../../Services/api.services";
import { BASE_URL } from "../../default.config";
import moment from "moment/moment";
import FutureRelease from "../../PublicComponents/FutureRelease";
import { useDispatch, useSelector } from 'react-redux'

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 32,
	height: 14,
	padding: 0,
	display: "flex",
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 15,
		},
		"& .MuiSwitch-switchBase.Mui-checked": {
			transform: "translateX(9px)",
		},
	},
	"& .MuiSwitch-switchBase": {
		padding: 2,
		"&.Mui-checked": {
			transform: "translateX(18px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#2A3B43",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		width: 10,
		height: 10,
		borderRadius: 6,
		transition: theme.transitions.create(["width"], {
			duration: 200,
		}),
	},
	"& .MuiSwitch-track": {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor:
			theme.palette.mode === "dark"
				? "rgba(255,255,255,.35)"
				: "rgba(0,0,0,.25)",
		boxSizing: "border-box",
	},
}));


const SettingScreen = () => {

	const theme = useTheme();
	const InputStyle = {
		color: '#BCBCBC',
		'& .MuiInputBase-root': {
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.5vmax', // Font size for large screens
			},
			[theme.breakpoints.up('lg')]: {
				fontSize: '.9vmax', // Font size for large screens
			},
		},
		backgroundColor: '#FAFAFA'
	}	

	const [open, setOpen] = React.useState(false);
	const [openDocument, setOpenDocument] = React.useState(false);
	const [adminDetails, setAdminDetails] = React.useState(null)
	const [brokers, setBrokers] = React.useState(null)
	const imageRef = React.useRef();

	const handleClick = () => {
		setOpen(true)
	}

	React.useEffect(() => {
		fetchAdminDetails();
	}, [])

	const fetchAdminDetails = async () => {
		let token = localStorage.getItem('admin')
		token = token ? JSON.parse(token) : null
		const id = token?.user_id;
		try {

			const res = await http.get(`${BASE_URL}admin/fetch-admin/${id}`)
			setAdminDetails(res?.data)
			const brokers = await http.get(`${BASE_URL}brokers`)
			setBrokers(brokers?.data);
		} catch (e) {
			console.log(e);
		}
	}

	const getBrokers = async () => {
		try {
			const res = await http.get(`${BASE_URL}settings/broker`)
			console.log(res);
		} catch (e) {
			console.log(e);
		}
	}

	const formatDate = (dateString) => {
		const options = {
			year: '2-digit',
			month: '2-digit',
			day: '2-digit',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		};

		const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
		const [date, time] = formattedDate.split(', '); // Splitting date and time parts
		const [day, month, year] = date.split('/');
		const [formattedHour, formattedMinute] = time.split(':');
		const period = formattedDate.includes('PM') ? 'PM' : 'AM';
		return `${year.slice(0)}-${month}-${day} ${formattedHour}:${formattedMinute} ${period}`;
	};


	// UPLOADS FILES FROM MSG

	const uploadFiles = async (files) => {
		try {

			let formdata = new FormData();
			formdata.append("file", files[0], files[0]?.name);
			console.log(formdata);
			const uploadImage = await http.post(`${BASE_URL}admin/upload`, formdata)

		} catch (error) {
			console.log(error);
		}
	};


	return (
		<>
			<div className="w-full flex flex-col px-3 pb-10">
				<AdminHeader text="settings" />
				{/* PROFILE DETAILS  */}
				<div className="flex flex-col w-full gap-4 my-6 lg:my-10">
					<SettingsTitle
						title="Profile"
					/>
					<div className="flex flex-col lg:flex-row items-center lg:items-start justify-center gap-2 lg:gap-5 w-full lg:w-fit">
						<div className="flex flex-col items-center justify-center gap-2">
							<Avatar
								alt={adminDetails?.name}
								sx={{ width: 90, height: 90 }}
							/>
							{/* <div
								onClick={() => {
									imageRef.current.click()
									// setAnchorEl(null);
								}}
								className="block"
							>
								<Avatar
									alt="Cindy Baker"
									src="https://images.unsplash.com/photo-1699488169253-3e6dfa960947?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0MHx8fGVufDB8fHx8fA%3D%3D"
									sx={{ width: 90, height: 90 }}
								/>
							</div> */}
							{/* SELECT IMAGE INPUT */}
							{/* <input
								ref={imageRef}
								onClick={() => { imageRef.current.value = null }}
								accept="image/*"
								type="file"
								name="myImage"
								onChange={(event) => {
									{
										console.log(event?.target?.files);
										uploadFiles(event?.target?.files)
									}
								}}
								className="!hidden"
							/> */}
						</div>
						<div className="h-full flex flex-col items-center lg:items-start justify-center gap-1">
							<div className="flex items-center justify-center lg:justify-between w-full gap-4 py-1">
								<h6 className="text-[#1C1C1C] uppercase text-sm lg:text-lg font-medium">
									{adminDetails?.name}
								</h6>
								{/* <BorderColorOutlinedIcon sx={{ color: "#1C1C1C", }} /> */}
							</div>
							<div className="flex items-center gap-4">
								<CallIcon sx={{ color: "#757575", fontSize: 18 }} />
								<small className="text-[#262626] text-xs lg:text-sm">
									+91 {adminDetails?.mobile}
								</small>
							</div>
							<div className="flex items-center gap-4">
								<MailIcon sx={{ color: "#757575", fontSize: 18 }} />
								<small className="text-[#262626] text-xs lg:text-sm">
									{adminDetails?.email}
								</small>
							</div>
						</div>
					</div>
				</div>
				<div className="w-full my-6 lg:my-10 flex flex-col gap-4">
					<SettingsTitle
						title={'Brokers'}
					/>
					<div className="flex items-start w-full justify-between lg:justify-start flex-wrap gap-3">
						{/* Assuming `brokers` is an array of broker objects */}
						{brokers?.map((broker, index) => (
							<BrokerCard
								key={index}
								logo={zerodha}
								createdat={broker.createdat}
							/>
						))}
						{/* <AddButton
							onClick={() => handleClick(true)}
						/> */}
					</div>
				</div>
				<div className="relative bg-slate-50 p-1">
					<FutureRelease message={'Awaiting Feature...'} />
					{/* DOCUMENTATION  */}
					<div className="w-full my-6 lg:my-10 flex flex-col gap-4">
						<SettingsTitle
							title={'Documentation'}
						/>
						<AddButton
							onClick={() => setOpenDocument(true)}
						/>
					</div>

					<div className="w-full my-6 lg:my-10 flex flex-col gap-4">
						<SettingsTitle
							title={'Complaint Categories'}
						/>
						<div className="flex flex-wrap gap-4 h-10">
							<div className="flex-1">
								{/* <h6 className="text-[#717171] text-sm">Category</h6> */}
								<TextField
									sx={InputStyle}
									label="Category"
									placeholder="Type category here..."
									fullWidth
									size="small"
									id="category"
									variant="outlined"
								/>
							</div>
							<div className="flex-1">
								<TextField
									label="Reason"
									placeholder="Type reason here..."
									sx={InputStyle}
									fullWidth
									size="small"
									id="reason"
									variant="outlined"
								/>
							</div>
							<Button variant="contained" sx={{ backgroundColor: '#2A3B43', borderRadius: 0.5, boxShadow: 'none' }} >
								ADD
							</Button>
						</div>
						{/* LIST PREVIOS REASONS AND CATEGORIES BELOW */}
						<div>
							<p className="text-[#333] text-base font-light">Recents</p>
							{/* LIST BELOW */}
						</div>
					</div>
				</div>
			</div>
			<AddBrokerDialog open={open} onClose={() => setOpen(false)} />
			<AddDocumentDialog open={openDocument} onClose={() => setOpenDocument(false)} />
		</>
	);
};

export default SettingScreen;


const SettingsTitle = ({ title }) => (
	<h3 className="text-xs lg:text-lg font-semibold tracking-wider uppercase">
		{title}
	</h3>
)

const BrokerCard = ({ onChange, logo, createdat }) => (
	<div className="px-4 py-3 rounded min-w-[160px] max-w-[200px] min-h-[120px] bg-[#3737370A] shadow flex flex-col">
		{/* <div className="flex w-full items-center justify-end gap-2 py-1">
			<BorderColorOutlinedIcon sx={{ color: '#757575' }} />
			<AntSwitch
				checked={true}
				onChange={onChange}
			/>
		</div> */}
		<div className="w-full flex items-center justify-center m-auto">
			<img src={zerodha} alt="zerodha" />
		</div>
		<div className="flex w-full  items-center justify-end">
			<p className="text-[#9E9E9E] font-semibold text-[10px]">{moment(createdat).format('DD/MM/YYYY hh:mm A')}</p>
		</div>
	</div>
)

const AddButton = ({ onClick }) => (
	<div onClick={onClick} class="flex justify-center items-center max-w-[148px] border-dashed active:shadow-lg transition-all border-gray-500 border-2 rounded p-4 hover:bg-gray-50 cursor-pointer">
		<AddIcon sx={{ fontSize: "5vmax", color: '#2A3B43' }} />
	</div>
)

