import * as React from 'react';
import AdminDekstopLayout from './AdminDekstopLayout';
import { useSelector } from 'react-redux';
import { ApiService,  } from '../Services/api.services';

const api = new ApiService()

export default function AdminLayout() {

    const [details, setDetails] = React.useState(null)
    const { admin } = useSelector((state) => state.admin)

    // HANDLE LOGOUT
    const logout = async () => {
        const token = localStorage.getItem('token');
        const response = await api.logout({ token })
        // console.log(response);
        localStorage.clear();
        window.location.reload();
    };

    return (
        <>
            {admin ?
                <AdminDekstopLayout
                    logout={logout}
                    details={details}
                />
                :
                <div className='w-full h-screen flex  justify-center items-center'>
                    <p className='text-slate-600 text-lg lg:text-xl'>Loading...</p>
                </div>
            }

        </>
    );
}
