import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  snackbar: "",
  admin: null,
  currentMargin: null,
};

export const Reducer = createSlice({
  name: "admin",
  initialState,
  reducers: {
    updateSnackbar: (state, action) => {
      state.snackbar = action.payload;
    },
    setAdmin: (state, action) => {
      state.admin = action.payload;
    },
    setCurrentMargin: (state, action) => {
      state.currentMargin = action?.payload;
    },
  },
});

export const { updateSnackbar, setAdmin, setCurrentMargin } = Reducer.actions;

export default Reducer.reducer;
