import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { RightArrow, RightIcon } from '../PublicComponents/Icons';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const DialogBox = (props) => {

    // CHANGE DATA TIME FORMAT.

    const originalDateTime = props?.subscriptionDetail?.createdat;
    const formattedDate = new Date(originalDateTime).toLocaleDateString('en-US', {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
    });

    const formattedTime = new Date(originalDateTime).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric'
    });

    const formattedDateTime = `${formattedDate} ${formattedTime}`;


    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <BootstrapDialog
                fullScreen={fullScreen}
                aria-labelledby="responsive-dialog-title"
                onClose={props.onClose}
                open={props.open}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: props.color }} id="customized-dialog-title">
                    <h6 className='text-[#3E3E3E] text-[2vmax] font-semibold lg:text-[1.1vmax]'>{props?.subscriptionDetail?.name}</h6>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon sx={{ color: "#3E3E3E" }} />
                </IconButton>

                <DialogContent sx={{ backgroundColor: props.color }} dividers>
                    <div>
                        <div className='w-fit'>
                            <h6 className='text-[#3E3E3E] text-left text-[1.5vmax] font-medium lg:text-[.8vmax]'>{props?.subscriptionDetail?.description}</h6>
                        </div>
                        <div className='w-full flex items-center justify-start py-5'>
                            <div className='flex items-center w-2/4 lg:w-1/3 justify-between px-3  border border-[#ECEAEA] py-2 lg:py-3 lg:px-5 bg-[#FDFDFD] rounded shadow gap-6'>
                                <div>
                                    <p className='text-[#717171] text-[1.5vmax] lg:text-[.8vmax] font-normal'>Subscribers</p>
                                    <div className='flex items-center gap-2'>
                                        <h6 className='text-[#1C1C1C] text-[1.7vmax] tracking-wider lg:text-[1vmax] font-semibold'>20899</h6>
                                        <RightArrow />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-3'>
                            <div >
                                <h6 className='text-[#3E3E3E] text-left text-[1.7vmax] py-3 font-semibold lg:text-[1vmax]'>Features</h6>
                            </div>
                            <div className='flex  flex-wrap items-center justify-start gap-3 lg:gap-5'>
                                <div className='flex bg-[#FDFDFD] w-fit rounded shadow px-3 py-2 items-center  gap-2 text-[1.5vmax]'>
                                    <RightIcon />
                                    <h6 className='text-[#575555] font-normal lg:text-[.8vmax]'>Nifty</h6>
                                </div>
                                <div className='flex bg-[#FDFDFD] w-fit rounded shadow px-3 py-2 items-center  gap-2 text-[1.5vmax]'>
                                    <RightIcon />
                                    <h6 className='text-[#575555] font-normal lg:text-[.8vmax]'>Vega</h6>
                                </div>
                                <div className='flex bg-[#FDFDFD] w-fit rounded shadow px-3 py-2 items-center  gap-2 text-[1.5vmax]'>
                                    <RightIcon />
                                    <h6 className='text-[#575555] font-normal lg:text-[.8vmax]'>Theta</h6>
                                </div>
                                <div className='flex bg-[#FDFDFD] w-fit rounded shadow px-3 py-2 items-center  gap-2 text-[1.5vmax]'>
                                    <RightIcon />
                                    <h6 className='text-[#575555] font-normal lg:text-[.8vmax]'>Bank Nifty</h6>
                                </div>
                                <div className='flex bg-[#FDFDFD] w-fit rounded shadow px-3 py-2 items-center  gap-2 text-[1.5vmax]'>
                                    <RightIcon />
                                    <h6 className='text-[#575555] font-normal lg:text-[.8vmax]'>Sensex</h6>
                                </div>
                                <div className='flex bg-[#FDFDFD] w-fit rounded shadow px-3 py-2 items-center  gap-2 text-[1.5vmax]'>
                                    <RightIcon />
                                    <h6 className='text-[#575555] font-normal lg:text-[.8vmax]'>Fin Nifty</h6>
                                </div>
                                <div className='flex bg-[#FDFDFD] w-fit rounded shadow px-3 py-2 items-center  gap-2 text-[1.5vmax]'>
                                    <RightIcon />
                                    <h6 className='text-[#575555] font-normal lg:text-[.8vmax]'>Midcp Nifty</h6>
                                </div>
                                <div className='flex bg-[#FDFDFD] w-fit rounded shadow px-3 py-2 items-center  gap-2 text-[1.5vmax]'>
                                    <RightIcon />
                                    <h6 className='text-[#575555] font-normal lg:text-[.8vmax]'>Equilibrium price</h6>
                                </div>
                                <div className='flex bg-[#FDFDFD] w-fit rounded shadow px-3 py-2 items-center  gap-2 text-[1.5vmax]'>
                                    <RightIcon />
                                    <h6 className='text-[#575555] font-normal lg:text-[.8vmax]'>Delta</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: props.color }}>
                    <div className='flex items-start justify-between w-full px-2'>
                        <h6 className='text-[#666666] text-[1.2vmax] lg:text-[.8vmax]'>Created at - {formattedDateTime}</h6>
                        <div className='flex flex-col items-end gap-1'>
                            <p className='text-[#F0AD00] font-medium text-[1.3vmax] lg:text-[.8vmax]'>{props?.subscriptionDetail?.trialperiod} days</p>
                            <small className='text-[#1C1C1C] font-semibold text-[2vmax] lg:text-[1vmax]'>₹ {props?.subscriptionDetail?.price}</small>
                        </div>
                    </div>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default DialogBox