import { Button } from "@mui/material"

const BrokerNotConn = ({ message, onConnect,msg, type, loading }) => {

    return (
        <div className="z-50 backdrop-blur-md absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center">
            <div className="flex flex-col justify-center items-center">
                <h1 className="text-xl lg:text-2xl tracking-widest text-center my-4 mb-4">{msg}</h1>
                <h1 className="text-2xl lg:text-4xl tracking-widest mb-2">{message}</h1>
                {loading ? null :
                    <>
                        <p className="text-slate-500 text-xs lg:text-sm tracking-wider mb-3">connect your broker for further access</p>
                        <Button
                            variant="contained"
                            sx={{
                                boxShadow: 'none',
                                borderRadius: 1
                            }}
                            size="large"
                            onClick={() => onConnect()}
                        >
                            {type}
                        </Button>
                    </>

                }
            </div>
        </div>
    )
}

export default BrokerNotConn