import * as React from "react";

const PositionTotal = (props) => {
  // console.log('POSITION DATA -- ', props.marketPositions)
  const [profitLoss, setProfitLoss] = React.useState("");
  const [loss, setLoss] = React.useState("");
  // console.log(props)
  React.useEffect(() => {
    const _post = [];
    let total = 0;
    let loss = 0;
    for (let index = 0; index < props?.positions?.length; index++) {
      let obj = { ...props?.positions[index] };
      obj.profitLoss =
        -obj.realizedprofitloss +
        obj.currentquantity * props.marketPositions?.[obj?.instrumenttoken]?.last_price;
      loss =
        obj.profitLoss && obj.profitLoss < 0
          ? loss + Math.abs(obj.profitLoss)
          : obj.realizedprofitloss > 0
          ? loss + obj.realizedprofitloss
          : loss + 0;
      if (index == props.positions.length - 1) {
        props.hasLoss(loss);
      }
      _post.push(obj);
    }
    total = _post?.reduce((acc, obj) => {
      return acc + (-obj.profitLoss || obj.realizedprofitloss);
    }, 0);

    setProfitLoss(-total);
    // let pl = 0
    // for (const pos in props?.positions) {
    //     pl = pl + props?.positions[pos]  
    // }
    // setProfitLoss
  }, [props]);  

  return (
    <div className="w-full flex justify-between items-center px-4 py-3 bg-blue-100">
      <p className="text-sm text-slate-600">P/L</p> 
      {profitLoss > 0 ? (
        <p className="text-green-600 font-bold tracking-wide">
          {parseFloat(Math.abs(profitLoss))?.toFixed(2)}
        </p>  
      ) : (
        <p className="text-red-600 font-bold tracking-wide">    
          {parseFloat(Math.abs(profitLoss))?.toFixed(2)}
        </p>
      )}    
    </div>
  );
};

export default PositionTotal;
