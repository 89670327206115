import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import AddCondition from '../../PrivateComponents/AddConditionDialog';
import { useDimensions } from "../../Contexts/DimensionContext/DimensionsContext";

const AddAction = () => {
    const [age, setAge] = React.useState('');
    const [openAddCondition, setOpenAddCondition] = React.useState(false)

    const { lg, xl, xxl } = useDimensions()

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <>
            <div className='px-2 lg:px-6 flex-1 overflow-y-auto flex flex-col gap-3'>
                {/* BASIC INFORMATION  */}
                <div className='border border-[#37373717] bg-[#FEFEFE] '>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon color='#000000' />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6 className='text-md font-bold'>Basic info</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='flex w-full flex-col justify-between lg:flex-row gap-3 lg:gap-5'>
                                <div className='w-full lg:w-[24%] '>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label="Action name"
                                        variant="outlined"
                                    />
                                </div>
                                <div className='w-full lg:w-[75%] '>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label="Short description"
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                            <div className='w-full flex flex-wrap justify-between lg:justify-start  gap-3 py-4 lg:gap-5'>
                                <div className='w-[48%] lg:w-[24%] '>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select entry  condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='w-[48%] lg:w-[24%] '>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select exit  condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='w-[48%] lg:w-[24%] '>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select trailing  condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='w-full flex items-center gap-2 lg:gap-5'>
                                    <div className='w-[48%] lg:w-[24%] '>
                                        <FormControl
                                            fullWidth
                                            size='small'
                                        >
                                            <InputLabel id="demo-simple-select-label">Qty/Investment</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={age}
                                                label="Age"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='w-[48%] lg:w-[24%] '>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            id="outlined-basic"
                                            // label="Strike"
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                {/* VALIDITY  */}
                <div className='border border-[#37373717] w-full flex flex-col items-center justify-center bg-[#FEFEFE]'>
                    <div className='w-full p-4'>
                        <h6 className='text-md font-bold'>Validity</h6>
                    </div>
                    <div className='w-full flex flex-col lg:flex-row object-contain gap-5 p-4 pb-6'>
                        <div className='w-full lg:w-[30%] flex items-center gap-[2px] justify-between'>
                            <div className='w-[48%] '>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="outlined-basic"
                                    label="Entry validity"
                                    placeholder='Date & time'
                                    variant="outlined"
                                />
                            </div>
                            <div className='w-3 h-[2px] bg-[#37373769]'></div>
                            <div className='w-[48%] '>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="outlined-basic"
                                    label=""
                                    placeholder='Date & time'
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className='w-full lg:w-[30%] flex items-center gap-[2px] justify-between'>
                            <div className='w-[48%] '>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="outlined-basic"
                                    label="Exit validity"
                                    placeholder='Date & time'
                                    variant="outlined"
                                />
                            </div>
                            <div className='w-3 h-[2px] bg-[#37373769]'></div>
                            <div className='w-[48%] '>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="outlined-basic"
                                    label=""
                                    placeholder='Date & time'
                                    variant="outlined"
                                />
                            </div>
                        </div>
                    </div>

                </div>

                {/* MAX LOSS & PROFIT  */}
                <div className='border border-[#37373717] w-full flex flex-col items-center justify-center bg-[#FEFEFE]'>
                    <div className='w-full p-4'>
                        <h6 className='text-md font-bold'>Max loss or Profit</h6>
                    </div>
                    <div className='w-full flex  flex-col lg:flex-row object-contain gap-5 p-4 pb-6'>
                        <div className='w-full lg:w-[30%] flex items-center gap-[2px] justify-between'>
                            <div className='w-[48%] '>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="outlined-basic"
                                    label="Max Loss"
                                    placeholder='No.'
                                    variant="outlined"
                                />
                            </div>
                            <div className='w-3 h-[2px] bg-[#37373769]'></div>
                            <div className='w-[48%] '>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="outlined-basic"
                                    label=""
                                    placeholder='No.'
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className='w-full lg:w-[30%] flex items-center gap-[2px] justify-between'>
                            <div className='w-[48%] '>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="outlined-basic"
                                    label="Max Profit"
                                    placeholder='No.'
                                    variant="outlined"
                                />
                            </div>
                            <div className='w-3 h-[2px] bg-[#37373769]'></div>
                            <div className='w-[48%] '>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="outlined-basic"
                                    label=""
                                    placeholder='No.'
                                    variant="outlined"
                                />
                            </div>
                        </div>
                    </div>

                </div>

                {/* RE-ENTRY & EXIT  */}
                <div className='border border-[#37373717] w-full flex flex-col items-center justify-center bg-[#FEFEFE]'>
                    <div className='w-full p-4'>
                        <h6 className='text-md font-bold'>Re-entry & exit</h6>
                    </div>
                    <div className='w-full flex  flex-col lg:flex-row object-contain gap-5 p-4 pb-6'>
                        <div className='w-full lg:w-[16%] '>
                            <FormControl size='small' fullWidth>
                                <InputLabel id="demo-simple-select-label">Re-entry</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Age"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='w-full lg:w-[30%] flex items-center gap-[2px] justify-between'>
                            <div className='w-[48%] '>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="outlined-basic"
                                    label="Max trades"
                                    placeholder='No.'
                                    variant="outlined"
                                />
                            </div>
                            <div className='w-3 h-[2px] bg-[#37373769]'></div>
                            <div className='w-[48%] '>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="outlined-basic"
                                    label=""
                                    placeholder='No.'
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className='w-full lg:w-[16%] '>
                            <FormControl size='small' fullWidth>
                                <InputLabel id="demo-simple-select-label">Exit</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Age"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                </div>

                <div className='w-full flex justify-end py-6 '>
                    <Button
                        disabled
                        variant='contained'
                        size={lg || xl || xxl ? "large" : "medium"}
                    >
                        SAVE
                    </Button>
                </div>

                <AddCondition
                    open={openAddCondition}
                    onclose={() => setOpenAddCondition(false)}
                />
            </div >

        </>
    )
}

export default AddAction
