import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useTheme } from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Rating } from '@mui/material';
import moment from 'moment';

export default function SignalDrawer({ openSignal, setOpenSignal, list, data }) {
    const theme = useTheme();

    return (
        <div>

            <React.Fragment key={'right'}>
                <Drawer
                    anchor={'right'}
                    open={openSignal}
                    onClose={() => setOpenSignal(false)}
                >
                    <Box className=' flex flex-col gap-4 py-5 p-3' sx={{ width: 400 }} role="presentation" >
                        <Typography variant='5'>
                            NOTIFICATIONS
                        </Typography>
                        {/* {console.log(data,'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAa')} */}
                        {data?.map((item) => <div className=' bg-[#3737370A] flex justify-between px-2 pl-3 ' >

                            <div className='flex gap-2'>
                                <Box className={item?.order_type ==='buy' ? ` bg-[#00B386C7] self-center rounded-md flex justify-center items-center w-10 h-10` : ` bg-[#EB5B3C] self-center rounded-md flex justify-center items-center w-10 h-10`}>
                                    <NotificationsIcon className=' text-white' />
                                </Box>
                                <div className=' self-center'>
                                    <Typography className='text-[#373737] text-sm' sx={{ fontSize: 12 }}>{item?.symbol}</Typography>
                                    <Typography className='text-[#373737] text-sm' sx={{ fontSize: 15, fontWeight: 500 }} >{item?.algo_name}</Typography>
                                </div>
                            </div>
                            <div className=' self-center' >
                                <Rating sx={{ fontSize: 15 }} name="read-only" size='small' value={item?.rating} readOnly />
                                <Typography className={item?.order_type ==='buy' ?'text-[#37373796] text-right':'text-[#EB5B3C] text-right'} sx={{ fontSize: 15, fontWeight: 700 }} >{item?.order_type?.toUpperCase()}</Typography>
                                <Typography className='text-[#37373796] text-right' sx={{ fontSize: 10 }} >{moment(item.upd).format('LT')} </Typography>
                            </div>
                        </div>

                        )}
                    </Box>
                </Drawer>
            </React.Fragment>

        </div >
    );
}