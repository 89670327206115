import React from 'react'
import Group1 from '../assets/Group.jpg'
import Group2 from '../assets/Group 2004.jpg'
import Arrow from '../assets/Vector.jpg'
import Group3 from '../assets/Group 2008.jpg'


const HomeCard = (props) => {
    return (
            <div className='w-full relative flex items-center justify-between bg-white rounded-md h-[25vh] lg:h-[40vh] lg:gap-5 lg:py-10 shadow px-6 lg:flex-col-reverse'>
                <div>
                     <img src={props.img} alt="#" />
                </div>
                <div className='w-[70%]  flex h-full flex-col items-start justify-center gap-4 lg:items-center'>
                    <h6 className='font-[Open Sans] text-[#141414] font-semibold text-[2vmax] sm:text-[3vmax] lg:text-[1.7vmax] lg:text-center lg:whitespace-nowrap'>{props.title}</h6>
                    <p className='font-[Open Sans] text-[#767676] font-normal text-[1.3vmax] sm:text-[1.8vmax] lg:text-[.9vmax] lg:text-center'>{props.desc}</p>
                    <img className='absolute bottom-5 end-10 ' src={Arrow} alt="" />
                </div>
            </div>
    )
}

export default HomeCard