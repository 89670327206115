import { Button } from "@mui/material";
import { styled } from "@mui/styles";


// BUTTONS STYLES
export const BuyButton = styled(Button)(({ theme }) => ({
    color: "#00B386",
    backgroundColor: "#F1FBF6",
    border: "1px solid #00B386",
    "&:hover": {
        backgroundColor: "#F1FBF6",
    },
    minWidth: "24px",
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 10,
    fontWeight: "bold",
    borderRadius: 5,
    padding: "8px 16px",
    textTransform: "capitalize",
    // boxShadow: 'none'
}));

export const SellButton = styled(Button)(({ theme }) => ({
    color: "#EB5B3CF7",
    backgroundColor: "#FFF6F6",
    border: "1px solid #EB5B3CF7",
    "&:hover": {
        backgroundColor: "#FFF6F6",
    },  
    minWidth: "24px",
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 10,
    fontWeight: "bold",
    borderRadius: 5,
    padding: "8px 16px",
    textTransform: "capitalize",
    // boxShadow: 'none'
}));

