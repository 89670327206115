import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { H6, P } from '../Theme/ThemeComponent';
import { useThemeContext } from '../Theme/ThemeContext';

export default function EquityOptionSelect({
    Heading,
    options,
    onUpdateState,
    data
}) {
    const { theme } = useThemeContext();
    return (
        <div>
            <Accordion sx={{
                boxShadow: 'none !important',
                padding: 0
            }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                        padding: 0
                    }}
                >
                    <H6
                        title={Heading}
                        color={theme.palette.text.charcolGray}
                        font={'bold'}
                    />
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: 0,
                        display: 'flex',         // Set flexbox display
                        flexDirection: 'column', // Set direction to column
                    }}
                >
                    {options?.map((item, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    size='small'
                                    checked={data?.includes(item?.value) ? true : false}
                                    onChange={() => onUpdateState(item)}
                                />
                            }
                            label={
                                <H6
                                    title={item?.label}
                                    color={theme.palette.text.charcolGray}
                                    font={'normal'}
                                />
                            }
                        />
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
