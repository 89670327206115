import React, { useEffect, useState } from "react";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import StoplossTarget from "../../PublicComponents/StoplossTarget";
import { slt } from "../../default.config";
import { http } from "../../Services/api.services";
import { useDispatch, useSelector } from "react-redux";
import sltpData from "../../sltp.data.json";
import { asyngetStatus, showToast, updateSL } from "../../store/Actions/Action";
import { useDimensions } from "../../Contexts/DimensionContext/DimensionsContext";
import StoplossTargetMobile from "../../PublicComponents/StoplossTargetMobile";
import SettingDrawer from "./SettingDrawer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";
import ActionChildSelect from "../../PrivateComponents/ActionChildSelect";
import SettingSIdeNav from "../../PublicComponents/SettingSIdeNav";
import { useLocation } from "react-router-dom";
import SLReducer, { slReducer } from "../../store/Reducer/SLReducer";

const classText = "text-md text-[#247CFF]  text-center font-bold my-2";

const Settings = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // type index or kltp to cal api
  const [_type, setType] = useState("");
  const [userId, setUserId] = useState(localStorage.getItem("user_id"));
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [openDrawer, setopenDrawer] = useState(false);
  const [snackbar, setSnackbar] = React.useState({
    message: "",
    open: false,
    mode: "primary",
    duration: 60000,
  });
  const SL = useSelector((state) => state.SL);
  const { xs, sm, md, lg, xl, xxl } = useDimensions();
  const { admin } = useSelector((state) => state.admin);
  // CHECK THE GET API DATA IT IS BLANK OR NOT
  const [isData, setIsData] = useState(false);
  // ROLE AND ID FOR THE API
  const id = admin ? admin?.id : userId;
  const role = admin ? "admin" : "user";
  const [selected, setSelected] = useState(0);

  const [stopLossTarget, setSlTarget] = React.useState({ ...SL });
  // UPDATE _TEXT VALUE

  useEffect(() => {
    setSlTarget({ ...SL });   
  }, [SL]);

  useEffect(() => {
    const newType = location?.pathname?.split("/")[4]?.split("-")[1] || "index";
    setType(newType);
  }, [location]);

  useEffect(() => {
    setIsDisabled(true);
  }, [location?.pathname]);

  useEffect(() => {
    if (_type ) {
      fetchSLData(_type);
    }
  }, [_type]);

  // FETCH  SL DATA
  const fetchSLData = async () => {
    if (_type) {
      const STOPLOSS_TARGET_URL = `/settings/sltp/${role}/${id}`;
      try {
        const response = await http.get(`${STOPLOSS_TARGET_URL}?type=${_type}`);
        if (response?.status == 200) {
          dispatch(
            slReducer({
              type: "updateSL",
              payload: response.data[0] || { ...initData, type: _type },
            })
          );
          if (!response.data?.length) setIsData(false);
          else setIsData(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  // ON FORMCHANGE
  const onSLTChange = (_type, value) => {
    try {
      if (value < 0) {
        // setSnackbar({ open: true, message: 'Value must not be a negative number', mode: 'error', duration: 3000 })
        const _snackbar = {
          message: "Value must not be a negative number",
          open: true,
          duration: 3000,
          type: "error",
        };
        dispatch(showToast(_snackbar));
        return;
      }
      dispatch(slReducer({ type: _type, payload: value }));
      setIsDisabled(false);
    } catch (e) {
      console.log("ERROR", e);
      setIsDisabled(true);
    }
  };
  // SAVE SLDATA
  const saveStopLossTarget = async () => {
    try {
      const _body = { ...SL };
      _body["type"] = _type;
      admin ? (_body["admin_id"] = admin?.id) : (_body["user_id"] = userId);

      if (isData === false) {
        const response = await http.post("/settings/sltp", _body);
        dispatch(slReducer({ type: "updateSL", payload: response.data[0] }));
        if (response.status === 200) {
          setSlTarget(response?.data[0]);
          // fetchStoplossTarget(selected ? 'ltp' : 'index')
          const _snackbar = {
            message: "Stoploss and Target updated successfully!",
            open: true,
            duration: 3000,
            type: "success",
          };
          dispatch(showToast(_snackbar));
          dispatch(updateSL(role, id, _type));
        } else {
          const _snackbar = {
            message: "Failed to update stoploss and target",
            open: true,
            duration: 3000,
            type: "error",
          };
          dispatch(showToast(_snackbar));
        }
      } else {
        const response = await http.put(`/settings/sltp/${SL?.id}`, _body);
        dispatch(slReducer({ type: "updateSL", payload: response.data[0] }));
        if (response.status === 200) {
          setSlTarget(response?.data[0]);
          const _snackbar = {
            message: "Stoploss and Target updated successfully!",
            open: true,
            duration: 3000,
            type: "success",
          };
          dispatch(showToast(_snackbar));
        } else {
          const _snackbar = {
            message: "Failed to update stoploss and target",
            open: true,
            duration: 3000,
            type: "error",
          };
          dispatch(showToast(_snackbar));
        }
      }
    } catch (e) {
      console.log("failed to save stoploss and target");
    }
  };

  return (
    <div className="w-full flex gap-3 bg-white px-0  h-full">
      {lg || xl || xxl ? (
        <div className="flex-1 flex overflow-auto relative">
          <div className="flex-1 shadow bg-white flex">
            <div className="flex-1 p-2">
              <StoplossTarget
                data={SL}
                onChange={(_type, value) => onSLTChange(_type, value)}
                onSave={() => saveStopLossTarget()}
                isDisabled={isDisabled}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-1 w-full shadow overflow-y-auto  bg-white flex flex-col">
          <div className="flex-1 w-full px-2">
            <div className="w-full flex items-center justify-center py-3">
              <h6 className="text-md font-bold ">Nifty 50</h6>
            </div>
            <p className={classText}>CALL</p>
            <StoplossTargetMobile
              data={stopLossTarget}
              index="NIFTY"
              orderType="call"
              onChange={(market, opt, op, txn, type, value) =>
                onSLTChange(market, opt, op, txn, type, value)
              }
              onSave={() => saveStopLossTarget()}
            />
            <p className={classText}>PUT</p>
            <StoplossTargetMobile
              data={stopLossTarget}
              index="NIFTY"
              orderType="put"
              onChange={(market, opt, op, txn, type, value) =>
                onSLTChange(market, opt, op, txn, type, value)
              }
              onSave={() => saveStopLossTarget()}
            />
          </div>
          <div className="flex-1 w-full px-2">
            <div className="w-full flex items-center justify-center py-3">
              <h6 className="text-md font-bold ">Bank nifty</h6>
            </div>
            <p className={classText}>CALL</p>
            <StoplossTargetMobile
              data={stopLossTarget}
              index="BANKNIFTY"
              orderType="call"
              onChange={(market, opt, op, txn, type, value) =>
                onSLTChange(market, opt, op, txn, type, value)
              }
              onSave={() => saveStopLossTarget()}
            />
            <p className={classText}>PUT</p>
            <StoplossTargetMobile
              data={stopLossTarget}
              index="BANKNIFTY"
              orderType="put"
              onChange={(market, opt, op, txn, type, value) =>
                onSLTChange(market, opt, op, txn, type, value)
              }
              onSave={() => saveStopLossTarget()}
            />
          </div>
          <div className="flex-1 w-full px-2">
            <div className="w-full flex items-center justify-center py-3">
              <h6 className="text-md font-bold ">Fin nifty</h6>
            </div>
            <p className={classText}>CALL</p>
            <StoplossTargetMobile
              data={stopLossTarget}
              index="FINNIFTY"
              orderType="call"
              onChange={(market, opt, op, txn, type, value) =>
                onSLTChange(market, opt, op, txn, type, value)
              }
              onSave={() => saveStopLossTarget()}
            />
            <p className={classText}>PUT</p>
            <StoplossTargetMobile
              data={stopLossTarget}
              index="FINNIFTY"
              orderType="put"
              onChange={(market, opt, op, txn, type, value) =>
                onSLTChange(market, opt, op, txn, type, value)
              }
              onSave={() => saveStopLossTarget()}
            />
          </div>
          <div className="flex-1 w-full px-2">
            <div className="w-full flex items-center justify-center py-3">
              <h6 className="text-md font-bold ">Midcp nifty</h6>
            </div>
            <p className={classText}>CALL</p>
            <StoplossTargetMobile
              data={stopLossTarget}
              index="MIDCPNIFTY"
              orderType="call"
              onChange={(market, opt, op, txn, type, value) =>
                onSLTChange(market, opt, op, txn, type, value)
              }
              onSave={() => saveStopLossTarget()}
            />
            <p className={classText}>PUT</p>
            <StoplossTargetMobile
              data={stopLossTarget}
              index="MIDCPNIFTY"
              orderType="put"
              onChange={(market, opt, op, txn, type, value) =>
                onSLTChange(market, opt, op, txn, type, value)
              }
              onSave={() => saveStopLossTarget()}
            />
          </div>
          <div className="flex-1 w-full px-2">
            <div className="w-full flex items-center justify-center py-3">
              <h6 className="text-md font-bold">Sensex</h6>
            </div>
            <p className={classText}>CALL</p>
            <StoplossTargetMobile
              data={stopLossTarget}
              index="SENSEX"
              orderType="call"
              onChange={(market, opt, op, txn, type, value) =>
                onSLTChange(market, opt, op, txn, type, value)
              }
              onSave={() => saveStopLossTarget()}
            />
            <p className={classText}>PUT</p>
            <StoplossTargetMobile
              data={stopLossTarget}
              index="SENSEX"
              orderType="put"
              onChange={(market, opt, op, txn, type, value) =>
                onSLTChange(market, opt, op, txn, type, value)
              }
              onSave={() => saveStopLossTarget()}
            />
          </div>
          <div className="flex w-full justify-center px-4 py-6 mb-14">
            <Button
              disabled={isDisabled}
              variant="outlined"
              color="primary"
              onClick={() => saveStopLossTarget()}
            >
              SAVE STOPLOSS & TARGET
            </Button>
          </div>
          <SettingDrawer
            openDrawer={openDrawer}
            selected={selected}
            setSelected={setSelected}
            onClose={() => setopenDrawer(false)}
          />
        </div>
      )}
    </div>
  );
};

export default Settings;

var initData = {
  options: {
    NIFTY: {
      call: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
      put: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
    },
    BANKNIFTY: {
      call: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
      put: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
    },
    FINNIFTY: {
      call: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
      put: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
    },
    MIDCPNIFTY: {
      call: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
      put: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
    },
    SENSEX: {
      call: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
      put: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
    },
  },
  type: "index",
};
