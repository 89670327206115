import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { ListItemButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ActionChildSelect from "../../PrivateComponents/ActionChildSelect";
import { useLocation, useNavigate } from "react-router-dom";

const drawerWidth = 270;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const listItemStyle = {
  // margin: '5px 0',
  padding: "0 10px",
  overflow: "hidden",
};

const notSelectedButton = {
  width: "100%",
  borderBottom: "1px solid #0000003B",
  padding: "16px 24px",
};

const drawerOptions = [
  { name: "SL & Target (Index)", route: "" },
  { name: "SL & Target (LTP)", route: "sl-ltp" },
];

const drawerOptions1 = [{ name: "Action", route: "actions" }];

const SettingDrawer = (props) => {
  const location = useLocation();
  const theme = useTheme();
  const parentRoute = location.pathname.split("/")[5] || "";
  const [menu, setMenu] = React.useState(
    location?.pathname?.includes("tools") ? drawerOptions1 : drawerOptions
  );
  const navigate = useNavigate();
  const childRoute = location.pathname.split("/")[6] || null;

  const selectedButton = {
    width: "100%",
    borderBottom: childRoute ? "1px solid  #0000003B" : "4px solid #1C1C1C",
    backgroundColor: "#1C1C1C0A",
    padding: "16px 24px",
  };

  const navigationPath = "/admin/options/settings";

  return (
    <>
      <div>
        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              top: "auto",
              bottom: 0,
              // height: '94vh',
              backgroundColor: "#FFFFFF",
              boxShadow: "-3px 4px 5px 2px rgba(0, 0, 0, 0.16)",
              border: "1px ",
              borderTopRightRadius: "7px",
              // boxShadow
            },
          }}
          variant="temporary"
          anchor="left"
          open={props.openDrawer}
          onClose={() => props?.onClose()}
          // hideBackdrop='false'
          // BackdropProps={{ invisible: true }}
        >
          <DrawerHeader>
            <div className="w-full flex bg-white items-center justify-end">
              <ArrowBackIosIcon
                onClick={() => props?.onClose()}
                color="#373737"
                fontSize="small"
              />
            </div>
          </DrawerHeader>

          <List>
            {menu?.map((el, idx) => (
              <ListItem
                key={idx} // Add a unique key for each item in the array
                onClick={() => props?.setSelected(idx)}
                sx={listItemStyle}
                disablePadding
              >
                <ListItemButton
                  onClick={() => {
                    navigate(`${navigationPath}/${el?.route}`);
                    props?.onClose();
                  }}
                  sx={
                    parentRoute === el?.route
                      ? selectedButton
                      : notSelectedButton
                  }
                >
                  <h6 className="text-[#373737] font-semibold text-md">
                    {el?.name}
                  </h6>
                </ListItemButton>
              </ListItem>
            ))}

            {location?.pathname?.includes("tools") && (
              <ActionChildSelect onClose={() => props?.onClose()} />
            )}
          </List>
        </Drawer>
      </div>
    </>
  );
};

export default SettingDrawer;
