import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WolfLogo from '../../assets/wolf-logo 1.png'
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import EmailIcon from '@mui/icons-material/Email';
import LoginPageImg from '../../assets/Rectangle 447.png'
import CloseIcon from '@mui/icons-material/Close';
import { ApiService } from '../../Services/api.services';
import { emailRegex, passwordRegex } from '../../ValidationRegex'
import { asyngetStatus } from '../../store/Actions/Action'
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { Button, Skeleton } from '@mui/material';
const customPlaceholderStyle = {
    color: 'red', // Change the color to your desired color
    fontFamily: 'Arial, sans-serif', // Change the font family to your desired font
};


const _form = {
    email: '',
    password: ''
}

const api = new ApiService()

export default function ForgotPass(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    // handle Password Visiblity

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // handle Password Visiblity

    const [signinForm, setSigninForm] = React.useState({ ..._form })
    const [isLoading, setIsLoading] = React.useState(false)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [showImage, setShowImage] = React.useState(false)


    const onFormChange = (name, value) => {
        try {
            const form = { ...signinForm }
            form[name] = value
            setSigninForm(form)
        }
        catch (e) {
            console.log(`ERROR WHILE FORM CHANGE --- ${e}`)
        }
    }

    const handleLogin = async () => {
        try {
            props?.onEnterOtp();
            // if (emailRegex.test(signinForm.email) && passwordRegex.test(signinForm.password)) {
            //     setIsLoading(true)
            //     const response = await api.login(signinForm)
            //     // console.log(response.data.token);
            //     const _snackbar = {
            //         message: response.message,
            //         open: true,
            //         duration: 3000,
            //         type: response.status
            //     };

            //     if (response.status === 'success') {
            //         // Set Token in local storage 
            //         const tokenToSet = response.data.token ? response.data.token : '';
            //         const userIdToSet = response.data.user_id ? response.data.user_id : '';
            //         localStorage.setItem('token', tokenToSet)
            //         localStorage.setItem('user_id', userIdToSet)
            //         setIsLoading(false);
            //         props.onClose();
            //         navigate('/index-options/strikes')
            //     } else {
            //         setIsLoading(false);
            //     }
            //     dispatch(asyngetStatus(_snackbar));
            //     // setSigninForm(_form); //NOT REQUIRED
            // }
            // else {
            //     const _snackbar = {
            //         message: 'Invalid values',
            //         open: true,
            //         duration: 3000,
            //         type: 'error'
            //     };
            //     dispatch(asyngetStatus(_snackbar));
            // }
        }
        catch (e) {
            console.log(`ERROR WHILE LOGIN --- ${e}`)
            setIsLoading(false);
        }
    }

    const inputStyle = {
        // color: 'rgba(55, 55, 55, 0.20)',
        '& .MuiInputBase-root': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '2vmax', // Font size for large screens
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '.8vmax', // Font size for large screens
            },
            bgcolor: '#fff'
        },

    }

    const handleImageLoad = () => {
        setShowImage(true);
    };

    const handleImageError = () => {
        setShowImage(false);
    };


    // Create a new Image object to handle loading
    const image = new Image();
    image.src = LoginPageImg;
    image.onload = handleImageLoad;
    image.onerror = handleImageError;


    return (

        <div>
            <div className='w-full !bg-rgba(248, 248, 248, 0.99)'>
                <Dialog
                    fullScreen={fullScreen}
                    open={props.open}
                    onClose={() => props.onClose()}
                    aria-labelledby="responsive-dialog-title"
                >
                    <div style={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }} className='w-full flex'>
                        {showImage ? (
                            <div className='hidden lg:flex w-full bg-[#000000]'>
                                <img
                                    className='w-full h-full object-contain'
                                    src={LoginPageImg}
                                    alt="LoginPageImg"
                                // onLoad={handleImageLoad}
                                // onError={handleImageError}
                                />
                            </div>
                        ) : (
                            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.11)' }} className='hidden lg:block'>
                                <Skeleton width={250} height={'100%'} />
                            </div>
                        )}
                        {/* <img className='w-full h-full object-contain' src={LoginPageImg} alt="LoginPageImg" /> */}
                        <div className='w-full'>
                            <DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }}>
                                <div className='flex flex-col items-center justify-center gap-3 py-6 lg:py-1'>
                                    <img src={WolfLogo} alt="WolfLogo" />
                                    <h6 className='text-[#373737] font-normal tracking-wider text-[2.2vmax] lg:text-[1.5vmax]'>Join <span className='font-semibold'>Fastr</span></h6>
                                    <CloseIcon onClick={() => props.onClose()} sx={{ position: "absolute", top: "4%", right: "1%", transform: 'translate(-50%,-50%)' }} />
                                </div>
                            </DialogTitle>

                            <DialogContent sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)' }} >
                                <div className='input_area flex items-center justify-center w-full h-[65vh] lg:h-[50vh] lg:w-[35vw] lg:overflow-y-auto lg:px-4'>
                                    <div className='w-full flex flex-col items-start justify-center gap-5 '>
                                        <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                            <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>E-mail</h6>
                                            <TextField
                                                id="input-with-icon-textfield"
                                                onKeyUp={(event) => {
                                                    if (event.key === 'Enter') {
                                                        handleLogin()
                                                    }
                                                }}
                                                placeholder='Enter your email address'
                                                helperText={signinForm.email?.length > 0 && !emailRegex?.test(signinForm.email) ? "Please Enter Valid Email" : ""}
                                                error={signinForm.email?.length > 0 && !emailRegex?.test(signinForm.email)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EmailIcon sx={{ color: 'rgba(55, 55, 55, 0.20)' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                // variant="standard"
                                                size='small'
                                                className='custom-placeholder  border !border-[F4F4F4] !shadow-none '
                                                sx={inputStyle}
                                                type='email'
                                                value={signinForm.email}
                                                onChange={(e) => onFormChange('email', e?.target?.value)}
                                            />
                                        </div>

                                        <LoadingButton
                                            loading={isLoading}
                                            sx={{ padding: '.6vmax 0', boxShadow: 'none' }}
                                            onClick={handleLogin}
                                            fullWidth
                                            disabled={
                                                !(emailRegex.test(signinForm.email))
                                            }
                                            variant="contained"
                                            size="small"
                                        >
                                            <h6 className='text-[#FFFFFF] font-normal text-[2vmax] tracking-wider lg:text-[.8vmax]'>SEND OTP</h6>
                                        </LoadingButton>

                                        <button className='flex items-center justify-center w-full py-3 lg:py-1 rounded-sm ' >
                                            <h6 className='text-[#373737] font-normal text-[1.7vmax] tracking-wide lg:text-[.8vmax]'>Please enter your correct email</h6>
                                        </button>
                                    </div>
                                </div>
                            </DialogContent>
                        </div>
                    </div>

                </Dialog>
            </div >
        </div >
    );
}
