import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
    Volume,
    OICell,
    StrikeCell,
    CallCell,
    PutOICell,
    PutVolume,
    PutCell,
    OrderCell,
    PutOrderCell,
} from "./OptionTableDataComponent";
import BottomSheet from "./BottomSheet";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useLocation } from "react-router-dom";
import NoRows from "./NoData";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import OIChange from "./OiChange";
import * as iv from "implied-volatility";
import xGreeks from "../Services/greeks.service";
import BlockIcon from "@mui/icons-material/Block";
import { Button } from "@mui/material";
const useStyles = makeStyles({
    sticky: {
        position: "sticky",
        left: 0,
        background: "white",
        boxShadow: "5px 2px 5px grey",
        borderRight: "2px solid black",
    },
    sticky: {
        position: "sticky",
        left: 0,
        // background: "white",
        zIndex: 5,
        borderRight: "0.1px  solid  rgba(224, 224, 224, 1)",
    },
    sticky2: {
        position: "sticky",
        right: 0,
        borderLeft: "0.1px  solid  rgba(224, 224, 224, 1)",
        zIndex: 5,
        // borderRight: '0.1px  solid  rgba(224, 224, 224, 1)'
    },
    stickyHead: {
        position: "sticky",
        top: 0,
        background: "white",
        zIndex: 10,
    },
    bgGray: {
        background: "#eee",
    },
});

const twoDotDigit = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
// column with orders
const columnsWithOrders = [
    { id: "call", label: "LTP", minWidth: 170, align: "center" },
    { id: "CallOrders", label: "Orders/Ratio", minWidth: 130, align: "center" },
    { id: "Putvolume", label: "Volume [Lacs]", minWidth: 130, align: "center" },
    {
        id: "oi",
        label: "OI ",
        minWidth: 130,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "size",
        label: "Greeks/Ratio",
        minWidth: 200,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "density",
        label: "OI ",
        minWidth: 130,
        align: "center",
        format: (value) => value.toFixed(2),
    },
    {
        id: "density1",
        label: "Volume [Lacs]",
        minWidth: 130,
        align: "center",
        format: (value) => value.toFixed(2),
    },
    {
        id: "PutOrders",
        label: "Orders/Ratio",
        minWidth: 130,
        align: "center",
        format: (value) => value.toFixed(2),
    },
    {
        id: "put",
        label: "LTP",
        minWidth: 170,
        align: "center",
        format: (value) => value.toFixed(2),
    },
];

// column without orders
const columnsWithOutOrders = [
    { id: "call", label: "LTP", minWidth: 170, align: "center" },
    { id: "Putvolume", label: "Volume [Lacs]", minWidth: 130, align: "center" },
    {
        id: "population",
        label: "OI ",
        minWidth: 130,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "size",
        label: "Greeks/Ratio",
        minWidth: 130,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "density",
        label: "OI ",
        minWidth: 130,
        align: "center",
        format: (value) => value.toFixed(2),
    },
    {
        id: "density1",
        label: "Volume [Lacs]",
        minWidth: 130,
        align: "center",
        format: (value) => value.toFixed(2),
    },
    {
        id: "put",
        label: "LTP",
        minWidth: 170,
        align: "center",
        format: (value) => value.toFixed(2),
    },
];

const columns = [];

const cellStyle = {
    width: "calc(100% / 9)",
    // minWidth: '100px',
    borderRight: "0.1px  solid  rgba(224, 224, 224, 1)",
};


function ColumnGroupingTable({
    data,
    onFormChange,
    isScrolling,
    setIsScrolling,
    currentValue,
    placeInstantOrder,
    admin,
    TableHeight,
    isConnected,
    msExpiry,
    currentExpiry,
    placingInstantOrder,
    orderInstrument,
    previousTicks,
    oiFactor,
    onOIFactorUpdated
}) {
    // console.log(previousTicks)
    // console.log('option chain data -- ', data, isScrolling, currentValue, admin, TableHeight, isConnected)
    // console.log(currentExpiry,)
    isConnected = true;
    const classes = useStyles();
    const { currentIndex, activePath } = useSelector((state) => state.optionHome);
    let _rankings = useSelector((state) => state.rankings);
    let rankings = _rankings.ranking[currentIndex];
    const max =
        Math.floor(data?.strikePrice / data?.strikeMargin) * data?.strikeMargin;
    const min =
        Math.ceil(data?.strikePrice / data?.strikeMargin) * data?.strikeMargin;
    // console.log('option chain --- min max', min, max, data)
    // const [oiFactor, setOIFactor] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // TABLE COLUMNS
    const columnData = admin ? columnsWithOrders : columnsWithOutOrders;

    // FUNCTION TO SCROLL TABLE ON PAGE REFRESH
    const focusedRowRef = React.useRef(null);
    const tc = React.useRef(null);

    // const options = { ...data.options }

    React.useEffect(() => {
        // console.log('IN TIMEOUT -- ', focusedRowRef?.current)
        if (focusedRowRef?.current && !isScrolling) {
            const topOffset = focusedRowRef?.current?.offsetTop;
            tc?.current?.scrollTo({ top: topOffset - 200, behavior: "smooth" });
            setIsScrolling(true);
        }
    }, [focusedRowRef?.current]);

    // console.log(focusedRowRef.current)

    const handleScroll = () => {
        // setIsScrolling(true);
    };

    return (
        <>
            {isConnected ? (
                <TableContainer
                    ref={tc}
                    component={Paper}
                    sx={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead
                            className={classes.stickyHead}
                            sx={{ padding: "initial", padding: "2vmax 0" }}
                        >
                            <TableRow
                                sx={{ padding: "initial", padding: "2vmax 0", width: "100%" }}
                            >
                                <TableCell
                                    sx={{ padding: "initial", padding: ".5vmax 1vmax" }}
                                    align="left"
                                    colSpan={3}
                                >
                                    <h6 className="font-[Open Sans] uppercase !text-[#505050] text-[1vmax] font-semibold tracking-wider">
                                        Call
                                    </h6>
                                </TableCell>
                                {admin ? <TableCell></TableCell> : null}
                                <TableCell
                                    sx={{ padding: "initial", padding: ".5vmax 1vmax" }}
                                    align="center"
                                    colSpan={1}
                                >
                                    <h6 className="font-[Open Sans] uppercase !text-[#505050] text-[1vmax] font-semibold tracking-wider">
                                        Strike
                                    </h6>
                                </TableCell>
                                {admin ? <TableCell></TableCell> : null}
                                <TableCell></TableCell>
                                <TableCell
                                    sx={{ padding: "initial", padding: ".5vmax 1vmax" }}
                                    align="right"
                                    colSpan={3}
                                >
                                    <h6 className="font-[Open Sans] uppercase !text-[#505050] text-[1vmax] font-semibold tracking-wider">
                                        Put
                                    </h6>
                                </TableCell>
                            </TableRow>
                            <TableRow
                                style={{
                                    backgroundColor: "teal",
                                }}
                            >
                                {columnData?.map((column, i) => (
                                    // {column?.label === 'orders' ? 'hello':null}
                                    <TableCell
                                        sx={{
                                            backgroundColor: "#F3F3F3",
                                            borderRight: "0.1px  solid  #9E9E9E4D",
                                        }}
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            top: 35,
                                            minWidth: column.minWidth,
                                            padding: ".2vmax 0",
                                        }}
                                        className={
                                            column.id === "call"
                                                ? classes.sticky
                                                : column.id === "put"
                                                    ? classes.sticky2
                                                    : ""
                                        }
                                    >
                                        <h6 className="font-[Open Sans] !text-[#505050] text-sm font-medium tracking-wider">
                                            {column.label}
                                        </h6>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody onWheel={() => handleScroll()} className="relative">
                            {data?.[currentExpiry]?.options && data?.[currentExpiry]?.options["CE"]
                                ? Object.keys(data?.[currentExpiry]?.options["CE"])?.map((callKey, rowIndex) => {
                                    const putKey = Object.keys(data?.[currentExpiry]?.options["PE"])[rowIndex];
                                    const put = data?.[currentExpiry]?.options["PE"][putKey];
                                    const call = data?.[currentExpiry]?.options["CE"][callKey];
                                    // console.log('OPTIONS CALL --- ', call, put)
                                    // console.log(data?.strikePrice, call.strike, put.strike)
                                    // if (data?.strikePrice - (10 * data?.strikeMargin) > call.strike || data?.strikePrice + (10 * data?.strikeMargin) < call.strike) {
                                        return (
                                            <TableRow
                                                ref={max == call?.strike ? focusedRowRef : null}
                                                key={rowIndex}
                                            >
                                                <TableCell
                                                    className={
                                                        data?.strikePrice >= call?.strike
                                                            ? `!bg-blue-100 ${classes.sticky}`
                                                            : `!bg-white ${classes.sticky}`
                                                    }
                                                    sx={cellStyle}
                                                    align={"center"}
                                                >
                                                    {/* {console.log(placingInstantOrder)} */}
                                                    <CallCell
                                                        showBasket={activePath == "basket"}
                                                        loading={
                                                            placingInstantOrder &&
                                                            call?.instrument_token == orderInstrument
                                                        }
                                                        onBuy={() =>
                                                            onFormChange(
                                                                "buy",
                                                                "call",
                                                                call?.strike,
                                                                call,
                                                                data["tradingsymbol"]
                                                            )
                                                        }
                                                        onSell={() =>
                                                            onFormChange(
                                                                "sell",
                                                                "call",
                                                                call?.strike,
                                                                call,
                                                                data["tradingsymbol"]
                                                            )
                                                        }
                                                        row={{ call }}
                                                        data={data}
                                                        admin={admin}
                                                        prevLTP={
                                                            // previousTicks[`'${call?.instrument_token}'`]?.ltp
                                                            call?.change
                                                        }
                                                        changePercent={call?.changePercent}
                                                        onInstantBuy={() =>
                                                            placeInstantOrder(
                                                                "buy",
                                                                "call",
                                                                call?.strike,
                                                                call,
                                                                callKey
                                                            )
                                                        }
                                                        onInstantSell={() =>
                                                            placeInstantOrder(
                                                                "sell",
                                                                "call",
                                                                call?.strike,
                                                                call,
                                                                callKey
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                {admin ? (
                                                    <TableCell
                                                        className={
                                                            data?.strikePrice >= call?.strike
                                                                ? `bg-blue-100`
                                                                : ""
                                                        }
                                                        sx={cellStyle}
                                                        align={"center"}
                                                    >
                                                        <OrderCell
                                                            indexLTP={call?.indexLTP
                                                                ?.toFixed(2)
                                                                ?.toLocaleString()}
                                                            smb={call?.smb?.toFixed(2)?.toLocaleString()}
                                                            tbs={call?.tbs?.toFixed(2)}
                                                            indexLTPRank={
                                                                rankings.includes("INDEX/LTP")
                                                                    ? call?.indexLTPRank
                                                                    : null
                                                            }
                                                            tbsRank={
                                                                rankings?.includes("TBO/TSO")
                                                                    ? call?.tbsRank
                                                                    : null
                                                            }
                                                            smbRank={
                                                                rankings?.includes("BS-BB")
                                                                    ? call?.smbRank
                                                                    : null
                                                            }
                                                        />
                                                    </TableCell>
                                                ) : null}
                                                <TableCell
                                                    className={
                                                        data?.strikePrice >= call?.strike
                                                            ? `bg-blue-100`
                                                            : ""
                                                    }
                                                    sx={cellStyle}
                                                    align={"center"}
                                                >
                                                    <Volume
                                                        admin={admin}
                                                        volume={
                                                            call?.volume
                                                                ? (call?.volume / 100000)?.toLocaleString(
                                                                    undefined,
                                                                    twoDotDigit
                                                                ) || 0
                                                                : null
                                                        }
                                                        percentile={
                                                            (
                                                                (call?.volume / data[currentExpiry].callVolume) * 100 || 0
                                                            )?.toLocaleString(undefined, twoDotDigit) + "%"
                                                        }
                                                        volumeRank={
                                                            rankings?.includes("VOLUME %")
                                                                ? call?.volumeRank
                                                                : null
                                                        }
                                                        iv={
                                                            iv.getImpliedVolatility(
                                                                call?.last_price,
                                                                data?.strikePrice,
                                                                call?.strike,
                                                                msExpiry > call?.ms ? msExpiry - call?.ms : 0,
                                                                0.1,
                                                                "call",
                                                                0.1
                                                            ) * 100
                                                        }
                                                        inMinute={call.show_vol ? call.inMinute : null}
                                                        inMinuteRanking={
                                                            rankings.includes("VOL 1/5 Min")
                                                                ? call.inMinuteRank
                                                                : 0
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        data?.strikePrice >= call?.strike
                                                            ? `bg-blue-100`
                                                            : ""
                                                    }
                                                    sx={cellStyle}
                                                    align={"center"}
                                                >
                                                    <OICell
                                                        admin={admin}
                                                        oi={
                                                            call?.oi
                                                                ? (call?.oi / 1000)?.toLocaleString(
                                                                    undefined,
                                                                    twoDotDigit
                                                                )
                                                                : 0
                                                        }
                                                        oiRank={
                                                            rankings?.includes("OI") ? call?.oiRank : null
                                                        }
                                                        percentile={
                                                            (
                                                                (call?.oi / data[currentExpiry].callOI) * 100 || 0
                                                            )?.toLocaleString(undefined, twoDotDigit) + "%"
                                                        }
                                                        candle={
                                                            <>
                                                                <OIChange
                                                                    oiFactor={oiFactor}
                                                                    updateOIFactor={(oi) => {
                                                                        // console.log('OI UPDATED', oi)
                                                                        onOIFactorUpdated(oi)
                                                                    }}
                                                                    oi={data[currentExpiry].callOI}
                                                                    high={call?.oiHigh}
                                                                    low={call?.oiLow}
                                                                    close={call?.oi}
                                                                    open={call?.prev_oi}
                                                                    qty={data?.qty}
                                                                />
                                                            </>
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell sx={cellStyle} align={"center"}>
                                                    {/* {console.log(call.oiCPRRank, put.oiCPRRank, rankings)} */}
                                                    <StrikeCell
                                                        max={max}
                                                        min={min}
                                                        admin={admin}
                                                        strike={call?.strike}
                                                        strikePrice={parseInt(call?.strike)?.toLocaleString(
                                                            "en",
                                                            {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0,
                                                            }
                                                        )}
                                                        pcr={put?.oiPCR}
                                                        cpr={call?.oiCPR}
                                                        cprRank={
                                                            rankings.includes("OI CPR") ? call?.oiCPRRank : 0
                                                        }
                                                        pcrRank={
                                                            rankings.includes("OI PCR") ? call?.oiPCRRank : 0
                                                        }
                                                        volumePCR={put?.volPCR}
                                                        volumeCPR={call?.volCPR}
                                                        volumePCRRank={
                                                            rankings.includes("VOL PCR")
                                                                ? call?.volPCRRank
                                                                : 0
                                                        }
                                                        volumeCPRRank={
                                                            rankings.includes("VOL CPR")
                                                                ? call?.volCPRRank
                                                                : 0
                                                        }
                                                        onStrike={() => onFormChange()}
                                                        // theta={(msExpiry - call?.ms) ? (xGreeks(
                                                        //     data?.strikePrice,
                                                        //     call?.strike,
                                                        //     currentExpiry,
                                                        //     (msExpiry - call?.ms)
                                                        // ).callTheta)?.toFixed(1) || 0 : 0}
                                                        // vega={(msExpiry - call?.ms) ? xGreeks(
                                                        //     data?.strikePrice,
                                                        //     call?.strike,
                                                        //     currentExpiry,
                                                        //     msExpiry - call?.ms
                                                        // ).vega || 0 : 0}
                                                        theta={"0.0"}
                                                        vega={"0.0"}
                                                    />
                                                </TableCell>
                                                {/* {console.log(data?.strikePrice, put)} */}
                                                <TableCell
                                                    className={
                                                        data?.strikePrice < put?.strike ? `bg-blue-100` : ""
                                                    }
                                                    sx={cellStyle}
                                                    align={"center"}
                                                >
                                                    {/* {console.log(put)} */}
                                                    <OICell
                                                        admin={admin}
                                                        oi={
                                                            put?.oi
                                                                ? (put?.oi / 1000)?.toLocaleString(
                                                                    undefined,
                                                                    twoDotDigit
                                                                )
                                                                : 0
                                                        }
                                                        percentile={
                                                            (
                                                                (put?.oi / data[currentExpiry].putOI) * 100 || 0
                                                            )?.toLocaleString(undefined, twoDotDigit) + "%"
                                                        }
                                                        oiRank={
                                                            rankings?.includes("OI") ? put?.oiRank : null
                                                        }
                                                        candle={
                                                            <OIChange
                                                                oiFactor={oiFactor}
                                                                updateOIFactor={(oi) => {
                                                                    // console.log('OI UPDATED', oi)
                                                                    onOIFactorUpdated(oi)
                                                                }}
                                                                // updateOIFactor={(oi) => setOIFactor(oi)}
                                                                oi={data[currentExpiry].putOI}
                                                                high={put?.oiHigh}
                                                                low={put?.oiLow}
                                                                close={put?.oi}
                                                                open={put?.prev_oi}
                                                                qty={data?.qty}
                                                            />
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        data?.strikePrice < put?.strike ? `bg-blue-100` : ""
                                                    }
                                                    sx={cellStyle}
                                                    align={"center"}
                                                >
                                                    <Volume
                                                        admin={admin}
                                                        volume={
                                                            put?.volume
                                                                ? (put?.volume / 100000)?.toLocaleString(
                                                                    undefined,
                                                                    twoDotDigit
                                                                ) || 0
                                                                : null
                                                        }
                                                        percentile={
                                                            (
                                                                (put?.volume / data[currentExpiry].putVolume) * 100 || 0
                                                            )?.toLocaleString(undefined, twoDotDigit) + "%"
                                                        }
                                                        volumeRank={
                                                            rankings?.includes("VOLUME %")
                                                                ? put?.volumeRank
                                                                : null
                                                        }
                                                        iv={
                                                            iv.getImpliedVolatility(
                                                                put?.last_price,
                                                                data?.strikePrice,
                                                                put?.strike,
                                                                msExpiry > put?.ms ? msExpiry - put?.ms : 0,
                                                                0.1,
                                                                "put",
                                                                0.1
                                                            ) * 100
                                                        }
                                                        inMinute={put.show_vol ? put.inMinute : null}
                                                        inMinuteRanking={
                                                            rankings.includes("VOL 1/5 Min")
                                                                ? put.inMinuteRank
                                                                : 0
                                                        }
                                                    />
                                                </TableCell>
                                                {admin ? (
                                                    <TableCell
                                                        className={
                                                            data?.strikePrice < put?.strike
                                                                ? `bg-blue-100`
                                                                : ""
                                                        }
                                                        sx={cellStyle}
                                                        align={"center"}
                                                    >
                                                        <OrderCell
                                                            indexLTP={put?.indexLTP
                                                                ?.toFixed(2)
                                                                ?.toLocaleString()}
                                                            tbs={put?.tbs?.toFixed(2)}
                                                            smb={put?.smb?.toFixed(2)?.toLocaleString()}
                                                            indexLTPRank={
                                                                rankings.includes("INDEX/LTP")
                                                                    ? put?.indexLTPRank
                                                                    : null
                                                            }
                                                            tbsRank={
                                                                rankings?.includes("TBO/TSO")
                                                                    ? put?.tbsRank
                                                                    : null
                                                            }
                                                            smbRank={
                                                                rankings?.includes("BS-BB")
                                                                    ? put?.smbRank
                                                                    : null
                                                            }
                                                        />
                                                    </TableCell>
                                                ) : null}
                                                {/* <TableCell></TableCell> */}
                                                <TableCell
                                                    className={
                                                        data?.strikePrice < put?.strike
                                                            ? `!bg-blue-100 ${classes.sticky2}`
                                                            : `!bg-white ${classes.sticky2}`
                                                    }
                                                    sx={cellStyle}
                                                    align={"center"}
                                                >
                                                    <PutCell
                                                        showBasket={activePath == "basket"}
                                                        loading={
                                                            placingInstantOrder &&
                                                            put?.instrument_token == orderInstrument
                                                        }
                                                        onBuy={() =>
                                                            onFormChange(
                                                                "buy",
                                                                "put",
                                                                put?.strike,
                                                                put,
                                                                data["tradingsymbol"]
                                                            )
                                                        }
                                                        onSell={() =>
                                                            onFormChange(
                                                                "sell",
                                                                "put",
                                                                put?.strike,
                                                                put,
                                                                data["tradingsymbol"]
                                                            )
                                                        }
                                                        row={{ put }}
                                                        admin={admin}
                                                        prevLTP={
                                                            put.change
                                                        }
                                                        changePercent={put.changePercent}
                                                        data={data}
                                                        onInstantBuy={() =>
                                                            placeInstantOrder(
                                                                "buy",
                                                                "put",
                                                                put?.strike,
                                                                put,
                                                                putKey
                                                            )
                                                        }
                                                        onInstantSell={() =>
                                                            placeInstantOrder(
                                                                "sell",
                                                                "put",
                                                                put?.strike,
                                                                put,
                                                                putKey
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    // }
                                })
                                : null}
                        </TableBody>
                    </Table>
                    {!admin?.active ? (
                        <Box className="w-full h-full px-10 flex flex-col justify-center items-center">
                            {/* <BlockIcon sx={{ fontSize: 160 }} color='disabled' /> */}
                            {/* <p className='text-red-500 text-xl mb-2'>failed to load option chain!</p> */}
                            {/* <Button variant='outlined'>
                                Connect Broker Now
                            </Button> */}
                        </Box>
                    ) : !data?.[currentExpiry]?.options ? (
                        <Box className="w-full h-full px-10 flex flex-col justify-center items-center">
                            <p className="font-mono text-[12px] mb-2">
                                Loading please wait...
                            </p>
                            <LinearProgress sx={{ width: "100%" }} />
                        </Box>
                    ) : null}
                </TableContainer>
            ) : (
                <div className="w-full h-full flex justify-center items-center">
                    <NoRows />
                </div>
            )}
        </>
    );
}

export default React.memo(ColumnGroupingTable);
