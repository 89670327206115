import * as React from 'react';
import { CircularProgress } from "@mui/material"

const Loading = ({ message }) => {
    


    return (
        <div className="flex-1 h-40 flex justify-center items-center ">
            {message ?
                <p className="text-lg text-center text-slate-400">{message}</p>
                : <CircularProgress  color="primary" size={40} />
            }
        </div>
    )
}

export default Loading