import * as  React from 'react'
import AdminHeader from '../../PrivateComponents/AdminHeader'
import AllTransections from '../../PrivateComponents/AllTransections';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import PeopleIcon from '@mui/icons-material/People';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import FilterDrawer from '../../PrivateComponents/FilterDrawer';
import AdminTable from '../../PrivateComponents/AdminTable';
import { http } from '../../Services/api.services';
import { BASE_URL } from '../../default.config';


const HeadCell = ({ title }) => (
    <h6 className='text-[1.5vmax]  lg:text-[.8vmax] text-[#000000] font-semibold tracking-wider'>
        {title}
    </h6>
)


const columns = [

    { id: 'name', label: <HeadCell title="Name" />, minWidth: 110, maxWidth: 200, align: 'center' },
    {
        id: 'mobile',
        label: <HeadCell title="Mobile" />,
        minWidth: 130,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'subscription',
        label: <HeadCell title="Subscription" />,
        minWidth: 130,
        align: 'center',
        format: (value) => value.toFixed(2),
    },

    {
        id: 'price',
        label: <HeadCell title="Price" />,
        minWidth: 170,
        align: 'center',
        format: (value) => value.toFixed(2),
    },

    {
        id: 'date',
        label: <HeadCell title="Joined on" />,
        minWidth: 110,
        align: 'center',
        type: 'date',
        format: (value) => value.toFixed(2),
    },
];



const RevenueScreen = () => {

    const [openDrawer, setOpenDrawer] = React.useState(false)
    const [tableHeight, seTableHeight] = React.useState(null)
    const [totalRevenue, setTotalRevenue] = React.useState(null)
    const [totalRevenueCount, setTotalRevenueCount] = React.useState(0)
    const [revenues, setRevenues] = React.useState(null)
    const tc = React.useRef(null);

    const handleDrawerClick = () => {
        setOpenDrawer(true)
    }

    React.useEffect(() => {
        if (tc?.current) {
            seTableHeight(tc?.current?.offsetHeight - 88)
        }
        getRevenue();
    }, []);

    const getRevenue = async () => {
        try {
            const res = await http.get(`${BASE_URL}admin/revenue/total`)
            setTotalRevenue(res?.data?.total)
            const revenues = await http.get(`${BASE_URL}admin/revenue`)
            setRevenues(revenues?.data)
            setTotalRevenueCount(revenues?.data?.length)
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            {/* <FilterDrawer openDrawer={openDrawer} data={filterRowsData} onFormChange={() => setOpenDrawer(false)} /> */}
            <div className='w-full flex flex-col flex-1 px-2'>
                <AdminHeader
                    text='REVENUE'
                    onFormChange={handleDrawerClick}
                    totalPlans={totalRevenueCount}
                />
                <div className='w-full flex items-center justify-between  py-3'>
                    <div className='flex items-center justify-between px-3  border border-[#ECEAEA] py-2 lg:py-3 lg:px-5 bg-[#FDFDFD] rounded shadow w-fit gap-6'>
                        <Avatar>
                            <PeopleIcon />
                        </Avatar>
                        <div>
                            <h6 className='text-[#393939] text-[2vmax] lg:text-[1.2vmax] font-semibold'>₹ {totalRevenue}</h6>
                            <p className='text-[#717171] text-[1.5vmax] lg:text-[.8vmax] font-normal'>Total revenue earned</p>
                        </div>
                    </div>
                </div>
                <div ref={tc} className='flex cursor-pointer flex-wrap flex-1 mb-2 max-w-[93vw] lg:max-w-full items-start justify-start gap-5rounded'>
                    <AdminTable
                        height={tableHeight}
                        rows={revenues}
                        columns={columns}
                        pagination={true}
                        click={(id) => {
                            console.log('click');
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default RevenueScreen