import React, { useEffect, useRef } from "react";
import VWAPAggregate from "../../PublicComponents/VWAPAggregate";
import { ApiService, http } from "../../Services/api.services";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrentVwapindex,
  updateVwapDatasource,
  updateVwapStartDate,
} from "../../store/Reducer/ToolsReducer";
import { useTools } from "../../Contexts/ToolsContext/ToolsContext";

const vwap = require("technicalindicators").VWAP;

const VWAPScreen = () => {
  const dispatch = useDispatch();
  const { vwapStartDate, currentVwapIndex, vwapDataSource } = useSelector(
    (state) => state.tools
  );
  const { onFormSubmit, loading } = useTools();
  const [currentIndex, setCurrentIndex] = React.useState("");
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const [currentExpiry, setCurrentExpiry] = React.useState("");
  const [expiry, setExpiry] = React.useState({});
  const [height, setHeight] = React.useState(0);

  const [minuteUpdate, setMinuteUpdate] = React.useState(null);

  const containerRef = React.useRef();

  let xTimer;

  const onFormChange = (value, key) => {
    try {
      // console.log(value, key);
      if (key === "index") {
        setCurrentIndex(value);
        dispatch(updateCurrentVwapindex(value));
      } else if (key === "expiry") {
        setCurrentExpiry(value);
      } else if (key === "from") {
        // console.log(new Date(value))
        setFromDate(value);
        dispatch(updateVwapStartDate(value));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const types = ["instrument_token", "from", "to", "interval"];

  useEffect(() => {
    if (vwapDataSource?.length > 0)
      setHeight(containerRef?.current?.offsetHeight);
  }, [containerRef?.current?.offsetHeight, vwapDataSource]);

  return (
    <>
      <div ref={containerRef} className="w-full flex-1 h-full">
        <div className="w-full h-8 bg-white">
          <h6 className="text-center text-blue-500 font-bold">Anchored VWAP</h6>
        </div>
        <div className="px-3">
          <VWAPAggregate
            height={height}
            loading={loading}
            onFormChange={onFormChange}
            onFormSubmit={onFormSubmit}
            expiry={expiry[currentIndex?.index?.toLowerCase()]}
            currentExpiry={currentExpiry}
            // datasource={datasource}
            datasource={vwapDataSource}
            vwapStartDate={vwapStartDate}
            currentVwapIndex={currentVwapIndex}
          />
        </div>
      </div>
    </>
  );
};

export default VWAPScreen;
