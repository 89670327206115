import * as React from "react";
import { BasketPositionCard, BasketPositionTotal } from "./BasketCard";
import { useDispatch, useSelector } from "react-redux";
import { setBasketPositions, updateBasketStatus, updateCurrentPosition } from "../../store/Reducer/BasketReducer";
import { http } from "../../Services/api.services";
import { showToast } from "../../store/Actions/Action";
import { PositionSkeletons } from "./BasketSkelatons";
import { useMarketContext } from "../../Contexts/MarketContext/MarketContext";

const positionsInstruments = {};

const BasketPosition = ({ data }) => {
	const dispatch = useDispatch();
	const { basketPositions, currentPosition } = useSelector((state) => state.Baskets);
	const { admin } = useSelector((state) => state.admin);
	const user = useSelector((state) => state.user);
	const id = admin ? admin?.id : user?.id;
	const [isLoading, setIsLoading] = React.useState(false);
	const market = useMarketContext();
	const memoizedMarket = React.useMemo(() => market, [market]);
	const [finalPL, setFinalPL] = React.useState(0);

	const [basketPL, setBasketPL] = React.useState({})

	const createOrderBody = (type, positions) => {
		// FUNCTION CREATES ORDER BODY TO BE SENT
		try {
			const returner = []
			for (let index = 0; index < positions.length; index++) {
				const position = positions[index];
				let _type = position.total_quantity > 0 ? 'buy' : 'sell'
				if (_type != type.toLowerCase()) {
					if (position.total_quantity) {
						returner.push({
							"exchange": position.exchange,
							"index": position.index,
							"instrument_token": position.instrument_token,
							"price": 0,
							"order_type": "MARKET",
							"product": "NRML",
							"quantity": Math.abs(position.total_quantity),
							"tradingsymbol": position.tradingsymbol,
							"transaction_type": type?.toUpperCase(),
							"expiry": position.expiry,
							"validity": "DAY"
						})
					}
				}
			}
			return returner
		}
		catch (e) {
			throw e
		}
	}

	const exitAllBasketPosition = async (basket, position) => {
		try {
			// EXIT WHOLE BASKET LEGS
			setIsLoading(true);
			const body = {
				buy: createOrderBody('BUY', JSON.parse(position.orders)),
				sell: createOrderBody('SELL', JSON.parse(position.orders)),
				open_orders: JSON.parse(position.open_orders),
				basket_id: basket.id,
				stoploss: {},
				user_id: admin.id,
				pid: position?.pid,
				type: 'exit'
			}
			const response = await http.post(`orders/basket/executeorders`, body);
			setIsLoading(false);
			if (response?.status === 200) {
				getAllPositions()
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_snackbar));
			}
		} catch (e) {
			setIsLoading(false);
			console.log(`ERROR WHILE DELETING BASKET ${e}`);
			const _snackbar = {
				message: e?.response?.data?.error,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
		}
	};

	const getAllPositions = async () => {
		try {
			const response = await http.get(`orders/${id}/basket/position`);
			if (response?.status === 200) {
				console.log('Fetched positions:', response?.data?.data);
				dispatch(setBasketPositions(response?.data?.data));
			} else {
				dispatch(setBasketPositions([]));
			}
		} catch (e) {
			dispatch(setBasketPositions([]));
			console.log(`ERROR WHILE FETCHING POSITIONS ${e}`);
		}
	};

	const returnPendingLegs = async (pid) => {
		try {
			// RETURNS SELL ORDERS NOT SENT TO BROKER BEFORE BUY EXECUTION
			const response = await http.get(`orders/basket/history/${pid}/pending`)
			if (response?.status == 200)
				return response?.data?.data
			else return []
		}
		catch (e) {
			console.log('ERROR WHILE FETCHING PENDING ORDERS ', e)
		}
	}

	React.useEffect(() => {
		// console.log('called again')
		if (basketPositions && currentPosition) {
			const filteredPosition = basketPositions.find((position) => position.id === currentPosition?.id);
			console.log(filteredPosition)
			updatePositionRedux(filteredPosition)
		}
	}, [basketPositions]);

	const updatePositionRedux = async (position) => {
		console.log(position.pendings)
		console.log(currentPosition?.id)
		if (true) {
			const pendings = await returnPendingLegs(position?.pid)
			console.log({ ...position, pendings: JSON.stringify(pendings) })
			dispatch(updateCurrentPosition({ ...position, pendings: JSON.stringify(pendings) }));
		}
	}

	React.useEffect(() => {
		let _positionsPL = { ...basketPL }
		let _finalPL = 0;
		if (basketPositions && basketPositions?.length > 0) {
			for (let index = 0; index < basketPositions.length; index++) {
				let _totalPL = 0
				const orders = basketPositions[index]?.orders && JSON.parse(basketPositions[index]?.orders);
				for (let _index = 0; _index < orders.length; _index++) {
					const order = orders[_index];
					const instrumentLTP = returnLTP(order);
					const profitLoss = parseFloat(order.profit_loss);
					const totalQuantity = parseFloat(order.total_quantity);
					let _PL = profitLoss - totalQuantity * instrumentLTP;
					if (!Number.isNaN(profitLoss) && !Number.isNaN(totalQuantity) && !Number.isNaN(instrumentLTP)) {
						_PL = parseFloat(_PL.toFixed(2));
						if (_PL) _totalPL += _PL;
					}
				}
				if (_totalPL) _finalPL += _totalPL;
				_positionsPL[basketPositions[index]?.id] = _totalPL
				// _positionsPL.push({ basker_id: basketPositions[index], pl: _totalPL })
			}
			setBasketPL(_positionsPL)
			setFinalPL(_finalPL);

			// const _allPositions = basketPositions ? [...basketPositions] : [];
			// let _finalPL = 0;
			// const updatedPositions = _allPositions.map((position) => {
			// 	let orders = position?.orders ? JSON.parse(position.orders) : [];
			// 	let _totalPL = 0;

			// 	orders?.forEach((order) => {
			// 		const instrumentLTP = returnLTP(order);
			// 		const profitLoss = parseFloat(order.profit_loss);
			// 		const totalQuantity = parseFloat(order.total_quantity);
			// 		let _PL = profitLoss - totalQuantity * instrumentLTP;
			// 		if (!Number.isNaN(profitLoss) && !Number.isNaN(totalQuantity) && !Number.isNaN(instrumentLTP)) {
			// 			_PL = parseFloat(_PL.toFixed(2));
			// 			if (_PL) _totalPL += _PL;
			// 		}
			// 	});
			// 	if (_totalPL) _finalPL += _totalPL;
			// 	return { ...position, TPL: _totalPL };
			// });
			// setFinalPL(_finalPL);
			// const hasChanged = JSON.stringify(basketPositions) !== JSON.stringify(updatedPositions);
			// if (hasChanged) {
			// 	dispatch(setBasketPositions(updatedPositions));
			// }
		}
		// console.log('changes in market', basketPL)
	}, [memoizedMarket]);

	const returnLTP = (order) => {
		try {
			const instrument = order?.instrument_token;
			return market.basketPositions && market.basketPositions?.[instrument]
				? market.basketPositions?.[instrument]?.last_price
				: 0;
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div className="lg:h-full w-full flex flex-col border-l-[0.5px] border-gray-100 relative flex-1 min-h-[300px]">
			<div className="w-full flex flex-col flex-1 overflow-y-auto pb-20 items-start p-4 px-0">
				{basketPositions ? (
					basketPositions.length > 0 ? (
						basketPositions.map((position, index) => (
							<BasketPositionCard
								position={position}
								key={index}
								isLoading={isLoading}
								selectPosition={async () => {
									console.log('FROM HERE!')
									updatePositionRedux(position)
									dispatch(updateBasketStatus("position selected"));
								}}
								pl={basketPL[position?.id]}
								exitBasket={(basketId) => {
									exitAllBasketPosition(basketId, position);
									// console.log(`Exiting basket with ID: ${basketId}`);
								}}
							/>
						))
					) : (
						<div className="w-full h-full flex justify-center items-center">
							<p className="text-center">NO POSITIONS FOUND!</p>
						</div>
					)
				) : (
					<PositionSkeletons />
				)}
			</div>
			<div className="w-full absolute bottom-0 z-50">
				<BasketPositionTotal finalPL={finalPL} />
			</div>
		</div>
	);
};

export default BasketPosition;

const SL = () => (
	<div className="bg-red-100 px-2 py-1 w-10 rounded-sm">
		<p className="text-[10px] font-bold tracking-widest text-center text-red-500">SL</p>
	</div>
);

const T = () => (
	<div className="bg-green-200 bg-opacity-40 px-2 py-1 w-10 rounded-sm">
		<p className="text-[10px] font-bold tracking-widest text-center text-blue-500">T</p>
	</div>
);

const M = () => (
	<div className="bg-blue-100 px-2 py-1 w-10 rounded-sm">
		<p className="text-[10px] font-bold tracking-widest text-center text-blue-500">M</p>
	</div>
);
