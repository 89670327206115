import * as  React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { useLocation, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography';
import { MyCalender, MyCalenderMobile } from '../PublicComponents/Icons';
import { Button, IconButton, Tooltip } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import DateRangeSelect from './DateRangeSelect';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const names = [
  'Suggestions',
  'Feedbacks',
  'Complaints',
  'Other',

];

const AdminHeader = ({ back, text, onchange, totalPlans, ondateChange, onFormChange, onAdd, onUserSearch, getDatas }) => {
  const navigate = useNavigate()
  const location = useLocation().pathname.split('/');
  const path = location[2];
  const [personName, setPersonName] = React.useState([]);
  const [isSearch, setIsSearch] = React.useState(false);
  const dateInputRef = React.useRef(null);
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div className='flex gap-2 '>
      {back ?
        <IconButton disableFocusRipple disableRipple onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton> : null
      }
      <div className='w-full flex flex-col items-start py-5  lg:py-4 lg:flex-row lg:justify-between lg:items-center  '>
        {/* {path === 'feedback' ? (
          <div className='hidden lg:block'>
            <Tabs value={0} aria-label="basic tabs example">
              <Tab label={<h2 onClick={onchange} className=' hidden lg:block tracking-wider cursor-pointer uppercase font-semibold text-[2.5vmax] lg:text-[1vmax]'>FEEDBACK</h2>} {...a11yProps(0)} />
              <Tooltip title="COMING SOON">
                <Tab label={<h2 onClick={onchange} className=' hidden lg:block tracking-wider cursor-pointer uppercase font-semibold text-[2.5vmax] lg:text-[1vmax]'>COMPLAINTS</h2>} {...a11yProps(1)} />
              </Tooltip>
            </Tabs>
          </div>
        ) : ( */}
        <h2 onClick={onchange} className='text-[#000000] hidden lg:block tracking-wider cursor-pointer uppercase font-semibold text-[2.5vmax] lg:text-[1.5vmax]'>{text} <span className='text-[2vmax] lg:text-[1vmax]'>({totalPlans})</span></h2>
        {/* )} */}

        {path === 'dashboard' ? (
          <h1></h1>
        ) : path === 'algorithms' ? (
          <div className='flex  items-center justify-between  gap-3 w-full lg:w-fit lg:flex-row py-2'>
            <div className='w-full lg:w-fit'>
              <TextField
                id="input-with-icon-textfield"
                placeholder='search'
                InputProps={{
                  startAdornment: (
                    // <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'rgba(55, 55, 55, 0.44)' }} />
                    // </InputAdornment>
                  ),
                }}
                onFocus={() => {
                  if (!isLargeScreen) {
                    setIsSearch(true);
                  }
                }}
                onBlur={() => {
                  if (!isLargeScreen) {
                    setIsSearch(false);
                  }
                }}
                fullWidth
                variant="outlined"
                size='small'
              />
            </div>
            {!isSearch && (
              <div className='flex items-center  gap-1 w-fit lg:w-fit lg:gap-2'>
                <div className='max-h-full hidden'>
                  <FormControl fullWidth
                    sx={{
                      minWidth: isLargeScreen ? '7vw' : '20vw',
                      maxWidth: isLargeScreen ? '7vw' : '20vw',
                    }}
                  >
                    <InputLabel id="demo-multiple-checkbox-label" sx={{ fontSize: '1vmax' }}>Plan</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      size='small'
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={personName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className='w-5/12 lg:w-fit hidden lg:block'>
                  <TextField
                    id="input-with-icon-textfield"
                    inputRef={dateInputRef}
                    type='date'
                    variant="outlined"
                    size='small'
                    fullWidth

                  />
                </div>
                <div className='block lg:hidden' onClick={() => dateInputRef.current.showPicker()}>
                  <MyCalenderMobile />
                </div>
              </div>
            )}


          </div>
        ) : path === 'subscriptions' ? (
          <div className='flex flex-col items-start gap-3 w-full lg:w-fit lg:flex-row py-2'>
            <div className='w-full lg:w-fit hidden'>
              <TextField
                id="input-with-icon-textfield"
                placeholder='search'
                InputProps={{
                  startAdornment: (
                    // <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'rgba(55, 55, 55, 0.44)' }} />
                    // </InputAdornment>
                  ),
                }}
                fullWidth
                variant="outlined"
                size='small'
              />
            </div>

          </div>
        ) : path === 'users' ? (
          <div className='flex  items-center justify-between  gap-2 w-full lg:w-fit lg:flex-row py-2'>
            <div className='w-full lg:w-fit'>
              <TextField
                id="input-with-icon-textfield"
                placeholder='search'
                InputProps={{
                  startAdornment: (
                    // <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'rgba(55, 55, 55, 0.44)' }} />
                    // </InputAdornment>
                  ),
                }}
                onFocus={() => {
                  if (!isLargeScreen) {
                    setIsSearch(true);
                  }
                }}
                onBlur={() => {
                  if (!isLargeScreen) {
                    setIsSearch(false);
                  }
                }}
                onChange={(e) => onUserSearch(e?.target?.value)}
                fullWidth
                variant="outlined"
                size='small'
              />
            </div>
            {!isSearch && (
              <div className='flex items-center gap-1 w-fit lg:w-fit lg:gap-4'>
                <div className='max-h-full hidden'>
                  <FormControl fullWidth
                    sx={{
                      minWidth: isLargeScreen ? '7vw' : '20vw',
                      maxWidth: isLargeScreen ? '7vw' : '20vw',
                    }}
                  >
                    <InputLabel id="demo-multiple-checkbox-label" sx={{ fontSize: '1vmax' }}>Plan</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      size='small'
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={personName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className='w-5/12 lg:w-fit hidden '>
                  <TextField
                    id="input-with-icon-textfield"
                    inputRef={dateInputRef}
                    type='date'
                    variant="outlined"
                    size='small'
                    fullWidth
                  />
                  <DateRangeSelect getData={getDatas} />
                </div>


                <div className='block lg:hidden' onClick={() => dateInputRef.current.showPicker()}>
                  <MyCalenderMobile />
                </div>
                <div onClick={onFormChange} className='w-fit  h-fit p-1.5 border border-[#9E9E9E78] rounded-sm'>
                  <TuneIcon sx={{ color: '#505050' }} />
                </div>
              </div>
            )}

          </div>
        ) : path === 'notifications' ? (
          <div className='flex flex-row items-start gap-3 w-full lg:w-fit lg:flex-row py-2'>
            <div className='w-full lg:w-fit'>
              <TextField
                id="input-with-icon-textfield"
                placeholder='search'
                InputProps={{
                  startAdornment: (
                    // <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'rgba(55, 55, 55, 0.44)' }} />
                    // </InputAdornment>
                  ),
                }}
                onFocus={() => {
                  if (!isLargeScreen) {
                    setIsSearch(true);
                  }
                }}
                onBlur={() => {
                  if (!isLargeScreen) {
                    setIsSearch(false);
                  }
                }}
                fullWidth
                variant="outlined"
                size='small'
              />
            </div>
            {!isSearch && (
              <div className='flex  items-center justify-between w-fit gap-2 lg:w-fit lg:gap-3'>
                <div onClick={ondateChange} className='w-fit hidden cursor-pointer lg:flex items-center justify-between gap-8 px-2 py-2 rounded border border-[#9E9E9E78] lg:w-fit'>
                  <h6 className='text-[#37373770] text-[1vmax]'>Date</h6>
                  <MyCalender />
                </div>
                <div onClick={ondateChange} className='border block lg:hidden border-[#9E9E9E78] rounded'>
                  <MyCalenderMobile />
                </div>
                <div onClick={onchange} className='w-fit bcursor-pointer h-fit py-1 px-3  bg-[#2A3B43] rounded-sm'>
                  <AddIcon sx={{ color: '#fff' }} />
                </div>
              </div>
            )}

          </div>
        ) : path === 'feedback' ? (
          <div className='flex  items-center justify-between  gap-3 w-full lg:w-fit lg:flex-row py-2'>
            <div className='w-full lg:w-fit'>
              <TextField
                id="input-with-icon-textfield"
                placeholder='search'
                InputProps={{
                  startAdornment: (
                    // <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'rgba(55, 55, 55, 0.44)' }} />
                    // </InputAdornment>
                  ),
                }}
                onFocus={() => {
                  if (!isLargeScreen) {
                    setIsSearch(true);
                  }
                }}
                onBlur={() => {
                  if (!isLargeScreen) {
                    setIsSearch(false);
                  }
                }}
                fullWidth
                variant="outlined"
                size='small'
              />
            </div>
            {!isSearch && (
              <div className='flex items-center gap-2 w-fit lg:w-fit lg:gap-4'>
                <div className='max-h-full'>
                  {/* sx={{
                      minWidth: isLargeScreen ? '7vw' : '20vw',
                      maxWidth: isLargeScreen ? '7vw' : '20vw',
                    }} */}
                  <FormControl fullWidth
                    sx={{
                      width: 200
                    }}
                  >
                    <InputLabel id="demo-multiple-checkbox-label" sx={{ fontSize: '1vmax' }}>Type</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      size='small'
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={personName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className='w-5/12 lg:w-fit hidden lg:block'>
                  <TextField
                    id="input-with-icon-textfield"
                    inputRef={dateInputRef}
                    type='date'
                    variant="outlined"
                    size='small'
                    fullWidth

                  />
                </div>
                <div className='block lg:hidden' onClick={() => dateInputRef.current.showPicker()}>
                  <MyCalenderMobile />
                </div>
                {/* <div onClick={handleButtonClick} className='border block lg:hidden border-[#9E9E9E78] rounded p-1.5'>
                <CalendarTodayIcon sx={{ color: '#373737C9' }} />
              </div> */}
                <div onClick={onFormChange} className='w-fit h-fit p-1.5 border border-[#9E9E9E78] rounded-sm'>
                  <TuneIcon sx={{ color: '#505050' }} />
                </div>
              </div>
            )}
          </div>
        ) : path === 'transaction' ? (
          <div className='flex  items-center justify-between  gap-3 w-full lg:w-fit lg:flex-row py-2'>
            <div className='w-full lg:w-fit'>
              <TextField
                id="input-with-icon-textfield"
                placeholder='search'
                InputProps={{
                  startAdornment: (
                    // <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'rgba(55, 55, 55, 0.44)' }} />
                    // </InputAdornment>
                  ),
                }}
                onFocus={() => {
                  if (!isLargeScreen) {
                    setIsSearch(true);
                  }
                }}
                onBlur={() => {
                  if (!isLargeScreen) {
                    setIsSearch(false);
                  }
                }}
                fullWidth
                variant="outlined"
                size='small'
              />
            </div>
            {!isSearch && (
              <div className='flex items-center gap-1 w-fit lg:w-fit lg:gap-2'>
                <div className='max-h-full'>
                  <FormControl fullWidth
                    sx={{
                      minWidth: isLargeScreen ? '7vw' : '20vw',
                      maxWidth: isLargeScreen ? '7vw' : '20vw',
                    }}
                  >
                    <InputLabel id="demo-multiple-checkbox-label" sx={{ fontSize: '1vmax' }}>Users</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      size='small'
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={personName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div onClick={onFormChange} className='w-fit h-fit p-1.5 border border-[#9E9E9E78] rounded-sm'>
                  <TuneIcon sx={{ color: '#505050' }} />
                </div>
              </div>
            )}


          </div>
        ) : path === 'revenue' ? (
          <div className='flex  items-center justify-between  gap-3 w-full lg:w-fit lg:flex-row py-2'>
            <div className='w-full lg:w-fit'>
              <TextField
                id="input-with-icon-textfield"
                placeholder='search'
                InputProps={{
                  startAdornment: (
                    // <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'rgba(55, 55, 55, 0.44)' }} />
                    // </InputAdornment>
                  ),
                }}
                onFocus={() => {
                  if (!isLargeScreen) {
                    setIsSearch(true);
                  }
                }}
                onBlur={() => {
                  if (!isLargeScreen) {
                    setIsSearch(false);
                  }
                }}
                fullWidth
                variant="outlined"
                size='small'
              />
            </div>
            {!isSearch && (
              <div className='flex items-center gap-1 w-fit lg:w-fit lg:gap-2'>
                <div className='max-h-full hidden'>
                  <FormControl fullWidth
                    sx={{
                      minWidth: isLargeScreen ? '7vw' : '20vw',
                      maxWidth: isLargeScreen ? '7vw' : '20vw',
                    }}
                  >
                    <InputLabel id="demo-multiple-checkbox-label" sx={{ fontSize: '1vmax' }}>Subscription</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      size='small'
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={personName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div onClick={onFormChange} className='w-fit h-fit p-1.5 border border-[#9E9E9E78] rounded-sm'>
                  <TuneIcon sx={{ color: '#505050' }} />
                </div>
              </div>
            )}


          </div>
        ) : path === 'settings' ? (
          <div className=' flex-col items-start justify-between gap-3 w-full hidden lg:flex lg:w-fit lg:flex-row py-2'>
            <div className='w-full hidden lg:w-fit'>
              <TextField
                id="input-with-icon-textfield"
                placeholder='search'
                InputProps={{
                  startAdornment: (
                    // <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'rgba(55, 55, 55, 0.44)' }} />
                    // </InputAdornment>
                  ),
                }}
                fullWidth
                variant="outlined"
                size='small'
              />
            </div>
          </div>
        ) : path === 'news' ? (
          <div className='flex gap-4'>
            <TextField
              id="input-with-icon-textfield"
              placeholder='search'
              sx={{
                minWidth: 300
              }}
              InputProps={{
                startAdornment: (
                  // <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'rgba(55, 55, 55, 0.44)' }} />
                  // </InputAdornment>
                ),
              }}
              fullWidth
              variant="outlined"
              size='small'
              onChange={onchange}
            />
            <TextField
              id="date-filter"
              placeholder='Date'
              type='date'
              fullWidth
              variant="outlined"
              size='small'
              onChange={ondateChange}
            />
            <Button
              onClick={() => onAdd()}
              variant='contained'
              size='small'
              color='primary'>
              <AddIcon />
            </Button>
          </div>
        ) : (<p></p>)}
      </div >
    </div>
  )
}

export default AdminHeader