import React from "react";
import { TextField } from "@mui/material";
import { P } from "../Theme/ThemeComponent";
import { useThemeContext } from "../Theme/ThemeContext";
import moment from "moment";

const DatePickerComponent = ({ filterData, setFilterData }) => {
    const { theme } = useThemeContext();
    const startDateRef = React.useRef(null);
    const endDateRef = React.useRef(null);

    const today = moment().format("YYYY-MM-DD");

    const handleStartDateChange = (e) => {
        const selectedDate = e.target.value;
        if (moment(selectedDate).isAfter(today)) {
            alert("Start date cannot be after today.");
            return;
        }

        if (filterData.endDate && moment(selectedDate).isAfter(filterData.endDate)) {
            alert("Start date cannot be after the end date.");
            return;
        }

        setFilterData((prevData) => ({
            ...prevData,
            startDate: selectedDate,
        }));
    };

    const handleEndDateChange = (e) => {
        const selectedDate = e.target.value;
        if (moment(selectedDate).isAfter(today)) {
            alert("End date cannot be after today.");
            return;
        }

        if (filterData.startDate && moment(selectedDate).isBefore(filterData.startDate)) {
            alert("End date cannot be before the start date.");
            return;
        }

        setFilterData((prevData) => ({
            ...prevData,
            endDate: selectedDate,
        }));
    };

    return (
        <>
            <div className="">
                <div className="w-0 h-0 overflow-hidden">
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        type="date"
                        inputRef={startDateRef}
                        value={filterData?.startDate}
                        fullWidth
                        onChange={handleStartDateChange}
                    />
                </div>

                <div style={{ background: theme.palette.background.whisperGray }} className="flex whitespace-nowrap items-center justify-between gap-2 p-4 rounded py-2 cursor-pointer">
                    <div onClick={() => {
                        if (startDateRef.current) {
                            startDateRef.current.showPicker();
                        }
                    }}>
                        <P
                            font='bold'
                            title={filterData?.startDate ? filterData?.startDate : 'DD/MM/YY'}
                            color={theme.palette.text.coolGray}
                        />
                    </div>
                    <span>-</span>
                    <div onClick={() => {
                        if (endDateRef.current) {
                            endDateRef.current.showPicker();
                        }
                    }}>
                        <P
                            font='bold'
                            title={filterData?.endDate ? filterData?.endDate : 'DD/MM/YY'}
                            color={theme.palette.text.coolGray}
                        />
                    </div>
                </div>

                <div className="w-0 h-0 overflow-hidden">
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        type="date"
                        inputRef={endDateRef}
                        value={filterData?.endDate}
                        fullWidth
                        onChange={handleEndDateChange}
                    />
                </div>
            </div>
        </>
    );
}

export default DatePickerComponent;
