import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WolfLogo from '../../assets/wolf-logo 1.png'
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import LoginPageImg from '../../assets/Rectangle 447.png'
import CloseIcon from '@mui/icons-material/Close';
import CallIcon from '@mui/icons-material/Call';
import { otpRegex } from '../../ValidationRegex';
import LoadingButton from '@mui/lab/LoadingButton';
import { ApiService, http } from '../../Services/api.services';
import { useDispatch } from 'react-redux';
import { showToast, } from '../../store/Actions/Action'
import { Button } from '@mui/material';

const customPlaceholderStyle = {
    color: 'red', // Change the color to your desired color
    fontFamily: 'Arial, sans-serif', // Change the font family to your desired font
};

const api = new ApiService()

export default function EnterOtp(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [otp, setOtp] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [seconds, setSeconds] = React.useState(30);

    const handleClickOpen = () => {
        setOpen(true);
    };

    React.useEffect(() => {
        if (seconds === 0) {
            return;
        }

        const interval = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [seconds]);

    const handleClose = () => {
        setOpen(false);
    };

    const sendOtp = async () => {
        try {
            const response = await http.post('/user/send-otp', { email: props?.email, type: 'forgot password' })
            console.log(response);
            if (response?.status == 200) {
                setSeconds(30)
                const _snackbar = {
                    message: 'OTP sent succesfully',
                    open: true,
                    duration: 3000,
                    type: 'success'
                };
                dispatch(showToast(_snackbar))
            } else {
                const _snackbar = {
                    message: 'Error',
                    open: true,
                    duration: 3000,
                    type: 'error'
                };
                dispatch(showToast(_snackbar))
            }

        } catch (error) {
            console.log(error);
        }
    }

    const handleVerifyOtp = async () => {
        try {
            if (otpRegex.test(otp)) {
                const data = {
                    email: props.email,
                    otp: otp
                }
                setIsLoading(true)
                const response = await http.post(`/user/verify-otp`, data)
                if (response?.status == 200) {
                    setIsLoading(false)
                    props?.set_token(response?.data?.data?.token)
                    const _snackbar = {
                        message: response?.data?.message,
                        open: true,
                        duration: 3000,
                        type: 'success'
                    };
                    dispatch(showToast(_snackbar));
                    props?.onResetPass();
                } else {
                    const _snackbar = {
                        message: response?.data?.message,
                        open: true,
                        duration: 3000,
                        type: 'error'
                    };
                    dispatch(showToast(_snackbar));
                    setIsLoading(false)
                }
            }
            else {
                const _snackbar = {
                    message: 'Invalid OTP',
                    open: true,
                    duration: 3000,
                    type: 'error'
                };
                dispatch(showToast(_snackbar));
            }

        } catch (e) {
            const response = e?.response;
            console.log(`ERROR WHILE VERYFING OTP --- ${e}`)
            setIsLoading(false)
            const _snackbar = {
                message: response?.data?.message,
                open: true,
                duration: 3000,
                type: 'error'
            };
            dispatch(showToast(_snackbar));
        }
    }


    return (

        <div>
            <div className='w-full !bg-rgba(248, 248, 248, 0.99)'>
                <Dialog
                    fullScreen={fullScreen}
                    open={props.open}
                    onClose={() => props.onClose()}
                    aria-labelledby="responsive-dialog-title"
                >
                    <div style={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }} className='w-full min-h-[50vh] !lg:min-h-[52vh] flex'>
                        <div className='hidden lg:flex w-full bg-[#000000]'>
                            <img className='w-full h-full object-contain' src={LoginPageImg} alt="LoginPageImg" />
                        </div>
                        <div className='w-full'>
                            <DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }}>
                                <div className='flex flex-col items-center justify-center gap-3 py-6 lg:py-1'>
                                    <img src={WolfLogo} alt="WolfLogo" />
                                    <h6 className='text-[#373737] font-normal tracking-wider text-[2.2vmax] lg:text-[1.5vmax]'>Join <span className='font-semibold'>Fastr</span></h6>
                                    <CloseIcon className='!hidden lg:!block' onClick={() => {
                                        props.onClose();
                                    }} sx={{ position: "absolute", cursor: 'pointer', top: "4%", right: "1%", transform: 'translate(-50%,-50%)' }} />
                                </div>
                            </DialogTitle>

                            <DialogContent sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)' }} >
                                <div className='input_area flex items-center justify-center w-full h-[55vh] lg:h-[50vh] lg:w-[35vw] lg:overflow-y-auto lg:px-4'>
                                    <div className='w-full flex flex-col items-start justify-center gap-5 '>
                                        <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>

                                            <div className='flex items-center justify-between w-full'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Enter OTP</h6>
                                                {seconds === 0 ?
                                                    <Button onClick={() => sendOtp()} variant='text' className='!capitalize'>
                                                        <h6 className='text-[#5367FF] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Resend OTP</h6>
                                                    </Button> :
                                                    <Button variant='text' className='!capitalize'>
                                                        <h6 className='text-[#5367FF] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>{seconds < 10 ? `00:0${seconds}` : `00:${seconds}`}</h6>
                                                    </Button>
                                                }

                                            </div>
                                            <TextField
                                                id="input-with-icon-textfield"
                                                placeholder='Enter OTP'
                                                helperText={otp?.length > 0 && !otpRegex?.test(otp) ? "Please Enter Valid otp " : ""}
                                                error={otp?.length > 0 && !otpRegex?.test(otp)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <CallIcon sx={{ color: 'rgba(55, 55, 55, 0.20)' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                type='number'
                                                fullWidth
                                                value={otp}
                                                onKeyUp={(event) => {
                                                    if (event.key === 'Enter') {
                                                        handleVerifyOtp()
                                                    }
                                                }}
                                                onChange={(e) => setOtp(e?.target?.value)}
                                                size='small'
                                                className='custom-placeholder bg-white  border !border-[F4F4F4] !shadow-none '
                                                sx={{
                                                    color: 'rgba(55, 55, 55, 0.20)',
                                                    '& .MuiInputBase-root': {
                                                        [theme.breakpoints.down('sm')]: {
                                                            fontSize: '2vmax', // Font size for large screens
                                                        },
                                                        [theme.breakpoints.up('lg')]: {
                                                            fontSize: '.8vmax', // Font size for large screens
                                                        },
                                                    },
                                                }}
                                            />

                                        </div>
                                        <div className='flex flex-col items-center justify-center w-full   lg:justify-start lg:gap-3'>
                                            <LoadingButton
                                                loading={isLoading}
                                                sx={{ padding: '.6vmax 0', boxShadow: 'none' }}
                                                onClick={handleVerifyOtp}
                                                fullWidth
                                                disabled={
                                                    !(otpRegex.test(otp))
                                                }
                                                variant="contained"
                                                size="small"
                                            >
                                                <h6 className='text-[#FFFFFF] font-normal text-[2vmax] tracking-wider lg:text-[.8vmax]'>Submit</h6>
                                            </LoadingButton>
                                            <div className='flex items-center justify-center w-full py-3 lg:py-1 rounded-sm lg:w-fit' >
                                                <h6 className='text-[#828282] font-normal text-[1.7vmax] tracking-wide lg:text-[.8vmax]'>Enter 6-digit OTP sent to your email {props?.email}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                        </div>
                    </div>

                </Dialog>
            </div>
        </div>
    );
}
