import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    learnwithus: 0,
    earnwithus: 0,
    showlogin: 0
}


const UpdateLearn = (state = initialState, action) => {
    return { ...state, learnwithus: state.learnwithus + action.payload.payload }
}

const UpdateEarn = (state = initialState, action) => {
    return { ...state, earnwithus: state.earnwithus + action.payload.payload }
}
const ShowLogin = (state = initialState, action) => {
    return { ...state, showlogin: action.payload.payload ? (state.showlogin + action.payload.payload) : 0 }
}

export const Reducer = createSlice({
    name: 'events',
    initialState,
    reducers: {
        updateLearn: UpdateLearn,
        updateEarn: UpdateEarn,
        showLogin: ShowLogin
    },
})

export const { updateEarn, updateLearn, showLogin } = Reducer.actions

export default Reducer.reducer  