import { LoadingButton } from "@mui/lab";
import { Button, Skeleton, styled } from "@mui/material";
import * as React from "react";

const SecondaryButton = styled(LoadingButton)(({ theme }) => ({
  color: "#262626",
  backgroundColor: "#F0F0F0",
  border: "1px solid #d9d9d9",
  "&:hover": {
    backgroundColor: "#f9f9f9",
  },
  minWidth: "24px",
  // width: '48px',
  height: "28px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 12,
  fontWeight: "bold",
  borderRadius: 5,
  padding: "8px 16px",
  textTransform: "capitalize",
  // boxShadow: 'none'
}));

const OrderButton = styled(LoadingButton)(({ theme }) => ({
  color: "white",
  // backgroundColor: '#ff0000',
  // '&:hover': {
  //     backgroundColor: '#D74040',
  // },
  minWidth: "24px",
  width: "48px",
  height: "24px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 10,
  // fontWeight: "bold",
  borderRadius: 2,
  padding: "7px 14px",
  boxShadow: "none",
}));

const OrderButtonMobile = styled(Button)(({ theme }) => ({
  color: "white",
  // backgroundColor: '#ff0000',
  // '&:hover': {
  //     backgroundColor: '#D74040',
  // },
  minWidth: "24px",
  width: "32px",
  height: "24px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 10,
  fontWeight: "bold",
  borderRadius: 2,
  padding: "7px 14px",
  boxShadow: "none",
}));

function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

const rankBg = (rank) => {
  return rank === 1
    ? "!bg-[#F0AD00] px-1"
    : rank === 3
      ? "!bg-[#B08D57] px-1"
      : rank === 2
        ? "!bg-[#C0C0C0] px-1"
        : "";
};

const rankKey = (rank) => {
  return rank
    ? "font-normal text-white text-[12px]"
    : "font-normal text-slate-600 text-[12px]";
};

const rankValue = (rank, noColor, hasColor) => {
  return rank
    ? "font-bold !text-white text-[13.5px]"
    : `font-medium text-[13.5px] ${noColor ? "" : hasColor ? hasColor : "text-slate-800"
    }`;
};

const KeyValue = ({ title, value, lighter, max }) => {
  return (
    <div className="flex flex-1 justify-between items-center w-full">
      <p className="text-[#757575] font-normal mb-1 text-[11.5px] ">
        {title}
      </p>
      {!value || value == "NaN" ? (
        <Skeleton height={16} width={48} />
      ) : lighter ? (
        <p
          className={`text-slate-400 font-medium text-[13px] ${max ? "bg-yellow-200 font-semibold px-1" : ""
            }`}
        >
          {value}
        </p>
      ) : (
        <p
          className={`text-slate-800 font-medium text-[13px] ${max ? "bg-yellow-200 font-semibold px-1" : ""
            }`}
        >
          {value}
        </p>
      )}
    </div>
  );
};

export const CallCell = ({
  showBasket,
  row,
  data,
  onInstantBuy,
  onInstantSell,
  admin,
  onBuy,
  onSell,
  loading,
  prevLTP,
  changePercent,
}) => {
  return (
    <>
      <div className="w-fil flex flex-col items-center gap-2">
        <div
          onClick={() => {
            if (admin) {
              onBuy();
            }
          }}
          className="w-12"
        >
          {row.call?.last_price ? (
            <h5
              className={`text-[#505050] font-semibold text-[13px] transform transition-transform duration-200 ease-in-out ${admin
                ? "hover:scale-110 hover:font-extrabold  cursor-pointer"
                : ""
                }`}
            >
              {row.call?.last_price?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </h5>
          ) : row?.call?.last_price == 0 ? (
            "0.00"
          ) : (
            <Skeleton height={20} width={90} />
          )}
        </div>
        <div className="">
          {prevLTP ? (
            row.call?.last_price > prevLTP ? (
              <h5
                className={`text-green-600 text-center whitespace-nowrap font-bold text-[12px]`}
              >
                {Math.abs(
                  (row.call?.last_price - prevLTP).toFixed(2)
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                &nbsp; (
                {Math.abs(changePercent.toFixed(2)).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                %)
              </h5>
            ) : (
              <h5
                className={`text-red-500 whitespace-nowrap font-bold text-[12px]`}
              >
                {Math.abs(
                  (prevLTP - row.call?.last_price).toFixed(2)
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                &nbsp; (
                {Math.abs(changePercent.toFixed(2)).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                %)
              </h5>
            )
          ) : (
            <h5 className={`text-gray-400 font-bold text-[12px]`}>
              0.00 (0.00%)
            </h5>
          )}
        </div>
        {/* {showBasket ? (
                    <SecondaryButton
                        endIcon={
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.7631 7.73069L13.9548 6.66129C14.0798 5.96166 14.1424 5.61249 13.9417 5.38315C13.7418 5.15381 13.3735 5.15381 12.6377 5.15381H2.44999C1.71415 5.15381 1.34589 5.15381 1.14595 5.38315C0.945333 5.61249 1.00854 5.96166 1.1329 6.66129L1.96012 11.2868C2.23425 12.82 2.37098 13.586 2.93093 14.0408C3.4902 14.495 4.2968 14.495 5.91002 14.495H7.54384M8.91796 11.9181H14.4144M11.6662 14.495V9.34124M11.3227 5.15381C11.3227 4.21409 10.9245 3.31286 10.2159 2.64838C9.50721 1.9839 8.54605 1.6106 7.54384 1.6106C6.54164 1.6106 5.58048 1.9839 4.87181 2.64838C4.16315 3.31286 3.76502 4.21409 3.76502 5.15381"
                                    stroke="#373737"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        }
                    >
                        Add to Basket
                    </SecondaryButton> */}
        {/* ) : ( */}
        <div className="flex items-center gap-3">
          <OrderButton
            variant="contained"
            className="buy-button"
            loading={loading}
            onClick={() => {
              if (admin) {
                onInstantBuy();
              } else {
                onBuy();
              }
            }}
            color="buy"
          >
            Buy
          </OrderButton>
          <OrderButton
            variant="contained"
            className="sell-button"
            loading={loading}
            onClick={() => {
              if (admin) {
                onInstantSell();
              } else {
                onSell();
              }
            }}
            color="sell"
          >
            Sell
          </OrderButton>
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export const PutCell = ({
  showBasket,
  row,
  data,
  onInstantSell,
  admin,
  onInstantBuy,
  onBuy,
  onSell,
  loading,
  prevLTP,
  changePercent,
}) => {
  return (
    <>
      <div>
        <div className="flex items-center flex-col gap-2">
          <div
            onClick={() => {
              if (admin) {
                onBuy();
              }
            }}
            className="w-12"
          >
            {row.put?.last_price ? (
              <h5
                className={`text-[#505050] font-semibold text-[13px] transform transition-transform duration-200 ease-in-out ${admin
                  ? "hover:scale-110 hover:font-extrabold  cursor-pointer"
                  : ""
                  }`}
              >
                {row.put?.last_price?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </h5>
            ) : row?.put?.last_price == 0 ? (
              "0.00"
            ) : (
              <Skeleton height={20} width={90} />
            )}
          </div>
          <div className="">
            {prevLTP ? (
              row.put?.last_price > prevLTP ? (
                <h5
                  className={`text-green-600 text-center whitespace-nowrap font-bold text-[12px]`}
                >
                  {Math.abs(
                    (row.put?.last_price - prevLTP).toFixed(2)
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp; (
                  {Math.abs(changePercent.toFixed(2)).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                  %)
                </h5>
              ) : (
                <h5
                  className={`text-red-500 font-bold whitespace-nowrap text-[12px]`}
                >
                  {Math.abs(
                    (prevLTP - row.put?.last_price).toFixed(2)
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  &nbsp; (
                  {Math.abs(changePercent.toFixed(2)).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                  %)
                </h5>
              )
            ) : (
              <h5 className={`text-gray-400 font-bold text-[12px]`}>
                0.00 (0.00%)
              </h5>
            )}
          </div>
          {/* {showBasket ? (
            <SecondaryButton
              endIcon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.7631 7.73069L13.9548 6.66129C14.0798 5.96166 14.1424 5.61249 13.9417 5.38315C13.7418 5.15381 13.3735 5.15381 12.6377 5.15381H2.44999C1.71415 5.15381 1.34589 5.15381 1.14595 5.38315C0.945333 5.61249 1.00854 5.96166 1.1329 6.66129L1.96012 11.2868C2.23425 12.82 2.37098 13.586 2.93093 14.0408C3.4902 14.495 4.2968 14.495 5.91002 14.495H7.54384M8.91796 11.9181H14.4144M11.6662 14.495V9.34124M11.3227 5.15381C11.3227 4.21409 10.9245 3.31286 10.2159 2.64838C9.50721 1.9839 8.54605 1.6106 7.54384 1.6106C6.54164 1.6106 5.58048 1.9839 4.87181 2.64838C4.16315 3.31286 3.76502 4.21409 3.76502 5.15381"
                    stroke="#373737"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
            >
              Add to Basket
            </SecondaryButton>
          ) : ( */}
          <div className="flex items-center  gap-3">
            <OrderButton
              className="buy-button"
              variant="contained"
              loading={loading}
              onClick={() => {
                if (admin) {
                  onInstantBuy();
                } else {
                  onBuy();
                }
              }}
              color="buy"
            >
              Buy
            </OrderButton>
            <OrderButton
              className="sell-button"
              variant="contained"
              loading={loading}
              onClick={() => {
                if (admin) {
                  onInstantSell();
                } else {
                  onSell();
                }
              }}
              color="sell"
            >
              Sell
            </OrderButton>
            {/* <Button onClick={() => onBuy()} variant="contained" size="small" color="buy" sx={{ color: "#FFFFFF" }} className="!!text-[10px] !min-w-[30px] !px-3 !shadow-none !rounded-[2px]">BUY</Button>
                        <Button onClick={() => onSell()} variant="contained" size="small" color="sell" sx={{ color: "#FFFFFF" }} className="!!text-[10px] !min-w-[30px] !px-3 !shadow-none !rounded-[2px] ">Sell</Button> */}
          </div>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

// export const Volume = ({ volume, percent }) => {
//     // const MaxOi = data.callMaxOi;

//     return (
//         <>
//             <div className="flex flex-col items-start justify-between gap-2">
//                 {/* <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">{((row.call.oi) / 1000).toLocaleString({ minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h6> */}
//                 <KeyValue
//                     title="V"
//                     value={volume}
//                 />
//                 {/* <KeyValue
//                     title="V 1/5m"
//                     value={'--'}
//                 /> */}
//                 <KeyValue
//                     title="V%"
//                     value={percent || '--'}
//                     lighter={true}
//                     max={parseInt(percent) >= 100}
//                 />
//             </div>
//         </>
//     )
// }

export const Volume = ({
  volume,
  ratio,
  inMinute,
  inMinuteRanking,
  percentile,
  admin,
  volumeRank,
  iv,
}) => (
  <div className="flex flex-col">
    <div className="flex-1 mb-2">
      <div className="flex justify-between items-center">
        <div className="flex justify-start">
          <p className="font-normal text-slate-600 text-[11px]">V</p>
        </div>
        <p className="font-medium text-[12px] text-slate-800">{volume || 0}</p>
      </div>
    </div>
    {/* <div className="flex-1 mb-2">
            <div className="flex justify-between items-center">
                <div className="flex justify-start">
                    <p className="font-normal text-slate-600 text-[12px]">Vol PCR</p>
                </div>
                <p className="font-medium text-[12px] text-slate-800">{ratio || '--'}</p>
            </div>
        </div> */}
    {admin ? (
      <div className="flex-1 mb-2">
        <div
          className={
            "flex justify-between items-center " + rankBg(inMinuteRanking)
          }
        >
          <div className="flex justify-start">
            <p className={rankKey(inMinuteRanking)}>V 1/5m</p>
          </div>
          <p className={rankValue(inMinuteRanking)}>
            {inMinute ? inMinute.toFixed(2) : "--"}
          </p>
        </div>
      </div>
    ) : null}
    <div className="flex-1 mb-2">
      <div
        className={"flex justify-between items-center " + rankBg(volumeRank)}
      >
        <div className="flex justify-start">
          <p className={rankKey(volumeRank)}>V %</p>
        </div>
        {admin ? (
          <p className={rankValue(volumeRank)}>{percentile || 0}</p>
        ) : (
          <p
            className={`font-medium text-[12px] ${percentile == "100.00%" ? "bg-[#F0AD00]" : ""
              } px-1 text-slate-800`}
          >
            {percentile || "0%"}
          </p>
        )}
      </div>
    </div>
    {/* {admin ?
            <div className="flex-1 mb-2">
                <div className="flex justify-between items-center">
                    <div className="flex justify-start">
                        <p className="font-normal text-slate-600 text-[11px]">IV</p>
                    </div>
                    <p className="font-medium text-[12px] text-slate-800">{parseFloat(iv)?.toLocaleString({ maximumFractionDigits: 2 })}</p>
                </div>
            </div>
            : null} */}
  </div>
);

// export const OrderCell = ({ indexLTP, tbs, smb }) => {
//     // const MaxOi = data.callMaxOi;

//     return (
//         <>
//             <div className="flex flex-col items-start justify-between gap-2">
//                 {/* <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">{((row.call.oi) / 1000).toLocaleString({ minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h6> */}
//                 <KeyValue
//                     title="Index/LTP"
//                     value={indexLTP || '--'}
//                 />
//                 <KeyValue
//                     title="TBO/TSO"
//                     value={tbs || '--'}
//                 />
//                 <KeyValue
//                     title="BS - BB"
//                     value={smb || '--'}
//                 />
//             </div>
//         </>
//     )
// }

// export const OICell = ({ oi, percent }) => {
//     // const MaxVolume = data.callMaxVolume;
//     return (
//         <>
//             <div className="flex flex-col items-start justify-between gap-2">
//                 {/* <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">{((row.call.oi) / 1000).toLocaleString({ minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h6> */}
//                 <KeyValue
//                     title={'OI'}
//                     value={oi || '--'}
//                 />
//                 <KeyValue
//                     title={'OI %'}
//                     value={percent}
//                     lighter={true}
//                     max={parseInt(percent) >= 100}
//                 />
//             </div>
//         </>
//     )
// }

export const OrderCell = ({
  indexLTP,
  tbs,
  smb,
  indexLTPRank,
  tbsRank,
  smbRank,
}) => (
  <div className="flex flex-col">
    <div className="flex-1 mb-2">
      <div
        className={
          "flex justify-between items-center gap-1 " + rankBg(indexLTPRank)
        }
      >
        <div className={"flex justify-start"}>
          <p className={rankKey(indexLTPRank)}>Index/LTP</p>
        </div>
        <p className={rankValue(indexLTPRank)}>{kFormatter(indexLTP || 0)}</p>
      </div>
    </div>
    <div className="flex-1 mb-2">
      <div
        className={"flex justify-between items-center gap-1 " + rankBg(tbsRank)}
      >
        <div className="flex justify-start">
          <p className={rankKey(tbsRank)}>TBO/TSO</p>
        </div>
        <p className={rankValue(tbsRank)}>{tbs || "--"}</p>
      </div>
    </div>
    <div className="flex-1 mb-2">
      <div
        className={"flex justify-between items-center gap-1 " + rankBg(smbRank)}
      >
        <div className="flex justify-start">
          <p className={rankKey(smbRank)}>BS-BB</p>
        </div>
        <p className={rankValue(smbRank)}>{smb || "--"}</p>
      </div>
    </div>
  </div>
);

export const OICell = ({ admin, oi, candle, pcr, percentile, oiRank }) => (
  <div className="flex flex-col max-w-full">
    <div className="flex-1 mb-2">
      <div className={"flex justify-between items-center  " + rankBg(oiRank)}>
        <div className="flex justify-start">
          <p className={rankKey(oiRank)}>OI</p>
        </div>
        <p className={rankValue(oiRank)}>
          {oi ? (
            oi
          ) : oi == 0 ? (
            "0.00"
          ) : (
            <Skeleton variant="text" width={28} height={20} />
          )}
        </p>
      </div>
    </div>
    {/* <div className="flex-1 mb-2">
            <div className="flex justify-between items-center">
                <div className="flex justify-start">
                    <p className="font-normal text-slate-600 text-[12px]">OI/PCR</p>
                </div>
                <p className="font-medium text-[12px] text-slate-800">{pcr}</p>
            </div>
        </div> */}
    <div className="flex-1 mb-2">
      <div className="flex justify-between items-center">
        <div className="flex justify-start">
          <p className="font-normal text-slate-600 text-[12px]">OI %</p>
        </div>
        <p
          className={`font-medium text-[12px] ${!admin && percentile == "100.00%" ? "bg-[#F0AD00]" : ""
            } px-1 text-slate-800`}
        >
          {percentile || "0%"}
        </p>
      </div>
    </div>
    {admin ? (
      <div className="flex-1 mb-2">
        <div className="flex justify-between items-center">
          <div className="flex justify-center flex-1 overflow-hidden">
            {candle}
          </div>
        </div>
      </div>
    ) : null}
  </div>
);

export const PutVolume = ({ row, data }) => {
  // const MaxPutVolume = data.putMaxVolume;
  return (
    <>
      <div className="flex flex-col items-start justify-between gap-2">
        {/* <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">{((row.call.oi) / 1000).toLocaleString({ minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h6> */}
        <div className="flex items-center justify-between w-full ">
          <h6 className="text-[#505050] font-medium m-0 text-[.8vmax] ">
            V
          </h6>
          <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">
            0.00
          </h6>
        </div>
        <div className="flex items-center justify-between w-full ">
          <h6 className="text-[#505050] font-medium m-0 text-[.8vmax] ">
            V1/5m
          </h6>
          <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">
            -
          </h6>
        </div>
        <div className="flex items-center justify-between w-full ">
          <h6 className="text-[#505050] font-medium m-0 text-[.8vmax] ">
            V%
          </h6>
          <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">
            NaN%
          </h6>
        </div>
      </div>
    </>
  );
};

export const PutOICell = ({ row, data }) => {
  // const MaxPutOi = data.putMaxOi;
  return (
    <>
      <div className="flex flex-col items-start justify-between gap-2">
        {/* <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">{((row.call.oi) / 1000).toLocaleString({ minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h6> */}
        <div className="flex items-center justify-between w-full ">
          <h6 className="text-[#505050] font-medium m-0 text-[.8vmax] ">
            OI
          </h6>
          <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">
            44.80
          </h6>
        </div>
        <div className="flex items-center justify-between w-full ">
          <h6 className="text-[#505050] font-medium m-0 text-[.8vmax] ">
            OI
          </h6>
          <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">
            0%
          </h6>
        </div>
      </div>
    </>
  );
};

export const PutOrderCell = ({ row, data }) => {
  // const MaxPutOi = data.putMaxOi;
  return (
    <>
      <div className="flex flex-col items-start justify-between gap-2">
        {/* <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">{((row.call.oi) / 1000).toLocaleString({ minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h6> */}
        <div className="flex items-center justify-between w-full ">
          <h6 className="text-[#505050] font-medium m-0 text-[.8vmax] ">
            Index/LTP
          </h6>
          <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">
            0.00
          </h6>
        </div>
        <div className="flex items-center justify-between w-full ">
          <h6 className="text-[#505050] font-medium m-0 text-[.8vmax] ">
            TBO/TSO
          </h6>
          <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">
            33.94
          </h6>
        </div>
        <div className="flex items-center justify-between w-full ">
          <h6 className="text-[#505050] font-medium m-0 text-[.8vmax] ">
            BS-BB
          </h6>
          <h6 className="text-[#505050] font-semibold m-0 text-[.8vmax] ">
            0
          </h6>
        </div>
      </div>
    </>
  );
};

export const StrikeCell = ({
  strikePrice,
  admin,
  cpr,
  pcr,
  onStrike,
  min,
  max,
  strike,
  volumePCR,
  volumeCPR,
  theta,
  vega,
  cprRank,
  pcrRank,
  volumeCPRRank,
  volumePCRRank,
}) => {
  // console.log(cprRank, pcrRank)
  return (
    <>
      <div className="w-full  flex flex-col items-center gap-4">
        <div className="flex justify-center items-center">
          <h6
            className={
              min == strike || max == strike
                ? "font-bold text-[#5367FF] m-0 text-lg  transform transition-transform duration-200 ease-in-out hover:scale-110 hover:font-extrabold"
                : "text-[#5367FF] m-0 text-lg  transform transition-transform duration-200 ease-in-out hover:scale-110 hover:font-bold"
            }
          >
            {strikePrice}
          </h6>
        </div>
        <div
          className={`flex w-full  items-center justify-${admin ? "between" : "center"
            } gap-4`}
        >
          {admin ? (
            <div className="flex flex-col items-start ">
              <p className="flex justify-between  gap-1 font-normal text-[#bdbdbd] m-0 text-[11px] text-center lg:text-[12px]">
                T &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <span className="text-xs text-black">{theta}</span>
              </p>
              <p className="flex  gap-1 font-normal text-[#bdbdbd] m-0 text-[11px] text-center lg:text-[12px]">
                Vega &nbsp;
                <span className="text-xs text-black">{vega}</span>
              </p>
            </div>
          ) : null}
          {/* <div className={`flex flex-col items-${admin ? 'start' : 'center'}`}> */}
          {/* {cprRank ? console.log('cpr -- ', rankBg(cprRank), cpr, cprRank) : null}
                    {pcrRank ? console.log('pcr -- ', rankBg(pcrRank), pcr, pcrRank) : null} */}
          <div className={`flex flex-col items-start  `}>
            {cpr > 1 ? (
              <p
                className={
                  "font-normal text-[#bdbdbd] m-0 text-[11px]  text-center lg:text-[12px] " +
                  rankBg(cprRank) +
                  " " +
                  rankKey(cprRank)
                }
              >
                {"CPR"}
                &nbsp; &nbsp; &nbsp;
                <span
                  className={
                    "text-xs " + rankValue(cprRank, false, "text-[#00B386]")
                  }
                >
                  {cpr?.toFixed(2) || "--"}
                </span>
              </p>
            ) : (
              <p
                className={
                  "font-normal text-[#bdbdbd] m-0 text-[11px]  text-center lg:text-[12px] " +
                  rankBg(pcrRank) +
                  " " +
                  rankKey(pcrRank)
                }
              >
                {"PCR"}
                &nbsp; &nbsp; &nbsp;
                <span
                  className={
                    "text-xs " + rankValue(pcrRank, false, "text-[#EB5B3C]")
                  }
                >
                  {pcr?.toFixed(2) || "--"}
                </span>
              </p>
            )}

            {admin ? (
              volumeCPR > 1 ? (
                <p
                  className={
                    "flex  gap-1 font-normal text-[#bdbdbd] m-0 text-[11px] text-center lg:text-[12px] " +
                    rankBg(volumeCPRRank) +
                    " " +
                    rankKey(volumeCPRRank)
                  }
                >
                  V.CPR &nbsp;
                  {volumeCPR ? (
                    <span
                      className={
                        "text-xs " +
                        rankValue(volumeCPRRank, false, "text-[#EB5B3C]")
                      }
                    >
                      {volumeCPR.toFixed(2)}
                    </span>
                  ) : (
                    "--"
                  )}
                </p>
              ) : (
                <p
                  className={
                    "flex  gap-1 font-normal text-[#bdbdbd] m-0 text-[11px] text-center lg:text-[12px] " +
                    rankBg(volumePCRRank) +
                    " " +
                    rankKey(volumePCRRank)
                  }
                >
                  V.PCR &nbsp;
                  {volumePCR ? (
                    <span
                      className={
                        "text-xs " +
                        rankValue(volumePCRRank, false, "text-[#00B386]")
                      }
                    >
                      {volumePCR.toFixed(2)}
                    </span>
                  ) : (
                    "--"
                  )}
                </p>
              )
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export const MobileCell = ({ row, volume, percent, onBuy, onSell }) => {
  return (
    <>
      <div>
        <p className="text-[#505050] font-bold font=[Poppins] text-[1.5vma] mb-1">
          {row.call?.last_price?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}
        </p>
        <div className="flex justify-center items-center">
          <span className="text-[#666666] font-normal text-[10px] mr-2">
            Vol
          </span>
          <p className="text-[#505050] font-semibold font=[Poppins] text-[11px]">
            {volume}
          </p>
        </div>
        <p className="text-[#9F0BD3] text-[10px]">({percent})</p>
        <div className="flex items-center justify-between mt-2">
          <OrderButtonMobile
            variant="contained"
            onClick={() => onBuy()}
            color="buy"
          >
            BUY
          </OrderButtonMobile>
          <OrderButtonMobile
            variant="contained"
            onClick={() => onSell()}
            color="sell"
          >
            SELL
          </OrderButtonMobile>
        </div>
      </div>
    </>
  );
};

export const MobilePutCell = ({ row, volume, percent, onBuy, onSell }) => {
  return (
    <>
      <div>
        <p className="text-[#505050] font-bold font=[Poppins] text-[1.5vma] mb-1">
          {row.put?.last_price?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}
        </p>
        <div className="flex justify-center items-center">
          <span className="text-[#666666] font-normal text-[10px] mr-2">
            Vol
          </span>
          <p className="text-[#505050] font-semibold font=[Poppins] text-[11px]">
            {volume}
          </p>
        </div>
        <p className="text-[#9F0BD3] text-[10px]">({percent})</p>
        <div className="flex items-center justify-between mt-2">
          <OrderButtonMobile
            variant="contained"
            onClick={() => onBuy()}
            color="buy"
          >
            BUY
          </OrderButtonMobile>
          <OrderButtonMobile
            variant="contained"
            onClick={() => onSell()}
            color="sell"
          >
            SELL
          </OrderButtonMobile>
        </div>
      </div>
    </>
  );
};
