import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useThemeContext } from "../Theme/ThemeContext";

const EquityOrderTable = ({
    rows,
    columns,
    isPagination,
    totalRows,
    skip,
    setSkip,
    setLimit,
    limit,
    onRowClickFunction
}) => {
    const { theme } = useThemeContext();
    // Conditional pagination props
    const paginationProps = isPagination
        ? {
            paginationMode: "server",
            paginationModel: {
                pageSize: limit || 20,
                page: skip / (limit || 1) || 0, // prevent division by zero
            },
            onPaginationModelChange: (newPaginationModel) => {
                setSkip(newPaginationModel.page * newPaginationModel.pageSize);
                setLimit(newPaginationModel.pageSize);
            },
            pageSizeOptions: [10, 15, 20, 25, 30, 50, 100],
        }
        : {};
    return (
        <>
            <DataGrid
                rows={rows || []}
                loading={!rows}
                columns={columns}
                rowCount={totalRows}
                hideFooter={!isPagination} // Hide the footer if pagination is not enabled
                disableRowSelectionOnClick
                sx={{
                    // border: "none",
                    backgroundColor: 'transparent !important',
                    "& .css-1iyq7zh-MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.whisperGray,
                        // border: "none !important",
                        color: theme.palette.text.coolGray
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none !important'
                    },
                    '& .css-lm0971-MuiDataGrid-root': {
                        borderColor: 'transparent !important',
                    },
                    overflowY: 'auto',
                    flex: 1
                }}
                pageSizeOptions={[5]}
                {...(isPagination && paginationProps)} // Conditionally spread pagination props
            />
        </>
    );
};

export default EquityOrderTable;
