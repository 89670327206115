import { Skeleton, TextField } from "@mui/material"
import InputAdornment from '@mui/material/InputAdornment';
import NewsArticle from "../PublicComponents/NewsArticle"
import { SearchOutlined } from "@mui/icons-material"
import React from "react";
import { http } from "../Services/api.services";
import { useLocation, useNavigate } from "react-router-dom";
import ShareDialog from "../PublicComponents/ShareDialog";
import { panel } from "../default.config";
import HomepageHeaders from "../PublicComponents/HomepageHeaders";


const SearchStyle = {
    mx: 3,
    my: 2,
    background: '#eee',
}

const News = () => {

    const [news, setNews] = React.useState(null)
    const [showShare, setShowShare] = React.useState(false)
    const [currentNews, setCurrentNews] = React.useState({ title: '', link: '' })
    const navigate = useNavigate()

    const [route, setRoute] = React.useState()

    const { pathname } = useLocation()
    console.log(pathname)
    React.useEffect(() => {
        fetchNews()
        document.title = 'Today\'s Trend | Fastr'
        if (pathname.includes('/home/')) setRoute('/home')
        else setRoute('')
        return () => {
            document.title = 'Fastr'
        }
    }, [])

    const fetchNews = async () => {
        try {
            const response = await http.get('news/published/user')
            if (response.status == 200) {
                setNews(response.data)
            }
            else setNews([])
        }
        catch (e) {
            setNews([])
            console.log('ERROR WHILE FETCHING NEWS -- ', e)
        }
    }

    const onShare = (_news) => {
        setCurrentNews({
            title: _news?.title,
            link: `${panel}news/${_news.id}/${_news.title}`
        })
        setShowShare(true)
    }

    const onShareClose = () => {
        setCurrentNews({
            title: '',
            link: ''
        })
        setShowShare(false)
    }

    return (
        <div className="w-full h-full py-4">
            <div className='py-6 lg:py-10'>
                <HomepageHeaders heading="Today's Trend" desc='Stay updated & informed about market status' />
            </div>
            <div className="mx-4 lg:mx-52 2xl:mx-96 xl:mx-80 flex flex-col gap-8 min-h-[720px]">
                {/* <div className="flex justify-center items-center w-full">
                    <TextField
                        fullWidth
                        id="search-input"
                        size="small"
                        sx={SearchStyle}
                        InputProps={{
                            placeholder: 'Search here...',
                            startAdornment: <InputAdornment position="start">
                                <SearchOutlined />
                            </InputAdornment>,
                        }}
                    />
                </div> */}
                {news ? news.length ?
                    news?.map((_news, index) => (
                        <NewsArticle
                            key={index}
                            title={_news.title}
                            content={_news.snippet}
                            cover={_news.cover}
                            createdAt={_news.createdat}
                            category={_news.category}
                            click={() => navigate(`${route}/news/${_news.id}/${_news.title}`)}
                            onShareNews={() => onShare(_news)}
                        />
                    )) : null :
                    <div className="flex flex-col gap-10">
                        {[1, 2, 3].map((_data) => (
                            <NewsLoader key={_data} />
                        ))
                        }
                    </div>
                }
                <ShareDialog
                    show={showShare}
                    onHide={() => onShareClose()}
                    link={currentNews?.link}
                    title={currentNews?.title}
                />
            </div>
        </div>
    )
}

export default News

const NewsLoader = () => (
    <div className="w-full flex">
        <div className="flex-1 px-4">
            <Skeleton variant="text" height={40} width={'80%'} />
            <Skeleton variant="text" height={20} width={'90%'} />
            <Skeleton variant="text" height={20} width={'60%'} />
            <br></br>
            <div className="flex flex-wrap gap-4 items-center">
                <Skeleton variant="text" height={25} width={60} />
            </div>
        </div>
        <div className="w-40">
            <Skeleton variant="rounded" height={'100%'} />
        </div>
    </div>
)