import React from "react";
import IndexTableHeadCell from "./IndexTableHeadCell";
import { IconButton } from "@mui/material";
import { RefreshOutlined } from "@mui/icons-material";

const TopVolume = ({
  call,
  callVolume,
  put,
  putVolume,
  onRefresh,
}) => {

  return (
    <>
      <div className="w-full h-full relative overflow-auto flex flex-col">
        <div className="flex w-full  bg-[#F3F3F3] p-2 items-center justify-between gap-6 relative">
          <h6 className="text-[#000000] font-semibold text-xs 2xl:text-md">
            Top Volumes
          </h6>
          <IconButton className="absolute top-0 right-0" onClick={onRefresh}>
            <RefreshOutlined fontSize="small" />
          </IconButton>
        </div>
        <div className="w-full flex flex-col flex-1 pb-4">
          <>
            <div className="px-2 py-1 border-b border-t border-b-[#eae8e8] border-t-[#eae8e8]">
              <p className="text-[#666666] font-medium text-xs uppercase">
                Call
              </p>
            </div>
            <div className="bg-[#FFFBF6] border border-[#FFFBF6]">
              <div className="w-full flex items-center justify-between px-2 py-1">
                <small className="text-[#A7A7A7] font-medium text-xs ">
                  Strike Price
                </small>
                <small className="text-[#5DA92F] font-medium text-xs bg-[#5DA92F33] px-4 py-1">
                  {call ? call?.toLocaleString() : 0}
                </small>
              </div>
              <div className="w-full flex items-center justify-between px-2 py-1">
                <small className="text-[#A7A7A7] font-medium text-xs ">
                  Volume
                </small>
                <small className="text-[#373737] font-medium text-xs ">
                  {/* {callVolume?.callVolume
                    ? (callVolume?.callVolume / 1000000).toFixed(2) + " M"
                    : " 0M"} */}
                  {callVolume
                    ? (callVolume / 1000000).toFixed(2) + " M"
                    : " 0M"}
                </small>
              </div>
            </div>
          </>
          <span className="flex-1"></span>
          <>
            <div className="px-2 py-1 border-b border-t border-b-[#eae8e8] border-t-[#eae8e8]">
              <p className="text-[#666666] font-medium text-xs uppercase">
                Put
              </p>
            </div>
            <div className="bg-[#FFFBF6] border border-[#FFFBF6]">
              <div className="w-full flex items-center justify-between px-2 py-1">
                <small className="text-[#A7A7A7] font-medium text-xs ">
                  Strike Price
                </small>
                <small className="text-[#FF0E0E] font-medium text-xs bg-[#FF0E0E1A] px-4 py-1">
                  {put ? put?.toLocaleString() : 0}
                </small>
              </div>
              <div className="w-full flex items-center justify-between px-2 py-1">
                <small className="text-[#A7A7A7] font-medium text-xs ">
                  Volume
                </small>
                <small className="text-[#373737] font-medium text-xs ">
                  {/* {putVolume?.putVolume
                    ? (putVolume?.putVolume / 1000000).toFixed(2) + " M"
                    : " 0M"} */}
                  {putVolume ? (putVolume / 1000000).toFixed(2) + " M" : " 0M"}
                </small>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default TopVolume;
