import { createSlice } from "@reduxjs/toolkit";

const initialState = null

const UserSubsReducer = (state = initialState, action) => {
    return action.payload.payload
}

export const Reducer = createSlice({
    name: 'userSubs',
    initialState,
    reducers: {
        updateUserSubs: UserSubsReducer
    },
})

export const { updateUserSubs } = Reducer.actions
export default Reducer.reducer  