import moment from "moment/moment";
import React, { useEffect } from "react";
import TechnicalIndicatorsForm from "../../PrivateComponents/TechnicalIndicatorsForm";
import TechnicalIndicatorsTable from "../../PrivateComponents/TechnicalIndicatorsTable";
import { http } from "../../Services/api.services";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { showToast } from "../../store/Actions/Action";
import { useDispatch } from "react-redux";

const vwap = require("technicalindicators").VWAP;
const RSI = require("technicalindicators").RSI;
const EMA = require("technicalindicators").EMA;
const SMA = require("technicalindicators").SMA;
const BB = require("technicalindicators").BollingerBands;

const HISTORIC_DATA_ENDPOINT = `/ds/historic-data`;

const _form = {
  symbol: "",
  from: moment(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).format(
    "YYYY-MM-DD"
  ),
  to: moment(new Date()).format("YYYY-MM-DD"),
  interval: "minute",
  rsi: false,
  vwap: false,
  ema: [],
  sma: [],
  bb: [],
  perChange: false,
};

const Indicators = () => {
  const dispatch = useDispatch();
  const types = ["symbol", "from", "to", "interval"];
  const [isSearched, setSearched] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(true);
  const [data, setData] = React.useState(_form);
  const [autoCompleteText, setAutoCompleteText] = React.useState("");

  const [datasource, setDatasource] = React.useState(null);

  const onFormChange = async (value, type) => {
    try {
      let obj = { ...data };
      obj[type] = value;
      setData(obj);
      if (type == "symbol") {
        setAutoCompleteText(obj[type]["tradingsymbol"]);
      } else if (type === "vwap") {
        if (value) onvwapChange(datasource);
        else {
          // REMOVE VWAP
        }
      } else if (type === "rsi") {
        if (value) onrsiChange(datasource);
        else {
          // REMOVE RSI
        }
      } else if (type === "ema") onemaChange(datasource, value);
      else if (type === "sma") onsmaChange(datasource, value);
      else if (type === "bb") onbbChange(datasource, value);
      else if (type === "perChange") onPerChange(datasource);
    } catch (e) {
      console.log(`Form error - ${e}`);
    }
  };

  const onvwapChange = (data) => {
    try {
      const vwapBody = {
        open: [],
        close: [],
        high: [],
        low: [],
        volume: [],
      };
      for (let index = 0; index < data.length; index++) {
        const row = data[index];
        vwapBody.low.unshift(row.low);
        vwapBody.high.unshift(row.high);
        vwapBody.open.unshift(row.open);
        vwapBody.close.unshift(row.close);
        vwapBody.volume.unshift(row.volume);
        if (index === data.length - 1) {
          // CALCULATE
          let results = vwap.calculate(vwapBody);
          if (results.length) {
            results = results?.reverse();
            for (let _index = 0; _index < results.length; _index++) {
              const _vwap_result_ = results[_index];
              data[_index]["vwap"] = _vwap_result_ || 0;
              if (_index === results.length - 1) setDatasource(data);
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onrsiChange = (data) => {
    console.log(data);
    try {
      const rsiBody = {
        values: data?.map((d) => d.close),
        period: 15,
      };
      rsiBody.values = rsiBody.values?.reverse();
      if (rsiBody.values.length === data.length) {
        let results = RSI.calculate(rsiBody);
        results = results?.reverse();
        console.log("RSI results --- ", results);
        if (results.length) {
          for (let _index = 0; _index < results.length; _index++) {
            const _rsi_result_ = results[_index];
            data[_index]["rsi"] = _rsi_result_ || 0;
            if (_index === results.length - 1) setDatasource(data);
          }
        }
      }
    } catch (e) {
      console.log(`RSI CALCULATION ERROR ${e}`);
    }
  };

  const onemaChange = (data, values) => {
    try {
      let close = data?.map((d) => d.close);
      for (let index = 0; index < values.length; index++) {
        const period = values[index];
        const emaBody = {
          values: close?.reverse(),
          period,
        };
        let results = EMA.calculate(emaBody);
        results = results?.reverse();
        for (let _index = 0; _index < results.length; _index++) {
          const _ema_result_ = results[_index];
          data[_index]["ema_" + period] = _ema_result_ || 0;
          if (_index === results.length - 1) {
            if (index === values.length - 1) setDatasource(data);
          }
        }
      }
    } catch (e) {
      console.log(`EMA CALCUALTION ERROR ${e}`);
    }
  };

  const onsmaChange = (data, values) => {
    try {
      const close = data?.map((d) => d.close);
      for (let index = 0; index < values.length; index++) {
        const period = values[index];
        const smaBody = {
          values: close?.reverse(),
          period,
        };
        let results = SMA.calculate(smaBody);
        results = results.reverse();
        for (let _index = 0; _index < results.length; _index++) {
          const _ema_result_ = results[_index];
          data[_index]["sma_" + period] = _ema_result_ || 0;
          if (_index === results.length - 1) {
            if (index === values.length - 1) setDatasource(data);
          }
        }
      }
    } catch (e) {
      console.log(`SMA CALCUALTION ERROR ${e}`);
    }
  };

  const onPerChange = (data) => {
    try {
      console.log("onperchange");
      for (let index = 0; index < data.length; index++) {
        const _data = data[index];
        _data.perChange = ((_data.high - _data.low) / _data.low) * 100;
        if (index === data.length - 1) setDatasource(data);
      }
    } catch (e) {
      console.log(`PERCHANGE ERROR ${e}`);
    }
  };

  const onbbChange = (data, values) => {
    try {
      const close = data?.map((d) => d.close)?.reverse();
      for (let index = 0; index < values.length; index++) {
        const period = values[index];
        const bbBody = {
          values: close,
          period,
          stdDev: 2,
        };
        const results = BB.calculate(bbBody);
        for (let _index = 0; _index < results.length; _index++) {
          const _bb_result_ = results[_index];
          data[_index]["bb_" + period] = _bb_result_ || 0;
          if (_index === results.length - 1) {
            if (index === values.length - 1) setDatasource(data);
          }
        }
      }
    } catch (e) {
      console.log(`SMA CALCUALTION ERROR ${e}`);
    }
  };

  const isSuccessBody = (body) => {
    return new Promise((resolve, reject) => {
      try {
        let count = 0;
        for (let index = 0; index < types.length; index++) {
          const key = types[index];
          if (body[key]) count++;
          if (index == types.length - 1) {
            resolve(types.length == count ? true : false);
          }
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  const onSubmit = async () => {
    try {
      setSearched(true);
      setLoading(true);
      const valid = await isSuccessBody(data);  
      if (valid) {
        const _body = { ...data };
        // DAYS COUNT
        let responseData = [];
        let days =
          (new Date(_body.to).getTime() - new Date(_body.from).getTime()) /
          (1000 * 60 * 60 * 24);
        // SUCCESS BODY
        while (days > 0) {
          if (days > 60) {
            //2023-07-03
            _body.from = moment(
              new Date(new Date(_body.to).getTime() - 1000 * 60 * 60 * 24 * 60)
            ).format("YYYY-MM-DD");
          } else {
            _body.from = moment(
              new Date(
                new Date(_body.to).getTime() - 1000 * 60 * 60 * 24 * days
              )
            ).format("YYYY-MM-DD");
          }
          const body = {
            instrument_token: _body.symbol["instrument_token"],
            ..._body,
          };
          const response = await http.post(HISTORIC_DATA_ENDPOINT, body);
          setLoading(false);
          if (response && response.status == 200) {
            // SUCCESS RESPONSE
            responseData = [...responseData, ...response.data];
            setIsVisible(false);
          } else {
            // FAILED RESPONSE
            const error = response?.response?.data?.error;
            // setSnackbar({
            //     duration: 3000,
            //     message: error?.message,
            //     mode: 'error',
            //     open: true
            // })
            setIsVisible(true);
          }
          days = days - 60;
          _body.to = _body.from;
          if (days <= 0) {
            setDatasource(responseData);
            if (responseData?.length) {
              if (data.vwap) onvwapChange(responseData);
              if (data.rsi) onrsiChange(responseData);
              if (data.perChange) onPerChange(responseData);
              if (data.ema && data.ema?.length)
                onemaChange(responseData, data.ema);
              if (data.sma && data.sma?.length)
                onsmaChange(responseData, data.sma);
              if (data.bb && data.bb?.length) onbbChange(responseData, data.bb);
            }
          }
        }
      } else {
        // THROW ERRORS
      }
    } catch (e) {
      // setDatasource(dummy_response.data)
      const _snackbar = {
        message: "Failed to fetch historic data!",
        open: true,
        duration: 3000,
        type: "error",
      };
      dispatch(showToast(_snackbar));
      console.log(`error while submit ${e}`);
      setLoading(false);
      setIsVisible(true);
      setSearched(false);
    }
  };

  return (
    <div className="flex-1 flex overflow-auto relative h-full">
      <div className="flex-1 flex flex-col shadow bg-white px-3 py-2">
        <Accordion
          defaultExpanded
          sx={{
            border: "1px solid #1111",
            marginBottom: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="#000000" />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <h6 className="text-md font-bold">Indicators</h6>
          </AccordionSummary>
          <AccordionDetails>
            <TechnicalIndicatorsForm
              marketType={data?.marketType}
              symbol={data?.symbol}
              from={data?.from}
              to={data?.to}
              interval={data?.interval || "minute"}
              vwap={data?.vwap}
              ema={data?.ema}
              sma={data?.sma}
              rsi={data?.rsi}
              bb={data?.bb}
              perChange={data?.perChange}
              changeEvent={(value, key) => onFormChange(value, key)}
              loading={loading}
              onSubmit={() => onSubmit()}
              isVisible={isVisible}
            />
          </AccordionDetails>
        </Accordion>
        <div className="flex-1 bg-slate-50 overflow-hidden">
          {isSearched ? (
            <TechnicalIndicatorsTable
              rsi={data?.rsi}
              vwap={data?.vwap}
              ema={data?.ema}
              sma={data?.sma}
              bb={data?.bb}
              perChange={data?.perChange}
              datasource={datasource}
              showoi={
                data?.symbol?.exchange === "NFO" ||
                data?.symbol?.exchange === "BFO"
              }
            />
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <p className="text-slate-600 tracking-widest font-light text-sm">
                Please fill the form above!
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Indicators;
