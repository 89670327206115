// IP - STAGING
// export const BASE_URL = `http://64.227.140.49:8000/api/` /* LOCAL URL  */
// export const WEBSOCKET_URL = `ws://64.227.140.49:8000`
// export const WEBSOCKET_URL_TICKER = `ws://64.227.140.49:8001`     
// export const EVENT_SOURCE_URL = `http://64.227.140.49:8000/api/`


// LOCAL
// export const BASE_URL = `http://192.168.1.16:8000/api/` /* LOCAL URL  */
// export const WEBSOCKET_URL = `ws://192.168.1.16:8000`
// export const WEBSOCKET_URL_TICKER = `ws://192.168.1.16:8001`     
// export const EVENT_SOURCE_URL = `http://192.168.1.4:8000/api/`

// PROD
export const BASE_URL = `https://go-api.tradewolf.in/api/` /* PROD URL  */
export const EVENT_SOURCE_URL = `https://go-api.tradewolf.in/api/`
export const WEBSOCKET_URL = `wss://go-api.tradewolf.in`
export const WEBSOCKET_URL_TICKER = `wss://ws.tradewolf.in`
export const WEBSOCKET_API_URL = ``
export const SOCKET_URL = `https://tradewolf.in/` /* PROD URL  */

// STAGE URL                        
// export const BASE_URL = `http://64.227.140.49:8000/api/` 
// export const WEBSOCKET_URL = `ws://64.227.140.49:8000`

// PROD PANEL LINK  
export const panel = `https://go.tradewolf.in/`

// export const PAYMENT_URL = `http://localhost:3001/` //LOCAL
export const PAYMENT_URL = `https://learnwithus.tradewolf.in/` //PROD   

export const IMAGE_URL = BASE_URL + 'admin/download/'

export const ENDPOINTS = {
    // LOGOUT: `employee/logout`    
    EXPIRY: `customer/index-options/expiry`,
    OPTIONDATA: `indexes/option-chain`,
    LASTICKS: `indexes/last-ticks`,
    OPTION_CHAIN: `ds/option-chain`
}


export const SAMCO_ENDPOINTS = {
    LOGIN: `https://api.stocknote.com/login`,
    OPTIOIN_CHAIN: `https://api.stocknote.com/option/optionChain`,
    WEBSOCKET: `wss://stream.stocknote.com/`
}

export const HASH = {
    lwu: '#learn-with-us',
    ewu: '#earn-with-us',
    trial: '#start-free-trial',
}

export const TERMS_AND_CONDITIONS = `https://docs.google.com/document/d/e/2PACX-1vQazc1tynjaaBBFYTXxFmbkj34yt0BITon5A24cPfZwOltEDphPF4AYBWXZgknAREgfy8QzkRr0mvbq/pub?embedded=true`

export const dummy_option_chain = {
    "nifty": {
        "id": "3e41345c-04ae-4ec9-9da4-c531989e8f4b",
        "index": "NIFTY",
        "tradingsymbol": "NSE:NIFTY 50",
        "strikeMargin": 50,
        "type": "NIFTY_OI",
        "qty": 50,
        "option": [
            {
                "strikePrice": 21100.0,
                "symbol": "NIFTY2410421100",
                "put": {
                    "symbol": "NIFTY2410421100PE",
                    "instrument": 10792450,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421100CE",
                    "instrument": 10792194,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21150.0,
                "symbol": "NIFTY2410421150",
                "put": {
                    "symbol": "NIFTY2410421150PE",
                    "instrument": 10793986,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421150CE",
                    "instrument": 10793730,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21200.0,
                "symbol": "NIFTY2410421200",
                "put": {
                    "symbol": "NIFTY2410421200PE",
                    "instrument": 10795266,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421200CE",
                    "instrument": 10794242,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21250.0,
                "symbol": "NIFTY2410421250",
                "put": {
                    "symbol": "NIFTY2410421250PE",
                    "instrument": 10799874,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421250CE",
                    "instrument": 10798594,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21300.0,
                "symbol": "NIFTY2410421300",
                "put": {
                    "symbol": "NIFTY2410421300PE",
                    "instrument": 10800642,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421300CE",
                    "instrument": 10800130,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21350.0,
                "symbol": "NIFTY2410421350",
                "put": {
                    "symbol": "NIFTY2410421350PE",
                    "instrument": 10801410,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421350CE",
                    "instrument": 10800898,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21400.0,
                "symbol": "NIFTY2410421400",
                "put": {
                    "symbol": "NIFTY2410421400PE",
                    "instrument": 10801922,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421400CE",
                    "instrument": 10801666,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21450.0,
                "symbol": "NIFTY2410421450",
                "put": {
                    "symbol": "NIFTY2410421450PE",
                    "instrument": 10802434,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421450CE",
                    "instrument": 10802178,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21500.0,
                "symbol": "NIFTY2410421500",
                "put": {
                    "symbol": "NIFTY2410421500PE",
                    "instrument": 10803458,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421500CE",
                    "instrument": 10803202,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21550.0,
                "symbol": "NIFTY2410421550",
                "put": {
                    "symbol": "NIFTY2410421550PE",
                    "instrument": 10804738,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421550CE",
                    "instrument": 10804482,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21600.0,
                "symbol": "NIFTY2410421600",
                "put": {
                    "symbol": "NIFTY2410421600PE",
                    "instrument": 10805250,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421600CE",
                    "instrument": 10804994,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21650.0,
                "symbol": "NIFTY2410421650",
                "put": {
                    "symbol": "NIFTY2410421650PE",
                    "instrument": 10806018,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421650CE",
                    "instrument": 10805762,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21700.0,
                "symbol": "NIFTY2410421700",
                "put": {
                    "symbol": "NIFTY2410421700PE",
                    "instrument": 10806530,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421700CE",
                    "instrument": 10806274,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21750.0,
                "symbol": "NIFTY2410421750",
                "put": {
                    "symbol": "NIFTY2410421750PE",
                    "instrument": 10807042,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421750CE",
                    "instrument": 10806786,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21800.0,
                "symbol": "NIFTY2410421800",
                "put": {
                    "symbol": "NIFTY2410421800PE",
                    "instrument": 10807810,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421800CE",
                    "instrument": 10807298,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21850.0,
                "symbol": "NIFTY2410421850",
                "put": {
                    "symbol": "NIFTY2410421850PE",
                    "instrument": 10810626,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421850CE",
                    "instrument": 10810114,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21900.0,
                "symbol": "NIFTY2410421900",
                "put": {
                    "symbol": "NIFTY2410421900PE",
                    "instrument": 10811138,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421900CE",
                    "instrument": 10810882,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21950.0,
                "symbol": "NIFTY2410421950",
                "put": {
                    "symbol": "NIFTY2410421950PE",
                    "instrument": 10811650,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410421950CE",
                    "instrument": 10811394,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 22000.0,
                "symbol": "NIFTY2410422000",
                "put": {
                    "symbol": "NIFTY2410422000PE",
                    "instrument": 10812162,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "NIFTY2410422000CE",
                    "instrument": 10811906,
                    "exchange": "NFO"
                }
            }
        ],
        "expiry": [
            "2024-01-04",
            "2024-01-11",
            "2024-01-18",
            "2024-01-25",
            "2024-02-01",
            "2024-02-29",
            "2024-03-28",
            "2024-06-27",
            "2024-09-26",
            "2024-12-26",
            "2025-06-26",
            "2025-12-24",
            "2026-06-25",
            "2026-12-31",
            "2027-06-24",
            "2027-12-30",
            "2028-06-29",
            "2028-12-28"
        ],
        "updated_at": "2024-01-02",
        "strikePrice": 21552.95,
        "instrumentToken": 256265,
        "strikePriceH": 21500
    },
    "banknifty": {
        "id": "efa5f419-241a-41cd-9283-c82ae2fc7581",
        "index": "BANKNIFTY",
        "tradingsymbol": "NSE:NIFTY BANK",
        "strikeMargin": 100,
        "type": "BANKNIFTY_OI",
        "qty": 15,
        "option": [
            {
                "strikePrice": 46700.0,
                "symbol": "BANKNIFTY2410346700",
                "put": {
                    "symbol": "BANKNIFTY2410346700PE",
                    "instrument": 11954434,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410346700CE",
                    "instrument": 11951874,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 46800.0,
                "symbol": "BANKNIFTY2410346800",
                "put": {
                    "symbol": "BANKNIFTY2410346800PE",
                    "instrument": 11955714,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410346800CE",
                    "instrument": 11955458,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 46900.0,
                "symbol": "BANKNIFTY2410346900",
                "put": {
                    "symbol": "BANKNIFTY2410346900PE",
                    "instrument": 11957250,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410346900CE",
                    "instrument": 11956994,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 47000.0,
                "symbol": "BANKNIFTY2410347000",
                "put": {
                    "symbol": "BANKNIFTY2410347000PE",
                    "instrument": 11959042,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410347000CE",
                    "instrument": 11958018,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 47100.0,
                "symbol": "BANKNIFTY2410347100",
                "put": {
                    "symbol": "BANKNIFTY2410347100PE",
                    "instrument": 11961346,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410347100CE",
                    "instrument": 11961090,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 47200.0,
                "symbol": "BANKNIFTY2410347200",
                "put": {
                    "symbol": "BANKNIFTY2410347200PE",
                    "instrument": 11961858,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410347200CE",
                    "instrument": 11961602,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 47300.0,
                "symbol": "BANKNIFTY2410347300",
                "put": {
                    "symbol": "BANKNIFTY2410347300PE",
                    "instrument": 11963394,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410347300CE",
                    "instrument": 11963138,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 47400.0,
                "symbol": "BANKNIFTY2410347400",
                "put": {
                    "symbol": "BANKNIFTY2410347400PE",
                    "instrument": 11963906,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410347400CE",
                    "instrument": 11963650,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 47500.0,
                "symbol": "BANKNIFTY2410347500",
                "put": {
                    "symbol": "BANKNIFTY2410347500PE",
                    "instrument": 11965698,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410347500CE",
                    "instrument": 11964930,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 47600.0,
                "symbol": "BANKNIFTY2410347600",
                "put": {
                    "symbol": "BANKNIFTY2410347600PE",
                    "instrument": 11966210,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410347600CE",
                    "instrument": 11965954,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 47700.0,
                "symbol": "BANKNIFTY2410347700",
                "put": {
                    "symbol": "BANKNIFTY2410347700PE",
                    "instrument": 11983106,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410347700CE",
                    "instrument": 11966466,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 47800.0,
                "symbol": "BANKNIFTY2410347800",
                "put": {
                    "symbol": "BANKNIFTY2410347800PE",
                    "instrument": 11985154,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410347800CE",
                    "instrument": 11984386,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 47900.0,
                "symbol": "BANKNIFTY2410347900",
                "put": {
                    "symbol": "BANKNIFTY2410347900PE",
                    "instrument": 11986178,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410347900CE",
                    "instrument": 11985922,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 48000.0,
                "symbol": "BANKNIFTY2410348000",
                "put": {
                    "symbol": "BANKNIFTY2410348000PE",
                    "instrument": 11988994,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410348000CE",
                    "instrument": 11986690,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 48100.0,
                "symbol": "BANKNIFTY2410348100",
                "put": {
                    "symbol": "BANKNIFTY2410348100PE",
                    "instrument": 11990274,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410348100CE",
                    "instrument": 11989250,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 48200.0,
                "symbol": "BANKNIFTY2410348200",
                "put": {
                    "symbol": "BANKNIFTY2410348200PE",
                    "instrument": 11990786,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410348200CE",
                    "instrument": 11990530,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 48300.0,
                "symbol": "BANKNIFTY2410348300",
                "put": {
                    "symbol": "BANKNIFTY2410348300PE",
                    "instrument": 11991298,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410348300CE",
                    "instrument": 11991042,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 48400.0,
                "symbol": "BANKNIFTY2410348400",
                "put": {
                    "symbol": "BANKNIFTY2410348400PE",
                    "instrument": 11992066,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410348400CE",
                    "instrument": 11991554,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 48500.0,
                "symbol": "BANKNIFTY2410348500",
                "put": {
                    "symbol": "BANKNIFTY2410348500PE",
                    "instrument": 11993090,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410348500CE",
                    "instrument": 11992834,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 48600.0,
                "symbol": "BANKNIFTY2410348600",
                "put": {
                    "symbol": "BANKNIFTY2410348600PE",
                    "instrument": 8971010,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "BANKNIFTY2410348600CE",
                    "instrument": 8970754,
                    "exchange": "NFO"
                }
            }
        ],
        "expiry": [
            "2024-01-03",
            "2024-01-10",
            "2024-01-17",
            "2024-01-25",
            "2024-01-31",
            "2024-02-29",
            "2024-03-28",
            "2024-06-27",
            "2024-09-26",
            "2024-12-26"
        ],
        "updated_at": "2024-01-02",
        "strikePrice": 47621.9,
        "instrumentToken": 260105,
        "strikePriceH": 47600
    },
    "midcpnifty": {
        "id": "05fb33b0-9cfc-4865-9d19-d61e5b321de0",
        "index": "MIDCPNIFTY",
        "tradingsymbol": "NSE:NIFTY MID SELECT",
        "strikeMargin": 25,
        "type": "MIDCPNIFTY_OI",
        "qty": 75,
        "option": [
            {
                "strikePrice": 10150.0,
                "symbol": "MIDCPNIFTY2410810150",
                "put": {
                    "symbol": "MIDCPNIFTY2410810150PE",
                    "instrument": 10530306,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810150CE",
                    "instrument": 10530050,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10175.0,
                "symbol": "MIDCPNIFTY2410810175",
                "put": {
                    "symbol": "MIDCPNIFTY2410810175PE",
                    "instrument": 10532354,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810175CE",
                    "instrument": 10532098,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10200.0,
                "symbol": "MIDCPNIFTY2410810200",
                "put": {
                    "symbol": "MIDCPNIFTY2410810200PE",
                    "instrument": 10533890,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810200CE",
                    "instrument": 10533634,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10225.0,
                "symbol": "MIDCPNIFTY2410810225",
                "put": {
                    "symbol": "MIDCPNIFTY2410810225PE",
                    "instrument": 10544642,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810225CE",
                    "instrument": 10544386,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10250.0,
                "symbol": "MIDCPNIFTY2410810250",
                "put": {
                    "symbol": "MIDCPNIFTY2410810250PE",
                    "instrument": 10548226,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810250CE",
                    "instrument": 10547714,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10275.0,
                "symbol": "MIDCPNIFTY2410810275",
                "put": {
                    "symbol": "MIDCPNIFTY2410810275PE",
                    "instrument": 10549250,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810275CE",
                    "instrument": 10548994,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10300.0,
                "symbol": "MIDCPNIFTY2410810300",
                "put": {
                    "symbol": "MIDCPNIFTY2410810300PE",
                    "instrument": 10562818,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810300CE",
                    "instrument": 10561026,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10325.0,
                "symbol": "MIDCPNIFTY2410810325",
                "put": {
                    "symbol": "MIDCPNIFTY2410810325PE",
                    "instrument": 10563842,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810325CE",
                    "instrument": 10563586,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10350.0,
                "symbol": "MIDCPNIFTY2410810350",
                "put": {
                    "symbol": "MIDCPNIFTY2410810350PE",
                    "instrument": 10572034,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810350CE",
                    "instrument": 10571778,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10375.0,
                "symbol": "MIDCPNIFTY2410810375",
                "put": {
                    "symbol": "MIDCPNIFTY2410810375PE",
                    "instrument": 10573570,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810375CE",
                    "instrument": 10573314,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10400.0,
                "symbol": "MIDCPNIFTY2410810400",
                "put": {
                    "symbol": "MIDCPNIFTY2410810400PE",
                    "instrument": 10575362,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810400CE",
                    "instrument": 10574082,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10425.0,
                "symbol": "MIDCPNIFTY2410810425",
                "put": {
                    "symbol": "MIDCPNIFTY2410810425PE",
                    "instrument": 10575874,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810425CE",
                    "instrument": 10575618,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10450.0,
                "symbol": "MIDCPNIFTY2410810450",
                "put": {
                    "symbol": "MIDCPNIFTY2410810450PE",
                    "instrument": 10578434,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810450CE",
                    "instrument": 10578178,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10475.0,
                "symbol": "MIDCPNIFTY2410810475",
                "put": {
                    "symbol": "MIDCPNIFTY2410810475PE",
                    "instrument": 10579458,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810475CE",
                    "instrument": 10579202,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10500.0,
                "symbol": "MIDCPNIFTY2410810500",
                "put": {
                    "symbol": "MIDCPNIFTY2410810500PE",
                    "instrument": 10582018,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810500CE",
                    "instrument": 10581762,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10525.0,
                "symbol": "MIDCPNIFTY2410810525",
                "put": {
                    "symbol": "MIDCPNIFTY2410810525PE",
                    "instrument": 10582530,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810525CE",
                    "instrument": 10582274,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 10550.0,
                "symbol": "MIDCPNIFTY2410810550",
                "put": {
                    "symbol": "MIDCPNIFTY2410810550PE",
                    "instrument": 10584578,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "MIDCPNIFTY2410810550CE",
                    "instrument": 10584322,
                    "exchange": "NFO"
                }
            }
        ],
        "expiry": [
            "2024-01-08",
            "2024-01-15",
            "2024-01-22",
            "2024-01-29",
            "2024-02-05",
            "2024-02-26",
            "2024-03-22"
        ],
        "updated_at": "2024-01-02",
        "strikePrice": 10324.7,
        "instrumentToken": 288009,
        "strikePriceH": 10300
    },
    "sensex": {
        "id": "7c27f17c-a39b-4b42-956f-986ed743d2d8",
        "index": "SENSEX",
        "tradingsymbol": "BSE:SENSEX",
        "strikeMargin": 100,
        "type": "SENSEX_OI",
        "qty": 10,
        "option": [
            {
                "strikePrice": 70600.0,
                "symbol": "SENSEX2410570600",
                "put": {
                    "symbol": "SENSEX2410570600PE",
                    "instrument": 220631045,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410570600CE",
                    "instrument": 219333381,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 70700.0,
                "symbol": "SENSEX2410570700",
                "put": {
                    "symbol": "SENSEX2410570700PE",
                    "instrument": 220965381,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410570700CE",
                    "instrument": 220745221,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 70800.0,
                "symbol": "SENSEX2410570800",
                "put": {
                    "symbol": "SENSEX2410570800PE",
                    "instrument": 220712453,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410570800CE",
                    "instrument": 219752197,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 70900.0,
                "symbol": "SENSEX2410570900",
                "put": {
                    "symbol": "SENSEX2410570900PE",
                    "instrument": 219495429,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410570900CE",
                    "instrument": 220814341,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 71000.0,
                "symbol": "SENSEX2410571000",
                "put": {
                    "symbol": "SENSEX2410571000PE",
                    "instrument": 220356357,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410571000CE",
                    "instrument": 219066629,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 71100.0,
                "symbol": "SENSEX2410571100",
                "put": {
                    "symbol": "SENSEX2410571100PE",
                    "instrument": 218879493,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410571100CE",
                    "instrument": 217674501,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 71200.0,
                "symbol": "SENSEX2410571200",
                "put": {
                    "symbol": "SENSEX2410571200PE",
                    "instrument": 217650693,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410571200CE",
                    "instrument": 219450629,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 71300.0,
                "symbol": "SENSEX2410571300",
                "put": {
                    "symbol": "SENSEX2410571300PE",
                    "instrument": 219248901,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410571300CE",
                    "instrument": 217966341,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 71400.0,
                "symbol": "SENSEX2410571400",
                "put": {
                    "symbol": "SENSEX2410571400PE",
                    "instrument": 217786117,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410571400CE",
                    "instrument": 220973829,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 71500.0,
                "symbol": "SENSEX2410571500",
                "put": {
                    "symbol": "SENSEX2410571500PE",
                    "instrument": 219660549,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410571500CE",
                    "instrument": 218376197,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 71600.0,
                "symbol": "SENSEX2410571600",
                "put": {
                    "symbol": "SENSEX2410571600PE",
                    "instrument": 218148357,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410571600CE",
                    "instrument": 217579781,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 71700.0,
                "symbol": "SENSEX2410571700",
                "put": {
                    "symbol": "SENSEX2410571700PE",
                    "instrument": 217552389,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410571700CE",
                    "instrument": 218744837,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 71800.0,
                "symbol": "SENSEX2410571800",
                "put": {
                    "symbol": "SENSEX2410571800PE",
                    "instrument": 218557189,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410571800CE",
                    "instrument": 217632773,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 71900.0,
                "symbol": "SENSEX2410571900",
                "put": {
                    "symbol": "SENSEX2410571900PE",
                    "instrument": 217601797,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410571900CE",
                    "instrument": 220853765,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 72000.0,
                "symbol": "SENSEX2410572000",
                "put": {
                    "symbol": "SENSEX2410572000PE",
                    "instrument": 221573637,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410572000CE",
                    "instrument": 221340165,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 72100.0,
                "symbol": "SENSEX2410572100",
                "put": {
                    "symbol": "SENSEX2410572100PE",
                    "instrument": 221338629,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410572100CE",
                    "instrument": 221283589,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 72200.0,
                "symbol": "SENSEX2410572200",
                "put": {
                    "symbol": "SENSEX2410572200PE",
                    "instrument": 221278469,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410572200CE",
                    "instrument": 221210885,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 72300.0,
                "symbol": "SENSEX2410572300",
                "put": {
                    "symbol": "SENSEX2410572300PE",
                    "instrument": 221067525,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410572300CE",
                    "instrument": 221595397,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 72400.0,
                "symbol": "SENSEX2410572400",
                "put": {
                    "symbol": "SENSEX2410572400PE",
                    "instrument": 223009541,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410572400CE",
                    "instrument": 222973701,
                    "exchange": "BFO"
                }
            },
            {
                "strikePrice": 72500.0,
                "symbol": "SENSEX2410572500",
                "put": {
                    "symbol": "SENSEX2410572500PE",
                    "instrument": 222971653,
                    "exchange": "BFO"
                },
                "call": {
                    "symbol": "SENSEX2410572500CE",
                    "instrument": 222846469,
                    "exchange": "BFO"
                }
            }
        ],
        "expiry": [
            "2024-01-05",
            "2024-01-12",
            "2024-01-19",
            "2024-01-25",
            "2024-02-02",
            "2024-02-09",
            "2024-02-16",
            "2024-02-23",
            "2024-03-28",
            "2024-06-28",
            "2024-09-27",
            "2024-12-27",
            "2025-06-27",
            "2025-12-26",
            "2026-06-26",
            "2026-12-25",
            "2027-06-25",
            "2027-12-31",
            "2028-06-30",
            "2028-12-29"
        ],
        "updated_at": "2024-01-02",
        "strikePrice": 71506.72,
        "instrumentToken": 265,
        "strikePriceH": 71500
    },
    "finnifty": {
        "id": "ecb72b60-b0b2-4f69-a45e-d52307c08074",
        "index": "FINNIFTY",
        "tradingsymbol": "NSE:NIFTY FIN SERVICE",
        "strikeMargin": 50,
        "type": "FINNIFTY_OI",
        "qty": 40,
        "option": [
            {
                "strikePrice": 20800.0,
                "symbol": "FINNIFTY2410920800",
                "put": {
                    "symbol": "FINNIFTY2410920800PE",
                    "instrument": 11930370,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410920800CE",
                    "instrument": 11930114,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 20850.0,
                "symbol": "FINNIFTY2410920850",
                "put": {
                    "symbol": "FINNIFTY2410920850PE",
                    "instrument": 11932930,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410920850CE",
                    "instrument": 11930626,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 20900.0,
                "symbol": "FINNIFTY2410920900",
                "put": {
                    "symbol": "FINNIFTY2410920900PE",
                    "instrument": 11935746,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410920900CE",
                    "instrument": 11933186,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 20950.0,
                "symbol": "FINNIFTY2410920950",
                "put": {
                    "symbol": "FINNIFTY2410920950PE",
                    "instrument": 11936258,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410920950CE",
                    "instrument": 11936002,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21000.0,
                "symbol": "FINNIFTY2410921000",
                "put": {
                    "symbol": "FINNIFTY2410921000PE",
                    "instrument": 11940098,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921000CE",
                    "instrument": 11936514,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21050.0,
                "symbol": "FINNIFTY2410921050",
                "put": {
                    "symbol": "FINNIFTY2410921050PE",
                    "instrument": 11940610,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921050CE",
                    "instrument": 11940354,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21100.0,
                "symbol": "FINNIFTY2410921100",
                "put": {
                    "symbol": "FINNIFTY2410921100PE",
                    "instrument": 11944194,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921100CE",
                    "instrument": 11940866,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21150.0,
                "symbol": "FINNIFTY2410921150",
                "put": {
                    "symbol": "FINNIFTY2410921150PE",
                    "instrument": 11945730,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921150CE",
                    "instrument": 11944450,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21200.0,
                "symbol": "FINNIFTY2410921200",
                "put": {
                    "symbol": "FINNIFTY2410921200PE",
                    "instrument": 11949058,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921200CE",
                    "instrument": 11946754,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21250.0,
                "symbol": "FINNIFTY2410921250",
                "put": {
                    "symbol": "FINNIFTY2410921250PE",
                    "instrument": 11952642,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921250CE",
                    "instrument": 11949570,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21300.0,
                "symbol": "FINNIFTY2410921300",
                "put": {
                    "symbol": "FINNIFTY2410921300PE",
                    "instrument": 11953410,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921300CE",
                    "instrument": 11952898,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21350.0,
                "symbol": "FINNIFTY2410921350",
                "put": {
                    "symbol": "FINNIFTY2410921350PE",
                    "instrument": 11953922,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921350CE",
                    "instrument": 11953666,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21400.0,
                "symbol": "FINNIFTY2410921400",
                "put": {
                    "symbol": "FINNIFTY2410921400PE",
                    "instrument": 11962882,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921400CE",
                    "instrument": 11954178,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21450.0,
                "symbol": "FINNIFTY2410921450",
                "put": {
                    "symbol": "FINNIFTY2410921450PE",
                    "instrument": 11964418,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921450CE",
                    "instrument": 11964162,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21500.0,
                "symbol": "FINNIFTY2410921500",
                "put": {
                    "symbol": "FINNIFTY2410921500PE",
                    "instrument": 11968002,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921500CE",
                    "instrument": 11964674,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21550.0,
                "symbol": "FINNIFTY2410921550",
                "put": {
                    "symbol": "FINNIFTY2410921550PE",
                    "instrument": 11968514,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921550CE",
                    "instrument": 11968258,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21600.0,
                "symbol": "FINNIFTY2410921600",
                "put": {
                    "symbol": "FINNIFTY2410921600PE",
                    "instrument": 11970562,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921600CE",
                    "instrument": 11970306,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21650.0,
                "symbol": "FINNIFTY2410921650",
                "put": {
                    "symbol": "FINNIFTY2410921650PE",
                    "instrument": 11971074,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921650CE",
                    "instrument": 11970818,
                    "exchange": "NFO"
                }
            },
            {
                "strikePrice": 21700.0,
                "symbol": "FINNIFTY2410921700",
                "put": {
                    "symbol": "FINNIFTY2410921700PE",
                    "instrument": 11973122,
                    "exchange": "NFO"
                },
                "call": {
                    "symbol": "FINNIFTY2410921700CE",
                    "instrument": 11972866,
                    "exchange": "NFO"
                }
            }
        ],
        "expiry": [
            "2024-01-09",
            "2024-01-16",
            "2024-01-23",
            "2024-01-30",
            "2024-02-27",
            "2024-03-26"
        ],
        "updated_at": "2024-01-02",
        "strikePrice": 21276,
        "instrumentToken": 257801,
        "strikePriceH": 21200
    }
}

export const slt = {
    "type": "stoplossTarget",
    "options": {
        "NIFTY": {
            "call": {
                "buy": {
                    "sl": 0,
                    "t": 0
                },
                "sell": {
                    "sl": 0,
                    "t": 0
                }
            },
            "put": {
                "buy": {
                    "sl": 0,
                    "t": 0
                },
                "sell": {
                    "sl": 0,
                    "t": 0
                }
            }
        },
        "BANKNIFTY": {
            "call": {
                "buy": {
                    "sl": 0,
                    "t": 0
                },
                "sell": {
                    "sl": 0,
                    "t": 0
                }
            },
            "put": {
                "buy": {
                    "sl": 0,
                    "t": 0
                },
                "sell": {
                    "sl": 0,
                    "t": 0
                }
            }
        },
        "FINNIFTY": {
            "call": {
                "buy": {
                    "sl": 0,
                    "t": 0
                },
                "sell": {
                    "sl": 0,
                    "t": 0
                }
            },
            "put": {
                "buy": {
                    "sl": 0,
                    "t": 0
                },
                "sell": {
                    "sl": 0,
                    "t": 0
                }
            }
        },
        "MIDCPNIFTY": {
            "call": {
                "buy": {
                    "sl": 0,
                    "t": 0
                },
                "sell": {
                    "sl": 0,
                    "t": 0
                }
            },
            "put": {
                "buy": {
                    "sl": 0,
                    "t": 0
                },
                "sell": {
                    "sl": 0,
                    "t": 0
                }
            }
        },
        "SENSEX": {
            "call": {
                "buy": {
                    "sl": 0,
                    "t": 0
                },
                "sell": {
                    "sl": 0,
                    "t": 0
                }
            },
            "put": {
                "buy": {
                    "sl": 0,
                    "t": 0
                },
                "sell": {
                    "sl": 0,
                    "t": 0
                }
            }
        }
    }
};


