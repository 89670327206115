import React from "react";
import GraphHeader from "./GraphHeader";
import IhmGraph from "./IhmGraph";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import IconButton from "@mui/material/IconButton";

const IHMCHart = (props) => {
  let _interval = props?.interval;
  let _currentIndex = props?.currentIndex?.toUpperCase();
  let _eventName = `${_currentIndex}minute${_interval}`;

  // console.log(props?.ihmData?.[_eventName]);  

  return (
    <>
      <GraphHeader
        title="IHM Chart"
        interval={props?.interval}
        setIntervalFunction={props?.setIntervalFunction}
      />
      <div className="w-full flex-1 p-2 flex items-center justify-center">
        <IhmGraph
          ihmData={props?.ihmData?.[_eventName]}  
          isFullScreen={props?.isFullScreen}
          setIhmData={props?.setIhmData}
        />
      </div>
      {!props?.isFullScreen && (
        <div className="w-full  flex items-center justify-end pr-1 pb-1">
          <IconButton
            onClick={() => {
              props?.setIsFullScreen(true);
              props?.setGraphType("IHM Chart");
            }}
            aria-label="delete"
          >
            <FullscreenIcon color="gray" className="cursor-pointer" />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default IHMCHart;
