import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { H5, H6 } from "../Theme/ThemeComponent";
import { useThemeContext } from "../Theme/ThemeContext";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";

export default function ConfirmRemoveCompany({
    loading,
    open,
    onHandleClose,
    onRemoveCompany,
    message,
    company,
    selectedWatchlistData
}) {
    const { theme } = useThemeContext();
    const { currentWatchlist, newWatchlist, allWatchlists } = useSelector((state) => state.equity);
    return (
        <Dialog
            open={open}
            onClose={() => onHandleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Remove Company?</DialogTitle>
            <DialogContent
                sx={{
                    maxWidth: 400,
                }}
            >
                <DialogContentText id="alert-dialog-description">
                    <H6 title={`Are you sure you want to remove ${company?.name} from ${currentWatchlist?.watchlist?.title ? currentWatchlist?.watchlist?.title : selectedWatchlistData?.title}?`} color={theme.palette.text.coolGray} font={'normal'} />
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    size="small"
                    variant="outlined" onClick={onHandleClose}>Cancel</Button>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={loading}
                    autoFocus
                    size="small"
                    onClick={() => onRemoveCompany()}
                >
                    Remove
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
