// DimensionsContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const DimensionsContext = createContext();

export const useDimensions = () => {
    return useContext(DimensionsContext);
};

export const DimensionsProvider = ({ children }) => {
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    
    const [xs, setXSmall] = useState(window.innerWidth < 640 ? true : false)
    const [sm, setSmall] = useState(window.innerWidth >= 640 && window?.innerWidth < 768 ? true : false)
    const [md, setMedium] = useState(window.innerWidth >= 768 && window?.innerWidth < 1024 ? true : false)
    const [lg, setLarge] = useState(window.innerWidth >= 1024 && window?.innerWidth < 1280 ? true : false)
    const [xl, setXLarge] = useState(window.innerWidth >= 1280 && window?.innerWidth <= 1536? true : false)
    const [xxl, setXXLarge] = useState(window.innerWidth > 1536 ? true : false)

    const updateWindowDimensions = () => {
        setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        setXSmall(window.innerWidth < 640 ? true : false)
        setSmall(window.innerWidth >= 640 && window?.innerWidth < 768 ? true : false)
        setMedium(window.innerWidth >= 768 && window?.innerWidth < 1024 ? true : false)
        setLarge(window.innerWidth >= 1024 && window?.innerWidth < 1280 ? true : false)
        setXLarge(window.innerWidth >= 1280 && window?.innerWidth < 1536 ? true : false)
        setXXLarge(window.innerWidth >= 1536 ? true : false)
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);

    const contextValue = {
        windowDimensions,
        xs,
        sm,
        md,
        lg,
        xl,
        xxl
    };

    return (
        <DimensionsContext.Provider value={contextValue}>
            {children}
        </DimensionsContext.Provider>
    );
};
