import React from "react";
import { BasketIcon } from "../../PublicComponents/Icons";
import { styled } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { trimString } from "../../Services/api.services";
import { useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Chip from "@mui/material/Chip";
import moment from "moment";

export const BasketCard = ({ basket, getOrders, executeCurrentBasket }) => {
	const { currentBasket } = useSelector((state) => state.Baskets);
	return (
		<>
			<div
				onClick={() => getOrders(basket?.id)}
				className={`w-full cursor-pointer p-3 py-4 flex items-center justify-between gap-3 border border-transparent border-b-[#DEDEDE]  border-r-[5px] ${basket?.id === currentBasket?.id ||
					basket?.name === currentBasket?.name
					? "border-r-blue-500"
					: "border-r-transparent hover:border-r-gray-300"
					}`}>
				<div className="w-3/5">
					<h5 className={`${basket?.id === currentBasket?.id ||
						basket?.name === currentBasket?.name
						? "text-blue-600"
						: "text-[#000000]"
						} whitespace-nowrap text-sm  lg:text-sm font-semibold overflow-hidden text-ellipsis`}>
						{basket?.name}{" "}
					</h5>
				</div>
				<div className="flex  gap-2 items-center">
					{basket?.legs ? (
						<Chip size="small" variant="filled" label={basket?.legs} />
					) : null}
					{basket?.legs ? "" : null}
					{basket?.stoploss?.stoploss ? (
						<p className={`${basket?.id === currentBasket?.id ||
							basket?.name === currentBasket?.name
							? "text-red-600"
							: "text-[#373737]"
							} whitespace-nowrap text-sm lg:text-xs`} >
							SL{" "}
							<span className="font-semibold">
								₹{basket?.stoploss?.stoploss}
							</span>
						</p>
					) : null}
					{basket?.stoploss?.target ? (
						<p
							className={`${basket?.id === currentBasket?.id ||
								basket?.name === currentBasket?.name
								? "text-green-600"
								: "text-[#373737]"
								} whitespace-nowrap text-sm lg:text-xs`}
						>
							T{" "}
							<span className="font-semibold">₹{basket?.stoploss?.target}</span>
						</p>
					) : null}
				</div>
			</div>
		</>
	);
};

export const HistoryCard = ({ history, selectHistory }) => {
	const { currentHistory } = useSelector((state) => state.Baskets);

	return (
		<>
			<div
				onClick={() => selectHistory()}
				className={`w-full group cursor-pointer p-3 flex flex-col gap-3 border border-transparent border-b-[#DEDEDE] border-r-[5px]  ${history?.id == currentHistory?.id
					? "border-r-blue-500"
					: " border-r-transparent hover:border-r-gray-300"
					} `}
			>
				<div className="w-full flex items-center justify-between">
					<h5
						className={`${history?.id == currentHistory?.id
							? "text-blue-600"
							: "text-slate-500"
							}  whitespace-nowrap text-sm lg:text-sm font-semibold text-ellipsis w-3/5 overflow-hidden `}
					>
						{history?.name}
					</h5>
					{history?.hit ? history?.hit?.toLowerCase() == "sl" ? <SL /> : history?.hit?.toLowerCase() == "target" ? <T /> : <M /> : ''}
				</div>
				<div className="w-full flex items-center justify-between">
					<div className="flex items-center gap-3">
						<Chip size="small" variant="filled" label={(history?.total_buy + history?.total_sell) || 0} />
						<p className={`${history?.id == currentHistory?.id ? "text-red-600" : "text-[#373737]"} whitespace-nowrap  text-sm`}>
							SL&nbsp;
							<span className="font-semibold text-sm">
								₹{(history?.stoploss && history?.stoploss != 'null') ? JSON.parse(history?.stoploss)?.stoploss : 0}
							</span>
						</p>
						<p className={`${history?.id == currentHistory?.id
							? "text-green-600"
							: "text-[#373737]"
							}   whitespace-nowrap  text-sm`}>
							TP&nbsp;
							<span className="font-semibold">
								₹{(history?.stoploss && history?.stoploss != 'null') ? JSON.parse(history?.stoploss)?.target : 0}{" "}
							</span>
						</p>
					</div>

					<h6 className={`${history?.profit_loss < 0 ? "text-red-600" : "text-green-600"} font-semibold text-sm`}>
						{Number(
							Math.abs(history?.profit_loss)?.toFixed(2)
						).toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</h6>
				</div>
				<div className="w-full">
					<p className="text-[#9E9E9E] font-semibold text-sm">{moment(history?.updatedatist).format('DD/MM/YYYY hh:mm A')}</p>
				</div>
			</div>
		</>
	);
};

export const BasketPositionCard = ({
	position,
	selectPosition,
	exitBasket,
	isLoading,
	pl
}) => {
	const { currentPosition } = useSelector((state) => state.Baskets);

	return (
		<>
			<div
				onClick={() => selectPosition()}
				className={`w-full group cursor-pointer p-3 flex flex-col gap-3 border border-transparent border-b-[#DEDEDE] border-r-[5px] ${position?.id == currentPosition?.id
					? " border-r-blue-500 "
					: "border-r-transparent hover:border-r-gray-300 "
					} `}
			>
				<div className="w-full flex items-center justify-between">
					{/* <BasketIcon /> */}
					<h5
						className={`${position?.quantity == 0
							? "text-gray-400"
							: position?.id == currentPosition?.id
								? "text-blue-600"
								: "text-[#000000]"
							}  whitespace-nowrap text-sm lg:text-sm font-semibold text-ellipsis w-3/5 overflow-hidden`}
					>
						{position?.name}
					</h5>
					<div className="flex items-center gap-3">
						{position?.quantity == 0 ? (
							position?.hit ? (
								position?.hit == "sl" ? (
									<SL />
								) : (
									<T />
								)
							) : (
								<M />
							)
						) : null}
						{position?.open_orders
							? JSON.parse(position?.open_orders)?.length > 0 && (
								<MoreHorizIcon sx={{ color: "#FA6A4B" }} />
							)
							: null}
						{position?.quantity == 0 ? (
							<>
								<p className="hidden">{position?.quantity}</p>
							</>
						) : (
							<div className="hidden group-hover:flex items-center gap-2">
								<PositionButton
									size="small"
									variant="contained"
									color="error"
									loading={isLoading}
									onClick={(e) => {
										e.preventDefault();
										exitBasket(position);
									}}
								>
									EXIT
								</PositionButton>
							</div>
						)}
					</div>
				</div>
				<div className="w-full flex items-center justify-between">
					<div className="flex gap-3 items-center">
						<Chip
							size="small"
							variant="filled"
							label={
								position?.orders ? JSON.parse(position?.orders).length : null
							}
						/>
						<p className={`${position?.quantity == 0
							? "text-gray-400"
							: position?.id == currentPosition?.id
								? "text-red-600"
								: "text-[#373737]"
							}   whitespace-nowrap  text-sm lg:text-xs`}>
							SL{" "}
							<span className="font-semibold">
								₹
								{position?.stoploss?.stoploss != undefined
									? position?.stoploss?.stoploss
									: "- -"}
							</span>
						</p>
						<p className={`${position?.quantity == 0
							? "text-gray-400"
							: position?.id == currentPosition?.id
								? "text-green-600"
								: "text-[#373737]"
							}   whitespace-nowrap  text-sm lg:text-xs`}>
							TP{" "}
							<span className="font-semibold">
								₹
								{position?.stoploss?.target != undefined
									? position?.stoploss?.target
									: "- -"}
							</span>
						</p>
					</div>
					{pl !== null && pl !== undefined ? (
						<h6 className={`${pl <= 0 ? "text-green-600" : "text-red-600"} font-semibold text-sm lg:text-sm`}>
							{Number(Math.abs(pl)?.toFixed(2)).toLocaleString(
								undefined,
								{
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								}
							)}
						</h6>
					) : (
						<Skeleton variant="text" width={40} sx={{ fontSize: "1rem" }} />
					)}
				</div>
			</div>
		</>
	);
};

export const BasketPositionTotal = ({ finalPL }) => {
	return (
		<>
			<div className="w-full flex justify-between items-center px-4 py-3 bg-blue-100">
				<p className="text-sm text-slate-600">P/L</p>
				<p
					className={`${finalPL <= 0 ? "text-green-600" : "text-red-600"
						} font-bold tracking-wide`}
				>
					{/* {Math.abs(finalPL)?.toFixed(2)} */}
					{Number(Math.abs(finalPL)?.toFixed(2)).toLocaleString(undefined, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					})}
				</p>
			</div>
		</>
	);
};

// POSITION LEG CARD
export const LegsCard = ({ leg, market, returnLTP, onAdd, onExitOrder }) => {
	function calculateValue(order) {
		const instrumentLTP = returnLTP(order?.instrument_token);
		const profitLoss = parseFloat(order.profit_loss);
		const totalQuantity = parseFloat(order.total_quantity);

		if (
			!Number.isNaN(profitLoss) &&
			!Number.isNaN(totalQuantity) &&
			!Number.isNaN(instrumentLTP)
		) {
			// Calculate the profit/loss for the order
			const calculatedValue = Math.abs(
				profitLoss - totalQuantity * instrumentLTP
			);

			// Format the calculated value
			return Number(calculatedValue.toFixed(2)).toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
		}

		// Return 'Invalid Value' if the values are not valid numbers
		return "Invalid Value";
	}

	return (
		<div
			className={
				"max-w-full w-full  group transition ease-in-out hover:shadow-lg rounded cursor-pointer duration-100 bg-[#FFFFFF] p-4 flex flex-col items-start gap-2"
			}
		>
			<div className={"flex w-full justify-between items-center "}>
				<div className="w-full flex items-center justify-between ">
					<div
						className={`
     ${leg.total_quantity == 0
								? "bg-transparent border-none "
								: leg?.tradingsymbol?.includes("PE")
									? "bg-[#FFF4F4] border-[#D4151585]"
									: "bg-[#F1FBF6] border-[#00B38652]"
							}
   `}
					>
						<p
							className={`text-sm tracking-wide font-semibold cursor-pointer ${leg.total_quantity == 0
								? "text-gray-400 p-0"
								: leg?.tradingsymbol.includes("PE")
									? "text-[#D41515] p-2"
									: "text-[#00B386] p-2"
								}  `}
						>
							{leg?.tradingsymbol}
						</p>
					</div>
					{leg?.total_quantity != 0 ? (
						<div className="hidden group-hover:flex items-center gap-2">
							<PositionButton
								size="small"
								variant="contained"
								color="success"
								onClick={() => onAdd(leg)}
							>
								ADD
							</PositionButton>
							<PositionButton
								size="small"
								variant="contained"
								color="error"
								onClick={() => onExitOrder(leg)}
							>
								EXIT
							</PositionButton>
						</div>
					) : null}
				</div>
			</div>
			<div className="flex justify-between w-full">
				<div className="flex items-center gap-2">
					{leg?.total_quantity == 0 ? (
						<p className={`text-gray-400 font-medium text-xs lg:text-sm tracking-wide`}>
							{leg?.last_transaction_type === 'SELL' ? 'BUY' : 'SELL'} &nbsp;
							<span className="font-bold text-[#000000]">
								{Math.abs(leg?.total_quantity)}
							</span>
						</p>
					) : (
						leg?.total_quantity > 0 ? (
							<p className={`text-green-600 font-medium text-xs lg:text-sm tracking-wide`}>
								{"BUY"} &nbsp;
								<span className="font-bold text-[#000000]">
									{Math.abs(leg?.total_quantity)}
								</span>
							</p>
						) :
							<p className={`text-red-600 font-medium text-xs lg:text-sm tracking-wide`}>
								{"SELL"} &nbsp;
								<span className="font-bold text-[#000000]">
									{Math.abs(leg?.total_quantity)}
								</span>
							</p>
					)}
					{leg.limit && (
						<p className="text-[#000000] font-medium text-xs lg:text-sm tracking-wide">
							LMT. <span className="font-bold">{leg?.limit}</span>
						</p>
					)}
				</div>

				{leg?.total_quantity === 0 ? (
					leg?.profit_loss ? (
						leg?.profit_loss <= 0 ? (
							<p className="text-green-600 font-bold text-sm lg:text-sm tracking-wide">
								{Math.abs(leg?.profit_loss)?.toFixed(2)}
							</p>
						) : (
							<p className="text-red-600 font-bold text-sm lg:text-sm tracking-wide">
								{Math.abs(leg?.profit_loss)?.toFixed(2)}
							</p>
						)
					) : (
						leg?.exit_index ?
							<p className="text-green-600 font-bold text-sm lg:text-sm tracking-wide">
								0.00
							</p> :
							<Skeleton variant="text" width={30} sx={{ fontSize: "1rem" }} />
					)
				) : market?.instruments &&
					market?.instruments[`'${leg?.instrument_token}'`] ? (
					-leg.profit_loss +
						leg.total_quantity * returnLTP(leg.instrument_token) >=
						0 ? (
						<p className="text-green-600 font-semibold text-sm lg:text-sm tracking-wide">
							{calculateValue(leg)}
						</p>
					) : (
						<p className="text-red-600 font-semibold text-sm lg:text-sm tracking-wide">
							{calculateValue(leg)}
						</p>
					)
				) : (
					<Skeleton variant="text" width={30} sx={{ fontSize: "1rem" }} />
				)}

				{/* {market?.instruments &&
        market?.instruments[`'${leg?.instrument_token}'`] ? (
          -leg.profit_loss +
            leg.total_quantity * returnLTP(leg.instrument_token) >=
          0 ? (
            <p className="text-green-600 font-semibold text-sm lg:text-sm  tracking-wide">
              {calculateValue(leg)}
            </p>
          ) : (
            <p className="text-red-600 font-semibold text-sm lg:text-sm  tracking-wide">
              {calculateValue(leg)}
            </p>
          )
        ) : leg?.profit_loss ? (
          leg?.profit_loss <= 0 ? (
            <p className="text-green-600 font-bold text-sm lg:text-sm tracking-wide">
              {Math.abs(leg?.profit_loss)?.toFixed(2)}
            </p>
          ) : (
            <p className="text-red-600 font-bold text-sm lg:text-sm tracking-wide">
              {Math.abs(leg?.profit_loss)?.toFixed(2)}
            </p>
          )
        ) : (
          <Skeleton variant="text" width={30} sx={{ fontSize: "1rem" }} />
        )} */}
			</div>
			<div className="w-full flex items-center justify-between">
				<div className="w-full flex items-center flex-wrap gap-2   py-1">
					{leg?.entry_ltp ? (
						<p className="text-[#373737] bg-gray-100 p-1 px-2 rounded-[2px]  text-[11px] tracking-wide">
							ENT L.{" "}
							<span className="text-black-600 font-bold">{leg?.entry_ltp}</span>
						</p>
					) : null}
					{leg?.exit_ltp ? (
						<p className="text-[#373737] bg-gray-100 p-1 px-2 rounded-[2px] text-[11px] tracking-wide">
							EX L.{" "}
							<span className="text-black-600 font-bold">{leg?.exit_ltp}</span>
						</p>
					) : null}
					{leg?.entry_index ? (
						<p className="text-[#373737] bg-gray-100 p-1 px-2 rounded-[2px] text-[11px] tracking-wide">
							ENT I.{" "}
							<span className="text-black-600 font-bold">
								{leg?.entry_index}
							</span>
						</p>
					) : null}
					{leg?.exit_index ? (
						<p className="text-[#373737] bg-gray-100 p-1 px-2 rounded-[2px] text-[11px] tracking-wide">
							EX I.{" "}
							<span className="text-black-600 font-bold">
								{leg?.exit_index}
							</span>
						</p>
					) : null}
				</div>
				<h6 className="text-[#000000] text-sm lg:text-sm font-bold">
					{returnLTP(leg.instrument_token)?.toFixed(2)}
					{/* {returnLTP(leg.instrument_token) ? (
            returnLTP(leg.instrument_token)?.toFixed(2)
          ) : (
            <Skeleton variant="text" width={30} sx={{ fontSize: "1rem" }} />
          )} */}
				</h6>
			</div>
		</div>
	);
};

export const HistoryLegsCard = ({ leg }) => {
	return (
		<div className={"max-w-full w-full group transition ease-in-out rounded duration-100 bg-[#FFFFFF] p-4 flex flex-col items-start gap-2 border-b-[0.5px] hover:bg-[#fafafa]"}>
			<div className={"flex w-full justify-between items-center "}>
				<div className="w-full flex items-center justify-between ">
					<div
						className={`flex items-center gap-3 ${leg?.tradingsymbol?.includes("PE")
							? "bg-[#FFF4F4]"
							: " bg-[#F1FBF6]"
							} border ${leg?.tradingsymbol?.includes("PE")
								? "border-[#D4151585]"
								: "border-[#00B38652]"
							} `}
					>
						<p
							className={`text-sm tracking-wide font-semibold cursor-pointer ${leg.tradingsymbol?.includes("PE")
								? "text-[#D41515]"
								: "text-[#00B386]"
								}  p-2`}
						>
							{leg?.tradingsymbol}
						</p>
					</div>
				</div>
			</div>
			<div className="flex justify-between w-full">
				<div className="flex items-center gap-2">
					<p className={`${leg?.transaction_type == "BUY" ? "text-green-600" : "text-red-600"} text-xs lg:text-sm tracking-wide font-bold`}>
						{leg?.transaction_type} &nbsp;
						<span className="font-bold text-[#000000]">
							{Math.abs(leg?.quantity)}
						</span>
					</p>
				</div>
				<p className="text-xs font-semibold text-gray-700">{leg.order_type}</p>
				{/* <p
					className={`${leg?.profit_loss < 0 ? "text-red-600" : "text-green-600"
						} font-bold text-sm lg:text-base tracking-wide`}
				>
					{Number(Math.abs(leg?.profit_loss)?.toFixed(2)).toLocaleString(
						undefined,
						{
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}
					)}
				</p> */}
			</div>
			{leg.ltp && (
				<div className="w-full flex items-center justify-between">
					<div className="w-full flex items-center flex-wrap gap-2 py-1">
						{leg?.type?.toLowerCase() == 'entry' ? (
							<p className="text-[#373737] bg-gray-100 p-1 px-2 rounded-[2px]  text-[11px] tracking-wide">
								ENT L.{" "}
								<span className="text-black-600 font-bold">{leg?.ltp}</span>
							</p>
						) : null}
						{leg?.leg?.type?.toLowerCase() == 'exit' ? (
							<p className="text-[#373737] bg-gray-100 p-1 px-2 rounded-[2px] text-[11px] tracking-wide">
								EX L.{" "}
								<span className="text-black-600 font-bold">{leg?.ltp}</span>
							</p>
						) : null}
						{leg?.type?.toLowerCase() == 'entry' ? (
							<p className="text-[#373737] bg-gray-100 p-1 px-2 rounded-[2px] text-[11px] tracking-wide">
								ENT I.{" "}
								<span className="text-black-600 font-bold">
									{leg?.strike}
								</span>
							</p>
						) : null}
						{leg?.type?.toLowerCase() == 'exit' ? (
							<p className="text-[#373737] bg-gray-100 p-1 px-2 rounded-[2px] text-[11px] tracking-wide">
								EX I.{" "}
								<span className="text-black-600 font-bold">
									{leg?.strike}
								</span>
							</p>
						) : null}
					</div>
				</div>
			)}
		</div>
	);
};

const SL = () => (
	<div className="bg-[#FCCECE] px-2 py-1 w-10 rounded-sm ">
		<p className="text-[8px] font-bold tracking-widest text-center text-[#D41515]">
			SL
		</p>
	</div>
);

const T = () => (
	<div className="bg-[#00B3864F] bg-opacity-40 px-2 py-1 w-10 rounded-sm">
		<p className="text-[10px] font-bold tracking-widest text-center text-[#00B386]">
			T
		</p>
	</div>
);

const M = () => (
	<div className="bg-[#5367FF54] px-2 py-1 w-10 rounded-sm">
		<p className="text-[10px] font-bold tracking-widest text-center text-[#5367FF]">
			M
		</p>
	</div>
);

const PositionButton = styled(LoadingButton)(({ theme }) => ({
	minWidth: "22px",
	height: "20px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	fontSize: 10,
	borderRadius: 2,
}));
