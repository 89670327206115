import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';



const useStyles = makeStyles((theme) => ({
    root: {
        '& .css-lm0971-MuiDataGrid-root .MuiDataGrid-withBorderColor': {
            backgroundColor: '#FBFBFB !important',
        },
        '& .MuiDataGrid-root,::before, ::after ': {
            borderColor: 'transparent',
        },
        backgroundColor: '#FBFBFB !important',
    },
}));


const DashboardTable = (props) => {
    const classes = useStyles();

    const calculateHeight = () => {
        // You may need to adjust this calculation based on your specific requirements
        return props.rows.length * 50 + 110; // Assuming each row has a height of 50 and adding some extra space
    };

    return (
        <Box sx={{ height: calculateHeight(), width: '100%' }} >
            <DataGrid
                rows={props.rows}
                columns={props.columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                sx={{
                    bgcolor: 'white !important',
                    '& .MuiDataGrid-columnHeader': {
                        background: '#FBFBFB !important',
                        border: 'none !important',
                        pointerEvents:'none'
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none !important'
                    },
                    '& .MuiDataGrid-withBorderColor': {
                        borderColor: 'transparent !important',
                        bgcolor: '#FBFBFB'
                    },
                    '& .css-lm0971-MuiDataGrid-root': {
                        borderColor:'transparent !important',
                    },
                    '& .css-204u17-MuiDataGrid-main': {
                        bgcolor: '#FBFBFB  !important'
                    },
                    '& .css-1hu7l6g-MuiDataGrid-root': {
                        borderColor: 'red !important'
                    },
                    '& .css-lm0971-MuiDataGrid-root': {
                        borderColor: 'transparent !important',
                    },

                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
                disableColumnMenu
                disableDensitySelector
                disableVirtualization
                disableColumnSelector
                hideFooter
                className={classes.root}
            />
        </Box>
    );
}


export default DashboardTable