import * as React from 'react';
import { useNavigate } from "react-router-dom"
import AdminHeader from "../../PrivateComponents/AdminHeader"
import NewsArticle from "../../PublicComponents/NewsArticle"
import { http } from "../../Services/api.services"
import { useDispatch } from 'react-redux';
import { showToast } from '../../store/Actions/Action';
import PromptDialog from '../../PrivateComponents/PromptDialog';
import Loading from '../../PrivateComponents/Loading';

const News = () => {

    const navigate = useNavigate()
    const [news, setNews] = React.useState(null)
    const [showDelete, setShowDelete] = React.useState(false)
    const dispatch = useDispatch()

    const [current, setCurrent] = React.useState('') 

    React.useEffect(() => {
        fetchNews()
    }, [])

    const fetchNews = async () => {
        try {
            const response = await http.get('news')
            console.log(response.data)
            if (response.status == 200) {
                setNews(response.data)
            }
            else setNews([])
        }
        catch (e) {
            setNews([])
            console.log('ERROR WHILE FETCHING NEWS -- ', e)
        }
    }

    const publish = async (e, id) => {
        try {
            e?.stopPropagation()
            const body = {
                status: 'published'
            }
            const response = await http.patch(`news/${id}`, body)
            if (response.status == 200) {
                const _snack = {
                    message: 'News published successfully!',
                    type: 'success',
                    duration: 3000,
                    open: true
                }
                dispatch(showToast(_snack))
                fetchNews()
            }
            else {
                const _snack = {
                    message: 'Failed to publish news!',
                    type: 'error',
                    duration: 3000,
                    open: true
                }
                dispatch(showToast(_snack))
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const unpublish = async (e, id) => {
        try {
            e?.stopPropagation()
            const body = {
                status: 'unpublished'
            }
            const response = await http.patch(`news/${id}`, body)
            if (response.status == 200) {
                const _snack = {
                    message: 'News unpublished successfully!',
                    type: 'success',
                    duration: 3000,
                    open: true
                }
                dispatch(showToast(_snack))
                fetchNews()
            }
            else {
                const _snack = {
                    message: 'Failed to unpublish news!',
                    type: 'error',
                    duration: 3000,
                    open: true
                }
                dispatch(showToast(_snack))
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const onDelete = async (e, id) => {
        try {
            setCurrent(id)
            setShowDelete(true)
            e?.stopPropagation()
        }
        catch (e) {

        }
    }

    const deleteNews = async (id) => {
        try {
            const response = await http.delete(`news/${current}`)
            if(response.status == 200) {
                const _snack = {
                    message: 'News deleted successfully!',
                    type: 'success',
                    duration: 3000,
                    open: true
                }
                dispatch(showToast(_snack))
                fetchNews()
                setCurrent('')
                setShowDelete(false)
            }
        }
        catch (e) {
            const _snack = {
                message: 'Failed to delete!',
                type: 'error',
                duration: 3000,
                open: true
            }
            dispatch(showToast(_snack))
            console.log('ERROR IN DELETE NEWS -- ', e)
        }
    }

    return (
        <div className="w-full flex-1 px-4 py-6 flex flex-col">
            <AdminHeader text='News'
                onAdd={() => navigate('/admin/add-news')}
                ondateChange={(e) => console.log(e?.target?.value)}
                onchange={(e) => console.log(e?.target?.value)}
            />
            <div className="py-10 mx-4 lg:mx-24 gap-4 flex flex-col flex-1">
                {!news ? <Loading/> : !news.length ? <Loading message="No Data"/> : news?.map((_news, index) => (
                    <NewsArticle
                        key={index}
                        admin={true}
                        title={_news.title}
                        content={_news.snippet || _news.content}
                        cover={_news.cover}
                        createdAt={(_news.createdat)?.toString()}
                        category={_news.category}
                        status={_news.status}
                        onPublish={(e) => publish(e, _news.id)}
                        onUnpublish={(e) => unpublish(e, _news.id)}
                        onDelete={(e) => onDelete(e, _news.id)}
                        click={() => navigate('/admin/update-news/' + _news.id)}
                    />
                ))}
            </div>
            <PromptDialog
                open={showDelete}
                onClose={() => setShowDelete(false)}
                title={'Delete News?'}
                onSuccess={() => deleteNews()}
                message={'Do you really want to delete this post? This will permanently remove the post from everywhere'}
            />
        </div>
    )
}

export default News