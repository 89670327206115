import React from 'react'
import HeaderComponent from '../../EquityComponents/HeaderComponent'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useThemeContext } from '../../Theme/ThemeContext';
import { BarCharts } from '../../EquityComponents/BarCharts';
import { http } from '../../Services/api.services';
import { BASE_URL } from '../../default.config';
import { setCurrentAlgo } from '../../store/Reducer/EquityReducer';

const AlgoReport = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { theme } = useThemeContext();
    const _id = location.pathname.split('/')[3];
    const { currentAlgo } = useSelector((state) => state.equity);
    const [pnlData, setPnlData] = React.useState(null)
    const [betaData, setBetaData] = React.useState(null)
    const [roiData, setRoiData] = React.useState(null)
    const [wealthData, setWealthData] = React.useState(null)
    // GET ALGO REPORT DATA 
    React.useEffect(() => {
        getAlgoReportData(_id);
    }, [])

    console.log(currentAlgo, 'sss');

    const getAlgoReportData = async (algo_id) => {
        try {
            const response = await http.get(`${BASE_URL}algo_report/${algo_id}`)
            if (response?.status == 200) {
                setPnlData(response?.data?.pnl_array);
                setBetaData(response?.data?.roi_array);
                setRoiData(response?.data?.beta_array);
                setWealthData(response?.data?.wealth_creation_array);
                const _data = { ...currentAlgo, algo: response?.data?.algo, analytics: response?.data?.analytics }
                dispatch(setCurrentAlgo(_data));
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING ALGO REPORT DATA ${e}`);
        }
    }

    return (
        <>
            <div className="w-full flex-1   p-2 lg:p-4 flex flex-col ">
                <div style={{ background: theme.palette.background.white }} className="w-full  flex-1 flex flex-col  p-2 lg:p-6 shadow rounded">
                    {/* MAIN SECTION  */}
                    <div className="w-full py-4 flex flex-col items-start gap-4 lg:gap-6">
                        <HeaderComponent
                            title={currentAlgo?.algo?.name}
                            analytics={currentAlgo?.analytics}
                            // onApplyRatingFilter={(rating) => {
                            //     filterAlgoData(skip, limit, rating)
                            // }}
                            goBack={() => {
                                navigate(`/equity/algorithm`)
                            }}
                        />
                    </div>
                    <div className='w-full flex flex-wrap'>
                        <div className='w-[49%]'>
                            <BarCharts
                                data={pnlData}
                            />
                        </div>
                        <div className='w-[49%]'>
                            <BarCharts
                                data={roiData}
                            />
                        </div>
                        <div className='w-[49%]'>
                            <BarCharts
                                data={betaData}
                            />
                        </div>
                        <div className='w-[49%]'>
                            <BarCharts
                                data={roiData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AlgoReport
