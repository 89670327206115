import React from 'react'
import { H1, H2, H3, H4, H5, H6, P } from '../Theme/ThemeComponent'
import { useThemeContext } from '../Theme/ThemeContext'
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateSignUp } from '../store/Reducer/Reducer';

const HomePageStrikesImg = require('../assets/HomepageStrikesImg.png')
const HomePageEquityImg = require('../assets/HomepageEquity.png')

const Landing = () => {
  const dispatch = useDispatch();
  const { theme } = useThemeContext();
  const { signUp } = useSelector((state) => state.optionHome);

  const OptionsCell = ({ heading, desc }) => (
    <div className='w-full flex flex-col items-start gap-2 lg:gap-2'>
      <H4
        title={heading}
        color={theme.palette.text.charcolGray}
        font={'bold'}
      />
      <div className='leading-none w-full lg:w-10/12'>
        <H6
          title={desc}
          color={theme.palette.text.coolGray}
          font={'normal'}
        />
      </div>
    </div>
  )

  return (
    <>
      <div className='w-full  bg-[#FFFFFF] pt-24 '>
        <div className='w-full m-auto flex flex-col items-center gap-3 lg:gap-5 justify-center py-24 lg:py-36 border-b px-4 lg:px-20 '>
          <H2
            title={'Reach new heights of financial success'}
            color={theme.palette.text.charcolGray}
            font={'bold'}
          />
          <div className='text-center w-full lg:w-1/2 mb-8'>
            <H5
              title={`Algorithm Performance Analysis, Equity, Options, Bulk Orders, Market Analysis, Buy/Sell on a click, Basket Orders and more!`}
              color={theme.palette.text.coolGray}
              font={'normal'}
            />
          </div>

          <div className='flex items-center gap-4'>
            <Button
              variant="contained"
              color='blue'
              sx={{
                color: '#FFFFFF'
              }}
              onClick={() => {
                dispatch(updateSignUp(true))
              }}
            >
              Go to Equity
            </Button>
            <Button
              variant="contained"
              color='blue'
              sx={{
                color: '#FFFFFF'
              }}
              onClick={() => {
                dispatch(updateSignUp(true))
              }}
            >
              Go to Options
            </Button>
          </div>
        </div>

        <div className='w-full py-20 border-b flex flex-col items-start gap-8 lg:gap-12 px-4 lg:px-20 '>
          <div className='w-full  flex flex-col items-center gap-3 lg:gap-5 justify-center '>
            <H2
              title={'Seamless options trading'}
              color={theme.palette.text.charcolGray}
              font={'bold'}
            />
            <div className='text-center w-full lg:w-1/2 mb-8'>
              <H5
                title={`Access the industry's best tools and expert insights to achieve your financial goals. Get fast, reliable execution when you trade options on our web platform.`}
                color={theme.palette.text.coolGray}
                font={'normal'}
              />
            </div>
          </div>
          <div className='w-full flex flex-col lg:flex-row gap-12 lg:gap-0'>
            <div className='w-full lg:w-1/2  '>
              <img className='w-full h-full object-cover' src={HomePageStrikesImg} alt="HomePageStrikesImg" />
            </div>
            <div className='w-full lg:w-1/2 flex flex-col items-start gap-6 lg:gap-6'>
              <OptionsCell
                heading={`Quick buy-sell execution with stop-loss protection.`}
                desc={`Instantly place buy or sell orders, and set predefined stop-loss levels that trigger a sale when the asset’s price hits a certain limit, minimizing potential losses. `}
              />
              <OptionsCell
                heading={`IHM Chart - Evaluate performance multiple chart`}
                desc={`Instantly place buy or sell orders, and set predefined stop-loss levels that trigger a sale when the asset’s price hits a certain limit, minimizing potential losses. `}
              />
              <OptionsCell
                heading={`SLC - Spot line charts of indexes with top five companies `}
                desc={`Instantly place buy or sell orders, and set predefined stop-loss levels that trigger a sale when the asset’s price hits a certain limit, minimizing potential losses. `}
              />
            </div>
          </div>
        </div>

        <div className='w-full py-20 border-b flex flex-col items-start gap-8 lg:gap-12 px-4 lg:px-20 '>
          <div className='w-full  flex flex-col items-center gap-2 lg:gap-5 justify-center '>
            <H2
              title={'Smooth equity transactions'}
              color={theme.palette.text.charcolGray}
              font={'bold'}
            />
            <div className='text-center w-full lg:w-1/2 mb-8'>
              <H5
                title={`Access the industry's best tools and expert insights to achieve your financial goals.`}
                color={theme.palette.text.coolGray}
                font={'normal'}
              />
            </div>
          </div>
          <div className='w-full flex flex-col lg:flex-row-reverse gap-12 lg:gap-0 '>
            <div className='w-full lg:w-1/2 '>
              <img className='w-full h-full object-cover' src={HomePageEquityImg} alt="HomePageEquityImg" />
            </div>
            <div className='w-full lg:w-1/2 flex flex-col items-start gap-6 lg:gap-6'>
              <OptionsCell
                heading={`Quick buy-sell execution with stop-loss protection.`}
                desc={`Instantly place buy or sell orders, and set predefined stop-loss levels that trigger a sale when the asset’s price hits a certain limit, minimizing potential losses. `}
              />
              <OptionsCell
                heading={`Quick buy-sell execution with stop-loss protection.`}
                desc={`Instantly place buy or sell orders, and set predefined stop-loss levels that trigger a sale when the asset’s price hits a certain limit, minimizing potential losses. `}
              />
              <OptionsCell
                heading={`Quick buy-sell execution with stop-loss protection.`}
                desc={`Instantly place buy or sell orders, and set predefined stop-loss levels that trigger a sale when the asset’s price hits a certain limit, minimizing potential losses. `}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Landing
