import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, LinearProgress, TextField } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import moment from "moment/moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F0F8FF",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "#262626",
  },
  [`&.${tableCellClasses.root}`]: {
    border: "1px solid rgba(158, 158, 158, 0.12)",
    padding: "10px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  width: "100%",
}));

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

VirtuosoTableComponents.Scroller.displayName = "ref1";
VirtuosoTableComponents.TableBody.displayName = "ref2";

function vHeaderContent(vwap, rsi, ema, sma, perChange, bb, showOI) {
  return (
    <>
      <StyledTableCell align="right">
        <p className="text-sm text-[#373737] font-normal">DATE & Time</p>   
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-sm text-[#373737] font-normal">OPEN</p>
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-sm text-[#373737] font-normal">HIGH</p>
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-sm text-[#373737] font-normal">LOW</p>
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-sm text-[#373737] font-normal">CLOSE</p>
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-sm text-[#373737] font-normal">VOLUME</p>
      </StyledTableCell>
      {showOI ? (
        <StyledTableCell align="right">
          <p className="text-sm text-[#373737] font-normal">OI</p>
        </StyledTableCell>
      ) : null}
      {vwap ? (
        <StyledTableCell align="right">
          <p className="text-sm text-[#373737] font-normal">VWAP</p>
        </StyledTableCell>
      ) : null}
      {rsi ? (
        <StyledTableCell align="right">
          <p className="text-sm text-[#373737] font-normal">RSI</p>
        </StyledTableCell>
      ) : null}
      {perChange ? (
        <StyledTableCell align="right">
          <p className="text-sm text-[#373737] font-normal">% Change</p>
        </StyledTableCell>
      ) : null}
      {ema?.length
        ? ema?.map((_ema, index) => (
            <StyledTableCell key={index} align="right">
              <p className="text-sm text-[#373737] font-normal">{_ema} EMA</p>
            </StyledTableCell>
          ))
        : null}
      {sma?.length
        ? sma?.map((_sma, index) => (
            <StyledTableCell key={index} align="right">
              <p className="text-sm text-[#373737] font-normal">{_sma} SMA</p>
            </StyledTableCell>
          ))
        : null}
      {bb?.length
        ? bb?.map((_bb, index) => (
            <StyledTableCell
              key={index}
              align="right"
              width={175}
              className="!py-0 !px-2"
            >
              <div className="flex-1 flex justify-between items-center">
                <p className="text-xs text-[#262626] font-normal">low</p>
                <p className="text-sm text-[#373737] font-normal">{_bb} BOL</p>
                <p className="text-xs text-[#262626] font-normal">up</p>
              </div>
            </StyledTableCell>
          ))
        : null}
    </>
  );
}

function vRowContent(index, row, vwap, rsi, ema, sma, perChange, bb, showOI) {
  return (
    <>
      <StyledTableCell align="right">
        <p className="text-xs text-[#424242]">
          {moment(row?.date).format(`DD-MM-YY HH:mm`)}
        </p>
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-xs text-[#262626]">
          {row?.open?.toLocaleString("en-US", { minimumFractionDigits: 2 })}
        </p>
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-xs text-[#262626]">
          {row?.high?.toLocaleString("en-US", { minimumFractionDigits: 2 })}
        </p>
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-xs text-[#262626]">
          {row?.low?.toLocaleString("en-US", { minimumFractionDigits: 2 })}
        </p>
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-xs text-[#262626]">
          {row?.close?.toLocaleString("en-US", { minimumFractionDigits: 2 })}
        </p>
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-xs text-[#262626]">
          {row?.volume?.toLocaleString("en-US", { minimumFractionDigits: 0 })}
        </p>
      </StyledTableCell>
      {showOI ? (
        <StyledTableCell align="right">
          <p className="text-xs text-[#262626]">
            {row?.oi?.toLocaleString("en-US", { minimumFractionDigits: 0 })}
          </p>
        </StyledTableCell>
      ) : null}
      {vwap ? (
        <StyledTableCell align="right">
          <p className="text-xs text-[#262626]">
            {row?.vwap?.toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </p>
        </StyledTableCell>
      ) : null}
      {rsi ? (
        <StyledTableCell align="right">
          <p className="text-xs text-[#262626]">
            {row?.rsi?.toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </p>
        </StyledTableCell>
      ) : null}
      {perChange ? (
        <StyledTableCell
          align="right"
          className={
            row?.perChange >= 2 && row?.perChange < 4
              ? "bg-green-100"
              : row?.perChange >= 4
              ? "bg-blue-100"
              : ""
          }
        >
          <p className="text-xs text-[#262626]">
            {row?.perChange?.toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </p>
        </StyledTableCell>
      ) : null}
      {ema?.length
        ? ema?.map((_ema, index) => (
            <StyledTableCell key={index} align="right">
              <p className="text-xs text-[#262626]">
                {row["ema_" + _ema]?.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </p>
            </StyledTableCell>
          ))
        : null}
      {sma?.length
        ? sma?.map((_sma, index) => (
            <StyledTableCell key={index} align="right">
              <p className="text-xs text-[#262626]">
                {row["sma_" + _sma]?.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
              </p>
            </StyledTableCell>
          ))
        : null}
      {bb?.length
        ? bb?.map((_bb, index) => (
            <StyledTableCell
              key={index}
              align="right"
              width={175}
              className="!py-0 !px-2"
            >
              <div className="flex-1 flex justify-between items-center">
                <p className="text-xs text-[#262626]">
                  {row["bb_" + _bb]?.lower?.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </p>
                <p className="text-xs text-[#262626]">
                  {row["bb_" + _bb]?.upper?.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </p>
              </div>
            </StyledTableCell>
          ))
        : null}
    </>
  );
}

export default function TechnicalIndicatorsTable(props) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        maxWidth: props.width,
        height: "100%",
        position: "relative",
      }}
    >
      <TableVirtuoso
        data={props.datasource}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() =>
          vHeaderContent(
            props.vwap,
            props.rsi,
            props.ema,
            props.sma,
            props.perChange,
            props.bb,
            props.showoi
          )
        }
        itemContent={(index, row) =>
          vRowContent(
            index,
            row,
            props.vwap,
            props.rsi,
            props.ema,
            props.sma,
            props.perChange,
            props.bb,
            props.showoi
          )
        }
        totalCount={200}
      />
      {props?.datasource?.length === 0 ? (
        <div className="absolute top-10 bottom-0 right-0 left-0 flex justify-center items-center">
          <h1 className="text-sm">NO DATA!</h1>
        </div>
      ) : null}
      {!props?.datasource ? (
        <div className="absolute top-10 bottom-0 right-0 left-0 flex flex-col justify-center items-center">
          <p>Loading please wait... {props.symbol}</p>
          <LinearProgress sx={{ width: "85%" }} />
        </div>
      ) : null}
    </TableContainer>
  );
}
