import React from "react";
import { useNavigate } from "react-router-dom";
import GitHubIcon from '@mui/icons-material/GitHub';
import { useThemeContext } from '../Theme/ThemeContext'
import { H4, H5, H6, P } from "../Theme/ThemeComponent";
import { Button, Checkbox, } from '@mui/material'
import { useDispatch } from "react-redux";
import { setCurrentAlgo, } from "../store/Reducer/EquityReducer";

const AlgoCard = ({ bg, algo }) => {
  const { theme } = useThemeContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSubscribed = false

  const Fields = ({ heading, value }) => (
    <div className={`flex items-center gap-1 p-1 px-3 bg-[${algo?.color}] shadow-sm`}>
      <P title={heading} color={theme.palette.text.charcolGray} font="bold" />
      <P title={value} color={heading == 'ROI' ? theme.palette.text.green : theme.palette.text.charcolGray} font="bold" />
    </div>
  )

  return (
    <>
      <div
        onClick={() => {
          navigate(`${algo?.id}`);
          dispatch(setCurrentAlgo(algo))
        }}
        className={`w-full cursor-pointer shrink-0  shadow flex flex-col items-start justify-center gap-3  p-4 px-5  rounded bg-[${algo?.color}]`}
      >
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center gap-3">
            <GitHubIcon sx={{ color: theme.palette.text.charcolGray }} />
            {/* <h6 className="text-base lg:text-lg font-semibold">{algo?.title}</h6> */}
            <H4 title={algo?.title} color={theme.palette.text.charcolGray} font='bold' />
          </div>
          <div className={`flex items-center ${isSubscribed ? '' : 'gap-2'}`}>
            {isSubscribed ?
              <Checkbox disabled checked /> :
              <P
                title={'$ 99,999 / Month'}
                font="bold"
                color={theme.palette.text.charcolGray}
              />
            }
            <Button
              variant='contained'
              size='small'
              disabled={isSubscribed}
              sx={{
                fontWeight: 'normal',
                fontSize: '10px',
                borderRadius: 0.4
              }}
            >
              {isSubscribed ? 'Subscribed' : 'Subscribe'}
            </Button>
          </div>
        </div>
        <P
          title={algo?.description}
          color={theme.palette.text.coolGray}
        />
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Fields
              heading='ROI'
              value={algo?.average_roi ? algo?.average_roi?.toFixed(2) : '--'}
            />
            <Fields
              heading='Open Long Trades'
              value={algo?.open_long_trade ? algo?.open_long_trade : '--'}
            />
            <Fields
              heading='Open Short Trades'
              value={algo?.open_short_trade ? algo?.open_long_trade : '--'}
            />
            <Fields
              heading='Closed Trades'
              value={algo?.closed_trade}
            />
          </div>
          <H6
            title={'$' + algo?.profit ? algo?.profit : ''}
            color={theme.palette.text.charcolGray}
            font={'bold'}
          />
        </div>
      </div>
    </>
  );
};

export default AlgoCard;
