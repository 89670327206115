import { json, redirect } from 'react-router-dom';
import { BASE_URL, ENDPOINTS } from "../default.config"
import axios from "axios"

export class ApiService {

    login = async (body) => {
        try {
            const URL = `${BASE_URL}user/login`
            const options = {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const response = await fetch(URL, options)
            return await response.json()
        }
        catch (e) {
            console.log(`ERROR IN LOGIN --- ${e}`, e)
            throw e
        }
    }

    logout = async (token) => {
        try {
            const URL = `${BASE_URL}user/logout`
            const options = {
                method: 'POST',
                body: JSON.stringify(token),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            // console.log(options);
            const response = await fetch(URL, options)
            return await response.json()
        }
        catch (e) {
            console.log(`ERROR IN LOGOUT --- ${e}`, e)
            throw e
        }
    }

    register = async (body) => {
        try {
            const URL = `${BASE_URL}user/register`
            const options = {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const response = await fetch(URL, options)
            return await response.json()
        }
        catch (e) {
            console.log(`ERROR IN LOGIN --- ${e}`, e)
            return e
        }
    }

    sendOtp = async (mobileNumber) => {
        try {
            const URL = `${BASE_URL}user/send-otp`
            const options = {
                method: 'POST',
                body: JSON.stringify(mobileNumber),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const response = await fetch(URL, options)
            return await response.json()
        }
        catch (e) {
            console.log(`ERROR IN SENDING OTP --- ${e}`, e)
            return e
        }
    }

    varifyOtp = async (otp) => {
        // console.log(otp);
        try {
            const URL = `${BASE_URL}user/verify-otp`
            const options = {
                method: 'POST',
                body: JSON.stringify(otp),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const response = await fetch(URL, options)
            return await response.json()
        }
        catch (e) {
            console.log(`ERROR IN VERIFYING OTP --- ${e}`, e)
            return e
        }
    }

    authBroker = async (userId, request_token, admin) => {
        try {
            const URL = admin ? `${BASE_URL}admin/connect/${userId}` : `${BASE_URL}user/connect/${userId}`;
            const options = {
                method: 'POST',
                body: JSON.stringify({ request_token }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const response = await fetch(URL, options);
            return await response.json();
        } catch (e) {
            console.log(`ERROR IN GET AUTH BROKER --- ${e}`, e);
            return e;
        }
    }

    getUserAuthStatus = async (userId) => {
        try {
            const URL = `${BASE_URL}user/auth-status/${userId}`;
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                    // You may include other headers like authorization token if needed
                }
            };
            const response = await fetch(URL, options);
            return await response.json();
        } catch (e) {
            console.log(`ERROR IN GET USER AUTH STATUS --- ${e}`, e);
            return e;
        }
    }

    askQuery = async (data) => {
        try {
            const URL = `${BASE_URL}ask-query`
            const options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const response = await fetch(URL, options)
            return await response.json()
        }
        catch (e) {
            console.log(`ERROR IN LOGIN --- ${e}`, e)
            throw e
        }
    }

}

class HttpService {

    http = axios.create({
        baseURL: BASE_URL
    })
    token = localStorage.getItem('token');

    updateToken = (_token) => {
        this.token = _token
    }
    constructor() {
        this.http.interceptors.request.use((config) => {
            //SUCCUSS
            // config.headers.Authorization = `${token}`
            // config.headers['Origin'] = window?.location?.host
            // config.headers['Content-Type'] = 'application/json'
            return config
        }, (error) => {
            // ERROR
            return Promise.reject(error)
        })
    }

    get(endpoint) {
        // GET REQUEST
        return this.http.get(endpoint)
    }

    post(endpoint, body) {
        // POST REQUEST with a request body (body parameter)
        return this.http.post(endpoint, body)
    }

    put(endpoint, body) {
        // GET REQUEST
        return this.http.put(endpoint, body)
    }

    remove(endpoint) {
        // REMOVE REQUEST
        return this.http.remove(endpoint)
    }

    patch(endpoint, body) {
        // PATCH REQUEST with a request body (body parameter)
        return this.http.patch(endpoint, body)
    }

    delete(endpoint) {
        return this.http.delete(endpoint)
    }


}


export const http = new HttpService()


export const yyyymmdd = (date) => {
    const _date = {
        y: new Date(date).getFullYear(),
        m: new Date(date).getMonth() + 1 < 10 ? `0${new Date(date).getMonth() + 1}` : new Date(date).getMonth(),
        d: new Date(date).getDate() < 10 ? `0${new Date(date).getDate()}` : new Date(date).getDate()
    }

    return `${_date.y}-${_date.m}-${_date.d}`
}

export const colorshades = (baseColor) => {
    const generateColorShades = (baseColor, numberOfShades) => {
        const parsedColor = parseColor(baseColor);
        const shades = [];

        for (let i = 1; i <= numberOfShades; i++) {
            const shade = {
                r: Math.floor(parsedColor.r - i * (parsedColor.r / numberOfShades)),
                g: Math.floor(parsedColor.g - i * (parsedColor.g / numberOfShades)),
                b: Math.floor(parsedColor.b - i * (parsedColor.b / numberOfShades)),
            };

            shades.push(rgbToHex(shade.r, shade.g, shade.b));
        }

        return shades;
    };

    // Function to parse the hex color code to extract values
    const parseColor = (color) => {
        const match = color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
        return match
            ? {
                r: parseInt(match[1], 16),
                g: parseInt(match[2], 16),
                b: parseInt(match[3], 16),
            }
            : null;
    };

    // Function to convert RGB values to HEX color code
    const rgbToHex = (r, g, b) =>
        `#${(1 << 24 | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;

    // Generate color shades
    const numberOfShades = 5; // Change this value as needed
    const shades = generateColorShades(baseColor, numberOfShades);
    return shades
}

// Utility function to trim the string
export const trimString = (str, length) => {
    if (!str) return '';
    return str.length > length ? `${str.substring(0, length)}...` : str;
};
