import * as React from 'react'
import SuccessLogo from '../assets/success-1.gif'
import { useLocation, useNavigate } from 'react-router-dom';

const Success = () => {
    const navigate = useNavigate()
    const location = useLocation()

    React.useEffect(() => {
        if (location.hash) {
            setTimeout(() => {
                navigate('/')
            }, 5000);
        }
        else {
            setTimeout(() => {
                navigate('/index-options/strikes')
            }, 5000);
        }
    }, [location.hash])

    return (
        <div className="h-full w-full flex flex-col justify-center items-center bg-[#f6f6f6]">
            <img
                src={SuccessLogo}
                height={200}
            />
            {/* <div className="px-4 text-center">
                <h1 className="text-lg text-[#333]">We’re verifying your payment. Once done, our team will confirm your registration and contact you for the next steps</h1>
            </div> */}
        </div>
    )
}

export default Success
