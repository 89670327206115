import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { useDimensions } from "../Contexts/DimensionContext/DimensionsContext";
// import http from 'path-to-your-http-module'; // Import your HTTP module
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const IhmGraph = (props) => {
  const dispatch = useDispatch();
  const { ihmChartData } = useSelector((state) => state.tools);
  const { sm, md, lg, xl, xxl } = useDimensions();
  const [dps, setDps] = useState(null);

  useEffect(() => {
    if (props.ihmData) {
      setDps(props.ihmData);
    }
  }, [props.ihmData]);

  const options = {
    height: props?.isFullScreen
      ? 500
      : sm
      ? 250
      : md
      ? 250
      : lg
      ? 250
      : xl
      ? 250
      : xxl
      ? 350
      : 450,
    title: {
      text: "",
    },
    backgroundColor: "transparent",
    axisX: {
      gridColor: "transparent",
      labelFontColor: "#bdbdbd",
      tickColor: "transparent",
      intervalType: "minute",
      interval: 0,
      lineColor: "#D2D2D2",
      valueFormatString: "HH:mm", // Display hours and minutes in the format you want
    },
    axisY: {
      gridColor: "transparent",
      // gridColor: (dps.length > 0 && dps[0].color) || 'transparent',
      tickColor: "transparent",
      lineColor: "#D2D2D2",
      labelFontColor: "#bdbdbd",
      interval: 1, // Set the desired interval for y-axis
      minimum: 0, // Set the minimum value for y-axis
      maximum: 5.5, // Set the maximum value for y-axis
      labelFormatter: function (e) {
        return dps && dps.length > 0 ? e.value : "";
      },  
    },
    data: [
      {
        type: "line",
        lineColor: "#000",
        markerSize: 6,
        lineThickness: 0.3,
        markerType: "circle",
        toolTipContent:
          "<span style='color: {color}; background-color: yellow;'></span> {y} ",
        dataPoints:
          dps && dps.length > 0
            ? dps?.map((dataPoint) => ({
                x: new Date(dataPoint.x),
                y: dataPoint.y,
                color: dataPoint.color,
              }))
            : [],
      },
    ],
  };  

  // console.log(options,'shshshshsh');
  
  const chartStyle = {
    width: "100%",
  };
  
  return (
    <div style={chartStyle}>
      {dps ? (
        dps?.length > 0 ? (
          <CanvasJSChart options={options} />
        ) : (
          <p className="text-center">no data </p>
        )
      ) : (
        <div className="w-full flex justify-center">
          <CircularProgress disableShrink />
        </div>
      )}
    </div>
  );
};

export default IhmGraph;
