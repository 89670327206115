import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { setUser } from "../store/Reducer/UserReducer";
import { ApiService, http } from "../Services/api.services";
import { asyncupdateBroker, reduxUpdateBrokers, showToast } from "../store/Actions/Action";
import { setAllALgos, setAllWatchlists, setIndustries } from "../store/Reducer/EquityReducer";
import { BASE_URL } from "../default.config";
const NetworkErrorImg = require("../assets/NetworkErroImg.png");

const api = new ApiService();

const UserAuth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);
    const [isNetworkError, setIsNetworkError] = React.useState(false);
    const [brokers, setBrokers] = React.useState([]);
    const [login_url, setLogin_url] = React.useState("");

    // FIRST THING FIRST -- FETCH USER DETAILS
    const fetchUser = async (id) => {
        try {
            const url = `user/${id}`;
            const response = await http.get(url);
            if (response.status == 200) {
                // Set user data directly to Redux
                dispatch(setUser(response.data)); // The response data will go into state.user
                setIsNetworkError(false);
                getAllWatchlist(response?.data?.id)
                if (!response.data.active) {
                    const _snackbar = {
                        message: "Broker Not Connected!",
                        open: true,
                        duration: 10000,
                        type: "error",
                    };
                    dispatch(showToast(_snackbar));
                }
            } else {
                setIsNetworkError(true);
            }
        } catch (e) {
            console.log(e + " - ERROR WHILE FETCHING DETAILS!");
            if (e.message == "Network Error") {
                setIsNetworkError(true);
            }
        }
    };


    // GET ALL WATCHLISTS 
    // React.useEffect(() => {
    //     getAllWatchlist();
    // }, []);

    const getAllWatchlist = async (id) => {
        try {
            const _apiUrl = `${BASE_URL}watchlist/${id}`
            const response = await http.get(_apiUrl);
            if (response.status == 200) {
                dispatch(setAllWatchlists(response?.data));
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING WATCHLISTS ${e}`);
        }
    };

    // FETCH ALL INDUSTRIES AND SECTORS 
    const fetchIndustries = async () => {
        try {
            const response = await http.get(`sector-and-industry`)
            if (response?.status == 200) {
                dispatch(setIndustries(response?.data))
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING INDUSTRIES ${e}`);

        }
    }

    // FETCH BROKERS
    const fetchBroker = async () => {
        try {
            const response = await http.get(`/brokers`);
            if (response.status == 200) {
                setBrokers(response.data);
                dispatch(reduxUpdateBrokers(response?.data));
            }
        } catch (e) {
            setBrokers([]);
            console.log("errow while fetching brokers");
        }
    };


    // USE EFFECT TO FETCH USER DETAILS 
    React.useEffect(() => {
        // CHECK FOR ADMIN LOGIN
        // market.setCurrentIndex("nifty");
        let _user = localStorage.getItem("user");
        _user = _user ? JSON.parse(_user) : null;
        if (_user) {
            fetchUser(_user.user_id);
            fetchBroker();
            fetchIndustries();
        } else navigate("/");
    }, []);

    React.useEffect(() => {
        if (user) {
            usrAuthStatus(user?.id);
        }
    }, [user]);

    // AUTH STATUS OF THE USER
    const usrAuthStatus = async (userId) => {
        try {
            const response = await api.getUserAuthStatus(userId);
            dispatch(asyncupdateBroker(response.active));
            if (!response.active) {
                setLogin_url(response.login_url);
                localStorage.setItem("auth-connect", response.login_url);
            }
        } catch (e) {
            console.log(`ERROR WHILE GETTING USER AUTH STATUS --- ${e}`);
        }
    };

    return (
        <>
            <div className="w-full h-full flex flex-col">
                {user ? (
                    <div className="flex-1 flex flex-col overflow-hidden">
                        <Outlet />
                    </div>
                ) : (
                    <div className="w-full h-full flex justify-center items-center">
                        {isNetworkError ? (
                            <div className="w-full flex flex-col items-center justify-center gap-2">
                                <img src={NetworkErrorImg} alt="NetworkErrorImg" />
                                <h1 className="text-2xl uppercase font-bold text-[#f6908e]">
                                    Network Error
                                </h1>
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        </>
    )
}

export default UserAuth