import { createSlice } from "@reduxjs/toolkit";
const SL = {
  type: "stoplossTarget",
  options: {
    NIFTY: {
      call: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
      put: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
    },
    BANKNIFTY: {
      call: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
      put: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
    },
    FINNIFTY: {
      call: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
      put: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
    },
    MIDCPNIFTY: {
      call: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
      put: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
    },
    SENSEX: {
      call: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
      put: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
    },
    BANKEX: {
      call: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
      put: {
        buy: {
          sl: 0,
          t: 0,
        },
        sell: {
          sl: 0,
          t: 0,
        },
      },
    },  
  },  
}; 

const initialState = SL;    

const SLReducer = (state = initialState, action) => {
  switch (action.payload.type) {
    // UPDATE NIFTY
    case "nifty-call-buy-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          NIFTY: {
            ...(state.options?.NIFTY || {}),
            call: {
              ...(state.options?.NIFTY?.call || {}),
              buy: {
                ...(state.options?.NIFTY?.call?.buy || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "nifty-call-buy-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          NIFTY: {
            ...(state.options?.NIFTY || {}),
            call: {
              ...(state.options?.NIFTY?.call || {}),
              buy: {
                ...(state.options?.NIFTY?.call?.buy || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "nifty-call-sell-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          NIFTY: {
            ...(state.options?.NIFTY || {}),
            call: {
              ...(state.options?.NIFTY?.call || {}),
              sell: {
                ...(state.options?.NIFTY?.call?.sell || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "nifty-call-sell-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          NIFTY: {
            ...(state.options?.NIFTY || {}),
            call: {
              ...(state.options?.NIFTY?.call || {}),
              sell: {
                ...(state.options?.NIFTY?.call?.sell || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "nifty-put-buy-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          NIFTY: {
            ...(state.options?.NIFTY || {}),
            put: {
              ...(state.options?.NIFTY?.put || {}),
              buy: {
                ...(state.options?.NIFTY?.put?.buy || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "nifty-put-buy-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          NIFTY: {
            ...(state.options?.NIFTY || {}),
            put: {
              ...(state.options?.NIFTY?.put || {}),
              buy: {
                ...(state.options?.NIFTY?.put?.buy || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "nifty-put-sell-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          NIFTY: {
            ...(state.options?.NIFTY || {}),
            put: {
              ...(state.options?.NIFTY?.put || {}),
              sell: {
                ...(state.options?.NIFTY?.put?.sell || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "nifty-put-sell-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          NIFTY: {
            ...(state.options?.NIFTY || {}),
            put: {
              ...(state.options?.NIFTY?.put || {}),
              sell: {
                ...(state.options?.NIFTY?.put?.sell || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    // UPDATE BANK
    case "banknifty-call-buy-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKNIFTY: {
            ...(state.options?.BANKNIFTY || {}),
            call: {
              ...(state.options?.BANKNIFTY?.call || {}),
              buy: {
                ...(state.options?.BANKNIFTY?.call?.buy || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "banknifty-call-buy-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKNIFTY: {
            ...(state.options?.BANKNIFTY || {}),
            call: {
              ...(state.options?.BANKNIFTY?.call || {}),
              buy: {
                ...(state.options?.BANKNIFTY?.call?.buy || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "banknifty-call-sell-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKNIFTY: {
            ...(state.options?.BANKNIFTY || {}),
            call: {
              ...(state.options?.BANKNIFTY?.call || {}),
              sell: {
                ...(state.options?.BANKNIFTY?.call?.sell || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "banknifty-call-sell-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKNIFTY: {
            ...(state.options?.BANKNIFTY || {}),
            call: {
              ...(state.options?.BANKNIFTY?.call || {}),
              sell: {
                ...(state.options?.BANKNIFTY?.call?.sell || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "banknifty-put-buy-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKNIFTY: {
            ...(state.options?.BANKNIFTY || {}),
            put: {
              ...(state.options?.BANKNIFTY?.put || {}),
              buy: {
                ...(state.options?.BANKNIFTY?.put?.buy || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "banknifty-put-buy-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKNIFTY: {
            ...(state.options?.BANKNIFTY || {}),
            put: {
              ...(state.options?.BANKNIFTY?.put || {}),
              buy: {
                ...(state.options?.BANKNIFTY?.put?.buy || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "banknifty-put-sell-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKNIFTY: {
            ...(state.options?.BANKNIFTY || {}),
            put: {
              ...(state.options?.BANKNIFTY?.put || {}),
              sell: {
                ...(state.options?.BANKNIFTY?.put?.sell || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "banknifty-put-sell-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKNIFTY: {
            ...(state.options?.BANKNIFTY || {}),
            put: {
              ...(state.options?.BANKNIFTY?.put || {}),
              sell: {
                ...(state.options?.BANKNIFTY?.put?.sell || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    // UPDATE  FIN
    case "finnifty-call-buy-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          FINNIFTY: {
            ...(state.options?.FINNIFTY || {}),
            call: {
              ...(state.options?.FINNIFTY?.call || {}),
              buy: {
                ...(state.options?.FINNIFTY?.call?.buy || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "finnifty-call-buy-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          FINNIFTY: {
            ...(state.options?.FINNIFTY || {}),
            call: {
              ...(state.options?.FINNIFTY?.call || {}),
              buy: {
                ...(state.options?.FINNIFTY?.call?.buy || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "finnifty-call-sell-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          FINNIFTY: {
            ...(state.options?.FINNIFTY || {}),
            call: {
              ...(state.options?.FINNIFTY?.call || {}),
              sell: {
                ...(state.options?.FINNIFTY?.call?.sell || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "finnifty-call-sell-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          FINNIFTY: {
            ...(state.options?.FINNIFTY || {}),
            call: {
              ...(state.options?.FINNIFTY?.call || {}),
              sell: {
                ...(state.options?.FINNIFTY?.call?.sell || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "finnifty-put-buy-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          FINNIFTY: {
            ...(state.options?.FINNIFTY || {}),
            put: {
              ...(state.options?.FINNIFTY?.put || {}),
              buy: {
                ...(state.options?.FINNIFTY?.put?.buy || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "finnifty-put-buy-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          FINNIFTY: {
            ...(state.options?.FINNIFTY || {}),
            put: {
              ...(state.options?.FINNIFTY?.put || {}),
              buy: {
                ...(state.options?.FINNIFTY?.put?.buy || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "finnifty-put-sell-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          FINNIFTY: {
            ...(state.options?.FINNIFTY || {}),
            put: {
              ...(state.options?.FINNIFTY?.put || {}),
              sell: {
                ...(state.options?.FINNIFTY?.put?.sell || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "finnifty-put-sell-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          FINNIFTY: {
            ...(state.options?.FINNIFTY || {}),
            put: {
              ...(state.options?.FINNIFTY?.put || {}),
              sell: {
                ...(state.options?.FINNIFTY?.put?.sell || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    // UPDATE MIDCP
    case "midcpnifty-call-buy-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          MIDCPNIFTY: {
            ...(state.options?.MIDCPNIFTY || {}),
            call: {
              ...(state.options?.MIDCPNIFTY?.call || {}),
              buy: {
                ...(state.options?.MIDCPNIFTY?.call?.buy || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "midcpnifty-call-buy-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          MIDCPNIFTY: {
            ...(state.options?.MIDCPNIFTY || {}),
            call: {
              ...(state.options?.MIDCPNIFTY?.call || {}),
              buy: {
                ...(state.options?.MIDCPNIFTY?.call?.buy || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "midcpnifty-call-sell-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          MIDCPNIFTY: {
            ...(state.options?.MIDCPNIFTY || {}),
            call: {
              ...(state.options?.MIDCPNIFTY?.call || {}),
              sell: {
                ...(state.options?.MIDCPNIFTY?.call?.sell || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "midcpnifty-call-sell-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          MIDCPNIFTY: {
            ...(state.options?.MIDCPNIFTY || {}),
            call: {
              ...(state.options?.MIDCPNIFTY?.call || {}),
              sell: {
                ...(state.options?.MIDCPNIFTY?.call?.sell || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "midcpnifty-put-buy-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          MIDCPNIFTY: {
            ...(state.options?.MIDCPNIFTY || {}),
            put: {
              ...(state.options?.MIDCPNIFTY?.put || {}),
              buy: {
                ...(state.options?.MIDCPNIFTY?.put?.buy || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "midcpnifty-put-buy-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          MIDCPNIFTY: {
            ...(state.options?.MIDCPNIFTY || {}),
            put: {
              ...(state.options?.MIDCPNIFTY?.put || {}),
              buy: {
                ...(state.options?.MIDCPNIFTY?.put?.buy || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "midcpnifty-put-sell-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          MIDCPNIFTY: {
            ...(state.options?.MIDCPNIFTY || {}),
            put: {
              ...(state.options?.MIDCPNIFTY?.put || {}),
              sell: {
                ...(state.options?.MIDCPNIFTY?.put?.sell || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "midcpnifty-put-sell-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          MIDCPNIFTY: {
            ...(state.options?.MIDCPNIFTY || {}),
            put: {
              ...(state.options?.MIDCPNIFTY?.put || {}),
              sell: {
                ...(state.options?.MIDCPNIFTY?.put?.sell || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    // UPDATE SENSEX
    case "sensex-call-buy-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          SENSEX: {
            ...(state.options?.SENSEX || {}),
            call: {
              ...(state.options?.SENSEX?.call || {}),
              buy: {
                ...(state.options?.SENSEX?.call?.buy || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "sensex-call-buy-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          SENSEX: {
            ...(state.options?.SENSEX || {}),
            call: {
              ...(state.options?.SENSEX?.call || {}),
              buy: {
                ...(state.options?.SENSEX?.call?.buy || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "sensex-call-sell-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          SENSEX: {
            ...(state.options?.SENSEX || {}),
            call: {
              ...(state.options?.SENSEX?.call || {}),
              sell: {
                ...(state.options?.SENSEX?.call?.sell || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "sensex-call-sell-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          SENSEX: {
            ...(state.options?.SENSEX || {}),
            call: {
              ...(state.options?.SENSEX?.call || {}),
              sell: {
                ...(state.options?.SENSEX?.call?.sell || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "sensex-put-buy-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          SENSEX: {
            ...(state.options?.SENSEX || {}),
            put: {
              ...(state.options?.SENSEX?.put || {}),
              buy: {
                ...(state.options?.SENSEX?.put?.buy || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "sensex-put-buy-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          SENSEX: {
            ...(state.options?.SENSEX || {}),
            put: {
              ...(state.options?.SENSEX?.put || {}),
              buy: {
                ...(state.options?.SENSEX?.put?.buy || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "sensex-put-sell-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          SENSEX: {
            ...(state.options?.SENSEX || {}),
            put: {
              ...(state.options?.SENSEX?.put || {}),
              sell: {
                ...(state.options?.SENSEX?.put?.sell || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "sensex-put-sell-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          SENSEX: {
            ...(state.options?.SENSEX || {}),
            put: {
              ...(state.options?.SENSEX?.put || {}),
              sell: {
                ...(state.options?.SENSEX?.put?.sell || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    // UPDATE BANKEX
    case "bankex-call-buy-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKEX: {
            ...(state.options?.BANKEX || {}),
            call: {
              ...(state.options?.BANKEX?.call || {}),
              buy: {
                ...(state.options?.BANKEX?.call?.buy || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "bankex-call-buy-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKEX: {
            ...(state.options?.BANKEX || {}),
            call: {
              ...(state.options?.BANKEX?.call || {}),
              buy: {
                ...(state.options?.BANKEX?.call?.buy || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "bankex-call-sell-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKEX: {
            ...(state.options?.BANKEX || {}),
            call: {
              ...(state.options?.BANKEX?.call || {}),
              sell: {
                ...(state.options?.BANKEX?.call?.sell || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "bankex-call-sell-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKEX: {
            ...(state.options?.BANKEX || {}),
            call: {
              ...(state.options?.BANKEX?.call || {}),
              sell: {
                ...(state.options?.BANKEX?.call?.sell || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "bankex-put-buy-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKEX: {
            ...(state.options?.BANKEX || {}),
            put: {
              ...(state.options?.BANKEX?.put || {}),
              buy: {
                ...(state.options?.BANKEX?.put?.buy || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "bankex-put-buy-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKEX: {
            ...(state.options?.BANKEX || {}),
            put: {
              ...(state.options?.BANKEX?.put || {}),
              buy: {
                ...(state.options?.BANKEX?.put?.buy || {}),
                t: action.payload.payload,
              },
            },
          },
        },
      };
    case "bankex-put-sell-sl":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKEX: {
            ...(state.options?.BANKEX || {}),
            put: {
              ...(state.options?.BANKEX?.put || {}),
              sell: {
                ...(state.options?.BANKEX?.put?.sell || {}),
                sl: action.payload.payload,
              },
            },
          },
        },
      };
    case "bankex-put-sell-t":
      return {
        ...state,
        options: {
          ...(state.options || {}),
          BANKEX: {
            ...(state.options?.BANKEX || {}),
            put: {
              ...(state.options?.BANKEX?.put || {}),
              sell: {
                ...(state.options?.BANKEX?.put?.sell || {}),
                t: action.payload.payload,
              },
            },  
          },
        },
      };
    // UPDATE SL
    case "updateSL":
      return { ...state, ...action.payload.payload }; 
    default:
      return { ...state, ...action.payload.payload }; 
  }
};

export const SLIndex = createSlice({
  name: "sl",
  initialState: initialState,
  reducers: {
    slReducer: SLReducer,
    updateCurrentSL: (state, action) => {
      return action.payload;
    },
  },
});

export const { slReducer, updateCurrentSL } = SLIndex.actions;

export default SLIndex.reducer;     
