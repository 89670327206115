import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  positions: null,
  basketPositions: null,
  openOrders: null,
  orders: null,
  positionType: "strike",
};

const OrderReducer = createSlice({
  name: "orders",
  initialState: initialState,
  reducers: {
    updatePositions: (state, action) => {
      state.positions = action.payload;
    },
    updateBasketPositions: (state, action) => {
      state.basketPositions = action.payload;
    },
    updateOpenOrders: (state, action) => {
      state.openOrders = action.payload;
    },
    updateOrders: (state, action) => {
      state.orders = action.payload;
    },
    setPositionType: (state, action) => {
      state.positionType = action.payload;
    },
  },
});

export const {
  updatePositions,
  updateOpenOrders,
  updateOrders,
  updateBasketPositions,
  setPositionType,  
} = OrderReducer.actions;
export default OrderReducer.reducer;
