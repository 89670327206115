import { Button, Skeleton } from '@mui/material';
import React, { useEffect } from 'react'
import HomeTopPerformingAlgo from '../../EquityComponents/HomeTopPerformingAlgo';
import { H1, H4, H5, H6, P } from '../../Theme/ThemeComponent';
import { useThemeContext } from '../../Theme/ThemeContext';
import HomeWatchlistCard from '../../EquityComponents/HomeWatchlistCard';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import { http } from '../../Services/api.services';
import { BASE_URL } from '../../default.config';
import { useDispatch, useSelector } from 'react-redux';
import { setAllALgos } from '../../store/Reducer/EquityReducer';
import WatchlistCard from '../../EquityComponents/WatchlistCard';


export default function EquityHome() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allAlgos, allWatchlists } = useSelector((state) => state.equity);
  const { theme } = useThemeContext();
  const viewmy = true;
  const [value, setValue] = React.useState(0);
  const skeletons = Array.from({ length: 4 });
  const [isLoading, setIsLoading] = React.useState(false);

  const colors = [
    "bg-[#5367FF0D]",
    "bg-[#F0AD000D]",
    "bg-[#00B3860D]",
    "bg-[#D740400D]",
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const MyAlgoFields = ({ title, value }) => (
    <div className='flex flex-col items-center gap-1'>
      <P title={title} color={theme.palette.text.secondary} />
      <H6 title={value} color={theme.palette.text.profit} font='medium' />
    </div>
  )

  // GET ALL ALGORITHMS

  React.useEffect(() => {
    getAllAlgorithms(0, 6, 'roi');
  }, []);

  const getAllAlgorithms = async (skip, limit, sort) => {
    try {
      const _body = {
        sort_by: sort,
        limit: limit,
        offset: skip
      }
      const response = await http.post(`${BASE_URL}algo`, _body);
      if (response.status == 200) {
        dispatch(setAllALgos(response?.data));
      }
    } catch (e) {
      console.log(`ERROR WHILE FETCHING ALGORITHMS ${e}`);
    }
  };


  return (
    <div className="w-full flex-1 flex flex-col overflow-hidden  lg:flex-row gap-4 p-0 lg:p-4">
      <div style={{ backgroundColor: theme.palette.background.white }} className='w-full flex-1 border border-[#F2F2F2] flex flex-col items-start gap-2  p-2  shadow'>
          <div className='py-4'>
            <H4
              title={'TOP PERFORMING ALGOS'}
              font="bold"
              color={theme.palette.text.charcolGray}
            />
          </div>
          <div className='flex-1 overflow-y-auto w-full   flex flex-col gap-3'>
            {!allAlgos
              ? <div className='w-full flex-1 flex flex-col items-center justify-center gap-4'>
                {skeletons.map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    width={600}
                    fullWidth
                    height={160}
                  />
                ))}
              </div>
              : allAlgos.map((algo, index) => {
                  const colorIndex = index % colors.length;
                  return (
                    <HomeTopPerformingAlgo
                      key={index}
                      algo={algo}
                    />
                  );
                return null;
              })}

            {/* <HomeTopPerformingAlgo /> */}
            <div className='w-full flex items-end justify-end'>
              <Button
                variant="text"
                size='small'
                sx={{ color: theme.palette.text.charcolGray }}
                endIcon={<KeyboardArrowRightIcon />}
                onClick={() => {
                  navigate('/equity/algorithm')
                }}
              >
                View More
              </Button>
            </div>
          </div>
      </div>
      <div style={{ backgroundColor: theme.palette.background.white }} className='w-full flex-1 border border-[#F2F2F2] flex flex-col items-start gap-2  p-2  shadow'>
          <div className='py-4'>
            <H4
              title={'WATCHLIST'}
              font="bold"
              color={theme.palette.text.charcolGray}
            />
          </div>
          <div className='w-full flex-1 overflow-y-auto  flex flex-col gap-3'>
            {!allWatchlists
              ? <div className='w-full flex-1 flex flex-col overflow-y-auto items-center justify-center gap-4'>
                {skeletons.map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    width={600}
                    fullWidth
                    height={160}
                  />
                ))}
              </div>
              : allWatchlists?.map((watchlist, index) => {
                if (index < 6) {
                  return (
                    // <HomeWatchlistCard />
                    <WatchlistCard
                      key={index}
                      watchListData={watchlist}
                    />
                  )
                }
                return null;
              })}
            <div className='w-full flex items-end justify-end'>
              <Button
                variant="text"
                size='small'
                sx={{ color: theme.palette.text.charcolGray }}
                endIcon={<KeyboardArrowRightIcon />}
                onClick={() => {
                  navigate('/equity/watchlist')
                }}
              >
                View More
              </Button>
            </div>
          </div>
      </div>
    </div>
  )
}
