import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel, ScrollingCarousel } from '@trendyol-js/react-carousel';
import PlanCard from './PlanCard';
import { Children } from 'react';


const smooth = require('../assets/StandardPlan.png')
const speed = require('../assets/PremiumPlan.png')

const smoothAvailableFeatures = [
    'Advanced options Chain',
    'Data analytics',
    'Equilibrium Price',
    'OI ration',
    'Buy and Sell Large quantity without any bucket order',
    'Volume analysis',
    'Point based Stoploss',
    'LTP based Stoploss',
    'Point based Target Price',
    'LTP based Target Price',
    'Position Watch',
    'Track Order Details',
    'Track your Performance'
]

const smoothNotAvailableFeatures = [
    'Auto Trailling Stoploss',
    'Speed buy & sell',
    'Advanced Stoploss setting',
    'Advanced target price setting ',
    'market sentimental indicator',
    'Short Covering indicator',
    'Pofit booking indicator',
    'Long & Short buildup indicator',
    'Advance market data'
]

const speedAvailableFeatures = [
    'Advanced options Chain',
    'Data analytics',
    'Equilibrium Price',
    'OI ration',
    'Buy and Sell Large quantity without any bucket order',
    'Volume analysis',
    'Point based Stoploss',
    'LTP based Stoploss',
    'Point based Target Price',
    'LTP based Target Price',
    'Position Watch',
    'Track Order Details',
    'Track your Performance',
    'Auto Trailling Stoploss',
    'Speed buy & sell',
    'Advanced Stoploss setting',
    'Advanced target price setting ',
    'market sentimental indicator',
    'Short Covering indicator',
    'Pofit booking indicator',
    'Long & Short buildup indicator',
    'Advance market data'
]

const Mobileslider = ({ onSubscribe }) => {
    return (
        <Carousel show={1} slide={1} swiping={true} swipeOn={1}>
            {/* <div className='py-10 px-4 flex items-center justify-center'>
                <PlanCard img={smooth} heading='Smooth' price='Rs. 2,999.00' bg='#00B3860A' availableFeatures={smoothAvailableFeatures} notAvailableFeatures={smoothNotAvailableFeatures} onSubscribe={onSubscribe} />
            </div>
            <div className='py-10 px-4 flex items-center justify-center'>
                < PlanCard img={speed} heading='Speed' price='Rs. 4,999.00' bg='#F0AD000A' availableFeatures={speedAvailableFeatures} disable='true' />
            </div> */}
            {Children}
        </Carousel>
    );
}

export default Mobileslider