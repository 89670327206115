import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";

const menuItemStyle = {
  fontSize: 12,
  padding: "6px 12px",
  width: 70,
};

const menuItm = [
  { label: "1 Min", value: 1 },
  { label: "3 Min", value: 3 },
  { label: "5 Min", value: 5 },
  { label: "10 Min", value: 10 },
  { label: "15 Min", value: 15 },
  { label: "30 Min", value: 30 },
  { label: "60 Min", value: 60 },
];

const IndexTableHeadCell = ({ interval, title, setIntervalFunction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [_interval, set_Interval] = React.useState(
    localStorage.getItem("emaInterval") || 1
  );
  const numberInterval = parseInt(_interval);

  let localName =
    title == "ema"
      ? "emaInterval"
      : title == "rsi"
      ? "rsiInterval"
      : "slInterval";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };    

  const handleChange = (event) => {
    // console.log(ihm);
    setIntervalFunction(event.target.value);
    set_Interval(event.target.value);
    localStorage.setItem(localName, event?.target?.value);
    setAnchorEl(null);
  };

  return (
    <>
      <div className="flex-1 flex items-center justify-between px-2">
        <h6 className="text-[#000000] uppercase font-semibold text-xs 2xl:text-md">
          {title === "Pcr/Cpr" ? (
            <div>
              <span className="text-red-500">{title?.split("/")[0]}</span>
              <span> / </span>
              <span className="text-green-500">{title?.split("/")[1]}</span>
            </div>
          ) : (
            title
          )}
        </h6>
        <div
          onClick={handleClick}
          className="border cursor-pointer border-[#D2D2D2] flex mt-1 mr-1 items-center gap-2 rounded px-2 py-[2px]"
        >
          <h6 className="text-[10px] whitespace-nowrap">{interval} Min</h6>
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "12px",
            }}
          />
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          // value={15}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {menuItm?.map((el, idx) => (
            <MenuItem
              key={idx}
              selected={el?.value == interval}
              sx={menuItemStyle}
              value={el?.value}
              // onClick={(el) => handleChange(el, 'ihm')}
              onClick={handleChange}
            >
              {el?.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  );
};

export default IndexTableHeadCell;
