import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";  
import Chip from "@mui/material/Chip";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { CalIcon, ExitIcon, PLIcon } from "../../PublicComponents/Icons";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "@mui/lab";
import { ApiService, http } from "../../Services/api.services";
import { useDispatch } from "react-redux";
import {
  updateBaskethistory,
  updateCurrentHistory,
} from "../../store/Reducer/BasketReducer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  borderBottom: "none",
  margin: 0,
  padding: theme.spacing(3),
}));

const _filterData = {
  date: {
    startDate: "",
    endDate: "",
  },
  p_l: {
    from: "",
    to: "",
  },
  exitType: {
    sl: false,
    target: false,
    manual: false,
  },
  basketsName: [],
};

export default function BasketFilter({
  open,
  handleOpenFilter,
  filterData,
  setFilterData,
  onresetFilter,
  onClearBasketNames,
  id,
  filterApplied
}) {
  const dispatch = useDispatch();
  // const [filterData, setFilterData] = React.useState({ ..._filterData });
  const [textData, setTextData] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  // REMOVE BASKET NAMES
  const handleRemoveBasketName = (idx) => {
    const _data = [...filterData.basketsName];
    _data.splice(idx, 1);
    setFilterData((prevData) => ({
      ...prevData,
      basketsName: _data,
    }));
    console.info("You clicked the delete icon.");
  };

  // ADD BASKET NAMES
  const addTextData = () => {
    const _data = [...filterData.basketsName];
    _data.push(textData);
    setFilterData((prevData) => ({
      ...prevData,
      basketsName: _data,
    }));
    setTextData("");
  };

  // GET FILTERED HISTORY DATA
  const filterBasketData = async () => {
    setLoading(true);
    try {
      const _payloadData = JSON.stringify({...filterData, date: { startDate: new Date(filterData.date.startDate), endDate: new Date(filterData.date.endDate) }});
      const response = await http.get(
        `orders/${id}/basket/history?filter=${_payloadData}`
      );
      if (response?.status == 200) {
        filterApplied(true)
        dispatch(updateBaskethistory(response?.data?.data));
        dispatch(updateCurrentHistory(null)); 
        handleOpenFilter();
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(`ERROR WHILE FILTERING HISTORY DATA ${e}`);
    }
  };  

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleOpenFilter}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Basket Filter
        </DialogTitle>
        <IconButton
          aria-label="close"  
          onClick={handleOpenFilter}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            maxWidth: 700,
            width: 700,
          }}
          dividers
        >
          <div className="w-full border-b border-b-[#D9D9D9] flex flex-col items-start gap-4 pb-8  pt-4">
            <div className="w-full flex items-center gap-2">
              <div className="flex w-1/6 items-center gap-2">
                <CalIcon />
                <h6 className="text-[#373737]  text-sm py-2 lg:text-sm font-semibold whitespace-nowrap">
                  Date
                </h6>
              </div>
              <div className="flex w-5/6  items-center gap-2">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    backgroundColor: "#F8F8F8",
                  }}
                  type="datetime-local"
                  value={filterData?.date?.startDate}
                  onChange={(e) => {
                    setFilterData((prevData) => ({
                      ...prevData,
                      date: {
                        ...prevData.date,
                        startDate: e.target.value,
                      },
                    }));
                  }}
                />
                <span>-</span>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    backgroundColor: "#F8F8F8",
                  }}
                  type="datetime-local"
                  value={filterData?.date?.endDate}
                  onChange={(e) => {
                    setFilterData((prevData) => ({
                      ...prevData,
                      date: {
                        ...prevData.date,
                        endDate: e.target.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="w-full flex items-center gap-2">
              <div className="flex w-1/6 items-center gap-2">
                <PLIcon />
                <h6 className="text-[#373737]  text-sm py-2 lg:text-sm font-semibold whitespace-nowrap">
                  P/L
                </h6>
              </div>
              <div className="flex w-5/6   items-center gap-2">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  // type="date"
                  type="number"
                  placeholder="From"
                  sx={{
                    backgroundColor: "#F8F8F8",
                  }}
                  value={filterData?.p_l?.from}
                  fullWidth
                  onChange={(e) => {
                    setFilterData((prevData) => ({
                      ...prevData,
                      p_l: {
                        ...prevData.p_l,
                        from: e.target.value,
                      },
                    }));
                  }}
                />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  type="number"
                  sx={{
                    backgroundColor: "#F8F8F8",
                  }}
                  placeholder="To"
                  value={filterData?.p_l?.to}
                  fullWidth
                  onChange={(e) => {
                    setFilterData((prevData) => ({
                      ...prevData,
                      p_l: {
                        ...prevData.p_l,
                        to: e.target.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="w-full flex items-center gap-2">
              <div className="flex w-1/6 items-center gap-2">
                <ExitIcon />
                <h6 className="text-[#373737]   text-sm py-2 lg:text-sm font-semibold whitespace-nowrap">
                  Exit type
                </h6>
              </div>
              <div className="flex bg-[#F8F8F8] w-5/6  px-4 items-center gap-8">
                <FormControlLabel
                  onChange={(e) => {
                    setFilterData((prevData) => ({
                      ...prevData,
                      exitType: {
                        ...prevData?.exitType,
                        sl: e?.target?.checked,
                      },
                    }));
                  }}
                  control={<Checkbox checked={filterData?.exitType?.sl} />}
                  label="SL"
                />
                <FormControlLabel
                  control={<Checkbox checked={filterData?.exitType?.target} />}
                  onChange={(e) => {
                    setFilterData((prevData) => ({
                      ...prevData,
                      exitType: {
                        ...prevData?.exitType,
                        target: e?.target?.checked,
                      },
                    }));
                  }}
                  label="T"
                />
                <FormControlLabel
                  control={<Checkbox checked={filterData?.exitType?.manual} />}
                  onChange={(e) => {
                    setFilterData((prevData) => ({
                      ...prevData,
                      exitType: {
                        ...prevData?.exitType,
                        manual: e?.target?.checked,
                      },
                    }));
                  }}
                  label="M"
                />
              </div>
            </div>
          </div>

          <div className="w-full ">
            <div className="flex w-full py-6 items-center gap-2">
              <TextField
                id="outlined-basic"
                placeholder="Write Basket Name"
                variant="outlined"
                size="small"
                fullWidth
                value={textData}
                sx={{
                  backgroundColor: "#F8F8F8",
                }}
                onChange={(e) => {
                  setTextData(e?.target?.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && textData?.trim()) {
                    addTextData();
                  }
                }}
              />
              <Button
                onClick={() => {
                  if (textData?.trim()) {
                    addTextData();
                  }
                }}
                size="medium"
                variant="contained"
              >
                Add
              </Button>
              <IconButton
                onClick={() => {
                  onClearBasketNames();
                  setTextData("");
                }}
                aria-label="delete"
                size="small"
              >
                <RestartAltIcon />
              </IconButton>
            </div>
            <div className=" py-4 flex items-center gap-2 flex-wrap">
              {filterData?.basketsName?.length > 0
                ? filterData?.basketsName?.map((itm, idx) => (
                    <Chip
                      sx={{
                        borderRadius: 1,
                      }}
                      key={idx}
                      label={itm}
                      onDelete={() => handleRemoveBasketName(idx)}
                    />
                  ))
                : null}
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            padding: 2,
          }}
        >
          <Button
            onClick={() => {
              onresetFilter();
              filterApplied(false)
              setTextData("");
            }}
            variant="outlined"
            autoFocus
          >
            Reset
          </Button>
          <LoadingButton
            onClick={() => filterBasketData()}
            loading={loading}
            variant="contained"
            autoFocus
          >
            Apply
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
