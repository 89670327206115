import React from 'react'

const HomepageHeaders = (props) => {
    return (
        <div className='flex flex-col items-center justify-center gap-8 lg:gap-5'>
            <h1 className='text-[#141414] font-semibold text-center text-3xl lg:text-5xl leading-1'>{props.heading}<span className='text-[#1C1C1C] tetx-[3.5vmax]'></span></h1>
            <p className='text-center text-lg lg:text-3xl font-extralight text-[#757575]'>{props.desc}</p>
        </div>
    )
}

export default HomepageHeaders