import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

export default function ConnectBroker(props) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleChangeData = (type, value) => {
        const _data = { ...props?.addBrokerData }
        _data[type] = value
        props?.setAddBrokerData(_data)
    }

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                // open={props?.open}
                open={true}
                onClose={() => props?.onclose()}
                aria-labelledby="responsive-dialog-title"
            // BackdropProps={{ invisible: true }}
            >
                <DialogTitle id="responsive-dialog-title">
                    {props?.isEdit ?
                        "Update broker"
                        :
                        "Connect to broker"
                    }
                </DialogTitle>
                <DialogContent>
                    <div className='w-full flex flex-col flex-1  h-full items-center justify-center py-8 min-w-[20vw] lg:items-start gap-5'>
                        <TextField
                            id="outlined-basic"
                            label="api key*"
                            variant="outlined"
                            size='small'
                            fullWidth
                            value={props?.addBrokerData?.api_key}
                            onChange={(e) => handleChangeData('api_key', e?.target?.value)}
                        />
                        <TextField
                            id="outlined-basic"
                            label="api secret*"
                            variant="outlined"
                            size='small'
                            fullWidth
                            value={props?.addBrokerData?.api_secret}
                            onChange={(e) => handleChangeData('api_secret', e?.target?.value)}
                        />
                        <TextField
                            id="outlined-basic"
                            label="id*"
                            variant="outlined"
                            size='small'
                            fullWidth
                            value={props?.addBrokerData?.user_name}
                            onChange={(e) => handleChangeData('user_name', e?.target?.value)}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ color: 'red' }} autoFocus onClick={() => props?.onclose()}>
                        Close
                    </Button>

                    {props?.isEdit ?
                        <LoadingButton
                            loading={props?.isLoading}
                            onClick={() => props?.updateBroker()}
                            sx={{ width: 'fit-content' }}
                            fullWidth
                            variant="contained"
                            size="small"
                        // disabled={
                        //     !(nameRegex.test(props?.addUserData?.name)
                        //         && mobileRegex.test(props?.addUserData?.mobile)
                        //         && emailRegex.test(props?.addUserData?.email)
                        //         && passwordRegex.test(props?.addUserData?.password)
                        //         && props?.addUserData?.password === props?.confirmPassword
                        //     )
                        // }
                        >
                            UPDATE
                        </LoadingButton>
                        :
                        <LoadingButton
                            loading={props?.isLoading}
                            onClick={() => props?.addBroker()}
                            sx={{ width: 'fit-content' }}
                            fullWidth
                            variant="contained"
                            size="small"
                        // disabled={
                        //     !(nameRegex.test(props?.addUserData?.name)
                        //         && mobileRegex.test(props?.addUserData?.mobile)
                        //         && emailRegex.test(props?.addUserData?.email)
                        //         && passwordRegex.test(props?.addUserData?.password)
                        //         && props?.addUserData?.password === props?.confirmPassword
                        //     )
                        // }
                        >
                            SAVE
                        </LoadingButton>
                    }

                </DialogActions>
            </Dialog>

        </>
    );
}