import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import StepperComponent from './StepperComponent';
import { Alert, IconButton, ListItemIcon, Snackbar } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

const customPlaceholderStyle = {
    color: 'red', // Change the color to your desired color
    fontFamily: 'Arial, sans-serif', // Change the font family to your desired font
};

export default function ResponsiveDialog(props) {
    const dispatch = useDispatch()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [error, setErrors] = React.useState({ open: false, type: '', message: '' })

    return (

        <div>
            <div className='w-full !bg-rgba(248, 248, 248, 0.99)'>
                <Dialog
                    fullScreen={fullScreen}
                    open={props.open}
                    onClose={() => props.onClose()}
                    aria-labelledby="responsive-dialog-title"
                >
                    <div className='relative w-full'>
                        <DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', paddingTop: 4 }}>
                            <div className='flex flex-col items-center justify-center gap-2 py-6 lg:py-0 '>
                                <h6 style={{ color: '#000000' }} className='font-semibold tracking-wider text-lg lg:text-xl'>Follow these steps to add your broker</h6>
                            </div>
                        </DialogTitle>
                        <div className='absolute top-4 right-4'>
                            <IconButton onClick={() => props.onClose()}>
                                <CloseOutlined />
                            </IconButton>
                        </div>
                    </div>
                    <DialogContent sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)' }} >
                        <StepperComponent
                            setOpenStapper={props?.setOpenStapper}
                            brokers={props?.brokers}
                            response={(err) => setErrors({ ...err })}
                            onClose={() => { props?.onClose(); }}
                        />
                    </DialogContent>
                </Dialog>
            </div>
            <Snackbar open={error?.open} autoHideDuration={5000} onClose={() => setErrors({ open: false, message: '' })}>
                <Alert onClose={() => setErrors({ open: false, message: '', type: '' })} severity={error?.type} sx={{ width: '100%' }}>
                    {error?.message}
                </Alert>
            </Snackbar>
        </div>
    );
}
