// SAVE THIS!! ONE OF THE BEST AUTOCOMPLETE METHOD EVER FOUND ON INTERNET @react @mui/material
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import { http } from '../Services/api.services';


const SYMBOLS_URL = `/ds/search-symbol`


const InstrumentAutoComplete = ({ inputObject, textValue, onChange }) => {
    const [value, setValue] = React.useState(inputObject);
    const [inputValue, setInputValue] = React.useState(textValue);
    const [options, setOptions] = React.useState([]);
    const [searching, setSearching] = React.useState(false);

    const fetchSymbols = async (request, callback) => {
        try {
            setSearching(true)
            const response = await http.post(SYMBOLS_URL, request)
            setSearching(false)
            if (response?.data) {
                setOptions(response?.data)
                console.log(response);
            }
            if (response.data?.data?.length) {
                callback(response.data?.data)
            }
        }
        catch (e) { 
            setSearching(false)
            console.log(`ERROR WHILE FETCHING SYMBOLS ${e}`)
        }
    }

    const fetch = React.useMemo(
        () =>
            debounce((request, callback) => { 
                fetchSymbols(request, callback)
            }, 400),
        [],
    );  

    React.useEffect(() => {
        let active = true;  
        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;   
        }
        fetch({ symbol: inputValue }, (results) => {
            if (active) {
                let newOptions = [];
                if (value) {
                    newOptions = [value];
                }
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            }
        });
        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            id="symbol-autocomplete"
            size='small'
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.tradingsymbol
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText={searching ? "Searching..." : "No Symbols"}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                onChange(newValue)
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} placeholder="Type symbol here..." fullWidth />
            )}
            renderOption={(props, option) => {
                return (
                    <div key={option?._id} {...props}>
                        <p className='text-slate-800 text-sm'>{option.exchange} : {option.tradingsymbol}</p>
                    </div>
                );
            }}
        />
    );
}

export default InstrumentAutoComplete;