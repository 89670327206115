import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";




const SubscriptionExpired = () => {



    const navigate = useNavigate()

    const { plans } = useSelector((state) => state.plans)

    const planRoute = `/home/plans/${plans[0]?.title}/${plans[0]?.id}/${plans[0]?.cycle[0]}`
    return (
        <div className="h-full w-full flex justify-center items-center bg-[#fafafa]">
            <div className="max-w-[320px] min-h-[200px] shadow-lg rounded-lg bg-white backdrop-blur px-4 py-6">
                <div className="flex justify-center">
                    <p className="text-md text-slate-600 font-light">No Subscription Found!</p>
                </div>
                <p className="text-sm text-center my-4 mx-4 font-extralight">
                    Hey there! seems you do not have any active subscription, we request you to get an active subscription to access our features.
                </p>
                <div className="flex justify-center">
                    <Button variant="contained" className="!capitalize" onClick={() => navigate(planRoute)}>
                        View Subscription Plans
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionExpired;