import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { emailRegex, mobileRegex, nameRegex, passwordRegex, factorRegex } from '../../ValidationRegex';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ModulesIcon } from '../../PublicComponents/Icons';
import { useDimensions } from "../../Contexts/DimensionContext/DimensionsContext";

export default function AddTarget(props) {
    const { lg, xl, xxl } = useDimensions()
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <>
            <Dialog
                // fullScreen={fullScreen}
                open={props?.open}
                onClose={() => props?.onclose()}
                aria-labelledby="responsive-dialog-title"
            // BackdropProps={{ invisible: true }}
            >
                <DialogTitle id="responsive-dialog-title">
                    <div className='w-full flex items-center justify-between'>
                        {"Add Target"}
                        <CloseOutlinedIcon
                            onClick={() => props?.onclose()}
                        />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className='w-full flex flex-col flex-1  h-full items-center justify-center py-8 min-w-[70vw] lg:min-w-[20vw] lg:items-start gap-5'>

                        <div className='w-full flex flex-col lg:flex-row gap-3'>
                            <div className='w-full flex items-center gap-[2px] justify-between'>
                                <div className='w-[48%] '>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label="TP"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ModulesIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className='w-3 h-[2px] bg-[#37373769]'></div>
                                <div className='w-[48%] '>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label=""
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ModulesIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='w-full flex items-center gap-[2px] justify-between'>
                                <div className='w-[48%] '>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label="SL"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ModulesIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className='w-3 h-[2px] bg-[#37373769]'></div>
                                <div className='w-[48%] '>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label=""
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ModulesIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:w-1/2 pr-2 '>
                            <FormControl size='small' fullWidth>
                                <InputLabel
                                    //  shrink={true} 
                                    id="demo-simple-select-label">
                                    Dynamic/Final
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"

                                    id="demo-simple-select"
                                    value={age}
                                    label="Age"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    {props?.isEdit ?
                        <LoadingButton
                            loading={props?.isLoading}
                            onClick={() => props?.onUpdate()}
                            sx={{ width: 'fit-content' }}
                            fullWidth
                            variant="contained"
                            size="small"
                            disabled={
                                !(nameRegex.test(props?.addUserData?.name)
                                    && mobileRegex.test(props?.addUserData?.mobile)
                                    && emailRegex.test(props?.addUserData?.email)
                                    // && passwordRegex.test(props?.addUserData?.password)
                                    // && props?.addUserData?.password === props?.confirmPassword
                                )
                            }
                        >
                            Update
                        </LoadingButton>
                        :
                        <LoadingButton
                            loading={props?.isLoading}
                            onClick={() => props?.onAdd()}
                            sx={{ width: 'fit-content' }}
                            fullWidth
                            variant="contained"
                            size={lg || xl || xxl ? 'medium' : 'small'}
                            disabled={
                                !(nameRegex.test(props?.addUserData?.name)
                                    && mobileRegex.test(props?.addUserData?.mobile)
                                    && emailRegex.test(props?.addUserData?.email)
                                    && passwordRegex.test(props?.addUserData?.password)
                                    && props?.addUserData?.password === props?.confirmPassword
                                )
                            }
                        >
                            Done
                        </LoadingButton>
                    }
                </DialogActions>
            </Dialog>

        </>
    );
}