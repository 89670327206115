import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment/moment';
import LinearProgress from '@mui/material/LinearProgress';
import NoRows from '../PublicComponents/NoData';

const showIndiaTime = (time) => {
    return new Date(time).getTime() + 19800000
}

const columns = [
    {
        field: 'createdat',
        headerName: <h6 className='font-semibold '>CREATED AT</h6>,
        minWidth: 200,
        flex: 1,
        valueGetter: (params) => { return moment(showIndiaTime(params?.value)).format('DD/MM/YYYY HH:mm a') }
    },
    {
        field: 'tradingsymbol',
        headerName: <h6 className='font-semibold '>TRADING SYMBOL</h6>,
        minWidth: 220,
        flex: 1,
        // align:'center'
    },
    {
        field: 'transaction_type',
        headerName: <h6 className='font-semibold '>Txn Type</h6>,
        minWidth: 100,
        flex: 1,
        // align:'center'
    },
    {
        field: 'order_type',
        headerName: <h6 className='font-semibold '>Order Type</h6>,
        flex: 1,
        minWidth: 100,
        // align:'center'
    },
    {
        field: 'quantity',
        headerName: <h6 className='font-semibold '>Quantity</h6>,
        minWidth: 100,
        flex: 1,
        // align:'center'
    },
    {
        field: 'price',
        headerName: <h6 className='font-semibold '>Price</h6>,
        minWidth: 100,
        flex: 1,
        // align:'center'
        valueGetter: (params) => {
            // console.log(params)
            if (params.value || params.valeu == 0) {
                return params.value
            }
            else {
                if (!params?.value) {
                    return '--'
                }
                else return params?.value
            }

        }
    },
    {
        field: 'status',
        headerName: <h6 className='font-semibold '>Success</h6>,
        minWidth: 200,
        flex: 1,
        valueGetter: (params) => {
            if (params?.row?.response?.error) {
                return params?.row?.response?.error
            }
            else {
                if (!params?.value) {
                    return '--'
                }
                else return params?.value
            }

        }
        // align:'center'
    },
    // {
    //     field: 'Error',
    //     headerName: <h6 className='font-semibold '>Error</h6>,
    //     minWidth: 100,
    //     flex: 1,
    //     // align:'center'
    // },
];

export default function UserOrderTable({ data }) {
    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <DataGrid
                rows={data || []}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 50,
                        },
                    },
                }}
                pageSizeOptions={[50, 100, 500]}
                slots={{
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: NoRows
                }}
                loading={!data}
            />
        </Box>  
    );
}
