import React, { useState } from 'react'
import { useDimensions } from "../../Contexts/DimensionContext/DimensionsContext";
import AllTransections from '../../PrivateComponents/AllTransections';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { http } from '../../Services/api.services'

const HeadCell = ({ title }) => (
    <h6 className='text-[1.5vmax]  lg:text-[.8vmax] text-[#373737]  font-medium tracking-wider'>
        {title}
    </h6>
)

const columns = [
    {
        field: 'name',
        headerName: <HeadCell title="Name" />,
        minWidth: 150,
        flex: 1,
        // renderCell: (params) => {

        //     return <div className='flex gap-2  items-center' style={{ opacity: params?.row?.status ? 1 : 0.4 }}>

        //         <div>
        //             <p className='font-bold '>{params.row.name}</p>
        //         </div>
        //     </div>
        // }
    },
    {
        field: 'description',
        headerName: <HeadCell title="Description" />,
        minWidth: 250,
        flex: 1,

    },
    {
        field: 'strike',
        headerName: <HeadCell title="Strike" />,
        minWidth: 200,
        flex: 1,

    },
    {
        field: 'index',
        headerName: <HeadCell title="Index" />,
        minWidth: 200,
        flex: 1,

    },
    {
        field: 'expiry',
        headerName: <HeadCell title="Expiry" />,
        minWidth: 200,
        flex: 1,

    },
    {
        field: 'ordertype',
        headerName: <HeadCell title="PE/CE" />,
        minWidth: 200,
        flex: 1,

    },
    {
        field: 'txntype',
        headerName: <HeadCell title="Buy/Sell" />,
        minWidth: 200,
        flex: 1,
        renderCell: (params) => {
            const txnType = params.row.txntype;

            return (
                <div className='flex items-center gap-1' style={{ opacity: params?.row?.status ? 1 : 0.4 }}>
                    <p className={`text-${txnType === 'buy' ? 'green-400 ' : 'red-700'} capitalize`}>{txnType}</p>
                </div >
            )

        },

    },
    {
        field: 'createdat',
        headerName: <HeadCell title="Created at" />,
        minWidth: 150,
        // flex: 1,
        renderCell: (params) => {
            const date = params.row.createdat;

            const date1 = date?.split('T')[0]

            return (
                <div className='flex items-center gap-1' style={{ opacity: params?.row?.status ? 1 : 0.4 }}>
                    <p>{date1}</p>
                    <DeleteOutlineOutlinedIcon />
                </div>
            )

        },

    },
];

const EntryConditions = () => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState(0)
    const { xs, sm, md, lg, xl, xxl } = useDimensions()
    const divRef = useRef(null);
    const [currentWidth, setcurrentWidth] = React.useState(0)
    const [allEntryConditions, setAllEntryConditions] = useState([])

    const navigateUrl = '/admin/options/settings/action/entry-conditions/add-entry-conditions'

    React.useEffect(() => {
        const updateDivWidth = () => {
            if (divRef.current) {
                const currentWidth = divRef.current.offsetWidth;
                setcurrentWidth(currentWidth)
            }
        };

        // Initial setup
        updateDivWidth();

        // Attach event listener for screen size changes
        window.addEventListener('resize', updateDivWidth);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateDivWidth);
        };
    }, []);

    const getEntryconditions = async () => {
        try {
            const response = await http.get('/entry_condition')
            if (response?.status == 200) {
                setAllEntryConditions(response?.data)
            }
        } catch (e) {
            console.log('ERROR WHILE FETCHING ENTRY CONDITIONS', e);
        }
    }

    // FETCH ALL ENTRY CONDITIONS 
    React.useEffect(() => {
        getEntryconditions();
    }, [])


    return (
        <>
            <div className="w-full flex-1 flex gap-3 bg-white px-0  h-full">
                <div
                    ref={divRef}
                    // style={{ maxWidth: lg || xl || xxl ? 'calc(100% - 150px)' : '98vw' }}
                    className="flex-1 w-full  flex flex-col p-2"
                >
                    <div className='flex-1 w-full '>
                        <AllTransections
                            currentWidth={currentWidth}
                            columns={columns}
                            rows={allEntryConditions}
                            rowClick={(params) => navigate(`${navigateUrl}/${params?.row?.id}`)}
                        />
                    </div>
                    <div className='w-full flex pt-2 justify-end'>
                        <Fab
                            onClick={() => {
                                navigate('/admin/options/tools/actions/entry-conditions/add-entry-conditions')
                            }}
                            color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </div>
                </div>
            </div >
        </>
    )
}

export default EntryConditions
