import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    user: null,
};

export const Reducer = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
});

export const { setUser } = Reducer.actions;

export default Reducer.reducer;

