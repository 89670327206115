import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WolfLogo from '../assets/wolf-logo 1.png'
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import LoginPageImg from '../assets/Rectangle 447.png'
import CloseIcon from '@mui/icons-material/Close';
import CallIcon from '@mui/icons-material/Call';
import { mobileRegex } from '../ValidationRegex';
import { asyngetStatus, showToast } from '../store/Actions/Action'
import LoadingButton from '@mui/lab/LoadingButton';
import { ApiService, http } from '../Services/api.services';
import { useDispatch } from 'react-redux';
import { BASE_URL } from '../default.config';

const customPlaceholderStyle = {
    color: 'red', // Change the color to your desired color
    fontFamily: 'Arial, sans-serif', // Change the font family to your desired font
};


const api = new ApiService()

export default function ResponsiveDialog(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [mobile, setMobile] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    // HANDLE SEND OTP

    const handleSendOtp = async () => {
        try {
            if (mobileRegex.test(mobile)) {
                setIsLoading(true)
                const response = await http.post(`${BASE_URL}user/send-otp`, { mobile })
                console.log(response);
                if (response?.status === 200) {
                    setIsLoading(false)
                    const _snackbar = {
                        message: response?.data?.message,
                        open: true,
                        duration: 3000,
                        type: 'success'
                    };
                    dispatch(showToast(_snackbar));
                    props.onRequestOtp(mobile);
                } else {
                    const _snackbar = {
                        message: response?.data?.message,
                        open: true,
                        duration: 3000,
                        type: 'error'
                    };
                    dispatch(showToast(_snackbar));
                    setIsLoading(false)
                }
            }
            else {
                const _snackbar = {
                    message: 'Invalid Mobile',
                    open: true,
                    duration: 3000,
                    type: 'error'
                };
                dispatch(showToast(_snackbar));
            }

        } catch (e) {
            const response = e?.response;
            console.log(`ERROR WHILE SENDING OTP --- ${e}`)
            setIsLoading(false)
            const _snackbar = {
                message: response?.data?.message,
                open: true,
                duration: 3000,
                type: 'error'
            };
            dispatch(showToast(_snackbar));
        }
    };


    return (

        <div>
            <div className='w-full !bg-rgba(248, 248, 248, 0.99)'>
                <Dialog
                    fullScreen={fullScreen}
                    open={props.open}
                    onClose={() => props.onClose()}
                    aria-labelledby="responsive-dialog-title"
                >
                    <div style={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }} className='w-full min-h-[50vh] !lg:min-h-[52vh] flex'>
                        <div className='hidden  lg:flex w-full bg-[#000000]'>
                            <img className='w-full h-full object-contain' src={LoginPageImg} alt="LoginPageImg" />
                        </div>
                        <div className='w-full'>
                            <DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }}>
                                <div className='flex flex-col items-center justify-center gap-3 py-6 lg:py-1'>
                                    <img src={WolfLogo} alt="WolfLogo" />
                                    <h6 className='text-[#373737] font-normal tracking-wider text-[2.2vmax] lg:text-[1.5vmax]'>Join <span className='font-semibold'>Fastr</span></h6>
                                    <CloseIcon onClick={() => props.onClose()} sx={{ position: "absolute", top: "4%", right: "1%", transform: 'translate(-50%,-50%)' }} />
                                </div>
                            </DialogTitle>

                            <DialogContent sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)' }} >
                                <div className='input_area flex items-center justify-center w-full h-[55vh] lg:h-[40vh] lg:w-[35vw] lg:overflow-y-auto lg:px-4'>
                                    <div className='w-full flex flex-col items-start justify-center gap-5 '>
                                        <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>

                                            <div className='flex items-center justify-between w-full'>
                                                <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Mobile no.</h6>
                                            </div>
                                            <TextField
                                                id="input-with-icon-textfield"
                                                placeholder='Enter your mobile no.'
                                                helperText={mobile?.length > 0 && !mobileRegex?.test(mobile) ? "Please Enter Valid Mobile Number" : ""}
                                                error={mobile?.length > 0 && !mobileRegex?.test(mobile)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <CallIcon sx={{ color: 'rgba(55, 55, 55, 0.20)' }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                type='number'
                                                fullWidth
                                                value={mobile}
                                                onChange={(e) => setMobile(e?.target?.value)}
                                                // variant="standard"
                                                onKeyUp={(event) => {
                                                    if (event.key === 'Enter') {
                                                        handleSendOtp()
                                                    }
                                                }}
                                                size='small'
                                                className='custom-placeholder bg-white  border !border-[F4F4F4] !shadow-none '
                                                sx={{
                                                    color: 'rgba(55, 55, 55, 0.20)',
                                                    '& .MuiInputBase-root': {
                                                        [theme.breakpoints.down('sm')]: {
                                                            fontSize: '2vmax', // Font size for large screens
                                                        },
                                                        [theme.breakpoints.up('lg')]: {
                                                            fontSize: '.8vmax', // Font size for large screens
                                                        },
                                                    },
                                                }}
                                            />

                                        </div>

                                        <div className='flex flex-col items-center justify-center w-full lg:flex-col-reverse lg:items-start lg:justify-start lg:gap-3'>
                                            <LoadingButton
                                                loading={isLoading}
                                                sx={{ padding: '.6vmax 0', boxShadow: 'none' }}
                                                onClick={handleSendOtp}
                                                fullWidth
                                                disabled={
                                                    !(mobileRegex.test(mobile))
                                                }
                                                variant="contained"
                                                size="small"
                                            >
                                                <h6 className='text-[#FFFFFF] font-normal text-[1.7vmax] tracking-wider lg:text-[.8vmax]'>Request OTP</h6>
                                            </LoadingButton>
                                            <div onClick={props.onSignIn} className='flex cursor-pointer items-center justify-center w-full py-3 lg:py-1 rounded-sm lg:w-fit' >
                                                <h6 className='text-[#828282] font-normal text-[1.7vmax] tracking-wide lg:text-[.8vmax]'>Already have an account? <span className='text-[#3366FF]'>Sign in</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogContent>
                        </div>
                    </div>

                </Dialog>
            </div>
        </div>
    );
}
