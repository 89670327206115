import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Button, Checkbox, CircularProgress, ListItemText, OutlinedInput } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CheckBox } from '@mui/icons-material';
import InstrumentAutoComplete from './InstrumentAutoComplete';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

const TechnicalIndicatorsForm = ({ loading, from, to, symbol, interval, isVisible, rsi, vwap, sma, ema, changeEvent, onSubmit, perChange, bb }) => {
    return (
        <div className='flex flex-col justify-between gap-4 py-4'>
            <div className='flex flex-col lg:flex-row flex-wrap justify-between flex-1 gap-3'>
                <div className='w-full lg:w-[25%]'>
                    <FormControl fullWidth className='flex-1'>
                        <FormLabel id="market-type">Trade Symbol</FormLabel>
                        <InstrumentAutoComplete
                            inputObject={symbol}
                            textValue={symbol?.tradingvalue}
                            onChange={(value) => changeEvent(value, 'symbol')}
                        />
                    </FormControl>
                </div>
                <div className='w-full lg:w-fit flex-1 flex items-center gap-3'>
                    <FormControl className='flex-1'>
                        <FormLabel id="market-type">Date Range (Start)</FormLabel>
                        <OutlinedInput size='small' type='date' value={from} onChange={(e) => changeEvent(e?.target?.value, 'from')} />
                    </FormControl>
                    <FormControl className='flex-1'>
                        <FormLabel id="market-type">Date Range (End)</FormLabel>
                        <OutlinedInput size='small' type='date' value={to} onChange={(e) => changeEvent(e?.target?.value, 'to')} />
                    </FormControl>
                </div>
                <div className='w-full lg:w-[25%]'>
                    <FormControl fullWidth className='flex-1'>
                        <FormLabel id="interval">Interval</FormLabel>
                        <Select
                            labelId="interval-label"
                            id="interval-select"
                            label="Interval"
                            size='small'
                            value={interval}
                            onChange={(e) => changeEvent(e?.target?.value, 'interval')}
                        >
                            <MenuItem value={'minute'}>1 min</MenuItem>
                            <MenuItem value={'3minute'}>3 min</MenuItem>
                            <MenuItem value={'5minute'}>5 min</MenuItem>
                            <MenuItem value={'10minute'}>10 min</MenuItem>
                            <MenuItem value={'15minute'}>15 min</MenuItem>
                            <MenuItem value={'30minute'}>30 min</MenuItem>
                            <MenuItem value={'60minute'}>60 min</MenuItem>
                            <MenuItem value={'day'}>1 Day</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className='w-full flex justify-between items-center gap-5'>
                <div className='flex flex-wrap gap-3 flex-1'>
                    <FormControl className='flex flex-row justify-start items-center'>
                        <FormControlLabel
                            disabled={isVisible}
                            label="RSI"
                            control={<Checkbox checked={rsi} onChange={(e) => { changeEvent(!rsi, 'rsi') }} />}
                        />
                    </FormControl>
                    <FormControl className='flex flex-row justify-start items-center'>
                        <FormControlLabel
                            disabled={isVisible}
                            label="VWAP"
                            control={<Checkbox checked={vwap} onChange={(e) => { changeEvent(!vwap, 'vwap') }} />}
                        />
                    </FormControl>
                    <FormControl className='flex flex-row justify-start items-center'>
                        <FormControlLabel
                            disabled={isVisible}
                            label="% Change"
                            control={<Checkbox checked={perChange} onChange={(e) => { changeEvent(!perChange, 'perChange') }} />}
                        />
                    </FormControl>
                    <FormControl
                        disabled={isVisible}
                        size='small' sx={{ minWidth: 100 }}>
                        <InputLabel id="sma-label">SMA</InputLabel>
                        <Select
                            labelId="sma-label"
                            id="sma"
                            multiple
                            value={sma}
                            onChange={(e) => changeEvent(e?.target?.value, 'sma')}
                            input={<OutlinedInput size='small' label="SMA" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {[5, 10, 20, 50].map((count) => (
                                <MenuItem key={count} value={count}>
                                    <Checkbox checked={sma.indexOf(count) > -1} />
                                    <ListItemText primary={count + ' SMA'} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        disabled={isVisible}
                        size='small' sx={{ minWidth: 100 }}>
                        <InputLabel id="ema-label">EMA</InputLabel>
                        <Select
                            labelId="ema-label"
                            id="ema"
                            multiple
                            size='small'
                            value={ema}
                            onChange={(e) => changeEvent(e?.target?.value, 'ema')}
                            input={<OutlinedInput size='small' label="EMA" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {[5, 10, 20, 50].map((count) => (
                                <MenuItem key={count} value={count}>
                                    <Checkbox checked={ema.indexOf(count) > -1} />
                                    <ListItemText primary={count + ' EMA'} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        disabled={isVisible}
                        size='small' sx={{ minWidth: 100 }}>
                        <InputLabel id="bb-label">BOL</InputLabel>
                        <Select
                            labelId="bb-label"
                            id="bb"
                            multiple
                            size='small'
                            value={bb}
                            onChange={(e) => changeEvent(e?.target?.value, 'bb')}
                            input={<OutlinedInput size='small' label="BOL" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {[5, 10, 20, 50].map((count) => (
                                <MenuItem key={count} value={count}>
                                    <Checkbox checked={bb.indexOf(count) > -1} />
                                    <ListItemText primary={count} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className='flex w-full py-4 lg:py-0 justify-end items-end '>
                <Button
                    variant='contained'
                    size='small'
                    disabled={!from || !to || !symbol || !interval || loading}
                    onClick={() => onSubmit()}
                >
                    {loading ? <CircularProgress size={'24px'} /> : 'SUBMIT'}
                </Button>
            </div>
        </div>
    )
}

export default TechnicalIndicatorsForm;


const DateRange = () => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
            <DatePicker
                label="Basic date picker"

            />
        </DemoContainer>
    </LocalizationProvider>
)