import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  emailRegex,
  mobileRegex,
  nameRegex,
  passwordRegex,
  factorRegex,
} from "../ValidationRegex";

export default function AddUser(props) {
  const handleChangeData = (type, value) => {
    const _data = { ...props?.addUserData };
    _data[type] = value;
    props?.setAddUserData(_data);
  };

  return (
    <>
      <Dialog
        // fullScreen={fullScreen}
        open={props?.open}
        onClose={() => props?.onclose()}
        aria-labelledby="responsive-dialog-title"
        // BackdropProps={{ invisible: true }}
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="w-full flex items-center justify-between">
            {props?.isEdit ? "Edit user" : "Add new user"}
            {props?.isEdit && (
              <Button
                onClick={() => props?.openUpdateBrokerDialog()}
                className="min-w-[150px]"
                variant="outlined"
                size="small"
                color="primary"
              >
                Update Broker
              </Button>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="w-full flex flex-col flex-1  h-full items-center justify-center py-8 min-w-[20vw] lg:items-start gap-5">
            <TextField
              id="outlined-basic"
              label="Name*"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={props?.addUserData?.name}
              onChange={(e) => handleChangeData("name", e?.target?.value)}
              error={
                props?.addUserData?.name?.length > 0 &&
                !nameRegex.test(props?.addUserData?.name)
              }
              helperText={
                props?.addUserData?.name?.length > 0 &&
                !nameRegex.test(props?.addUserData?.name)
                  ? "Enter valid name"
                  : ""
              }
            />
            <TextField
              id="outlined-basic"
              label="Email Address*"
              variant="outlined"
              size="small"
              type="email"
              fullWidth
              value={props?.addUserData?.email}
              onChange={(e) => handleChangeData("email", e?.target?.value)}
              error={
                props?.addUserData?.email?.length > 0 &&
                !emailRegex.test(props?.addUserData?.email)
              }
              helperText={
                props?.addUserData?.email?.length > 0 &&
                !emailRegex.test(props?.addUserData?.email)
                  ? "Enter valid email"
                  : ""
              }
            />
            <TextField
              id="outlined-basic"
              label="Mobile*"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              value={props?.addUserData?.mobile}
              onChange={(e) => handleChangeData("mobile", e?.target?.value)}
              error={
                props?.addUserData?.mobile?.length > 0 &&
                !mobileRegex.test(props?.addUserData?.mobile)
              }
              helperText={
                props?.addUserData?.mobile?.length > 0 &&
                !mobileRegex.test(props?.addUserData?.mobile)
                  ? "Enter valid mobile no."
                  : ""
              }
            />
            <TextField
              id="outlined-basic"
              label="Factor*"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={props?.addUserData?.factor}
              error={
                props?.addUserData?.factor?.length > 0 &&
                !factorRegex.test(props?.addUserData?.factor)
              }
              helperText={
                props?.addUserData?.factor?.length > 0 &&
                !factorRegex.test(props?.addUserData?.factor)
                  ? "Enter valid factor"
                  : ""
              }
              onChange={(e) => handleChangeData("factor", e?.target?.value)}
            />
            <TextField
              id="outlined-basic"
              label="Password*"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={props?.addUserData?.password}
              onChange={(e) => handleChangeData("password", e?.target?.value)}
              error={
                props?.addUserData?.password?.length > 0 &&
                !passwordRegex.test(props?.addUserData?.password)
              }
              helperText={
                props?.addUserData?.password?.length > 0 &&
                !passwordRegex.test(props?.addUserData?.password)
                  ? "Enter valid password"
                  : ""
              }
            />
            <TextField
              id="outlined-basic"
              label="Confirm Password*"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={props?.confirmPassword}
              error={
                props?.confirmPassword?.length > 0 &&
                props.addUserData?.password != props?.confirmPassword
              }
              helperText={
                props?.confirmPassword?.length > 0 &&
                props.addUserData?.password != props?.confirmPassword
                  ? "Password not Matched"
                  : ""
              }
              onChange={(e) => props?.setConfirmPassword(e?.target?.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: "red" }}
            autoFocus
            onClick={() => props?.onclose()}
          >
            Close
          </Button>
          {props?.isEdit ? (
            <LoadingButton
              loading={props?.isLoading}
              onClick={() => props?.onUpdate()}
              sx={{ width: "fit-content" }}
              fullWidth
              variant="contained"
              size="small"
              disabled={
                !(
                  (
                    nameRegex.test(props?.addUserData?.name) &&
                    mobileRegex.test(props?.addUserData?.mobile) &&
                    emailRegex.test(props?.addUserData?.email)
                  )
                  // && passwordRegex.test(props?.addUserData?.password)
                  // && props?.addUserData?.password === props?.confirmPassword
                )
              }
            >
              Update
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={props?.isLoading}
              onClick={() => props?.onAdd()}
              sx={{ width: "fit-content" }}
              fullWidth
              variant="contained"
              size="small"
              disabled={
                !(
                  nameRegex.test(props?.addUserData?.name) &&
                  mobileRegex.test(props?.addUserData?.mobile) &&
                  emailRegex.test(props?.addUserData?.email) &&
                  passwordRegex.test(props?.addUserData?.password) &&
                  props?.addUserData?.password === props?.confirmPassword
                )
              }
            >
              ADD
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
