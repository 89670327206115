import React from "react";
const StaticsCard = (props) => {
  return (
    <>
      <div className="flex bg-[#FBFBFB] shadow-sm border border-[#EAEAEA] rounded lg:border-none lg:rounded-none lg:shadow-none lg:bg-transparent px-3  py-4 items-center gap-2 w-[48%] lg:w-fit lg:gap-2">
        <div className="flex items-center justify-center ">
          {props?.icon}
        </div>
        <div className="">
          <h3 className="text-[#393939] font-semibold text-[1.8vmax] lg:text-[1vmax]">
            {props?.count}
          </h3>
          <p className="text-[#717171] fonr-[Poppins] text-[1.5vmax] lg:text-[.9vmax]">
            {props?.heading}
          </p>
        </div>
      </div>
    </>
  );
};

export default StaticsCard;
