import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import WolfLogo from '../../assets/wolf-logo 1.png'
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import LoginPageImg from '../../assets/Rectangle 447.png'
import CloseIcon from '@mui/icons-material/Close';
import { ApiService, http } from '../../Services/api.services';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { emailRegex, passwordRegex } from '../../ValidationRegex'
import { asyngetStatus, showToast } from '../../store/Actions/Action'
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { Button, Skeleton } from '@mui/material';
import EnterOtp from './EnterOtp';
const customPlaceholderStyle = {
  color: 'red', // Change the color to your desired color
  fontFamily: 'Arial, sans-serif', // Change the font family to your desired font
};

const _form = {
  email: '',
  password: ''
}

const api = new ApiService()

export default function Login(props) {

  const dispatch = useDispatch();
  const [openResetPassword, setOpenResetPassword] = React.useState(false);
  const navigate = useNavigate()

  // handle Password Visiblity

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // handle Password Visiblity

  const [signinForm, setSigninForm] = React.useState({ ..._form })
  const [isLoading, setIsLoading] = React.useState(false)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [showImage, setShowImage] = React.useState(false)

  // Function to mask the mobile number
  const maskMobileNumber = (mobile) => {
    if (mobile.length === 10) {
      // Replace middle 4 digits with 'x'
      return mobile.substring(0, 2) + 'xxx' + mobile.substring(6);
    } else {
      return mobile;
    }
  };


  const sendOTP = async () => {
    try {
      const response = await http.post('/user/send-otp', { email: props?.signinForm?.email, type: 'forgot password' })
      if (response?.status == 200) {
        const _snackbar = {
          message: `OTP Successfully send to Registered Mobile No.   ${maskMobileNumber(response?.data?.mobile)}`,
          open: true,
          duration: 3000,
          type: 'success'
        };
        dispatch(showToast(_snackbar))
        props?.onEnterOtp();
      } else {
        const _snackbar = {
          message: response?.data?.message,
          open: true,
          duration: 3000,
          type: 'error'
        };
        dispatch(showToast(_snackbar))
      }
    } catch (e) {
      console.log(e);
    }
  }

  const onFormChange = (name, value) => {
    try {
      const form = { ...props?.signinForm }
      form[name] = value
      props?.setSigninForm(form)
    }
    catch (e) {
      console.log(`ERROR WHILE FORM CHANGE --- ${e}`)
    }
  }

  const handleLogin = async () => {
    try {
      if (emailRegex.test(props?.signinForm.email) && passwordRegex.test(props?.signinForm.password)) {
        setIsLoading(true)
        const response = await api.login(props?.signinForm)
        // console.log(response.data.token);
        const _snackbar = {
          message: response.message,
          open: true,
          duration: 3000,
          type: response.status
        };

        if (response.status === 'success') {
          // Set Token in local storage 
          localStorage.setItem("user", JSON.stringify(response.data));
          setIsLoading(false);
          props.onClose();
          navigate('/equity')
        } else {
          setIsLoading(false);
        }
        dispatch(showToast(_snackbar));
        // setSigninForm(_form); //NOT REQUIRED
      }
      else {
        const _snackbar = {
          message: 'Invalid values',
          open: true,
          duration: 3000,
          type: 'error'
        };
        dispatch(showToast(_snackbar));
      }
    }
    catch (e) {
      console.log(`ERROR WHILE LOGIN --- ${e}`)
      setIsLoading(false);
    }
  }

  const inputStyle = {
    // color: 'rgba(55, 55, 55, 0.20)',
    '& .MuiInputBase-root': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '2vmax', // Font size for large screens
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '.8vmax', // Font size for large screens
      },
      bgcolor: '#fff'
    },

  }

  const handleImageLoad = () => {
    setShowImage(true);
  };

  const handleImageError = () => {
    setShowImage(false);
  };


  // Create a new Image object to handle loading
  const image = new Image();
  image.src = LoginPageImg;
  image.onload = handleImageLoad;
  image.onerror = handleImageError;


  return (

    <div>
      <div className='w-full'>
        <Dialog
          fullScreen={fullScreen}
          open={props.open}
          onClose={() => props.onClose()}
          aria-labelledby="responsive-dialog-title"
          sx={{
            '& .css-m9glnp-MuiPaper-root-MuiDialog-paper': {
              backgroundColor: 'rgba(248, 248, 248, 0.99)'
            }
          }}
        >
          <div style={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }} className='w-full flex'>
            {showImage ? (
              <div className='hidden lg:flex w-full bg-[#000000]'>
                <img
                  className='w-full h-full object-contain'
                  src={LoginPageImg}
                  alt="LoginPageImg"
                // onLoad={handleImageLoad}
                // onError={handleImageError}
                />
              </div>
            ) : (
              <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.11)' }} className='hidden lg:block'>
                <Skeleton width={250} height={'100%'} />
              </div>
            )}
            {/* <img className='w-full h-full object-contain' src={LoginPageImg} alt="LoginPageImg" /> */}
            <div className='w-full'>
              <DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }}>
                <div className='flex flex-col items-center justify-center gap-3 py-6 lg:py-1'>
                  <img src={WolfLogo} alt="WolfLogo" />
                  {/* <h6 className='block lg:hidden text-[#373737]  font-semibold tracking-wider text-[2vmax]'>Hello ! Welcome Back</h6> */}
                  <h6 className='text-[#373737]  font-normal tracking-wider text-[1.5vmax]'>Hello ! Welcome Back</h6>
                  <CloseIcon onClick={() => props.onClose()} sx={{ position: "absolute", top: "4%", right: "1%", transform: 'translate(-50%,-50%)' }} />
                </div>
              </DialogTitle>

              <DialogContent sx={{ backgroundColor: 'rgba(248, 248, 248, 0.99)' }} >
                <div className='input_area flex items-center justify-center w-full h-[65vh] lg:h-[50vh] lg:w-[35vw] lg:overflow-y-auto lg:px-4'>
                  <div className='w-full flex flex-col items-start justify-center gap-5 '>
                    <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>

                      <h6 className='text-[#373737]  font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>E-mail</h6>
                      <TextField
                        id="input-with-icon-textfield"
                        onKeyUp={(event) => {
                          if (event.key === 'Enter') {
                            handleLogin()
                          }
                        }}
                        placeholder='Enter your email address'
                        helperText={props?.signinForm.email?.length > 0 && !emailRegex?.test(props?.signinForm.email) ? "Please Enter Valid Email" : ""}
                        error={props?.signinForm.email?.length > 0 && !emailRegex?.test(props?.signinForm.email)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon sx={{ color: 'rgba(55, 55, 55, 0.20)' }} />
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        // variant="standard"
                        size='small'
                        className='custom-placeholder  border !border-[F4F4F4] !shadow-none '
                        sx={inputStyle}
                        type='email'
                        value={props?.signinForm.email}
                        onChange={(e) => onFormChange('email', e?.target?.value)}
                      />

                    </div>
                    <div className='w-full  flex flex-col items-start gap-3 lg:gap-2'>

                      <h6 className='text-[#373737]  font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Password</h6>
                      <TextField
                        id="input-with-icon-textfield"
                        placeholder='Enter your Password '
                        helperText={props?.signinForm.password?.length > 0 && !passwordRegex?.test(props?.signinForm.password) ? " password should have atleast 5 character" : ""}
                        error={props?.signinForm.password?.length > 0 && !passwordRegex?.test(props?.signinForm.password)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon sx={{ color: 'rgba(55, 55, 55, 0.20)' }} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {showPassword ? (
                                <VisibilityIcon
                                  onClick={handleClickShowPassword}
                                  sx={{ cursor: 'pointer', color: 'rgba(55, 55, 55, 0.20)' }}
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={handleClickShowPassword}
                                  sx={{ cursor: 'pointer', color: 'rgba(55, 55, 55, 0.20)' }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}

                        fullWidth
                        onKeyUp={(event) => {
                          if (event.key === 'Enter') {
                            handleLogin()
                          }
                        }}
                        type={showPassword ? 'text' : 'password'}
                        size="small"
                        className='custom-placeholder  border !border-[F4F4F4] !shadow-none'
                        sx={inputStyle}
                        value={props?.signinForm.password}
                        onChange={(e) => onFormChange('password', e?.target?.value)}
                      />
                    </div>
                    <div className='flex cursor-pointer items-center justify-end w-full'>
                      <Button
                        onClick={() => { sendOTP(); }}
                      >
                        <h6 className='text-[#5367FF]   font-normal lg:font-medium text-[1.4vmax] tracking-wide lg:text-[.8vmax]'>Reset Password</h6>
                      </Button>
                    </div>
                    <LoadingButton
                      loading={isLoading}
                      sx={{ padding: '.6vmax 0', boxShadow: 'none' }}
                      onClick={handleLogin}
                      fullWidth
                      disabled={
                        !(emailRegex.test(props?.signinForm.email) && passwordRegex.test(props?.signinForm.password))
                      }
                      variant="contained"
                      size="small"
                    >
                      <h6 className='text-[#FFFFFF]  font-normal text-[2vmax] tracking-wider lg:text-[.8vmax]'>Login</h6>
                    </LoadingButton>


                    <button className='flex items-center justify-center w-full py-3 lg:py-1 rounded-sm ' >
                      <h6 className='text-[#373737]  font-normal text-[1.7vmax] tracking-wide lg:text-[.8vmax]'>Don’t have an account? <span className='text-[#5367FF]' onClick={() => props.onSignUp()}>Sign up</span></h6>
                    </button>
                  </div>
                </div>
              </DialogContent>
            </div>
          </div>

        </Dialog >
      </div >
    </div >
  );
}
