import React from 'react'
import SingleCard from "../PublicComponents/SingleCard"
import { useMarketContext } from '../Contexts/MarketContext/MarketContext';

const Exchanges = () => {
    const market = useMarketContext();
    const memoizedMarket = React.useMemo(() => market, [market]);
    const nifty = memoizedMarket?.nifty;
    const banknifty = memoizedMarket?.banknifty;
    const midcpnifty = memoizedMarket?.midcpnifty;
    const finnifty = memoizedMarket?.finnifty;
    const sensex = memoizedMarket?.sensex;
    const indiavix = memoizedMarket?.indiaVix;
    const giftnifty = memoizedMarket?.giftNifty;



    return (
        <>
            <div className='CardDiv w-full flex flex-wrap items-center p-6 gap-5 lg:gap-10 overflow-y-auto'>
                <SingleCard
                    title={'BANK NIFTY/NIFTY'}
                    nifty={nifty}
                    index={banknifty}
                    percentValue={banknifty?.changePercent / nifty?.changePercent}
                />
                <SingleCard
                    title={'FIN/NIFTY'}
                    nifty={nifty}
                    index={finnifty}
                />
                <SingleCard
                    title={'MIDCP NIFTY/NIFTY'}
                    nifty={nifty}
                    index={midcpnifty}
                />
                <SingleCard
                    title={'BANK NIFTY/NIFTY'}
                    nifty={nifty}
                    index={banknifty}
                />
                <SingleCard
                    title={'SENSEX/NIFTY'}
                    nifty={nifty}
                    index={sensex}
                />
                <SingleCard
                    title={'GIFT NIFTY/NIFTY'}
                    nifty={nifty}
                    index={giftnifty}
                />
                <SingleCard
                    title={'INDIA VIX/NIFTY'}
                    nifty={nifty}
                    index={indiavix}
                />
            </div>
        </>
    )
}

export default Exchanges    
