import React from 'react'
import Header from '../PublicComponents/Header'
import SingleCard from "../PublicComponents/SingleCard"

const Indexes = () => {
    return (
        <>
            <Header />
            <div className='CardDiv w-full flex flex-wrap items-center py-4 px-2 justify-between gap-5 lg:gap-10 max-h-[55vh] lg:max-h-[65vh] overflow-y-auto'>
                {/* <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard /> */}
            </div>
        </>
    )
}

export default Indexes