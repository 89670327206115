import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ArrowRight } from '../PublicComponents/Icons';
import TextField from '@mui/material/TextField';
import DashboardTable from './DashboardTable';
import { v4 as uuidv4 } from 'uuid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// Top Companies

const companyColumns = [
    {
        field: 'Time', headerName: <h6 className='text-[1.5vmax] lg:text-[.8vmax] text-[#5C5C5C] font-semibold tracking-wider'>Time</h6>, minWidth: 110,
        flex: 1,
    },
    {
        field: 'Signal', headerName: <h6 className='text-[1.5vmax] lg:text-[.8vmax] text-[#5C5C5C] font-semibold tracking-wider'>Signal</h6>, minWidth: 110,
        flex: 1,
        renderCell: (params) => {


            return (
                <div className='border border-[#00B386] rounded-2xl px-4 py-1'>
                    <h6 className='text-[#00B386] text-[1.4vmax] lg:text-[.7vmax]'>Buy</h6>
                </div>
            );
        },
    },
    {
        field: 'Quantity', headerName: <h6 className='text-[1.5vmax] lg:text-[.8vmax] text-[#5C5C5C] font-semibold tracking-wider'>Quantity</h6>, minWidth: 110,
        flex: 1,
    },
    {
        field: 'Price', headerName: <h6 className='text-[1.5vmax] lg:text-[.8vmax] text-[#5C5C5C] font-semibold tracking-wider'>Price</h6>, minWidth: 110,
        flex: 1,
    },
    {
        field: 'Profit', headerName: <h6 className='text-[1.5vmax] lg:text-[.8vmax] text-[#5C5C5C] font-semibold tracking-wider'>Profit</h6>, minWidth: 100,
        renderCell: (params) => {
            return (
                <h6 className='text-[#00B386] text-[1.4vmax] lg:text-[.8vmax]'>+300.89</h6>
            );
        },
    },
];

const companyRows = [

    {
        Time: '12:30:00',
        id: uuidv4(),
        Quantity: '100',
        Price: '150.56',
    },
    {
        Time: '12:30:00',
        id: uuidv4(),
        Quantity: '100',
        Price: '150.56',
    },
    {
        Time: '12:30:00',
        id: uuidv4(),
        Quantity: '100',
        Price: '150.56',
    },
    {
        Time: '12:30:00',
        id: uuidv4(),
        Quantity: '100',
        Price: '150.56',
    },
    {
        Time: '12:30:00',
        id: uuidv4(),
        Quantity: '100',
        Price: '150.56',
    },

];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const AlgorithmDialog = (props) => {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                fullScreen={fullScreen}
                onClose={props.onClose}
                open={props.open}
            // open='true'
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#FFFFFF' }} id="customized-dialog-title">
                    <h6 className='text-[#3E3E3E] text-[2vmax] font-semibold lg:text-[1.1vmax]'>ALGORITHM 01</h6>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon sx={{ color: "#3E3E3E" }} />
                </IconButton>

                <DialogContent sx={{ backgroundColor: "#FFFFFF" }} dividers>
                    <div>
                        <div className='w-fit flex flex-col items-start gap-1'>
                            <h6 className='text-[#3E3E3E] text-left text-[1.5vmax] font-medium lg:text-[.8vmax]'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,</h6>
                            <h6 className='text-[#000000] font-semibold text-left text-[1.5vmax] my-1 lg:text-[1vmax]'>Accuracy: <span className='text-[#00B386]'>96%</span></h6>
                            <div className='w-full py-2 flex items-center gap-5'>
                                <div className='bg-[#FFFFFF] shadow rounded px-3 py-1 w-3/12'>
                                    <h6 className='text-[#939393] text-[1.7vmax] lg:text-[.8vmax]'>Subscribers</h6>
                                    <div className='flex items-center gap-1 lg:gap-2'>
                                        <p className='text-[#1C1C1C] font-semibold text-[2vmax] lg:text-[1vmax]'>20899</p>
                                        <ArrowRight />
                                    </div>
                                </div>
                                <div className='bg-[#FFFFFF] shadow rounded px-3 py-1 w-3/12'>
                                    <h6 className='text-[#939393] text-[1.7vmax] lg:text-[.8vmax]'>Net profit</h6>
                                    <div className='flex items-center gap-1 lg:gap-2'>
                                        <p className='text-[#00B386] font-semibold text-[2vmax] lg:text-[1vmax]'>+20899</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='flex items-center justify-between'>
                                <div className='w-fit'>
                                    <h6 className='text-[#1C1C1C] font-semibold tracking-wider text-[1.5vmax] lg:text-[1vmax]'>Transactions</h6>
                                    <div className='bg-[#1C1C1C] w-full h-[2px] rounded'></div>
                                </div>
                                <div>
                                    <TextField id="outlined-basic" type='date' size='small' sx={{
                                        fontSize: '.8vmax !important'
                                    }} variant="outlined" />
                                </div>
                            </div>
                        </div>
                        <div className='py-2'>
                            <div className='flex items-center py-2 justify-between w-full'>
                                <h6 className='text-[#1C1C1C] text-[1.3vmax] font-semibold lg:text-[.8vmax]'>Timestamp</h6>
                                <h6 className='text-[#1C1C1C] text-[1.3vmax] font-semibold lg:text-[.8vmax]'>Symbol</h6>
                                <h6 className='text-[#1C1C1C] text-[1.3vmax] font-semibold lg:text-[.8vmax]'>Signal</h6>
                                <h6 className='text-[#1C1C1C] text-[1.3vmax] font-semibold lg:text-[.8vmax]'>Quantity</h6>
                                <h6 className='text-[#1C1C1C] text-[1.3vmax] font-semibold lg:text-[.8vmax]'>Price</h6>
                                <h6 className='text-[#1C1C1C] text-[1.3vmax] font-semibold lg:text-[.8vmax]'>Close</h6>
                                <h6 className='text-[#1C1C1C] text-[1.3vmax] font-semibold lg:text-[.8vmax]'>Profit</h6>
                            </div>
                            <div>
                                <div>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ marginLeft: '1vmax', color: '#1C1C1C' }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            sx={{ padding: 'initial' }}
                                        >
                                            <div className='flex items-center  justify-between w-full'>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>11:30:00-12:45:00</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>AAPL</h6>
                                                <div className='border w-fit border-[#00B386] rounded-2xl px-4 py-1'>
                                                    <h6 className='text-[#00B386] text-[1.4vmax] lg:text-[.7vmax]'>Buy</h6>
                                                </div>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>100</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>150.56</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>Yes</h6>
                                                <h6 className='text-[#00B386] text-[1.3vmax] lg:text-[.9vmax]'>890.67</h6>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <DashboardTable columns={companyColumns} rows={companyRows} />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ marginLeft: '1vmax', color: '#1C1C1C' }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            sx={{ padding: 'initial' }}
                                        >
                                            <div className='flex items-center  justify-between w-full'>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>11:30:00-12:45:00</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>AAPL</h6>
                                                <div className='border w-fit border-[#00B386] rounded-2xl px-4 py-1'>
                                                    <h6 className='text-[#00B386] text-[1.4vmax] lg:text-[.7vmax]'>Buy</h6>
                                                </div>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>100</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>150.56</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>Yes</h6>
                                                <h6 className='text-[#00B386] text-[1.3vmax] lg:text-[.9vmax]'>890.67</h6>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <DashboardTable columns={companyColumns} rows={companyRows} />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ marginLeft: '1vmax', color: '#1C1C1C' }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            sx={{ padding: 'initial' }}
                                        >
                                            <div className='flex items-center  justify-between w-full'>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>11:30:00-12:45:00</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>AAPL</h6>
                                                <div className='border w-fit border-[#00B386] rounded-2xl px-4 py-1'>
                                                    <h6 className='text-[#00B386] text-[1.4vmax] lg:text-[.7vmax]'>Buy</h6>
                                                </div>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>100</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>150.56</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>Yes</h6>
                                                <h6 className='text-[#00B386] text-[1.3vmax] lg:text-[.9vmax]'>890.67</h6>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <DashboardTable columns={companyColumns} rows={companyRows} />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ marginLeft: '1vmax', color: '#1C1C1C' }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            sx={{ padding: 'initial' }}
                                        >
                                            <div className='flex items-center  justify-between w-full'>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>11:30:00-12:45:00</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>AAPL</h6>
                                                <div className='border w-fit border-[#00B386] rounded-2xl px-4 py-1'>
                                                    <h6 className='text-[#00B386] text-[1.4vmax] lg:text-[.7vmax]'>Buy</h6>
                                                </div>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>100</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>150.56</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>Yes</h6>
                                                <h6 className='text-[#00B386] text-[1.3vmax] lg:text-[.9vmax]'>890.67</h6>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <DashboardTable columns={companyColumns} rows={companyRows} />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ marginLeft: '1vmax', color: '#1C1C1C' }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            sx={{ padding: 'initial' }}
                                        >
                                            <div className='flex items-center  justify-between w-full'>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>11:30:00-12:45:00</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>AAPL</h6>
                                                <div className='border w-fit border-[#00B386] rounded-2xl px-4 py-1'>
                                                    <h6 className='text-[#00B386] text-[1.4vmax] lg:text-[.7vmax]'>Buy</h6>
                                                </div>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>100</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>150.56</h6>
                                                <h6 className='text-[#1C1C1C] text-[1.3vmax] lg:text-[.9vmax]'>Yes</h6>
                                                <h6 className='text-[#00B386] text-[1.3vmax] lg:text-[.9vmax]'>890.67</h6>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <DashboardTable columns={companyColumns} rows={companyRows} />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: "rgba(0, 179, 134, 0.03)" }}>
                    <div className='flex items-start justify-between w-full px-2'>
                        <h6 className='text-[#666666] text-[1.2vmax] lg:text-[.8vmax]'>Created at -12/10/23  14:44</h6>
                        <div className='flex flex-col lg:flex-row  items-end lg:items-center gap-1 lg:gap-3'>
                            <p className='text-[#3D3D3D] font-medium text-[1.3vmax] lg:text-[.8vmax]'>7 days trial period</p>
                            <small className='text-[#1C1C1C] font-semibold text-[2vmax] lg:text-[1vmax]'>$19,999</small>
                        </div>
                    </div>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default AlgorithmDialog