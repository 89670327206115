import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { useDispatch, useSelector } from "react-redux";

const menuItemStyle = {
  fontSize: 12,
  padding: "6px 12px",
  width: 70,
};
const menuItm = [
  { label: "1 Min", value: 1 },
  { label: "3 Min", value: 3 },
  { label: "5 Min", value: 5 },
  { label: "10 Min", value: 10 },
  { label: "15 Min", value: 15 },
  { label: "30 Min", value: 30 },
  { label: "60 Min", value: 60 },
];

const GraphHeader = ({ interval, title, setIntervalFunction }) => {
  const [menu, setMenu] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  React.useEffect(() => {
    // if (title == "SL Chart") {
    //   menuItm.unshift({ label: "1 Min", value: 1 });
    // }
    setMenu(menuItm);
  }, []);

  // let localName = title == 'IHM Chart' ? 'interval' : 'slInterval';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setIntervalFunction(event.target.value);
    setAnchorEl(null);
  };  

  return (
    <>
      <div className="w-full flex items-start justify-between">
        <h6 className="text-xs text-[#878787] bg-[#F3FBFD] py-2 shadow px-3">
          {title}
        </h6>

        <div
          onClick={handleClick}
          className="border cursor-pointer border-[#D2D2D2] flex my-1 mx-1 items-center gap-1 rounded px-2 py-1 bg-white shadow"
        >
          <h6 className="text-[10px] font-bold whitespace-nowrap">
            {interval} Min
          </h6>
          <KeyboardArrowDownIcon
            sx={{
              fontSize: "12px",
            }}
          />
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {menu?.map((el, idx) => (
            <MenuItem
              key={idx}
              selected={el?.value == interval}
              sx={menuItemStyle}
              value={el?.value}
              onClick={handleChange}
            >
              {el?.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  );
};

export default GraphHeader;
