import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { StarIcon } from "../../PublicComponents/Icons";
import HeaderComponent from "../../EquityComponents/HeaderComponent";
import { http } from "../../Services/api.services";
import { useThemeContext } from "../../Theme/ThemeContext";
import { Box, Divider, IconButton } from "@mui/material";
import { H3, H4, H5, H6, P } from "../../Theme/ThemeComponent";
import CompanyTable from "../../EquityComponents/CompanyTable";
import AlgoFilter from "../../EquityComponents/AlgoFIlter";
import { BuyButton, SellButton } from "../../EquityComponents/EquityButtons";
import AddIcon from '@mui/icons-material/Add';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentAlgo, setCurrentOrderType, setCurrentSelectedOrder, setOpenAddStock, setOpenOrderform, setSelectedCompany } from "../../store/Reducer/EquityReducer";
import Rating from '@mui/material/Rating';
import moment from "moment";
import OrderDrawer from "../../EquityComponents/OrderDrawer";
import AddInstrumentInWatchlist from "../../EquityComponents/AddInstrumentInWatchlist";
import { BASE_URL, WEBSOCKET_API_URL, WEBSOCKET_URL } from "../../default.config";
import { showToast } from "../../store/Actions/Action";

const _dateFilter = {
  startDate: '',
  endDate: '',
}

const __filterData = {
  statuses: {
    open: false,
    close: false
  },
  order_types: {
    buy: false,
    sell: false
  },
  sectors: []
}

const AlgoDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { theme } = useThemeContext();
  const _id = location.pathname.split('/')[3];
  const { user } = useSelector((state) => state.user);
  const [openOrderDrawer, setOpenOrderDrawer] = React.useState(false)
  const [order_types, setOrder_types] = React.useState([])
  const [orderType, setOrderType] = React.useState('buy')
  const [_type, setType] = React.useState('intraday')
  const [currentCompany, setCurrentCompany] = React.useState(null)
  const { currentAlgo } = useSelector((state) => state.equity);
  const [open, setOpen] = React.useState(false)
  const [filterDate, setFilterDate] = React.useState({ ..._dateFilter })
  const [filterData, setFilterData] = React.useState({ ...__filterData })
  const [openSelectWatchlists, setOpenSelectWatchlists] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedWatchlists, setSelectedWatchlists] = React.useState([]);
  const [currentRating, setCurrentRating] = React.useState(1)
  const [totalRows, setTotalRows] = React.useState(100);
  const [skip, setSkip] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 20,
    page: 0,
  });
  // GET CURRENT ALGORITHM DETAILS 
  const getAlgoDetail = async (skip, limit, rating) => {
    try {
      const _body = {
        algo_id: _id,
        skip: skip,
        limit: limit,
        rating: rating,
      }
      const response = await http.post(`algo/signal`, _body);

      if (response?.status === 200) {
        dispatch(setCurrentAlgo(response?.data));
        setTotalRows(response?.data?.total_signals);
      }
    } catch (e) {
      console.log(`ERROR WHILE FETCHING SIGNALS ${e}`);
    }
  }


  React.useEffect(() => {
    getAlgoDetail(skip, limit, currentRating);
  }, [skip, limit]);

  // ALGO DETAILS TABLE COLUMN 
  const columns = [
    {
      field: "symbol",
      headerName: "Company",
      minWidth: 180,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        return (
          <div className="flex w-full p-2 flex-col items-start justify-start gap-1">
            <a
              href="#"
              className=" hover:underline cursor-pointer" // Ensure it looks like a link
              onClick={(event) => {
                event.preventDefault(); // Prevents default link behavior
                event.stopPropagation(); // Prevents triggering the row click event
                const companyId = params?.row?.instrument_token;
                navigate(`/equity/company/${companyId}`);
              }}
            >
              {params?.row?.symbol}
            </a>
            <div className="w-full p-1 border rounded flex items-center justify-center">
              <Rating size="small" name="read-only" value={params?.row?.rating} readOnly />
            </div>
          </div>
        );
      },
    },
    {
      field: "last_price",
      headerName: "LTP",
      minWidth: 110,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 110,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: "trade",
      headerName: "Trade",
      minWidth: 110,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        const tradeValue = params?.value == 'buy' ? 'Long' : "Short"
        return (
          <div >
            {tradeValue}
          </div>
        );
      },
    },
    {
      field: "entry_price",
      headerName: "Signal Entry Price",
      minWidth: 110,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: "entry_date",
      headerName: "Signal Entry Date/Time",
      minWidth: 110,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        const formattedDate = moment(params?.row?.entry_date).format('DD-MM-YYYY');
        const formattedTime = moment(params?.row?.entry_date).format('HH:mm:ss');
        return (
          <div className="flex w-fit p-2 flex-col items-start justify-start gap-1">
            <H6
              title={formattedDate}
              color={theme.palette.text.charcolGray}
              font='normal'
            />
            <P
              title={formattedTime}
              color={theme.palette.text.silverGray}
              font='bold'
            />
          </div>
        );
      },
    },
    {
      field: "open_long_trade",
      headerName: "Best Entry",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: "exit_price",
      headerName: "Signal Exit Price",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: "open_short_trade",
      headerName: "Signal Exit Date/Time",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: "profit_or_loss",
      headerName: "P/L",
      align: 'left',
      headerAlign: "left",
      renderHeader: () => (
        <Box textAlign="left">
          <div className="flex w-fit items-center justify-center gap-1">
            <H6 title={'P'} color={theme.palette.text.green} />
            <H6 title={'/'} />
            <H6 title={'L'} color={theme.palette.text.red} />
          </div>
        </Box>
      ),
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        // Get the formatted value
        const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';

        // Determine color based on original value (sign) 
        const color = params?.value >= 0 ? 'green' : 'red';

        return (
          <div style={{ color }}>
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "roi",
      headerName: "ROI",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        // Get the formatted value
        const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';
        // Determine color based on original value (sign) 
        const color = params?.value >= 0 ? 'green' : 'red';
        return (
          <div style={{ color }}>
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 170,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => {
        const { row } = params;
        return (
          <div
            onClick={(event) => {
              event.stopPropagation(); // Prevents triggering the row click event
            }}
            className="flex items-center justify-center  gap-2">
            <BuyButton
              onClick={(event) => {
                event.stopPropagation(); // Prevents triggering the row click event
                dispatch(setOpenOrderform(true))
                dispatch(setCurrentSelectedOrder(row));
                dispatch(setCurrentOrderType('buy'));
              }}
            >
              B
            </BuyButton>
            <SellButton
              onClick={() => {
                dispatch(setOpenOrderform(true))
                dispatch(setCurrentSelectedOrder(row));
                dispatch(setCurrentOrderType('sell'));
              }}
            >
              S
            </SellButton>
            <Button variant="outlined"
              sx={{
                padding: 1,
                minWidth: '0px'
              }}
              onClick={() => {
                dispatch(setOpenAddStock(true));
                dispatch(setSelectedCompany(row));
              }}
            >
              <AddIcon fontSize="small" sx={{ fontSize: '15px !important' }} />
            </Button>
          </div >
        );
      },
    },
  ];

  // FILTER ALGO DATA 
  const filterAlgoData = async (skip, limit, rating) => {
    setIsLoading(true)
    try {
      const statuses = Object.keys(filterData.statuses).filter((key) => filterData.statuses[key]);
      const order_types = Object.keys(filterData.order_types).filter((key) => filterData.order_types[key]);
      if ((filterDate.startDate && !filterDate.endDate) || (!filterDate.startDate && filterDate.endDate)) {
        alert('Both start date and end date must be provided.');
        return
      }
      const _body = {
        algo_id: _id,
        skip: skip,
        limit: limit,
        rating: rating,
        statuses: statuses,
        order_types: order_types,
        ...(filterDate.startDate && filterDate.endDate ? { from_date: filterDate.startDate } : ''),
        ...(filterDate.startDate && filterDate.endDate ? { to_date: filterDate.endDate } : ''),
        industry_keys: filterData?.sectors
      };

      const response = await http.post(`algo/signal`, _body);

      setIsLoading(false)
      if (response?.status == 200) {
        setOpen(false)
        dispatch(setCurrentAlgo(response?.data));
        setTotalRows(response?.data?.total_signals);
      }
    } catch (e) {
      setIsLoading(false)
      console.log(`ERROR WHILE FETCHING SIGNALS ${e}`);
    }
  }

  // RESET FILTER VALUES 
  const resetFilter = () => {
    try {
      setOpen(false)
      setFilterData({
        statuses: {
          open: false,
          close: false
        },
        order_types: {
          buy: false,
          sell: false
        },
        sectors: []
      });
      setSkip(0);
      setLimit(20); // Reset limit to 20
      setFilterDate(_dateFilter);
      getAlgoDetail(0, 20, currentRating);
    } catch (e) {
      console.log(`ERROR WHILE RESETING FILTER ${e}`);
    }
  }

  // GET CURRENT COMPANY DATA BY SOCKET EVENT 
  React.useEffect(() => {
    try {
      // Create a WebSocket connection
      const socket = new WebSocket(`${WEBSOCKET_URL}/ws/algo-details`);

      // Event listener for when the connection is opened
      socket.onopen = () => {
        console.log('WebSocket connection opened for algo details');

        // Send a message to the server after the connection is open
        socket.send(location?.state);
      };

      // Event listener for when a message is received from the server
      socket.onmessage = (event) => {
        // console.log(event?.data);

        getAlgoDetail(skip, limit, currentRating);
        // Update state with the new message if needed
        // setMessages((prevMessages) => [...prevMessages, event.data]);
      };

      // Event listener for when the WebSocket connection is closed
      socket.onclose = (event) => {
        console.log('WebSocket connection closed for algo details:', event);
      };

      // Event listener for when there's an error with the WebSocket
      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      // Cleanup function to close the WebSocket connection when the component unmounts
      return () => {
        socket.close();
      };
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <>
      <div className="w-full flex-1   p-2 lg:p-4 flex flex-col ">
        <div style={{ background: theme.palette.background.white }} className="w-full  flex-1 flex flex-col  p-2 lg:p-6 shadow rounded">
          {/* MAIN SECTION  */}
          <div className="w-full py-4 flex flex-col items-start gap-4 lg:gap-6">
            <HeaderComponent
              title={currentAlgo?.algo?.name}
              analytics={currentAlgo?.analytics}
              currentRating={currentRating}
              setCurrentRating={setCurrentRating}
              order_types={order_types}
              setOrder_types={setOrder_types}
              onApplyRatingFilter={(rating) => {
                filterAlgoData(skip, limit, rating)
              }}
              goBack={() => {
                navigate(`/equity/algorithm`)
              }}
            />
          </div>
          {/* COMPANIES  */}
          <div className="w-full flex-1 flex flex-col">
            <div className="flex gap-3 items-center py-2">
              <H4 title={'TRADE SIGNALS'} color={theme.palette.text.charcolGray} font={'bold'} />
              <AlgoFilter
                open={open}
                onClose={() => {
                  setOpen(false)
                }}
                isLoading={isLoading}
                filterDate={filterDate}
                setFilterDate={setFilterDate}
                filterData={filterData}
                setFilterData={setFilterData}
                onApplyFilter={() => filterAlgoData(skip, limit, currentRating)}
                onResetFilter={() => resetFilter()}
              />
              <Button
                size="small"
                variant='contained'
                onClick={() => {
                  navigate(`/equity/algo-report/${_id}`)
                }}
              >
                Algo Report
              </Button>
            </div>
            <div className="w-full  flex-1  flex flex-col">
              <CompanyTable
                skip={skip}
                setSkip={setSkip}
                setLimit={setLimit}
                limit={limit}
                columns={columns}
                isPagination={true}
                totalRows={totalRows}
                paginationModel={paginationModel}
                rows={currentAlgo?.signals ? currentAlgo?.signals : null}
                onRowClickFunction={(params) => {
                  // navigate(`/equity/company/${params?.row?.instrument_token}`)
                  navigate(`/equity/algorithm/company/${_id}`, { state: params.row.instrument_token });

                }}
              />
            </div>
          </div>
        </div>
      </div >

      {/* <OrderDrawer
        open={openOrderDrawer}
        orderType={orderType}
        currentCompanny={currentCompany}
        onClose={() => {
          setOpenOrderDrawer(false)
        }}
        _type={_type}
        setType={setType}
        updateType={(__type) => {
          setType(__type)
        }}
      /> */}

      {/* <AddInstrumentInWatchlist
        open={openSelectWatchlists}
        setOpen={setOpenSelectWatchlists}
        isLoading={isLoading}
        selectedWatchlists={selectedWatchlists}
        setSelectedWatchlists={setSelectedWatchlists}
        onConfirmSelection={() => addStocks()}
        getAllWatchlist={() => getAlgoDetail(skip, limit, currentRating)}
      /> */}
    </>
  );
};

export default AlgoDetails;
