import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, LinearProgress } from '@mui/material';
import { green, red } from '@mui/material/colors';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: '#262626'
    },
    [`&.${tableCellClasses.root}`]: {
        border: '1px solid rgba(158, 158, 158, 0.12)',
        padding: '10px 8px'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

}));


const TextBox = (props) => (
    <TextField {...props} type='number' variant="outlined" size='small' />
)


export default function StoplossTargetMobile({ data, index, onSave, orderType, onChange }) {


    return (
        <div>
            <TableContainer component={Paper} sx={{ width: '100%', height: '100%', pb: 1, position: 'relative', boxShadow: 'none' }}>
                <Table stickyHeader aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell colSpan={2} align="center">
                                <p className='text-sm text-green-600 text-center font-bold'>BUY</p>
                            </StyledTableCell>
                            <StyledTableCell align="center" colSpan={2}>
                                <p className='text-sm text-red-600 text-center font-bold'>SELL</p>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell align="center">
                                <TextBox
                                    label="SL"
                                    // value={data?.options?.index?.call?.buy?.sl}
                                    value={data?.options?.[index]?.[orderType]?.buy?.sl}
                                    onChange={(e) => onChange('options', index, orderType, 'buy', 'sl', e?.target?.value)}
                                />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <TextBox
                                    label="T"
                                    value={data?.options?.[index]?.[orderType]?.buy?.t}
                                    onChange={(e) => onChange('options', index, orderType, 'buy', 't', e?.target?.value)}
                                />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <TextBox
                                    label="SL"
                                    value={data?.options?.[index]?.[orderType]?.sell?.sl}
                                    onChange={(e) => onChange('options', index, orderType, 'sell', 'sl', e?.target?.value)}
                                />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <TextBox
                                    label="T"
                                    value={data?.options?.[index]?.[orderType]?.sell?.t}
                                    onChange={(e) => onChange('options', index, orderType, 'sell', 't', e?.target?.value)}
                                />
                            </StyledTableCell>
                        </StyledTableRow>

                    </TableBody>
                </Table>
            </TableContainer>
            {/* <div className='flex w-full justify-end px-4'>
                <Button variant='outlined' color='primary' onClick={() => onSave()}>
                    SAVE STOPLOSS & TARGET
                </Button>
            </div> */}
        </div>
    )
} 