import { createSlice } from "@reduxjs/toolkit";
import { action } from "mobx";

const initialState = {
  currentToolsIndex: "nifty",
  ihmChartData: {
    data: {},
    interval: 3,
    fullscreen: false,
  },
  slChartData: {
    data: {},
    interval: 1,
    fullscreen: false,
  },
  highVolumeData: {
    call: 0,
    put: 0,
  },
  emaData: {
    interval: 3,
    data: {},
    companies: [],
    stocks: [],
  },
  rsiData: {
    data: null,
    interval: 3,
  },
  emaVolumeData: {
    interval: 3,
    data: [],
    companies: [],
    stocks: [],
  },
  cprpcrData: {
    data: null,
    interval: 3,
  },
  companiesStockData: [],
  currentVwapIndex: "",
  vwapStartDate: "",
  vwapDataSource: [],
  slcInterval: 1,
  ihmInterval: 1,

};

const toolsSlice = createSlice({
  name: "indexIndicator",
  initialState,
  reducers: {
    updateIhmChartData: (state, action) => {
      const { field, value, innerField } = action.payload;
      if (field == "data") {
        state.ihmChartData.data[innerField] = value;
      } else {
        state.ihmChartData[field] = value;
      }
    },
    updateCprPcrtData: (state, action) => {
      const { field, value } = action.payload;
      state.cprpcrData[field] = value;
    },
    updateSlChartData: (state, action) => {
      const { field, value, innerField } = action.payload;
      if (field == "data")
        state.slChartData.data[innerField] = value;
      else
        state.slChartData[field] = value;
    },
    updateEmaData: (state, action) => {
      const { field, value, innerField } = action.payload;
      if (field == "data")  
        state.emaData.data[innerField] = value;
      else
        state.emaData[field] = value;
    },
    updateEmaVolumeData: (state, action) => {
      const { field, value } = action.payload;
      state.emaVolumeData[field] = value;
    },
    updateRsiData: (state, action) => {
      const { field, value } = action.payload;
      state.rsiData[field] = value;
    },
    updateTopVolumeData: (state, action) => {
      state.highVolumeData = action.payload;
    },
    updateCompaniesStockData: (state, action) => {
      state.companiesStockData = action.payload;
    },
    updateCurrentVwapindex: (state, action) => {
      state.currentVwapIndex = action.payload;
    },
    updateVwapStartDate: (state, action) => {
      state.vwapStartDate = action.payload;
    },
    updateVwapDatasource: (state, action) => {
      state.vwapDataSource = action.payload;
    },
    updateCurrentToolsIndex: (state, action) => {
      state.currentToolsIndex = action.payload;
    },
    updateSlcInterval: (state, action) => {
      state.slcInterval = action.payload;
    },
    updateIhmInterval: (state, action) => {
      state.ihmInterval = action.payload;
    },
  },
});

export const {
  updateIhmChartData,
  updateSlChartData,
  updateTopVolumeData,
  updateCompaniesStockData,
  updateCurrentVwapindex,
  updateVwapStartDate,
  updateVwapDatasource,
  updateCurrentToolsIndex,
  updateSlcInterval,
  updateIhmInterval,
  updateEmaData,
  updateRsiData,
  updateEmaVolumeData,
  updateCprPcrtData,
} = toolsSlice.actions;
export default toolsSlice.reducer;
