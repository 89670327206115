import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';

const customPlaceholderStyle = {
    color: 'red', // Change the color to your desired color
    fontFamily: 'Arial, sans-serif', // Change the font family to your desired font
};

export default function ResponsiveDialog(props) {
    const dispatch = useDispatch()
    const theme = useTheme();
    const [brokerStr, setBrokerStr] = React.useState('Zerodha');
    const user = useSelector(state => state.user)


    const handleChange = (event) => {
        // console.log(event)
        props?.setMyBroker(event.target.value);
    };

    return (
        <div className='flex-1 my-10'>
            <div className='w-full !bg-rgba(248, 248, 248, 0.99)'>
                <div style={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }} className='w-full flex'>
                    <div className='w-full'>
                        {/* <div className='flex flex-col items-center justify-center gap-2 py-6 lg:py-0 '>
                            <h6 style={{ color: 'rgba(55, 55, 55, 0.59)' }} className=' font-semibold tracking-wider text-[2vmax] lg:text-[1.5vmax]'>Add Broker</h6>
                            <CloseIcon onClick={() => props.onClose()} sx={{ position: "absolute", top: "4%", right: "1%", transform: 'translate(-50%,-50%)' }} />
                        </div> */}
                        {/* <div className='flex justify-end items-center py-2 px-2'>
                            <a className='text-blue-500 hover:underline cursor-pointer' href='/how-to-add-broker' target='_blank'>How to add broker?</a>
                        </div> */}
                        <div className='input_area flex items-center justify-center w-full lg:overflow-y-auto'>
                            <div className='w-full flex flex-col items-start justify-center gap-6'>
                                <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                    <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>Select your broker</h6>
                                    <FormControl className='!bg-white' sx={{ backgroundColor: 'white' }} fullWidth size='small' >
                                        <Select
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            fullWidth
                                            value={brokerStr}
                                            size='small'
                                            sx={{
                                                // color: 'rgba(55, 55, 55, 0.20)',
                                                fontSize: { lg: '.9vmax' },
                                                '& .MuiInputBase-root': {
                                                    [theme.breakpoints.down('sm')]: {
                                                        fontSize: '2vmax',
                                                    },
                                                    [theme.breakpoints.up('lg')]: {
                                                        fontSize: '.8vmax',
                                                    },
                                                },
                                            }}
                                        >
                                            {props?.brokers?.map((broker, index) => (
                                                <MenuItem key={index} value={broker?.name} disabled selected>
                                                    <em>{broker?.name}</em>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                    <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>User Id</h6>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        placeholder='Enter User Id'
                                        fullWidth
                                        // variant="standard"
                                        value={props?.user_id}
                                        onChange={(e) => props?.setUserId(e?.target?.value)}
                                        size='small'
                                        type='text'
                                        className='custom-placeholder bg-white  border !border-[F4F4F4] !shadow-none '
                                        sx={{
                                            color: 'rgba(55, 55, 55, 0.20)',
                                            '& .MuiInputBase-root': {
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '2vmax', // Font size for large screens
                                                },
                                                [theme.breakpoints.up('lg')]: {
                                                    fontSize: '.8vmax', // Font size for large screens
                                                },
                                            },
                                        }} />
                                </div>
                                <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                    <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>API Key</h6>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        placeholder='Enter API Key'
                                        fullWidth
                                        // variant="standard"
                                        value={props?.api_key}
                                        onChange={(e) => props?.setApiKey(e?.target?.value)}
                                        size='small'
                                        type='text'
                                        className='custom-placeholder bg-white  border !border-[F4F4F4] !shadow-none '
                                        sx={{
                                            color: 'rgba(55, 55, 55, 0.20)',
                                            '& .MuiInputBase-root': {
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '2vmax', // Font size for large screens
                                                },
                                                [theme.breakpoints.up('lg')]: {
                                                    fontSize: '.8vmax', // Font size for large screens
                                                },
                                            },
                                        }}
                                    />

                                </div>
                                <div className='w-full flex flex-col items-start gap-3 lg:gap-2'>
                                    <h6 className='text-[#373737] font-semibold tracking-wider text-[1.5vmax] lg:text-[.8vmax]'>API Secret</h6>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        placeholder='Enter API Secret'
                                        fullWidth
                                        // variant="standard"
                                        size='small'
                                        value={props?.api_secret}
                                        onChange={(e) => props?.setApiSecret(e?.target?.value)}
                                        type='text'
                                        className='custom-placeholder bg-white  border !border-[F4F4F4] !shadow-none '
                                        sx={{
                                            color: 'rgba(55, 55, 55, 0.20)',
                                            '& .MuiInputBase-root': {
                                                [theme.breakpoints.down('sm')]: {
                                                    fontSize: '2vmax', // Font size for large screens
                                                },
                                                [theme.breakpoints.up('lg')]: {
                                                    fontSize: '.8vmax', // Font size for large screens
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
