import * as React from 'react'
import { useState } from 'react'
import TextField from '@mui/material/TextField';
import { Button, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { http } from '../Services/api.services';
import { emailRegex, mobileRegex, nameRegex } from '../ValidationRegex';
import HomepageHeaders from '../PublicComponents/HomepageHeaders';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';


const LearnCourses = [
    {
        title: "Option Kick Start",
        description: "Begin like a professional",
        duration: "5 days",
        amount: 6999,
        id: 'lwu_1'
    },
    {
        title: "Price Action For Options",
        description: "Rule based action",
        duration: "5 days",
        amount: 6999,
        id: 'lwu_2'
    },
    {
        title: "Data Analysis",
        description: "Bring accuracy in action",
        duration: "5 days",
        amount: 6999,
        id: 'lwu_3'
    },
    {
        title: "Speed Trading",
        description: "Track opportunities & book profits",
        duration: "5 days",
        amount: 14999,
        id: 'lwu_4'
    },
    {
        title: "Pro Options Traders",
        description: "Start your monthly income @ high ROI",
        duration: "5 days",
        amount: 14999,
        id: 'lwu_5'
    },
    {
        title: "Zero To Hero",
        description: "Learn all 5 in single go",
        duration: "25 days",
        amount: 39999,
        id: 'lwu_6'
    }
]

const LearnWithUs = () => {
    const user = useSelector((state) => state.user)
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [name, setName] = useState('')

    const [mid, setMID] = useState('')
    const [txnToken, setTxnToken] = useState('')
    const [orderId, setOrderId] = useState('')

    const [redirect, setRedirect] = useState(false)
    const [loading, setLoading] = useState(false)
    const [course, setCourse] = useState({})
    const location = useLocation();
    const form = React.useRef(null)

    // const course = LearnCourses[0]

    const { courseId } = useParams()

    React.useEffect(() => {
       setName(user?.name);
       setEmail(user?.email)
       setMobile(user?.mobile)
    }, [user])
    
    React.useEffect(() => {
        setCourse(location?.state?.course)
        document.title = 'Learn with us | Fastr'
        return () => {
            document.title = 'Fastr'
        }
    }, [courseId])

    const payment = async () => {
        try {
            // console.log(course)
            setLoading(true)
            const amount = course?.price
            const user = { mobile, email, amount, name, courseId: course?.id }
            const response = await http.post('/wolf-campaign/process-payment', user)
            console.log(response.status)
            if (response && response.status == 200) {
                console.log('success')
                setOrderId(response.data.orderId)
                setMID(response.data.mid)
                setTxnToken(response.data.token)
                setRedirect(true)
                // document?.getElementById('redFrom')?.submit()
                // console.log(document?.getElementById('redFrom'))
                setTimeout(() => {
                    form?.current?.submit()
                    // setLoading(false)
                }, 1000);
            }
            else {
                setLoading(false)
                alert('something went wrong!')
            }
            // const payment = await paytmgateway(user, "1")
            // console.log(payment)
        }
        catch (e) {
            setLoading(false)
            console.log(`ERROR WHILE PAYMENT ${e}`)
        }
    }

    return (
        <div className='flex-1 flex flex-col py-8 lg:py-10'>
            <div className='w-full '>
                {/* <h1 className='title-font text-center lg:text-[160px] text-[72px] bg-gradient-to-r from-pink-500 via-blue-700 to-purple-700 text-transparent bg-clip-text'>Learn with us</h1> */}
                <HomepageHeaders heading="LEARN WITH US!" />
                <div className='flex-1 flex flex-col my-4 lg:p-10 p-2'>
                    <h1 className="text-2xl text-center font-bold tracking-tight text-gray-900 sm:text-3xl mb-2">{course?.title}</h1>
                    <div>
                        <h3 className="sr-only">Description</h3>
                        <div className="space-y-6">
                            <p className="text-sm text-center text-slate-700 font-thin">{course?.description}</p>
                            {course ? <p className='text-center text-lg mx-4 md:mx-42 lg:mx-96'>
                                There are many myths about the option trading in the market , so to earn profit properly in the stock market you have to be trained about the option trading from the basics. The course of Options Basics is designed in a way to remove all the misconceptions and to train you properly for the options.
                            </p> : null}
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="mt-4 w-[360px] mx-auto">
                    {/* <h2 className="sr-only">Product information</h2> */}
                    <div className='flex justify-center py-4'>
                        <p className='text-xl lg:text-2xl uppercase font-semibold text-[#262626]'>Enroll Now</p>
                    </div>
                    <form className="mt-2">
                        <div className='flex w-full px-2 py-2 justify-center items-end mb-6'>
                            <div className='flex px-4 py-1 items-center border border-[#00B386] bg-[#dbefea]'>
                                <h3 className="text-sm text-gray-900">Amount:&nbsp;</h3>
                                <p className="text-xl font-extrabold text-[#00B386]">₹{course?.price?.toLocaleString()}</p>
                            </div>
                        </div>
                        <div className='mb-6'>
                            <TextField
                                // variant='filled'
                                size='small'
                                error={name ? !nameRegex.test(name) : false}
                                helperText={(name && !nameRegex.test(name)) ? 'Enter valid name' : ''}
                                fullWidth
                                id="name"
                                type='text'
                                label="Enter Your Name"
                                required
                                value={name}
                                onChange={(event) => {
                                    setName(event.target.value);
                                }}
                            />
                        </div>
                        <div className='mb-6'>
                            <TextField
                                // variant='filled'
                                size='small'
                                error={email ? !emailRegex.test(email) : false}
                                helperText={(email && !emailRegex.test(email)) ? 'Enter valid email' : ''}
                                fullWidth
                                id="email"
                                type='email'
                                label="Enter Email Address"
                                required
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}
                            />
                        </div>
                        <div className='mb-6'>
                            <TextField
                                // variant='filled'
                                error={mobile ? !mobileRegex.test(mobile) : false}
                                helperText={(mobile && !mobileRegex.test(mobile)) ? 'Enter valid mobile of 10 digits' : ''}
                                size='small'
                                fullWidth
                                id="mobile"
                                label="Enter Mobile Number"
                                required
                                type='tel'
                                value={mobile}
                                onChange={(event) => {
                                    setMobile(event.target.value);
                                }}
                            />
                        </div>
                        <Button
                            color='primary'
                            // loading={loading}
                            className={(!email || !mobile || !name) ? '' : 'bg-[#262626]'}
                            variant="contained"
                            fullWidth
                            onClick={payment}
                            disabled={(!email || !mobile || !name) || loading || !mobileRegex.test(mobile) || !emailRegex.test(email) || !nameRegex.test(name)}
                        >
                            {loading ?
                                <CircularProgress size={20} /> :
                                `Pay ₹${course?.price?.toLocaleString()} & Enroll Now`
                            }
                        </Button>
                    </form>
                </div>
            </div>
            {/* <div className="h-full flex justify-center items-center w-full">
                <div className="pt-6 flex-1 mx-10 lg:mx-20">
                    <div className='w-full flex flex-col-reverse lg:flex-row'>
                        <div className='flex-1 flex flex-col my-4 lg:p-10 p-2'>
                            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mb-2">{course?.title}</h1>
                            <div>
                                <h3 className="sr-only">Description</h3>
                                <div className="space-y-6">
                                    <p className="text-base text-slate-700 font-thin">{course?.description}</p>
                                    {course ? <p>
                                        There are many myths about the option trading in the market , so to earn profit properly in the stock market you have to be trained about the option trading from the basics. The course of Options Basics is designed in a way to remove all the misconceptions and to train you properly for the options.
                                    </p> : null}
                                </div>
                            </div>
                        </div>
                        <div className='flex-1 flex flex-col my-4'>
                            <div className="mt-4 lg:mx-20 xl:mx-40">
                                <div className='flex justify-center py-4'>
                                    <p className='text-lg text-[#262626]'>Enroll Now</p>
                                </div>
                                <form className="mt-2">
                                    <div className='flex justify-between items-center mb-6'>
                                        <h3 className="text-sm font-medium text-gray-900">Amount</h3>
                                        <p className="text-xl tracking-tight text-gray-900">₹ {course?.amount?.toLocaleString()}</p>
                                    </div>
                                    <div className='mb-6'>
                                        <TextField
                                            // variant='filled'
                                            size='small'
                                            error={name ? !nameRegex.test(name) : false}
                                            helperText={(name && !nameRegex.test(name)) ? 'Enter valid name' : ''}
                                            fullWidth
                                            id="name"
                                            type='text'
                                            label="Enter Your Name"
                                            required
                                            value={name}
                                            onChange={(event) => {
                                                setName(event.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className='mb-6'>
                                        <TextField
                                            // variant='filled'
                                            size='small'
                                            error={email ? !emailRegex.test(email) : false}
                                            helperText={(email && !emailRegex.test(email)) ? 'Enter valid email' : ''}
                                            fullWidth
                                            id="email"
                                            type='email'
                                            label="Enter Email Address"
                                            required
                                            value={email}
                                            onChange={(event) => {
                                                setEmail(event.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className='mb-6'>
                                        <TextField
                                            // variant='filled'
                                            error={mobile ? !mobileRegex.test(mobile) : false}
                                            helperText={(mobile && !mobileRegex.test(mobile)) ? 'Enter valid mobile of 10 digits' : ''}
                                            size='small'
                                            fullWidth
                                            id="mobile"
                                            label="Enter Mobile Number"
                                            required
                                            type='tel'
                                            value={mobile}
                                            onChange={(event) => {
                                                setMobile(event.target.value);
                                            }}
                                        />
                                    </div>
                                    <Button
                                        color='primary'
                                        // loading={loading}
                                        className={(!email || !mobile || !name) ? '' : 'bg-[#262626]'}
                                        variant="contained"
                                        fullWidth
                                        onClick={payment}
                                        disabled={(!email || !mobile || !name) || loading || !mobileRegex.test(mobile) || !emailRegex.test(email) || !nameRegex.test(name)}
                                    >
                                        {loading ?
                                            <CircularProgress size={20} /> :
                                            `Pay ₹${course?.amount?.toLocaleString()} & Enroll Now`
                                        }
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <br></br>
            <br></br>
            <br></br>
            <Hiddenfrom
                formRef={form}
                className='hidden'
                mid={mid}
                orderId={orderId}
                token={txnToken}
            />
        </div>
    )
}

export default LearnWithUs

const Hiddenfrom = (props) => {
    return (
        <div>
            <form
                ref={props.formRef}
                id="redFrom"
                method="post"
                action={
                    "https://securegw.paytm.in/theia/api/v1/showPaymentPage?mid=" +
                    props.mid +
                    "&orderId=" +
                    props.orderId
                }
                name="paytm"
            >
                <input type="hidden" name="mid" value={props.mid} />
                <input type="hidden" name="orderId" value={props.orderId} />
                <input type="hidden" name="txnToken" value={props.token} />
            </form>
        </div>
    );
};