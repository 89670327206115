import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';
import LinearProgress from '@mui/material/LinearProgress';

// import moment from 'moment-timezone';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NoRows from './NoData';
    

export default function OrdersTable({ data, columns, rowClick }) {
    const handleClick = rowClick ? rowClick : () => { };
    
    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <DataGrid
                rows={data || []}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 50,
                        },
                    },
                }}
                pageSizeOptions={[50, 100, 500]}
                slots={{
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: NoRows
                }}
                loading={!data}
                onRowClick={(params) => handleClick(params)}
            />  
        </Box>
    );
}
