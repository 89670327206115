import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { useDimensions } from "../Contexts/DimensionContext/DimensionsContext";
import { useSelector } from "react-redux";
// import http from 'path-to-your-http-module'; // Import your HTTP module

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const CompanyGraph = (props) => {
  const { companiesStockData, currentToolsIndex, slChartData } = useSelector(
    (state) => state.tools
  );
  const { sm, md, lg, xl, xxl } = useDimensions();
  const [dps, setDps] = useState([]);
  const [data, setData] = useState({
    high5: 100,
    low5: 50,
    highp: 150,
    lowp: 75,
    closep: 125,
    lowt: 125,
    hight: 125,
  });

  // console.log(props?.title,'0pdata');

  React.useEffect(() => {
    if (props.data) {
      //   console.log(props?.data);
      setDps(props?.data?.value);
    }
  }, [props.data]);

  React.useEffect(() => {
    if (companiesStockData) {
      // const currentIndexData = companiesStockData.find(
      //   (item) =>
      //     item?.index?.toLowerCase() === currentToolsIndex?.toLowerCase()
      // );
      // console.log(currentIndexData?.stock, "indddd dgtdtddtdt1");
      if (props?.graphValues) {
        setData({
          high5: props?.graphValues?.value?.high5,
          low5: props?.graphValues?.value?.low5,
          highp: props?.graphValues?.value?.highp,
          lowp: props?.graphValues?.value?.lowp,
          closep: props?.graphValues?.value?.closep,
          lowt: props?.graphValues?.value?.lowt,
          hight: props?.graphValues?.value?.hight,
        });
        // alert('updated!')
        // setData(currentIndexData?.stock);
      }
    }
  }, [companiesStockData, currentToolsIndex, props?.graphValues]);

  //   FIND HIGHEST AND LOWEST YAXIS VALUE

  const [highestValue, setHighestValue] = React.useState(null);
  const [lowestValue, setLowestValue] = React.useState(null);

  const [highestY, setHighestY] = useState(null);
  const [lowestY, setLowestY] = useState(null);

  useEffect(() => {
    if (props.data?.value && props.data?.value.length > 0) {
      const highestYValue = Math.max(...props.data.value.map((item) => item.y));
      const lowestYValue = Math.min(...props.data.value.map((item) => item.y));

      const highestYObject = props.data.value.find(
        (item) => item.y === highestYValue
      );
      const lowestYObject = props.data.value.find(
        (item) => item.y === lowestYValue
      );

      setHighestY(highestYObject);
      setLowestY(lowestYObject);
    }
  }, [props.data]);

  React.useEffect(() => {
    // Update highest and lowest values whenever the data changes
    updateMinMaxValues();
  }, [data]);

  const updateMinMaxValues = () => {
    const values = Object.values(data);

    // Find the highest and lowest values using Math.max and Math.min
    const highest = Math.max(...values);
    const lowest = Math.min(...values);

    // Update state with the results
    setHighestValue(highest);
    setLowestValue(lowest);
  };

  const options = {
    height: sm ? 150 : md ? 150 : lg ? 120 : xl ? 120 : xxl ? 120 : 150,
    title: {
      text: "",
    },
    backgroundColor: "transparent",
    axisX: {
      gridColor: "transparent",
      tickColor: "transparent",
      intervalType: "minute",
      interval: 0,
      valueFormatString: "HH:mm", // Display hours and minutes in the format you want
      labelFontSize: 10,
      labelFontColor: "#bdbdbd",
      lineColor: "transparent",
      labelFormatter: () => "",
    },

    axisY: {
      stripLines: [
        {
          startValue: data?.high5,
          endValue: data?.high5 + 0.7,
          color: "#FB2600",
          thickness: 0.1,
          // label: 'Red Line'
        },
        {
          startValue: data?.low5,
          endValue: data?.low5 + 0.7,
          color: "#FB2600",
          thickness: 0.1,
          // label: 'Green Line'
        },
        {
          startValue: data?.highp,
          endValue: data?.highp + 0.7,
          color: "#5DA44B",
          thickness: 0.1,
          // label: 'Green Line'
        },
        {
          startValue: data?.lowp,
          endValue: data?.lowp + 0.7,
          color: "#5DA44B",
          thickness: 0.1,
          // label: 'Red Line'
        },
        {
          startValue: data?.closep,
          endValue: data?.closep + 0.7,
          color: "#2B65FA",
          thickness: 0.1,
          lineDashType: "dash", // Set the lineDashType to "dash" for a dashed line
        },
      ],
      tickColor: "transparent",
      gridColor: "transparent",
      interval: 100,
      minimum: Math.min(lowestY?.y, data.low5, data.lowp),
      maximum: Math.max(highestY?.y, data.high5, data.highp),
      lineColor: "transparent",
      labelFormatter: () => "",
      labelFontSize: 10,
      labelFontColor: "#bdbdbd",
    },
    // data: [
    //   {
    //     type: "line",
    //     lineColor: "red",
    //     markerSize: 6,
    //     lineThickness: 5,
    //     markerType: "circle",
    //     markerColor: "transparent",
    //     toolTipContent:
    //       "<span style='color: {color}; background-color: yellow;'></span> {y} ",
    //     dataPoints:
    //       dps && dps.length > 0
    //         ? dps.map((dataPoint) => ({
    //             x: new Date(dataPoint.x),
    //             y: dataPoint.y,
    //             color: "red",
    //           }))
    //         : [],
    //   },
    // ],
    data: [
      {
        type: "line",
        lineColor: "#000",
        markerSize: 6,
        lineThickness: 1,
        markerType: "circle",
        markerColor: "transparent",
        toolTipContent:
          "<span style='color: {color}; background-color: yellow;'></span> {y} ",
        dataPoints:
          dps && dps.length > 0
            ? dps.map((dataPoint) => ({
              x: new Date(dataPoint.x),
              y: dataPoint.y,
              color: "transparent",
            }))
            : [],
      },
    ],
  };

  if (currentToolsIndex?.toLowerCase() == 'nifty')
    console.log(options, props?.title, 'options');


  const chartStyle = {
    width: "100%",
    maxHeight: "150px",
  };

  return (
    <div style={chartStyle}>
      {dps ? <CanvasJSChart options={options} /> : null}
    </div>
  );
};

export default CompanyGraph;
