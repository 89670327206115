import * as React from 'react';
import { useThemeContext } from '../../Theme/ThemeContext';
import { H3, H4, H5, H6, P } from '../../Theme/ThemeComponent';
import AddIcon from '@mui/icons-material/Add';
import { Button, Chip, IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CreateAlert from '../../EquityComponents/CreateAlert';
import { http } from '../../Services/api.services';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../default.config';
import ConfirmDeleteAlert from '../../EquityComponents/ConfirmDeleteAlert';
import { showToast } from '../../store/Actions/Action';

const Alerts = () => {
    const { theme } = useThemeContext();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const [open, setOpen] = React.useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
    const [allAlerts, setAllAlerts] = React.useState([]);
    const [currentSelectedAlert, setCurrentSelectedAlert] = React.useState(null);

    // GET ALL ALERTS DATA 
    React.useEffect(() => {
        if (user) {
            getAllAlerts(user?.id);
        }
    }, [user])

    const getAllAlerts = async (id) => {
        try {
            const response = await http.get(`${BASE_URL}alerts/${id}`);
            if (response?.status == 200) {
                setAllAlerts(response?.data)
            }
            console.log(response);
        } catch (e) {
            console.log(`ERROR WHILE FETCHINF ALERTS ${e}`);
        }
    }

    // REMOVE CURRENT SELECTED ALERT 
    const removeAlert = async () => {
        try {
            const _id = currentSelectedAlert?.id
            const response = await http.delete(`alerts/${_id}`);
            if (response?.status == 200) {
                setOpenDeleteAlert(false);
                getAllAlerts(user?.id);
                const _snackbar = {
                    message: response?.data?.message,
                    open: true,
                    type: 'success',
                    duration: 3000,
                }
                dispatch(showToast(_snackbar))
            } else {
                const _snackbar = {
                    message: response?.data?.message,
                    open: true,
                    type: 'error',
                    duration: 3000,
                }
                dispatch(showToast(_snackbar));
            }
        } catch (e) {
            const _snackbar = {
                message: e?.response?.data?.message,
                open: true,
                type: 'error',
                duration: 3000,
            }
            dispatch(showToast(_snackbar))
            console.log(`ERROR WHILE DELETING ALERTS ${e}`);
        }
    }

    return (
        <>
            <div className="w-full flex-1   p-2 lg:p-4 flex flex-col ">
                <div style={{ background: theme.palette.background.white }} className="w-full  flex-1 flex flex-col  p-2 lg:p-6 shadow rounded">
                    <div className='w-full flex items-center justify-between'>
                        <H3
                            title={'Alerts'}
                            color={theme.palette.text.charcolGray}
                            font="bold"
                        />
                        <Button
                            size="small"
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => setOpen(true)}
                        >
                            Create Alert
                        </Button>
                    </div>
                    <div style={{
                        border: `1px solid ${theme.palette.text.lightGray}`
                    }} className='w-full flex-1 my-4'>
                        {allAlerts ?
                            allAlerts?.map((item, index) => (
                                <div
                                    key={index}
                                    className='w-full flex items-center justify-between border-b border-b-gray-300 py-2 px-3 lg:px-4'>
                                    <div className=' flex items-start gap-3 flex-col'>
                                        <div className='flex items-center gap-2'>
                                            <div className='flex gap-1'>
                                                <H6
                                                    title={item?.instrument_name}
                                                    color={theme.palette.text.charcolGray}
                                                    font="normal"
                                                />
                                                <H6
                                                    title="|"
                                                    color={theme.palette.text.coolGray}
                                                />
                                                <H6
                                                    title={item?.target_price}
                                                    color={theme.palette.text.charcolGray}
                                                    font="normal"
                                                />
                                            </div>
                                            <Chip
                                                sx={{
                                                    borderRadius: 1.5,
                                                    padding: '10px 12px',
                                                    color: theme.palette.text.coolGray
                                                }}
                                                size='small'
                                                label={item?.always ? 'Always' : 'Once'}
                                                variant="outlined"
                                            />
                                        </div>
                                        <P
                                            title={item?.title}
                                            color={theme.palette.text.coolGray}
                                            font='normal'
                                        />
                                    </div>
                                    <IconButton
                                        onClick={() => {
                                            setOpenDeleteAlert(true);
                                            setCurrentSelectedAlert(item)
                                        }}
                                        aria-label="delete">
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                </div>
                            ))
                            : null}
                    </div>
                </div>
            </div>

            {/* CREATE ALERT DIALOGUE  */}
            <CreateAlert
                open={open}
                onHandleClose={() => {
                    setOpen(false);
                }}
                getAllAlerts={() => getAllAlerts(user?.id)}
                removeAlert={() => removeAlert()}
            />
            <ConfirmDeleteAlert
                open={openDeleteAlert}
                onCloseAlert={() => {
                    setOpenDeleteAlert(false)
                }}
                onRemoveWatchlist={() => removeAlert()}
            />
        </>
    )
}

export default Alerts;