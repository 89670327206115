import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    nifty: true,
    banknifty: true,
    finnifty: true,
    midcpnifty: true,
    sensex: true,
    ADVANCED_OPTIONS_CHAIN: false,
    DATA_ANALYTICS: false,
    EQUILIBRIUM_PRICE: false,
    BUY_LARGE_QUANTITY: false,
    VOLUME_ANALYSIS: false,
    STOPLOSS_USING_MUTIPLE_OPTIONS: false,
    TRACK_LIVE_POSITIONS: false,
    TRACK_YOUR_PERFORMANCE: false,
    AUTO_TRAILLING_STOPLOSS: false,
    SPEED_BUY_SELL: false,
    ADVANCED_STOPLOSS_SETTING: false,
    ADVANCED_TARGET_PRICE_SETTING: false,
    MARKET_SENTIMENTAL_INDICATOR: false,
    SHORT_COVERING_INDICATOR: false,
    PROFIT_BOOKING_INDICATOR: false,
    LONG_SHORT_BUILDUP_INDICATOR: false,
    ADVANCE_MARKET_DATA: false,
}

const _updateFeatures = (state = initialState, action) => {
    return { ...state, ...action.payload }
}

const FeaturesIndex = createSlice({
    name: 'features',
    initialState: initialState,
    reducers: {
        updateFeatues: _updateFeatures
    }
})

export const { updateFeatues } = FeaturesIndex.actions
export default FeaturesIndex.reducer;   