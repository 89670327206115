import React from 'react'
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 32,
    height: 14,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#2A3B43',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 10,
        height: 10,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));


const ComplaintCard = () => {
    return (
        <>
            <div className='flex shadow lg:shrink-0 flex-col items-start gap-4 w-full py-3 px-2 rounded-md lg:w-[32%] lg:py-4' >
                <div className='flex items-center justify-between w-full gap-3'>
                    <div className='flex items-center w-full gap-3'>
                        <div className='w-16 h-16 lg:w-10 lg:h-10 rounded-full overflow-hidden'>
                            <img className='w-full h-full object-cover' src="https://images.unsplash.com/photo-1682686578842-00ba49b0a71a?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHwxfHx8ZW58MHx8fHx8" alt="" />
                        </div>
                        <div className='flex flex-col items-start lg:flex-row lg:items-center gap-1 lg:gap-3  '>
                            <h6 className='text-[#373737]  whitespace-nowrap text-[1.56vmax] font-semibold lg:text-[.8vmax]'>Sanskrati Moolchnadani</h6>
                            <p className='text-[#666666]  font-medium text-[1.4vmax] lg:text-[.6vmax] whitespace-nowrap'>12/10/23  14:44</p>
                        </div>
                    </div>
                    <AntSwitch onClick={(e) => e.stopPropagation()} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                </div>
                <div className='flex items-center gap-3'>
                    <p className='text-[#1C1C1C]  font-semibold text-[1.4vmax] lg:text-[.9vmax]'><span className='text-[#575555] font-normal'>Category :</span> My platform is not working</p>
                </div>
            </div>
        </>
    )
}

export default ComplaintCard