import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { H3, H4, H5, H6, P } from "../Theme/ThemeComponent";
import { useThemeContext } from "../Theme/ThemeContext";
import GitHubIcon from '@mui/icons-material/GitHub';
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CompanyFilter from "./CompanyFilter";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Divider, Skeleton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import { StarIcon } from "../PublicComponents/Icons";
import { setCurrentOrderType, setCurrentSelectedOrder, setOpenOrderform } from "../store/Reducer/EquityReducer";

const HeaderComponent = ({ title, analytics, onApplyFilter, resetFilter, filterDate, setFilterDate, isLoading, setOrder_types, order_types, goBack, onAddCompany, onApplyRatingFilter, companydata, currentRating, setCurrentRating }) => {
  const { theme } = useThemeContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { currentAlgo } = useSelector((state) => state.equity);
  const [open, setOpen] = React.useState(false)
  console.log(companydata, 'axos');


  // FORMAT FIELD VALUES 
  function formatfieldsValue(value) {
    if (value == null || isNaN(value)) {
      return '0.00';
    }
    return Math.abs(value).toFixed(2);
  }

  const goBackFunction = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="w-full flex flex-col   items-start justify-start lg:justify-between gap-4">
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center gap-4 lg:gap-8">
            <div className="flex  items-center gap-2">
              <ArrowBackIcon
                sx={{
                  cursor: 'pointer'
                }}
                onClick={() => goBackFunction()}
              />
              <div className="flex items-center gap-3">
                <div className="flex items-center gap-1">
                  {title ?
                    <>
                      <GitHubIcon sx={{ color: theme.palette.text.charcolGray }} />
                      <H4
                        title={title}
                        color={theme.palette.text.charcolGray}
                        font="bold"
                      />
                    </>
                    :
                    <div className="flex flex-col gap-2">
                      <div className="flex gap-2">
                        <Skeleton width={30} height={30} variant="circular" animation="wave" />
                        <Skeleton width={150} height={30} variant="text" animation="wave" />
                      </div>
                      {!location?.pathname?.includes('company')
                        ?
                        <Skeleton width={300} height={30} variant="text" animation="wave" />
                        : null}
                    </div>
                  }
                  {location?.pathname?.includes('company') && companydata ? (
                    <>
                      <ArrowForwardIosIcon
                        fontSize="small"
                        sx={{ fontSize: '14px !important', color: theme.palette.text.charcolGray, cursor: 'pointer' }}
                      />
                      <div
                        onClick={() => {
                          navigate(`/equity/company/${companydata?.instrument_token}`)
                        }}
                        className="cursor-pointer">
                        <H5
                          title={companydata?.name || ''}
                          color={theme.palette.text.charcolGray}
                          font="bold"
                        />
                      </div>
                      <H6
                        title={companydata?.sector ? `${companydata.sector}` : ''}
                        color={theme.palette.text.coolGray}
                      />
                      {/* <div
          onClick={() => {
            navigate(`/equity/company/${companydata?.instrument_token}`)
          }}
          className="cursor-pointer flex items-center gap-2 ">
          <H5
          title={companydata?.name || ''}
          color={theme.palette.text.charcolGray}
          font="bold"
          />
          <H6
          title={companydata?.sector ? `(${companydata.sector})` : ''}
          color={theme.palette.text.coolGray}
          />
          </div> */}

                    </>
                  )
                    : null}
                </div>
              </div>
            </div>
            {location?.pathname?.includes('company') ?
              <div className="flex items-center gap-2 lg:gap-4">
                <div className='flex items-center gap-1 lg:gap-2 '>
                  <H6 title={'LTP'} font="normal" />
                  <H6 title={companydata?.last_price?.toFixed(2) || '--'} font="bold" />
                </div>
                {location?.pathname?.includes('company') ?
                  companydata ?
                    <div className="flex items-center gap-2">
                      <Button
                        variant="contained"
                        sx={{
                          padding: 0.5,
                          minWidth: '0px',
                          backgroundColor: theme.palette.background.lightGray,
                          boxShadow: 'none',
                          '&:hover': {
                            backgroundColor: theme.palette.background.lightGray,
                            boxShadow: 'none',
                          },
                        }}
                        onClick={() => onAddCompany()}
                      >
                        <Tooltip title="Add Company to watchlists" arrow>
                          <AddIcon fontSize="small" sx={{ color: theme.palette.text.black }} />
                        </Tooltip>
                      </Button>
                      <Button variant="contained"
                        sx={{
                          padding: 0.5,
                          minWidth: '0px',
                          boxShadow: 'none'
                        }}
                        size="small"
                      >
                        <CandlestickChartIcon fontSize="small" sx={{ color: theme.palette.text.white }} />
                      </Button>
                    </div>
                    : <div className="flex gap-2">
                      <Skeleton width={40} height={40} variant="rectangular" animation="wave" />
                      <Skeleton width={40} height={40} variant="rectangular" animation="wave" />
                    </div>
                  :
                  null}
              </div>
              : null}
          </div>
          {location?.pathname?.includes('company') ?
            companydata ?
              <div className="flex items-center gap-2">
                <Button
                  variant="contained"
                  sx={{
                    color: theme.palette.text.white,
                    backgroundColor: theme.palette.background.green,
                    fontSize: '10px',
                    boxShadow: 'none',
                    '&:hover': { // Corrected the hover pseudo-class
                      backgroundColor: theme.palette.background.green,
                      boxShadow: 'none'
                    },
                  }}
                  onClick={() => {
                    dispatch(setCurrentOrderType('buy'));
                    dispatch(setCurrentSelectedOrder(companydata));
                    dispatch(setOpenOrderform(true));
                  }}
                >
                  Buy
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    color: theme.palette.text.white,
                    backgroundColor: theme.palette.background.red,
                    fontSize: '10px',
                    boxShadow: 'none',
                    '&:hover': { // Corrected the hover pseudo-class
                      backgroundColor: theme.palette.background.red,
                      boxShadow: 'none'
                    },
                  }}
                  onClick={() => {
                    dispatch(setCurrentOrderType('sell'));
                    dispatch(setCurrentSelectedOrder(companydata));
                    dispatch(setOpenOrderform(true));
                  }}
                >
                  Sell
                </Button>
              </div>
              :
              <div className="flex gap-2">
                <Skeleton width={80} height={30} variant="rectangular" animation="wave" />
                <Skeleton width={80} height={30} variant="rectangular" animation="wave" />
              </div>
            :
            currentAlgo ?
              <div className={`flex items-center gap-2`}>
                <P
                  title={'$ 99,999 / Month'}
                  font="bold"
                  color={theme.palette.text.charcolGray}
                />
                <Button
                  variant='contained'
                  size='small'
                  // disabled={isSubscribed}
                  sx={{
                    fontWeight: 'normal',
                    fontSize: '10px',
                    borderRadius: 0.4
                  }}
                >
                  Subscribe
                </Button>
              </div>
              : <Skeleton width={140} height={40} variant="rectangular" animation="wave" />
          }

        </div>
        {!location?.pathname?.includes('company') && currentAlgo ?
          <P
            title={currentAlgo?.algo?.description}
            color={theme.palette.text.coolGray}
          />
          : null}
      </div >


      {/* ANALYTICS DATA  */}
      < div className="w-full  flex  items-center flex-wrap justify-between py-0 lg:py-2" >
        <div className='flex flex-col items-center gap-1'>
          <P title={'ROI'} color={theme.palette.text.coolGray} />
          <H6 title={`${formatfieldsValue(analytics?.roi)}%`} color={theme.palette.text.green} font='medium' />
        </div>
        <Divider
          orientation="vertical"
          flexItem
        />
        <div className='flex flex-col items-center gap-1'>
          <P
            title={'Closed Trades'}
            color={theme.palette.text.coolGray}
          />
          <H6
            title={analytics?.closed_trade != null ? analytics.closed_trade : '--'}
            color={theme.palette.text.charcolGray}
            font='medium'
          />
        </div>

        <Divider
          orientation="vertical"
          flexItem
        />
        <div className='flex flex-col items-center gap-1'>
          <P title={'Open Long Trades'} color={theme.palette.text.coolGray} />
          <H6 title={analytics?.open_long_trade || '--'} color={theme.palette.text.charcolGray} font='medium' />
        </div>
        <Divider
          orientation="vertical"
          flexItem
        />
        <div className='flex flex-col items-center gap-1'>
          <P title={'Open Short Trades'} color={theme.palette.text.coolGray} />
          <H6 title={analytics?.open_short_trade || '--'} color={theme.palette.text.charcolGray} font='medium' />
        </div>
        <Divider
          orientation="vertical"
          flexItem
        />
        <div className='flex flex-col items-center gap-1'>
          <P title={'Total Profit'} color={theme.palette.text.coolGray} />
          <H6 title={formatfieldsValue(analytics?.profit) || '--'} color={theme.palette.text.green} font='medium' />
        </div>
        <Divider
          orientation="vertical"
          flexItem
        />
        <div className='flex flex-col items-center gap-1'>
          <P title={'Total Loss'} color={theme.palette.text.coolGray} />
          <H6 title={formatfieldsValue(analytics?.total_loss) || '--'} color={theme.palette.text.red} font='medium' />
        </div>
        <Divider
          orientation="vertical"
          flexItem
        />
        <div className='flex flex-col items-center gap-1'>
          <div className="flex items-center justify-center">
            <P title={'Net  '} color={theme.palette.text.coolGray} /> &nbsp;
            <P title={'P'} color={theme.palette.text.green} />
            <P title={'/'} color={theme.palette.text.coolGray} />
            <P title={'L'} color={theme.palette.text.red} />
          </div>
          <H6 title={formatfieldsValue(analytics?.total_profit_loss) || '--'} color={theme.palette.text.green} font='medium' />
        </div>
        <Divider
          orientation="vertical"
          flexItem
        />
        <div className='flex flex-col items-center gap-1'>
          <P title={'Top Profit'} color={theme.palette.text.coolGray} />
          <H6 title={formatfieldsValue(analytics?.top_profit) || '--'} color={theme.palette.text.green} font='medium' />
        </div>
        <Divider
          orientation="vertical"
          flexItem
        />
        <div className='flex flex-col items-center gap-1'>
          <P title={'Top Loss'} color={theme.palette.text.coolGray} />
          <H6 title={formatfieldsValue(analytics?.top_loss) || '--'} color={theme.palette.text.red} font='medium' />
        </div>
        <Divider
          orientation="vertical"
          flexItem
        />
        <div className='flex flex-col items-center gap-1'>
          <P title={'Avg. Beta'} color={theme.palette.text.coolGray} />
          <H6 title={analytics?.beta || '--'} color={theme.palette.text.charcolGray} font='medium' />
        </div>
        <Divider
          orientation="vertical"
          flexItem
        />
        <div className='flex flex-col items-center gap-1'>
          <P title={'Nifty Phase'} color={theme.palette.text.coolGray} />
          <H6 title={analytics?.phase || '--'} color={theme.palette.text.charcolGray} font='medium' />
        </div>
      </div >


      {/* RATING FILTER  */}
      {
        !location?.pathname?.includes('report') ?
          <div className="w-full flex items-center justify-between">

            < div className="flex flex-wrap items-center gap-2" >
              <Button
                variant="text"
                size="small"
                endIcon={<StarIcon />}
                sx={{
                  boxShadow: currentRating == 1 ? 1 : 0
                }}
                onClick={() => {
                  setCurrentRating(1)
                  onApplyRatingFilter(1)
                }}
              >
                All
              </Button>
              <Button
                variant="text"
                size="small"
                endIcon={<StarIcon />}
                onClick={() => {
                  setCurrentRating(2)
                  onApplyRatingFilter(2)
                }}
                sx={{
                  boxShadow: currentRating >= 2 && currentRating < 3 ? 1 : 0
                }}
              >
                2
              </Button>
              <Button
                variant="text"
                size="small"
                endIcon={<StarIcon />}
                onClick={() => {
                  setCurrentRating(3)
                  onApplyRatingFilter(3)
                }}
                sx={{
                  boxShadow: currentRating >= 2 && currentRating < 4 ? 1 : 0
                }}
              >
                3
              </Button>
              <Button
                variant="text"
                size="small"
                endIcon={<StarIcon />}
                onClick={() => {
                  setCurrentRating(4)
                  onApplyRatingFilter(4)
                }}
                sx={{
                  boxShadow: currentRating >= 2 && currentRating < 5 ? 1 : 0
                }}
              >
                4
              </Button>
              <Button variant="text" size="small"
                endIcon={<StarIcon />}
                onClick={() => {
                  setCurrentRating(5)
                  onApplyRatingFilter(5)
                }}
                sx={{
                  boxShadow: currentRating >= 2 ? 1 : 0
                }}
              >
                5
              </Button>
            </div >
            <CompanyFilter
              open={open}
              onClose={() => {
                setOpen(false)
              }}
              isLoading={isLoading}
              filterDate={filterDate}
              setFilterDate={setFilterDate}
              order_types={order_types}
              setOrder_types={setOrder_types}
              onApplyFilter={() => {
                onApplyFilter(0, 20, currentRating)
              }}
              resetFilter={() => {
                setOpen(false);
                resetFilter();
              }}
            />
          </div>

          : null
      }
    </>
  );
};

export default HeaderComponent;
