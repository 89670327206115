import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import AdminTable from '../../PrivateComponents/AdminTable';
import DashboardTable from '../../PrivateComponents/DashboardTable'
import Avatar from '@mui/material/Avatar';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import ComplainDrawer from '../../PrivateComponents/ComplaintDrawer'
import { useLocation, useNavigate } from 'react-router-dom';
import { http } from '../../Services/api.services';
import { BASE_URL } from '../../default.config';
import OptionTable from '../../PublicComponents/OptionTable'
import FutureRelease from '../../PublicComponents/FutureRelease';



const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 32,
    height: 14,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#2A3B43',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 10,
        height: 10,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));


const HeadCell = ({ title }) => (
    <h6 className='text-[1.5vmax] lg:text-[.8vmax] text-[#000000] font-semibold tracking-wider'>
        {title}
    </h6>
)


// COLUMNS OF THE USER  SUBSCRIPTIONS TABLE 





// Investment Data



// Transection Data



// Order Data


const tablesColumns = [

    [
        {
            id: 'tradingsymbol',
            label: <HeadCell title="Symbol" />,
            minWidth: 110,
            flex: 1,
            align: 'left'
        },
        {
            id: 'Side',
            label: <HeadCell title="Side" />,
            minWidth: 110,
            flex: 1,
            align: 'left'
        },
        {
            id: 'currentquantity',
            label: <HeadCell title="Qty" />,
            minWidth: 110,
            flex: 1,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'takeProfit',
            label: <HeadCell title="Take profit" />,
            minWidth: 110,
            flex: 1,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'stopLoss',
            label: <HeadCell title="Stop loss" />,
            minWidth: 110,
            flex: 1,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'Amount',
            label: <HeadCell title="Last price" />,
            minWidth: 110,
            flex: 1,
            align: 'center',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'PL',
            label: <HeadCell title="PL" />,
            minWidth: 130,
            flex: 1,
            align: 'center',
            format: (value) => value.toFixed(2),
        },

    ],
    [
        {
            id: 'tradingsymbol',
            label: <HeadCell title="Symbol" />,
            minWidth: 110,
            align: 'left'
        },
        {
            id: 'Side',
            label: <HeadCell title="Side" />,
            minWidth: 130,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'quantity',
            label: <HeadCell title="Qty" />,
            minWidth: 130,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'price',
            label: <HeadCell title="Price" />,
            minWidth: 130,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'takeProfit',
            label: <HeadCell title="Take profit" />,
            minWidth: 110,
            flex: 1,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'stopLoss',
            label: <HeadCell title="Stop loss" />,
            minWidth: 110,
            flex: 1,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },

        {
            id: 'status',
            label: <HeadCell title="Status" />,
            minWidth: 130,
            align: 'center',
            format: (value) => value.toFixed(2),
        },

        {
            id: 'placingTime',
            label: <HeadCell title="Placing time" />,
            minWidth: 170,
            align: 'center',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'order_is',
            label: <HeadCell title="Order ID" />,
            minWidth: 170,
            align: 'center',
            type: 'data',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'expiry',
            label: <HeadCell title="Expiry" />,
            minWidth: 110,
            align: 'center',
            format: (value) => value.toFixed(2),
        },

    ],
    [

        {
            id: 'name',
            label: <HeadCell title="Name" />,
            minWidth: 110,
            align: 'left'
        },
        {
            id: 'price',
            label: <HeadCell title="Price" />,
            minWidth: 130,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'expiry_date',
            label: <HeadCell title="Validity" />,
            minWidth: 130,
            align: 'center',
            format: (value) => value.toFixed(2),
        },

        {
            id: 'createdat',
            label: <HeadCell title="Subscribed on" />,
            minWidth: 170,
            align: 'center',
            type: 'date',
            format: (value) => value.toFixed(2),
        },
    ],
    [
        {
            id: 'txnid',
            label: <HeadCell title="Transaction Id" />,
            minWidth: 110,
            align: 'left',
        },
        {
            id: 'txnamount',
            label: <HeadCell title="Price" />,
            minWidth: 110,
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'status',
            label: <HeadCell title="Status" />,
            minWidth: 110,
            align: 'center',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'createdat',
            label: <HeadCell title="Date" />,
            minWidth: 170,
            align: 'center',
            type: 'date',
            format: (value) => value.toFixed(2),
        },

    ]

]



const UserDetails = () => {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path?.pathname.split('/')[3]
    const [order, setOrder] = React.useState(false)
    const [position, setPosition] = React.useState(false)
    const [value, setValue] = React.useState(0);
    const [positionValue, setPositionValue] = React.useState(0);
    const [userDetails, setUserDetails] = React.useState(null)
    const [userSubscriptions, setUserSubscriptions] = React.useState(null)
    const [userTransections, setuserTransections] = React.useState(null)
    const [userOrders, setuserOrders] = React.useState(null)
    const [positions, setPositions] = React.useState(null)
    const [tableRows, setTableRows] = React.useState(null)
    const divRef = React.useRef(null)
    const heightRef = React.useRef(null)

    const handleChange = (event, newValue) => {
        console.log(newValue);
        setValue(newValue);
    };

    const [openDrawer, setOpenDrawer] = React.useState(false)

    const handleDrawerClick = () => {
        setOpenDrawer(true)
    }

    React.useEffect(() => {
        getUserDetails();
        // getUserSubscriptions();
        // getUserTransections();
        // getUserOrders();
        getPositions();
    }, [])

    // GET USER STATUS
    const getUserDetails = async () => {
        try {
            const res = await http.get(`${BASE_URL}admin/user/${id}`)
            setUserDetails(res?.data)
        } catch (e) {
            console.log(e);
        }
    }


    // UPDATE USER STATUS
    const updateUser = async (active) => {
        try {
            // UPDATE STATUS LOCALLY
            const _user = { ...userDetails }
            _user.active = active
            setUserDetails(_user)

            const url = `${BASE_URL}admin/user/${id}/status`
            const res = await http.post(url, { status: active })
            if (res?.status === 200) {
                getUserDetails();
            }
        } catch (e) {
            console.log(e);
        }
    }

    // GET USER SUBSCRIPTIONS
    const getUserSubscriptions = async () => {
        try {
            const res = await http.get(`${BASE_URL}admin/user/${id}/subscriptions`)
            setTableRows(res?.data)
        } catch (e) {
            console.log(e);
        }
    }

    // GET USER TRANSECTIONS
    const getUserTransections = async () => {
        try {
            const res = await http.get(`${BASE_URL}admin/user/${id}/transactions`)
            setTableRows(res?.data)
        } catch (e) {
            console.log(e);
        }
    }

    // GET USER ORDERS
    const getUserOrders = async () => {
        try {
            const res = await http.get(`${BASE_URL}admin/user/${id}/orders`)
            setTableRows(res?.data)
        } catch (e) {
            console.log(e);
        }
    }

    // GET USER POSITIONS
    const getPositions = async () => {
        try {
            const res = await http.get(`${BASE_URL}admin/user/${id}/positions`)
            setTableRows(res?.data?.data)
        } catch (e) {
            console.log(e);
        }
    }

    // FORMAT DATE AND TIME
    const originalDateTime = userDetails?.joined_on;
    const formattedDate = new Date(originalDateTime).toLocaleDateString('en-US', {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
    });
    const formattedTime = new Date(originalDateTime).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric'
    });
    const formattedDateTime = `${formattedDate} ${formattedTime}`;


    return (
        <>
            <ComplainDrawer openDrawer={openDrawer} onFormChange={() => setOpenDrawer(false)} />
            <div ref={divRef} className='w-full py-3 flex-1 overflow-y-auto lg:overflow-hidden px-3 flex flex-col  lg:gap-3 '>
                <div className='w-full  flex-1 flex flex-col items-start gap-3 '>
                    {/* USER DETAILS */}
                    <div className='w-full bg-[#2A3B430D] px-2  rounded'>
                        <div className='flex items-center justify-between w-full py-2 '>
                            <div className='flex items-center gap-1'>
                                <KeyboardBackspaceIcon sx={{ cursor: 'pointer' }} onClick={() => navigate('/admin/users')} />
                                <h6 className='text-[#1C1C1C] hidden lg:block font-semibold uppercase text-[2vmax] lg:text-[.8vmax]'>{userDetails?.name}</h6>
                            </div>
                            <div className='flex items-center gap-1 lg:gap-5'>
                                <div>
                                    {/* <InsertCommentOutlinedIcon /> */}
                                </div>
                                <h6 className='text-[#313131] font-semibold text-[1.7vmax] capitalize lg:text-[.7vmax]'><span className='text-[#585454] font-medium'>Joined on-  </span>21/10/23  13:21</h6>
                                <AntSwitch onClick={() => updateUser(!userDetails?.active)} checked={userDetails?.active} />
                            </div>
                        </div>
                        <div className='flex flex-col items-start lg:flex-row w-full  lg:items-center'>
                            <div className='flex items-center gap-5 py-4'>
                                <Avatar sx={{ width: 60, height: 60 }} alt="Cindy Baker" src="#" />
                                <h6 className='text-[#1C1C1C] block lg:hidden font-semibold uppercase text-[2vmax] lg:text-[.8vmax]'>{userDetails?.name}</h6>
                            </div>
                            <div className='flex flex-col items-start lg:items-center  lg:flex-row w-full px-2 py-1 justify-between'>
                                <div className='flex items-center gap-2 lg:gap-1'>
                                    <div className='flex flex-col items-start gap-2 lg:gap-1'>
                                        <h6 className='text-[#313131] font-semibold text-[1.7vmax] lg:text-[.7vmax] whitespace-nowrap'><span className='text-[#585454] font-medium'>Mobile no.</span> +91 {userDetails?.mobile}</h6>

                                        <h6 className='text-[#313131] font-semibold text-[1.7vmax] lg:text-[.7vmax] capitalize'><span className='text-[#585454] font-medium capitalize'>Location </span> {userDetails?.city}, {userDetails?.state}</h6>
                                    </div>
                                </div>
                                <div className='flex flex-col items-start gap-2 lg:gap-1'>
                                    <h6 className='text-[#313131] font-semibold text-[1.7vmax] lg:text-[.7vmax]'><span className='text-[#585454] font-medium'>Email Id  </span>{userDetails?.email}</h6>
                                    <h6 className='text-[#313131] font-semibold text-[1.7vmax] lg:text-[.7vmax]'><span className='text-[#585454] font-medium'>Brokers  </span><span className='text-[#07A704]'>{userDetails?.broker}</span></h6>
                                </div>
                                <div className='flex flex-col items-start gap-2 lg:gap-1 py-1'>
                                    <h6 className='text-[#313131] font-semibold text-[1.7vmax] lg:text-[.7vmax]'><span className='text-[#585454] font-medium'>Age  </span>{userDetails?.age} yrs</h6>
                                    <h6 className='text-[#313131] font-semibold text-[1.7vmax] lg:text-[.7vmax]'><span className='text-[#585454] font-medium'>Joined on   </span> {formattedDateTime}</h6>
                                </div>
                                <div className='flex flex-col items-start gap-2 lg:gap-1 py-1'>
                                    <h6 className={`text-[1.7vmax] lg:text-[.7vmax] font-semibold ${userDetails?.pl < 0 ? 'text-[#D41515]' : 'text-[#07A704]'
                                        }`}>
                                        P<span className='font-medium text-[#585454] '> & <span className='font-medium'>L</span></span> {userDetails?.pl}
                                    </h6>
                                    <h6 className='text-[#313131] font-semibold text-[1.7vmax] lg:text-[.7vmax]'><span className='text-[#585454] font-medium'>Total Investment  </span>{userDetails?.investment}</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-full flex-1 flex flex-col items-start gap-2 overflow-y-auto'>

                        <div className='w-full border border-[#E6E6E6] py-4 flex-1  rounded flex flex-col items-center gap-2'>
                            <div className='py-2 w-full px-4'>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab
                                            sx={{
                                                color: '#A5A5A5',
                                                '&.Mui-selected': {
                                                    color: '#1C1C1C',
                                                },
                                            }}
                                            onClick={() => getPositions()} label={<h6 className=' font-medium text-[1.7vmax] capitalize lg:text-[.8vmax] '>Positions</h6>} />
                                        <Tab
                                            sx={{
                                                color: '#A5A5A5',
                                                '&.Mui-selected': {
                                                    color: '#1C1C1C',
                                                },
                                            }}
                                            onClick={() => getUserOrders()} label={<h6 className=' font-medium text-[1.7vmax] capitalize lg:text-[.8vmax]'>Orders</h6>} />
                                        <Tab
                                            sx={{
                                                color: '#A5A5A5',
                                                '&.Mui-selected': {
                                                    color: '#1C1C1C',
                                                },
                                            }}
                                            onClick={() => getUserSubscriptions()} label={<h6 className=' font-medium text-[1.7vmax] capitalize lg:text-[.8vmax]'>Subscription</h6>} />
                                        <Tab
                                            sx={{
                                                color: '#A5A5A5',
                                                '&.Mui-selected': {
                                                    color: '#1C1C1C',
                                                },
                                            }}
                                            onClick={() => getUserTransections()} label={<h6 className=' font-medium text-[1.7vmax] capitalize lg:text-[.8vmax]'>Transactions</h6>} />

                                    </Tabs>
                                </Box>
                            </div>
                            <div ref={heightRef} className='max-w-[90vw] lg:w-full flex-1 py-4 overflow-hidden'>
                                {/* <AdminTable width={divWidth} rows={modifiedRows} columns={columns} /> */}
                                <AdminTable
                                    height={heightRef?.current?.offsetHeight}
                                    columns={tablesColumns[value]}
                                    rows={tableRows || []}
                                    click={(id) => {
                                        console.log('click');
                                    }}
                                />

                            </div>
                        </div>

                    </div>

                </div>
            </div >
        </>
    )
}

export default UserDetails