import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ranking: {
        nifty: [],
        banknifty: [],
        finnifty: [],
        midcpnifty: [],
        sensex: [],
        bankex: [],
        rankings: {
            htb: [false, false, false, true, true, true, true, true, true, true, true, true],
            keyInChain: ['oiPCR', 'oiCPR', 'indexLTP', 'volume', 'volPCR', 'volCPR', 'inMinute', 'tbs', 'theta', 'smb', 'oi'],
            key: ['oiPCR', 'oiCPR', 'indexLTP', 'volume', 'volPCR', 'volCPR', 'inMinute', 'tbs', 'theta', 'smb', 'oi'],
            value: ['OI PCR', 'OI CPR', 'INDEX/LTP', 'VOLUME %', 'VOL PCR', 'VOL CPR', 'VOL 1/5 Min', 'TBO/TSO', 'Theta', 'BS-BB', 'OI']
        }
    }
}

const RankingReducer = (state = initialState, action) => {
    switch (action.payload.type) {
        case 'nifty': return { ...state, ranking: { ...state.ranking, nifty: action.payload.payload } };
        case 'banknifty': return { ...state, ranking: { ...state.ranking, banknifty: action.payload.payload } };
        case 'finnifty': return { ...state, ranking: { ...state.ranking, finnifty: action.payload.payload } };
        case 'midcpnifty': return { ...state, ranking: { ...state.ranking, midcpnifty: action.payload.payload } };
        case 'sensex': return { ...state, ranking: { ...state.ranking, sensex: action.payload.payload } };
        case 'bankex': return { ...state, ranking: { ...state.ranking, bankex: action.payload.payload } };
    }
}


const RankingData = createSlice({
    name: 'ranking',
    initialState: initialState,
    reducers: {
        rankingReducer: RankingReducer
    }
})

export const { rankingReducer } = RankingData.actions;
export default RankingData.reducer;