import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Loading from './Loading';
import NoRows from '../PublicComponents/NoData';
import moment from 'moment/moment';

const HeadCell = ({ title }) => (
    <h6 className='text-[1.5vmax] whitespace-nowrap lg:text-[.8vmax] text-[#1C1C1C]  font-normal tracking-wider'>
        {title}
    </h6>
)


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 32,
    height: 14,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#2A3B43',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 10,
        height: 10,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const showIndiaTime = (time) => {
    return new Date(time).getTime() + 19800000
}

const timeFormat = (value) => {
    return moment(showIndiaTime(value)).format('DD/MM/YYYY HH:mm A');
}

export default function AdminTable(props) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [tableHeight, setTableHeight] = React.useState('72vh');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    React.useEffect(() => {
        if (props?.height !== undefined) {
            setTableHeight(props.height - 56);
        }
    }, [props?.height]);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer
                sx={{
                    minHeight: tableHeight,
                    maxHeight: tableHeight,
                    position: 'relative'
                }}
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {props?.columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column?.minWidth, maxWidth: column?.minWidth, backgroundColor: '#f5f5f5', overflow: 'hidden' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {props?.rows?.length > 0 ? (
                        <TableBody>
                            {props?.rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow onClick={() => props?.click(row?.id)} hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {props?.columns.map((column) => {
                                                const value = row[column.id];
                                                const id = row?.id;
                                                const active = !row?.active;
                                                return (
                                                    // <Tooltip title={value}>
                                                    <TableCell key={column.id} align={column.align} style={{ backgroundColor: 'transparent', minWidth: column?.minWidth, maxWidth: column?.minWidth, overflow: 'hidden', border: 'none' }}>
                                                        {props?.id}
                                                        {column.format && typeof value === 'number'
                                                            ? <HeadCell title={column.format(value)} />
                                                            : column?.action ?
                                                                <div className='w-full flex justify-center'>
                                                                    <AntSwitch
                                                                        onClick={(event) => {
                                                                            event.stopPropagation(); // line to stop event propagation
                                                                            props?.onUpdate(id, active);
                                                                        }}
                                                                        checked={row?.active}
                                                                    />
                                                                </div>
                                                                : column.type === 'date' ? <HeadCell title={timeFormat(value)} /> :
                                                                    Array.isArray(value) ? <HeadCell title={value[0]} /> : <HeadCell title={value} />
                                                        }
                                                    </TableCell>
                                                    // </Tooltip>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    ) : (
                        null
                    )}
                </Table>
                {props?.rows?.length === 0 ? (
                    <div className='absolute top-10 bottom-0 right-0 left-0 flex justify-center items-center'>
                        <NoRows />
                    </div>
                ) : null}
                {!props?.rows && (
                    <div className='absolute top-10 bottom-0 right-0 left-0 flex justify-center items-center'>
                        <Loading />
                    </div>
                )}
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[100, 250, 500, 1000]}
                component="div"
                count={props?.rows?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    // background:'red',
                    borderTop: '1px solid #E9E7E7'
                }}
            />
        </Paper>
    );
}