import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import { ThemeProvider as MuiThemeProvider, CssBaseline } from '@mui/material';
import { ThemeProvider } from "@mui/material";
import { Provider } from 'react-redux';
import { store } from './store/Store';
import { adminStore } from './store/AdminStore';
import { DimensionsProvider } from './Contexts/DimensionContext/DimensionsContext';
import AuthBroker from './Public/Options/AuthBroker';
import PublicRoutes from './Routes/PublicRoutes';
import PrivateRoutes from './Routes/PrivateRoutes';
import { CustomThemeProvider, useThemeContext } from './Theme/ThemeContext';
import { material } from './Theme/Theme';

function App() {
  return (
    // <DimensionsProvider>
    //   <CustomThemeProvider>
    //     <Provider store={store}>
    //       <BrowserRouter>
    //         <PublicRoutes />
    //       </BrowserRouter>
    //     </Provider>
    //   </CustomThemeProvider>
    //   <BrowserRouter>
    //     <Routes>
    //       <Route path="/auth-broker" element={<AuthBroker />} />
    //     </Routes>
    //   </BrowserRouter>
    //   <ThemeProvider theme={material}>
    //     <Provider store={adminStore}>
    //       <BrowserRouter>
    //         <PrivateRoutes />
    //       </BrowserRouter>
    //     </Provider>
    //   </ThemeProvider>
    // </DimensionsProvider>
    <DimensionsProvider>
      <ThemeProvider theme={material}>
        <CustomThemeProvider>
          <Provider store={store}>
            <BrowserRouter>
              <PublicRoutes />
            </BrowserRouter>
          </Provider>
        </CustomThemeProvider>
        <Provider store={adminStore}>
          <BrowserRouter>
            <PrivateRoutes />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </DimensionsProvider>
  );
}

export default App;
