import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, LinearProgress } from "@mui/material";
import { green, red } from "@mui/material/colors";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "#262626",
  },
  [`&.${tableCellClasses.root}`]: {
    border: "1px solid rgba(158, 158, 158, 0.12)",
    padding: "10px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const TextBox = (props) => (
  <TextField {...props} type="number" variant="outlined" size="small" />
);

export default function StoplossTarget(props) {
  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          height: "100%",
          pb: 4,
          position: "relative",
          boxShadow: "none",
        }}
      >
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={1} align="center"></StyledTableCell>
              <StyledTableCell colSpan={4} align="center">
                <p className="text-sm text-[#247CFF] text-center font-bold">
                  CALL
                </p>
              </StyledTableCell>
              <StyledTableCell align="center" colSpan={4}>
                <p className="text-sm text-[#247CFF] text-center font-bold">
                  PUT
                </p>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={1} align="center"></StyledTableCell>
              <StyledTableCell colSpan={2} align="center">
                <p className="text-sm text-green-600 text-center font-bold">
                  BUY
                </p>
              </StyledTableCell>
              <StyledTableCell align="center" colSpan={2}>
                <p className="text-sm text-red-600 text-center font-bold">
                  SELL
                </p>
              </StyledTableCell>
              <StyledTableCell colSpan={2} align="center">
                <p className="text-sm text-green-600 text-center font-bold">
                  BUY
                </p>
              </StyledTableCell>
              <StyledTableCell align="center" colSpan={3}>
                <p className="text-sm text-red-600 text-center font-bold">
                  SELL
                </p>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="center">
                <p className="text-sm text-[#373737] text-center font-light">
                  OPTIONS
                </p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <p className="text-sm text-[#373737] text-center font-bold">
                  SL
                </p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <p className="text-sm text-[#373737] text-center font-bold">
                  T
                </p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <p className="text-sm text-[#373737] text-center font-bold">
                  SL
                </p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <p className="text-sm text-[#373737] text-center font-bold">
                  T
                </p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <p className="text-sm text-[#373737] text-center font-bold">
                  SL
                </p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <p className="text-sm text-[#373737] text-center font-bold">
                  T
                </p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <p className="text-sm text-[#373737] text-center font-bold">
                  SL
                </p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <p className="text-sm text-[#373737] text-center font-bold">
                  T
                </p>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="center">
                <p className="font-bold text-lg">NIFTY</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.NIFTY?.call?.buy?.sl}
                  onChange={(e) =>
                    props.onChange("nifty-call-buy-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.NIFTY?.call?.buy?.t}
                  onChange={(e) =>
                    props.onChange("nifty-call-buy-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.NIFTY?.call?.sell?.sl}
                  onChange={(e) =>
                    props.onChange("nifty-call-sell-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.NIFTY?.call?.sell?.t}
                  onChange={(e) =>
                    props.onChange("nifty-call-sell-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.NIFTY?.put?.buy?.sl}
                  onChange={(e) =>
                    props.onChange("nifty-put-buy-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.NIFTY?.put?.buy?.t}
                  onChange={(e) =>
                    props.onChange("nifty-put-buy-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.NIFTY?.put?.sell?.sl}
                  onChange={(e) =>
                    props.onChange("nifty-put-sell-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.NIFTY?.put?.sell?.t}
                  onChange={(e) =>
                    props.onChange("nifty-put-sell-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="center">
                <p className="font-bold text-lg">BANKNIFTY</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.BANKNIFTY?.call?.buy?.sl}
                  onChange={(e) =>
                    props.onChange("banknifty-call-buy-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.BANKNIFTY?.call?.buy?.t}
                  onChange={(e) =>
                    props.onChange("banknifty-call-buy-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.BANKNIFTY?.call?.sell?.sl}
                  onChange={(e) =>
                    props.onChange("banknifty-call-sell-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.BANKNIFTY?.call?.sell?.t}
                  onChange={(e) =>
                    props.onChange("banknifty-call-sell-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.BANKNIFTY?.put?.buy?.sl}
                  onChange={(e) =>
                    props.onChange("banknifty-put-buy-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.BANKNIFTY?.put?.buy?.t}
                  onChange={(e) =>
                    props.onChange("banknifty-put-buy-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.BANKNIFTY?.put?.sell?.sl}
                  onChange={(e) =>
                    props.onChange("banknifty-put-sell-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.BANKNIFTY?.put?.sell?.t}
                  onChange={(e) =>
                    props.onChange("banknifty-put-sell-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="center">
                <p className="font-bold text-lg">FINNIFTY</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.FINNIFTY?.call?.buy?.sl}
                  onChange={(e) =>
                    props.onChange("finnifty-call-buy-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.FINNIFTY?.call?.buy?.t}
                  onChange={(e) =>
                    props.onChange("finnifty-call-buy-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.FINNIFTY?.call?.sell?.sl}
                  onChange={(e) =>
                    props.onChange("finnifty-call-sell-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.FINNIFTY?.call?.sell?.t}
                  onChange={(e) =>
                    props.onChange("finnifty-call-sell-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.FINNIFTY?.put?.buy?.sl}
                  onChange={(e) =>
                    props.onChange("finnifty-put-buy-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.FINNIFTY?.put?.buy?.t}
                  onChange={(e) =>
                    props.onChange("finnifty-put-buy-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.FINNIFTY?.put?.sell?.sl}
                  onChange={(e) =>
                    props.onChange("finnifty-put-sell-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.FINNIFTY?.put?.sell?.t}
                  onChange={(e) =>
                    props.onChange("finnifty-put-sell-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="center">
                <p className="font-bold text-lg">MIDCPNIFTY</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.MIDCPNIFTY?.call?.buy?.sl}
                  onChange={(e) =>
                    props.onChange("midcpnifty-call-buy-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.MIDCPNIFTY?.call?.buy?.t}
                  onChange={(e) =>
                    props.onChange("midcpnifty-call-buy-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.MIDCPNIFTY?.call?.sell?.sl}
                  onChange={(e) =>
                    props.onChange("midcpnifty-call-sell-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.MIDCPNIFTY?.call?.sell?.t}
                  onChange={(e) =>
                    props.onChange("midcpnifty-call-sell-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.MIDCPNIFTY?.put?.buy?.sl}
                  onChange={(e) =>
                    props.onChange("midcpnifty-put-buy-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.MIDCPNIFTY?.put?.buy?.t}
                  onChange={(e) =>
                    props.onChange("midcpnifty-put-buy-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.MIDCPNIFTY?.put?.sell?.sl}
                  onChange={(e) =>
                    props.onChange("midcpnifty-put-sell-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.MIDCPNIFTY?.put?.sell?.t}
                  onChange={(e) =>
                    props.onChange("midcpnifty-put-sell-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="center">
                <p className="font-bold text-lg">SENSEX</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.SENSEX?.call?.buy?.sl}
                  onChange={(e) =>
                    props.onChange("sensex-call-buy-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.SENSEX?.call?.buy?.t}
                  onChange={(e) =>
                    props.onChange("sensex-call-buy-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.SENSEX?.call?.sell?.sl}
                  onChange={(e) =>
                    props.onChange("sensex-call-sell-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.SENSEX?.call?.sell?.t}
                  onChange={(e) =>
                    props.onChange("sensex-call-sell-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.SENSEX?.put?.buy?.sl}
                  onChange={(e) =>
                    props.onChange("sensex-put-buy-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.SENSEX?.put?.buy?.t}
                  onChange={(e) =>
                    props.onChange("sensex-put-buy-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.SENSEX?.put?.sell?.sl}
                  onChange={(e) =>
                    props.onChange("sensex-put-sell-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.SENSEX?.put?.sell?.t}
                  onChange={(e) =>
                    props.onChange("sensex-put-sell-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="center">
                <p className="font-bold text-lg">Bankex</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.BANKEX?.call?.buy?.sl}
                  onChange={(e) =>
                    props.onChange("bankex-call-buy-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.BANKEX?.call?.buy?.t}
                  onChange={(e) =>
                    props.onChange("bankex-call-buy-t", e?.target?.value)
                  } 
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.BANKEX?.call?.sell?.sl}
                  onChange={(e) =>
                    props.onChange("bankex-call-sell-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.BANKEX?.call?.sell?.t}
                  onChange={(e) =>
                    props.onChange("bankex-call-sell-t", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.BANKEX?.put?.buy?.sl}
                  onChange={(e) =>
                    props.onChange("bankex-put-buy-sl", e?.target?.value)
                  } 
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.BANKEX?.put?.buy?.t}
                  onChange={(e) =>
                    props.onChange("bankex-put-buy-t", e?.target?.value)
                  } 
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="SL"
                  value={props?.data?.options?.BANKEX?.put?.sell?.sl}
                  onChange={(e) =>
                    props.onChange("bankex-put-sell-sl", e?.target?.value)
                  }
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextBox
                  label="T"
                  value={props?.data?.options?.BANKEX?.put?.sell?.t}
                  onChange={(e) =>
                    props.onChange("bankex-put-sell-t", e?.target?.value)
                  } 
                />
              </StyledTableCell>    
            </StyledTableRow>       
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex w-full justify-end px-4">
        <Button
          disabled={props.isDisabled}
          variant="contained"
          color="primary"
          onClick={() => props.onSave()}
        >
          SAVE STOPLOSS & TARGET
        </Button>
      </div>
    </div>
  );
}
