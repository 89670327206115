import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentIndex: "nifty",
  snackbar: "",
  expiry: "",
  currentExpiry: "1/1/2023",
  brokerConnected: false,
  brokers: [],
  admin: false,
  activeSL: null,
  activePath: "",
  paths: [],
  isEquity: false,
  currentModule: "options",
  privateUsers: [],
  privateOrders: null,
  signUp: false
};

export const Reducer = createSlice({
  name: "counter",
  initialState,
  reducers: {
    getCurrentIndex: (state, action) => {
      state.currentIndex = action.payload;
    },
    getExpiry: (state, action) => {
      state.expiry = action.payload;
    },
    getCurrentExpiry: (state, action) => {
      state.currentExpiry = action.payload;
    },
    getStatus: (state, action) => {
      state.snackbar = action.payload;
    },
    getBrokerConnected: (state, action) => {
      state.brokerConnected = action.payload;
    },
    getBrokers: (state, action) => {
      state.brokers = action.payload;
    },
    updateActiveSL: (state, action) => {
      state.activeSL = action.payload;
    },
    updateactivePath: (state, action) => {
      state.paths = action.payload;
      state.activePath = action.payload[action.payload?.length - 1];
      // console.log(action.payload)
    },
    updateIsEquity: (state, action) => {
      state.isEquity = action.payload[0];
      state.currentModule = action.payload[1];
    },
    updatePrivateUsers: (state, action) => {
      state.privateUsers = action.payload;
    },
    updatePrivateOrders: (state, action) => {
      state.privateOrders = action.payload;
    },
    updateSignUp: (state, action) => {
      state.signUp = action.payload
    }
  },
});

export const {
  getCurrentIndex,
  getStatus,
  getExpiry,
  getCurrentExpiry,
  getBrokerConnected,
  getBrokers,
  updateActiveSL,
  updateIsEquity,
  updateactivePath,
  updatePrivateUsers,
  updatePrivateOrders,
  updateSignUp
} = Reducer.actions;

export default Reducer.reducer;
