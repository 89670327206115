import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppLogo from '../assets/logo 2 (2) 1.png'
import HomePage from '../assets/HomePage.png'
import MobileImg from '../assets/HomePageMobile.png'
import Studio from '../assets/Studio-Display.png'
import Mobileslider from '../PublicComponents/Mobileslider'
import Footer from '../PublicComponents/Footer';
import HomeCard from '../PublicComponents/HomeCard';
import PlanCard from '../PublicComponents/PlanCard';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import HomepageHeaders from '../PublicComponents/HomepageHeaders';
import { RealTime, RegularCompliance, TechAnalysis } from '../PublicComponents/Icons';
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material';
import Register from './Options/Login';
import SignUpMobile from './SignUpMobile';
import SignUpEmail from './SignUpEmail';
import ConfirmOtop from './Options/ConfirmOtp'
import BasicDetails from '../PublicComponents/BasicDetails'
import AddBroker from './Options/AddBroker'
import SignUp from './SignUp'

const Basic = require('../assets/BasicPlan.png')
const Standard = require('../assets/StandardPlan.png')
const Premium = require('../assets/PremiumPlan.png')
const Group1 = require('../assets/Group.jpg')
const Group2 = require('../assets/Group 2004.jpg')
const Group3 = require('../assets/Group 2008.jpg')




const Homepage = () => {

  const isSmallScreen = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed
  const drawerWidth = isSmallScreen ? 240 : 400; // Set the width based on the screen size
  const navItems = ['ABOUT', 'OUR SERVICES', 'INVEST WITH US', 'CONTACT'];
  const [open, setOpen] = React.useState(false);

  const path = useLocation();
  const pathnameSegments = path.pathname.split('/');
  const route = pathnameSegments[2];
  const [mobileOpen, setMobileOpen] = React.useState(false);

  // React.useEffect(()=>{ 

  // },[])

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleSignup = () => {
    setOpen(true);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }} className='px-4'>
        <img className='sm:h-12' src={AppLogo} alt="" />
      </Typography>
      <Divider />
      <List sx={{ paddingTop: { sm: '3vh' } }}>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'start', }}>
              <Link to={`/${item.toLowerCase()}`}>
                {route === undefined ? (
                  <h6 className={item === 'HOME' ? 'font-semibold tracking-wider font-[Open Sans] text-[#767676]  text-[2vmax]' : 'font-[Open Sans] tracking-wider text-[#767676] text-[2vmax] font-normal'}>{item}</h6>
                ) : (
                  <h6 className={route === item ? 'font-semibold  font-[Open Sans] text-[#767676] text-[2vmax]' : 'font-[Open Sans] text-[#767676] text-[2vmax] font-normal'}>{item}</h6>
                )}
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <div className='px-4'>
        <div onClick={handleSignup} className='bg-[#1C1C1C]  w-fit py-2  px-8 my-4 sm:px-12 sm:py-3 rounded-sm'>
          <h6 className='text-white font-[Open Sans] text-[1.6vmax] sm:text-[2vmax]  font-normal tracking-wider'>Login</h6>
        </div>
      </div>
    </Box >
  );

  return (
    <>

      <div className='bg-[#F6F6F6] w-full'>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar component="nav" sx={{ backgroundColor: '#F6F6F6', boxShadow: "none", color: "#111", padding: { sm: '2vmax .5vmax' } }} className='lg:py-5' >
            <Toolbar>

              <div className='flex items-center justify-between w-full' >
                <div>
                  <img src={AppLogo} alt="" />
                </div>
                <div className='hidden lg:flex items-center justify-between gap-8'>
                  {navItems.map((item, idx) => (
                    <Link key={idx} to={`/${item.toLowerCase()}`}>
                      {/* {route === undefined ? (
                        <h6 className={item === 'HOME' ? 'font-semibold tracking-wider font-[Open Sans] text-[#767676]  text-[.9vmax]' : 'font-[Open Sans] tracking-wider text-[#767676] text-[.9vmax] font-normal'}>{item}</h6>
                      ) : (
                        <h6 className={route === item ? 'font-semibold  font-[Open Sans] text-[#767676] text-[.9vmax]' : 'font-[Open Sans] text-[#767676] text-[.9vmax] font-normal'}>{item}</h6>
                      )} */}
                      <h6 className='font-[Open Sans] tracking-wider text-[#767676] text-[.9vmax] font-semibold'>{item}</h6>
                    </Link>
                  ))}
                  <div onClick={handleSignup} className='bg-[#1C1C1C] py-2 px-5 rounded-sm'>
                    <h6 className='font-[Open Sans] tracking-wider text-white text-[.8vmax]   font-normal'>LOGIN</h6>
                  </div>
                  {/* <Button size='small' variant="contained" sx={{ fontSize: '.8vmax', boxShadow: 'none', borderRadius: '2px', fontFamily: "Poppins" }}>Login</Button> */}
                </div>
              </div>

              <MenuIcon sx={{ display: { lg: 'none' }, fontSize: { sm: "4vmax" } }} onClick={handleDrawerToggle} />
              {/* </IconButton> */}
            </Toolbar>
          </AppBar>
          <nav>
            <Drawer
              // container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        </Box>
        <div className='px-4 sm:px-10 lg:pt-0'>
          {/* HomePage */}

          <div className='lg:flex lg:items-center pt-10 lg:justify-center lg:h-screen lg:flex-1 lg:pt-[10vh] '>
            <div className='flex flex-col items-start justify-between py-12 gap-10 sm:items-center sm:justify-center lg:flex-row lg:items-center lg:justify-between'>
              <div className='flex flex-col  items-start justify-center gap-6 lg:gap-10 sm:items-center lg:items-start sm:gap-8'>
                <h1 className='font-[Open Sans] text-[#1C1C1C] font-semibold text-left sm:text-center lg:text-left text-[3.5vmax] leading-none lg:text-[3.4vmax] sm:text-[5vmax] '>Your trading success
                  starts with us.</h1>
                <p className='font-[Open Sans] text-[#767676D4] font-normal text-left text-[1.7vmax] leading-normal  lg:text-[1.2vmax] sm:text-[1.7vmax] sm:text-center   lg:text-left sm:w-[80%] lg:w-[90%]'>Tap into our expertise, cutting-edge tools, and comprehensive resources to launch your trading journey towards unprecedented success.</p>
                <div className='bg-black py-4 px-7 rounded-sm hidden lg:flex'>
                  <h6 className='font-[Open Sans] text-[#F5F5F5] text-[1vmax] tracking-wider font-normal'>Start with free trial</h6>
                </div>
              </div>
              <div className='items-end justify-end sm:items-center sm:justify-center p-0 hidden sm:flex lg:flex h-[40vh]'>
                <img src={HomePage} alt="HomePage" />
              </div>
              <div className='py-6 sm:hidden lg:hidden'>
                <img className='w-full h-full object-cover' src={MobileImg} alt="HomepageImage1" />
              </div>
              <div className='flex items-center justify-center w-full lg:hidden'>
                <div className='bg-black py-3 px-6 rounded-sm w-fit sm:px-10 sm:py-5  lg:hidden'>
                  <h6 className='font-[Open Sans] text-[#F5F5F5] text-[1.5vmax] sm:text-[2vmax] tracking-wider font-normal'>Start with free trial</h6>
                </div>
              </div>
            </div>
          </div>
          {/* HomePage */}

          {/* Feature Cards */}
          <div>
            <HomepageHeaders heading='Why settle for anything less than Fastr?' desc='We are pioneers in prioritizing technology, relentlessly pushing boundaries to empower our customers with the ultimate competitive advantage for successful trading.' />
            <div className='flex w-full flex-col items-center gap-5 lg:flex-row lg:py-10'>
              <HomeCard title='Algo Trading' img={Group1} desc='Experience the power of our comprehensive manual training program' />
              <HomeCard title='Target Price' img={Group2} desc='Discover the winning formula with our fund prices' />
              <HomeCard title='Fast Trading Performance' img={Group3} desc='Keeps pace with your ambitions, ensuring swift execution and maximizing your opportunities.' />
            </div>
          </div>
          {/* Feature Cards */}

          {/* Feature Cards */}
          <div className='py-10 relative'>
            <HomepageHeaders heading='Expertly executed by our experienced team' desc='Our experienced trading team expertly executes every transaction,' />

            <div className='lg:flex lg:items-center lg:justify-center sm:flex sm:items-center sm:justify-center relative lg:py-14 sm:py-14'>
              <div className='px-3 sm:w-3/5 sm:h-3/5 lg:w-2/5 lg:h-2/5'>
                <img src={Studio} alt="Studio" />
              </div>
            </div>

            <div className='flex flex-col items-center gap-5 px-4 py-6'>
              <div className='bg-[#000000A8] w-full customFilter flex items-center gap-4 px-3 py-3 rounded-md sm:py-2 sm:w-fit sm:absolute sm:top-[47%] sm:start-[83%] sm:transform sm:translate-x-[-50%] sm:translate-y-[-50%] lg:py-2 lg:w-fit lg:absolute lg:top-[37%] lg:start-[75%] lg:transform lg:translate-x-[-50%] lg:translate-y-[-50%]'>
                <div>
                  <TechAnalysis />
                </div>
                <div>
                  <h6 className='text-[#FFFFFF] text-[1.5vmax] lg:w-[20vw] font-[Open Sans] whitespace-nowrap sm:text-[1.3vmax] lg:text-[1vmax]'>Advanced Technical Analysis</h6>
                </div>
              </div>
              <div className='bg-[#000000A8] w-full customFilter flex items-center gap-4 px-3 py-3 rounded-md sm:py-2 sm:w-fit sm:absolute sm:top-[52%] sm:start-[20%] sm:transform sm:translate-x-[-50%] sm:translate-y-[-50%] lg:py-2  lg:w-fit lg:absolute lg:top-[45%] lg:start-[28%] lg:transform lg:translate-x-[-50%] lg:translate-y-[-50%]'>
                <div>
                  <RealTime />
                </div>
                <div>
                  <h6 className='text-[#FFFFFF] text-[1.5vmax] lg:w-[20vw] font-[Open Sans] whitespace-nowrap sm:text-[1.3vmax] lg:text-[1vmax]'>Real-time Monitoring and Reporting</h6>
                </div>
              </div>
              <div className='bg-[#000000A8] w-full customFilter flex items-center gap-4 px-3 py-3 rounded-md sm:py-2 sm:w-fit sm:absolute sm:bottom-[16%] sm:start-[67%] sm:transform sm:translate-x-[-50%] sm:translate-y-[-50%] lg:w-fit lg:py-2 lg:absolute lg:top-[77%] lg:start-[61%] lg:transform lg:translate-x-[-50%] lg:translate-y-[-50%]'>
                <div>
                  <RegularCompliance />
                </div>
                <div>
                  <h6 className='text-[#FFFFFF] text-[1.5vmax] lg:w-[20vw] font-[Open Sans]  whitespace-nowrap sm:text-[1.3vmax] lg:text-[1vmax]'>Regulatory Compliance</h6>
                </div>
              </div>
            </div>
          </div>
          {/* Feature Cards */}

          {/* Join us */}
          <div className='flex flex-col items-center justify-center gap-8 sm:gap-12 lg:gap-10 py-16 lg:w-[70%] m-auto'>
            <h1 className='font-[Open Sans] text-[#141414] font-semibold text-center text-[2.7vmax] sm:text-[3.5vmax] sm:w-[90%] lg:text-[2vmax] leading-none'>Join us today and unlock the power of trading
              with our comprehensive training program</h1>
            <p className='font-[Open Sans] text-[#767676D4] font-normal text-center text-[1.7vmax] sm:text-[1.8vmax] sm:w-[85%] lg:w-[70%] leading-normal lg:text-[1vmax]'>Embark on your trading journey today and gain access to our all-encompassing training program, empowering you with the knowledge and skills to unleash the full potential of trading.</p>
            <div className='bg-black py-3 px-6 rounded-sm sm:px-10 sm:py-5 sm:rounded-sm'>
              <h6 className='font-[Open Sans] text-[#F5F5F5] text-[1.5vmax] tracking-wider font-normal sm:text-[1.8vmax] lg:text-[.9vmax]'>GET STARTED</h6>
            </div>
          </div>
          {/* Join us */}

          {/* Set Plan*/}
          <div>
            <HomepageHeaders heading='Choose your right plan!' desc='Lorem ipsum dolor sit amet. Est voluptate voluptas aut itaque labore est expedita' />
            <div className='w-full lg:hidden'>
              <Mobileslider />
            </div>
            <div className='hidden lg:flex lg:items-center justify-center lg:w-full gap-10 lg:py-10'>
              <PlanCard img={Basic} heading='BASIC' color='#3366FF' bg='rgba(51, 102, 255, 0.04)' price='$12,999' />
              <PlanCard img={Standard} heading='STANDARD' color='#00B386' bg='rgba(0, 179, 134, 0.04)' price='$99,999' />
              <PlanCard img={Premium} heading='PREMIUM' color='#F0AD00' bg='rgba(240, 173, 0, 0.04)' price='$13,999' />
            </div>
          </div>
          {/* Set Plan*/}

        </div>
        <Footer />
      </div>
      {/* <Register open={open} onClose={() => setOpen(false)}  /> */}
      {/* <SignUpMobile open={open} onClose={() => setOpen(false)} />  */}
      {/* <ConfirmOtop open={open} onClose={() => setOpen(false)} /> */}
      {/* <SignUpEmail open={open} onClose={() => setOpen(false)} /> */}
      {/* <BasicDetails open={open} onClose={() => setOpen(false)} /> */}
      {/* <AddBroker open={open} onClose={() => setOpen(false)} /> */}
      <SignUp open={open} onClose={() => setOpen(false)} />
    </>
  );
}

export default Homepage