import * as React from "react";
import { useTheme } from "@mui/material/styles";
import FasterLogo from "../../assets/fastr_logo.png";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { ApiService, http } from "../../Services/api.services";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { emailRegex, passwordRegex } from "../../ValidationRegex";
import { showToast } from "../../store/Actions/Action";
import { useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const _form = {
  email: "",
  password: "",
};

const api = new ApiService();

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [signinForm, setSigninForm] = React.useState({ ..._form });
  const [isLoading, setIsLoading] = React.useState(false);
  const theme = useTheme();

  // TABS CODE FOR SELECT
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    let token = localStorage.getItem("admin");
    token = token ? JSON.parse(token) : null;
    if (token) {
      navigate("/admin/options/strikes");
    }
  }, []);

  const onFormChange = (name, value) => {
    try {
      const form = { ...signinForm };
      form[name] = value;
      setSigninForm(form);
    } catch (e) {
      console.log(`ERROR WHILE FORM CHANGE --- ${e}`);
    }
  };

  const handleLogin = async () => {
    try {
      if (
        emailRegex.test(signinForm.email) &&
        passwordRegex.test(signinForm.password)
      ) {
        setIsLoading(true);
        // PUT SOME VALIDATION HERE
        const response = await http.post(`admin/login`, signinForm);
        console.log(response.data);
        if (response.status === 200) {
          const _snackbar = {
            message: response?.data?.message,
            open: true,
            duration: 3000,
            type: response.data.status,
          };
          dispatch(showToast(_snackbar));
          if (response.data.status === "success") {
            // Set Token in local storage
            const tokenToSet = response.data.data.token
              ? response.data.data.token
              : "";
            localStorage.setItem("admin", JSON.stringify(response.data.data));
            if (value == 0) {
              navigate("/admin/dashboard");
            } else {
              navigate("/admin/options/strikes");
            }
          } else {
          }
        }
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      let response = e?.response?.data;
      const _snackbar = {
        message: response?.message || 'Failed! Please try again later.',
        open: true,
        duration: 3000,
        type: 'error',
      };
      console.log(_snackbar);
      dispatch(showToast(_snackbar));
      console.log(`ERROR WHILE LOGIN --- ${e}`);
    }
  };

  const inputStyle = {
    // color: 'rgba(55, 55, 55, 0.20)',
    "& .MuiInputBase-root": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "2vmax", // Font size for large screens
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: ".8vmax", // Font size for large screens
      },
      bgcolor: "#fff",
    },
  };

  return (
    <div className="h-full w-full flex justify-center items-center">
      <div className="flex flex-col gap-4 min-w-[300px]">
        <div className="flex flex-col items-center justify-center">
          <img style={{ height: 32 }} src={FasterLogo} alt="FasterLogo" />
        </div>
        <div className="w-full flex items-center justify-center py-2">
          <Tabs
            sx={{
              "& .css-kxhebr-MuiTabs-indicator": {
                display: "none",
              },
              "& .css-wy1qx7.Mui-selected": {
                backgroundColor: "#1C1C1C",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#1C1C1C",
                },
              },
              "& .css-p5x7jj": {
                display: "none",
              },
              "& .css-wy1qx7": {
                color: "#000",
                minWidth: "80px",
                minHeight: "35px",
                padding: 1,
                textTransform: "capitalize",
                borderRight: "1px solid #00000021",
                "& .css-6w7hx6-MuiTabs-root": {
                  minHeight: "20px",
                },
                "&:hover": {
                  backgroundColor: "#eeeeee",
                },
              },
              background: "#FFFFFF",
              border: "1px solid #00000021",
              width: "fit-content",
              height: "fit-content",
              minHeight: "20px",
              padding: 0,
              borderRadius: 1,
            }}
            size="small"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Admin" {...a11yProps(0)} />
            <Tab label="Trade" {...a11yProps(1)} />
          </Tabs>
        </div>
        <div className="w-full flex flex-col items-start gap-3 lg:gap-2">
          <h6 className="text-slate-600 text-sm">Email</h6>
          <TextField
            placeholder="Enter your email address"
            helperText={
              signinForm.email?.length > 0 &&
                !emailRegex?.test(signinForm.email)
                ? "Please Enter Valid Email"
                : ""
            }
            error={
              signinForm.email?.length > 0 &&
              !emailRegex?.test(signinForm.email)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon sx={{ color: "rgba(55, 55, 55, 0.20)" }} />
                </InputAdornment>
              ),
            }}
            fullWidth
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                handleLogin();
              }
            }}
            size="small"
            className="custom-placeholder border !border-[F4F4F4] !shadow-none "
            sx={inputStyle}
            type="email"
            value={signinForm.email}
            onChange={(e) => onFormChange("email", e?.target?.value)}
          />
        </div>
        <div className="w-full flex flex-col items-start gap-3 lg:gap-2 mb-2">
          <h6 className="text-slate-600 text-sm">Password</h6>
          <TextField
            placeholder="Enter your Password"
            helperText={
              signinForm.password?.length > 0 &&
                !passwordRegex?.test(signinForm.password)
                ? " password should have atleast 5 character"
                : ""
            }
            error={
              signinForm.password?.length > 0 &&
              !passwordRegex?.test(signinForm.password)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon sx={{ color: "rgba(55, 55, 55, 0.20)" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {showPassword ? (
                    <VisibilityIcon
                      onClick={handleClickShowPassword}
                      sx={{
                        cursor: "pointer",
                        color: "rgba(55, 55, 55, 0.20)",
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      onClick={handleClickShowPassword}
                      sx={{
                        cursor: "pointer",
                        color: "rgba(55, 55, 55, 0.20)",
                      }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                handleLogin();
              }
            }}
            fullWidth
            type={showPassword ? "text" : "password"}
            size="small"
            className="custom-placeholder  border !border-[F4F4F4] !shadow-none"
            sx={inputStyle}
            value={signinForm.password}
            onChange={(e) => onFormChange("password", e?.target?.value)}
          />
        </div>
        <LoadingButton
          loading={isLoading}
          onClick={() => handleLogin()}
          fullWidth
          disabled={
            !(
              emailRegex.test(signinForm.email) &&
              passwordRegex.test(signinForm.password)
            )
          }
          variant="contained"
          size="medium"
        >
          <h6 className="text-[#FFFFFF] font-normal text-[2vmax] tracking-wider lg:text-[.8vmax]">
            Login
          </h6>
        </LoadingButton>
      </div>
    </div>
  );
}
