import React from 'react'
import Switch from '@mui/material/Switch';
import { RightArrow, RightIcon } from '../PublicComponents/Icons';
import { styled } from '@mui/material/styles';


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 32,
    height: 14,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#2A3B43',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 10,
        height: 10,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const AdminSubCard = (props) => {

    const originalDateTime = props?.createdat;
    const formattedDate = new Date(originalDateTime).toLocaleDateString('en-US', {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
    });

    const formattedTime = new Date(originalDateTime).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric'
    });

    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return (
        <>
            <div onClick={props.onchange} style={{ background: props.bg }} className='flex shadow lg:shrink-0 flex-col items-start gap-4 w-full py-3 px-2 rounded-md lg:w-[32%] lg:py-4' >
                <div className='flex items-center justify-between w-full'>
                    <h6 className='text-[#1C1C1C]  font-semibold text-[2vmax] lg:text-[1vmax]'>{props?.text}</h6>
                    <div className='flex items-center gap-2'>
                        <h6 className='text-[#00B386]  font-semibold text-[2vmax] lg:text-[.8vmax]'>{props?.per}</h6>
                        <AntSwitch onClick={(e) => e.stopPropagation()} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                    </div>
                </div>
                <div className='w-full'>
                    <p className='text-[#575555]  text-[1.5vmax] lg:text-[.7vmax]'>{props?.description}</p>
                </div>
                <div className='flex  items-center gap-3 w-full lg:gap-2'>
                    <small className='text-[#1C1C1C]  text-[1.5vmax] font-semibold lg:text-[.8vmax]'>Subscribers: <span>{props?.sub}</span></small>
                    {/* <RightArrow /> */}
                </div>
                {props?.sub ? (
                    <div className='hidden items-center gap-3 w-full lg:gap-2'>
                        <small className='text-[#1C1C1C]  text-[1.5vmax] font-semibold lg:text-[.8vmax]'>Subscribers: <span>{props?.sub}</span></small>
                        <RightArrow />
                    </div>
                ) : (
                    <div className=' hidden items-center gap-3 w-full lg:gap-2'>
                        <RightIcon />
                        <small className='text-[#1C1C1C]  text-[1.5vmax] font-semibold lg:text-[.8vmax]'>All features are included</small>
                    </div>
                )}

                <div className='flex items-start justify-between w-full'>
                    <div className='w-full'>
                        <h6 className='text-[#666666]  text-[1.3vmax] lg:text-[.7vmax]'>Created at - {formattedDateTime}</h6>
                    </div>
                    <div className='flex flex-col items-end gap-2 lg:flex-row lg:gap-3 lg:items-center'>
                        {props?.sub ? (
                            <h5></h5>
                        ) : (
                            <h5 className={`text-${props.color} font-Poppins text-1.5vw whitespace-nowrap lg:text-[0.8vmax]`}>{props?.trialperiod} days</h5>
                        )}
                        <h4 className='text-[#1C1C1C]  font-semibold whitespace-nowrap text-[2vmax] lg:text-[1vmax]'>₹ {props?.price}</h4>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminSubCard