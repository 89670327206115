import { DeleteOutline, ShareOutlined } from '@mui/icons-material'
import { Chip, IconButton, Skeleton, Tooltip } from '@mui/material'
import * as React from 'react'
import PublishIcon from '@mui/icons-material/Publish';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { IMAGE_URL } from '../default.config';
import moment from 'moment';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';

const NewsArticle = ({ title, content, cover, category, createdAt, status, click, admin, onPublish, onDelete, onUnpublish, onShareNews }) => {

    const theme = useTheme();

    const contentRef = React.useRef()
    const contentRefMob = React.useRef()

    React.useEffect(() => {
        contentRef.current.innerHTML = content
        contentRefMob.current.innerHTML = content
    }, [content])

    const onShare = (e) => {
        e.stopPropagation()
        onShareNews()
    }

    return (
        <div onClick={() => click()} className='w-full flex px-6 py-3 hover:shadow hover:bg-[#fafafa] transition cursor-pointer rounded-lg'>
            <div className='flex-1 gap-4 hidden lg:flex'>
                <div className='flex-1 flex flex-col gap-3'>
                    <h1 className='text-xl text-[#242424] font-medium'>
                        {title}
                    </h1>
                    <p ref={contentRef} className='text-sm text-[#000000] font-normal break-words'>

                    </p>
                    <div className='flex flex-wrap gap-4 justify-start items-center py-2'>
                        {createdAt ?
                            <p className='text-xs text-gray-500'>{moment(createdAt).fromNow()}</p>
                            : <Skeleton variant='text' width={80} height={20} />
                        }
                        <div className='flex gap-2'>
                            {category?.map((c, i) => (
                                <Chip key={i} size='small' label={c} sx={{ fontSize: 12, px: 1.2, py: 1.6, color: '#3e3e3e' }} />
                            ))}
                        </div>
                        <span className='flex-1'></span>
                        {admin ? <>
                            {status == 'published' ?
                                <Tooltip title='Unpublish'>
                                    <IconButton color='warning' onClick={onUnpublish}>
                                        <UnpublishedIcon />
                                    </IconButton>
                                </Tooltip> :
                                <Tooltip title='Publish'>
                                    <IconButton color='success' onClick={onPublish}>
                                        <PublishIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            <Tooltip title='Delete'>
                                <IconButton color='error' onClick={onDelete}>
                                    <DeleteOutline />
                                </IconButton>
                            </Tooltip>
                        </> :
                            <IconButton onClick={(e) => onShare(e)}>
                                <ShareOutlined />
                            </IconButton>
                        }
                    </div>
                </div>
                <div className='min-w-[160px] w-[160px] max-h-[full] min-h-[full]'>
                    <img className='h-full object-cover rounded-[12.46px]' src={IMAGE_URL + 'thumb-' + cover} />
                </div>
            </div>
            <div className='lg:hidden flex flex-col gap-2 flex-1'>
                <div className='w-full'>
                    <img className='h-full w-full object-cover rounded-[12.46px]' src={IMAGE_URL + 'thumb-' + cover} />
                </div>
                <h1 className='text-xl text-[#242424] font-medium'>
                    {title}
                </h1>
                <p ref={contentRefMob} className='text-sm text-[#000000] font-normal break-words'>

                </p>
                <p className='text-xs text-gray-500'>{moment(createdAt).fromNow()}</p>
                <div className='flex gap-4 justify-start items-center py-2'>
                    <div className='flex gap-2 flex-wrap'>
                        {category?.map((c, i) => (
                            <Chip key={i} size='small' label={c} sx={{ fontSize: 12, px: 1.2, py: 1.6, color: '#3e3e3e' }} />
                        ))}
                    </div>
                    <span className='flex-1'></span>
                    {admin ? <>
                        {status == 'published' ?
                            <Tooltip title='Unpublish'>
                                <IconButton color='warning' onClick={onUnpublish}>
                                    <UnpublishedIcon />
                                </IconButton>
                            </Tooltip> :
                            <Tooltip title='Publish'>
                                <IconButton color='success' onClick={onPublish}>
                                    <PublishIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title='Delete'>
                            <IconButton color='error' onClick={onDelete}>
                                <DeleteOutline />
                            </IconButton>
                        </Tooltip>
                    </> :
                        <IconButton onClick={(e) => onShare(e)}>
                            <ShareOutlined />
                        </IconButton>
                    }
                </div>
            </div>
        </div>
    )
}

export default NewsArticle