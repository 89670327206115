import React from 'react'
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { styled } from '@mui/material/styles';
import FooterLogo from '../assets/footerlogo.png'
import { ApiService } from '../Services/api.services';
import { asyngetStatus, showToast } from '../store/Actions/Action'
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { emailRegex, mobileRegex, nameRegex } from '../ValidationRegex';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { H5 } from '../Theme/ThemeComponent';
import { useThemeContext } from '../Theme/ThemeContext';

const CustomTextarea = styled(TextareaAutosize)(({ theme }) => ({
    width: '100%',
    border: '1px solid rgba(174, 165, 165, 0.44)',
    backgroundColor: 'transparent',
    color: '#AEA5A5D9',
    resize: 'none',
    padding: theme.spacing(1),
    '&::placeholder': {
        color: '#504d4d',
    },
    '&:focus': {
        outline: 'none', // Remove the outline
        boxShadow: 'none', // Remove the box-shadow
    },
}));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Feedback',
    'Complaint',
    'Suggestion',
    'Other',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}



const _form = {
    name: '',
    email: '',
    message: '',
    type: ''
}

const api = new ApiService()

const Footer = ({ onEarnWithUs, onLearnWithUs }) => {
    const {theme} = useThemeContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [askQueryForm, setAskQueryForm] = React.useState({ ..._form })
    const [isLoading, setIsLoading] = React.useState(false)
    // const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const earnwithus = React.useRef()
    const learnwithus = React.useRef()

    const handleChange = (event) => {
        // For a single selection, set directly to the state
        setPersonName(event?.target?.value);
    };

    const onFormChange = (name, value) => {
        try {
            const form = { ...askQueryForm }
            form[name] = value
            setAskQueryForm(form)
        }
        catch (e) {
            console.log(`ERROR WHILE FORM CHANGE --- ${e}`)
        }
    }

    const handleQuery = async () => {
        try {
            const lowercaseType = personName?.toLowerCase();
            const body = {
                ...askQueryForm,
                type: lowercaseType
            }
            setAskQueryForm(body);
            setIsLoading(true)
            const response = await api.askQuery(body)
            console.log(response?.statusCode);
            setIsLoading(false)
            // console.log(response);
            if (response) {
                const _snackbar = {
                    message: 'query created!    ',
                    open: true,
                    duration: 3000,
                    type: 'success'
                };
                dispatch(showToast(_snackbar));
                setAskQueryForm(_form); //NOT REQUIRED
                setPersonName('')
            }
        }
        catch (e) {
            console.log(`ERROR WHILE LOGIN --- ${e}`)
            setIsLoading(false);
        }
    }

    const focusToLearn = () => {
        learnwithus.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const focusToEarn = () => {
        earnwithus.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const tnc = `https://docs.google.com/document/d/e/2PACX-1vQazc1tynjaaBBFYTXxFmbkj34yt0BITon5A24cPfZwOltEDphPF4AYBWXZgknAREgfy8QzkRr0mvbq/pub?embedded=true`;

    return (
        <>
            <div className='bg-[#1C1C1C] px-3 lg:h-fit w-full flex flex-col items-center justify-center gap-12 sm:gap-24 py-4 lg:flex-row-reverse lg:items-start lg:px-12 lg:justify-center lg:py-6'>
                <H5
                    title={`©2018, All rights reserved. Handcrafted by eulogik`}
                    color={theme.palette.text.white}
                />
            </div>
        </>
    )
}

export default Footer