import { createSlice } from "@reduxjs/toolkit";
// import marketData from '../../option.data.json'
// const market = {
//     "nifty": {
//         "index": "NIFTY",
//         "tradingsymbol": "NSE:NIFTY 50",
//         "strikeMargin": 50,
//         "type": "NIFTY_OI",
//         "qty": 50,
//         "exchange": "NFO",
//         "options": null,
//         "strikePrice": null,
//         "start": 18700,
//         "end": 19700,
//         "strike": 0,
//         "monthEnd": true,
//         "indexInstrumentToken": 256265,
//         "instrumentToken": 256265,
//         "expiry": [],
//         "strikePriceH": 19200,
//         "symbols": {}
//     },
//     "banknifty": {
//         "index": "BANKNIFTY",
//         "tradingsymbol": "NSE:NIFTY BANK",
//         "strikeMargin": 100,
//         "type": "BANKNIFTY_OI",
//         "qty": 15,
//         "exchange": "NFO",
//         "options": null,
//         "strikePrice": null,
//         "start": 42300,
//         "end": 44300,
//         "strike": 0,
//         "monthEnd": true,
//         "indexInstrumentToken": 260105,
//         "instrumentToken": 260105,
//         "expiry": [],
//         "strikePriceH": 43300,
//         "symbols": {}
//     },
//     "finnifty": {
//         "index": "FINNIFTY",
//         "tradingsymbol": "NSE:NIFTY FIN SERVICE",
//         "strikeMargin": 50,
//         "type": "FINNIFTY_OI",
//         "qty": 40,
//         "exchange": "NFO",
//         "options": null,
//         "strikePrice": null,
//         "start": 18900,
//         "end": 19900,
//         "strike": 0,
//         "monthEnd": true,
//         "indexInstrumentToken": 257801,
//         "instrumentToken": 257801,
//         "expiry": [],
//         "strikePriceH": 19400,
//         "symbols": {}
//     },
//     "midcpnifty": {
//         "index": "MIDCPNIFTY",
//         "tradingsymbol": "NSE:NIFTY MID SELECT",
//         "strikeMargin": 25,
//         "type": "MIDCPNIFTY_OI",
//         "qty": 75,
//         "exchange": "NFO",
//         "options": null,
//         "strikePrice": null,
//         "start": 8550,
//         "end": 9050,
//         "strike": 0,
//         "monthEnd": true,
//         "indexInstrumentToken": 288009,
//         "instrumentToken": 288009,
//         "expiry": [],
//         "strikePriceH": 8800,
//         "symbols": {}
//     },
//     "sensex": {
//         "index": "SENSEX",
//         "tradingsymbol": "BSE:SENSEX",
//         "strikeMargin": 100,
//         "type": "SENSEX_OI",
//         "qty": 10,
//         "exchange": "BFO",
//         "options": null,
//         "strikePrice": null,
//         "start": 63500,
//         "end": 65500,
//         "strike": 0,
//         "monthEnd": true,
//         "indexInstrumentToken": 265,
//         "instrumentToken": 265,
//         "expiry": [],
//         "strikePriceH": 64500,
//         "symbols": {}
//     }
// }

const market = {
    currentIndex: "nifty",
    nifty: {},
    banknifty: {},
    finnifty: {},
    midcpnifty: {},
    sensex: {},
    bankex: {},
    instruments: {},
    previousTicks: {},
}

const initialState = market

const MarketReducer = (state = initialState, action) => {
    switch (action.payload.type) {
        case 'nifty': return { ...state, nifty: action.payload.payload };
        case 'banknifty': return { ...state, banknifty: action.payload.payload }
        case 'finnifty': return { ...state, finnifty: action.payload.payload }
        case 'midcpnifty': return { ...state, midcpnifty: action.payload.payload }
        case 'sensex': return { ...state, sensex: action.payload.payload }
        case 'bankex': return { ...state, bankex: action.payload.payload }
        case 'currentIndex': return { ...state, currentIndex: action.payload.payload }
        case 'previousTicks': return { ...state, previousTicks: action.payload.payload }
        case 'instruments': return { ...state, instruments: action.payload.payload }
        case 'updateMarket': return { ...state, ...action.payload.payload }
        default: return { ...state, ...action.payload.payload }
    }
}

const MarketIndex = createSlice({
    name: 'market',
    initialState: initialState,
    reducers: {
        marketReducer: MarketReducer,
        updatemarket: (state, action) => {
            return { ...state, ...action.payload.payload };
        },
    }
})

export const { marketReducer, updatemarket } = MarketIndex.actions
export default MarketIndex.reducer;