import React from 'react'

const signUpImg = require('../../assets/howtotradesignupimg.png')   
const kiteFormImg = require('../../assets/kiteForm.png')    
const kiteMyapp = require('../../assets/kiteMyapp.png') 
const Billing = require('../../assets/Billing.png') 
const appDetails = require('../../assets/apppDetails.png')  
const createApp = require('../../assets/createApp.png') 
const addBroker = require('../../assets/addBroker.png') 

const HowTo = () => {
    return (
        <>
            <div className='w-full flex items-center justify-center px-2'>
                <div className='w-full md:w-[75%] lg:w-[50%]  flex flex-col gap-6 lg:gap-8'>
                    <div className='w-full py-6'>
                        <div className='w-full flex items-center py-2 '>
                            <h1 className='text-2xl lg:text-5xl my-4 lg:my-6 font-light'>Steps to connect your Zerodha Account to Tradewolf</h1>
                        </div>
                        <div className='w-full px-2'>
                            <ol className='text-blue-600 px-2 text-sm list-decimal'>
                                <li className="hover:border-b hover:border-blue-600 w-fit">
                                    <a href="#step-1">Signup on https://kite.trade</a>
                                </li>
                                <li className="hover:border-b hover:border-blue-600 w-fit">
                                    <a href="#step-2">Setup billing</a>
                                </li>
                                <li className="hover:border-b hover:border-blue-600 w-fit">
                                    <a href="#step-3">Create  an app</a>
                                </li>
                                <li className="hover:border-b hover:border-blue-600 w-fit">
                                    <a href="#step-4"> Add API key & secret to Tradewolf - Final Step</a>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div id='step-1' className='w-full'>
                        <div className='w-full py-2 px-2 flex flex-col gap-2'>
                            <h3 className='text-xl lg:text-2xl'>1. Signup on https://kite.trade</h3>
                            <p className='text-sm lg:text-sm my-2'>Go to <a className="border-b border-blue-600 w-fit" href="#">https://kite.trade</a> and Signup with all the correct credentials and go to the dashboard.</p>
                        </div>
                        <div className='w-full h-auto lg:h-[56vh] py-3'>
                            <img className='lg:h-full object-contain border border-black-200 p-2 rounded' src={signUpImg} alt="signUpImg" />
                        </div>
                        <div className='w-full h-auto lg:h-[56vh] py-3'>
                            <img className=' lg:h-full object-contain border border-black-200 p-2 rounded' src={kiteFormImg} alt="kiteFormImg" />
                        </div>
                        <div className='w-full h-auto lg:h-[56vh] py-3'>
                            <img className='h-full object-contain border border-black-200 p-2 rounded' src={kiteMyapp} alt="kiteFormImg" />
                        </div>
                    </div>
                    <div id='step-2' className='w-full'>
                        <div className='w-full py-2 px-2 flex flex-col gap-2'>
                            <h3 className='text-xl lg:text-2xl'>2. Setup billing</h3>
                            <p className='text-sm lg:text-sm w-full lg:w-[65%] my-2'>Go to Billing in the top navigation and link your zerodha trading account to enable kite.trade to charge the api charges directly from your trading account.
                                Alternatively, you can also pay the  sum through a payment gateway but it’ll require you to manually add balance every time it’s exhausted.</p>
                        </div>
                        <div className='w-full h-auto lg:h-[56vh] py-3'>
                            <img className='h-full object-contain border border-black-200 p-3 rounded' src={Billing} alt="Billing" />
                        </div>
                    </div>
                    <div id='step-3' className='w-full'>
                        <div className='w-full py-2 px-2 flex flex-col gap-2'>
                            <h3 className='text-xl lg:text-2xl'>3. Create  an app</h3>
                            <p className='text-sm lg:text-sm w-full lg:w-[60%]'>Go to “My apps” and hit “create new app”. Add the following details, submit and confirm.</p>
                        </div>
                        <div className='w-full h-auto lg:h-[56vh] py-3'>
                            <img className='h-full object-contain border border-black-200 p-2 rounded' src={createApp} alt="createApp" />
                        </div>
                        <div class="w-full lg:w-[60%]  mt-8">
                            <table class="table-auto border-collapse w-full">
                                <thead>
                                    <tr>
                                        <th class="border border-gray-400 px-4 py-2 ">Type *</th>
                                        <th class="border border-gray-400 px-4 py-2">Connect</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">App name *</td>
                                        <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">Tradewolf</td>
                                    </tr>
                                    <tr>
                                        <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">Zerodha Client ID *</td>
                                        <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm   text-gray-300">**Your Zerodha Login ID**</td>
                                    </tr>
                                    <tr>
                                        <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">Redirect URL *</td>
                                        <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">https://go.tradewolf.in/auth-broker</td>
                                    </tr>
                                    <tr>
                                        <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">Postback URL</td>
                                        <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">https://go-api.tradewolf.in/api/webhook</td>
                                    </tr>
                                    <tr>
                                        <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">Description *</td>
                                        <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">Tradewolf Connect</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div id='step-4' className='w-full'>
                        <div className='w-full py-2 px-2 flex flex-col gap-2'>
                            <h3 className='text-xl lg:text-2xl'>4. Add API key & secret to Tradewolf - Final Step</h3>
                            <p className='text-xs lg:text-sm w-full lg:w-[60%]'>Go to the app you just created on kite.trade and copy the API Key and Secret code. Go to “Add Broker” option on Tradewolf and paste the API Key and Secret codes you just copied.</p>
                        </div>
                        <div className='w-full h-auto lg:h-[56vh] py-3'>
                            <img className='h-full object-contain border border-black-200 p-2 rounded' src={appDetails} alt="appDetails" />
                        </div>
                        <div className='w-full h-auto lg:h-[56vh] py-3'>
                            <img className='h-full object-contain border border-black-200 p-2 rounded' src={addBroker} alt="addBroker" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowTo





// import AddBrokerZerodha from '../assets/add-broker-zerodha.pdf'

// const HowTo = () => (
//     <iframe
//         className="w-full h-full"
//         src={AddBrokerZerodha}
//     />
// )

// export default HowTo
