import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { updateVwapDatasource } from "../../store/Reducer/ToolsReducer";
import { http } from "../../Services/api.services";

const vwap = require("technicalindicators").VWAP;
// GET EVENT FOR POSISITIONS AND OPEN ORDERS

const ToolsContext = createContext();

export const useTools = () => {
  return useContext(ToolsContext);
};

export const ToolsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [updatePositions, setUpdatePositions] = useState("");
  const [updateOrders, setUpdateOrders] = useState("");
  const [minuteUpdate, setMinuteUpdate] = useState(null);
  const [loading, setloading] = useState(false);

  const { vwapStartDate, currentVwapIndex, vwapDataSource } = useSelector(
    (state) => state.tools
  );

  let xTimer;

  const returnHistoric = (body) => {
    console.log(body, "historic body!");
    return new Promise(async (resolve, reject) => {
      try {
        const response = await http.post("/ds/historic-data/volume", body);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  };

  useEffect(() => {
    return () => {
      if (xTimer) {
        clearInterval(xTimer);
      }
      if (_secondsWatcher) {
        clearInterval(_secondsWatcher);
      }
    };
  }, []);

  useEffect(() => {
    if (minuteUpdate) {
      const _body = {
        instrument_token: currentVwapIndex?.instrument,
        from: dayjs(new Date(new Date().getTime() - 60000)).format(
          "YYYY-MM-DD HH:mm:00"
        ),
        to: dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:00"),
        interval: "minute",
      };
      setHistoric(_body);
    }
  }, [minuteUpdate]);

  const setHistoric = async (_body) => {
    const __data = await returnHistoric(_body);
    onvwapChange([...vwapDataSource, ...__data.data.data]);
  };

  var _secondsWatcher;
  const onFormSubmit = async () => {
    setloading(true);
    try {
      if (xTimer) {
        clearInterval(xTimer);
      }
      const body = {
        instrument_token: currentVwapIndex?.instrument,
        from: dayjs(new Date(vwapStartDate)).format("YYYY-MM-DD HH:mm:00"),
        to: dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:00"),
        interval: "minute",
      };  
      const response = await returnHistoric(body);
      if (response.data && response.data.data && response.data.data.length) {
        await onvwapChange(response.data.data);
        setloading(false);
      } else {
        dispatch(updateVwapDatasource([]));
      }
      const __datasource = [...response.data.data];
      _secondsWatcher = setInterval(async () => {
        const _body = {
          instrument_token: currentVwapIndex?.instrument,
          from: dayjs(new Date(body.to)).format("YYYY-MM-DD HH:mm:00"),
          to: dayjs(new Date().getTime()).format("YYYY-MM-DD HH:mm:00"),
          interval: "minute",
        };
        const __data = await returnHistoric(_body);
        const _datasource = [...__datasource, ...__data.data.data];
        await onvwapChange(_datasource);
        setloading(false);
        initMinuteTicker(_datasource);
        clearInterval(_secondsWatcher);
      }, 60000);
      const newData = [...response.data.data].reverse();
      dispatch(updateVwapDatasource(newData));
    } catch (e) {
      setloading(false);
      if (xTimer) {
        clearInterval(xTimer);
      }
      if (_secondsWatcher) {
        clearInterval(_secondsWatcher);
      }
    }
  };

  const initMinuteTicker = (_datasource) => {
    try {
      xTimer = setInterval(() => {
        setMinuteUpdate(new Date().getTime());
      }, 60000);
    } catch (e) {
      console.log(e);
    }
  };

  const onvwapChange = (data) => {
    try {
      const vwapBody = {
        open: [],
        close: [],
        high: [],
        low: [],
        volume: [],
      };

      const mutableData = data.map((row) => ({ ...row }));

      for (let index = 0; index < mutableData.length; index++) {
        const row = mutableData[index];
        vwapBody.low.unshift(row.low);
        vwapBody.high.unshift(row.high);
        vwapBody.open.unshift(row.open);
        vwapBody.close.unshift(row.close);
        vwapBody.volume.unshift(row.volume);

        if (index === mutableData.length - 1) {
          let results = vwap.calculate(vwapBody);

          if (results.length) {
            results = results.reverse();
            for (let _index = 0; _index < results.length; _index++) {
              const _vwap_result_ = results[_index];
              mutableData[_index].vwap = Math.round(_vwap_result_) || 0;
              if (_index === results.length - 1) {
                const newData = [...mutableData];
                dispatch(updateVwapDatasource(newData));
              }
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  //   useEffect(() => {
  //     if (vwapStartDate && currentVwapIndex) {
  //       onFormSubmit();
  //     }
  //   }, [vwapStartDate, currentVwapIndex]);

  const contextValue = {
    onFormSubmit,
    loading,
  };

  return (
    <ToolsContext.Provider value={contextValue}>
      {children}
    </ToolsContext.Provider>
  );
};
