import React, { createContext, useState, useMemo, useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { darkTheme, lightTheme } from './ThemeComponent';

const ThemeContext = createContext();

export function CustomThemeProvider({ children }) {
  const [themeMode, setThemeMode] = useState('system');
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(() => {
    if (themeMode === 'system') {
      return prefersDarkMode ? darkTheme : lightTheme;
    }
    return themeMode === 'dark' ? darkTheme : lightTheme;
  }, [themeMode, prefersDarkMode]);

  const toggleTheme = () => {
    setThemeMode(prevMode => (prevMode === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useThemeContext() {
  return useContext(ThemeContext);
}
