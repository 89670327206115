import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddTarget from './AddTarget';

const DashedBorderButton = styled(Button)({
    border: '1px dashed #000',
    color: '#1C1C1C',
    minWidth: '150px'
});

const AddTraillingCondition = () => {
    const [openAddCondition, setOpenAddCondition] = React.useState(false)

    return (
        <>
            <div className='px-2 lg:px-6 flex-1 overflow-y-auto flex flex-col gap-3'>
                {/* BASIC INFORMATION  */}
                <div className='border border-[#37373717] bg-[#FEFEFE] '>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon color='#000000' />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6 className='text-md font-bold'>Basic info</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='flex w-full flex-col mt-4 justify-between lg:flex-row gap-3 lg:gap-5'>
                                <div className='w-full lg:w-[24%] '>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label="Trailing name"
                                        variant="outlined"
                                    />
                                </div>
                                <div className='w-full lg:w-[75%] '>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label="Short description"
                                        variant="outlined"
                                    />
                                </div>
                            </div>

                        </AccordionDetails>
                    </Accordion>
                </div>

                {/* ACTIONS  */}
                <div className='border border-[#37373717] w-full flex flex-col items-center justify-center flex-1 bg-[#FEFEFE]'>
                    <div className=' w-full lg:w-9/12 flex flex-col items-center justify-center flex-1 '>
                        <div className='w-full flex flex-col justify-center items-center py-4'>
                            {/* <ConditionCard />
                            <TabComponent />
                            <ConditionCard /> */}
                        </div>

                        {/* <GroupCard /> */}

                        {/* ADD BUTTONS  */}
                        <div className='w-full flex items-center justify-center gap-3 my-4'>
                            <DashedBorderButton
                                size='small'
                                onClick={() => setOpenAddCondition(true)}
                            >
                                <AddOutlinedIcon
                                    color='#1C1C1C'
                                />
                                Target
                            </DashedBorderButton>
                        </div>
                    </div>

                </div>
                <div className='w-full flex justify-end py-6 '>
                    <Button
                        disabled
                        variant='contained'
                        size="small"
                    >
                        SAVE
                    </Button>
                </div>

                <AddTarget
                    open={openAddCondition}
                    onclose={() => setOpenAddCondition(false)}
                />
            </div >

        </>
    )
}

export default AddTraillingCondition 
