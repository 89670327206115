import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import AddBroker from '../Public/Options/AddBroker'
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { http } from '../Services/api.services';

import AddBrokerStep1 from '../assets/add-broker/1.jpg'
import AddBrokerStep2 from '../assets/add-broker/2.jpg'
import AddBrokerStep3 from '../assets/add-broker/3.jpg'
import AddBrokerStep4 from '../assets/add-broker/4.jpg'
import { LinearProgress } from '@mui/material';
import { setUser } from '../store/Reducer/UserReducer';
import { duration } from 'moment';
import { showToast } from '../store/Actions/Action';




export default function StepperComponent(props) {



    const theme = useTheme();
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [isLoading, setIsLoading] = React.useState(false)
    const [myBroker, setMyBroker] = React.useState();


    const [api_key, setApiKey] = React.useState('');
    const [api_secret, setApiSecret] = React.useState('');
    const [user_id, setUserId] = React.useState('');

    const contentRef = React.useRef()
    const tableRef = React.useRef()

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        console.log(steps?.length - 1);
        setActiveStep(steps?.length - 1)
    };

    React.useEffect(() => {
        if (props?.brokers && props?.brokers.length) {
            {
                setMyBroker(props?.brokers[0])
            }
        }
    }, [props])

    React.useEffect(() => {
        if (contentRef?.current) {
            contentRef.current.innerHTML = steps[activeStep]['component']
            if (tableRef && tableRef.current) {
                tableRef.current.innerHTML = steps[activeStep]['table']
            }
        }
    }, [activeStep])


    //   ADD BROKER FUNCTION 

    const onsubmit = async () => {
        try {
            // console.log(user)    
            setIsLoading(true)
            // console.log(myBroker)
            console.log(user, 'hehhhhe');

            const body = {
                "brokerId": myBroker?.id,
                "name": myBroker?.name,
                "userId": user?.id,
                "user_name": user_id,
                "api_key": api_key,
                "api_secret": api_secret
            }
            const response = await http.post(`/user/add-broker`, body)
            setIsLoading(false)
            if (response.status == 200) {
                const _snackbar = {
                    message: 'Broker added successfully!',
                    open: true,
                    type: 'success',
                    duration: 3000
                }
                dispatch(showToast(_snackbar))
                dispatch(setUser(response.data))
                props.onClose()
                window?.location?.reload();
            }
            else {
                const _snackbar = {
                    message: response?.data?.message,
                    open: true,
                    type: 'error',
                    duration: 3000
                }
                dispatch(showToast(_snackbar))
            }
        }
        catch (e) {
            setIsLoading(false)
            console.log('add broker error', e)
            const _snackbar = {
                message: e?.response?.data?.message?.detail,
                open: true,
                type: 'error',
                duration: 3000
            }
            dispatch(showToast(_snackbar))
        }
    }


    const steps = [
        {
            image: AddBrokerStep1,
            component: `<p className='my-4 mx-6 text-lg text-slate-600'>Go to <a className='text-blue-600 hover:underline cursor-pointer' href='https://kite.trade' target='_blank'>https://kite.trade</a> and Signup with all the correct credentials and go to the dashboard.</p>`,
        },
        {
            image: AddBrokerStep2,
            component: `<p className='my-4 mx-6 text-lg text-slate-600'>Go to Billing in the top navigation and link your zerodha trading account to enable kite.trade to charge the api charges directly from your trading account. Alternatively, you can also pay the sum through a payment gateway but it’ll require you to manually add balance every time it’s exhausted.</p>`,
        },
        {
            image: AddBrokerStep3,
            component: `<p className='my-4 mx-6 text-lg text-slate-600'>Go to “My apps” and hit “create new app”. Add the following details, submit and confirm.</p>`,
            table: `<table class="table-auto border-collapse w-full">
            <thead>
                <tr>
                    <th class="border border-gray-400 px-4 py-2 ">Type *</th>
                    <th class="border border-gray-400 px-4 py-2">Connect</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">App name *</td>
                    <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">Fastr</td>
                </tr>
                <tr>
                    <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">Zerodha Client ID *</td>
                    <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm   text-gray-300">**Your Zerodha Login ID**</td>
                </tr>
                <tr>
                    <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">Redirect URL *</td>
                    <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">https://go.tradewolf.in/auth-broker</td>
                </tr>
                <tr>
                    <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">Postback URL</td>
                    <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">https://go-api.tradewolf.in/api/webhook</td>
                </tr>
                <tr>
                    <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">Description *</td>
                    <td class="border border-gray-400 px-4 py-2 text-xs lg:text-sm ">Tradewolf Connect</td>
                </tr>
            </tbody>
                    </table>`
        },
        {
            image: AddBrokerStep4,
            component: `<p className='my-4 mx-6 text-lg text-slate-600'>Go to the app you just created on kite.trade and copy the API Key and Secret code. Go to “Add Broker” option on Tradewolf and paste the API Key and Secret codes you just copied.</p>`,
        },
        {
            component:
                <AddBroker
                    myBroker={myBroker}
                    setMyBroker={setMyBroker}
                    api_key={api_key}
                    setApiKey={setApiKey}
                    api_secret={api_secret}
                    setApiSecret={setApiSecret}
                    setUserId={setUserId}
                    brokers={props?.brokers}
                    user_id={user_id}
                />,
        }
    ];

    const [imageLoaded, setImgLoaded] = React.useState([false, false, false, false])

    const onLoad = (e, step) => {
        console.log(e)
        const _loaded = [...imageLoaded]
        _loaded[step] = true
        setImgLoaded(_loaded)
    }


    return (
        <Box sx={{ width: '100%', flexGrow: 1, height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <div style={{ backgroundColor: 'rgba(248, 248, 248, 0.99)', }} className='w-full h-full lg:w-[40vw] lg:max-h-[60vh] flex justify-center overflow-auto relative'>
                {activeStep < 4 ?
                    <div>
                        {imageLoaded[activeStep] ?
                            null :
                            <div className='absolute top-0 left-0 right-0 h-full w-full flex flex-col justify-center items-center lg:h-[36vh] bg-white z-[999999]'>
                                <LinearProgress sx={{ width: 280 }} color='primary' variant='indeterminate' />
                                <p className='text-sm text-slate-400 mt-2'>Loading image...</p>
                            </div>
                        }
                        <img
                            className='flex-1'
                            src={steps[activeStep]['image']}
                            height={'100%'}
                            width={'100%'}
                            onLoad={(e) => onLoad(e, activeStep)}
                        />
                        <div className='py-2' ref={contentRef}></div>
                        {steps[activeStep]['table'] ?
                            <>
                                <br></br>
                                <div ref={tableRef}></div>
                            </> : null
                        }

                    </div> :
                    <AddBroker
                        myBroker={myBroker}
                        setMyBroker={setMyBroker}
                        api_key={api_key}
                        setApiKey={setApiKey}
                        api_secret={api_secret}
                        setApiSecret={setApiSecret}
                        setUserId={setUserId}
                        brokers={props?.brokers}
                        user_id={user_id}
                    />
                }
            </div>


            {activeStep === steps.length ? (
                // <React.Fragment>
                //     <Typography sx={{ mt: 2, mb: 1 }}>
                //         All steps completed - you&apos;re finished
                //     </Typography>
                //     <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                //         <Box sx={{ flex: '1 1 auto' }} />
                //         <Button onClick={handleReset}>Reset</Button>
                //     </Box>
                // </React.Fragment>
                <></>
            ) : (
                <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <div className='flex items-center gap-3'>
                            <Button
                                color="inherit"
                                variant='outlined'
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                size='small'
                                sx={{
                                    padding: '.2vmax 1vmax',
                                }}
                            >
                                {/* <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                        <path d="M6.56164 13.1257L-0.00390625 6.56018L6.56164 -0.00537109L7.72703 1.16001L2.32686 6.56018L7.72703 11.9603L6.56164 13.1257Z" fill="black" />
                                        <path d="M11.1554 13.1257L4.58984 6.56018L11.1554 -0.00537109L12.3208 1.16001L6.92061 6.56018L12.3208 11.9603L11.1554 13.1257Z" fill="black" />
                                    </svg>
                                </div> */}
                                PREVIOUS
                            </Button>
                            {/* {isStepOptional(activeStep) && ( */}
                            <Button
                                variant='outlined'
                                color="inherit"
                                disabled={activeStep === steps.length - 1}
                                onClick={handleSkip}
                                size='small'
                                sx={{
                                    padding: '.2vmax 1vmax'
                                }}
                            >
                                Skip
                            </Button>
                            {/* )} */}
                        </div>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {activeStep === steps.length - 1 ?
                            <LoadingButton
                                loading={isLoading}
                                fullWidth
                                sx={{ width: 80 }}
                                onClick={() => onsubmit()}
                                variant="contained"
                                size="small"
                            >
                                Add
                            </LoadingButton>
                            :
                            <Button
                                variant='contained'
                                size='small'
                                sx={{
                                    backgroundColor: '#1C1C1C',
                                    padding: '.4vmax 1.4vmax'
                                }}
                                onClick={handleNext}
                            >
                                Next
                            </Button>
                        }
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}
