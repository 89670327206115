// themes.js
import { createTheme } from '@mui/material/styles';
import React from 'react';
import { Typography, Link, } from '@mui/material';

// Define your custom light theme
export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#ff0000', // Example primary color
        },
        text: {
            profit: '#00B386',
            loss: '#FF0000',
            white: '#FFFFFF',
            black: '#000',
            snowWhite: '#FCFCFC',
            ghostWhite: '#F3F3F3',
            charcolGray: '#373737',
            coolGray: '#858585',
            lightGray: '#E2E2E2',
            silverGray: '#B4B4B4',
            whisperGray: '#F2F2F2',
            green: '#00B386',
            mintGreen: '#00B386',
            coralRed: '#EB5B3C',
            red: '#FF0000',
            skyBlue: '#E0EDF4',
            pink: '#FEEEF5',
            mist: '#E9E5F3',
            blue: '#3F88F6',
            skyBlue: '#448FFF',
            gold: '#D7B950',
            lightBlue: '#6791FB'
        },
        icon: {
            primary: '#696969'
        },
        background: {
            white: '#FFFFFF',
            snowWhite: '#FCFCFC',
            ghostWhite: '#F3F3F3',
            charcolGray: '#373737',
            coolGray: '#858585',
            lightGray: '#E2E2E2',
            silverGray: '#B4B4B4',
            whisperGray: '#F2F2F2',
            green: '#F0F8E6',
            mintGreen: '#00B386',
            coralRed: '#EB5B3C',
            red: '#FF0000',
            skyBlue: '#E0EDF4',
            pink: '#FEEEF5',
            mist: '#E9E5F3',
            green: '#00B386',
            red: '#FF0000',
            lightBlue: '#448FFF0D'
        },
    },
});

// Define your custom dark theme
export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#ff0000', // Example primary color
        },
        text: {
            profit: '#00B386',
            loss: '#FF0000',
            white: '#FFFFFF',
            black: '#000',
            snowWhite: '#FCFCFC',
            ghostWhite: '#F3F3F3',
            charcolGray: '#373737',
            coolGray: '#858585',
            lightGray: '#E2E2E2',
            silverGray: '#B4B4B4',
            whisperGray: '#F2F2F2',
            green: '#00B386',
            mintGreen: '#00B386',
            coralRed: '#EB5B3C',
            red: '#FF0000',
            skyBlue: '#E0EDF4',
            pink: '#FEEEF5',
            mist: '#E9E5F3',
            blue: '#3F88F6',
            skyBlue: '#448FFF'
        },
        icon: {
            primary: '#696969'
        },
        background: {
            white: '#FFFFFF',
            snowWhite: '#FCFCFC',
            ghostWhite: '#F3F3F3',
            charcolGray: '#373737',
            coolGray: '#858585',
            lightGray: '#E2E2E2',
            silverGray: '#B4B4B4',
            whisperGray: '#F2F2F2',
            green: '#F0F8E6',
            mintGreen: '#00B386',
            coralRed: '#EB5B3C',
            red: '#FF0000',
            skyBlue: '#E0EDF4',
            pink: '#FEEEF5',
            mist: '#E9E5F3',
            green: '#00B386',
            red: '#FF0000',
            lightBlue: '#448FFF0D'
        },
    },
});


export function H1({ title }) {
    return (
        <Typography
            variant="h1"
            sx={{
                fontSize: {
                    xs: '1.5rem',
                    sm: '2rem',
                    md: '2.5rem',
                    lg: '3rem',
                },
                // textAlign: 'center',
            }}
        >
            {title}
        </Typography>
    );
}

export function H2({ title, color, font }) {
    return (
        <Typography
            variant="h2"
            fontWeight={font}
            color={color}
            sx={{
                fontSize: {
                    xs: '20px',
                    sm: '22px',
                    md: '24px',
                    lg: '32px',
                },
            }}
        >
            {title}
        </Typography>
    );
}

export function H3({ title, color, font }) {
    return (
        <Typography
            variant="h3"
            fontWeight={font}
            color={color}
            sx={{
                fontSize: {
                    xs: '16px',
                    sm: '18px',
                    md: '20px',
                    lg: '21px',
                },
                // textAlign: 'center',
            }}
        >
            {title}
        </Typography>
    );
}

export function H4({ title, color, font }) {
    return (
        <Typography
            variant="h4"
            fontWeight={font}
            color={color}
            sx={{
                fontSize: {
                    xs: '16px',
                    sm: '14px',
                    md: '16px',
                    lg: '18px',
                },
                // textAlign: 'center',
            }}
        >
            {title}
        </Typography>
    );
}

export function H5({ title, color, font }) {
    return (
        <Typography
            variant="h5"
            fontWeight={font}
            color={color}
            sx={{
                fontSize: {
                    xs: '14px',
                    sm: '14px',
                    md: '14px',
                    lg: '16px',
                },
                // textAlign: 'center',
            }}
        >
            {title}
        </Typography>
    );
}

export function H6({ title, color, font }) {
    return (
        <Typography
            variant="h6"
            color={color}
            fontWeight={font}
            sx={{
                fontSize: {
                    xs: '12px',
                    sm: '12px',
                    md: '13px',
                    lg: '14px',
                },
                // textAlign: 'center',
            }}
        >
            {title}
        </Typography>
    );
}

export function P({ title, color, font, align }) {

    return (
        <Typography
            variant="p"
            color={color}
            textAlign={align}
            font={font}
            sx={{
                fontSize: {
                    xs: '11px',
                    sm: '11px',
                    md: '11px',
                    lg: '12px',
                },
                lineHeight: 1.5
                // textAlign: 'center',
            }}
        >
            {title}
        </Typography>
    );
}

export function Links({ href, children }) {
    return (
        <Link
            href={href}
            sx={{
                textDecoration: 'none',
                color: 'primary.main',
                '&:hover': {
                    textDecoration: 'underline',
                },
            }}
        >
            {children}
        </Link>
    );
}