import * as React from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import HomeLayout from "../PublicComponents/Homelayout";
import Homepage from "../Public/Homepage";
import NotFound from "../PublicComponents/NotFound";
import Strikes from "../PublicComponents/Strikes"
import Exchanges from "../Public/Exchanges"
import Indexes from "../Public/Indexes";
import Landing from "../Public/Landing";
import Options from "../PublicComponents/Options";
import Auth from "../Public/Options/Auth";
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { asyngetStatus, showToast } from "../store/Actions/Action";
// import SocketProvider from "../PublicComponents/socketContext/Index";
import Orders from "../PublicComponents/Orders";
import Payment from "../Public/Payment";
import Success from "../Public/Success";
import Failure from "../Public/Failure";
import HowTo from "../Public/Options/HowTo";
import SubscriptionExpired from "../Public/SubscriptionExpired";
import News from "../Public/News";
import Root from "../Public/Options/Root";
import NewsDetails from "../Public/NewsDetails";
import LearnWithUs from "../Public/LearnWithUs";
import LearnHome from "../Public/LearnHome";
import Home from "../Public/Home";
import Settings from "../Private/Admin/Settings";
import Indicators from "../Private/Admin/Indicators";
import Portfolio from "../Public/Equity/Portfolio";
import EquityNavbar from "../Public/Equity/EquityNavbar";
import EquityHome from "../Public/Equity/EquityHome";
import Watchlists from "../Public/Equity/Watchlists";
import Algorihtms from '../Public/Equity/Algorithms'
import WatchlistDetails from "../Public/Equity/WatchlistDetails";
import AlgoDetails from "../Public/Equity/AlgoDetails";
import CompanyDetails from "../Public/Equity/CompanyDetails";
import OrdersEquity from "../Public/Equity/OrdersEquity";
import NewScreen from "../NewScreen";
import PublicLayout from "../EquityComponents/PublicLayout";
import UserAuth from "../EquityComponents/UserAuth";
import AlgoCompanyDetails from "../Public/Equity/AlgoCompanyDetaiils";
import AuthBroker from "../Public/Options/AuthBroker";
import Alerts from "../Public/Equity/Alerts";
import PortfolioReport from "../Public/Equity/PortfolioReport";
import DetailReport from "../Public/Equity/DetailReport";
import MyAlgoReport from "../Public/Equity/MyAlgoReport";
import { MarketStoreMstProvider } from "../store/mobx/MarketStoreMST";
import { MarketStateProvider } from "../Contexts/MarketContext/MarketContext";
import { observer } from "mobx-react-lite";
import { WebSocketProvider } from "../Contexts/WebsocketContext/WebSocketContext";
import CompanyReport from "../Public/Equity/CompanyReport";
import AlgoReport from "../Public/Equity/AlgoReport";
const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Observer = observer(() => {
	return (
		<WebSocketProvider>
			{/* <ToolsProvider> */}
			<Outlet />
			{/* </ToolsProvider> */}
		</WebSocketProvider>
	);
});

const MarketMSTComponents = () => {
	return (
		<MarketStoreMstProvider>
			<MarketStateProvider>
				<Observer />
			</MarketStateProvider>
		</MarketStoreMstProvider>
	);
};


const PublicRoutes = () => {

	const location = useLocation()


	const disptch = useDispatch();
	const { snackbar } = useSelector((state) => state.admin);

	const [open, setOpen] = React.useState(false);


	const handleClose = (event, reason) => {
		disptch(showToast({ open: false, message: '', duration: 5000, type: '' }))
	};

	React.useEffect(() => {
		// HERE WE ARE MANAGING THE SCROLL TO TOP FEATURE
		if (!location.hash)
			window?.scrollTo(0, 0)
	}, [location])


	return (
		<>

			{/* <Route path="news" element={<News />} /> */}
			{/* BELOW ROUTES NOT FOR PUBLIC AS FOR NOW - HIDE THEM BEFORE PUSOHING TO PRODUCTION */}
			{/* <Route path="settings" element={<Settings />} /> */}
			{/* <Route path="indicators" element={<Indicators />} /> */}
			{/* SocketProvider */}

			{/* <SocketProvider> */}
			<Routes>
				<Route path="/" element={<Root />}>
					<Route path="/" element={<Landing />} />
					<Route path="/learn-with-us/:courseId" element={<LearnWithUs />} />
					<Route path="/news" element={<News />} />
					<Route path="/news/:id/:name" element={<NewsDetails />} />
					<Route path="/how-to-add-broker" element={<HowTo />} />
				</Route>
				<Route path="/add-broker" element={<HowTo />} />
				{/* <Route path="/auth-broker" element={<AuthBroker />} /> */}
				<Route path="/no-subscription" element={<SubscriptionExpired />} />
				<Route path="/success" element={<Success />} />
				<Route path="/failed" element={<Failure />} />
				<Route path="home" element={<Home />}>
					<Route path="news" element={<News />} />
					<Route path="news/:id/:name" element={<NewsDetails />} />
					<Route path="earn-with-us" element={<Payment />} />
					<Route path="plans/:title/:id/:cycle" element={<Payment />} />
					<Route path="learn-with-us" element={<LearnHome />} />
					<Route path="learn-with-us/:courseId" element={<LearnWithUs />} />
				</Route>

				{/* EQUITY ROUTES  */}
				<Route path="/" element={<UserAuth />}>
					<Route path="auth-broker" element={<AuthBroker />} />
					<Route path="equity" element={<PublicLayout />}>
						<Route path="alerts" element={<Alerts />} />
						<Route path="company">
							<Route path=":id" element={<CompanyDetails />} />
							<Route path="report" element={<CompanyReport />} />
						</Route>
						<Route index element={<EquityHome />} />
						<Route path="algorithm/" >
							<Route index element={<Algorihtms />} />
							<Route path=":id" element={<AlgoDetails />} />
							<Route path="report" element={<AlgoReport />} />
							<Route path="company/:id" element={<AlgoCompanyDetails />} />
						</Route>
						<Route path="watchlist" >
							<Route index element={<Watchlists />} />
							<Route path=":id" element={<WatchlistDetails />} />
						</Route>
						<Route path="portfolio">
							<Route index element={<Portfolio />} />
							<Route path="report" element={<PortfolioReport />} />
							<Route path="detailed-report" element={<DetailReport />} />
							<Route path="my-algo-report" element={<MyAlgoReport />} />
						</Route>
						<Route path="algo-report">
							<Route path=":id" element={<AlgoReport />} />
						</Route>
						<Route path="orders" element={<OrdersEquity />} />
					</Route>
					<Route path="" element={<MarketMSTComponents />}>
						<Route path="index-options" element={<HomeLayout />} >
							<Route path="strikes" element={<Strikes />} />
							<Route path="orders" element={<Orders />} />
							<Route path="exchanges" element={<Exchanges />} />
							<Route path="indexes" element={<Indexes />} />
							<Route path="news" element={<News />} />
							{/* BELOW ROUTES NOT FOR PUBLIC AS FOR NOW - HIDE THEM BEFORE PUSOHING TO PRODUCTION */}
							<Route path="settings" element={<Settings />} />
							<Route path="indicators" element={<Indicators />} />
						</Route>
						<Route path="/options" element={<Options />} />
					</Route>
				</Route>


				{/* <Route path="/home" element={<Landing />} /> */}
				{/* <Route path="*" element={<NotFound />} /> */}
			</Routes>
			{/* </SocketProvider> */}
			{/* SocketProvider */}
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={snackbar.open}
					autoHideDuration={snackbar.duration}
					onClose={handleClose}
				>
					<Alert
						variant="filled"
						onClose={handleClose}
						severity={snackbar.type}
						sx={{ width: "100%" }}
					>
						{snackbar.message}
					</Alert>
				</Snackbar>
			</Stack>
		</>
	)
}

export default PublicRoutes