import React from "react";
import GraphHeader from "./GraphHeader";
import IndexTableHeadCell from "./IndexTableHeadCell";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCprPcrtData,
  updateEmaData,
  updateEmaVolumeData,
  updateRsiData,
} from "../store/Reducer/ToolsReducer";

const HeadCell = ({ label, color, value, ref }) => (
  <div className="w-full border-b border-b-[#eae8e8] flex flex-col items-center justify-center gap-2 py-2">
    <div
      className={`w-10/12 border px-2 ${label === "spot" ? "border-[#B23AEB]" : "border-[#eae8e8]"
        } rounded flex items-center justify-between`}
    >
      <h6
        style={{ color: color }}
        className={`p-1 border-r ${label === "spot" ? "border-r-[#B23AEB]" : "border-r-[#eae8e8]"
          }   text-xs uppercase`}
      >
        {label}
      </h6>
      <small className="text-[#8F8F8F] text-xs">{value?.toFixed(2)}</small>
    </div>
  </div>
);

const skeletons = Array.from({ length: 6 }).map((_, index) => (
  <div key={index} className="w-full flex flex-col items-center gap-2">
    <Skeleton
      width={100}
      animation="wave"
      variant="text"
      sx={{ fontSize: "1rem" }}
    />
  </div>
));

const skeletons1 = (
  <div className="w-full flex flex-col items-center gap-2">
    <Skeleton animation="wave" variant="rectangular" width={160} height={80} />
    <Skeleton animation="wave" variant="rectangular" width={160} height={20} />
    <Skeleton animation="wave" variant="rectangular" width={160} height={50} />
  </div>
);

const rangeData = [
  {
    min: 81,
    max: 100,
  },
  {
    min: 61,
    max: 80,
  },
  {
    min: 41,
    max: 60,
  },
  {
    min: 31,
    max: 40,
  },
  {
    min: 0,
    max: 30,
  },
];

const IndexTable = (props) => {
  const dispatch = useDispatch();
  const { emaData, rsiData, emaVolumeData, cprpcrData, currentToolsIndex } = useSelector(
    (state) => state.tools
  );
  const [currentIndexData, setCurrentIndexData] = React.useState(null)

  // console.log(rsiData)

  // console.log(cprpcrData);


  let divH =
    (cprpcrData?.data?.prv2cpr > cprpcrData?.data?.prv2pcr
      ? cprpcrData?.data?.prv2cpr
      : cprpcrData?.data?.prv2pcr) * 25;
  let div1H =
    (cprpcrData?.data?.prvcpr > cprpcrData?.data?.prvpcr
      ? cprpcrData?.data?.prvcpr
      : cprpcrData?.data?.prvpcr) * 25;
  let div2H =
    (cprpcrData?.data?.crtcpr > cprpcrData?.data?.crtpcr
      ? cprpcrData?.data?.crtcpr
      : cprpcrData?.data?.crtpcr) * 25;

  // FUNCTION TO SCROLL TABLE ON PAGE REFRESH
  const focusedRowRef = React.useRef(null);
  const tc = React.useRef(null);

  React.useEffect(() => {
    if (focusedRowRef?.current) {
      const topOffset = focusedRowRef?.current?.offsetTop;
      tc?.current?.scrollTo({ top: topOffset - 200, behavior: "smooth" });
    }
  }, [focusedRowRef?.current]);

  const updateEmaDataFunction = (field, value) => {
    dispatch(updateEmaData({ field, value }));
  };

  const updateRsiDataFunction = (field, value) => {
    dispatch(updateRsiData({ field, value }));
  };

  const updateEmaVolumeDataFunction = (field, value) => {
    dispatch(updateEmaVolumeData({ field, value }));
  };

  const updatePcrCprDataFunction = (field, value) => {
    dispatch(updateCprPcrtData({ field, value }));
  };

  let _interval = emaData.interval
  let _currentIndex = currentToolsIndex
  let _emaEventName = `EMA${_currentIndex.toUpperCase()}minute${_interval}`


  React.useEffect(() => {
    setCurrentIndexData(emaData?.data[_emaEventName])
  }, [emaData, currentToolsIndex]);


  return (
    <>
      <div className="w-full overflow-hidden flex h-full flex-1">
        {/* EMA  */}
        <div className="flex-1 flex-col flex border border-r-2">
          <div className="w-full px-2 flex items-center justify-center">
            <IndexTableHeadCell
              title="ema"
              interval={emaData?.interval}
              //   setIntervalFunction={props?.setEmaInterval}
              setIntervalFunction={(value) => {
                if (emaData?.interval != value) props?.setEmaChange(true);
                else props?.setEmaChange(false);
                updateEmaDataFunction("interval", value);
              }}
            />
          </div>
          <div ref={tc} className="w-full overflow-y-auto">
            {currentIndexData?.ema?.length > 0 ? (
              currentIndexData?.ema.map((el, idx) => {
                return (
                  <div
                    key={idx}
                    ref={el?.label == "EMA20" ? focusedRowRef : null}
                    className="w-full"
                  >
                    <HeadCell
                      label={el?.label}
                      color={el?.color}
                      value={el?.value}
                    />
                  </div>
                );
              })
            ) : (
              <div className="w-full flex flex-col justify-center items-center py-2">
                {skeletons}
              </div>
            )}
          </div>
        </div>
        {/* RSI  */}
        <div className="flex-1 flex-col flex border border-r-2">
          <div className="w-full flex items-center justify-center">
            <div className="flex-1 flex items-center justify-center">
              <IndexTableHeadCell
                title="rsi"
                interval={rsiData?.interval}
                // setIntervalFunction={props?.setRsiInterval}
                setIntervalFunction={(value) => {
                  updateRsiDataFunction("interval", value);
                }}
              />
            </div>
          </div>
          {rsiData?.data && !props?.loadingData.rsi ? (
            <div className="w-full overflow-y-auto">
              {rangeData?.map((range, index) =>
                rsiData?.data >= range?.min && rsiData?.data <= range?.max ? (
                  <div
                    key={index}
                    className="w-full border-b border-b-[#eae8e8] flex flex-col items-center justify-center gap-2 py-2"
                  >
                    <h6 className="text-[#666262b2] p-1 text-xs">
                      <span className="text-[#B23AEB] text-md font-bold">
                        {rsiData?.data}{" "}
                      </span>
                      ({range?.min} - {range?.max})
                    </h6>
                  </div>
                ) : (
                  <div
                    key={index}
                    className="w-full border-b border-b-[#eae8e8] flex flex-col items-center justify-center gap-2 py-2"
                  >
                    <h6 className="text-[#666262b2] p-1 text-xs">
                      {range?.min} - {range?.max}
                    </h6>
                  </div>
                )
              )}
            </div>
          ) : (
            <div className="w-full flex flex-col justify-center items-center py-2">
              {[1, 2, 3, 4, 5].map(el => (
                <div key={el} className="w-full border-b border-b-[#eae8e8] flex flex-col items-center justify-center gap-2 py-2">
                  <Skeleton
                    width={100}
                    animation="wave"
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        {/* PCR / CPR  */}
        <div className="flex-1 flex-col flex border border-r-2">
          <div className="w-full flex items-center justify-center ">
            <IndexTableHeadCell
              title="Pcr/Cpr"
              interval={cprpcrData?.interval}
              // setIntervalFunction={props.setCPRInterval}
              setIntervalFunction={(value) => {
                updatePcrCprDataFunction("interval", value);
              }}
            />
          </div>
          {cprpcrData?.data ? (
            <div className="w-[full] flex-1 flex items-center flex-col gap-1 overflow-y-auto py-1">
              <div
                style={{ height: `${divH}%` }}
                className={`w-[80%] border flex justify-center ${cprpcrData?.data?.prv2cpr > cprpcrData?.data?.prv2pcr
                  ? "border-[#00B386] bg-[#00B3861A]"
                  : "border-[#FF2222] bg-[#FF22221A]"
                  } pt-2`}
              >
                {cprpcrData?.data?.prv2cpr > cprpcrData?.data?.prv2pcr ? (
                  <h6 className="text-[#00B386] p-1 border-r border-r-[#eae8e8] text-xs">
                    {cprpcrData?.data?.prv2cpr?.toLocaleString(undefined, {
                      minimumFractionDigits: 3,
                    })}
                  </h6>
                ) : (
                  <h6 className="text-[#FF2222] p-1 border-r border-r-[#eae8e8] text-xs">
                    {cprpcrData?.data?.prv2pcr?.toLocaleString(undefined, {
                      minimumFractionDigits: 3,
                    })}
                  </h6>
                )}
              </div>
              <div
                style={{ height: `${div1H}%` }}
                className={`w-[80%] border flex justify-center ${cprpcrData?.data?.prvcpr > cprpcrData?.data?.prvpcr
                  ? "border-[#00B386] bg-[#00B3861A]"
                  : "border-[#FF2222] bg-[#FF22221A]"
                  } items-center`}
              >
                {cprpcrData?.data?.prvcpr > cprpcrData?.data?.prvpcr ? (
                  <h6 className="text-[#00B386] p-1 border-r border-r-[#eae8e8] text-xs">
                    {cprpcrData?.data?.prvcpr?.toLocaleString(undefined, {
                      minimumFractionDigits: 3,
                    })}
                  </h6>
                ) : (
                  <h6 className="text-[#FF2222] p-1 border-r border-r-[#eae8e8] text-xs">
                    {cprpcrData?.data?.prvpcr?.toLocaleString(undefined, {
                      minimumFractionDigits: 3,
                    })}
                  </h6>
                )}
              </div>
              <div
                style={{ height: `${div2H}%` }}
                className={`w-[80%] border flex justify-center ${cprpcrData?.data?.crtcpr > cprpcrData?.data?.crtpcr
                  ? "border-[#00B386] bg-[#00B3861A]"
                  : "border-[#FF2222] bg-[#FF22221A]"
                  } items-center`}
              >
                {cprpcrData?.data?.crtcpr > cprpcrData?.data?.crtpcr ? (
                  <h6 className="text-[#00B386] p-1 border-r border-r-[#eae8e8] text-xs">
                    {cprpcrData?.data?.crtcpr?.toLocaleString(undefined, {
                      minimumFractionDigits: 3,
                    })}
                  </h6>
                ) : (
                  <h6 className="text-[#FF2222] p-1 border-r border-r-[#eae8e8] text-xs">
                    {cprpcrData?.data?.crtpcr?.toLocaleString(undefined, {
                      minimumFractionDigits: 3,
                    })}
                  </h6>
                )}
              </div>
            </div>
          ) : (
            <div className="w-full flex flex-col justify-center items-center py-2">
              {skeletons1}
            </div>
          )}
        </div>
        {/* VOLUME  */}
        <div className="flex-1 flex-col flex border border-r-2">
          <div className="w-full">
            <IndexTableHeadCell
              title="Vol EMA"
              interval={emaVolumeData?.interval}
              // setIntervalFunction={props.setEmaVolumeInterval}
              setIntervalFunction={(value) => {
                updateEmaVolumeDataFunction("interval", value);
              }}
            />
          </div>
          <div className="w-full overflow-y-auto" ref={tc}>
            {emaVolumeData?.data?.length > 0 && !props?.loadingData?.volEMA ? (
              emaVolumeData?.data.map((el, idx) => {
                return (
                  <div
                    key={idx}
                    ref={el?.label == "EMA20" ? focusedRowRef : null}
                    className="w-full"
                  >
                    <HeadCell
                      label={el?.label}
                      color={el?.color}
                      value={el?.value}
                    />
                  </div>
                );
              })
            ) : (
              <div className="w-full flex flex-col justify-center items-center py-2">
                {[1, 2, 3, 4, 5].map(e => (
                  <div key={e} className="w-full" >
                    <div className="w-full border-b border-b-[#eae8e8] flex flex-col items-center justify-center gap-2 py-2">
                      <Skeleton
                        width={100}
                        animation="wave"
                        variant="text"
                        sx={{ fontSize: "1rem" }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexTable;
