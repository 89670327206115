import React from 'react'
import IhmGraph from '../../PrivateComponents/IhmGraph'
import IhmHeader from '../../PrivateComponents/IhmHeader'


const IhmScreen = () => {
    return (
        <>
            <div className='bg-[#fff] flex-1 h-full w-full flex flex-col px-2'>
                <IhmHeader />
                <div className='w-full mt-20 flex-1'>
                    <IhmGraph />
                </div>
            </div>
        </>
    )
}

export default IhmScreen      

