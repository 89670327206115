import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Volume, OICell, StrikeCell, CallCell, PutOICell, PutVolume, PutCell, OrderCell, PutOrderCell, MobileCell, MobilePutCell } from './OptionTableDataComponent';
import BottomSheet from './BottomSheet';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const twoDotDigit = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

const columns = [
    // { id: 'name', label: '', minWidth: 170, align: 'center', },
    { id: 'Call', label: 'Call', minWidth: 110, align: 'left', },
    { id: 'Strike', label: 'Strike', minWidth: 130, align: 'center', },
    {
        id: 'Put',
        label: 'Put',
        minWidth: 110,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

export default function ColumnGroupingTable({ data, onFormChange, TableHeight }) {
    const max = Math.floor(data?.strikePrice / data?.strikeMargin) * data?.strikeMargin
    const min = Math.ceil(data?.strikePrice / data?.strikeMargin) * data?.strikeMargin
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    // console.log(data.options)
    return (
        <>
            {/* <Paper sx={{ width: '100%',height: '100%', }}> */}
            <TableContainer component={Paper} sx={{ width: '100%', height: '100%', position: 'relative' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{ padding: "initial", padding: "2vmax 0", }}>
                        <TableRow>
                            {columns?.map((column) => (
                                <TableCell sx={{ backgroundColor: '#F3F3F3', padding: '1vmax 2vmax !important' }}
                                    key={column.id}
                                    align={column.align}
                                    style={{ top: 0, minWidth: column.minWidth, padding: ".2vmax 0" }}
                                >
                                    <h6 className='font-[Open Sans] !text-[#505050] text-[1.7vmax] font-semibold tracking-wider'>{column.label}</h6>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {data?.options && data?.options['CE'] ? Object.keys(data?.options['CE'])?.map((callKey, rowIndex) => {
                            const putKey = Object.keys(data.options['PE'])[rowIndex]
                            const put = data.options['PE'][putKey]
                            const call = data.options['CE'][callKey]
                            return (
                                <TableRow key={rowIndex}>
                                    <TableCell align={'center'} className={data?.strikePrice >= call?.strikePrice ? `bg-blue-100` : ''}>
                                        <MobileCell
                                            onBuy={() => onFormChange('buy', 'call', call?.strikePrice, call, data['tradingsymbol'])}
                                            onSell={() => onFormChange('sell', 'call', call?.strikePrice, call, data['tradingsymbol'])}
                                            row={{ call }}
                                            volume={(call?.volume / 100000)?.toLocaleString(undefined, twoDotDigit) || 0}
                                            percent={(((call?.volume / data.callVolume) * 100) || 0)?.toLocaleString(undefined, twoDotDigit) + '%'}
                                        />
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <StrikeCell
                                            min={min}
                                            max={max}
                                            strike={data?.strikePrice}
                                            strikePrice={data?.strikePrice?.toLocaleString()}
                                            pcr={put?.openInterest / call?.openInterest}
                                            cpr={call?.openInterest / put?.openInterest}
                                            onStrike={() => onFormChange('strike')}
                                        />
                                    </TableCell>
                                    <TableCell align={'center'} className={data?.strikePrice < put?.strikePrice ? `bg-blue-100` : ''}>
                                        <MobilePutCell
                                            onBuy={() => onFormChange('buy', 'put', put?.strikePrice, put, data['tradingsymbol'])}
                                            onSell={() => onFormChange('sell', 'put', put?.strikePrice, put, data['tradingsymbol'])}
                                            row={{ put }}
                                            volume={(put?.volume / 100000)?.toLocaleString(undefined, twoDotDigit) || 0}
                                            percent={(((put?.volume / data.putVolume) * 100) || 0)?.toLocaleString(undefined, twoDotDigit) + '%'}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        }) : null}
                    </TableBody>
                </Table>
                {!data?.option ?
                    <Box className="w-full h-full px-10 flex flex-col justify-center items-center">
                        <p className='font-mono text-[12px] mb-2'>Loading please wait...</p>
                        <LinearProgress sx={{ width: '100%' }} />
                    </Box> : null
                }
            </TableContainer>
        </>
    );
}