import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, LinearProgress, TextField } from "@mui/material";
import dayjs from "dayjs";
import { TableVirtuoso } from "react-virtuoso";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  Checkbox,
  CircularProgress,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const indexArr = [
  {
    index: "NIFTY",
    tradingsymbol: "NSE:NIFTY 50",
    strikeMargin: 50,
    type: "NIFTY_OI",
    qty: 50,
    option: "NFO",
    instrument: "256265",
  },
  {
    index: "BANKNIFTY",
    tradingsymbol: "NSE:NIFTY BANK",
    strikeMargin: 100,
    type: "BANKNIFTY_OI",
    qty: 15,
    option: "NFO",
    instrument: "260105",
  },
  {
    index: "FINNIFTY",
    tradingsymbol: "NSE:NIFTY FIN SERVICE",
    strikeMargin: 50,
    type: "FINNIFTY_OI",
    qty: 40,
    option: "NFO",
    instrument: "257801",
  },
  {
    index: "MIDCPNIFTY",
    tradingsymbol: "NSE:NIFTY MID SELECT",
    strikeMargin: 25,
    type: "MIDCPNIFTY_OI",
    qty: 75,
    option: "NFO",
    instrument: "288009",
  },
  {
    index: "SENSEX",
    tradingsymbol: "BSE:SENSEX",
    strikeMargin: 100,
    type: "SENSEX_OI",
    qty: 10,
    option: "BFO",
    instrument: "265",
  },
  {
    index: "BANKEX",
    tradingsymbol: "BSE:BANKEX",
    strikeMargin: 100,
    type: "BANKEX_OI",
    qty: 15,
    option: "BFO",
    instrument: "274441",
  },
];  

// SELECT INDEX
// SELECT EXPIRY
// SELECT FROM
// FETCH HISTORIC - 1 MIN INTER
// WHILE API BEING CALLED CONNECT TICKER OR FETCH REAL TIME DATA FROM REDUX
// NOW WITH BOTH DATA CALCULATE TOTAL OF THE VWAP

// JUST NOW I GOT TO KNOW INDEXES DO NOT HAVE VOLUMES IT SHOULD BE CALCULATED USING STOCKS DEFINING THOSE DERIVATIVES
// FETCH ALL THE VOLUME OF DERIVATES AND TOTAL AND ADD WITH THE CURRENT INDEX OF EACH MINUTE

/**
 * STEPS TO IMPLMENT
 * 1. CREATE AN API TO GET THE HISTORIC DATA FOR SELECTED DURATION
 * 2. WHILE FETCHING HISTORIC DATA - YOU WILL NOT RECEIVE THE VOLUME AGAINST THE INDEX
 */

// ONCE ALL DONE - SET THE REAL TIME DATA AS HISTORIC IN TABLE

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F0F8FF",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "#262626",
  },
  [`&.${tableCellClasses.root}`]: {
    border: "1px solid rgba(158, 158, 158, 0.12)",
    padding: "10px 16px",
  },
}));

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
}; 

VirtuosoTableComponents.Scroller.displayName = "ref1";
VirtuosoTableComponents.TableBody.displayName = "ref2";

function vHeaderContent(vwap, rsi, ema, sma, perChange, bb, showOI) {
  return (
    <>
      <StyledTableCell align="right">
        <p className="text-sm text-[#373737] font-normal">DATE & TIME</p>
      </StyledTableCell>
      {/* <StyledTableCell align="right">
                <p className='text-sm text-[#373737] font-normal'>OPEN</p>
            </StyledTableCell>
            <StyledTableCell align="right">
                <p className='text-sm text-[#373737] font-normal'>HIGH</p>
            </StyledTableCell>
            <StyledTableCell align="right">
                <p className='text-sm text-[#373737] font-normal'>LOW</p>
            </StyledTableCell> */}
      <StyledTableCell align="right">
        <p className="text-sm text-[#373737] font-normal">STRIKE PRICE</p>
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-sm text-[#373737] font-normal">VOLUME</p>
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-sm text-[#373737] font-normal">Anchored VWAP</p>
      </StyledTableCell>
    </>
  );
} 

function vRowContent(index, row, vwap, rsi, ema, sma, perChange, bb, showOI) {
  return (
    <>
      <StyledTableCell align="right">
        <p className="text-xs text-[#424242]">
          {dayjs(row?.date).format(`DD-MM-YY HH:mm`)}
        </p>
      </StyledTableCell>
      {/* <StyledTableCell align="right">
                <p className='text-xs text-[#262626]'>{row?.open?.toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
            </StyledTableCell>
            <StyledTableCell align="right">
                <p className='text-xs text-[#262626]'>{row?.high?.toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
            </StyledTableCell>
            <StyledTableCell align="right">
                <p className='text-xs text-[#262626]'>{row?.low?.toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
            </StyledTableCell> */}
      <StyledTableCell align="right">
        <p className="text-xs text-[#262626]">
          {row?.close?.toLocaleString("en-US", { minimumFractionDigits: 2 })}
        </p>
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-xs text-[#262626]">
          {row?.volume?.toLocaleString("en-US", { minimumFractionDigits: 0 })}
        </p>
      </StyledTableCell>
      <StyledTableCell align="right">
        <p className="text-xs text-[#262626]">
          {row?.vwap?.toLocaleString("en-US", { minimumFractionDigits: 0 })}
        </p>
      </StyledTableCell>
      {/* {showOI ? <StyledTableCell align="right">
                <p className='text-xs text-[#262626]'>{row?.oi?.toLocaleString('en-US', { minimumFractionDigits: 0 })}</p>
            </StyledTableCell> : null}
            {vwap ? <StyledTableCell align="right">
                <p className='text-xs text-[#262626]'>{row?.vwap?.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
            </StyledTableCell> : null}
            {rsi ? <StyledTableCell align="right">
                <p className='text-xs text-[#262626]'>{row?.rsi?.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
            </StyledTableCell> : null}
            {perChange ? <StyledTableCell align="right" className={row?.perChange >= 2 && row?.perChange < 4 ? 'bg-green-100' : row?.perChange >= 4 ? 'bg-blue-100' : ''}>
                <p className='text-xs text-[#262626]'>{row?.perChange?.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
            </StyledTableCell> : null}
            {ema?.length ? ema?.map((_ema, index) => (
                <StyledTableCell key={index} align="right">
                    <p className='text-xs text-[#262626]'>{row['ema_' + _ema]?.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                </StyledTableCell>
            )) : null}
            {sma?.length ? sma?.map((_sma, index) => (
                <StyledTableCell key={index} align="right">
                    <p className='text-xs text-[#262626]'>{row['sma_' + _sma]?.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                </StyledTableCell>
            )) : null}
            {bb?.length ? bb?.map((_bb, index) => (
                <StyledTableCell key={index} align="right" width={175} className='!py-0 !px-2'>
                    <div className='flex-1 flex justify-between items-center'>
                        <p className='text-xs text-[#262626]'>{row['bb_' + _bb]?.lower?.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                        <p className='text-xs text-[#262626]'>{row['bb_' + _bb]?.upper?.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                    </div>
                </StyledTableCell>
            )) : null} */}
    </>
  );
}

export default function VWAPAggregate(
  {
    expiry,
    loading,
    from,
    datasource,
    interval,
    onFormChange,
    onFormSubmit,
    height,
    vwapStartDate,
    currentVwapIndex,
  },
  props
) { 
  const appendInterval = React.useRef(null);
  const virtuosoRef = React.useRef(null);
  const [atBottom, setAtBottom] = React.useState(false);
  const showButtonTimeoutRef = React.useRef(null);
  const [showButton, setShowButton] = React.useState(false);

  React.useEffect(() => {
    return () => {
      clearInterval(appendInterval.current);
      clearTimeout(showButtonTimeoutRef.current);
    };
  }, []);

  // React.useEffect(() => {
  //     clearTimeout(showButtonTimeoutRef.current)
  //     if (!atBottom) {
  //         showButtonTimeoutRef.current = setTimeout(() => setShowButton(true), 500)
  //     } else {
  //         setShowButton(false)
  //     }
  // }, [atBottom, setShowButton])

  React.useEffect(() => {
    if (datasource?.length) {
      virtuosoRef.current.scrollToIndex({
        index: datasource.length - 1,
        behavior: "smooth",
      });
    }
  }, [datasource]);

  return (
    <>
      <div className="flex flex-col justify-between gap-4 py-4 flex-1">
        <div className="flex flex-wrap items-end flex-1 gap-3">
          <FormControl className="min-w-[200px]">
            <FormLabel id="interval">Select Index</FormLabel>
            <Select
              labelId="interval-label"
              id="interval-select"
              label="Interval"
              size="small"
              value={currentVwapIndex}
              onChange={(e) => onFormChange(e?.target?.value, "index")}
            >
              {indexArr?.map((_index, index) => (
                <MenuItem key={index} value={_index}>
                  {_index?.index}
                </MenuItem>
              ))}
            </Select> 
          </FormControl>  
          {/* <FormControl className='flex-1'>
                        <FormLabel id="interval">Select Expiry</FormLabel>
                        <Select
                            labelId="interval-label"
                            id="interval-select"
                            label="Interval"
                            size='small'
                            value={interval}
                            onChange={(e) => onFormChange(e?.target?.value, 'expiry')}
                        >
                            {expiry?.map((_expiry, index) => (
                                <MenuItem key={index} value={_expiry}>{_expiry}</MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
          <FormControl className="min-w-[200px]">
            <FormLabel id="market-type">Start Date Time</FormLabel>
            <OutlinedInput  
              size="small"
              type="datetime-local"
              // value={from}
              value={vwapStartDate}
              onChange={(e) => onFormChange(e?.target?.value, "from")}
            />  
          </FormControl>
          <div className="flex justify-end items-center h-full">
            <Button
              sx={{ height: 40, minWidth: 140 }}
              variant="contained"
              size="small"
              // disabled={!from || !to || !symbol || !interval || loading}
              onClick={() => onFormSubmit()}
            >
              {loading ? (
                <CircularProgress size={"24px"} color="white" />
              ) : (
                "SUBMIT"
              )}
            </Button>
          </div>
        </div>
      </div>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          maxWidth: props.width,
          height: height - 150,
          position: "relative",
        }}
      >
        <TableVirtuoso
          ref={virtuosoRef}
          data={datasource}
          components={VirtuosoTableComponents}
          fixedHeaderContent={() =>
            vHeaderContent(
              props.vwap,
              props.rsi,
              props.ema,
              props.sma,
              props.perChange,
              props.bb,
              props.showoi
            )
          }
          itemContent={(index, row) =>
            vRowContent(
              index,
              row,
              props.vwap,
              props.rsi,
              props.ema,
              props.sma,
              props.perChange,
              props.bb,
              props.showoi
            )
          }
          totalCount={200}
        />
        {datasource?.length === 0 ? (
          <div className="absolute top-10 bottom-0 right-0 left-0 flex justify-center items-center">
            <h1 className="text-sm">NO DATA!</h1>
          </div>
        ) : null}
        {loading ? (
          <div className="absolute top-10 bottom-0 right-0 left-0 flex flex-col justify-center items-center">
            <p>Loading please wait... {props.symbol}</p>
            <LinearProgress sx={{ width: "85%" }} />
          </div>
        ) : null}
      </TableContainer> 
    </>
  );
}
