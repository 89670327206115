import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress, IconButton, Skeleton } from "@mui/material";
// import { post } from '../Services/api.service';
import Typography from "@mui/material/Typography";
import AppLogo from "../assets/logo 2 (2) 1.png";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import { useMediaQuery } from "@mui/material";
import { CrossIcon, RightIcon } from "../PublicComponents/Icons";
import Radio from "@mui/material/Radio";
import { http } from "../Services/api.services";
import PlanCard from "../PublicComponents/PlanCard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import { asyngetStatus, showToast } from "../store/Actions/Action";
import { ArrowBack } from "@mui/icons-material";
import HomepageHeaders from "../PublicComponents/HomepageHeaders";
import TenureSwitch from "../PublicComponents/TenureSwitch";
import { Carousel } from "@trendyol-js/react-carousel";
import { showLogin } from "../store/Reducer/EventReducer";

const product = {
  name: "Option Basics (CTT - Certified Trained Traders)",
  price: 6999,
  href: "#",
  description:
    "There are many myths about the option trading in the market , so to earn profit properly in the stock market you have to be trained about the option trading from the basics. The course of Options Basics is designed in a way to remove all the misconceptions and to train you properly for the options.",
};
const reviews = { href: "#", average: 4, totalCount: 117 };

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Payment = () => {
  const { title, id, cycle } = useParams();
  const [planDuration, setPlanDuration] = useState(cycle < 12 ? 0 : 1);

  const [token, useToken] = useState(localStorage.getItem("token"));
  const [userId, setUserId] = useState(localStorage.getItem("user_id"));

  const { plans } = useSelector((state) => state.plans);

  const user = useSelector((state) => state.user);
  const userSubscription = useSelector((state) => state.userSubs);
  // console.log(plans, userSubscription)
  const [selectedPlan, setSelectedPlan] = useState(
    plans.find((plan) => plan.id == id)
  );
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(0);
  const [mid, setMID] = useState("");
  const [txnToken, setTxnToken] = useState("");
  const [orderId, setOrderId] = useState("");

  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = React.useRef(null);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed
  const drawerWidth = isSmallScreen ? 240 : 400; // Set the width based on the screen size

  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const _plan = plans.find((plan) => plan.id == id);
    setAmount(_plan?.["amount"][planDuration]);
    document.title = "Earn with us | Fastr";
    return () => (document.title = "Fastr");
  }, [planDuration]);

  // RADIO CODE

  const [selectedValue, setSelectedValue] = React.useState("");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const subscribe = async () => {
    try {
      // if (userSubscription?.daysleft > 0) {
      //     const _snackbar = {
      //         message: 'Already subscribed to same plan',
      //         open: true,
      //         duration: 3000,
      //         type: 'error'
      //     }
      //     dispatch(asyngetStatus(_snackbar))
      //     return
      // }
      if (!user?.name) {
        dispatch(showLogin({ type: "showlogin", payload: 1 }));
        const _snackbar = {
          message: "Subscription need login, Please Login",
          open: true,
          duration: 3000,
          type: "error",
        };
        dispatch(showToast(_snackbar));
        return;
      } else if (amount) {
        const body = {
          amount: amount,
          cycle: planDuration < 1 ? 1 : 13,
          userId: localStorage.getItem("user_id"),
          subscriptionId: id,
        };
        setLoading(true);
        const response = await http.post("/user/subscribeplan", body);
        if ((response.status = 200)) {
          console.log(response.data.data);
          setMID(response.data?.data?.mid);
          setOrderId(response.data?.data?.orderId);
          setTxnToken(response.data?.data?.token);
          setRedirect(true);
          setTimeout(() => {
            form?.current?.submit();
          }, 1000);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <div className="w-full flex flex-col">
      {/* {title && id && cycle ?
                <div className='h-14 lg:h-16 px-6 py-4'>
                    <div>
                        <img className='hover:cursor-pointer' onClick={() => navigate('/')} src={AppLogo} alt="Fastr-logo" />
                    </div>
                </div> : null
            } */}
      <div className="flex-1">
        <div className="py-6 lg:py-10">
          <HomepageHeaders heading="Earn with us!" desc="" />
        </div>
        {userSubscription ? (
          <div className="py-2 px-4 flex flex-col gap-4 justify-center items-center mb-10">
            <div className="w-[360px] border border-red-600 shadow-md px-6 py-4 bg-red-100 rounded-full h-16 flex items-center justify-center">
              {userSubscription?.daysleft < 0 ? (
                <p className="text-center text-red-600 text-lg">
                  Curent plan has<b> expired</b>
                </p>
              ) : (
                <p className="text-center text-red-600 text-lg">
                  Curent plan expires in{" "}
                  <b>{userSubscription?.daysleft} days</b>
                </p>
              )}
            </div>
            <p className="text-xs lg:text-sm font-light text-center">
              To ensure uninterrupted access to our premium features and
              content, we encourage you to <b>renew</b> your subscription at
              your earliest convenience.
            </p>
          </div>
        ) : null}
        <TenureSwitch
          checked={planDuration == 1}
          planDuration={planDuration}
          setPlanDuration={setPlanDuration}
        />
        <div className="w-full hidden lg:flex items-center justify-center gap-10 py-0 lg:py-4">
          {plans?.map((plan, index) => (
            <PlanCard
              title={plan.title}
              amount={plan?.amount[planDuration]}
              tenure={plan?.tenure[planDuration]}
              cycle={plan?.cycle[planDuration]}
              excludes={plan.excludes}
              includes={plan.includes}
              trailDuration={plan.trial}
              key={index}
              id={plan.id}
              color={plan.color}
              disabled={plan.disable}
              perCycle={plan?.amount[0]}
              currentSubscription={userSubscription}
              msg={plan?.msg}
              onButtonClick={() => subscribe()}
            />
          ))}
        </div>
        {/* note -- 4/1/24 enable payment for all users */}
        <div className="block lg:hidden">
          <Carousel
            dynamic
            responsive
            show={1}
            slide={1}
            swiping={true}
            swipeOn={1}
          >
            {plans?.map((plan, index) => (
              <div
                key={index}
                className="py-10 px-4 flex items-center justify-center"
              >
                <PlanCard
                  title={plan.title}
                  amount={plan?.amount[planDuration]}
                  tenure={plan?.tenure[planDuration]}
                  cycle={plan?.cycle[planDuration]}
                  id={plan.id}
                  excludes={plan.excludes}
                  includes={plan.includes}
                  trailDuration={plan.trial}
                  color={plan.color}
                  disabled={plan.disable}
                  perCycle={plan?.amount[0]}
                  currentSubscription={userSubscription}
                  msg={plan?.msg}
                  onButtonClick={() => subscribe()}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <br></br>
        <br></br>
        <Hiddenfrom
          formRef={form}
          className="hidden"
          mid={mid}
          orderId={orderId}
          token={txnToken}
        />
      </div>
    </div>
  );
};

const Hiddenfrom = (props) => {
  return (
    <div>
      {props?.mid && props?.orderId ? (
        <form
          ref={props.formRef}
          id="redFrom"
          method="post"
          action={
            "https://securegw.paytm.in/theia/api/v1/showPaymentPage?mid=" +
            props.mid +
            "&orderId=" +
            props.orderId
          }
          name="paytm"
        >
          <input type="hidden" name="mid" value={props.mid} />
          <input type="hidden" name="orderId" value={props.orderId} />
          <input type="hidden" name="txnToken" value={props.token} />
        </form>
      ) : null}
    </div>
  );
};

export default Payment;
