import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddCondition from '../../PrivateComponents/AddConditionDialog';
import { useSelector } from 'react-redux'
import {  http } from '../../Services/api.services';
import { useLocation } from 'react-router-dom';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // minWidth: fullWidth,
        },
    },
};

const DashedBorderButton = styled(Button)(({ disabled }) => ({
    border: `1px dashed ${disabled ? '#808080' : '#000'}`,
    color: '#1C1C1C',
    minWidth: '150px'
}));

const createEntryform = {
    name: '',
    description: '',
    index: '',
    expiry: '',
    strike: '',
    txntype: '',
    ordertype: ''
}

const AddEntryCondition = () => {
    const location = useLocation();
    const detailId = location?.pathname?.split('/')[8] || null;
    const [age, setAge] = React.useState('');
    const [openAddCondition, setOpenAddCondition] = React.useState(false)
    const [selectedIndex, setSelectedIndex] = React.useState(null)
    const market = useSelector((state) => state.market);
    const [entryForm, setEntryForm] = React.useState({ ...createEntryform })
    const { currentIndex, currentExpiry, activeSL } = useSelector((state) => state.optionHome)
    const [conditionDets, setConditionDets] = React.useState(null)

    // GET CONDITION DETAILS 
    React.useEffect(() => {
        getConditionDetails();
    }, [detailId])

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const onFormChange = (name, value) => {
        try {
            const form = { ...entryForm }
            form[name] = value
            setEntryForm(form)
        }
        catch (e) {
            console.log(`ERROR WHILE FORM CHANGE --- ${e}`)
        }
    }

    const createEntry = async () => {
        try {
            const _body = {
                name: '',
                description: '',
                index: '',
                expiry: '',
                strike: '',
                txnType: '',
                orderType: ''
            }
            const response = await http.post('entry_condition', entryForm)
            console.log(response);

        } catch (e) {
            console.log('ERROR WHILE CREATING ENTRY' + e);
        }
    }

    // GET CONDITION DETAILS 
    const getConditionDetails = async () => {
        try {
            const response = await http.get(`entry_condition/${detailId}`)
            if (response?.status == 200) {
                setConditionDets(response?.data[0])
                setEntryForm(response?.data[0])
            }
        } catch (e) {
            console.log('ERROR WHILE FETCHING CONDITION DETAILS ' + e);
        }
    }

    return (
        <>
            <div className='px-2 lg:px-6 flex-1 overflow-y-auto flex flex-col gap-3'>
                {/* BASIC INFORMATION  */}
                <div className='border border-[#37373717] bg-[#FEFEFE] '>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon color='#000000' />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h6 className='text-md font-bold'>Basic info</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='flex w-full flex-col  lg:flex-row gap-3 lg:gap-5'>
                                <div className='w-full lg:w-[24%] '>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label="Entry name"
                                        variant="outlined"
                                        value={entryForm?.name}
                                        onChange={(e) => onFormChange('name', e?.target?.value)}
                                    />
                                </div>
                                <div className='w-full lg:w-[75%] '>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label="Short description"
                                        variant="outlined"
                                        value={entryForm?.description}
                                        onChange={(e) => onFormChange('description', e?.target?.value)}
                                    />
                                </div>
                            </div>
                            <div className='w-full flex flex-wrap  gap-3 py-4 lg:gap-5'>
                                <div className='w-[49%] lg:w-[24%] '>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-simple-select-label">Index</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={entryForm?.index}
                                            MenuProps={MenuProps}
                                            label="Index"
                                            onChange={(e) => onFormChange('index', e?.target?.value)}
                                        >
                                            <MenuItem value='nifty'>Nifty </MenuItem>
                                            <MenuItem value='banknifty'>Bank Nifty</MenuItem>
                                            <MenuItem value='finnifty'>Fin Nifty</MenuItem>
                                            <MenuItem value='midcpnifty'>Mid cap Nifty</MenuItem>
                                            <MenuItem value='sensex'>Sensex</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='w-[49%] lg:w-[24%] '>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-simple-select-label">Expiry</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={entryForm?.expiry}
                                            label="Enpiry"
                                            MenuProps={MenuProps}
                                            onChange={(e) => onFormChange('expiry', e?.target?.value)}
                                        >
                                            {market[entryForm?.index]?.expiry?.map((exp, idx) => (
                                                <MenuItem key={idx} value={exp}>{exp}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='w-[49%] lg:w-[24%] '>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label="Strike"
                                        variant="outlined"
                                        type='number'
                                        value={entryForm?.strike}
                                        onChange={(e) => onFormChange('strike', e?.target?.value)}
                                    />
                                </div>
                                <div className='w-[49%] lg:w-[24%] '>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="demo-simple-select-label">PE/CE</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={entryForm?.ordertype}
                                            MenuProps={MenuProps}
                                            label="PE/CE"
                                            onChange={(e) => onFormChange('ordertype', e?.target?.value)}
                                        >
                                            <MenuItem value='pe'>PE</MenuItem>
                                            <MenuItem value='ce'>ce</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='w-[49%] lg:w-[24%] '>
                                    <FormControl
                                        fullWidth
                                        size='small'
                                    >
                                        <InputLabel id="demo-simple-select-label">Buy/Sell</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={entryForm?.txntype}
                                            label="Buy/Sell"
                                            MenuProps={MenuProps}
                                            onChange={(e) => onFormChange('txntype', e?.target?.value)}
                                        >
                                            <MenuItem value='buy'>Buy</MenuItem>
                                            <MenuItem value='sell'>Sell</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                {/* ACTIONS  */}
                <div className='border border-[#37373717] w-full flex flex-col items-center justify-center flex-1 bg-[#FEFEFE]'>
                    <div className=' w-full lg:w-9/12 flex flex-col items-center justify-center flex-1 '>
                        <div className='w-full flex flex-col justify-center items-center py-4'>
                            {/* <ConditionCard />
                            <TabComponent />
                            <ConditionCard /> */}
                        </div>

                        {/* <GroupCard /> */}

                        {/* ADD BUTTONS  */}
                        <div className='w-full flex items-center justify-center gap-3 my-4'>
                            <DashedBorderButton
                                disabled={detailId ? false : true}
                                size='small'
                                onClick={() => setOpenAddCondition(true)}
                            >
                                <AddOutlinedIcon
                                    color='#1C1C1C'
                                />
                                Condition
                            </DashedBorderButton>
                            <DashedBorderButton
                                disabled={detailId ? false : true}
                                size='small'
                            >
                                <AddOutlinedIcon
                                    color='#1C1C1C'
                                />
                                Group
                            </DashedBorderButton>
                        </div>
                    </div>

                </div>
                <div className='w-full flex justify-end py-6 '>
                    <Button
                        // disabled
                        variant='contained'
                        size="medium"
                        onClick={() => createEntry()}
                    >
                        SAVE
                    </Button>
                </div>

                <AddCondition
                    open={openAddCondition}
                    onclose={() => setOpenAddCondition(false)}
                />
            </div >

        </>
    )
}

export default AddEntryCondition
