import { Button, Checkbox, Divider } from '@mui/material'
import React from 'react'
import { H5, H6, P } from '../Theme/ThemeComponent';
import { useThemeContext } from '../Theme/ThemeContext';
import { useNavigate } from 'react-router-dom';


export default function HomeTopPerformingAlgo({ algo }) {
    const { theme } = useThemeContext();
    const navigate = useNavigate();
    const isSubscribed = false
    const WatchlistFields = ({ title, value }) => (
        <div className='flex flex-col items-center gap-1'>
            <P title={title} color={theme.palette.text.coolGray} />
            <H6 title={value} color={theme.palette.text.charcolGray} font='medium' />
        </div>
    )

    return (
        <div
            onClick={() => {
                navigate(`/equity/algorithm/${algo?.id}`)
            }}
            className={`flex cursor-pointer flex-col gap-3 bg-[#FEEEF580]  border border-[#FFCEE380] rounded-md p-3`}>
            <div className='flex items-start justify-between'>
                <div>
                    <H5
                        title={algo.title}
                        color={theme.palette.text.charcolGray}
                        font="medium"
                    />
                    <P
                        title={algo.description}
                        color={theme.palette.text.silverGray}
                    />
                </div>
                {/* <LockIcon sx={{ color: theme.palette.text.black }} /> */}
                <div className={`flex items-center ${isSubscribed ? '' : 'gap-2'}`}>
                    {isSubscribed ?
                        <Checkbox disabled checked /> :
                        <P
                            title={'$ 99,999 / Month'}
                            font="bold"
                            color={theme.palette.text.charcolGray}
                        />
                    }
                    <Button
                        variant='contained'
                        size='small'
                        disabled={isSubscribed}
                        sx={{
                            fontWeight: 'normal',
                            fontSize: '10px',
                            borderRadius: 0.4
                        }}
                    >
                        {isSubscribed ? 'Subscribed' : 'Subscribe'}
                    </Button>
                </div>
            </div>
            <div className='flex justify-between lg:justify-between flex-wrap gap-2  '>
                <div className='flex flex-col items-center gap-1'>
                    <P title={'ROI'} color={theme.palette.text.coolGray} />
                    <H6 title={`${algo.average_roi?.toFixed(2)}%`} color={algo.average_roi?.toFixed(2) >= 0 ? theme.palette.text.green : theme.palette.text.red} font='medium' />
                </div>
                <Divider orientation="vertical" flexItem />
                <WatchlistFields title='Open Short Trades' value={algo.open_short_trade} />
                <Divider orientation="vertical" flexItem />
                <WatchlistFields title='Open Long Trades' value={algo.open_long_trade} />
                <Divider orientation="vertical" flexItem />
                <WatchlistFields title='Closed Trades' value={algo.closed_trade} />
                <Divider orientation="vertical" flexItem />
                <div className='flex flex-col items-center gap-1'>
                    <div className='flex'>
                        <P title={"P"} color={theme.palette.text.green} />
                        <P title={"/"} color={theme.palette.text.coolGray} />
                        <P title={"L"} color={theme.palette.text.red} />
                    </div>
                    <H6 title={algo.pnl} color={algo.pnl >= 0 ? theme.palette.text.green : theme.palette.text.red} font='medium' />
                </div>
            </div>
        </div>
    )
}


