import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { asyngetCurrentIndex, setOpenOrders, setPositions, showToast } from "../../store/Actions/Action";
import MobileTable from "../../PublicComponents/MobileTable";
import AddBroker from "../../Public/Options/AddBroker";
import { ApiService, http } from "../../Services/api.services";
import MobilePositions from "../../PublicComponents/MobilePositions";
import { Alert, Badge, IconButton, Snackbar, Tab, Tabs } from "@mui/material";
import { useWebSocket } from "../../Contexts/WebsocketContext/WebSocketContext";
import { expiryReducer } from "../../store/Reducer/ExpiryReducer";
import { observer } from "mobx-react-lite";
import { useMarketMst } from "../../store/mobx/MarketStoreMST";
import moment from "moment";
import BottomSheet from "../../PublicComponents/BottomSheet";
import BasketHeader from "./BasketHeader";
import BasketTable from "./BasketTable";
import BasketPosition from "./BasketPositions";
import BasketsList from "./BasketsList";
import BasketHistory from "./BasketHistory";
import BasketLegs from "./BasketLegs";
import { setBasketPositions, updateAllBaskets, updateAllBasketsLegs, updateBaskethistory, updateBasketPositions, updateBasketStatus, updateCurrentBasket, updateCurrentBasketTab, updateCurrentHistory, updateCurrentPosition, updateCurrentSelectedOrder, } from "../../store/Reducer/BasketReducer";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import BasketFilter from "./BasketFilter";
import { useMarketContext } from "../../Contexts/MarketContext/MarketContext";
import { setCurrentMargin } from "../../store/Reducer/AdminReducer";
import Button from "@mui/material/Button";

const api = new ApiService();

const _filterData = {
	date: {
		startDate: "",
		endDate: "",
	},
	p_l: {
		from: "",
		to: "",
	},
	exitType: {
		sl: false,
		target: false,
		manual: false,
	},
	basketsName: [],
};

const BasketStrikes = () => {
	// INIT WEBSOCKET CONTEXT
	// SL REDUCER STATE
	const [filterApplied, setFilterApplied] = React.useState(false)
	const [basketFilterCount, setBasketFilterCount] = React.useState(0)
	const SL = useSelector((state) => state.SL);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	//   const market = useMarketContext();
	const market = useMarketContext();

	// console.log(market);

	const allCurrentExpiry = useSelector((state) => state.allCurrentExpiry);
	const user = useSelector((state) => state.user);
	const home = useSelector((state) => state.optionHome);
	const features = useSelector((state) => state.features);
	const { positions, openOrders } = useSelector((state) => state.orders);
	const { currentIndex, currentExpiry, activeSL } = useSelector((state) => state.optionHome);
	const [open, setOpen] = React.useState(false);
	const [openBroker, setOpenBroker] = React.useState(false);
	const [openMobilePosition, setOpenMobilePosition] = React.useState(false);
	const [refresh, setRefresh] = React.useState(false);
	// const [positions, setPositions] = React.useState([]);
	const [error, setErrors] = React.useState({
		open: false,
		type: "",
		message: "",
	});
	const [brokers, setBrokers] = React.useState([]);
	const [placingOrder, setPlacingOrder] = React.useState(false);
	const [isModifying, setIsModifying] = React.useState(null);
	const { admin, currentMargin } = useSelector((state) => state.admin);
	const [isScrolling, setIsScrolling] = React.useState(false);
	const [orderInstrument, setOrderIntrument] = React.useState(0);
	// const [currentExpiry, setCurrentExpiry] = React.useState({ nifty: home.expiry.nifty, banknifty: home.expiry.banknifty, finnifty: home.expiry.finnifty, midcpnifty: home.expiry.midcpnifty, sensex: home.expiry.sensex })
	const role = admin ? "admin" : "user";
	const id = admin ? admin?.id : user?.id;
	const [isHideVolume, setisHideVolume] = React.useState(true);
	const [currentSelectedType, setCurrentSelectedType] = React.useState("position");
	const { basketStatus, currentBasketTab, currentBasket, currentBasketFormData } = useSelector((state) => state.Baskets);
	const [showSelectMsg, setShowSelectMsg] = React.useState(false);
	const [formData, setFormData] = React.useState({
		orderType: "",
		txnType: "",
		symbol: "",
		tradingsymbol: "",
		exchange: "",
		instrument: null,
		strike: null,
		currentOption: null,
	});
	const [filterData, setFilterData] = React.useState({ ..._filterData });
	const [openHistoryFilter, setOpenHistoryFilter] = React.useState(false);
	const memoizedMarket = React.useMemo(() => market, [market]);

	// FETCH BROKER DETAILS

	const fecthBrokers = async () => {
		try {
			const response = await http.get(`/brokers`);
			if (response.status == 200) {
				setBrokers(response.data);
			}
		} catch (e) {
			console.log("errow while fetching brokers");
		}
	};

	// CALL POSITION , BROKER AND ORDERS DATA FUNCTION
	React.useEffect(() => {
		fecthBrokers();
	}, [user]);

	React.useEffect(() => {
		if (currentBasketTab == 2 && !filterApplied) {
			// FETCH BASKET HISTORY
			getBasketHistory()
		}
	}, [currentBasketTab])


	React.useEffect(() => {
		updateFilterCount()
	}, [filterApplied, openHistoryFilter])


	const updateFilterCount = () => {
		let count = 0
		if (filterData?.basketsName?.length) count++
		if (filterData?.date.startDate || filterData?.date.endDate) count++
		if (Object.values(filterData.exitType)?.includes(true)) count++
		if (filterData?.p_l.from && filterData?.p_l.to) count++
		setBasketFilterCount(count)
	}

	// IBDEX CHANGE EVENT
	const onIndexChangeEvent = (value) => {
		try {
			market.setCurrentIndex(value);
			setIsScrolling(false);
			dispatch(asyngetCurrentIndex(value));
			// dispatch(asyngetCurrentExpiry(expiry[value][0]))
		} catch (e) {
			console.log(`ERROR-- - ${e}`);
		}
	};

	const params = "action=login&type=login&status=success&request_token=pRTxaBjsn3YOKk2bGwOGXYV0VUKUKZuo";

	// ON form change function
	const onFormChange = (txnType, orderType, strike, data, tradingsymbol) => {
		// console.log(txnType, orderType, strike, data, tradingsymbol)
		if (user?.broker?.length || admin) {
			if (!home?.brokerConnected && !admin) {
				const _snackbar = {
					message: "Broker Not Connected!",
					open: true,
					duration: 10000,
					type: "error",
				};
				dispatch(showToast(_snackbar));
				return;
			}
			try {
				setOpen(!open);
				setFormData({
					orderType,
					txnType,
					strike,
					tradingsymbol,
					exchange: data.exchange,
					instrument: data.instrument_token,
					symbol: data.tradingsymbol,
					currentOption: data,
				});
				console.log(formData);
			} catch (e) {
				console.log(`ERROR-- - ${e}`);
			}
		} else {
			setOpenBroker(true);
		}
	};

	// PLACE ORDER FORM
	const onPlaceOrder = async (body) => {
		try {
			setPlacingOrder(true);
			setOrderIntrument(body?.instrumentToken);
			let url;
			if (admin) {
				url = isModifying ? "/orders/private/modify-order" : "/orders/private/place-order";
			} else {
				url = isModifying ? "/order/modify-order" : "/placeorder";
			}
			if (isModifying) {
				body["orderId"] = admin ? isModifying?.masterorderid : isModifying?.order_id;
			}
			// console.log(body)
			const response = await http.post(url, body);
			// console.log(response);
			setPlacingOrder(false);
			setIsModifying(null);
			setOrderIntrument(0);

			if (response.status == 201) {
				// SUCCESS
				setOpen(false);
				const _success = {
					message: response.data.message,
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_success));
				// setErrors({
				// 	open: true,
				// 	message: response.data.message,
				// 	type: 'success'
				// })
			} else {
				const _error = {
					message: response.data.message,
					open: true,
					duration: 3000,
					type: "error",
				};
				dispatch(showToast(_error));
			}
		} catch (e) {
			setPlacingOrder(false);
			setOrderIntrument(0);
			console.log(e);
			// setIsModifying(null)
			const _error = {
				message: e?.response?.data?.message || "Failed to place ordre",
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_error));
			// setErrors({
			// 	open: true,
			// 	message: e.response.data.message || "Failed to place order",
			// 	type: 'error'
			// })
		}
	};

	// HANDLE TAB CHANGE
	const handleTabChange = (event, newValue) => {
		dispatch(updateCurrentBasketTab(newValue));
		dispatch(updateCurrentBasket(null));
		dispatch(updateCurrentPosition(null));
		dispatch(updateBasketStatus("notSelected"));
		dispatch(updateAllBasketsLegs(null));
	};

	// MODIFY ORDER FUNCTION
	const onModify = async (order) => {
		try {
			// console.log(order)
			setIsModifying(order);
			const instrument = `'${order.instrument_token}'`;
			if (market.instruments && market.instruments[instrument]) {
				const index = market.instruments[`'${order.instrument_token}'`]?.index;
				const type = market.instruments[`'${order.instrument_token}'`]?.type;
				const strike = market[index]["options"][type][instrument];
				// const strike = await findStrikeInMarket(market, pos?._id)
				// console.log(index, type, market)
				// console.log(pos)
				// console.log(strike)
				setOpen(!open);
				setFormData({
					orderType: strike.instrument_type == "CE" ? "call" : "put",
					txnType: order?.transaction_type?.toLowerCase() || order?.lasttxntype?.toLowerCase(),
					strike: strike.strike,
					tradingsymbol: order?.tradingsymbol,
					exchange: order?.exchange,
					instrument: order.instrument_token,
					symbol: strike.tradingsymbol,
					currentOption: strike,
					quantity: order?.quantity,
					currentIndex: strike.name?.toLowerCase(),
				});
			}
			// const strike = await findStrikeInMarket(market, order?.tradingsymbol)
		} catch (e) {
			console.log(`ERROR-- ${e}`);
		}
	};

	// CANCEL ORDER FUNCTION
	const onCancel = async (order) => {
		try {
			console.log(order);
			setPlacingOrder(true);
			setIsModifying(null);
			setOrderIntrument(order?.instrumentToken);
			const url = admin ? "/orders/private/cancel-order" : "/order/cancel-order";
			const orderId = admin ? order?.masterorderid : order?.order_id;
			const userId = admin ? admin?.id : user?.id;
			const response = await http.post(url, {
				userId: userId,
				orderId: orderId,
			});
			setPlacingOrder(false);
			setOrderIntrument(0);
			if (response.status == 200) {
				const _snackbar = {
					message: "Order Cancelled!",
					open: true,
					duration: 5000,
					type: "success",
				};
				dispatch(showToast(_snackbar));
			} else {
				const _snackbar = {
					message: "Failed to cancel order",
					open: true,
					duration: 5000,
					type: "error",
				};
				dispatch(showToast(_snackbar));
			}
		} catch (e) {
			setPlacingOrder(false);
			setOrderIntrument(0);
			const _snackbar = {
				message: "Failed to cancel order",
				open: true,
				duration: 5000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
			console.log(`ERROR-- ${e}`);
		}
	};

	// ON EXPIRY CHANGE FUNCTION
	const onExpiryChange = async (expiry) => {
		try {
			// const response = await http.get(`${ENDPOINTS.OPTIONDATA}/${id}?expiry=${expiry}&index=${currentIndex}`)
			// dispatch(marketReducer({ type: currentIndex, payload: response?.data[currentIndex] }))
			const _snack = {
				message: "changing Expiry",
				type: "info",
				duration: 500,
				open: true,
			};
			dispatch(showToast(_snack));
			dispatch(expiryReducer({ type: currentIndex, payload: expiry }));
			dispatch(
				expiryReducer({
					type: "timeToExpire",
					key: currentIndex,
					payload: moment(expiry).valueOf(),
				})
			);
		} catch (e) {
			console.log("ERR WHILE FECTHING OPTN", e);
		}
	};

	// ADD INSTANT ORDER IN BASKET
	const placeInstantOrder = async (txnType, ordType, strikePrice, row, instrument) => {
		try {
			console.log(currentBasketFormData)
			const _body = {
				index: currentIndex?.toUpperCase(),
				expiry: allCurrentExpiry[currentIndex?.toLowerCase()],
				quantity: market[currentIndex?.toLowerCase()]?.qty,
				strike: strikePrice,
				transaction_type: txnType?.toUpperCase(),
				instrument_type: ordType?.toLowerCase() == "put" ? "PE" : "CE",
				price: null,
			};
			dispatch(updateCurrentSelectedOrder(_body));
			if (basketStatus == 'notSelected') {
				setShowSelectMsg(true);
			}
			dispatch(updateCurrentBasketTab(1));
			dispatch(updateCurrentHistory(null));
			dispatch(updateCurrentPosition(null));
		} catch (e) {
			console.log(e, "ERROR WHILE PLACING ORDER!");
			const errorMessage = e?.response?.data?.message?.split(".")[0];
			const _snackbar = {
				message: errorMessage,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
		}
	};

	// GET ALL BASKETS DATA
	React.useEffect(() => {
		getAllBaskets();
	}, [basketStatus]);

	const getAllBaskets = async () => {
		try {
			const response = await http.get(`orders/${id}/basket`);
			if (response.status == 200) {
				dispatch(updateAllBaskets(response?.data?.data));
			} else {
				dispatch(updateAllBaskets([]));
			}
		} catch (e) {
			dispatch(updateAllBaskets([]));
			console.log(`ERROR WHILE FETCHING BASKETS ${e}`);
		}
	};

	// GET ALL BASKETS POSITIONS DATA

	React.useEffect(() => {
		// getAllPositions();
	}, []);

	const getAllPositions = async () => {
		try {
			const response = await http.get(`orders/${id}/basket/position`);
			if (response?.status == 200) {
				dispatch(setBasketPositions(response?.data?.data));
				// market.setBasketPositionsWS({
				//   updatePositions: true,
				//   basketPositions: market.setMarketBasketPositions(response.data.data),
				// });
			} else {
				dispatch(setBasketPositions([]));
			}
		} catch (e) {
			dispatch(setBasketPositions([]));
			console.log(`ERROR WHILE FETCHING POSITIONS ${e}`);
		}
	};

	// GET BASKET HISTORY DATA
	React.useEffect(() => {
		getBasketHistory();
	}, []);

	const getBasketHistory = async () => {
		try {
			const response = await http.get(`orders/${id}/basket/history`);
			if (response?.status == 200) {
				dispatch(updateBaskethistory(response?.data?.data));
			} else {
				dispatch(updateBaskethistory([]));
			}
		} catch (e) {
			dispatch(updateBaskethistory([]));
			console.log(`ERROR WHILE FETCHING BASKET HISTORY ${e}`);
		}
	};

	React.useEffect(() => {
		if (currentBasketTab == 0) {
			getAllPositions();
		}
	}, [currentBasketTab]);

	// FETCH USER CURRENT MARGIN
	React.useEffect(() => {
		const _id = admin ? admin?.id : user?.id;
		getCurrentMargin(_id);
	}, [admin, user]);

	const getCurrentMargin = async (id) => {
		try {
			const response = await http.get(`user/${id}/margins`);
			if (response?.status == 200) {
				dispatch(setCurrentMargin(response?.data));
			}
		} catch (e) {
			console.log(`ERROR WHILE FETCHING MARGIN ${e}`);
		}
	};

	// React.useEffect(() => {
	//   // setFilterData(_filterData);
	//   // getBasketHistory();
	// }, [currentBasketTab]);

	return (
		<>
			<div className="w-full basket-page  flex  px-0 h-full">
				{/* TABLE AND HEADER PART */}
				<div className={`w-3/6 h-full `}>
					<div className="w-full flex flex-col h-full">
						<BasketHeader
							isHideVolume={isHideVolume}
							upadteIsHideVolume={() => {
								setisHideVolume(!isHideVolume);
							}}
							equillibrium={features?.EQUILIBRIUM_PRICE}
							expiry={memoizedMarket[currentIndex]?.expiry?.slice(0, 5) || []}
							onIndexChange={onIndexChangeEvent}
							currentIndex={currentIndex}
							market={memoizedMarket}
							onExpiryChange={onExpiryChange}
							currentExpiry={allCurrentExpiry[currentIndex]}
							allCurrentExpiry={allCurrentExpiry}
							onFormChange={() => {
								setOpenMobilePosition(true);
							}}
						/>
						<div className="hidden lg:flex gap-3 flex-1 overflow-hidden shadow-sm">
							<div className="w-full  border flex-1 border-[#9E9E9E24] rounded">
								<BasketTable
									isHideVolume={isHideVolume}
									msExpiry={allCurrentExpiry?.timeToExpire[currentIndex]}
									currentExpiry={allCurrentExpiry[currentIndex]}
									data={memoizedMarket[currentIndex]}
									onFormChange={onFormChange}
									admin={admin}
									isScrolling={isScrolling}
									setIsScrolling={setIsScrolling}
									placeInstantOrder={(txnType, ordType, strikePrice, row, instrument) => placeInstantOrder(txnType, ordType, strikePrice, row, instrument)}
									isConnected={
										user?.broker?.length ? user?.broker[0]?.active : false
									}
								/>
							</div>
						</div>
						<div className="flex lg:hidden  px-2 py-1 overflow-hidden shadow-sm">
							<MobileTable
								data={memoizedMarket[currentIndex]}
								onFormChange={onFormChange}
							/>
						</div>
					</div>
				</div>
				{/* BASKET AND LEGS SECTION */}
				<div className={`w-3/6 flex h-full mt-1`}>
					{/* BASKET */}
					<div className={`w-2/5 bg-white hidden py-2 overflow-hidden transition-all duration-75 lg:flex lg:flex-col`}>
						<div className="flex items-center justify-center w-full">
							<Tabs
								value={currentBasketTab}
								onChange={handleTabChange}
								aria-label="position-order-tabs">
								<Tab label="Positions " {...a11yProps(0)} />
								<Tab label="Basket" {...a11yProps(1)} />
								<Tab label={"History"} {...a11yProps(2)} />
							</Tabs>
							{currentBasketTab == 2 ? (
								<IconButton
									onClick={() => {
										if (currentBasketTab == 2) {
											setOpenHistoryFilter(true);
										}
									}}
									aria-label="filter">
									<Badge badgeContent={filterApplied ? basketFilterCount : 0} color="primary">
										<FilterListOutlinedIcon />
									</Badge>
								</IconButton>
							) : null}
						</div>
						<TabPanel value={currentBasketTab} index={0}>
							<BasketPosition
								data={positions}
								refresh={refresh}
								lossMsgClick={() => navigate("/home/learn-with-us")}
							/>
						</TabPanel>
						<TabPanel value={currentBasketTab} index={1}>
							<BasketsList />
						</TabPanel>
						<TabPanel value={currentBasketTab} index={2}>
							<BasketHistory />
						</TabPanel>
					</div>
					{/* LEGS */}
					<div className={`w-3/5 h-full transition-all duration-75`}>
						<BasketLegs currentSelectedType={currentSelectedType} />
					</div>
				</div>
			</div>
			{openBroker ? (
				<AddBroker
					open={openBroker}
					user={user}
					brokers={brokers}
					response={(err) => setErrors({ ...err })}
					onClose={() => {
						setOpenBroker(false);
					}}
				/>
			) : null}

			{openMobilePosition ? (
				<MobilePositions
					positions={positions}
					currentTab={currentBasketTab}
					handleTabChange={handleTabChange}
					openOrders={openOrders}
					open={openMobilePosition}
					onClose={() => setOpenMobilePosition(false)}
					refresh={refresh}
					onModify={(order) => onModify(order)}
					onCancel={(order) => onCancel(order)}
				/>
			) : null}

			<Snackbar
				open={error?.open}
				autoHideDuration={5000}
				onClose={() => setErrors({ open: false, message: "" })}>
				<Alert
					onClose={() => setErrors({ open: false, message: "", type: "" })}
					severity={error?.type || "error"}
					sx={{ width: "100%" }}>
					{error?.message}
				</Alert>
			</Snackbar>

			{/* SELECT BASKET SNACKBAR */}
			<Snackbar
				open={showSelectMsg}
				onClose={() => {
					setShowSelectMsg(false);
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}>
				<Alert
					onClose={() => {
						setShowSelectMsg(false);
					}}
					severity="info"
					variant="filled"
					sx={{ width: "100%" }}>
					Please Select Basket or Create New
				</Alert>
			</Snackbar>

			{/* HISTORY FILTER  */}
			{openHistoryFilter && currentBasketTab == 2 ? (
				<BasketFilter
					handleOpenFilter={() => {
						setOpenHistoryFilter(false);
					}}
					open={openHistoryFilter}
					filterData={filterData}
					setFilterData={setFilterData}
					id={id}
					filterApplied={(value) => setFilterApplied(value)}
					onresetFilter={() => {
						setFilterData(_filterData);
						getBasketHistory();
					}}
					onClearBasketNames={() => {
						setFilterData((prevData) => ({
							...prevData,
							basketsName: [],
						}));
					}} />
			) : null}
		</>
	);
};

export default observer(BasketStrikes);

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tab - ${index}`}
			aria-labelledby={`tab - ${index}`}
			className="flex-1 overflow-auto"
			{...other}>
			{children}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `tab - ${index}`,
		"aria-controls": `tabpanel - ${index}`,
	};
}
