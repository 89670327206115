import * as React from 'react';

import {
    Chart,
    Lines,
    Layer,
} from 'rumble-charts';

const LineGraph = ({ width, height, color, data }) => {
    data = data || [(19200 % 10), (19200.26 % 10), (19200 % 10), (19202 % 10), (19205 % 10), (19200 % 10), (19200 % 10), (19200.2 % 10), (19200 % 10), (19201 % 10), (19200 % 10), (19200.2 % 10), (19200 % 10), (19201 % 10), (19200 % 10), (19200.2 % 10), (19200 % 10), (19201 % 10)]

    return (
        <Chart
            width={width || 150}
            height={height || 100}
            series={[
                {
                    data: data,
                }
            ]}
            minY={0.1}
            scaleX={{
                paddingEnd: 0,
                paddingStart: 0
            }}
            scaleY={{
                paddingTop: 10,
            }}
        >
            <Layer height="90%" width="90%">
                <Lines
                    layerWidth={width || 100}
                    interpolation="linear"
                    lineWidth={1.5}
                    lineStyle={{ stroke: color }}
                />
            </Layer>
        </Chart>
    )
};

export default LineGraph;