import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ApiService, http } from "../../Services/api.services";
import { setUser } from "../../store/Reducer/UserReducer";
import { updateUserSubs } from "../../store/Reducer/UserSubsReducer";
import {
  asyncupdateBroker,
  asyngetStatus,
  reduxUpdateBrokers,
  showToast,
} from "../../store/Actions/Action";
import KiteTicker from "../../Services/kiteticker";
import { updateTicks } from "../../store/Reducer/TickerReducer";
import moment from "moment-timezone";
import { updateOptionChain } from "../../Services/update.service";
import { marketReducer } from "../../store/Reducer/MarketReducer";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Fab,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  styled,
} from "@mui/material";
import { Container } from "react-bootstrap";
import HomeIcon from "@mui/icons-material/Home";
import CountrySwitch from "../../PublicComponents/CountrySwitch";
import {
  Add,
  CurrencyRupee,
  LightbulbRounded,
  LocalLibrary,
  Logout,
  Newspaper,
} from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import DoneIcon from "@mui/icons-material/Done";
import { ENDPOINTS, dummy_option_chain } from "../../default.config";
import Logo from "../../assets/Group 829.png";
import SchoolIcon from "@mui/icons-material/School";
import { WebSocketProvider } from "../../Contexts/WebsocketContext/WebSocketContext";
import { useDimensions } from "../../Contexts/DimensionContext/DimensionsContext";
import MenuIcon from "@mui/icons-material/Menu";
import HomeDrawer from "../../PublicComponents/HomeDrawer";
import { EditOutlined } from "@material-ui/icons";
import BrokerForm from "../../PublicComponents/BrokerForm";

import localFeatures from "../../features.json";
import { updateFeatues } from "../../store/Reducer/FeaturesReducer";
import { updateActiveSL } from "../../store/Reducer/Reducer";
import { expiryReducer } from "../../store/Reducer/ExpiryReducer";
import { ToolsProvider } from "../../Contexts/ToolsContext/ToolsContext";
import Searchcomponent from "../../EquityComponents/Searchcomponent";

const api = new ApiService();
const selectedRoute =
  "text-[#373737] font-semibold text-[13px] uppercase m-0";
const notSelected =
  "text-[#757575] font-normal uppercase text-[13px] m-0 cursor-pointer";
const disabledRoute =
  "text-[#C0C0C0CA] font-normal uppercase text-[13px] m-0";

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { xs, sm, md, lg, xl, xxl } = useDimensions();

  const home = useSelector((state) => state.optionHome);
  const user = useSelector((state) => state.user);
  const market = useSelector((state) => state.market);
  const features = useSelector((state) => state.features);
  const userSubscription = useSelector((state) => state.userSubs);
  const { plans } = useSelector((state) => state.plans);
  const { ticks } = useSelector((state) => state.ticks);
  const [token, useToken] = useState(localStorage.getItem("token"));
  const [userId, setUserId] = useState(localStorage.getItem("user_id"));
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [login_url, setLogin_url] = React.useState("");
  const [lastTicks, setLastTick] = React.useState(null);
  const [openBroker, setOpenBroker] = React.useState(false);
  const [planRoute, setPlanRoute] = React.useState(false);
  const [brokers, setBrokers] = React.useState([]);
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(
    home?.currentIndex || "nifty"
  );
  const pathnameSegments = location.pathname.split("/");
  const route = pathnameSegments[2];
  const allCurrentExpiry = useSelector((state) => state.allCurrentExpiry);
  const { admin } = useSelector((state) => state.admin);
  var ticker;

  const id = admin ? admin?.id : user?.id;
  const memoizedTicks = useMemo(() => ticks, [ticks]);

  useEffect(() => {
    //VERIFY TOKEN TO CHECK VALIDIIY OF TOKEN
    if (!token) {
      navigate("/");
    }
    if (userId) {
      fetchUser(userId);
    }
    fetchBroker();
  }, []);

  useEffect(() => {
    if (user?.id && userSubscription?.subscription?.id) {
      const _currentPlan = plans.find(
        (plan) => plan.id === userSubscription?.subscription?.id
      );
      const route = `/home/plans/${_currentPlan?.title}/${_currentPlan?.id}/${userSubscription?.price > 3000 ? 12 : 1
        }`;
      setPlanRoute(route);
      usrAuthStatus(user?.id, route);
    }
  }, [user, userSubscription]);

  // FETCH BROKERS
  const fetchBroker = async () => {
    try {
      const response = await http.get(`/brokers`);
      if (response.status == 200) {
        setBrokers(response.data);
        dispatch(reduxUpdateBrokers(response?.data));
      }
    } catch (e) {
      setBrokers([]);
      console.log("errow while fetching brokers");
    }
  };

  // AUTH STATUS OF THE USER
  const usrAuthStatus = async (userId, route) => {
    try {
      const response = await api.getUserAuthStatus(userId);
      dispatch(asyncupdateBroker(response.active));
      if (!response.active) {
        setLogin_url(response.login_url);
        localStorage.setItem("auth-connect", response.login_url);
      }
      if (location?.hash?.includes("#start-free-trial")) {
        setTimeout(() => {
          navigate(route);
        }, 700);
      }
    } catch (e) {
      console.log(`ERROR WHILE GETTING USER AUTH STATUS --- ${e}`);
    }
  };

  // FETCH USER
  const fetchUser = async (userId) => {
    try {
      const response = await http.get(`user/${userId}`);
      if (response.status == 200) {
        dispatch(
          setUser(response.data)
        );
        // dispatch(updateActiveSL(response?.data?.active_sl))
        fetchOptionChain(response.data);
        fetchMySubscription(response.data);
      }
    } catch (e) {
      console.log("ERROR WHILE FETCHING USER --- ", e);
    }
  };

  // FETCH USER SUBSCRIPTION
  const fetchMySubscription = async (usr) => {
    try {
      const response = await http.get(
        `subscription/my-subscriptions/${usr?.id}`
      );
      if (response.status == 200) {
        const expiry =
          new Date(response.data?.expiry_date).getTime() - new Date().getTime();
        const mysub = {
          ...response.data,
          daysleft: expiry ? Math.ceil(expiry / 1000 / 60 / 60 / 24) : 0,
        };
        // console.log(mysub)
        updateFeatureRedux(mysub?.subscription?.id);
        dispatch(
          updateUserSubs({ type: "userSubs/updateUserSubs", payload: mysub })
        );
        if (mysub?.daysleft < 0) {
          // SUBSCRIPTION EXPIRED
          const _snackbar = {
            message: "You do not have active subscription!",
            open: true,
            duration: 10000,
            type: "error",
          };
          dispatch(showToast(_snackbar));
        } else if (mysub?.daysleft == 0) {
          const _snackbar = {
            message: "Your Subscription is about expire!",
            open: true,
            duration: 10000,
            type: "warning",
          };
          dispatch(showToast(_snackbar));
        }
      } else {
        console.log("NOTHING FOUND!");
      }
    } catch (e) {
      if (e?.response?.status == 400) {
        // NO SUSBCRIPTION FOUND
        dispatch(
          updateUserSubs({ type: "userSubs/updateUserSubs", payload: null })
        );
        const _snackbar = {
          message: "No Active Subscription!!",
          open: true,
          duration: 10000,
          type: "error",
        };
        dispatch(showToast(_snackbar));
      }
      console.log("ERROR WHILE FETCHING MY SUBSCRIPTIONS", e);
    }
  };

  // UPDATE FEATURE REDUX TO ALLOW ACCESS
  const updateFeatureRedux = (subscriptionId) => {
    try {
      const activePlan = plans.find((plan) => plan.id === subscriptionId);
      const _feat = { ...features };
      for (let index = 0; index < activePlan?.features?.length; index++) {
        const activeFeatureId = activePlan?.features[index];
        for (let i = 0; i < localFeatures.length; i++) {
          const _local = localFeatures[i];
          if (_local.id == activeFeatureId) {
            _feat[_local?.key] = true;
          }
          if (index == activePlan?.features?.length - 1) {
            if (i === localFeatures.length - 1) {
              // SET FEATURES HERE
              dispatch(updateFeatues(_feat));
            }
          }
        }
      }
    } catch (e) {
      console.log(`ERROR WHILE UPDATING FEATURES REDUX ${e}`);
    }
  };

  // FETCH OPTION CHAIN
  const fetchOptionChain = async (usr) => {
    try {
      const response = await http.get(`${ENDPOINTS.OPTIONDATA}/${usr?.id}`);
      dispatch(marketReducer({ type: "updateMarket", payload: response.data }));
      // Extract expiry values for different instruments
      const niftyExpiry = response?.data?.nifty?.expiry[0];
      const bankniftyExpiry = response?.data?.banknifty?.expiry[0];
      const finniftyExpiry = response?.data?.finnifty?.expiry[0];
      const midcapniftyExpiry = response?.data?.midcpnifty?.expiry[0];
      const sensexExpiry = response?.data?.sensex?.expiry[0];

      // Update the Redux state with the extracted expiry values
      dispatch(expiryReducer({ type: "nifty", payload: niftyExpiry }));
      dispatch(expiryReducer({ type: "banknifty", payload: bankniftyExpiry }));
      dispatch(expiryReducer({ type: "finnifty", payload: finniftyExpiry }));
      dispatch(
        expiryReducer({ type: "midcpnifty", payload: midcapniftyExpiry })
      );
      dispatch(expiryReducer({ type: "sensex", payload: sensexExpiry }));
      const instruments = await returnInstruments(response.data);
      connectKiteTicker(
        instruments,
        usr?.broker[0]?.api_key,
        usr?.broker[0]?.access_token
      );
    } catch (e) {
      console.log("ERROR WHILE FETCHING OPTION CHAIN -- ", e);
      dispatch(
        marketReducer({ type: "updateMarket", payload: dummy_option_chain })
      );
    }
  };

  // CHANGE EXPIRY
  const onExpiryChange = async (expiry) => {
    try {
      const response = await http.get(
        `${ENDPOINTS.OPTIONDATA}/${id}?expiry=${expiry}&index=${currentIndex}`
      );
      dispatch(
        marketReducer({
          type: currentIndex,
          payload: response?.data[currentIndex],
        })
      );
      dispatch(expiryReducer({ type: currentIndex, payload: expiry }));
    } catch (e) {
      console.log("ERR WHILE FECTHING OPTN", e);
    }
  };

  // CONNECT KITE TICKER
  const connectKiteTicker = (instruments, key, token) => {
    // CONNECT TO KITE TICKER AND PUBLISH THROUGHOUT THE APP
    try {
      ticker = new KiteTicker({
        api_key: key || "lzj06l6saefis5vy",
        access_token: token || "X86xPYlRfEUOs8U9l4viKHv4TSr2x2uG",
      });
      ticker.connect();
      ticker.on("ticks", onTicks);
      ticker.on("connect", subscribe);

      function onTicks(ticks) {
        dispatch(updateTicks({ type: "ticks", payload: { ticks: ticks } }));
      }

      function subscribe() {
        ticker.subscribe(instruments);
        ticker.setMode(ticker.modeFull, instruments);
      }
    } catch (e) {
      console.log(" -- CONNECT KITE TICKER -- ");
    }
  };

  React.useEffect(() => {
    setCurrentIndex(home?.currentIndex);
  }, [home?.currentIndex]);

  React.useEffect(() => {
    if (memoizedTicks) {
      updateNifty(memoizedTicks);
      updateBnk(memoizedTicks);
      updateFin(memoizedTicks);
      updateMid(memoizedTicks);
      updateSensex(memoizedTicks);
    }
  }, [memoizedTicks]);

  // UPDATE SPECIFIC NIFTY IN REDUX
  const updateNifty = async (updated) => {
    try {
      const __market = JSON.parse(JSON.stringify(market["nifty"]));
      const _updateResponse = await updateOptionChain(
        updated,
        __market.option,
        __market,
        "strikePrice"
      );
      dispatch(marketReducer({ type: "nifty", payload: _updateResponse }));
    } catch (e) {
      console.log(`ERROR WHILE UPDATE NIFTY ${e}`);
    }
  };

  // UPDATE SPECIFIC BANKNIFTY IN REDUX
  const updateBnk = async (updated) => {
    try {
      const __market = JSON.parse(JSON.stringify(market["banknifty"]));
      const _updateResponse = await updateOptionChain(
        updated,
        __market.option,
        __market,
        "strikePrice"
      );
      dispatch(marketReducer({ type: "banknifty", payload: _updateResponse }));
    } catch (e) {
      console.log(`ERROR WHILE UPDATE BANK NIFTY ${e}`);
    }
  };

  // UPDATE SPECIFIC FINNIFTY IN REDUX
  const updateFin = async (updated) => {
    try {
      const __market = JSON.parse(JSON.stringify(market["finnifty"]));
      const _updateResponse = await updateOptionChain(
        updated,
        __market.option,
        __market,
        "strikePrice"
      );
      dispatch(marketReducer({ type: "finnifty", payload: _updateResponse }));
    } catch (e) {
      console.log(`ERROR WHILE UPDATE FINNIFTY ${e}`);
    }
  };

  // UPDATE SPECIFIC MIDCPNIFTY IN REDUX
  const updateMid = async (updated) => {
    try {
      const __market = JSON.parse(JSON.stringify(market["midcpnifty"]));
      const _updateResponse = await updateOptionChain(
        updated,
        __market.option,
        __market,
        "strikePrice"
      );
      dispatch(marketReducer({ type: "midcpnifty", payload: _updateResponse }));
    } catch (e) {
      console.log(`ERROR WHILE UPDATE MID CP NIFTY ${e}`);
    }
  };

  // UPDATE SPECIFIC SENSEX IN REDUX
  const updateSensex = async (updated) => {
    try {
      const __market = JSON.parse(JSON.stringify(market["sensex"]));
      const _updateResponse = await updateOptionChain(
        updated,
        __market.option,
        __market,
        "strikePrice"
      );
      dispatch(marketReducer({ type: "sensex", payload: _updateResponse }));
    } catch (e) {
      console.log(`ERROR WHILE UPDATE SENSEX ${e}`);
    }
  };

  // RETURNS INSTRUMENTS
  const returnInstruments = (data) => {
    return new Promise((resolve, reject) => {
      try {
        const keys = Object.keys(data);
        const instruments = [];
        for (let index = 0; index < keys.length; index++) {
          const key = keys[index];
          instruments.push(data[key]?.instrumentToken);
          for (let i = 0; i < data[key]?.option.length; i++) {
            const op = data[key]?.option[i];
            instruments.push(op.call.instrument, op.put.instrument);
            if (
              i === data[key]?.option.length - 1 &&
              index === keys.length - 1
            ) {
              resolve(instruments);
            }
          }
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  // HANDLE LOGOUT
  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    const response = await api.logout({ token });
    // console.log(response);
    localStorage.clear();
    window?.location?.reload();
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // CONTECT BROKER
  const connectBroker = () => {
    if (user?.broker?.length) window.location.href = login_url;
  };

  // UPDATE BROKER
  const updateBroker = () => {
    setOpenBroker(true);
    handleCloseUserMenu();
  };

  return (
    <div className="w-full h-full flex flex-col">
      {token || location.pathname?.includes("/plans/") ? (
        <div className="flex-1 flex flex-col overflow-hidden">
          <AppBar position="static" className="!bg-white !shadow w-full">
            <Container
              sx={{ width: "100%" }}
              className="!px-3 !max-w-full py-0 lg:!px-4"
            >
              <Toolbar
                disableGutters
                className="flex items-center justify-between"
              >
                <div className="flex items-center gap-10">
                  <div className="hidden lg:flex">
                    <Button
                      onClick={() => navigate("/")}
                      variant="text"
                      size="small"
                      disableRipple
                    >
                      <img src={Logo} alt="" />
                    </Button>
                  </div>
                  <div className="flex items-center gap-2 flex-wrap">
                    <IconButton
                      onClick={() => navigate("/index-options/strikes")}
                    >
                      <HomeIcon
                        sx={{
                          color: location?.pathname?.includes("/index-options/")
                            ? "#373737"
                            : "#757575",
                        }}
                      />
                    </IconButton>
                    {xs || sm || md ? (
                      <ToggleButtonGroup sx={{ border: "none" }}>
                        <Tooltip title="Coming Soon" enterTouchDelay={0}>
                          <ToggleButton
                            sx={{ borderWidth: "0px" }}
                            variant="text"
                            size="small"
                            disableRipple
                          >
                            <h6 className={
                              location?.pathname?.includes("/index-options/")
                                ? selectedRoute
                                : notSelected
                            }>EQ</h6>
                          </ToggleButton>
                        </Tooltip>
                        <Tooltip title="INDEX OPTIONS" enterTouchDelay={0}>
                          <ToggleButton
                            sx={{ borderWidth: "0px" }}
                            variant={"text"}
                            size="small"
                            onClick={() => navigate("/index-options/strikes")}
                          >
                            <h6
                              className={
                                location?.pathname?.includes("/index-options/")
                                  ? selectedRoute
                                  : notSelected
                              }
                            >
                              OP
                            </h6>
                          </ToggleButton>
                        </Tooltip>
                      </ToggleButtonGroup>
                    ) : (
                      <div className="flex items-center gap-2">
                        <Tooltip title="Coming Soon">
                          <Button variant="text" size="small" disableRipple>
                            <h6 className={
                              location?.pathname?.includes("/equity/")
                                ? selectedRoute
                                : notSelected
                            }>EQUITY</h6>

                          </Button>
                        </Tooltip>
                        <Tooltip title="INDEX OPTIONS">
                          <Button
                            variant={"text"}
                            size="small"
                            onClick={() => navigate("/index-options/strikes")}
                          >
                            <h6
                              className={
                                disabledRoute
                              }
                            >
                              INDEX OPTIONS
                            </h6>
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-center gap-1 lg:gap-5">
                  {md || lg || xl || xxl ? (
                    <>
                      <Tooltip
                        className="lg:flex lg:gap-2"
                        title="Today's Trend"
                      >
                        <Button
                          onClick={() => navigate("/home/news")}
                          variant="text"
                          size="small"
                        >
                          <Newspaper
                            sx={{
                              color: location?.pathname?.includes("/home/news")
                                ? "#373737"
                                : "#757575",
                            }}
                            fontSize={lg || xl || xxl ? "small" : "medium"}
                          />
                          {lg || xl || xxl ? (
                            <h6
                              className={
                                location?.pathname?.includes("/home/news")
                                  ? selectedRoute
                                  : notSelected
                              }
                            >
                              Today's Trend
                            </h6>
                          ) : null}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        className="lg:flex lg:gap-2"
                        title="Earn With Us"
                      >
                        <Button
                          onClick={() => navigate(planRoute)}
                          variant="text"
                          size="small"
                        >
                          <LightbulbRounded
                            sx={{
                              color: location?.pathname?.includes(
                                "/home/plans/"
                              )
                                ? "#373737"
                                : "#757575",
                            }}
                            fontSize={lg || xl || xxl ? "small" : "medium"}
                          />
                          {lg || xl || xxl ? (
                            <h6
                              className={
                                location?.pathname?.includes("/home/plans/")
                                  ? selectedRoute
                                  : notSelected
                              }
                            >
                              Earn With Us
                            </h6>
                          ) : null}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        className="lg:flex lg:gap-2"
                        title="Learn With Us"
                      >
                        <Button
                          onClick={() => navigate("/home/learn-with-us")}
                          variant="text"
                          size="small"
                        >
                          <SchoolIcon
                            sx={{
                              color: location?.pathname?.includes(
                                "/home/learn-with-us"
                              )
                                ? "#373737"
                                : "#757575",
                            }}
                            fontSize={lg || xl || xxl ? "small" : "medium"}
                          />
                          {lg || xl || xxl ? (
                            <h6
                              className={
                                location?.pathname?.includes(
                                  "/home/learn-with-us"
                                )
                                  ? selectedRoute
                                  : notSelected
                              }
                            >
                              Learn With Us
                            </h6>
                          ) : null}
                        </Button>
                      </Tooltip>
                    </>
                  ) : null}
                  <Tooltip title="US Coming Soon">
                    <div>
                      <CountrySwitch />
                    </div>
                  </Tooltip>
                  {md || lg || xl || xxl ? (
                    <Box sx={{ flexGrow: 0 }}>
                      <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <div className="relative">
                            <div
                              className={`w-3 h-3 rounded-full absolute top-0 right-0 z-50 ${home.brokerConnected
                                ? "bg-green-600"
                                : "bg-red-600"
                                }`}
                            ></div>
                            <Avatar
                              sx={{ position: "relative" }}
                              alt={user?.name}
                              src="/static/images/avatar/2.jpg"
                            />
                          </div>
                        </IconButton>
                      </Tooltip>
                      <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        {home.brokerConnected ? (
                          <MenuItem className="!bg-green-50">
                            <ListItemIcon>
                              <DoneIcon color="success" fontSize="small" />
                            </ListItemIcon>
                            <span className="text-slate-500">
                              Broker Connected
                            </span>
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={connectBroker}
                            className="!bg-red-50"
                          >
                            <ListItemIcon>
                              <ErrorIcon color="error" fontSize="small" />
                            </ListItemIcon>
                            <span className="text-slate-500">
                              Not Connected
                            </span>
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            navigate(`/home/news`);
                            handleCloseUserMenu();
                          }}
                        >
                          <ListItemIcon>
                            <Newspaper color="primary" fontSize="small" />
                          </ListItemIcon>
                          <span className="text-slate-500">Today's Trend</span>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            navigate(`${planRoute}`);
                            handleCloseUserMenu();
                          }}
                        >
                          <ListItemIcon>
                            <LightbulbRounded
                              color="primary"
                              fontSize="small"
                            />
                          </ListItemIcon>
                          <span className="text-slate-500">Earn with us</span>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            navigate(`/home/learn-with-us`);
                            handleCloseUserMenu();
                          }}
                        >
                          <ListItemIcon>
                            <SchoolIcon color="primary" fontSize="small" />
                          </ListItemIcon>
                          <span className="text-slate-500">Learn with us</span>
                        </MenuItem>
                        {user?.broker?.length ? (
                          <MenuItem onClick={() => updateBroker()}>
                            <ListItemIcon>
                              <EditOutlined color="primary" fontSize="small" />
                            </ListItemIcon>
                            <span className="text-slate-500">
                              Update Broker
                            </span>
                          </MenuItem>
                        ) : null}
                        <MenuItem onClick={handleLogout}>
                          <ListItemIcon>
                            <Logout color="error" fontSize="small" />
                          </ListItemIcon>
                          <span className="text-red-400">Logout</span>
                        </MenuItem>
                      </Menu>
                    </Box>
                  ) : (
                    <>
                      <IconButton onClick={() => setDrawerOpen(true)}>
                        <MenuIcon color="primary" />
                      </IconButton>
                    </>
                  )}
                </div>
              </Toolbar>
            </Container>
          </AppBar>
          <HomeDrawer
            isOpen={isDrawerOpen}
            currentRoute={location.pathname}
            onClose={() => setDrawerOpen(false)}
            openBroker={() => setOpenBroker(true)}
            onConnectBroker={() => connectBroker}
            earnwithus={() => navigate(`${planRoute}`)}
            learnwithus={() => navigate(`/home/learn-with-us`)}
            onNewsClick={() => navigate(`/home/news`)}
            onUpdateBroker={() => setOpenBroker(true)}
            onLogout={() => handleLogout()}
          />
          {openBroker ? (
            <BrokerForm
              open={openBroker}
              userBroker={user?.broker?.length ? user?.broker[0] : null}
              onClose={() => setOpenBroker(false)}
              user={user}
              brokers={brokers}
              type={"update"}
              onSubmit={(response) => dispatch(showToast(response))}
              isFullScreen={xs || sm || md}
            />
          ) : null}
          <WebSocketProvider>
            <ToolsProvider>
              <Outlet />
            </ToolsProvider>
          </WebSocketProvider>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <div>
            <h1 className="text-red-700 text-[5vmax]"></h1>
          </div>
        </div>
      )}
    </div>
  );
};
export default Auth;
