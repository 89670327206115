import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useThemeContext } from "../Theme/ThemeContext";
import { LoadingButton } from "@mui/lab";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AddWatchlist({
  open,
  isLoading,
  onHandleClose,
  watchlistName,
  setWatchlistNameFunction,
  onUpdateWatchlist,
  onCreateWatchlist,
  isEditing
}) {
  const { theme } = useThemeContext();

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={onHandleClose}
        aria-labelledby="customized-dialog-title" 
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2, fontSize: '16px', color: theme.palette.text.charcolGray }} id="customized-dialog-title">
          WATCHLIST NAME
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onHandleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="w-96 py-4">
            <TextField
              placeholder="enter name of watchlist to create"
              id="outlined-basic"
              // label="Outlined"
              fullWidth
              variant="outlined"
              value={watchlistName}
              size="small"
              onChange={(e) => setWatchlistNameFunction(e)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            size="small"
            variant="contained"
            onClick={() => {
              if (isEditing) {
                onUpdateWatchlist();
              } else {
                onCreateWatchlist();
              }
            }}
            loading={isLoading}
            disabled={!watchlistName}
          >
            {isEditing ? 'Save' : 'Create'}
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
