import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import GetAppIcon from '@mui/icons-material/GetApp';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const AddDocumentDialog = (props) => {
    const theme = useTheme();

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={props.onClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#FFFFFF' }} id="customized-dialog-title">
                    <h6 className='text-[#3E3E3E] text-[2vmax] tracking-wider font-semibold lg:text-[1vmax]'>ADD DOCUMENT</h6>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon sx={{ color: "#ABABAB" }} />
                </IconButton>

                <DialogContent sx={{ backgroundColor: "#FFFFFF" }} dividers>
                    <div className='w-[70vw] flex flex-col items-start gap-8 py-4 lg:w-fit '>
                        <div className='w-full lg:w-[35vw] flex flex-col items-start gap-1 lg:px-4'>
                            <h6 className='text-[#6A6A6A]  text-left text-[1.5vmax] font-medium tracking-wider lg:text-[.8vmax]'>Name</h6>
                            <TextField id="standard-basic" placeholder='Enter name' variant="standard" fullWidth
                                sx={{
                                    color: '#BCBCBC',
                                    '& .MuiInputBase-root': {
                                        [theme.breakpoints.down('sm')]: {
                                            fontSize: '1.5vmax', // Font size for large screens
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            fontSize: '.9vmax', // Font size for large screens
                                        },
                                    },
                                }}
                            />
                        </div>
                        <div className='w-full lg:w-[35vw] flex flex-col items-start gap-1 lg:gap-4 lg:px-4'>
                            <h6 className='text-[#6A6A6A]  text-left text-[1.5vmax] font-medium tracking-wider lg:text-[.8vmax]'>Logo</h6>
                            <div class="border w-[48%] lg:w-fit flex flex-col items-center justify-center rounded border-dotted border-[#C8C8C8] p-7 lg:p-5 lg:px-10">
                                <div>
                                    <GetAppIcon sx={{ color: '#CBCBCB' }} />
                                </div>
                                <div>
                                    <h6 className='text-[#CBCBCB] text-[2vmax] lg:text-[.9vmax]'>Upload logo</h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: "#FFFFFF" }}>
                    <div className='py-1'>
                        <div className='flex items-start justify-center py-2 w-fit px-6 lg:px-8 lg:py-3 rounded-sm bg-[#2A3B43]'>
                            <h6 className='text-white  text-[1.5vmax] lg:text-[.8vmax]'>Add</h6>
                        </div>
                    </div>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default AddDocumentDialog