import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const Separator = styled('div')(
  ({ theme }) => `
  height: ${theme.spacing(3)};
`,
);

const marks = [
  {
    value: 0,
    label: '0°C',
  },
  {
    value: 20,
    label: '20°C',
  },
  {
    value: 37,
    label: '37°C',
  },
  {
    value: 100,
    label: '100°C',
  },
];

function valuetext(value) {
  return `${value}°C`;
}



export const FilterCheckboxes = (props) => {

  const handleCheckboxChange = (event) => {
    const selectedValue = event.target.value;
    const isChecked = event.target.checked;

    let updatedSelectedRange;

    if (selectedValue === 'Select All') {
      // Handle "Select All" checkbox
      updatedSelectedRange = isChecked ? props.data : [];
    } else {
      // Handle other checkboxes
      updatedSelectedRange = Array.isArray(props.selectedRange)
        ? isChecked
          ? [...props.selectedRange, selectedValue]
          : props.selectedRange.filter((value) => value !== selectedValue)
        : isChecked
          ? [selectedValue]
          : null;
    }
    console.log(updatedSelectedRange);

    props.setSelectedRange(updatedSelectedRange);
  };


  return (
    <FormGroup>

      {props?.data?.map(((_data, index) => (
        <FormControlLabel
          key={index}
          value={_data}
          control={<Checkbox size='small' />}
          label={<h6 className='text-[#0000008C] capitalize text-sm lg:text-xs font-semibold'>{_data}</h6>}
          onChange={handleCheckboxChange}
          checked={Array.isArray(props.selectedRange) && props.selectedRange.includes(_data)}
        />
      )))}

      {/* "Select All" checkbox */}
      {props?.data?.length > 1 &&
        <FormControlLabel
          key="select-all"
          value="Select All"
          control={<Checkbox size='small' />}
          label={<h6 className='text-[#0000008C] capitalize text-sm lg:text-xs font-semibold'>Select All</h6>}
          onChange={handleCheckboxChange}
          checked={Array.isArray(props.selectedRange) && props.selectedRange.length === props.data.length}

        />
      }




    </FormGroup>
  );
};

export const FilterRange = (props) => {

  const handleRangeChange = (event, newValue) => {
    props?.setSelectedRange(newValue);
  };


  return (
    <Box sx={{ width: '100%' }}>
      <Slider
        sx={{
          '& .css-1xst22u-MuiSlider-rail ': {
            backgroundColor: '#0000002B'
          },
          '& .css-7pyz68-MuiSlider-track ': {
            backgroundColor: '#373737'
          },
          '& .css-6cwnna-MuiSlider-mark': {
            backgroundColor: '#373737'
          },
          color: '#373737'
        }}
        size='small'
        track="inverted"
        aria-labelledby="track-inverted-range-slider"
        getAriaValueText={valuetext}
        defaultValue={props?.selectedRange}
        // value={props?.selectedRange}
        min={0} // Set the minimum value
        max={props?.max} // Set the maximum value
        marks={props?.data}
        onChange={handleRangeChange}

      />
    </Box>
  );
}
