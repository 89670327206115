import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom"
import AdminHeader from "../PrivateComponents/AdminHeader"
import NewsArticle from "../PublicComponents/NewsArticle"
import { http } from "../Services/api.services"
import { useDispatch } from 'react-redux';
import { showToast } from '../store/Actions/Action';
import PromptDialog from '../PrivateComponents/PromptDialog';
import Loading from '../PrivateComponents/Loading';
import { IMAGE_URL, panel } from '../default.config';
import { Chip, IconButton, Skeleton, TextField } from '@mui/material';
import { SearchOutlined, ShareOutlined } from '@mui/icons-material';
import moment from 'moment';
import { InputAdornment } from '@material-ui/core';
import ShareDialog from '../PublicComponents/ShareDialog';

const SearchStyle = {
    my: 2,
    background: '#eee',
}


const NewsDetails = () => {
    const navigate = useNavigate()
    const [news, setNews] = React.useState(null)
    const [allNews, setAllNews] = React.useState(null)
    const dispatch = useDispatch()
    const [showShare, setShowShare] = React.useState(false)

    const params = useParams()
    const contentRef = React.useRef()


    React.useEffect(() => {
        fetchNews(params?.id)
        fetchNewsLatest()
        document.title = params?.name + ' | Fastr'
        return () => (
            document.title = 'Fastr'
        )
    }, [params?.id])

    const fetchNews = async (id) => {
        try {
            const response = await http.get(`news/${id}`)
            if (response.status == 200) {
                setNews(response.data)
                contentRef.current.innerHTML = response.data.content
            }
            else setNews(null)
        }
        catch (e) {
            setNews(null)
            console.log('ERROR WHILE FETCHING NEWS -- ', e)
        }
    }

    const fetchNewsLatest = async () => {
        try {
            const response = await http.get(`news/published/user`)
            if (response.status == 200) {
                const _temp = response.data
                const _index = _temp.findIndex(n => n.id === params.id)
                _temp.splice(_index, 1)
                setAllNews(_temp)

            }
            else setAllNews([])
        }
        catch (e) {
            setAllNews([])
            console.log('ERROR WHILE FETCHING NEWS -- ', e)
        }
    }

    const onArticlClick = (id, title) => {
        navigate(`/home/news/${id}/${title}`, '_self')
    }

    return (
        <div className="w-full h-full py-4">
            <div className='mx-4 lg:mx-52 2xl:mx-96 xl:mx-80'>
                <div className='w-full flex flex-col gap-6'>
                    <div className='flex justify-between items-center gap-4'>
                        <h1 className='text-lg lg:text-4xl font-medium'>{params?.name || news.title}</h1>
                        <div className='flex items-center'>
                            {news?.createdat ?
                                <p className='text-xs font-light whitespace-pre text-gray-400'>{moment(news?.createdat).fromNow()}</p>
                                : <Skeleton variant='text' width={72} height={20} />
                            }
                            <IconButton onClick={() => setShowShare(true)}>
                                <ShareOutlined />
                            </IconButton>
                        </div>
                    </div>
                    {news?.cover ?
                        <img className='w-full object-cover rounded' src={IMAGE_URL + news?.cover} />
                        : <div className='h-[200px] lg:h-[400px]'>
                            <Skeleton height={'100%'} width={'100%'} variant='rounded' />
                        </div>
                    }
                    <div className='flex gap-2'>
                        {news?.category ? news?.category.map((_cat, index) => (
                            <Chip
                                key={index}
                                label={_cat}
                            />
                        )) : <>
                            <Skeleton variant='text' height={40} width={60} />
                            <Skeleton variant='text' height={40} width={60} />
                        </>
                        }
                    </div>
                    <p ref={contentRef}></p>
                    {!news ?
                        <div className='flex flex-col gap-2'>
                            <Skeleton variant='text' height={40} width={'75%'} />
                            <Skeleton variant='text' height={40} width={'100%'} />
                            <Skeleton variant='text' height={40} width={'90%'} />
                            <Skeleton variant='text' height={40} width={'95%'} />
                            <Skeleton variant='text' height={40} width={'90%'} />
                        </div> : null
                    }

                </div>
                <br></br>
                <br></br>
                <br></br>
                {allNews && allNews?.length ?
                    <div className='flex flex-col gap-6'>
                        <div className='flex lg:flex-row flex-col justify-between items-center gap-x-24 px-6'>
                            <p className='text-lg font-extralight'>More news</p>
                            {/* <TextField
                                fullWidth
                                id="search-input"
                                size="small"
                                sx={SearchStyle}
                                InputProps={{
                                    placeholder: 'Search here...',
                                    startAdornment: <InputAdornment position="start">
                                        <SearchOutlined />
                                    </InputAdornment>,
                                }}
                            /> */}
                        </div>
                        <div className='flex flex-col gap-6'>
                            {allNews?.map((_news, index) => (
                                <NewsArticle
                                    key={index}
                                    title={_news.title}
                                    content={_news.snippet}
                                    cover={_news.cover}
                                    createdAt={_news.createdat}
                                    category={_news.category}
                                    click={() => onArticlClick(_news.id, _news.title)}
                                />
                            ))}
                        </div>
                    </div>
                    : null
                }
            </div>
            <ShareDialog
                show={showShare}
                onHide={() => setShowShare(false)}
                link={`${panel}news/${news?.id}/${news?.title}`}
                title={news?.title}
            />
        </div>
    )


}

export default NewsDetails