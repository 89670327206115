import React, {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPositions } from "../../store/Actions/Action";
import { bufferTime, fromEvent } from "rxjs";
import { useMarketMst } from "../../store/mobx/MarketStoreMST";
import { marketReducer } from "../../store/Reducer/MarketReducer";

const _market = {
	currentIndex: "nifty",
	nifty: {},
	banknifty: {},
	finnifty: {},
	midcpnifty: {},
	sensex: {},
	bankex: {},
	instruments: {},
	previousTicks: {},
	positions: {},
	basketPositions: {},
	indiaVix: {
		instrument: 264969
	},
	giftNifty: {
		instrument: 291849
	}
};

const wsxWorker = new Worker("/websocket.worker.js");
let wsObservable$ = fromEvent(wsxWorker, "message");

// const plWorker = new Worker('/pl.worker.js')
// let pl_observable$ = fromEvent(plWorker, 'message')

// GET EVENT FOR POSISITIONS AND OPEN ORDERS
const MarketStateContext = createContext();

export const useMarketContext = () => {
	return useContext(MarketStateContext);
};

let foreground = true;
const updateForeground = (value) => {
	foreground = value;
};

export const MarketStateProvider = ({ children }) => {
	const dispatch = useDispatch();
	const [market, setMarket] = useState({ ..._market });
	const [isMarketOpen, setIsMarketOpen] = useState();
	const [oiFactor, setOIFactor] = useState(0);


	// useEffect(() => {
	// 	const handleWindowBlur = () => {
	// 		console.log('BLUR');
	// 		wsxWorker.postMessage({ status: "pause" });
	// 		updateForeground(false);
	// 	};

	// 	const handleWindowFocus = () => {
	// 		console.log('FOCUS');
	// 		wsxWorker.postMessage({ status: "resume" });
	// 		updateForeground(true);
	// 	};

	// 	window.addEventListener('blur', handleWindowBlur);
	// 	window.addEventListener('focus', handleWindowFocus);

	// 	// Cleanup event listeners on component unmount
	// 	return () => {
	// 		window.removeEventListener('blur', handleWindowBlur);
	// 		window.removeEventListener('focus', handleWindowFocus);
	// 	};
	// }, []);

	useEffect(() => {
		function handleVisibilityChange() {
			if (document.hidden) {
				wsxWorker.postMessage({ status: "pause" });
				updateForeground(false);
			} else {
				wsxWorker.postMessage({ status: "resume" });
				updateForeground(true);
			}
		}
		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	useEffect(() => {
		let _market;
		/* WITH BUFFER */
		wsObservable$.pipe(bufferTime(100)).subscribe((event) => {
			if (!_market) {
				// _market = { ...market };
				_market = market
			}
			if (event.length) {
				try {
					for (let index = 0; index < event.length; index++) {
						const _ev = event[index];
						// updateIndex(_ev.data.index, _ev.data.data)
						const data = JSON.parse(_ev.data.data)
						_market[_ev.data.index] = {
							..._market[_ev.data.index],
							...data,
						};
						// console.log(event.length)
						if (index == event.length - 1) {
							setMarket({ ..._market });
							// setMarket(_market)
						}
					}
					// setMarket(_market)
				} catch (e) {
					console.log(`ERROR WHILE SETTING DATA FROM WEB WORKER`, e);
				}
			}
		});

		/* WITHOUT BUFFER */
		// wsObservable$.subscribe((event) => {
		// 	// console.log(event.data, ' -- RECEIVED FROM WORKER')
		// 	if (!_market) {
		// 		_market = market;
		// 	}
		// 	if (event.data) {
		// 		try {
		// 			_market[event?.data?.index] = {
		// 				..._market[event?.data?.index],
		// 				...event.data.data,
		// 			};
		// 			setMarket(_market);
		// 		} catch (e) {
		// 			console.log(`ERROR WHILE SETTING DATA FROM WEB WORKER`);
		// 		}
		// 	}
		// });

		return () => {
			wsxWorker.terminate();
		};
	}, []);

	// useEffect(() => {
	//     console.count('MARKET UPDATED')
	// }, [market])

	const setMarketPositions = (positions) => {
		try {
			if (positions && positions.length) {
				const _market = market;
				for (let index = 0; index < positions.length; index++) {
					const pos = positions[index];
					_market.positions[pos.instrumenttoken] = {};
				}
				setMarket({ ..._market });
			}
			// console.log(_market.positions)
			return _market.positions;
		} catch (e) {
			console.log(`ERROR WHILE UPDATING POSITION LTP - ${e}`);
		}
	};

	const setMarketBasketPositions = (basketPositions) => {
		try {
			if (basketPositions && basketPositions.length) {
				const _market = market;
				for (let index = 0; index < basketPositions.length; index++) {
					const pos = basketPositions[index];
					const posOrders = JSON.parse(pos.orders);
					const openOrders = JSON.parse(pos.open_orders);
					for (let j = 0; j < posOrders.length; j++) {
						const order = posOrders[j];
						if (!_market.basketPositions[order.instrument_token])
							_market.basketPositions[order.instrument_token] = {};
					}
					for (let index = 0; index < openOrders.length; index++) {
						const order = openOrders[index];
						if (!_market.basketPositions[parseInt(order.instrument_token)])
							_market.basketPositions[parseInt(order.instrument_token)] = {};
					}
				}
				setMarket({ ..._market });
			}
			// console.log(_market.basketPositions)
			return _market.basketPositions;
		} catch (e) {
			console.log(`ERROR WHILE UPDATING BASKET POSITION LTP - ${e}`);
		}
	};

	const appendBasketPositions = (instrument) => {
		try {
			const _market = market;
			if (!_market.basketPositions[instrument]) {
				_market.basketPositions[instrument] = {};
				const _params = {
					updateBasketPositions: true,
					basketPositions: _market.basketPositions,
				};
				console.log('appendBasketPositions -- ', _market.basketPositions);
				setMarket({ ..._market })
				updateBasketPositionsWS(_params)
			}
		}
		catch (e) {
			console.log(`ERROR WHILE SETTING BASKET POSITONS INSTRUMENT -- `, e)
		}
	}

	const setSignelMarketBasketPosition = (basketInstrument) => {
		try {
			if (basketInstrument && basketInstrument?.length) {
				const _market = market;
				const _basketInstrument = basketInstrument;
				for (let index = 0; index < _basketInstrument.length; index++) {
					const instrument = _basketInstrument[index];
					if (!_market?.basketPositions[instrument]) {
						_market.basketPositions[instrument] = {}
					}
				}
				setMarket({ ..._market });
			}
			return _market.basketPositions;
		} catch (e) {
			console.log(`ERROR WHLE UPDATNG POSITION INSTRUMENTS ${e}`);
		}
	};

	const setMarketOpen = (value) => {
		try {
			setIsMarketOpen(value);
		} catch (e) {
			console.log(`ERROR WHILE UPDATING MARKET STATUS`);
		}
	};

	const updateAllow = (allow) => {
		wsxWorker.postMessage({ allow });
	};

	const setCurrentIndex = (index) => {
		try {
			// console.log(market)
			setMarket({ ...market, currentIndex: index });
			wsxWorker.postMessage({ indexChange: true, index: index });
		} catch (e) {
			console.log("ERROR WHILE SETTING CURRENT INDEX - ", e);
		}
	};

	const setCurrentExpiry = (expiry) => {
		try {
			// SEND CURRENT EXPIRY TO WEB WORKER
			wsxWorker.postMessage({ expiryChange: true, expiry: expiry });
		} catch (e) {
			console.log("ERROR WHILE SETTING EXPIRY ", e);
		}
	};

	const updateIndex = (index, data) => {
		// UPDATE PARTICULAR INDEX WITH LIVE DATA
		try {
			const _market = market;
			_market[index] = { ..._market[index], ...data };
			setMarket({ ..._market });
			// dispatch(marketReducer({ type: index, payload: data }))
		} catch (e) {
			console.log(`ERROR WHILE UPDATING ${index}`);
		}
	};

	const setExpiryIndex = (index, expiry, data, symbols) => {
		try {
			let _data = { ...market[index] };
			_data[expiry] = data;
			updateIndex(index, _data);
			wsxWorker.postMessage({
				expiryUpdate: true,
				data: _data,
				index: index,
				instruments: symbols,
			});
			// _data = null
		} catch (e) {
			console.log(`ERROR WHILE SETTING ${index} EXPIRY ${expiry}`, e);
		}
	};

	const updateIndexPromise = (index, data) => {
		return new Promise((resolve, reject) => {
			try {
				updateIndex(index, data);
				// console.log('KEY -- MST -- ', self[index])
				resolve(market[index]);
			} catch (e) {
				reject(e);
			}
		});
	};

	const setInstruments = (inst) => {
		// SET INSTRUMENTS FOR ALL MARKET DATA
		try {
			// console.log('UPDATING INSTRUMENTS...',Object.keys(inst).length)
			// dispatch(marketReducer({ type: 'instruments', payload: inst }))
			const _market = market;
			_market.instruments = inst;
			setMarket({ ..._market });
		} catch (e) {
			console.log(`ERROR WHILE UPDATING INSTRUMENTS - `, e);
		}
	};

	const startWebSockets = (params) => {
		// STARTS WEBSOCKET IN WEB WORKER - SEPARATE THREAD
		try {
			// console.log('WSWORKER', params)
			wsxWorker.postMessage(params);
		} catch (e) {
			console.log("ERROR WHILE STARTING WEBSOCKET FOR TICKERS - ", e);
		}
	};

	const updateMarketWs = (params) => {
		// UPDATE WEB WORKER IN BETWEEN - FOR OTHER EXPIRY DATES
		try {
			if (params?.updateTicker) {
				const _params = {
					marketUpdate: true,
					market: params.market,
					instruments: params.instruments,
				};
				// console.log(_params)
				wsxWorker.postMessage(_params);
			} else console.log(`NOT TO UPDATE WEB WORKER`);
		} catch (e) {
			console.log(`ERROR WHILE UPDATING WEB WORKDER SOCKET - ${e}`, e);
		}
	};

	const updatePositionsWS = (params) => {
		try {
			if (params?.updatePositions) {
				const _params = { updatePositions: true, positions: params.positions };
				// console.log(_params)
				wsxWorker.postMessage(_params);
			} else console.log(`NOT TO UPDATE WEB WORKER`);
		} catch (e) {
			console.log(
				`ERROR WHILE SENDING POSITION INSTRUMENTS TO WEBSOCKET - ${e}`
			);
		}
	};

	const appendPositionsWS = (params) => {
		try {
			if (params?.updatePositions) {
				const _market = market
				const _params = { updatePositions: true, positions: { ...market.positions, ...params.positions } };
				// console.log(_params)
				wsxWorker.postMessage(_params);
				console.log( _params.positions)
				_market['positions'] = _params.positions
				setMarket({ ..._market });
			}
			else console.log(`NOT DONE`);
		}
		catch (e) {
			console.log(`ERROR WHILE APPENDING POSITION INSTRUMENTS TO WEBSOCKET - ${e}`);
		}
	}

	const updateBasketPositionsWS = (params) => {
		try {
			console.log('UPDATING BASKET POSITION', params)
			if (params?.updateBasketPositions) {
				const _params = {
					updateBasketPositions: true,
					basketPositions: params.basketPositions,
				};
				// console.log(_params)
				wsxWorker.postMessage(_params);
			} else console.log(`NOT TO UPDATE WEB WORKER`);
		} catch (e) {
			console.log(
				`ERROR WHILE SENDING BASKET  POSITION INSTRUMENTS TO WEBSOCKET - ${e}`
			);
		}
	};

	const sendMessageToWorker = (params) => {
		try {
			wsxWorker.postMessage(params);
		} catch (e) {
			console.log(`ERROR WHILE SENDIGN MESSAGE TO WEB WORKER -- ${e}`);
		}
	};

	const context = {
		...market,
		isMarketOpen,
		startWebSockets,
		updateMarketWs,
		updatePositionsWS,
		appendPositionsWS,
		updateBasketPositionsWS,
		setExpiryIndex,
		updateIndex,
		updateIndexPromise,
		setInstruments,
		setCurrentIndex,
		setMarketOpen,
		setCurrentExpiry,
		updateAllow,
		setOIFactor,
		oiFactor,
		setMarketPositions,
		setMarketBasketPositions,
		sendMessageToWorker,
		setSignelMarketBasketPosition,
		appendBasketPositions
	};

	return (
		<MarketStateContext.Provider value={context}>
			{children}
		</MarketStateContext.Provider>
	);
};
