import React from 'react'
const ComingSoonImg = require('../assets/ComingSoon.jpg')


const ComingSoon = () => {
    return (
        <>
            <div className='w-full h-full flex items-center justify-center bg-[#f6f6f6B0] '>
                 <h1 className='text-2xl'>Coming Soon</h1>
            </div>
        </>
    )
}

export default ComingSoon
