import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export const BasketSkelatons = () => {
  return (
    <>
      <div className="w-full h-full">
        {[1, 2, 3, 4, 5, 6, 7].map((count) => (
          <div
            key={count}
            className="px-4 py-2 w-full  transition ease-in-out duration-100 hover:bg-white"
            style={{ borderBottom: "1px solid #eee" }}
          >
            <div className="flex justify-between mb-3 h-7 items-center">
              <Skeleton variant="text" width={100} sx={{ fontSize: "1rem" }} />
              <div className={`gap-2 transition ease-in-out duration-100 flex`}>
                <Skeleton variant="text" width={50} sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" width={50} sx={{ fontSize: "1rem" }} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const OpenOrderSkeletons = () => {
  return (
    <>
      <div className="w-full h-full">
        {[1, 2, 3, 4, 5, 6, 7].map((count) => (
          <div
            key={count}
            className="px-4 py-2 w-full  transition ease-in-out duration-100 "
            style={{ borderBottom: "1px solid #eee" }}
          >
            <div className="flex justify-between mb-3 h-7 items-center">
              <div className={`gap-2 transition ease-in-out duration-100 flex`}>
                <Skeleton
                  variant="text"
                  width={100}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton variant="text" width={35} sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" width={35} sx={{ fontSize: "1rem" }} />
              </div>
              <div className={`gap-2 transition ease-in-out duration-100 flex`}>
                <Skeleton variant="rectangular" width={30} height={30} />
                <Skeleton variant="rectangular" width={30} height={30} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const PositionSkeletons = () => {
  return (
    <>
      <div className="w-full h-full">
        {[1, 2, 3, 4, 5, 6, 7].map((count) => (
          <div
            key={count}
            className="px-4 py-2 w-full  transition ease-in-out duration-100 hover:bg-white"
            style={{ borderBottom: "1px solid #eee" }}
          >
            <div className="flex justify-between mb-3 h-7 items-center">
              <Skeleton variant="text" width={100} sx={{ fontSize: "1rem" }} />
              <div className={`gap-2 transition ease-in-out duration-100 flex`}>
                <Skeleton variant="rectangular" width={65} height={30} />
              </div>
            </div>
            <div className="flex justify-between mb-1">
              <Skeleton
                variant="text"
                width={"80%"}
                sx={{ fontSize: "1rem" }}
              />
              <Skeleton variant="text" width={50} sx={{ fontSize: "1rem" }} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const BasketFormSkelatons = () => {
  return (
    <>
      <div className="w-full h-full">
        {[1, 2, 3, 4, 5, 6, 7].map((count) => (
          <div
            key={count}
            className="px-4 py-2 w-full  transition ease-in-out duration-100 "
            style={{ borderBottom: "1px solid #eee" }}
          >
            <div
              className={`gap-2 transition ease-in-out duration-100 flex justify-between`}
            >
              <Skeleton variant="rectangular" width={65} height={30} />
              <Skeleton variant="rectangular" width={65} height={30} />
              <Skeleton variant="rectangular" width={65} height={30} />
              <Skeleton variant="rectangular" width={65} height={30} />
              <Skeleton variant="rectangular" width={65} height={30} />
              <Skeleton variant="rectangular" width={65} height={30} />
              <Skeleton variant="rectangular" width={65} height={30} />
              <Skeleton variant="circular" width={20} height={20} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
