import React, { useCallback, useEffect } from 'react'
import { Box, AppBar, Tabs, Tab, IconButton, InputAdornment, TextField } from '@mui/material';
import { useThemeContext } from '../../Theme/ThemeContext'
import EquityOrderTable from '../../EquityComponents/EquityOrderTable';
import SearchIcon from '@mui/icons-material/Search';
import EquityOrderFilter from '../../EquityComponents/EquityOrderFilter';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import OrderDrawer from '../../EquityComponents/OrderDrawer';
import { http } from '../../Services/api.services';
import { BASE_URL, WEBSOCKET_URL } from '../../default.config';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { H6, P } from '../../Theme/ThemeComponent';
import { setCurrentOrderType, setCurrentSelectedOrder, setOpenOrderform } from '../../store/Reducer/EquityReducer';
import { debounce, filter } from 'lodash';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const _dateFilter = {
  startDate: '',
  endDate: '',
}

const __filterData = {
  transaction_type: [],
  status: [],
  exit_mode: [],
  order_type: [],
  product_type: [],
  search: ''
}

export default function OrdersEquity() {
  const dispatch = useDispatch();
  const { theme } = useThemeContext();
  const { user } = useSelector(state => state.user)
  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false)
  const [openOrders, setOpenOrders] = React.useState([]);
  const [executedOrders, setExecutedOrders] = React.useState([]);
  const [filterDate, setFilterDate] = React.useState({ ..._dateFilter })
  const [filterData, setFilterData] = React.useState({ ...__filterData })
  const [totalRows, setTotalRows] = React.useState(100);
  const [skip, setSkip] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const parentDivRef = React.useRef(null);
  const childDivRef = React.useRef(null);
  const [height, setHeight] = React.useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 20,
    page: 0,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const executedOrdersColumns = [
    {
      field: "name",
      headerName: "Companies",
      minWidth: 150,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "date_time",
      headerName: "Date & Time",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center',
      renderCell: (params) => {
        const formattedDate = moment(params?.row?.created_at).format('DD-MM-YYYY');
        const formattedTime = moment(params?.row?.created_at).format('HH:mm:ss');
        return (
          <div className="flex w-full p-2  items-center justify-center gap-1">
            <H6
              title={formattedDate}
              color={theme.palette.text.charcolGray}
              font='normal'
            />
            <H6
              title={'|'}
              color={theme.palette.text.charcolGray}
              font='normal'
            />
            <P
              title={formattedTime}
              color={theme.palette.text.charcolGray}
              font='bold'
            />
          </div>
        );
      },
    },
    {
      field: "transaction_type",
      headerName: "Transection Type",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center',
      renderCell: (params) => {
        const color = params?.value >= 'BUY' ? 'green' : 'red';
        return (
          <div style={{ color }}>
            {params?.value}
          </div>
        );
      },
    },
    {
      field: "product_type",
      headerName: "Product Type",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "order_type",
      headerName: "Order Type",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "quantity",
      headerName: "Quantity",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "sl/t",
      headerName: "SL/T",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div >
            {params?.row?.stop_loss}
            /
            {params?.row?.target}
          </div>
        );
      },
    },
    {
      field: "trigger_mode",
      headerName: "Trigger Mode",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "price",
      headerName: "Limit",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "status",
      headerName: "Status",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
  ];

  const openOrdersColumns = [
    {
      field: "name",
      headerName: "Companies",
      minWidth: 150,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "date_time",
      headerName: "Date & Time",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center',
      renderCell: (params) => {
        const formattedDate = moment(params?.row?.created_at).format('DD-MM-YYYY');
        const formattedTime = moment(params?.row?.created_at).format('HH:mm:ss');
        return (
          <div className="flex w-full p-2  items-center justify-center gap-1">
            <H6
              title={formattedDate}
              color={theme.palette.text.charcolGray}
              font='normal'
            />
            <H6
              title={'|'}
              color={theme.palette.text.charcolGray}
              font='normal'
            />
            <P
              title={formattedTime}
              color={theme.palette.text.charcolGray}
              font='bold'
            />
          </div>
        );
      },
    },
    {
      field: "transaction_type",
      headerName: "Transection Type",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center',
      renderCell: (params) => {

        // Determine color based on original value (sign) 
        const color = params?.value >= 'BUY' ? 'green' : 'red';

        return (
          <div style={{ color }}>
            {params?.value}
          </div>
        );
      },
    },
    {
      field: "product_type",
      headerName: "Product Type",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "order_type",
      headerName: "Order Type",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "quantity",
      headerName: "Quantity",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "price",
      headerName: "Limit",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "last_price",
      headerName: "LTP",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "sl/t",
      headerName: "SL/T",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div >
            {params?.row?.stop_loss}
            /
            {params?.row?.target}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 170,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center',
      renderCell: (params) => {
        const { row } = params;
        return (
          <div className="flex items-center justify-center  gap-2">
            <IconButton
              onClick={() => {
                dispatch(setCurrentOrderType('modify'));
                dispatch(setOpenOrderform(true));
                dispatch(setCurrentSelectedOrder(params?.row));
              }}
              aria-label="edit order"
              color="primary"
            >
              <EditOutlinedIcon sx={{ fontSize: "18px !important", color: theme.palette.text.coolGray }} />
            </IconButton>
            <IconButton
              onClick={() => {
                dispatch(setCurrentOrderType('cancel'));
                dispatch(setOpenOrderform(true));
                dispatch(setCurrentSelectedOrder(params?.row));
              }}
              aria-label="edit order"
              color="primary"
            >
              <CloseOutlinedIcon sx={{ fontSize: "18px !important", color: theme.palette.text.coolGray }} />
            </IconButton>
          </div >
        );
      },
    },
  ];

  // GET OPEN ORDERS DATA ON SOCKET EVENT 

  React.useEffect(() => {
    try {
      // Create a WebSocket connection
      const socket = new WebSocket(`${WEBSOCKET_URL}/ws/open_order`);

      // Event listener for when the connection is opened
      socket.onopen = () => {
        console.log('WebSocket connection opened');
        socket.send(user?.id)
      };

      // Event listener for when a message is received from the server
      socket.onmessage = (event) => {
        console.log('Message from server:', event.data);
        getOpenOrders();
      };

      // Event listener for when the WebSocket connection is closed
      socket.onclose = (event) => {
        console.log('WebSocket connection closed:', event);
      };

      // Event listener for when there's an error with the WebSocket
      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      // Cleanup function to close the WebSocket connection when the component unmounts
      return () => {
        socket.close();
      };
    } catch (e) {
      console.log(e);
    }
  }, []);

  // GET ALL OPEN ORDERSBY API 
  useEffect(() => {
    if (user) {
      getOpenOrders();
    }
  }, [user])

  const getOpenOrders = async () => {
    try {
      const response = await http.get(`${BASE_URL}get_open_order?user_id=${user.id}`);
      if (response?.status == 200) {
        setOpenOrders(response?.data)
      }
    } catch (e) {
      console.log(`ERROR WHILE FETCHING OPEN ORDERS ${e}`);

    }
  }

  // GET ALL EXECUTED ORDERS BY API 
  useEffect(() => {
    if (user) {
      getExecutedOrders(__filterData, _dateFilter, skip, limit);
    }
  }, [user, skip, limit])

  const getExecutedOrders = async (_filterValues, _dateValues, skip, limit) => {
    setIsLoading(true);
    try {
      const _body = {
        user_id: user?.id,
        filters: { ..._filterValues },
        pagination: {
          limit: parseInt(limit),
          skip: parseInt(skip)
        }
      }
      _body.filters.dateFilter = { ..._dateValues }
      const response = await http.post(`${BASE_URL}get_executed_order`, _body);
      setIsLoading(false);
      if (response?.status == 200) {
        setExecutedOrders(response?.data?.data);
        setTotalRows(response?.data?.total_count)
      }
    } catch (e) {
      setIsLoading(false);
      console.log(`ERROR WHILE FETCHING OPEN ORDERS ${e}`);
    }
  }

  // SET FILTER BODY DATA ON CHANGE
  const onUpdateStateValues = (e, field) => {
    try {
      // Copy the current array for the specified field
      const _currentOptions = [...filterData[field]];

      const valueIndex = _currentOptions.indexOf(e?.value);

      if (valueIndex !== -1) {
        // If the value exists, remove it
        _currentOptions.splice(valueIndex, 1);
      } else {
        // If the value doesn't exist, add it
        _currentOptions.push(e?.value);
      }

      // Update the filterData state
      setFilterData((prevData) => ({
        ...prevData,
        [field]: _currentOptions,
      }));
    } catch (e) {
      console.log(`ERROR WHILE SETTING STATE VALUES: ${e}`);
    }
  };

  // RESET FITER FUNCTION 
  const resetFilter = async () => {
    try {
      setFilterData({
        ...__filterData
      });
      setFilterDate(({
        ..._dateFilter
      }))
      setSkip(0);
      setLimit(20);
      getExecutedOrders(__filterData, _dateFilter, 0, 20);
    } catch (e) {
      console.log(`ERROR WHILE RE-SETTING STATE VALUES: ${e}`);

    }
  }

  // MANAGE DIV HEIGHT
  React.useEffect(() => {
    let parentHeight = parentDivRef?.current?.offsetHeight;
    let childHeight = childDivRef?.current?.offsetHeight;
    setHeight(parentHeight - childHeight - 20);
  }, [parentDivRef?.current, childDivRef?.current]);


  // DEBOUNCED VERSION OF SEARCH EXECUTED ORDERS
  const debouncedFetchSearchResults = useCallback(debounce(getExecutedOrders, 300), []);

  useEffect(() => {
    debouncedFetchSearchResults(filterData, filterDate, skip, limit);
  }, [filterData?.search, debouncedFetchSearchResults]);

  return (
    <>
      <div className="w-full flex-1 h-screen overflow-hidden flex flex-col p-4">
        <div
          ref={parentDivRef}
          style={{ background: theme.palette.background.white }}
          className={`w-full flex-1 flex flex-col overflow-hidden p-0 shadow lg:p-2 lg:py-6`}
        >
          <Box sx={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column' }}>
            <AppBar
              ref={childDivRef}
              sx={{
                backgroundColor: 'transparent', // Background color
                color: theme.palette.text.black,
                boxShadow: 'none',
                display: 'flex !important',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row'
              }}
              position="static"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                // variant="fullWidth" 
                aria-label="full width tabs example"
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: theme.palette.text.black, // Set the indicator color to red
                  },
                  paddingLeft: '22px',
                }}
              >
                <Tab label="Open" {...a11yProps(0)} />
                <Tab label="Executed" {...a11yProps(1)} />
                {/* <Tab label="MTF" {...a11yProps(2)} /> */}
              </Tabs>
              {value == 1 ?
                <div className='flex items-center gap-2 '>
                  <TextField
                    placeholder="Search"
                    size='small'
                    id="outlined-start-adornment"
                    value={filterData?.search}
                    onChange={(e) => {
                      setFilterData((prevData) => ({
                        ...prevData,
                        search: e?.target?.value
                      }))
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            sx={{ color: theme.palette.text.coolGray }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <CloseOutlinedIcon
                            sx={{ color: theme.palette.text.coolGray, cursor: "pointer", opacity: filterData?.search?.length > 0 ? 1 : 0 }}
                            onClick={() => {
                              setFilterData((prevData) => ({
                                ...prevData,
                                search: ''
                              }))
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <EquityOrderFilter
                    filterDate={filterDate}
                    setFilterDate={setFilterDate}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    handleUpdateData={(e, _type) => {
                      onUpdateStateValues(e, _type);
                    }}
                    resetFilter={() => {
                      resetFilter();
                    }}
                    isLoading={isLoading}
                    onApplyFilter={() => getExecutedOrders(filterData, filterDate, skip, limit)}
                  />
                </div>
                : null}
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
              sx={{
                backgroundColor: 'red',
                flex: 1,
              }}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <div
                  style={{
                    height: height - 50
                  }}
                  className={`w-full flex-1 overflow-hidden`}>
                  <EquityOrderTable
                    columns={openOrdersColumns}
                    isPagination={false}
                    rows={openOrders}
                  />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <div
                  style={{
                    height: height - 50,
                  }}
                  className='w-full flex-1 '>
                  <EquityOrderTable
                    columns={executedOrdersColumns}
                    isPagination={true}
                    rows={executedOrders}
                    skip={skip}
                    setSkip={setSkip}
                    setLimit={setLimit}
                    limit={limit}
                    totalRows={totalRows}
                    paginationModel={paginationModel}
                  />
                </div>
              </TabPanel>
            </SwipeableViews>
          </Box>
        </div>
      </div>
    </>
  )
}
